import React, { memo } from 'react';
import { CardContent, Grid, Paper, Typography } from '@material-ui/core';
import { RenderButton, RenderFormikCheckbox, RenderFormikInput, RenderFormikSelect, Loader } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import style from './style.module.scss';
import { useTranslation } from 'react-i18next';

const ContractDetails = memo(
	({
		settings,
		settings: { ingressCps, ingressMaxSessions, onlyExternalRoutes, customerHardwareProfileId, ipAddress, prefix, hardwareProfiles },
		contract: { _id, userIsSupplier, userIsCustomer, partner, isOriginContract }
	}) => {
		const dispatch = useDispatch();
		const isLoading = useSelector(state => (state.routes && state.routes.loading) || false);
		const { t } = useTranslation('routing/routing');
		const formik = useFormik({
			initialValues: {
				customerHardwareProfileId, // for ingress
				ingressMaxSessions: ingressMaxSessions || 20000,
				ingressCps: ingressCps || 100,
				onlyExternalRoutes
			},
			onSubmit: (values, { resetForm }) => {
				dispatch({
					type: 'routes/UPDATE_ROUTE_SETTINGS',
					payload: {
						smartContractId: _id,
						...values
					}
				});
				// resetForm();
			},
			enableReinitialize: true
		});

		return (
			<div className={style.container}>
				{(settings && (
					<form>
						{(userIsSupplier && (
							<Paper>
								{(isLoading && <Loader loading />) || ''}
								<CardContent>
									<Grid container spacing={2}>
										{(!isOriginContract && (
											<Grid item xs={3}>
												<h4> {(partner && partner.name) || ''} {t('switch_ip')}</h4>
												{ipAddress}
											</Grid>
										)) ||
											''}
										<Grid item xs={1}>
											<RenderFormikInput label={t('ports')} name="ingressMaxSessions" formik={formik} />
										</Grid>
										<Grid item xs={1}>
											<RenderFormikInput label="CPS" name="ingressCps" formik={formik} />
										</Grid>
										<Grid item xs={5}>
											<RenderFormikCheckbox label={t('onlyExternalRoutes')} name="onlyExternalRoutes" formik={formik} />
										</Grid>
										<Grid item xs={(!isOriginContract && 2) || 5}>
											<div style={{ textAlign: 'right' }}>
												<RenderButton color="primary" onClick={formik.handleSubmit}>
													{t('save_settings')}
												</RenderButton>
											</div>
										</Grid>
									</Grid>
								</CardContent>
							</Paper>
						)) ||
							''}
						{(userIsCustomer && isOriginContract && (
							<Paper>
								{(isLoading && <Loader loading />) || ''}
								<CardContent>
									<Grid container spacing={2}>
										<Grid item xs={3}>
											<h4> {(partner && partner.name) || ''} {t('switch_ip')}</h4>
											{ipAddress}
										</Grid>
										<Grid item xs={2}>
											<h4> {(partner && partner.name) || ''} {t('prefix')}</h4>
											{prefix}
										</Grid>
										<Grid item xs={5}>
											<RenderFormikSelect
												disabled={customerHardwareProfileId}
												formik={formik}
												label={t('external_connection')}
												name="customerHardwareProfileId"
												options={hardwareProfiles || []}
											/>
										</Grid>
										<Grid item xs={2}>
											<div style={{ textAlign: 'right' }}>
												{(!customerHardwareProfileId && (
													<RenderButton color="primary" onClick={formik.handleSubmit}>
														{t('save_settings')}
													</RenderButton>
												)) ||
													''}
											</div>
										</Grid>
									</Grid>
								</CardContent>
							</Paper>
						)) ||
							''}
					</form>
				)) ||
					''}
				{(!settings && (
					<Paper>
						<CardContent>{t('loading')}</CardContent>
					</Paper>
				)) ||
					''}
			</div>
		);
	}
);

export default ContractDetails;
