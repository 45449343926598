export const initialState = {};

export const initialRegisterData = {
    name: null,
    email: null,
    password: null,
    accountType: null,
    errorMessage: null,
    loginSuccess:null
};
export const REGISTER_DATA='REGISTER_DATA';

export const getRegisterDataState = (state = initialState) =>
    state[REGISTER_DATA] || initialRegisterData
