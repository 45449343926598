import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fromFinancingrequests,
  fromWallet,
  fromUseraccount
} from "store/selectors";
import { reset } from "redux-form";
import { bindActionCreators } from "redux";
import {
  actionCreatorPreloaderOff,
  actionCreatorPreloaderOn,
  getFinancialRequestsSmartContracts,
  sendFinancialRequestsLenderOffer,
  getInitialWallet,
  updateFundingOffer
} from "store/actions";
import { FinancingRequestsList } from "components";

class FinancingRequestsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      walletFunds: 0
    };

    this.manageFundingRequestSubmit = this.manageFundingRequestSubmit.bind(
      this
    );
    this.handleActiveFundingOfferSubmit = this.handleActiveFundingOfferSubmit.bind(
      this
    );
  }

  componentWillMount() {
    const {
      userInfo,
      getFinancialRequestsSmartContracts,
      getInitialWallet
    } = this.props;
    getFinancialRequestsSmartContracts();
    getInitialWallet(userInfo.userId);
  }

  componentWillReceiveProps(nextProps) {
    const { walletInfo } = nextProps;
    if (walletInfo) {
      let amountTotal = 0;
      let walletFunds = 0;

      if (walletInfo.wallet) {
        walletInfo.wallet.map(obj => {
          amountTotal += Number.parseFloat(obj.fiatAmount);
        });
        walletFunds =
          amountTotal - Number.parseFloat(walletInfo.lockedOnSmartContracts);
      }

      this.setState({
        walletFunds
      });
    }
  }

  manageFundingRequestSubmit(data) {
    this.props.sendFinancialRequestsLenderOffer(data);
    this.props.reset("FundingOfferRequestForm");
  }

  handleActiveFundingOfferSubmit(data) {
    const { updateFundingOffer } = this.props;
    updateFundingOffer(data);
  }

  render() {
    const { walletFunds } = this.state;
    const { contracts } = this.props;
    return (
      <FinancingRequestsList
        contracts={contracts}
        walletFunds={walletFunds}
        manageFundingRequestSubmit={this.manageFundingRequestSubmit}
        handleActiveFundingOfferSubmit={this.handleActiveFundingOfferSubmit}
      />
    );
  }
}

FinancingRequestsContainer.defaultProps = {
  contracts: [],
  walletFunds: 0
};

function mapStateToProps(state) {
  return {
    userInfo: fromUseraccount.getUserInfoDataState(state),
    contracts: fromFinancingrequests.getSmartContracts(state),
    walletInfo: fromWallet.getWalletData(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionCreatorPreloaderOn,
      actionCreatorPreloaderOff,
      getFinancialRequestsSmartContracts,
      sendFinancialRequestsLenderOffer,
      updateFundingOffer,
      getInitialWallet,
      reset
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinancingRequestsContainer);
