import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, reset } from 'redux-form';

import { RegisterForm } from 'components';
import { registerRequest, cleanDataRegister } from 'store/actions';
import { createValidator, required, email, minLength, maxLength, match, validatePassword, agreement } from 'services/validation';
import { fromRegisteraccount } from 'store/selectors';
import axios from 'axios';
import myAccountApiService from 'services/api/MyAccountApi';

class RegisterFormContainer extends Component {
	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(reset('registerForm'));
		dispatch(cleanDataRegister());
	}

	render() {
		return <RegisterForm {...this.props} handleSubmit={this.props.handleSubmit} />;
	}
}

const mapStateToProps = state => ({
	form: state.form,
	info: fromRegisteraccount.getRegisterDataState(state)
});

const onSubmit = async (data, dispatch) => {
	dispatch(registerRequest(data));
};

const validate = createValidator({
	companyName: [required, minLength(3), maxLength(50)],
	contactName: [required, minLength(3), maxLength(50)],
	email: [required, email, maxLength(50)],
	password: [required, validatePassword],
	passwordConfirmation: [required, match('password', 'Password')],
	agree: [agreement],
	phone: [required, minLength(7), maxLength(13)]
});

RegisterFormContainer = connect(mapStateToProps, null)(RegisterFormContainer);

RegisterFormContainer = reduxForm({
	form: 'registerForm',
	destroyOnUnmount: false,
	onSubmit,
	initialValues: { accountType: '1' },
	validate
})(RegisterFormContainer);

export default RegisterFormContainer;
