import React from "react";
import { RoutesForm } from "components";
import {
  Paper,
  CardContent,
  DialogContent,
  Dialog,
  Slide
} from "@material-ui/core";

const Transition = props => <Slide direction="up" {...props} />;

class RoutesUpdateForm extends React.Component {
  render() {
    const {
      open,
      initialValues,
      handleShowHide,
      routesData,
      handleSubmit
    } = this.props;
    return (
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onBackdropClick={handleShowHide}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            overflow: "visible",
            position: "static"
          }
        }}
      >
        <DialogContent>
          <h4>Update Route</h4>
          <RoutesForm
            routesData={routesData}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            form="RoutesUpdateForm"
          />
        </DialogContent>
      </Dialog>
    );
  }
}

export default RoutesUpdateForm;
