import React from "react";
import { Field,reduxForm } from "redux-form";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createValidator,
  required
} from "services/validation";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Slide,
  Input
} from "@material-ui/core";
import { RenderButton, RenderInput} from "components";
import { withTranslation } from 'react-i18next';
import styles from "./style";

const Transition = props => <Slide direction="up" {...props} />;

class SmartContractUpdateName extends React.Component {
	
	constructor(props) {
		super(props);
		this.onChangeSmartContractInput = this.onChangeSmartContractInput.bind(this);
		const {contractData: {name}
		} = this.props
		this.props.change('nickname', name);

		
	}
	onChangeSmartContractInput = ev => {
		const value = ev.target.value || 0;
		this.props.change('nickname', value);
	};

  contractIdFormatter = idContract => {
    const padded = `${idContract}000000`.slice(0, -idContract.length);
    return parseInt(padded)
      .toString(16)
      .toUpperCase();
  };
	  
  render() {
    const {
		t,
      open,
      classes,
      openCloseHandler,
	  nickname,
      handleSubmit,
      contractData: {
        contractTerms: {
			contractId
        },
        name
      }    
    } = this.props;
	const idContract = contractId
    return (
      <Dialog
        open={open}
        onBackdropClick={openCloseHandler}
        TransitionComponent={Transition}
        maxWidth="sm"
      >
        <DialogTitle>Update Contract Name</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
				<Field
					component={RenderInput}
					name="nickname"
					type="text"
					label='Smart Contract'
					placeholder={name? `${name}`: this.contractIdFormatter(idContract)}
					onChange={this.onChangeSmartContractInput}
				/>
              </Grid>
            </Grid>

            <Divider className={classes.divider} />
          </DialogContent>
          <DialogActions>
              <RenderButton color="primary" type="submit">
                Save
              </RenderButton>
            <RenderButton color="danger" onClick={openCloseHandler}>
              Cancel
            </RenderButton>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

SmartContractUpdateName.defaultProps = {
  contractData: {
    contractTerms: {
      idContract: "",
      supplier: "",
      customer: "",
      paymentTerms: "",
      settlementPeriod: "",
      billingTimezone: "",
      contractEndDate: ""
    }
  }
};

const validate = createValidator({
	nickname: [required]
});

const SmartContractUpdateNameStyles = withStyles(styles)(
  SmartContractUpdateName
);
const SmartContractUpdateNameModalWithTranslation = withTranslation()(SmartContractUpdateNameStyles);


export default reduxForm({
  form: "SmartContractUpdateName",
  enableReinitialize: true,
  touchOnBlur: true,
  validate
})(SmartContractUpdateNameModalWithTranslation);
