import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormHelperText from '@material-ui/core/FormHelperText';

class RenderMaterialSwitch extends React.Component {
	state = {
		checked: typeof this.props.input.value !== typeof undefined ? this.props.input.value : false
	};

	render() {
		const {
			input,
			label,
			readOnly,
			meta: { touched, error },
			callback,
			forceCheck
		} = this.props;
		const hasError = typeof error !== typeof undefined && error.length > 0 && touched;
		const { checked } = this.state;
		return (
			<FormControl error={hasError}>
				<FormControlLabel
					control={
						<Switch
							{...input}
							disabled={readOnly !== undefined && readOnly}
							checked={!!checked || !!forceCheck}
							onChange={event => {
								this.setState({ checked: event.target.checked }, () => {
									if (typeof callback === 'function') {
										callback(event);
									}
								});
							}}
							name={input.name}
							color="primary"
							value="1"
						/>
					}
					label={label}
				/>
				{hasError && <FormHelperText>{error}</FormHelperText>}
			</FormControl>
		);
	}
}
export default RenderMaterialSwitch;
