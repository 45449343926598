import { FINANCING_REQUESTS_SMARTCONTRACTS_DATA } from "./actions";

export default function(state = {}, action) {
  switch (action.type) {
    case FINANCING_REQUESTS_SMARTCONTRACTS_DATA:
      return {
        ...state,
        [FINANCING_REQUESTS_SMARTCONTRACTS_DATA]: action.payload
      };
    default:
      return state;
  }
}
