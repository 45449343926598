import React from "react";
import moment from "moment";
import {
  AppBar,
  CardContent,
  Paper,
  Tab,
  Tabs,
  Typography
} from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  RenderBadge,
  RenderButton,
  RenderTable,
  FundingOfferRequestModal,
  FundingOfferDetailsModal
} from "components";

class FinancingRequestsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "lendingRequests",
      manageOffer: null,
      offerDetails: null
    };
    this.handleTabChange = this.handleTabChange.bind(this);
    this.manageFundingRequest = this.manageFundingRequest.bind(this);
    this.getModifier = this.getModifier.bind(this);
  }

  getModifier = data => {
    if (!data || !data.contractTerms) return 0;
    const {
      contractTerms: { billingCycle, paymentTerms }
    } = data;
    let modifier = 0;
    switch (billingCycle.toLowerCase()) {
      case "weekly":
        modifier = 7 + paymentTerms;
        break;
      case "semi-monthly":
      case "bi-weekly":
        modifier = 15 + paymentTerms;
        break;
      case "prepay":
      case "daily":
        modifier = 1 + paymentTerms;
        break;
      case "monthly":
        modifier = 30 + paymentTerms;
        break;
      case "bi-monthly":
        modifier = 60 + paymentTerms;
        break;
      default:
        modifier = 0;
        break;
    }
    return modifier;
  };

  manageFundingRequest = data => {
    const { manageOffer } = this.state;
    const { manageFundingRequestSubmit, walletFunds } = this.props;
    const modifier = data && data !== null ? this.getModifier(data) : 0;
    this.setState({
      manageOffer:
        manageOffer === null ? (
          <FundingOfferRequestModal
            contractData={{
              ...data,
              dailyRequestedAmount: data.fundingRequestedAmount / modifier,
              walletFunds
            }}
            dialogOpen
            dialogOpenCloseHandler={this.manageFundingRequest}
            onSubmit={postData => {
              manageFundingRequestSubmit(postData);
              this.manageFundingRequest();
            }}
            initialValues={{
              _id: data._id || null,
              walletFunds,
              lenderStatus: data.lenderStatus || null,
              interestRate: data.interestRate || null,
              dailyTesspayFee: data.dailyTesspayFee || true,
              contractId: data.contractTerms.contractId || null,
              percent: data.percent || 100,
              dailyFundingAmount:
                data.dailyFundingAmount ||
                data.fundingRequestedAmount / modifier,
              totalFundingAmount:
                data.totalFundingAmount || data.fundingRequestedAmount,
              maxDailyFundingAmount:
                data.dailyFundingAmount ||
                data.fundingRequestedAmount / modifier,
              maxTotalFundingAmount:
                data.totalFundingAmount || data.fundingRequestedAmount
            }}
          />
        ) : null
    });
  };

  fundingRequestDetails = data => {
    const { offerDetails } = this.state;
    const { handleActiveFundingOfferSubmit } = this.props;
    this.setState({
      offerDetails:
        offerDetails === null ? (
          <FundingOfferDetailsModal
            data={data}
            dialogOpen
            dialogOpenCloseHandler={this.fundingRequestDetails}
            handleActiveFundingOfferSubmit={handleActiveFundingOfferSubmit}
          />
        ) : null
    });
  };

  fundedFormatter = row => {
    if (row.fundingOffers) {
      return (
        <RenderBadge color="primary">
          ${row.settlementAmount} ({row.interestRate}
          %)
        </RenderBadge>
      );
    }
  };

  statusFormatter = row => {
    if (row.lenderStatus) {
      if (row.supplierStatus) {
        if (
          row.lenderStatus === "accepted" &&
          row.supplierStatus === "accepted"
        )
          return "Offer Accepted";
        if (
          row.lenderStatus === "accepted" &&
          row.supplierStatus === "declined"
        )
          return "Offer Declined";
      }
      if (row.lenderStatus === "accepted") return "Offer Submitted";
      if (row.lenderStatus === "declined") return "Request Declined";
    }
    return "Pending";
  };

  contractIdFormatter = contractId => {
    if (contractId) {
      const padded = `${contractId}000000`.slice(0, -contractId.length);
      return parseInt(padded)
        .toString(16)
        .toUpperCase();
    }
    return "-";
  };

  actionFormatter = row => (
    <div>
      <RenderButton
        color="primary"
        component={Link}
        to={`/smart-contracts/edit/${row.contractTerms.contractId}`}
      >
        View Smart Contract
      </RenderButton>
      <br />
      <RenderButton
        color="primary"
        onClick={() => {
          this.manageFundingRequest(row);
        }}
      >
        Manage Funding Request
      </RenderButton>
    </div>
  );

  actionActiveFormatter = row => (
    <div>
      <RenderButton
        color="primary"
        onClick={() => {
          this.fundingRequestDetails(row);
        }}
      >
        Details
      </RenderButton>
    </div>
  );

  handleTabChange = (ev, activeTab) => {
    this.setState({ activeTab });
  };

  render() {
    const { contracts, fundingOfferSubmitHandler } = this.props;
    const { manageOffer, offerDetails, activeTab } = this.state;

    const pendingContracts =
      contracts && Array.isArray(contracts)
        ? contracts.filter(
            obj =>
              obj.lenderStatus !== "declined" && obj.lenderStatus !== "accepted"
          )
        : [];
    const activeContracts =
      contracts && Array.isArray(contracts)
        ? contracts.filter(
            obj =>
              obj.lenderStatus === "accepted" &&
              obj.supplierStatus === "accepted"
          )
        : [];
    const historyContracts =
      contracts && Array.isArray(contracts)
        ? contracts.filter(
            obj =>
              obj.lenderStatus === "declined" ||
              obj.supplierStatus === "declined"
          )
        : [];

    return (
      <div>
        <AppBar position="static">
          <Tabs value={activeTab} onChange={this.handleTabChange}>
            <Tab label="Lending Requests" value="lendingRequests" />
            <Tab label="Active Funded Contracts" value="activeOffers" />
            <Tab label="Funding History" value="declinedOffers" />
          </Tabs>
        </AppBar>
        {manageOffer}
        {offerDetails}
        {activeTab === "lendingRequests" ? (
          <Paper>
            <CardContent>
              <Typography variant="h1" gutterBottom>
                Lending Requests
              </Typography>
              <RenderTable
                data={pendingContracts}
                filterable={false}
                columns={[
                  {
                    Header: "Smart Contract ID",
                    accessor: "contractTerms.contractId",
                    Cell: props => this.contractIdFormatter(props.value)
                  },
                  {
                    Header: "Customer",
                    accessor: "contractTerms.customer"
                  },
                  {
                    Header: "Supplier",
                    accessor: "contractTerms.supplier"
                  },
                  {
                    Header: "Payment Terms",
                    accessor: "contractTerms.paymentTerms",
                    Cell: props =>
                      parseInt(props.value) > 1
                        ? `${props.value} Days`
                        : `${props.value} Day`
                  },
                  {
                    Header: "Billing Cycle",
                    accessor: "contractTerms.billingCycle"
                  },
                  {
                    Header: "Settlement period",
                    accessor: "contractTerms.settlementPeriod"
                  },
                  {
                    Header: "Status",
                    accessor: "status",
                    Cell: props => this.statusFormatter(props.original)
                  },
                  {
                    Header: "Action",
                    accessor: "",
                    filterable: false,
                    Cell: props => this.actionFormatter(props.original)
                  }
                ]}
              />
            </CardContent>
          </Paper>
        ) : (
          ""
        )}

        {activeTab === "activeOffers" ? (
          <Paper>
            <CardContent>
              <Typography variant="h1" gutterBottom>
                Active Funded Contracts
              </Typography>
              <RenderTable
                data={activeContracts}
                filterable={false}
                columns={[
                  {
                    Header: "Smart Contract ID",
                    accessor: "contractTerms.contractId",
                    Cell: props => this.contractIdFormatter(props.value)
                  },
                  {
                    Header: "Customer",
                    accessor: "contractTerms.customer"
                  },
                  {
                    Header: "Supplier",
                    accessor: "contractTerms.supplier"
                  },
                  {
                    Header: "Financing Fee (%/invoice)",
                    accessor: "interestRate",
                    Cell: props =>
                      props.value !== "" && props.value !== undefined
                        ? `${props.value}%`
                        : ""
                  },
                  {
                    Header: "Credit Limit",
                    accessor: "totalFundingAmount",
                    Cell: props => {
                      const {
                        original: { percent = 100, totalFundingAmount }
                      } = props;
                      return `$${((totalFundingAmount * percent) / 100).toFixed(
                        2
                      )}`;
                    }
                  },
                  {
                    Header: "Coverage",
                    accessor: "percent",
                    Cell: props => `${props.value || 100}%`
                  },
                  {
                    Header: "Remaining Credit",
                    accessor: "totalFundingAmount",
                    Cell: props => {
                      const {
                        original: { percent = 100, totalFundingAmount }
                      } = props;
                      if (props.original.transactions) {
                        let alocatedAmount = 0;
                        props.original.transactions.map(obj => {
                          alocatedAmount +=
                            obj.type === "advance" ? obj.amount : 0;
                          alocatedAmount -=
                            obj.type === "return" ? obj.amount : 0;
                        });
                        return `$${(
                          (totalFundingAmount * percent) / 100 -
                          alocatedAmount
                        ).toFixed(2)}`;
                      }
                    }
                  },
                  // {
                  //   Header: "Status",
                  //   accessor: "status",
                  //   Cell: props => this.statusFormatter(props.original)
                  // },
                  {
                    Header: "Action",
                    accessor: "",
                    filterable: false,
                    Cell: props => this.actionActiveFormatter(props.original)
                  }
                ]}
              />
            </CardContent>
          </Paper>
        ) : (
          ""
        )}

        {activeTab === "declinedOffers" ? (
          <Paper>
            <CardContent>
              <Typography variant="h1" gutterBottom>
                Funding History
              </Typography>
              <RenderTable
                data={historyContracts}
                filterable={false}
                columns={[
                  {
                    Header: "Smart Contract ID",
                    accessor: "contractTerms.contractId",
                    Cell: props => this.contractIdFormatter(props.value)
                  },
                  {
                    Header: "Customer",
                    accessor: "contractTerms.customer"
                  },
                  {
                    Header: "Supplier",
                    accessor: "contractTerms.supplier"
                  },
                  {
                    Header: "Payment Terms",
                    accessor: "contractTerms.paymentTerms",
                    Cell: props =>
                      parseInt(props.value) > 1
                        ? `${props.value} Days`
                        : `${props.value} Day`
                  },
                  {
                    Header: "Billing Cycle",
                    accessor: "contractTerms.billingCycle"
                  },
                  {
                    Header: "Settlement period",
                    accessor: "contractTerms.settlementPeriod"
                  },
                  {
                    Header: "Status",
                    accessor: "status",
                    Cell: props => this.statusFormatter(props.original)
                  },
                  {
                    Header: "Action",
                    accessor: "",
                    filterable: false,
                    Cell: props => this.actionFormatter(props.original)
                  }
                ]}
              />
            </CardContent>
          </Paper>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default FinancingRequestsList;
