import axios from 'axios';
import { API } from 'aws-amplify';

const fastLinkConfig = {
	url: 'https://voip.api.tpio.us/',
	username: 'admin',
	password: 'tess@0909'
};

export const voipApiService = {};

voipApiService.getToken = () =>
	axios
		.post(`${fastLinkConfig.url}users/api-token-auth/`, {
			username: fastLinkConfig.username,
			password: fastLinkConfig.password
		})
		.then(({ data }) => {
			const instance = axios.create({
				baseURL: fastLinkConfig.url,
				headers: {
					'X-CSRFToken': data.token
				}
			});
			return instance;
		})
		.catch(err => err);

voipApiService.createCarrier = data =>
	voipApiService.getToken().then(instance =>
		instance
			.post('voip/carrier', data)
			.then(response => response)
			.catch(err => err)
	);

voipApiService.updateCarrier = (carrierId, data) =>
	voipApiService.getToken().then(instance =>
		instance
			.put(`voip/carrier/${carrierId}`, data)
			.then(response => response)
			.catch(err => err)
	);

voipApiService.createIps = (carrierId, dataArray) =>
	voipApiService.getToken().then(instance => {
		const promisesArray = [];
		dataArray.map(ip => {
			const ipAddrPostData = {
				carrier: carrierId,
				name: ip.ip,
				address: ip.ip,
				subnet_prefix_length: ip.netmask,
				port: ip.port
			};
			promisesArray.push(voipApiService.createIp(ipAddrPostData));
		});

		return Promise.all(promisesArray)
			.then(response => response)
			.then(resp => resp)
			.catch(err => err);
	});

voipApiService.createIp = data =>
	voipApiService.getToken().then(instance =>
		instance
			.post('voip/ip', data)
			.then(response => response)
			.catch(err => err)
	);

voipApiService.createProfile = data =>
	voipApiService.getToken().then(instance =>
		instance
			.post('voip/profile', data)
			.then(response => response)
			.catch(err => err)
	);

voipApiService.updateProfile = (sbcId, data) =>
	voipApiService.getToken().then(instance =>
		instance
			.put(`voip/profile/${sbcId}`, data)
			.then(response => response)
			.catch(err => err)
	);

voipApiService.createContract = data =>
	voipApiService.getToken().then(instance =>
		instance
			.post('voip/contract', data)
			.then(response => response)
			.catch(err => err)
	);

voipApiService.updateProfile = (sbcId, data) =>
	voipApiService.getToken().then(instance =>
		instance
			.put(`voip/profile/${sbcId}`, data)
			.then(response => response)
			.catch(err => err)
	);

voipApiService.loadCarrierReportData = params =>
	voipApiService.getToken().then(instance =>
		instance
			.get('/voip/carrier/reports_accounts_info', {
				params
			})
			.then(response => {
				return response;
			})
			.catch(err => err)
	);

voipApiService.getContractRoutes = (contractId, carrierId) =>
	voipApiService.getToken().then(instance =>
		instance
			.get(`/voip/contract/${contractId}/routes`, {
				params: {
					carrier_id: carrierId
				}
			})
			.then(response => response)
			.catch(err => err)
	);

export default voipApiService;
