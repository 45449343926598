const headerStyle = theme => ({
	header: {
		color: theme.palette.light,
		backgroundColor: theme.palette.primary.main,
		transition: 'top 400ms ease 0s',
		position: 'fixed',
		top: '0',
		zIndex: '1100',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		padding: '0 15px 0 0',
		'&.impersonated': {
			top: "48px"
		}
	},
	impersonatedBanner: {
		position: 'fixed',
		top: '0',
		width: '100%',
		height: '52px',
		padding: '10px 0',
		textAlign: 'right',
		backgroundColor: '#0b3a2b',
		zIndex: '1100',
	},
	impersonatedBannerButton: {
		color: theme.palette.primary.main,
		backgroundColor: '#fff',
		textTransform: 'none',
		'&:hover': {
			color: "#fff",
			backgroundColor: theme.palette.secondary.main,
		}
	},
	flex1: {
		flexGrow: '1'
	},
	noWrap: {
		whiteSpace: 'nowrap'
	},
	logo: {
		backgroundColor: theme.palette.primary.main,
		display: 'block',
		height: theme.spacing(1) * 6,
		width: '200px',
		textAlign: 'center'
	},
	logoImage: {
		height: theme.spacing(1) * 4,
		marginTop: theme.spacing(1)
	}
});

export default headerStyle;
