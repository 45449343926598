import React from "react";

const styleTitle = {
  marginBottom: 25,
  textAlign: "center"
};
const styleTotal = {
  fontSize: 15
};
const alignTotal = {
  textAlign: "center"
};
const centerTitle = {
  paddingLeft: "1.25rem",
  paddingRight: "1.25rem"
};
const FinancialHeader = props => (
  <div>
    <div className="row" style={centerTitle}>
      <div className="col-md-4" />
      <div className="col-md-4" style={styleTitle}>
        Total
      </div>
      <div className="col-md-4" style={styleTitle}>
        Available
      </div>
    </div>
    <div className="card-header" id="headingOne">
      <div className="row">
        <div className="col-md-4">
          <h5 className="mb-0">
            <button
              type="button"
              className="btn btn-link"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              onClick={props.showResult}
            >
              USD
            </button>
          </h5>
        </div>
        <div className="col-md-4" style={alignTotal}>
          <span className="badge badge-info" style={styleTotal}>
            {props.totalAmount}
          </span>
        </div>
        <div className="col-md-4" style={alignTotal}>
          <span className="badge badge-primary" style={styleTotal}>
            {" "}
            {props.totalAmount}
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default FinancialHeader;
