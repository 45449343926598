import { saveAs } from 'file-saver';
import { create, isArray } from 'lodash';
import { call, put, takeEvery, all } from 'redux-saga/effects';
import AcpProgramApi from 'services/api/AcpProgramApi';
import EscrowManagingApi from 'services/api/EscrowManagingApi';
import { triggerNotification } from 'store/notifications/actions';
import { actionCreatorPreloaderOn, actionCreatorPreloaderOff } from '../preloader/actions';
// eslint-disable-next-line import/no-named-as-default
import actions from './actions';
import { base64ToBlob } from 'utils';

export function* GET_AVAILABLE_CLAIMS(action) {
	yield put({
		type: actions.SET_LOADING,
		payload: {
			availableClaims: true
		}
	});
	try {
		const result = yield call(EscrowManagingApi.getAvailableClaims);

		if (result) {
			yield put({
				type: actions.SET_STATE,
				payload: {
					availableClaims: result
				}
			});
		}
	} catch (error) {
		console.log({ error });
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	} finally {
		yield put({
			type: actions.SET_LOADING,
			payload: {
				availableClaims: false
			}
		});
	}
}

export function* GET_ORDERS() {
	yield put({
		type: actions.SET_LOADING,
		payload: {
			orders: true
		}
	});
	try {
		const result = yield call(EscrowManagingApi.getOrders);

		if (result) {
			yield put({
				type: actions.SET_STATE,
				payload: {
					orders: result
				}
			});
		}
	} catch (error) {
		console.log({ error });
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	} finally {
		yield put({
			type: actions.SET_LOADING,
			payload: {
				orders: false
			}
		});
	}
}

export function* GET_CONTRACTS() {
	yield put({
		type: actions.SET_LOADING,
		payload: {
			contracts: true
		}
	});
	try {
		const result = yield call(EscrowManagingApi.getContracts);

		if (result) {
			yield put({
				type: actions.SET_STATE,
				payload: {
					contracts: result
				}
			});
		}
	} catch (error) {
		console.log({ error });
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	} finally {
		yield put({
			type: actions.SET_LOADING,
			payload: {
				contracts: false
			}
		});
	}
}

export function* GET_PARTICIPATING_PARTNERS() {
	yield put({
		type: actions.SET_LOADING,
		payload: {
			participants: true
		}
	});
	try {
		const result = yield call(EscrowManagingApi.getParticipatingPartners);

		if (result) {
			yield put({
				type: actions.SET_STATE,
				payload: {
					participatingPartners: result
				}
			});
		}
	} catch (error) {
		console.log({ error });
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	} finally {
		yield put({
			type: actions.SET_LOADING,
			payload: {
				participants: false
			}
		});
	}
}

export function* CREATE_NEW_ORDER(action) {
	yield put({
		type: actions.SET_LOADING,
		payload: {
			orders: true
		}
	});
	try {
		const result = yield call(EscrowManagingApi.createOrder, action.payload);

		if (result) {
			yield put({
				type: actions.GET_ORDERS,
				payload: result
			});
			yield put(triggerNotification('Order created successfully', 'success'));
		}
	} catch (error) {
		console.log({ error });
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	} finally {
		yield put({
			type: actions.SET_LOADING,
			payload: {
				orders: false
			}
		});
	}
}

export function* CREATE_NEW_SMART_CONTRACT(action) {
	yield put({
		type: actions.SET_LOADING,
		payload: {
			orders: true
		}
	});

	try {
		const result = yield call(EscrowManagingApi.createSmartContract, action.payload);
		if (result) {
			yield put({
				type: actions.GET_ORDERS
			});
			yield put(triggerNotification('ACP Smart contract created successfully', 'success'));
		}
	} catch (error) {
		console.log({ error });
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	} finally {
		yield put({
			type: actions.SET_LOADING,
			payload: {
				orders: false
			}
		});
	}
}

export function* CREATE_SELF_FULFILLMENT_SMART_CONTRACT(action) {
	yield put({
		type: actions.SET_LOADING,
		payload: {
			orders: true
		}
	});

	try {
		const result = yield call(EscrowManagingApi.createSelfFulfillmentSmartContract, action.payload);

		if (result) {
			yield put({
				type: actions.GET_ORDERS
			});
			yield put(triggerNotification('Self-fulfillment order created successfully', 'success'));
		} else {
			yield put(triggerNotification(result.data.message, 'error'));
		}
	} catch (error) {
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	} finally {
		yield put({
			type: actions.SET_LOADING,
			payload: {
				orders: false
			}
		});
	}
}

export function* CREATE_SMART_CONTRACT_WITH_NEW_PARTNER(action) {
	yield put({
		type: actions.SET_LOADING,
		payload: {
			orders: true
		}
	});

	try {
		const result = yield call(EscrowManagingApi.createSmartContractWithNewPartner, action.payload);

		if (result) {
			yield put({
				type: actions.GET_ORDERS
			});
			yield put(triggerNotification('ACP Smart contract created successfully', 'success'));
		}
	} catch (error) {
		console.log({ error });
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	} finally {
		yield put({
			type: actions.SET_LOADING,
			payload: {
				orders: false
			}
		});
	}
}

export function* GET_POF_URL(action) {
	yield put({
		type: actions.SET_LOADING,
		payload: {
			pof: true
		}
	});

	try {
		const result = yield call(EscrowManagingApi.getPofUrl, action.payload);

		const blob = yield call(base64ToBlob, result);
		const blobUrl = URL.createObjectURL(blob);

		if (result) {
			yield put({
				type: actions.SET_STATE,
				payload: {
					pofUrl: blobUrl
				}
			});
		}
	} catch (error) {
		console.log({ error });
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	} finally {
		yield put({
			type: actions.SET_LOADING,
			payload: {
				pof: false
			}
		});
	}
}

export function* UPDATE_CONTRACT_POF(action) {
	yield put({
		type: actions.SET_LOADING,
		payload: {
			orders: true
		}
	});
	try {
		const result = yield call(EscrowManagingApi.updateContractPof, action.payload);

		if (result) {
			yield put({
				type: actions.GET_ORDERS
			});

			const { status } = action.payload;
			const responseMessage = status === 'approved' ? 'Proof of fulfillment approved' : 'Proof of fulfillment rejected';
			const responseType = status === 'approved' ? 'success' : 'error';

			yield put(triggerNotification(responseMessage, responseType));
		}
	} catch (error) {
		console.log({ error });
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	} finally {
		yield put({
			type: actions.SET_LOADING,
			payload: {
				orders: false
			}
		});
	}
}

export function* UPLOAD_FULFILLMENT_PROOF(action) {
	yield put({
		type: actions.SET_LOADING,
		payload: {
			contracts: true
		}
	});
	try {
		const result = yield call(EscrowManagingApi.uploadFulfillmentProof, action.payload);

		if (result) {
			yield put({
				type: actions.GET_ORDERS
			});
			yield put(triggerNotification('Fulfillment proof uploaded successfully', 'success'));
		}
	} catch (error) {
		console.log({ error });
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	} finally {
		yield put({
			type: actions.SET_LOADING,
			payload: {
				contracts: false
			}
		});
	}
}

export function* UPDATE_ORDER_STATUS(action) {
	yield put({
		type: actions.SET_LOADING,
		payload: {
			orders: true
		}
	});

	try {
		const result = yield call(EscrowManagingApi.updateOrderStatus, action.payload);

		if (result) {
			yield put({
				type: actions.GET_ORDERS
			});
			const { status } = action.payload;
			yield put(triggerNotification('Order successfully fulfilled', 'success'));
		}
	} catch (error) {
		console.log({ error });
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	} finally {
		yield put({
			type: actions.SET_LOADING,
			payload: {
				orders: false
			}
		});
	}
}

export function* UPDATE_CONTRACT_STATUS(action) {
	yield put({
		type: actions.SET_LOADING,
		payload: {
			orders: true
		}
	});
	try {
		const result = yield call(EscrowManagingApi.updateContractStatus, action.payload);

		if (result) {
			yield put({
				type: actions.GET_ORDERS
			});

			const { status } = action.payload;

			if (status !== 'fulfilled') {
				const responseMessage = status === 'active' ? 'Contract accepted' : 'Contract rejected';
				const responseType = status === 'active' ? 'success' : 'error';
				yield put(triggerNotification(responseMessage, responseType));
			}
		}
	} catch (error) {
		console.log({ error });
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	} finally {
		yield put({
			type: actions.SET_LOADING,
			payload: {
				orders: false
			}
		});
	}
}

export default function* rootSaga() {
	yield all([takeEvery(actions.GET_AVAILABLE_CLAIMS, GET_AVAILABLE_CLAIMS)]);
	yield all([takeEvery(actions.GET_ORDERS, GET_ORDERS)]);
	yield all([takeEvery(actions.GET_CONTRACTS, GET_CONTRACTS)]);
	yield all([takeEvery(actions.GET_PARTICIPATING_PARTNERS, GET_PARTICIPATING_PARTNERS)]);
	yield all([takeEvery(actions.CREATE_NEW_ORDER, CREATE_NEW_ORDER)]);
	yield all([takeEvery(actions.CREATE_NEW_SMART_CONTRACT, CREATE_NEW_SMART_CONTRACT)]);
	yield all([takeEvery(actions.CREATE_SMART_CONTRACT_WITH_NEW_PARTNER, CREATE_SMART_CONTRACT_WITH_NEW_PARTNER)]);
	yield all([takeEvery(actions.CREATE_SELF_FULFILLMENT_SMART_CONTRACT, CREATE_SELF_FULFILLMENT_SMART_CONTRACT)]);
	yield all([takeEvery(actions.UPDATE_CONTRACT_POF, UPDATE_CONTRACT_POF)]);
	yield all([takeEvery(actions.UPLOAD_FULFILLMENT_PROOF, UPLOAD_FULFILLMENT_PROOF)]);
	yield all([takeEvery(actions.GET_POF_URL, GET_POF_URL)]);
	yield all([takeEvery(actions.UPDATE_CONTRACT_STATUS, UPDATE_CONTRACT_STATUS)]);
	yield all([takeEvery(actions.UPDATE_ORDER_STATUS, UPDATE_ORDER_STATUS)]);
}
