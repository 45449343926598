export const initialState = {};

export const NOTIFICATIONS = "NOTIFICATIONS";

export const initialNotifications = {
  notifications: {
    completeProfile: null,
    notifications: {},
    participant: { walletAddress: undefined }
  }
};

export const notifications = (state = initialState) =>
  state[NOTIFICATIONS] || initialNotifications;

export const getNotifications = (state = initialState) =>
  notifications(state).notifications;

export const getInitialNotifications = () => initialNotifications;
