import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {Grid} from "@material-ui/core";
import {PublicTemplate} from "components";
import {MfaContainer} from "containers";
import mfaStyle from "./style";


const MFAPage = ({...props}) => {
    const { classes } = props;
    return (
        <PublicTemplate>
            <Grid container justify="center" alignItems="center" className={classes.content}>
                <Grid item>
                    <MfaContainer/>
                </Grid>
            </Grid>
        </PublicTemplate>
    );
};

export default withStyles(mfaStyle)(MFAPage);
