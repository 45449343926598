import { API } from "aws-amplify";

const settingsApiService = {
  getPlatformSettings() {
    return API.get("NODE_SERVICE_API", "/settings", {})
      .then(response => response)
      .catch(err => err);
  }
};

export default settingsApiService;
