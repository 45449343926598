import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import notificationStyle from "./style";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  danger: ErrorIcon,
  info: InfoIcon
};

class RenderNotification extends React.Component {
  state = {
    open: this.props.open
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ open: false });
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ open: nextProps.open });
  }

  render() {
    const {
      classes,
      className,
      message,
      onClose,
      variant,
      floating,
      anchorOrigin,
      open,
      ...other
    } = this.props;
    const Icon = variantIcon[variant];

    const content = (
      <SnackbarContent
        className={classNames(classes[variant], className, classes.container)}
        message={
          <div className={classes.message}>
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
            {message}
          </div>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            disableRipple
            className={classes.close}
            onClick={onClose || this.handleClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
        {...other}
      />
    );

    if (floating && this.state.open) {
      return (
        <Snackbar
          anchorOrigin={anchorOrigin}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={onClose || this.handleClose}
        >
          {content}
        </Snackbar>
      );
    }
    if (this.state.open) {
      return content;
    }
    return null;
  }
}

RenderNotification.defaultProps = {
  floating: true,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  }
};

RenderNotification.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  floating: PropTypes.bool,
  anchorOrigin: PropTypes.object,
  variant: PropTypes.oneOf(["success", "warning", "danger", "info"]).isRequired
};

export default withStyles(notificationStyle)(RenderNotification);
