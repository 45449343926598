const techProfileStyles = theme => ({
  root: {
    width: "100%"
  },
  Accordion: {
    marginTop: theme.typography.pxToRem(10)
  },
  AccordionSummary: {
    paddingLeft: 0,
    paddingRIght: 0,
    textAlign: "center",
    alignItems: "center"
  },
  AccordionDetails: {
    paddingLeft: theme.typography.pxToRem(5),
    paddingRIght: theme.typography.pxToRem(5),
    marginBottom: theme.typography.pxToRem(15)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium
  },
  assignedToText: {
    fontSize: theme.typography.pxToRem(10),
    marginBottom: theme.typography.pxToRem(10),
    fontWeight: theme.typography.fontWeightMedium
  },
  chipList: {
    fontSize: theme.typography.pxToRem(10),
    padding: "10px 0"
  },
  chip: {
    fontSize: theme.typography.pxToRem(10),
    padding: "5px 0 !important",
    height: "auto",
    marginRight: 8,
    marginBottom: 8
  }
});
export default techProfileStyles;
