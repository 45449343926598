const badgeStyle = theme => ({
  badge: {
    marginRight: `${theme.spacing(1) / 2}px`,
    whiteSpace: "nowrap",
    verticalAlign: "baseline",
    display: "inline-block",
    letterSpacing: 0.05
  },
  primary: {
    color: theme.palette.primary.main
  },
  secondary: {
    color: theme.palette.secondary.main
  },
  warning: {
    color: theme.palette.warning.dark
  },
  danger: {
    color: theme.palette.danger.main
  },
  success: {
    color: theme.palette.success.main
  },
  grey: {
    color: theme.palette.grey.main
  }
});

export default badgeStyle;
