import React from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";

const RenderMaterialField = ({
  input,
  readOnly,
  label,
  value,
  defaultValue,
  meta: { touched, error }
}) => {
  const hasError =
    typeof error !== typeof undefined && error.length > 0 && touched;
  if (readOnly !== undefined && readOnly) {
    input.readOnly = true;
  }
  return (
    <FormControl error={hasError} fullWidth>
      <TextField
        {...input}
        fullWidth
        label={label}
        helperText={hasError ? error : ""}
        error={hasError}
        className={hasError ? " is-invalid" : "is-valid"}
        InputProps={
          readOnly !== undefined && readOnly
            ? {
                readOnly: true
              }
            : {}
        }
      />
    </FormControl>
  );
};

export default RenderMaterialField;
