import React from "react";
import { PrivateTemplate, BreadCrumb } from "components";
import { FinancingRequestsContainer } from "containers";

const FinancingRequestsPage = props => {
  return (
    <PrivateTemplate>
      <BreadCrumb
        name="Financing requests"
        firstLevel="Home"
        secondLevel="Financing requests"
      />
      <FinancingRequestsContainer />
    </PrivateTemplate>
  );
};

export default FinancingRequestsPage;
