import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default (selectedInvoice, setModalVisible) => {
	//************************* LOCAL STATE VALUES  ***********************/

	const totalAvailable = useSelector(state => state?.wallet?.WALLET_DATA?.walletData?.totalAvailable);
	const isDownloading = useSelector(state => state?.reports?.INVOICE_DOWNLOAD_DATA?.isDownloading);
	const paymentInfo = useSelector(state => state?.reports?.INVOICE_REPORT_DATA?.paymentInfo);

	const amountDue = selectedInvoice?.original?.AMOUNTDUE;
	const isMaster = selectedInvoice?.isMaster;
	const paymentStatus = selectedInvoice?.original?.paymentStatus;

	//****************** HANDLERS **************************//

	const dispatch = useDispatch();

	const handlePayInvoice = () => {
		const { original, subInvoices } = selectedInvoice;
		const { supplier, AMOUNTDUE } = original;
		const { name } = supplier;

		const filterdSubInvoices = subInvoices.map(invoice => ({ _id: invoice.invoice._id, totalPaid: invoice.total }));

		const payload = {
			subInvoices: filterdSubInvoices,
			supplierName: name,
			AMOUNTDUE
		};

		dispatch({ type: 'reports/PAY_INVOICE', payload });
	};

	const handleInvoiceDownload = () => {
		const invId = selectedInvoice?.original?._id;
		const invNb = selectedInvoice?.original?.INV_NUMBER;
		const type = selectedInvoice?.invoiceType;
		const { isStandard } = selectedInvoice;

		dispatch({ type: 'reports/DOWNLOAD_INVOICE', payload: { invoiceId: invId, invoiceNumber: invNb, type, isOrigination: !isStandard } });
	};

	//******************** HANDLE INVOICE PAYMENT FEEDBACK ************/

	useEffect(() => {
		if (paymentInfo?.status === 'success') {
			toast.success(paymentInfo.message, {
				position: 'top-right',
				autoClose: 2000
			});

			setModalVisible(false);
		}

		if (paymentInfo?.status === 'error') {
			toast.error(paymentInfo.message, {
				position: 'top-right',
				autoClose: 2000
			});
		}
	}, [paymentInfo]);

	return { totalAvailable, isDownloading, amountDue, isMaster, paymentStatus, paymentInfo, handlePayInvoice, handleInvoiceDownload };
};
