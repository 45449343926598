import { REPORT_CDR_DATA, REPORT_LOADING } from './actions';

export const initialState = {};

export const initialFilterData = {
	customers: [],
	suppliers: [],
	countries: [],
	destTypes: [],
	destCarriers: [],
	contracts: [],
	filteredContracts: {
		selling: [],
		buying: []
	}
};

// export const REPORT_FILTER_DATA = "REPORT_FILTER_DATA";
//
// export const getFilterData = (state = initialState) =>
//   state[REPORT_FILTER_DATA] || initialFilterData;

//++++++++
export const initialReportData = {
	result: {
		billingIncrement: null,
		carrier: null,
		country: null,
		id: null,
		minutes: null,
		price: null,
		smartContract: null,
		total: '0.0',
		type: null
	}
};

export const initialData = {};

export const REPORTS_FILTERS = 'REPORTS_FILTERS';
export const REPORT_DATA = 'REPORT_DATA';

export const SET_CREATE_REPORT = 'SET_CREATE_REPORT';
export const INVOICE_REPORT_DATA = 'INVOICE_REPORT_DATA';
export const MONTHLY_INVOICE_REPORT_DATA = 'MONTHLY_INVOICE_REPORT_DATA';

export const INVOICE_DOWNLOAD_DATA = 'INVOICE_DOWNLOAD_DATA';

export const getReportData = (state = initialState) => state[SET_CREATE_REPORT] || initialReportData;
export const getReportLoading = (state = initialState) => state[REPORT_LOADING] || false;

const invoicesInitialState = {
	customer: {
		origination: {
			loading: false,
			data: []
		},
		standard: {
			loading: false,
			data: []
		},
		invoicesCached: false
	},
	supplier: {
		origination: {
			loading: false,
			data: []
		},
		standard: {
			loading: false,
			data: []
		},
		invoicesCached: false
	},
	paymentInfo: {
		status: '',
		message: ''
	}
};

const monthlyInvoicesInitialState = {
	customer: {
		loading: false,
		data: []
	},
	supplier: {
		loading: false,
		data: []
	},
	monthlyInvoicesCached: false
};

const initialExternalInvoicesData = {
	loading: false,
	data: [],
	externalInvoicesCached: false
};

const initialInvoiceDownloadState = {
	isDownloading: false
};

export const getInvoiceDownloadState = (state = initialInvoiceDownloadState) => state[INVOICE_DOWNLOAD_DATA] || initialInvoiceDownloadState;

export const getInvoices = (state = initialState) => state[INVOICE_REPORT_DATA] || invoicesInitialState;

export const getMonthlyInvoices = (state = initialState) => state[MONTHLY_INVOICE_REPORT_DATA] || monthlyInvoicesInitialState;

export const getReportsFilters = (state = initialFilterData) => state[REPORTS_FILTERS] || initialFilterData;

export const getReportsData = (state = initialData) => state[REPORT_DATA] || initialData;

export const getCDRReportsData = (state = initialData) => state[REPORT_CDR_DATA] || initialData;

export const EXTERNAL_INVOICE_REPORT_DATA = 'EXTERNAL_INVOICE_REPORT_DATA';
export const getExternalInvoices = (state = initialExternalInvoicesData) => state[EXTERNAL_INVOICE_REPORT_DATA] || initialExternalInvoicesData;
