const registerStyle = theme => ({
	root: {
		overflow: 'visible'
	},
	cardHeader: {
		textAlign: 'center',
		marginLeft: '20px',
		marginRight: '20px',
		marginBottom: '-20px',
		borderRadius: '3px',
		padding: '1rem 15px',
		top: '-40px',
		position: 'relative',
		backgroundColor: theme.palette.light,
		border: `1px solid ${theme.palette.lightGrey.main}`
	},
	headerText: {
		marginBottom: '10px'
	},
	form: {
		maxWidth: '400px'
	},
	appBar: {
		position: 'relative'
	},
	flex: {
		flex: 1
	},
	paper: {
		padding: '50px',
		maxWidth: '1200px',
		margin: 'auto'
	},
	tosTitle: {
		marginTop: '40px',
		marginBottom: '40px'
	}
});

export default registerStyle;
