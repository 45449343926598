import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const RenderFormikInput = ({ formik: { values, errors, touched, setFieldValue, handleBlur }, name, peek, onChange, ...rest }) => {
	const [showPassword, setShowPassword] = useState(false);

	const toggleShowHidePassword = useCallback(() => setShowPassword(state => !state), []);

	let hasError = (errors[name] && touched[name] && true) || false;
	let errorMsg = (hasError && !!errors[name] && errors[name]) || '';
	const InputProps = rest.InputProps || {};

	if (peek && rest.type === 'password') {
		InputProps.endAdornment = (
			<InputAdornment position="end">
				<IconButton aria-label="Toggle password visibility" onClick={toggleShowHidePassword} onMouseDown={toggleShowHidePassword}>
					{showPassword ? <VisibilityOff /> : <Visibility />}
				</IconButton>
			</InputAdornment>
		);
	}

	let value = values[name] || '';
	if (name.indexOf('.') > -1) {
		value = name.split('.').reduce((o, i) => (o && o[i]) || '', values);
		const error = name.split('.').reduce((o, i) => (o && o[i]) || '', errors);
		const touchedEl = name.split('.').reduce((o, i) => (o && o[i]) || '', touched);
		hasError = (error && touchedEl && true) || false;
		errorMsg = (hasError && !!error && error) || '';
	}
	return (
		<TextField
			fullWidth
			value={value}
			name={name}
			onChange={e => {
				setFieldValue(name, e.target.value);
				if (onChange && typeof onChange === 'function') onChange(e);
			}}
			onBlur={handleBlur}
			error={hasError}
			helperText={errorMsg}
			{...rest}
			InputProps={InputProps}
			type={(peek && ((showPassword && 'Text') || rest.type)) || rest.type || null}
		/>
	);
};

RenderFormikInput.defaultProps = {
	peek: false
};

RenderFormikInput.propTypes = {
	formik: PropTypes.object.isRequired,
	name: PropTypes.string.isRequired,
	peek: PropTypes.bool
};

export default RenderFormikInput;
