import React from "react";
import { Paper, CardContent } from "@material-ui/core";
import { RenderTable, CurrencyNumber } from "components";

const ReportMargin = props => {
  const { tableData } = props;

  const columns = [
    {
      Header: "Client",
      accessor: "carrier"
    },
    {
      Header: "Total minutes",
      accessor: "totalMinutes",
      headerClassName: "rt-text-right",
      className: "rt-text-right",
      Cell: data => parseFloat(data.value).toFixed(2),
      Footer: table => {
        let total = 0;
        table.data.map(obj => {
          total += parseFloat(obj.totalMinutes);
        });
        return <strong>{total.toFixed(2)}</strong>;
      }
    },
    {
      Header: "Total sale",
      headerClassName: "rt-text-right",
      className: "rt-text-right",
      accessor: "totalSale",
      Cell: props => (
        <CurrencyNumber amount={parseFloat(props.value).toFixed(6)} precision={6} />
      ),
      Footer: table => {
        let total = 0;
        table.data.map(obj => {
          total += parseFloat(obj.totalSale);
        });
        return (
          <strong>
            <CurrencyNumber amount={total.toFixed(6)} precision={6} />
          </strong>
        );
      }
    },
    {
      Header: "Total cost of sale",
      headerClassName: "rt-text-right",
      className: "rt-text-right",
      accessor: "totalCostOfSale",
      Cell: props => (
        <CurrencyNumber amount={parseFloat(props.value).toFixed(6)} precision={6} />
      ),
      Footer: table => {
        let total = 0;
        table.data.map(obj => {
          total += parseFloat(obj.totalCostOfSale);
        });
        return (
          <strong>
            <CurrencyNumber amount={total.toFixed(6)} precision={6} />
          </strong>
        );
      }
    },
    {
      Header: "TessPay fees",
      headerClassName: "rt-text-right",
      className: "rt-text-right",
      accessor: "tesspayFees",
      Cell: props => (
        <CurrencyNumber amount={parseFloat(props.value).toFixed(2)} />
      ),
      Footer: table => {
        let total = 0;
        table.data.map(obj => {
          total += parseFloat(obj.tesspayFees);
        });
        return (
          <strong>
            <CurrencyNumber amount={total.toFixed(2)} />
          </strong>
        );
      }
    },
    {
      Header: "Gross profit",
      headerClassName: "rt-text-right",
      className: "rt-text-right",
      accessor: "totalGrossProfit",
      Cell: props => (
        <CurrencyNumber amount={parseFloat(props.value).toFixed(6)} precision={6} />
      ),
      Footer: table => {
        let total = 0;
        table.data.map(obj => {
          total += parseFloat(obj.totalGrossProfit);
        });
        return (
          <strong>
            <CurrencyNumber amount={total.toFixed(6)} precision={6} />
          </strong>
        );
      }
    },
    {
      Header: "Gross Margin %",
      headerClassName: "rt-text-right",
      className: "rt-text-right",
      accessor: "totalGrossMarginPct",
      Cell: data => `${parseFloat(data.value).toFixed(2)}%`,
      Footer: table => {
        let total = 0;
        table.data.map(obj => {
          total += parseFloat(obj.totalGrossMarginPct);
        });
        return <strong>{total.toFixed(2)}%</strong>;
      }
    }
  ];

  return (
    <RenderTable
      data={tableData}
      columns={columns}
      filterable={false}
      style={{ maxHeight: 500 }}
      defaultSortDesc
      defaultSorted={["totalSale"]}
    />
  );
};

export default ReportMargin;
