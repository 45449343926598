import { AppBar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Drawer, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import Loader from 'components/atoms/Loader';
import RenderButton from 'components/atoms/RenderButton';
import RenderFormikInput from 'components/atoms/RenderFormikInput';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import Add from '@material-ui/icons/Add';
import RenderFormikDatePicker from 'components/atoms/RenderFormikDatePicker';
import RenderFormikSelect from 'components/atoms/RenderFormikSelect';
import { use } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { capitalizeWord, colorStatusText, handleCurrency } from 'utils';
import RenderTable from 'components/atoms/RenderTable';
import { Cancel, ThumbDown, ThumbUp } from '@material-ui/icons';
import { columns } from './columns';
import { useModal } from './hooks/useModal';
import { ReviewDrawer } from './ReviewDrawer';

export default memo(({ modalOpened, handleToggleModal, openedSmartContract, openedOrder }) => {
	const { smartContractRef, partner, dueDate, cost, scQty, status, pof, scPofTotalFulfilled, scTotalSelfFullfilled, _id } = openedSmartContract || {};
	const { itemsUnderReview, selectedInvoice, setSelectedInvoice, handleStatusChange, handleUpdatePof, loading } = useModal({ openedSmartContract, openedOrder });

	return (
		<>
			<Dialog open={modalOpened} className={styles.modal} onClose={handleToggleModal} maxWidth="xl">
				{loading.orders && <Loader fullscreen />}
				<DialogTitle>Smart Contract Details</DialogTitle>
				<DialogContent className="content">
					<div className="top-section">
						<div className="proposal-info-container">
							<div className="row">
								<Typography className="label">Partner</Typography>
								<Typography className="dark-text">{partner?.participantName}</Typography>
							</div>
							<div className="row">
								<Typography className="label">Order ID</Typography>
								<Typography className="dark-text">{openedOrder?.orderName}</Typography>
							</div>
							<div className="row">
								<Typography className="label">Smart Contract</Typography>
								<Typography className="dark-text">{smartContractRef}</Typography>
							</div>
						</div>
						<div className="proposal-info-container">
							<div className="row">
								<Typography className="label">Due Date</Typography>
								<Typography className="dark-text">{moment(dueDate).format('MMM Do YY')}</Typography>
							</div>
							<div className="row">
								<Typography className="label">Unit Cost</Typography>
								<Typography className="dark-text">{handleCurrency(cost, 'USD')}</Typography>
							</div>
						</div>
					</div>

					<Typography className="status" variant="h1" style={colorStatusText(status)}>
						{status?.toUpperCase().replace(/-/g, ' ') || ''}
					</Typography>
					<div className="statistics">
						<div className="centered">
							<Typography variant="caption" className="label">
								Escrow balance
							</Typography>
							<Typography variant="h6">
								{handleCurrency((openedOrder?.itemPrice * (scQty - scPofTotalFulfilled) * openedOrder?.finance?.coverage) / 100, 'USD')}
							</Typography>
						</div>
						<span className="divider" />
						<div className="centered">
							<Typography variant="caption" className="label">
								Quantity
							</Typography>
							<Typography>{scQty}</Typography>
						</div>
						<span className="divider" />
						<div className="centered">
							<Typography variant="caption" className="label">
								Delivered Items
							</Typography>
							<Typography className="large-text">{scPofTotalFulfilled}</Typography>
							<Typography variant="caption" className="label">
								{scQty - scPofTotalFulfilled} Remaining
							</Typography>
						</div>
						<span className="divider" />
						<div className="centered">
							<Typography variant="caption" className="label">
								Items Under Review
							</Typography>
							<Typography className="large-text">{itemsUnderReview}</Typography>
							<Typography variant="caption" className="label">
								{handleCurrency(itemsUnderReview * cost, 'USD')}
							</Typography>
						</div>
					</div>

					{status === 'waiting-for-partner-confirmation' && (
						<div className="space-top">
							<RenderButton className="button" color="danger" startIcon={<Cancel />} onClick={() => handleStatusChange(_id, 'rejected')}>
								Cancel Smart Contract
							</RenderButton>
						</div>
					)}
					{openedSmartContract?.status !== 'rejected' && (
						<>
							<Divider className="divider" />
							<Typography variant="h1" className="label">
								Invoices
							</Typography>
							<RenderTable
								className="space-top"
								data={pof || []}
								columns={columns(setSelectedInvoice, openedOrder)}
								showPagination={false}
								sortable={false}
								minRows={2}
								noDataText={
									<div>
										<strong>There are no proof of delivery yet</strong>
									</div>
								}
							/>
						</>
					)}
				</DialogContent>
				<DialogActions>
					<RenderButton onClick={handleToggleModal}>Close</RenderButton>
				</DialogActions>
			</Dialog>
			<ReviewDrawer selectedInvoice={selectedInvoice} setSelectedInvoice={setSelectedInvoice} handleUpdatePof={handleUpdatePof} />
		</>
	);
});
