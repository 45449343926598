import { API } from 'aws-amplify';

const apiWallet = {
	getListWallet(data) {
		// return API.get("WALLET_API", "/getIndex")
		return API.get('NODE_SERVICE_API', '/wallet')
			.then(response => response)
			.catch(err => err);
	},
	sendTopUp(data) {
		const myInit = {
			body: { amount: data.amountTopUp, paymentRef: data.paymentRef }
		};
		// return API.post("WALLET_API", "/topup", myInit)
		return API.post('NODE_SERVICE_API', '/wallet/topup', myInit)
			.then(response => response)
			.catch(err => err);
	},
	sendWithDraw(data) {
		const request = {
			body: data
		};
		return API.post('NODE_SERVICE_API', '/wallet/withdraw', request)
			.then(response => response)
			.catch(err => err);
	},
	downloadReport(data, format) {
		const request = {
			body: data
		};
		return API.post('NODE_SERVICE_API', `/wallet/report/download/${format}`, request)
			.then(response => response)
			.catch(err => err);
	},
	getTransactions(params) {
		console.log('paramsparams', params);
		return API.get('NODE_SERVICE_API', `/wallet/transactions`, { queryStringParameters: params })
			.then(response => response)
			.catch(err => err);
	},
	exportTransactions(params) {
		return API.get('NODE_SERVICE_API', `/wallet/transactions/export`, { queryStringParameters: params, responseType: 'blob' })
			.then(response => response)
			.catch(err => err);
	},

	/******* NEW ENDPOINTS  ***********/

	newGetListWallet(data) {
		return API.get('FINANCIALS_SERVICE_API', '/wallet')
			.then(response => response)
			.catch(err => err);
	},
	newSendTopUp(data) {
		const myInit = {
			body: { amount: data.amountTopUp, paymentRef: data.paymentRef }
		};

		return API.post('FINANCIALS_SERVICE_API', '/wallet/deposit', myInit)
			.then(response => response)
			.catch(err => err);
	},
	newSendWithDraw(data) {
		const request = {
			body: data
		};
		return API.post('FINANCIALS_SERVICE_API', '/wallet/withdraw', request)
			.then(response => response)
			.catch(err => err);
	},
	newDownloadReport(data) {
		const request = {
			body: { ...data }
		};

		return API.post('FINANCIALS_SERVICE_API', `/wallet/report/download`, request)
			.then(response => response)
			.catch(err => err);
	},
	newGetTransactions(params) {
		return API.get('FINANCIALS_SERVICE_API', `/wallet/transactions`, { queryStringParameters: params.payload })
			.then(response => response)
			.catch(err => err);
	},
	newExportTransactions(params) {
		return API.get('FINANCIALS_SERVICE_API', `/wallet/transactions/export`, { queryStringParameters: params, responseType: 'blob' })
			.then(response => response)
			.catch(err => err);
	},

	getTransactionsFilters() {
		return API.get('NODE_SERVICE_API', '/wallet/filters')
			.then(response => response)
			.catch(err => err);
	}
};

export default apiWallet;
