import React from "react";
import { RenderTable, CurrencyNumber } from "components";

const ReportLending = props => {
  const { tableData } = props;

  const columns = [
    {
      Header: "Client",
      accessor: "carrier"
    },
    {
      Header: "Lender fees",
      headerClassName: "rt-text-right",
      className: "rt-text-right",
      accessor: "lenderFees",
      Cell: props => (
        <CurrencyNumber amount={parseFloat(props.value).toFixed(6)} precision={6} />
      ),
      Footer: table => {
        let total = 0;
        table.data.map(obj => {
          total += parseFloat(obj.lenderFees);
        });
        return (
          <strong>
            <CurrencyNumber amount={total.toFixed(6)} precision={6} />
          </strong>
        );
      }
    },
    {
      Header: "Lender interest",
      headerClassName: "rt-text-right",
      className: "rt-text-right",
      accessor: "lenderInterest",
      Cell: data => `${parseFloat(data.value).toFixed(2)}% pm`
    },
    {
      Header: "Financed Amount",
      headerClassName: "rt-text-right",
      className: "rt-text-right",
      accessor: "loanedAmount",
      Cell: props => (
        <CurrencyNumber amount={parseFloat(props.value).toFixed(6)} precision={6} />
      )
    },
    {
      Header: "Net profit",
      headerClassName: "rt-text-right",
      className: "rt-text-right",
      accessor: "netProfit",
      Cell: props => (
        <CurrencyNumber amount={parseFloat(props.value).toFixed(6)} precision={6} />
      ),
      Footer: table => {
        let total = 0;
        table.data.map(obj => {
          total += parseFloat(obj.netProfit);
        });
        return (
          <strong>
            <CurrencyNumber amount={total.toFixed(6)} precision={6} />
          </strong>
        );
      }
    },
    {
      Header: "Margin (net)",
      headerClassName: "rt-text-right",
      className: "rt-text-right",
      accessor: "margin",
      Cell: data => `${parseFloat(data.value).toFixed(2)}%`
    }
  ];

  return (
    <RenderTable
      data={tableData}
      columns={columns}
      filterable={false}
      style={{ maxHeight: 500 }}
      defaultSortDesc
      defaultSorted={["totalSale"]}
    />
  );
};

export default ReportLending;
