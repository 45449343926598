// eslint-disable-next-line import/no-named-as-default
import actions from './actions';

const initialState = {
	loading: false,
	loadingFile: false,
	updatingFile: false,
	acpClaims: [],
	acpInvoices: [],

	// NLAD Submissions
	nladSubmissions: [],
	nladSubmissionsArchived: [],
	nladSubmissionsCurrentMonth: [],
	nladSubmissionsLastMonth: [],

	proFormaClaims: [],
	proFormaClaimsParticipantInfo: {},
	newProFormaClaims: [],
	payments: [],
	statements: [],
	invoice: {},
	archiveSettings: {}
};

export default function(state = initialState, action) {
	switch (action.type) {
		case actions.SET_STATE:
			return { ...state, ...action.payload };
		default:
			return state;
	}
}
