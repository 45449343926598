import React, { Component } from "react";
import { connect } from "react-redux";
import { BiddingPortal } from "components";
import {
  CreateOrderFormContainer,
  CancelOrderFormContainer,
  AcceptOrderFormContainer,
} from "containers";
import { initializeBiddingPortal, setResultProcessOrder, actionCreatorSelectItem } from "store/actions";
import { fromBiddingportal, fromUseraccount } from "store/selectors";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

class BiddingPortalContainer extends Component {
  constructor(props) {
    super(props);
    this.hideOpenOrder = this.hideOpenOrder.bind(this);
    this.addOpenOrder = this.addOpenOrder.bind(this);
    this.hideCancelOpenOrder = this.hideCancelOpenOrder.bind(this);
    this.cancelOpenOrder = this.cancelOpenOrder.bind(this);
    this.processCancelOpenOrder = this.processCancelOpenOrder.bind(this);
    this.hideAcceptOpenOrder = this.hideAcceptOpenOrder.bind(this);
    this.processAddOpenOrder = this.processAddOpenOrder.bind(this);
    this.processAcceptOpenOrder = this.processAcceptOpenOrder.bind(this);
    this.fnToaStr = this.fnToaStr.bind(this);
    this.toast = toast;
    this.state = {
      createOrderForm: false,
      cancelOrderForm: false,
      acceptOrderForm: false,
      idOpenOrder: null,
      typeAccept:false
    };
  }

  componentDidMount() {
    this.props.initializeBiddingPortal("userId");
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.resultProcessOrder !== nextProps.resultProcessOrder) {
      if (nextProps.resultProcessOrder) {
        this.fnToaStr(nextProps.resultProcessOrder);
        this.props.setResultProcessOrder(undefined);
      }
    }
  }

  fnToaStr(data) {
    if (data.status === "success") {
      this.toast.success(data.message, {
        position: "top-right",
        autoClose: 2000,
      });
    } else {
      this.toast.error(data.message, {
        position: "top-right",
        autoClose: 2000,
      });
    }
  }

  hideOpenOrder() {
    this.setState({ createOrderForm: false });
  }

  hideCancelOpenOrder() {
    this.setState({ cancelOrderForm: false, idOpenOrder: null });
  }

  hideAcceptOpenOrder() {
    this.setState({ acceptOrderForm: false, idOpenOrder: null });
  }

  addOpenOrder() {
    this.hideOpenOrder();
  }

  cancelOpenOrder() {
    this.hideCancelOpenOrder();
  }

  processCancelOpenOrder(data) {
    this.setState({ cancelOrderForm: true, idOpenOrder: data });
  }

  processAcceptOpenOrder(data, type) {
    if (this.props.user.userInfo.roles[0] === CONFIG_PORTAL.acountTypeFunder) {
      this.setState({ acceptOrderForm: true, idOpenOrder: data, typeAccept:type === "Funder" ? true : false });
    }
  }

  processAddOpenOrder() {
    this.setState({ createOrderForm: true });
  }

  render() {
    return (
      <div>
        <ToastContainer />
        <BiddingPortal
          user={this.props.user.userInfo}
          dataBiddingPortal={this.props.dataBiddingPortal}
          processAcceptOpenOrder={this.processAcceptOpenOrder}
          processCancelOpenOrder={this.processCancelOpenOrder}
          processAddOpenOrder={this.processAddOpenOrder}
          actionCreatorSelectItem={this.props.actionCreatorSelectItem}
        />
        {this.state.createOrderForm ? (
          <CreateOrderFormContainer
            hideOpenOrder={this.hideOpenOrder}
            createOrderForm={this.state.createOrderForm}
          />
        ) : null}

        <CancelOrderFormContainer
          cancelOrderForm={this.state.cancelOrderForm}
          hideCancelOpenOrder={this.hideCancelOpenOrder}
          cancelOpenOrder={this.cancelOpenOrder}
          idOpenOrder={this.state.idOpenOrder}
        />
        {this.state.acceptOrderForm ? (
          <AcceptOrderFormContainer
            acceptOrderForm={this.state.acceptOrderForm}
            hideAcceptOpenOrder={this.hideAcceptOpenOrder}
            acceptOpenOrder={this.acceptOpenOrder}
            idOpenOrder={this.state.idOpenOrder}
            typeAccept={this.state.typeAccept}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: fromUseraccount.getUserInfoDataState(state),
  dataBiddingPortal: fromBiddingportal.getInitializeBiddingPortal(state),
  resultProcessOrder: fromBiddingportal.getResultProcessOrder(state),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      initializeBiddingPortal,
      setResultProcessOrder,
      actionCreatorSelectItem
    },
    dispatch,
  );
}

BiddingPortalContainer.propTypes = {
  initializeBiddingPortal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  dataBiddingPortal: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BiddingPortalContainer);
