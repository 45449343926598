import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RoutesEdit } from "components";

const RoutesEditContainer = memo(
  ({
    match: {
      params: { smartContractId }
    }
  }) => {
    const dispatch = useDispatch();

    const details = useSelector( state => (state.routes && state.routes.details) || false);

    useEffect(() => {
      dispatch({
        type: "routes/GET_ROUTE_DETAILS",
        payload: smartContractId
      });

      return () => {
        dispatch({
          type: "routes/SET_STATE",
          payload: {
            details: {}
          }
        });
      };
    }, []);
    return <RoutesEdit
      contractDetails={details.contract || {}}
    />;
  }
);

export default RoutesEditContainer;
