export const initialState = {};

export const initialUrlRedirect = {
  url: null,
  userId: null
};
export const URL_REDIRECT = "URL_REDIRECT";

export const getUrlRedirectState = (state = initialState) =>
  state[URL_REDIRECT] || initialUrlRedirect;
