import React, { memo, useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { RenderFormikInput, RenderFormikSelect, RenderButton, RenderFormikSwitch } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';

const GeneralTab = memo(({ formik }) => {
	const dispatch = useDispatch();

	const hardwareProfileOptions = useSelector(state => (state.routes && state.routes.hardwareProfileOptions) || {});

	const triggerRemoveIPAddress = useCallback(
		index => {
			formik.setFieldValue(
				'ipAddresses',
				formik.values.ipAddresses.filter((ip, i) => i !== index)
			);
		},
		[JSON.stringify(formik.values.ipAddresses)]
	);

	const triggerAddIPAddress = useCallback(() => {
		formik.values.ipAddresses.push({
			ip: '',
			netmask: '32',
			port: '5060'
		});
		formik.setFieldValue('ipAddresses', formik.values.ipAddresses);
	}, [JSON.stringify(formik.values.ipAddresses)]);

	useEffect(() => {
		dispatch({ type: 'routes/GET_HARDWARE_PROFILE_OPTIONS' });
	}, []);

	return (
		<div className={styles.container}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<RenderFormikInput label="Profile Name" name="name" formik={formik} />
				</Grid>
				<Grid item xs={12} md={3}>
					<RenderFormikInput label="Tech prefix" name="techPrefix" formik={formik} />
				</Grid>
				<Grid item xs={12}>
					{/* <FieldArray component={TechnicalInformationIpAddresses} name="ipAddresses" /> */}
				</Grid>
				<Grid item xs={12} md={2}>
					<RenderFormikSelect
						disabled
						formik={formik}
						label="VoIP Protocol"
						name="voipProtocolId"
						options={(hardwareProfileOptions && hardwareProfileOptions.voipProtocols) || []}
					/>
				</Grid>
				<Grid item xs={12} md={8}>
					<RenderFormikSelect disabled formik={formik} label="Codecs" name="codecs" multiple options={(hardwareProfileOptions && hardwareProfileOptions.codecs) || []} />
				</Grid>
				<Grid item xs={12} md={1}>
					<RenderFormikSelect disabled formik={formik} label="DTMF" name="dtmf" options={(hardwareProfileOptions && hardwareProfileOptions.dtmfOptions) || []} />
				</Grid>
				<Grid item xs={12} md={1}>
					<RenderFormikInput disabled label="Dial String Format" name="dialStringFormat" formik={formik} />
				</Grid>

				<Grid item xs={12} md={3}>
					<RenderFormikSelect
						disabled
						formik={formik}
						name="ignoreEarlyMedia"
						label="Ignore Early Media"
						options={(hardwareProfileOptions && hardwareProfileOptions.ignoreEarlyMedia) || []}
					/>
				</Grid>
				<Grid item xs={12} md={2}>
					<RenderFormikInput label="CPS Limit" name="cpsLimit" formik={formik} />
				</Grid>
				<Grid item xs={12} md={2}>
					<RenderFormikInput label="PDD Timeout (s)" name="pddTimeout" formik={formik} />
				</Grid>
				<Grid item xs={12} md={2}>
					<RenderFormikInput label="Max Duration (s)" name="maxDuration" formik={formik} />
				</Grid>
				<Grid item xs={12} md={2}>
					<RenderFormikInput label="Ring Timer (s)" name="ringTimer" formik={formik} />
				</Grid>

				<Grid item xs={12} md={12}>
					{formik.values.ipAddresses.map((ipaddress, i) => (
						<div className="ip-address-wrap" key={i}>
							<Grid container spacing={2}>
								<Grid item xs={4}>
									<RenderFormikInput label="IP Address" name={`ipAddresses.${i}.ip`} formik={formik} />
								</Grid>
								<Grid item xs={2}>
									<RenderFormikInput type="number" label="Netmask" name={`ipAddresses.${i}.netmask`} formik={formik} />
								</Grid>
								<Grid item xs={2}>
									<RenderFormikInput type="number" label="Port" name={`ipAddresses.${i}.port`} formik={formik} />
								</Grid>
								<Grid item xs={4}>
									{(i > 0 && (
										<RenderButton color="danger" onClick={() => triggerRemoveIPAddress(i)}>
											Delete
										</RenderButton>
									)) ||
										''}
									{(i < 1 && (
										<RenderButton color="primary" onClick={() => triggerAddIPAddress(i)}>
											Add IP Address
										</RenderButton>
									)) ||
										''}
								</Grid>
							</Grid>
						</div>
					))}
				</Grid>

				<Grid item xs={12}>
					<Grid container>
						<Grid item>
							<RenderFormikSwitch label="Active" name="active" formik={formik} />
						</Grid>
						<Grid item>
							<RenderFormikSwitch label="T.38" name="t38" formik={formik} />
						</Grid>
						<Grid item>
							<RenderFormikSwitch label="Ignore Early NOSDP" name="ignoreEarlyNOSDP" formik={formik} />
						</Grid>
						<Grid item>
							<RenderFormikSwitch label="Proxy Media" name="proxyMedia" formik={formik} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
});

export default GeneralTab;
