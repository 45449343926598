import React, { memo, useCallback, useState } from 'react';
import { AppBar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, Switch, Tab, Tabs, TextField } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import { SmartContractsTab, OrdersTab, AvailableClaimsModal } from './components';
import { RenderButton } from 'components';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';

export default memo(() => {
	const [selectedTab, setSelectedTab] = useState('orders');
	const [needFinancing, setNeedFinancing] = useState(false);
	const [modalOpened, setModalOpened] = useState(false);

	const userRole = useSelector(state => (state.myaccount && state.myaccount.accountData.role) || false);

	const toggleModal = useCallback(() => {
		setModalOpened(!modalOpened);
	}, [modalOpened]);

	return (
		<div className={styles.container}>
			<AvailableClaimsModal modalOpened={modalOpened} toggleModal={toggleModal} />
			<>
				{/* TODO:ANDRES / THIS SECTION MIGHT BE USED IN THE FUTURE <AppBar position="static">
					<Tabs value={selectedTab} onChange={(_, value) => setSelectedTab(value)}>
						<Tab value="orders" label="Orders" />
						<Tab value="smartContracts" label="Smart Contracts" />
					</Tabs>
				</AppBar>
				{selectedTab === 'orders' && (
					<Grid container spacing={2}>
						<Grid item xs={9} />
						<Grid item xs={3} justifyContent="flex-end" className="display-flex">
							<RenderButton className="create-order-button" color="primary" onClick={toggleModal} startIcon={<Add />}>
								New order
							</RenderButton>
						</Grid>
					</Grid>
				)}

				<div>
					{(selectedTab === 'orders' && <OrdersTab />) || ''}
					{(selectedTab === 'smartContracts' && <SmartContractsTab />) || ''}
				</div> */}

				{userRole === 'acp-device-provider' ? (
					<SmartContractsTab />
				) : (
					<>
						{/* <Grid container spacing={2}>
							<Grid item xs={9} />
							<Grid item xs={3} justifyContent="flex-end" className="display-flex">
								<RenderButton className="create-order-button" color="primary" onClick={toggleModal} startIcon={<Add />}>
									New order
								</RenderButton>
							</Grid>
						</Grid> */}
						<OrdersTab />
					</>
				)}
			</>
		</div>
	);
});
