import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationsBox, ProfileBox } from 'components';
import { redirectAccount, actionCreatorSelectItem } from 'store/actions';
import { Typography } from '@material-ui/core';

export default memo(() => {
	const dispatch = useDispatch();
	const notifications = useSelector(
		state => (state.notifications.NOTIFICATIONS && state.notifications.NOTIFICATIONS.notifications && state.notifications.NOTIFICATIONS.notifications.notifications) || []
	);

	const triggerSelectNotification = useCallback(data => {
		dispatch(actionCreatorSelectItem(data));
	}, []);

	const triggerRedirect = useCallback(data => {
		dispatch(redirectAccount(data));
	}, []);

	useEffect(() => {
		dispatch({ type: 'GET_NOTIFICATIONS' });
	}, []);

	return (
		<span
			style={{
				display: 'flex',
				flexGrow: 1,
				alignItems: 'center',
				justifyContent: 'flex-end'
			}}
		>
			{notifications.completeProfile === false && (
				<Typography
					style={{
						flexGrow: 1,
						cursor: 'pointer',
						color: '#FE2712',
						fontWeight: '600'
					}}
					onClick={triggerRedirect}
				>
					Please complete your account setup
				</Typography>
			)}

			<NotificationsBox notifications={notifications} actionCreatorSelectItem={triggerSelectNotification} />
			<ProfileBox />
		</span>
	);
});
