import { API } from 'aws-amplify';
import axios from 'axios';


const NotificationsApi = {
	getNotifications(data) {
		return API.get('NODE_SERVICE_API', '/notification/getNotifications')
			.then(response => response)
			.catch(err => err);
	}
};

export default NotificationsApi;
