import {initialRegisterData, getRegisterDataState, REGISTER_DATA} from './selectors'
import {
    REGISTER_REQUEST,
    REGISTER_FAILURE,
    REGISTER_SUCCESS,
    CLEAN_DATA_REGISTER
} from './actions'

export default (state = initialRegisterData, {type, payload, meta}) => {
    switch (type) {
        case REGISTER_REQUEST:
            return {
                ...state,
                [REGISTER_DATA]: {
                    ...getRegisterDataState(state),
                    ...payload
                }
            };
        case REGISTER_FAILURE:
            return {
                ...state,
                [REGISTER_DATA]: {
                    errorMessage: payload,
                    loginSuccess: false
                }
            };
        case REGISTER_SUCCESS:
            return {
                ...state,
                [REGISTER_DATA]: {
                    ...getRegisterDataState(state),
                    errorMessage: payload,
                    loginSuccess: true
                }
            };
        case CLEAN_DATA_REGISTER:
            return {
                ...state,
                [REGISTER_DATA]: {
                    ...initialRegisterData
                }
            };
        default:
            return state
    }
}
