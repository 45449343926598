import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const styles = {
	summary: {
		width: '100%'
	},
	summaryColLeft: {
		display: 'inline-block',
		float: 'left'
	},
	summaryColRight: {
		display: 'inline-block',
		float: 'right'
	}
};

const RatePlansAccordion = props => {
	const { t } = useTranslation(['smartcontracts/create', 'translations']);

	const { classes, ratePlans, handleDownloadRatePlan } = props;
	const [expanded, setExpanded] = useState(null);
	return (
		<div>
			{(ratePlans &&
				ratePlans.map((ratePlan, idx) => (
					<Accordion key={idx} expanded={expanded === `panel${idx}`} onChange={() => setExpanded(state => state !== `panel${idx}` && `panel${idx}` || null)}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<div className={classes.summary}>
								<Typography className={classes.summaryColLeft} alig="left">
									{t('rateplan_uploaded_at', {
										rateplan: ratePlan.originalFileName,
										date: moment(ratePlan.createdAt).format('MMM DD YYYY HH:mm')
									})}
								</Typography>
								<Typography variant="caption" className={classes.summaryColRight} align="right">
									{ratePlan.billingSystemStatus || ''}
								</Typography>
							</div>
						</AccordionSummary>
						<AccordionDetails>
							<Button onClick={() => handleDownloadRatePlan(ratePlan.smartContractId, ratePlan._id || ratePlan.id, ratePlan.originalFileName)}>
								{t('translations:download')}
							</Button>
						</AccordionDetails>
					</Accordion>
				))) ||
				''}
		</div>
	);
};

RatePlansAccordion.propTypes = {
	ratePlans: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	handleDownloadRatePlan: PropTypes.func.isRequired,
	classes: PropTypes.shape().isRequired
};

export default withStyles(styles)(RatePlansAccordion);
