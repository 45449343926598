import React, { useState, useEffect } from 'react';
import Joyride from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { LightbulbOutline } from '@material-ui/icons';

import { PrivateTemplate, BreadCrumb } from 'components';
import Blinker from 'components/atoms/Blinker';
import AcpProgramScreen from 'screens/AcpProgram';
import { Button } from '@material-ui/core';

const AcpProgramPage = props => {
	const [run, setRun] = useState(false);
	const accountData = useSelector(state => (state.myaccount && state.myaccount.accountData) || {});

	const dispatch = useDispatch();

	const handleClickStart = () => {
		setRun(true);
	};

	useEffect(() => {
		if (!accountData?.guidedTours?.acp) {
			setRun(true);
		}
	}, []);

	const handleJoyrideCallback = data => {
		const { status } = data;
		if (status === 'finished' || status === 'skipped') {
			setRun(false);

			dispatch({
				type: 'myaccount/UPDATE_ACCOUNT_DETAILS',
				payload: {
					...accountData,
					guidedTours: {
						...accountData.guidedTours,
						acp: true
					}
				}
			});
		}
	};

	return (
		<PrivateTemplate>
			<BreadCrumb
				name="ACP Program"
				firstLevel="Home"
				secondLevel={
					<>
						ACP Program
						<Tooltip title="Guided Tour for ACP">
							<Blinker blinkForSeconds={2} secondsPerBlink={accountData?.guidedTours?.acp === true ? 0 : 2.5}>
								<Button
									onClick={handleClickStart}
									color="secondary"
									size="small"
									style={{
										border: 'none'
									}}
								>
									<LightbulbOutline size="small" style={{ color: 'orange' }} />
								</Button>
							</Blinker>
						</Tooltip>
					</>
				}
			/>
			<Joyride run={run} steps={steps} continuous showProgress showSkipButton options={options} callback={handleJoyrideCallback} />

			<AcpProgramScreen {...props} />
		</PrivateTemplate>
	);
};

const steps = [
	{
		target: '#provider-submissions-tab',
		content: 'This is the Provider Submissions tab. Here you can view all the submissions made by the providers.',
		placement: 'bottom-start',
		disableBeacon: true
	},
	{
		target: '#nlad-verified-submissions-tab',
		content: 'This is the NLAD Verified Submissions tab. Here you can view all the submissions that have been verified by NLAD.',
		placement: 'bottom-start',
		disableBeacon: true
	},
	{
		target: '#daily-claims-tab',
		content: 'This is the Daily Claims tab. Here you can view all the daily claims made by the providers.',
		disableBeacon: true
	},
	{
		target: '#pro-forma-claims-tab',
		content: 'This is the Pro Forma Claims tab. Here you can view all the pro forma claims made by the providers.',
		disableBeacon: true
	},
	{
		target: '#payments-tab',
		content: 'This is the Payments tab. Here you can view all the payments made by the providers.',
		placement: 'bottom-end',
		disableBeacon: true
	},
	{
		target: '#statements-tab',
		content: 'This is the Statements tab. Here you can view all the statements made by the providers.',
		placement: 'bottom-end',
		disableBeacon: true
	}
];

const options = {
	arrowColor: '#fff',
	backgroundColor: '#fff',
	beaconSize: 36,
	overlayColor: 'rgba(0, 0, 0, 0.5)',
	primaryColor: '#f04',
	spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
	textColor: '#333',
	width: undefined,
	zIndex: 100
};

export default AcpProgramPage;
