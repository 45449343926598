import React, { Component } from "react";
import { connect } from "react-redux";
import { AcceptOrderForm } from "components";
import { acceptOrder, order, setOrder } from "store/actions";
import { reduxForm, reset } from "redux-form";
import { fromBiddingportal, fromUseraccount } from "store/selectors";
import { createValidator } from "services/validation";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

class AcceptOrderFormContainer extends Component {
  constructor(props) {
    super(props);
    this.acceptOpenOrder = this.acceptOpenOrder.bind(this);
    this.isFunder = this.isFunder.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(reset("acceptOrderForm"));
    this.props.order(this.props.idOpenOrder);
  }
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(reset("acceptOrderForm"));
    this.props.setOrder(undefined);
  }
  acceptOpenOrder(data) {
    this.props.hideAcceptOpenOrder();
    this.props.handleSubmit(data);
  }
  isFunder(data) {
    return this.props.user.userInfo.roles[0] === CONFIG_PORTAL.acountTypeFunder;
  }

  render() {
    return (
      <AcceptOrderForm
        {...this.props}
        handleSubmit={this.acceptOpenOrder}
        order={this.props.order}
        isFunder={this.isFunder()}
      />
    );
  }
}
const mapStateToProps = state => ({
  user: fromUseraccount.getUserInfoDataState(state),
  order: fromBiddingportal.getOrder(state),
  initialValues: fromBiddingportal.getOrder(state)
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      order,
      setOrder
    },
    dispatch
  );
}

const onSubmit = (data, dispatch) => {
  dispatch(acceptOrder(data.id));
};

const validate = createValidator({});

AcceptOrderFormContainer.propTypes = {
  hideAcceptOpenOrder: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  idOpenOrder: PropTypes.string.isRequired
};

const reduxFormAcceptOrderFormContainer = reduxForm({
  form: "acceptOrderForm",
  destroyOnUnmount: true,
  onSubmit,
  validate
})(AcceptOrderFormContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxFormAcceptOrderFormContainer);
