import React from "react";
import { Card } from "reactstrap";

export default ({ numberOfBlocksPerDay }) => (
  <div>
    <h5 className="stats_title">BLOCK STATS</h5>
    <div className="card-group">
      <Card className="browser_card browser_card_stats">
        <h5>{numberOfBlocksPerDay}</h5>
        <label>Avg Blocks per Day</label>
      </Card>
      <Card className="browser_card browser_card_stats">
        <h5>1</h5>
        <label>Avg Tx per Block</label>
      </Card>
    </div>
  </div>
);
