import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContractDetails, ConnectionSettings, CreateNewRoute, RoutesList } from './components';
import { useBeforeunload } from "react-beforeunload";

import styles from './styles.module.scss';

const RouteDetails = memo(
	({
		match: {
			params: { smartContractId }
		}
	}) => {
		const dispatch = useDispatch();

		const details = useSelector(state => (state.routes && state.routes.details) || false);
		const loadingCreate = useSelector(state => (state.routes && state.routes.loadingCreate) || false);

		useBeforeunload(event => {
			if(loadingCreate) {
				event.preventDefault()
			}
		})

		useEffect(() => {
			dispatch({
				type: 'routes/GET_CONTRACT_DETAILS',
				payload: smartContractId
			});

			return () => {
				dispatch({
					type: 'routes/SET_STATE',
					payload: {
						details: {}
					}
				});
			};
		}, []);

		return (
			<div className={styles.container}>
				<ContractDetails data={details.contract || {}} />
				<ConnectionSettings settings={details.connectionSettings || {}} contract={details.contract || {}} />
				{(details && details.contract && details.contract.userIsSupplier && <CreateNewRoute smartContractId={smartContractId} />) || ''}
				{(details && details.contract && details.contract.userIsSupplier && <RoutesList smartContractId={smartContractId} />) || ''}
			</div>
		);
	}
);

export default RouteDetails;
