import { API } from "aws-amplify";

const InterconnectionsApi = {
  initializeInterconnections(data) {
    return API.post("NODE_SERVICE_API", "/interconnect/getInterconnections")
      .then(response => response)
      .catch(err => err);
  },
  validateInterconnection(data) {
    let init = {
      body: { guid: data.connectGuid }
    };
    return API.post("INTERCONNECTION_API", "/info", init)
      .then(response => response)
      .catch(err => err);
  },
  sendInterconnection(data) {
    let init = {
      body: { email: data.mail, interconnectionType: data.interconnectionType }
    };
    return API.post("NODE_SERVICE_API", "/interconnect/invite", init)
      .then(response => response)
      .catch(err => err);
  },
  acceptInterconnection(data) {
    let init = {
      body: { guid: data }
    };
    return API.post("NODE_SERVICE_API", "/interconnect/accept", init)
      .then(response => response)
      .catch(err => err);
  },
  agreementSign(data) {
    let init = {
      body: {
        payload: data
      }
    };
    return API.post("NODE_SERVICE_API", "/interconnect/agreement/sign", init)
      .then(response => response)
      .catch(err => err);
  },
  agreementDownload(data) {

    let init = {
      body: data
    };
    return API.post("NODE_SERVICE_API", "/interconnect/agreement/download", init)
      .then(response => response)
      .catch(err => err);
  },
  agreementStatus(data) {
    return API.post("NODE_SERVICE_API", "/interconnect/agreement/status", data)
      .then(response => response)
      .catch(err => err);
  },
  agreementAccept(data) {
    let init = {
      body: {
        payload: data
      }
    };
    return API.post("NODE_SERVICE_API", "/interconnect/agreement/accept", init)
      .then(response => response)
      .catch(err => err);
  }
};

export default InterconnectionsApi;
