import 'react-toastify/dist/ReactToastify.css';

import { AppBar, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, Paper, Slide, Tab, Tabs, Typography, Grid } from '@material-ui/core';
import {
	CustomerPayInvoiceModal,
	ExternalInvoice,
	FinancialReports,
	Invoice,
	LenderApproveInvoiceModal,
	MonthlyInvoice,
	RenderButton,
	SettlementsTable,
	TableCustomerInvoices,
	TableExternalInvoices,
	TableInvoicePerCustomer,
	TableLendingInvoices,
	TableSupplierInvoices
} from 'components';
/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {
	actionCreatorPreloaderOff,
	actionCreatorPreloaderOn,
	downloadInvoice,
	getFinancial,
	getInitialWallet,
	getSettlements,
	lenderApproveInvoice,
	loadExternalInvoices,
	loadInvoices,
	loadLenderInvoices,
	loadMonthlyInvoices,
	payInvoice,
	setResultWallet,
	walletDataSuccess
} from 'store/actions';
import { fromFinancial, fromMyaccount, fromReports, fromUseraccount, fromWallet } from 'store/selectors';

import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import { WalletTessPayContainer } from 'containers';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AuthManager } from '../components';
import { withTranslation } from 'react-i18next';

const Transition = props => <Slide direction="up" {...props} />;

class FinancialContainer extends Component {
	static totalAmount(data) {
		let total = 0;

		if (data !== '' && data.wallets) {
			let i = 0;
			for (i = 0; i < data.wallets.length; i++) {
				total += Number(data.wallets[i].fiatAmount);
			}
		}
		return total;
	}

	static formatNumber(data) {
		const n = parseFloat(data).toFixed(2);
		const withCommas = Number(n).toLocaleString('en');
		return `$${withCommas}`;
	}

	debouncePreloader = _.debounce(() => {
		const { actionCreatorPreloaderOff } = this.props;
		actionCreatorPreloaderOff();
	}, 800);

	constructor(props) {
		super(props);
		this.state = {
			results: false,
			value: 0,
			isInvoiceModalVisible: false,
			selectedInvoiceData: {},
			isMonthlyInvoiceModalVisible: false,
			selectedMonthlyInvoiceData: {},
			subInvoices: [],
			invoiceViewOpen: false,
			lenderInvoiceApproveViewOpen: false,
			customerPayInvoiceViewOpen: false,
			originationExpanded: {},
			standardExpanded: {},
			amountAvailable: 0,
			amountTotal: 0,
			lenderInvoiceApproveViewValues: {},
			customerPayInvoiceViewValues: {}
		};
		this.value = 0;
		this.totalAmountProp = 0;

		this.toast = toast;
	}

	componentWillMount() {
		const { getFinancial, getSettlements, loadLenderInvoices, getInitialWallet, userInfo, walletDataSuccess } = this.props;
		getFinancial();
		getSettlements();
		loadLenderInvoices();
		walletDataSuccess({});
		getInitialWallet(userInfo.userId);
	}

	componentDidMount() {
		const { actionCreatorPreloaderOn, loadMonthlyInvoices, loadExternalInvoices } = this.props;

		actionCreatorPreloaderOn();
		this.debouncePreloader();
		this.showResultData = false;

		loadMonthlyInvoices('customer');
		loadMonthlyInvoices('supplier');
		loadExternalInvoices();
	}

	// eslint-disable-next-line react/sort-comp
	fnToaStr(data) {
		if (data.success) {
			this.toast.success(data.message, {
				position: 'top-right',
				autoClose: 2000
			});
		} else {
			this.toast.error(data.message, {
				position: 'top-right',
				autoClose: 2000
			});
		}
	}

	componentWillReceiveProps(nextProps) {
		const { walletInfo } = nextProps;
		const { resultWallet, setResultWallet } = this.props;
		if (resultWallet !== nextProps.resultWallet) {
			if (nextProps.resultWallet) {
				// resetForm("withDrawForm");
				this.fnToaStr(nextProps.resultWallet);
				setResultWallet(undefined);
				this.setState({ amountTopUp: '', amountWithDraw: '' });
			}
		}

		if (walletInfo) {
			let amountTotal = 0;
			let amountAvailable = 0;

			if (walletInfo.wallet) {
				walletInfo.wallet.map(obj => {
					amountTotal += Number.parseFloat(obj.fiatAmount);
				});
				amountAvailable = amountTotal - Number.parseFloat(walletInfo.lockedOnSmartContracts);
			}

			this.setState({
				amountTotal,
				amountAvailable
			});
		}
	}

	toggleExternalInvoiceModal = invoiceData => {
		this.setState(prevState => {
			const { isExternalInvoiceModalVisible } = prevState;
			return {
				isExternalInvoiceModalVisible: !isExternalInvoiceModalVisible,
				selectedExternalInvoiceData: invoiceData
			};
		});
	};

	toggleMonthlyInvoiceModal = invoiceData => {
		this.setState(prevState => {
			const { isMonthlyInvoiceModalVisible } = prevState;
			return {
				isMonthlyInvoiceModalVisible: !isMonthlyInvoiceModalVisible,
				selectedMonthlyInvoiceData: invoiceData
			};
		});
	};

	toggleInvoiceModal = invoiceData => {
		this.setState(prevState => {
			const { isInvoiceModalVisible } = prevState;
			return {
				isInvoiceModalVisible: !isInvoiceModalVisible,
				selectedInvoiceData: invoiceData
			};
		});
	};

	showResult = () => {
		const { info } = this.props;
		const { results } = this.state;
		if (info && info.wallets && info.wallets.length > 0) {
			this.setState({ results: !results });
		}
	};

	formatDate = date => {
		let dateData = '';
		if (date && date !== undefined) {
			dateData = new Date(date).toISOString().substr(0, 10);
		}
		return dateData;
	};

	handleTabChange = (event, value) => {
		this.setState({
			value,
			originationExpanded: {},
			standardExpanded: {},
			selectedInvoiceData: {}
		});
	};

	handleInvoiceView = (evt, type, data, usageData, isMaster = false, isStandard = false) => {
		this.setState(prevState => ({
			...prevState,
			selectedInvoiceData: {
				original: data,
				usageSummary: usageData,
				isMaster,
				isStandard,
				invoiceType: type
			},
			invoiceViewOpen: !prevState.invoiceViewOpen
		}));
	};

	handleInvoiceDownload = () => {
		const { downloadInvoice } = this.props;
		const { selectedInvoiceData } = this.state;

		const invId = selectedInvoiceData && selectedInvoiceData.original && selectedInvoiceData.original._id;
		const invNb = selectedInvoiceData && selectedInvoiceData.original && selectedInvoiceData.original.INV_NUMBER;
		const type = selectedInvoiceData && selectedInvoiceData.invoiceType;
		const { isStandard } = selectedInvoiceData;

		return downloadInvoice(invId, invNb, type, !isStandard);
	};

	handleOriginationExpansion = expanded => {
		this.setState({
			originationExpanded: expanded
		});
	};

	handleStandardExpansion = expanded => {
		this.setState({
			standardExpanded: expanded
		});
	};

	handleLenderInvoiceApproveView = data => {
		const { lenderInvoiceApproveViewOpen } = this.state;
		this.setState({
			lenderInvoiceApproveViewOpen: !lenderInvoiceApproveViewOpen,
			lenderInvoiceApproveViewValues: lenderInvoiceApproveViewOpen ? {} : data
		});
	};

	handleLenderInvoiceApproveSubmit = data => {
		const { lenderApproveInvoice, loadLenderInvoices } = this.props;
		lenderApproveInvoice(data);
		this.handleLenderInvoiceApproveView();
	};

	handleCustomerPayInvoiceView = data => {
		const { customerPayInvoiceViewOpen } = this.state;
		this.setState({
			customerPayInvoiceViewOpen: !customerPayInvoiceViewOpen,
			customerPayInvoiceViewValues: customerPayInvoiceViewOpen ? {} : data
		});
	};

	handleCustomerPayInvoiceSubmit = data => {
		const { getInitialWallet, userInfo } = this.props;
		const { startDate, endDate, contract } = data;
		this.props.payInvoice({ contractId: contract, startDate, endDate });
		this.handleCustomerPayInvoiceView();
		getInitialWallet(userInfo.userId);
	};

	renderExternalInvoicesLoader = () => {
		const { externalInvoices } = this.props;

		if (externalInvoices && externalInvoices.loading) {
			return (
				<div>
					<Typography variant="body1">
						<CircularProgress color="secondary" size={20} />
						<span>&nbsp;&nbsp;&nbsp;Loading invoices ...</span>
					</Typography>
				</div>
			);
		}
		if (externalInvoices) {
			if (!externalInvoices.data || !externalInvoices.data.length) {
				return (
					<div>
						<Typography variant="body1">No monthly invoices found</Typography>
					</div>
				);
			}
		}
		return false;
	};

	renderMonthlyInvoiceLoader = (isSupplier = false) => {
		const { monthlyInvoicesPerCustomer, currentUserMonthlyInvoices } = this.props;

		const data = isSupplier ? monthlyInvoicesPerCustomer : currentUserMonthlyInvoices;
		if (data && data.loading) {
			return (
				<div>
					<Typography variant="body1">
						<CircularProgress color="secondary" size={20} />
						<span>&nbsp;&nbsp;&nbsp;Loading invoices ...</span>
					</Typography>
				</div>
			);
		}
		if (!data || !data.data || !data.data.length) {
			return (
				<div>
					<Typography variant="body1">No monthly invoices found</Typography>
				</div>
			);
		}
		return false;
	};

	AddThousandSeparator = amount => (amount !== undefined ? Number(amount).toLocaleString() : 0);

	render() {
		const {
			t,
			settlements,
			role,
			userInfo,
			participantData,
			loadInvoices,
			invoiceDownload: { isDownloading },
			loadLenderInvoices,
			lenderInvoicesApproval,
			invoices,
			monthlyInvoicesPerCustomer,
			currentUserMonthlyInvoices,
			externalInvoices
		} = this.props;

		const {
			value,
			selectedInvoiceData,
			invoiceViewOpen,
			originationExpanded,
			standardExpanded,
			lenderInvoiceApproveViewOpen,
			lenderInvoiceApproveViewValues,
			amountTotal,
			customerPayInvoiceViewOpen,
			customerPayInvoiceViewValues,
			isMonthlyInvoiceModalVisible,
			selectedMonthlyInvoiceData,
			isExternalInvoiceModalVisible,
			selectedExternalInvoiceData
		} = this.state;

		const isFunder = role.indexOf(ROLES.FUNDER) !== -1;

		return (
			<div>
				<ToastContainer />
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<AppBar position="static">
							<Tabs value={value} onChange={this.handleTabChange}>
								<Tab label={t('tabs.wallet')} onChange={(e, value) => this.setState({ value })} value={0} />

								<AuthManager permissions={['financials.customer-invoices']} userRole="carrier">
									<Tab label={t('tabs.customer_invoices')} onChange={(e, value) => this.setState({ value })} value={1} />
								</AuthManager>
								<AuthManager permissions={['financials.customer-invoices']} userRole="carrier">
									<Tab label={t('tabs.supplier_invoices')} onChange={(e, value) => this.setState({ value })} value={2} />
								</AuthManager>
								<AuthManager permissions={['financials.customer-invoices']} userRole="funder">
									<Tab label={t('tabs.lending_invoices')} value="lendingContractInvoices" onChange={(e, value) => this.setState({ value })} />
								</AuthManager>
							</Tabs>
						</AppBar>
					</Grid>

					<Grid item xs={12}>
						{value === 0 && <WalletTessPayContainer />}
						{/*

						{value === 1 && (
						<SettlementsTable
							data={settlements}
							loggedInParticipant={participantData}
						/>
						)}

						*/}
						{value === 1 && !isFunder && (
							<React.Fragment>
								<TableCustomerInvoices
									loadOriginationCustomerInvoices={() => loadInvoices('customer', true)}
									loadStandardCustomerInvoices={() => loadInvoices('customer', false)}
									data={invoices.customer}
									handleInvoiceView={this.handleInvoiceView}
									handleOriginationExpansion={this.handleOriginationExpansion}
									handleStandardExpansion={this.handleStandardExpansion}
									originationExpanded={originationExpanded}
									standardExpanded={standardExpanded}
								/>
								<br />
								<Paper style={{ opacity: '.6' }}>
									<CardContent>
										<Typography variant="h1" align="left">
											{t('monthly_invoices_per_cust')}
										</Typography>
										{this.renderMonthlyInvoiceLoader() || (
											<TableInvoicePerCustomer
												data={monthlyInvoicesPerCustomer.data}
												toggleInvoiceModal={this.toggleMonthlyInvoiceModal}
												clientDataKey="customerData"
											/>
										)}
									</CardContent>
								</Paper>
								<br />
								<Paper style={{ opacity: '.6' }}>
									<CardContent>
										<Typography variant="h1" align="left">
											{t('external_invoices')}
										</Typography>
										{this.renderExternalInvoicesLoader() || (
											<TableExternalInvoices data={externalInvoices.data} toggleInvoiceModal={this.toggleExternalInvoiceModal} />
										)}
									</CardContent>
								</Paper>
							</React.Fragment>
						)}

						{value === 2 && !isFunder && (
							<React.Fragment>
								<TableSupplierInvoices
									loadOriginationSupplierInvoices={() => loadInvoices('supplier', true)}
									loadStandardSupplierInvoices={() => loadInvoices('supplier', false)}
									data={invoices.supplier}
									handleInvoiceView={this.handleInvoiceView}
									handleOriginationExpansion={this.handleOriginationExpansion}
									handleStandardExpansion={this.handleStandardExpansion}
									originationExpanded={originationExpanded}
									standardExpanded={standardExpanded}
									handleCustomerPayInvoiceView={this.handleCustomerPayInvoiceView}
								/>
								<br />
								<Paper style={{ opacity: '.6' }}>
									<CardContent>
										<Typography variant="h1" align="left">
											{t('monthly_invoices_per_supplier')}
										</Typography>
										{this.renderMonthlyInvoiceLoader(true) || (
											<TableInvoicePerCustomer
												data={currentUserMonthlyInvoices.data}
												toggleInvoiceModal={this.toggleMonthlyInvoiceModal}
												clientDataKey="supplierData"
											/>
										)}
									</CardContent>
								</Paper>
							</React.Fragment>
						)}
						{value === 'lendingContractInvoices' && isFunder && (
							<TableLendingInvoices
								loadLenderInvoices={() => loadLenderInvoices()}
								tableData={lenderInvoicesApproval}
								handleInvoiceView={this.handleInvoiceView}
								handleLenderInvoiceApproveView={this.handleLenderInvoiceApproveView}
							/>
						)}
						{value === 'margin' && <FinancialReports reportType="margin" />}
						{value === 'lending' && <FinancialReports reportType="lending" />}
						<Dialog maxWidth="md" fullWidth open={!!isExternalInvoiceModalVisible} onBackdropClick={this.toggleExternalInvoiceModal} TransitionComponent={Transition}>
							<DialogContent>
								<ExternalInvoice
									ref={el => {
										this.eiComponentRef = el;
									}}
									data={selectedExternalInvoiceData}
								/>
							</DialogContent>
							<DialogActions>
								<RenderButton color="primary" onClick={this.toggleExternalInvoiceModal}>
									{t('translations:close')}
								</RenderButton>
								<ReactToPrint trigger={() => <RenderButton color="primary">Print</RenderButton>} content={() => this.eiComponentRef} />
							</DialogActions>
						</Dialog>
						<Dialog maxWidth="md" fullWidth open={!!isMonthlyInvoiceModalVisible} onBackdropClick={this.toggleMonthlyInvoiceModal} TransitionComponent={Transition}>
							<DialogContent>
								<MonthlyInvoice
									ref={el => {
										this.miComponentRef = el;
									}}
									data={selectedMonthlyInvoiceData}
								/>
							</DialogContent>
							<DialogActions>
								<RenderButton color="primary" onClick={this.toggleMonthlyInvoiceModal}>
									{t('translations:close')}
								</RenderButton>
								<ReactToPrint trigger={() => <RenderButton color="primary">Print</RenderButton>} content={() => this.miComponentRef} />
							</DialogActions>
						</Dialog>
						<Dialog maxWidth="md" open={!!invoiceViewOpen} onBackdropClick={this.handleInvoiceView} TransitionComponent={Transition}>
							<DialogContent>
								<Invoice
									ref={el => {
										this.componentRef = el;
									}}
									data={selectedInvoiceData}
								/>
							</DialogContent>
							<DialogActions>
								<RenderButton color="danger" onClick={this.handleInvoiceView}>
									{t('translations:close')}
								</RenderButton>
								<ReactToPrint trigger={() => <RenderButton color="primary">Print</RenderButton>} content={() => this.componentRef} />
								<RenderButton color="primary" onClick={this.handleInvoiceDownload} disabled={isDownloading}>
									{isDownloading ? (
										<Typography variant="body1" align="center">
											<CircularProgress color="secondary" size={15} />
											<span>&nbsp;&nbsp;&nbsp;{t('translations:downloading')}</span>
										</Typography>
									) : (
										t('translations:download')
									)}
								</RenderButton>
							</DialogActions>
						</Dialog>
						<LenderApproveInvoiceModal
							open={lenderInvoiceApproveViewOpen}
							openCloseHandler={this.handleLenderInvoiceApproveView}
							availableWalletAmount={this.AddThousandSeparator(amountTotal)}
							initialValues={lenderInvoiceApproveViewValues}
							onSubmit={this.handleLenderInvoiceApproveSubmit}
						/>
						<CustomerPayInvoiceModal
							open={customerPayInvoiceViewOpen}
							openCloseHandler={this.handleCustomerPayInvoiceView}
							availableWalletAmount={this.AddThousandSeparator(amountTotal)}
							initialValues={customerPayInvoiceViewValues}
							onSubmit={this.handleCustomerPayInvoiceSubmit}
						/>
					</Grid>
				</Grid>
			</div>
		);
	}
}
function dataEscrow(data) {
	if (data && data.result) {
		return data.result;
	}
	return '';
}

const mapStateToProps = state => ({
	form: state.form,
	info: dataEscrow(fromFinancial.getFinancialState(state)),
	settlements: fromFinancial.getSettlements(state),
	invoices: fromReports.getInvoices(state),
	lenderInvoicesApproval: fromFinancial.getLenderInvoicesApproval(state),
	invoiceDownload: fromReports.getInvoiceDownloadState(state),
	role: fromUseraccount.getUserRol(state),
	userInfo: fromUseraccount.getUserInfo(state),
	participantData: fromMyaccount.getParticipantData(state),
	walletInfo: fromWallet.getWalletData(state),
	resultWallet: fromWallet.getResultWallet(state),
	currentUserMonthlyInvoices: fromReports.getMonthlyInvoices(state).supplier,
	monthlyInvoicesPerCustomer: fromReports.getMonthlyInvoices(state).customer,
	externalInvoices: fromReports.getExternalInvoices(state)
});

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			actionCreatorPreloaderOn,
			actionCreatorPreloaderOff,
			getFinancial,
			getSettlements,
			loadInvoices,
			downloadInvoice,
			loadLenderInvoices,
			getInitialWallet,
			walletDataSuccess,
			lenderApproveInvoice,
			payInvoice,
			setResultWallet,
			loadMonthlyInvoices,
			loadExternalInvoices
		},
		dispatch
	);
}

FinancialContainer.defaultProps = {
	monthlyInvoicesPerCustomer: {
		loading: false,
		data: []
	},
	currentUserMonthlyInvoices: {
		loading: false,
		data: []
	}
};

FinancialContainer.propTypes = {
	actionCreatorPreloaderOn: PropTypes.func.isRequired,
	actionCreatorPreloaderOff: PropTypes.func.isRequired,
	getFinancial: PropTypes.func.isRequired,
	getSettlements: PropTypes.func.isRequired,
	monthlyInvoicesPerCustomer: PropTypes.shape({
		loading: PropTypes.bool,
		data: PropTypes.arrayOf(PropTypes.shape())
	}),
	currentUserMonthlyInvoices: PropTypes.shape({
		loading: PropTypes.bool,
		data: PropTypes.arrayOf(PropTypes.shape())
	})
};

const FinancialContainerTranslated = withTranslation(['financials/general', 'translations'])(FinancialContainer);

export default connect(mapStateToProps, mapDispatchToProps)(FinancialContainerTranslated);
