import React, { memo, createContext } from 'react';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import { AuthManager } from 'components';
import useFinancialsSectionState from './hooks/useFinancialsSectionState';
import { InvoiceSection, InvoicesModal, WalletSection, LendingInvoicesSection } from './components';
import styles from './styles.module.scss';
import { IntlProvider } from 'react-intl';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';

export const FinancialContext = createContext(null);

export default memo(() => {
	const { isFunder, selectedTab, setSelectedTab, context, t } = useFinancialsSectionState();
	const { i18n } = useTranslation();

	return (
		<IntlProvider locale={i18n.language}>
			<FinancialContext.Provider value={context}>
				<ToastContainer />
				<InvoicesModal />
				<div className={styles.container}>
					<div className="head-tabs">
						<AppBar position="static">
							<Tabs value={selectedTab} onChange={(e, value) => setSelectedTab(value)}>
								<Tab value={'wallet'} label={t('tabs.wallet')} onChange={(e, value) => setSelectedTab(value)} />
								<AuthManager permissions={['financials.customer-invoices']} userRole={['carrier']} value={'customer-invoices'}>
									<Tab value={'customer-invoices'} label={t('tabs.customer_invoices')} onChange={(e, value) => setSelectedTab(value)} />
								</AuthManager>
								<AuthManager permissions={['financials.customer-invoices']} userRole={['carrier']} value={'supplier-invoices'}>
									<Tab value={'supplier-invoices'} label={t('tabs.supplier_invoices')} onChange={(e, value) => setSelectedTab(value)} />
								</AuthManager>
								<AuthManager permissions={['financials.customer-invoices']} userRole={['funder']} value={'lending-contract-invoices'}>
									<Tab value={'lending-contract-invoices'} label={t('tabs.lending_invoices')} onChange={(e, value) => setSelectedTab(value)} />
								</AuthManager>
							</Tabs>
						</AppBar>
					</div>
					<div>
						{(selectedTab === 'wallet' && <WalletSection />) || ''}
						{(selectedTab === 'customer-invoices' && !isFunder && <InvoiceSection type={'customer'} />) || ''}
						{(selectedTab === 'supplier-invoices' && !isFunder && <InvoiceSection type={'supplier'} />) || ''}
						{(selectedTab === 'lending-contract-invoices' && isFunder && <LendingInvoicesSection />) || ''}
					</div>
				</div>
			</FinancialContext.Provider>
		</IntlProvider>
	);
});
