import React, { memo } from 'react';
import { Typography } from '@material-ui/core';
import { RenderTable, CurrencyNumber } from 'components';
import {useTranslation} from "react-i18next";

const ReportTraffic = memo(props => {
	const { tableData, isLoading } = props;
	const { t } = useTranslation('reports/general');

	let totalMinutes = 0;
	let totalAmount = 0;
	tableData.map(obj => {
		if (obj.data) {
			obj.data.map(subobj => {
				totalMinutes += parseFloat(subobj.minutes);
				totalAmount += parseFloat(subobj.amount);
			});
		}
	});

	const tableColumns = [
		{
			Header: t('report.country'),
			accessor: 'carrier',
			Cell: d => <Typography variant="h1">{d.value}</Typography>,
			style: {
				overflow: 'visible'
			},
			Footer: <strong style={{ fontSize: '1.2em' }}>Total:</strong>
		},
		{
			Header: t('report.destination_type'),
			accessor: 'title',
			Cell: () => null
		},
		{
			Header: t('report.destination_carrier'),
			accessor: 'title',
			Cell: () => null
		},
		{
			Header: t('report.minutes'),
			headerClassName: 'rt-text-right',
			className: 'rt-text-right rt-no-ellipsis',
			accessor: 'title',
			Cell: () => null,
			Footer: <strong style={{ fontSize: '1.2em' }}>{parseFloat(totalMinutes).toFixed(2)}</strong>
		},
		{
			Header: t('report.attempts'),
			headerClassName: 'rt-text-right',
			accessor: 'title',
			Cell: () => null
		},
		{
			Header: t('report.connected_calls'),
			headerClassName: 'rt-text-right',
			accessor: 'title',
			Cell: () => null
		},
		{
			Header: t('report.asr'),
			headerClassName: 'rt-text-right',
			accessor: 'title',
			Cell: () => null
		},
		{
			Header: t('report.acd'),
			headerClassName: 'rt-text-right',
			accessor: 'title',
			Cell: () => null
		},
		{
			Header: t('report.pdd'),
			headerClassName: 'rt-text-right',
			accessor: 'title',
			Cell: () => null
		},
		{
			Header: t('report.rate'),
			headerClassName: 'rt-text-right',
			accessor: 'title',
			Cell: () => null
		},
		{
			Header: t('report.billing_inc'),
			headerClassName: 'rt-text-right',
			accessor: 'title',
			Cell: () => null
		},
		{
			Header: t('report.amount'),
			headerClassName: 'rt-text-right',
			className: 'rt-text-right rt-no-ellipsis',
			accessor: 'title',
			Cell: () => null,
			Footer: (
				<strong style={{ fontSize: '1.2em' }}>
					<CurrencyNumber amount={parseFloat(totalAmount).toFixed(6)} precision={6} />
				</strong>
			)
		}
	];

	return (
		<RenderTable
			className="defaultExpanded"
			loading={isLoading}
			style={{
				maxHeight: '700px'
			}}
			expanded={{
				0: true,
				1: true,
				2: true,
				3: true,
				4: true,
				5: true,
				6: true
			}}
			data={tableData}
			columns={tableColumns}
			filterable={false}
			sortable={false}
			resizable={false}
			isExpandable
			showExpander={false}
			expanderDefaults={{
				sortable: false,
				resizable: false,
				filterable: false,
				width: 0
			}}
			SubComponent={row => (
				<div>
					<RenderTable
						loading={isLoading}
						resizable={false}
						sortable={false}
						data={row.original.data}
						style={{ width: '100%' }}
						columns={[
							{
								Header: '',
								accessor: 'country'
							},
							{
								Header: '',
								accessor: 'destinationType'
							},
							{
								Header: '',
								accessor: 'destinationCarrier'
							},
							{
								Header: '',
								className: 'rt-text-right',
								accessor: 'minutes',
								Cell: data => parseFloat(data.value).toFixed(2),
								Footer: table => {
									let total = 0;
									table.data.map(obj => {
										total += parseFloat(obj.minutes);
									});
									return <strong>{total.toFixed(2)}</strong>;
								}
							},
							{
								Header: '',
								className: 'rt-text-right',
								accessor: 'attempts'
							},
							{
								Header: '',
								className: 'rt-text-right',
								accessor: 'connected_calls'
							},
							{
								Header: '',
								className: 'rt-text-right',
								accessor: 'asr',
								Cell: data => `${parseFloat(data.value).toFixed(0)}%`
							},
							{
								Header: '',
								className: 'rt-text-right',
								accessor: 'acd'
							},
							{
								Header: '',
								className: 'rt-text-right',
								accessor: 'pdd'
							},
							{
								Header: '',
								className: 'rt-text-right',
								accessor: 'rate',
								// eslint-disable-next-line no-shadow
								Cell: props => <CurrencyNumber amount={parseFloat(props.value).toFixed(6)} precision={6} />
							},
							{
								Header: '',
								className: 'rt-text-right',
								accessor: 'billingIncrement'
							},
							{
								Header: '',
								className: 'rt-text-right',
								accessor: 'amount',
								// eslint-disable-next-line no-shadow
								Cell: props => <CurrencyNumber amount={parseFloat(props.value).toFixed(6)} precision={6} />,
								Footer: table => {
									let total = 0;
									table.data.map(obj => {
										total += parseFloat(obj.amount);
									});
									return (
										<strong>
											<CurrencyNumber amount={total.toFixed(6)} precision={6} />
										</strong>
									);
								}
							}
						]}
						filterable={false}
					/>
				</div>
			)}
		/>
	);
});

ReportTraffic.defaultProps = {
	tableData: []
};

export default ReportTraffic;
