import { call, put, select } from "redux-saga/effects";
import explorerApiService from "services/api/ExplorerApi";
// import { delay } from "redux-saga";
import { fromExplorer } from "store/selectors";
import {
  actionSmartContractExplorerGetDataResponse,
  successBlocksRequest,
  actionWalletExplorerGetDataResponse,
  actionCreatorPreloaderOn,
  actionCreatorPreloaderOff
} from "../actions";

export function* sagaSmartContractExplorerGetDataRequest(action) {
  try {
    const { smartContractAddresss, funderContractAddress } = action.payload;

    let result = {};
    if (
      smartContractAddresss !== undefined &&
      smartContractAddresss.trim() !== "" &&
      funderContractAddress !== undefined &&
      funderContractAddress.trim() !== ""
    ) {
      result = yield call(
        explorerApiService.getSmartContractFundedExplorerGetDataRequest,
        { smartContractAddresss, funderContractAddress }
      );
    } else {
      result = yield call(
        explorerApiService.getSmartContractExplorerGetDataRequest,
        smartContractAddresss
      );
    }
    yield put(actionSmartContractExplorerGetDataResponse(result));
  } catch (error) {}
}

export function* sagaInitBlocksPolling(action) {
  let flag = true;
  try {
    while (flag) {
      try {
        const statusBlocksPolling = yield select(
          fromExplorer.getStatusBlocksPolling
        );
        const result = yield call(explorerApiService.getData, action.payload);
        yield put(
          successBlocksRequest(result !== "not authenticated" ? result : [])
        );
        // yield call(delay, 40000);
        flag = statusBlocksPolling;
      } catch (error) {
        flag = false;
        // yield call(delay, 10000);
      }
    }
  } catch (error) {
    flag = false;
  }
}

export function* sagaWalletExplorerGetDataRequest(action) {
  try {
    const result = yield call(
      explorerApiService.getWalletExplorerGetDataRequest,
      action.payload
    );
    yield put(actionWalletExplorerGetDataResponse(result));
  } catch (error) {}
}
