import React from "react";
import { PrivateTemplate, BreadCrumb } from "components";
import { SmartContractLendingContainer } from "containers";

const SmartContractLendingListPage = ({ ...props }) => (
  <PrivateTemplate>
    <BreadCrumb
      name="Funding Offers"
      firstLevel="Home"
      secondLevel="Funding Offers"
    />
    <SmartContractLendingContainer {...props} />
  </PrivateTemplate>
);
export default SmartContractLendingListPage;
