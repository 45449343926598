import {
	actions,
	LOAD_SMART_CONTRACTS,
	INITIAL_SMART_CONTRACTS,
	SUCCESS_LIST_SMART_CONTRACT,
	INITIAL_NEW_SMART_CONTRACT,
	FAILURE_DATA_SMART_CONTRACT,
	SUCCESS_INITIAL_NEW_SMART_CONTRACT,
	LOAD_DESTINATIONS_TYPE_CARRIER,
	SUCCESS_DESTINATIONS_TYPE_CARRIER,
	LOAD_DESTINATIONS_CARRIER,
	SUCCESS_DESTINATIONS_CARRIER,
	LOAD_DIAL_CODES_TYPE_CARRIER_COUNTRY,
	CREATE_SMART_CONTRACT,
	CREATE_SMART_CONTRACT_FAILURE,
	UPDATE_SMART_CONTRACT,
	UPDATE_SMART_CONTRACT_FAILURE,
	LOAD_IP_ADDRESS_SMART_CONTRACTS,
	LOAD_IP_ADDRESS_SUCCESS,
	OPERATION_FAILED,
	OPERATION_SUCCESS,
	RATES_SMART_CONTRACT,
	RATES_SMART_CONTRACT_SUCCESS,
	RATES_SMART_CONTRACT_FAILED,
	CATALOGS_SMART_CONTRACT_SUCCESS,
	FUNDERS_SMART_CONTRACT_SUCCESS,
	BIDDING_SMART_CONTRACT_SUCCESS,
	SMART_CONTRACT_LINK_SUCCESS,
	LOAD_SMART_CONTRACT_SUCCESS,
	LOAD_SMART_CONTRACT_FAILED,
	SMART_CONTRACT_COUNT_ACTION_GET_DATA_RESPONSE,
	SMART_CONTRACT_COUNT_DATA,
	SMART_CONTRACT_ADDRESS_ACTION_GET_DATA_RESPONSE,
	SMART_CONTRACT_ADDRESS_DATA,
	SMART_CONTRACT_PAYMENT_TERMS,
	UPLOAD_SMART_CONTRACT_RATE_PLAN,
	UPLOAD_SMART_CONTRACT_IMMEDIATE_RATE_PLAN,
	SMART_CONTRACT_RATE_PLAN_ERROR,
	SMART_CONTRACT_RATE_PLAN_SUCCESS,
	CLEAR_SMART_CONTRACT_RATE_PLAN,
	INITIALIZE_SMART_CONTRACT_RATE_PLAN,
	SMART_CONTRACT_PROPOSED_RATES_ACTION,
	SMART_CONTRACT_PROPOSED_RATES_SUCCESS,
	SMART_CONTRACT_PROPOSED_RATES_FAILED,
	SMART_CONTRACT_PROPOSED_RATES_CLEAR,
	SMART_CONTRACT_FUNDING_OFFERS,
	SMART_CONTRACT_NEW_SMART_CONTRACT_DATA,
	SMART_CONTRACT_WALLET_DETAILS_DATA
} from './actions';
import {
	initialSmartContractsData,
	initialRatePlanData,
	initialRatesData,
	initialProposedRatesData,
	getSmartContractsDataState,
	getRatesDataState,
	getSmartContractRatePlanState,
	SMART_CONTRACTS_DATA,
	SMART_CONTRACTS_CATALOG_DATA,
	SMART_CONTRACT_RATES_DATA,
	SMART_CONTRACT_RATE_PLAN_DATA,
	SMART_CONTRACT_PROPOSED_RATES_DATA
} from './selectors';

const initialValues = {
	loadingProjects: false,

	projects: [],
	project: {},
	newSmartContractData: {
		customers: [],
		suppliers: [],
		trafficTypes: [],
		invoicePeriods: [],
		paymentTerms: []
	},
	smartContracts: [],
	ratePlans: [],
	smartContract: {},
}

export default (state = initialValues, { type, payload, meta }) => {
	switch (type) {
		case actions.SET_STATE:
			return { ...state, ...payload };
		case actions.ADD_RATES_PLAN:
			return {
				...state,
				ratePlans: [...state.ratePlans, payload]
			}

		/// things below will get removed

		case INITIAL_SMART_CONTRACTS:
			return {
				...state,
				[SMART_CONTRACTS_DATA]: {
					...initialSmartContractsData
				}
			};

		case LOAD_SMART_CONTRACTS:
			return {
				...state,
				[SMART_CONTRACTS_DATA]: {
					...initialSmartContractsData
				}
			};

		case SUCCESS_LIST_SMART_CONTRACT:
			return {
				...state,
				[SMART_CONTRACTS_DATA]: {
					...getSmartContractsDataState(state),
					...payload,
					errorMessage: null
				}
			};

		case SMART_CONTRACT_PROPOSED_RATES_ACTION:
		case SMART_CONTRACT_PROPOSED_RATES_CLEAR:
			return {
				...state,
				[SMART_CONTRACT_PROPOSED_RATES_DATA]: {
					...initialProposedRatesData
				}
			};

		case SMART_CONTRACT_PROPOSED_RATES_SUCCESS:
			return {
				...state,
				[SMART_CONTRACT_PROPOSED_RATES_DATA]: {
					...initialProposedRatesData,
					info: payload
				}
			};

		case SMART_CONTRACT_PROPOSED_RATES_FAILED:
			return {
				...state,
				[SMART_CONTRACT_PROPOSED_RATES_DATA]: {
					...initialProposedRatesData,
					error: payload
				}
			};

		case INITIALIZE_SMART_CONTRACT_RATE_PLAN:
			return {
				...state,
				[SMART_CONTRACT_RATE_PLAN_DATA]: {
					...getSmartContractRatePlanState(state),
					...initialRatePlanData
				}
			};

		case UPLOAD_SMART_CONTRACT_IMMEDIATE_RATE_PLAN:
		case UPLOAD_SMART_CONTRACT_RATE_PLAN:
			return {
				...state,
				[SMART_CONTRACT_RATE_PLAN_DATA]: {
					...getSmartContractRatePlanState(state),
					file: payload.ratePlanFile,
					loading: true
				}
			};

		case SMART_CONTRACT_RATE_PLAN_ERROR:
			return {
				...state,
				[SMART_CONTRACT_RATE_PLAN_DATA]: {
					...initialRatePlanData,
					info: undefined,
					...payload
				}
			};

		case SMART_CONTRACT_RATE_PLAN_SUCCESS:
			return {
				...state,
				[SMART_CONTRACT_RATE_PLAN_DATA]: {
					...initialRatePlanData,
					info: undefined,
					data: getSmartContractRatePlanState(state).data.concat([payload])
				}
			};

		case CLEAR_SMART_CONTRACT_RATE_PLAN:
			return {
				...state,
				[SMART_CONTRACT_RATE_PLAN_DATA]: {
					...initialRatePlanData,
					info: undefined
				}
			};

		case INITIAL_NEW_SMART_CONTRACT:
			return {
				...state,
				[SMART_CONTRACTS_DATA]: {
					...initialSmartContractsData
				}
			};

		case FAILURE_DATA_SMART_CONTRACT:
			return {
				...state,
				[SMART_CONTRACTS_DATA]: {
					...getSmartContractsDataState(state),
					errorMessage: payload
				}
			};

		case SUCCESS_INITIAL_NEW_SMART_CONTRACT:
			return {
				...state,
				[SMART_CONTRACTS_DATA]: {
					...getSmartContractsDataState(state),
					...payload
				}
			};
		case SUCCESS_DESTINATIONS_TYPE_CARRIER:
			return {
				...state,
				[SMART_CONTRACTS_DATA]: {
					...getSmartContractsDataState(state),
					...payload
				}
			};

		case LOAD_DESTINATIONS_TYPE_CARRIER:
			return {
				...state,
				[SMART_CONTRACTS_DATA]: {
					...getSmartContractsDataState(state),
					...payload
				}
			};

		case LOAD_DESTINATIONS_CARRIER:
			return {
				...state,
				[SMART_CONTRACTS_DATA]: {
					...getSmartContractsDataState(state),
					...payload
				}
			};

		case SUCCESS_DESTINATIONS_CARRIER:
			return {
				...state,
				[SMART_CONTRACTS_DATA]: {
					...getSmartContractsDataState(state),
					...payload
				}
			};

		case LOAD_DIAL_CODES_TYPE_CARRIER_COUNTRY:
			return {
				...state,
				[SMART_CONTRACTS_DATA]: {
					...getSmartContractsDataState(state),
					...payload
				}
			};

		case LOAD_IP_ADDRESS_SMART_CONTRACTS:
			return {
				...state,
				[SMART_CONTRACTS_DATA]: {
					...getSmartContractsDataState(state),
					...payload
				}
			};

		case LOAD_IP_ADDRESS_SUCCESS:
			return {
				...state,
				[SMART_CONTRACTS_DATA]: {
					...getSmartContractsDataState(state),
					...payload
				}
			};

		case UPDATE_SMART_CONTRACT:
		case CREATE_SMART_CONTRACT:
			return {
				...state,
				[SMART_CONTRACTS_DATA]: {
					...getSmartContractsDataState(state),
					...payload
				}
			};
		case UPDATE_SMART_CONTRACT_FAILURE:
		case CREATE_SMART_CONTRACT_FAILURE:
			return {
				...state,
				[SMART_CONTRACTS_DATA]: {
					...getSmartContractsDataState(state),
					...payload
				}
			};
		case RATES_SMART_CONTRACT:
			return {
				...state,
				[SMART_CONTRACT_RATES_DATA]: {
					...initialRatesData,
					loading: true,
					info: undefined
				}
			};
		case RATES_SMART_CONTRACT_SUCCESS:
			return {
				...state,
				[SMART_CONTRACT_RATES_DATA]: {
					...getRatesDataState(state),
					...payload,
					loading: false
				}
			};
		case RATES_SMART_CONTRACT_FAILED:
			return {
				...state,
				[SMART_CONTRACT_RATES_DATA]: {
					...getRatesDataState(state),
					info: {
						error: payload
					},
					loading: false
				}
			};
		case FUNDERS_SMART_CONTRACT_SUCCESS:
		case BIDDING_SMART_CONTRACT_SUCCESS:
		case SMART_CONTRACT_LINK_SUCCESS:
			return {
				...state,
				[SMART_CONTRACTS_DATA]: {
					...getSmartContractsDataState(state),
					...payload
				}
			};
		case CATALOGS_SMART_CONTRACT_SUCCESS:
			return {
				...state,
				[SMART_CONTRACTS_CATALOG_DATA]: {
					// ...getSmartContractsDataState(state),
					...payload
				}
			};
		case LOAD_SMART_CONTRACT_SUCCESS:
			return {
				...state,
				[SMART_CONTRACTS_DATA]: {
					...getSmartContractsDataState(state),
					smartContract: payload
				}
			};
		case LOAD_SMART_CONTRACT_FAILED:
			return {
				...state,
				[SMART_CONTRACTS_DATA]: {
					...getSmartContractsDataState(state),
					...payload,
					errorMessage: payload,
					smartContract: undefined
				}
			};
		case OPERATION_FAILED:
			return {
				...state,
				[SMART_CONTRACTS_DATA]: {
					...getSmartContractsDataState(state),
					...payload,
					messageStatus: undefined
				}
			};
		case OPERATION_SUCCESS:
			return {
				...state,
				[SMART_CONTRACTS_DATA]: {
					...getSmartContractsDataState(state),
					...payload,
					messageStatusError: undefined
				}
			};
		case SMART_CONTRACT_COUNT_ACTION_GET_DATA_RESPONSE:
			return {
				[SMART_CONTRACT_COUNT_DATA]: {
					...payload,
					messageStatusError: undefined
				}
			};
		case SMART_CONTRACT_ADDRESS_ACTION_GET_DATA_RESPONSE:
			return {
				[SMART_CONTRACT_ADDRESS_DATA]: {
					...payload,
					messageStatusError: undefined
				}
			};
		case SMART_CONTRACT_PAYMENT_TERMS:
			return {
				...state,
				[SMART_CONTRACTS_DATA]: {
					...getSmartContractsDataState(state),
					...payload,
					messageStatusError: undefined
				}
			};
		case SMART_CONTRACT_FUNDING_OFFERS:
			return {
				...state,
				[SMART_CONTRACT_FUNDING_OFFERS]: payload
			};
		case SMART_CONTRACT_NEW_SMART_CONTRACT_DATA:
			return {
				...state,
				[SMART_CONTRACT_NEW_SMART_CONTRACT_DATA]: payload
			};
		case SMART_CONTRACT_WALLET_DETAILS_DATA:
			return {
				...state,
				[SMART_CONTRACT_WALLET_DETAILS_DATA]: payload
			};
		default:
			return state;
	}
};
