import React, { memo, useEffect } from 'react';
import { useFormik } from 'formik';
import { CardContent, Grid, Paper, Typography } from '@material-ui/core';
import { InfoTextContainer } from 'containers';
import { RenderFormikInput, RenderFormikSelect, RenderFormikFileUpload, RenderButton, Loader, AuthManager } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { BankingSection } from './components';
import styles from './styles.module.scss';

export default memo(props => {
	const dispatch = useDispatch();
	const loading = useSelector(state => state.myaccount && state.myaccount.loading);
	const countries = useSelector(state => (state.myaccount && state.myaccount.countries) || []);
	const states = useSelector(state => (state.myaccount && state.myaccount.states) || []);
	const businessTypes = useSelector(state => (state.myaccount && state.myaccount.businessTypes) || []);
	const accountData = useSelector(state => (state.myaccount && state.myaccount.accountData) || {});
	const { t } = useTranslation('myaccount/general_information');

	const formik = useFormik({
		initialValues: {
			uploadLogo: '',
			participantName: '',
			countryId: '',
			stateId: '',
			businessTypeId: '',
			taxId: '',
			registrationNumber: '',
			addressLine1: '',
			city: '',
			zipPostalCode: '',
			phonePrefixId: '',
			phoneNumber: '',
			contactPerson: ''
		},
		onSubmit: (values, { resetForm }) => {
			const formData = new FormData();
			Object.keys(values).map(key => {
				formData.append(key, (typeof values[key] === 'object' && JSON.stringify(values[key])) || values[key]);
			});
			if (values.uploadLogo && values.uploadLogo[0]) {
				formData.append('uploadLogo', values.uploadLogo[0], (values.uploadLogo[0] && values.uploadLogo[0].name) || null);
			}
			dispatch({
				type: 'myaccount/UPDATE_ACCOUNT_DETAILS',
				payload: formData
			});
			resetForm();
		},
		validationSchema: Yup.object().shape({
			participantName: Yup.string().required(t('please_input_bank_help', { label: t('bank_name') })),
			countryId: Yup.string().required(t('please_input_bank_help', { label: t('bank_country') })),
			stateId: Yup.string().required(t('please_input_bank_help', { label: t('bank_state_province') })),
			businessTypeId: Yup.string().required(t('please_input_bank_help', { label: t('business_type') })),
			taxId: Yup.string().required(t('please_input_bank_help', { label: t('tax_id') })),
			registrationNumber: Yup.string().required(t('please_input_bank_help', { label: t('registration_number') })),
			addressLine1: Yup.string().required(t('please_input_bank_help', { label: t('beneficiary_address') })),
			city: Yup.string().required(t('please_input_bank_help', { label: t('beneficiary_city') })),
			zipPostalCode: Yup.string().required(t('please_input_bank_help', { label: t('zip_postal') })),
			phonePrefixId: Yup.string().required(t('please_input_bank_help', { label: t('phone_prefix') })),
			phoneNumber: Yup.string().required(t('please_input_bank_help', { label: t('phone_number') })),
			contactPerson: Yup.string().required(t('please_input_bank_help', { label: t('contact_person') }))
		})
	});

	useEffect(() => {
		dispatch({ type: 'myaccount/GET_COUNTRIES' });
		dispatch({ type: 'myaccount/GET_BUSINESS_TYPES' });
		dispatch({ type: 'myaccount/GET_ACCOUNT_DETAILS' });
		formik.resetForm();
	}, []);

	useEffect(() => {
		formik.setValues(accountData);
	}, [accountData]);

	useEffect(() => {
		formik.setFieldValue('phonePrefixId', formik.values.countryId);
		if (formik.values.countryId) {
			dispatch({ type: 'myaccount/GET_STATES', payload: formik.values.countryId });
		}
	}, [formik.values.countryId]);

	useEffect(() => {
		if (formik.values.stateId && states && Array.isArray(states)) {
			if (!states.find(s => s._id === formik.values.stateId)) {
				formik.setFieldValue('stateId', '');
			}
		}
	}, [states, formik]);

	return (
		<div className={styles.container}>
			{(loading && <Loader loading />) || (
				<Grid container spacing={2}>
					<Grid item xs={12} md={8}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={12}>
								<Paper>
									<CardContent>
										<Typography variant="h1" gutterBottom>
											{t('business_profile')}
										</Typography>
										<div>
											<form onSubmit={formik.handleSubmit}>
												<Grid container alignItems="flex-end" spacing={1}>
													<Grid item xs={4}>
														<RenderFormikInput label={t('company_name')} name="participantName" formik={formik} />
													</Grid>
													<Grid item xs={4}>
														<RenderFormikSelect
															formik={formik}
															label={t('country')}
															name="countryId"
															options={(countries && Array.isArray(countries) && countries.map(c => ({ value: c._id, title: c.name }))) || []}
														/>
													</Grid>
													<Grid item xs={4}>
														<RenderFormikFileUpload
															formik={formik}
															name="uploadLogo"
															allowedFileTypes={['.jpg', '.jpeg', '.png']}
															btnText={t('change_logo')}
															PreviewComponent={
																(accountData.logo && (
																	<img
																		src={accountData.logo}
																		alt={t('company_logo')}
																		style={{
																			maxWidth: '100%',
																			maxHeight: 50,
																			objectFit: 'contain'
																		}}
																	/>
																)) ||
																false
															}
														/>
													</Grid>
													<Grid item xs={4}>
														<RenderFormikSelect
															formik={formik}
															label={t('business_type')}
															name="businessTypeId"
															options={
																(businessTypes && Array.isArray(businessTypes) && businessTypes.map(c => ({ value: c._id, title: c.name }))) || []
															}
														/>
													</Grid>
													<Grid item xs={4}>
														<RenderFormikInput label={t('tax_id')} name="taxId" formik={formik} />
													</Grid>
													<Grid item xs={4}>
														<RenderFormikInput label={t('registration_number')} name="registrationNumber" formik={formik} />
													</Grid>
													<Grid item xs={6}>
														<RenderFormikInput label={t('registered_address')} name="addressLine1" formik={formik} />
													</Grid>
													<Grid item xs={4}>
														<RenderFormikInput label={t('city')} name="city" formik={formik} />
													</Grid>
													<Grid item xs={4}>
														<RenderFormikSelect
															formik={formik}
															label={t('state_province_region')}
															name="stateId"
															options={(states && Array.isArray(states) && states.map(c => ({ value: c._id, title: c.name }))) || []}
														/>
													</Grid>

													<Grid item xs={2}>
														<RenderFormikInput label={t('zip_postal')} name="zipPostalCode" formik={formik} />
													</Grid>
													<Grid item xs={6} />
													<Grid item xs={3}>
														<RenderFormikSelect
															formik={formik}
															label={t('country_code')}
															name="phonePrefixId"
															options={
																(countries &&
																	Array.isArray(countries) &&
																	countries.map(c => ({ value: c._id, title: `${c.name} +${c.prefix}` }))) ||
																[]
															}
														/>
													</Grid>
													<Grid item xs={4}>
														<RenderFormikInput type="tel" label={t('phone_number')} name="phoneNumber" formik={formik} />
													</Grid>
													<Grid item xs={5}>
														<RenderFormikInput type="tel" label={t('contact_person')} name="contactPerson" formik={formik} />
													</Grid>
												</Grid>

												<Grid container justify="center">
													<Grid item>
														<br />
														<AuthManager permissions={['myaccount.general.update-profile']}>
															<RenderButton type="submit" color="primary" disabled={formik.isSubmitting} onClick={formik.handleSubmit}>
																{t('update_profile')}
															</RenderButton>
														</AuthManager>
													</Grid>
												</Grid>
											</form>
										</div>
									</CardContent>
								</Paper>
							</Grid>
							<AuthManager
								permissions={[
									'myaccount.general.view-banking',
									'myaccount.general.create-banking',
									'myaccount.general.update-banking',
									'myaccount.general.delete-banking'
								]}
							>
								<Grid item xs={12} md={12}>
									<BankingSection />
								</Grid>
							</AuthManager>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4}>
						{(t('info_text.general_information', { defaultValue: [] }) || []).map(p => (
							// eslint-disable-next-line react/no-danger
							<div dangerouslySetInnerHTML={{ __html: p }} />
						))}
					</Grid>
				</Grid>
			)}
		</div>
	);
});
