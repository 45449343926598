const TableExternalInvoicesStyle = theme => ({
  titleContainer: {
    position: "relative",
    marginBottom: theme.spacing(1) * 2
  },
  viewMaster: {
    position: "absolute",
    left: 500,
    top: 0
  }
});

export default TableExternalInvoicesStyle;
