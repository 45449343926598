import React, { memo, useCallback, useEffect, useState } from 'react';
import { CardContent, Paper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { RenderTable, CurrencyNumber, Loader } from 'components';
import { useTranslation } from 'react-i18next';
import ReportFilters from '../ReportFilters';
import styles from './styles.module.scss';

export default memo(() => {
	const dispatch = useDispatch();

	const loading = useSelector(state => (state.reports && state.reports.loading) || false);
	const tableData = useSelector(state => (state.reports && state.reports.customerReport) || []);
	const { t } = useTranslation('reports/reports');

	const [filters, setFilters] = useState({
		startDate: moment()
			.utc()
			.hour(0)
			.minute(0)
			.seconds(0)
			.millisecond(0),
		endDate: moment()
			.utc()
			.hour(23)
			.minute(0)
			.seconds(0)
			.millisecond(0)
	});

	let totalMinutes = 0;
	let totalAmount = 0;
	(tableData || []).map(obj => {
		if (obj.data) {
			obj.data.map(subobj => {
				totalMinutes += parseFloat(subobj.minutes);
				totalAmount += parseFloat(subobj.amount);
			});
		}
	});

	const triggerCustomerReport = useCallback(() => {
		dispatch({ type: 'reports/GET_CUSTOMER_REPORT', payload: filters });
	}, [filters]);

	const triggerDownloadReport = useCallback(() => {
		dispatch({ type: 'reports/DOWNLOAD_CUSTOMER_REPORT', payload: filters });
	}, [filters]);

	const columns = [
		{
			Header: t('country'),
			accessor: 'country',
			Cell: data => data.value.replace(/['"]+/g, '')
		},
		{
			Header: t('destination_type'),
			accessor: 'destinationType'
		},
		{
			Header: t('destination_carrier'),
			accessor: 'destinationCarrier'
		},
		{
			Header: t('Minutes'),
			className: 'rt-text-right',
			accessor: 'minutes',
			Cell: data => parseFloat(data.value).toFixed(2),
			Footer: table => {
				let total = 0;
				table.data.map(obj => {
					total += parseFloat(obj.minutes);
				});
				return <strong>{total.toFixed(2)}</strong>;
			}
		},
		{
			Header: t('attempts'),
			className: 'rt-text-right',
			accessor: 'attempts'
		},
		{
			Header: t('connected_calls'),
			className: 'rt-text-right',
			accessor: 'connected_calls'
		},
		{
			Header: 'ASR',
			className: 'rt-text-right',
			accessor: 'asr',
			Cell: data => `${parseFloat(data.value).toFixed(0)}%`
		},
		{
			Header: 'ACD',
			className: 'rt-text-right',
			accessor: 'acd'
		},
		{
			Header: 'PDD',
			className: 'rt-text-right',
			accessor: 'pdd'
		},
		{
			Header: t('rate'),
			className: 'rt-text-right',
			accessor: 'rate',
			Cell: props => <CurrencyNumber amount={parseFloat(props.value).toFixed(6)} precision={6} />
		},
		{
			Header: t('billing_inc'),
			className: 'rt-text-right',
			accessor: 'billingIncrement'
		},
		{
			Header: t('amount'),
			className: 'rt-text-right',
			accessor: 'amount',
			Cell: props => <CurrencyNumber amount={parseFloat(props.value).toFixed(6)} precision={6} />,
			Footer: table => {
				let total = 0;
				table.data.map(obj => {
					total += parseFloat(obj.amount);
				});
				return (
					<strong>
						<CurrencyNumber amount={total.toFixed(6)} precision={6} />
					</strong>
				);
			}
		}
	];

	useEffect(() => {
		dispatch({
			type: 'reports/SET_STATE',
			payload: {
				customerReport: []
			}
		});
		triggerCustomerReport();
	}, []);

	return (
		<div className={styles.container}>
			<ReportFilters reportType="customer" onChange={setFilters} values={filters} onRunReport={triggerCustomerReport} onDownload={triggerDownloadReport} />
			{(loading && <Loader loading />) || (
				<div className="report-data">
					{(tableData.length && (
						<Paper>
							<CardContent>
								<h3>{t('summary')}</h3>
								<p>
									<strong>{t('total_minutes')}:</strong> {parseFloat(totalMinutes).toFixed(2)}
								</p>
								<p>
									<strong>{t('total_amount')}:</strong> <CurrencyNumber amount={parseFloat(totalAmount).toFixed(6)} precision={6} />
								</p>
							</CardContent>
						</Paper>
					)) ||
						null}

					{tableData.map((carrier, i) => (
						<Paper key={i}>
							<CardContent>
								<h3>{carrier.carrier}</h3>
								<RenderTable resizable={false} sortable={false} data={carrier.data} style={{ width: '100%' }} columns={columns} filterable={false} />
							</CardContent>
						</Paper>
					))}
				</div>
			)}
		</div>
	);
});
