import React from 'react';
import { RoutesForm } from 'components';
import { Paper, CardContent } from '@material-ui/core';

const initialValues = {
	matchType: 'N',
	// countryMatch: "",
	// countryMatchType: "",
	routeType: 'S',
	digitMatch: '*',
	destinations: [
		{
			type: 'smartContract',
			sessions: '1000',
			cps: '10',
			active: true
		}
	]
};

class RoutesCreateForm extends React.Component {
	render() {
		const { routesData, handleNewRouteSubmit } = this.props;

		const smartContracts = routesData.onlyExternalRoutes ? [] : routesData.smartContracts;

		return (
			<Paper>
				<CardContent>
					<h4>Add new Route</h4>
					<RoutesForm
						initialValues={initialValues}
						routesData={{
							...routesData,
							smartContracts
						}}
						onSubmit={handleNewRouteSubmit}
						form="RoutesCreateForm"
					/>
				</CardContent>
			</Paper>
		);
	}
}

RoutesCreateForm.defaultProps = {
	routesData: {}
};

export default RoutesCreateForm;
