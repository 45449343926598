import React, { useContext, useState, useEffect } from 'react';
import { Grid, Paper, CardContent, Typography, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { FinancialContext } from 'screens/Financials';

import { AuthManager, RenderButton, RenderTransactionAmount, WithDrawForm } from 'components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default ({ toggleModalOpen }) => {
	const { t } = useContext(FinancialContext);
	const dispatch = useDispatch();

	const [withdrawOpen, setWithdrawOpen] = useState(false);

	const toggleWithdrawOpen = () => setWithdrawOpen(!withdrawOpen);

	const { wallet, settings } = useSelector(state => state);

	const userId = useSelector(state => state?.useraccount?.userInfo?.userId);

	const { WALLET_LOADING = true, WALLET_DATA, RESULT_WALLET } = wallet;

	const { totalAvailable = 0, totalBalance, totalPending, totalProjected, bankAccount } = WALLET_DATA.walletData;
	const { PLATFORM_SETTINGS } = settings;
	const { currency } = PLATFORM_SETTINGS;

	const bankAccounts = bankAccount?.length > 0 ? bankAccount : [];

	const thereIsBankAccount = bankAccounts.length > 0 && bankAccount[0]?.bankName;

	const handleWithdrawSubmit = data => {
		dispatch({ type: 'transactions/WALLET_WITHDRAW', payload: { ...data, amountAvailable: parseFloat(totalAvailable).toFixed(2), userId } });
		toggleWithdrawOpen();
	};

	useEffect(() => {
		dispatch({ type: 'transactions/GET_WALLET' });
	}, []);

	useEffect(() => {
		if (RESULT_WALLET.status === 'success') {
			toast.success(RESULT_WALLET.message, {
				position: 'top-right',
				autoClose: 4000
			});
		}

		if (RESULT_WALLET.status === 'error') {
			toast.error(RESULT_WALLET.message, {
				position: 'top-right',
				autoClose: 4000
			});
		}
	}, [RESULT_WALLET]);

	return (
		<Grid container spacing={1}>
			<AuthManager permissions={['financials.view-wallet']}>
				<Grid item xs={12} md={12}>
					<Grid item xs={12}>
						<Paper>
							<CardContent style={{ position: 'relative' }}>
								<Typography variant="h1" gutterBottom>
									{t('wallet', { currency: (currency && currency.currencyName) || 'USD' })}
								</Typography>
								<Table>
									<TableBody>
										<TableRow>
											<TableCell>{t('balance')}</TableCell>
											<TableCell>
												<RenderTransactionAmount amount={parseFloat(totalBalance || 0)} />
											</TableCell>
										</TableRow>

										<TableRow>
											<TableCell>{t('current_available')}</TableCell>
											<TableCell>
												<RenderTransactionAmount amount={parseFloat(totalAvailable || 0)} />
											</TableCell>
										</TableRow>

										{(totalPending && (
											<TableRow>
												<TableCell>{t('pending_balance')}</TableCell>
												<TableCell>
													<RenderTransactionAmount amount={parseFloat(totalPending || 0)} />
												</TableCell>
											</TableRow>
										)) ||
											null}

										{(totalPending && (
											<TableRow>
												<TableCell>{t('projected_balance')}</TableCell>
												<TableCell>
													<RenderTransactionAmount amount={parseFloat(totalProjected || 0)} />
												</TableCell>
											</TableRow>
										)) ||
											null}

										{(!WALLET_LOADING && (
											<TableRow>
												<TableCell />
												<TableCell>
													{thereIsBankAccount ? (
														<div>
															<AuthManager permissions={['financials.create-deposit']}>
																<RenderButton color="primary" onClick={toggleModalOpen} disabled={WALLET_LOADING}>
																	{t('deposit')}
																</RenderButton>
															</AuthManager>
															<AuthManager permissions={['financials.create-withdraw']}>
																<RenderButton color="danger" onClick={toggleWithdrawOpen} disabled={WALLET_LOADING}>
																	{t('withdraw')}
																</RenderButton>
															</AuthManager>
														</div>
													) : (
														<RenderButton onClick={() => redirectAccount()} color="danger">
															{t('update_bank_info')}
														</RenderButton>
													)}
												</TableCell>
											</TableRow>
										)) ||
											null}
									</TableBody>
								</Table>
							</CardContent>
						</Paper>
					</Grid>
					<Grid item xs={12} />
				</Grid>
			</AuthManager>
			<WithDrawForm
				open={withdrawOpen}
				openCloseHandler={toggleWithdrawOpen}
				initialValues={{
					amountAvailable: parseFloat(totalAvailable).toFixed(2)
				}}
				bankAccounts={bankAccounts}
				onSubmit={handleWithdrawSubmit}
			/>
		</Grid>
	);
};
