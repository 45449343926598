import { call, put, select, all, takeEvery } from 'redux-saga/effects';
import apiWallet from 'services/api/walletApi';
import voipApiService from 'services/api/VoipApi';
import { Util } from 'components';
import { fromNotifications } from 'store/selectors';
import { push } from 'connected-react-router';
import actions, {
	// getWallet,
	walletDataSuccess,
	loadVoipApiDataSuccess,
	loadVoipApiDataFailure,
	walletDataFailure,
	getInitialWallet,
	setResultWallet,
	setTransactionsLoading,
	setWalletLoading,
	setWalletTransactions
} from './actions';
import { actionCreatorPreloaderOn, actionCreatorPreloaderOff } from '../preloader/actions';
import { actionCreatorSelectItem } from '../privatemenu/actions';
import { saveAs } from 'file-saver';

export function* getWallets(action) {
	yield put(actionCreatorPreloaderOn());
	try {
		yield put(setWalletLoading(true));
		const resultWallet = yield call(apiWallet.getListWallet, action.payload);
		if (!Util.isErrorResponseWallets(resultWallet)) {
			yield put(walletDataSuccess({ walletData: resultWallet }));
		}
		yield put(setWalletLoading(false));
	} catch (err) {
		const error = err.message || err;
		console.log(`message error ::${error}`);
		yield put(walletDataFailure(error));
	}
	yield put(actionCreatorPreloaderOff());
}

export function* getVoipApiData(action) {
	try {
		yield put(actionCreatorPreloaderOn());
		const voipDataResponse = yield call(voipApiService.loadCarrierReportData, action.payload);
		yield put(loadVoipApiDataSuccess(voipDataResponse));

		yield put(actionCreatorPreloaderOff());
	} catch (err) {
		yield put(actionCreatorPreloaderOff());
		const error = err.message || err;
		console.log(`message error ::${error}`);
		yield put(loadVoipApiDataFailure(error));
	}
}

export function* redirectAccount(action) {
	try {
		yield put(actionCreatorSelectItem('myaccount'));
		let str = '';
		if (action.payload !== undefined) {
			str = `/${action.payload}`;
		}
		yield put(push(`/account/index${str}`));
	} catch (err) {
		const error = err.message || err;
		console.log(`message error ::${error}`);
	}
}

export function* sendTopUp(action) {
	try {
		yield put(actionCreatorPreloaderOn());
		const resultWalletTopUp = yield call(apiWallet.sendTopUp, action.payload);
		yield put(actionCreatorPreloaderOff());
		yield put(setResultWallet(resultWalletTopUp));
		yield put(getInitialWallet());
	} catch (err) {
		yield put(actionCreatorPreloaderOff());
		const error = err.message || err;
		console.log(`message error ::${error}`);
		yield put(walletDataFailure(error));
	}
}

export function* sendWithDraw(action) {
	try {
		yield put(actionCreatorPreloaderOn());
		const resultWalletWithDraw = yield call(apiWallet.sendWithDraw, action.payload);
		let responseObject;
		if (Util.isErrorResponseWallets(resultWalletWithDraw)) {
			responseObject = {
				status: 'error',
				message: resultWalletWithDraw.details === undefined ? 'There was an error!' : resultWalletWithDraw.details
			};
		} else {
			responseObject = {
				status: 'success',
				message: resultWalletWithDraw.status
			};
		}
		yield put(actionCreatorPreloaderOff());
		yield put(setResultWallet(responseObject));
		yield put(getInitialWallet());
	} catch (err) {
		yield put(actionCreatorPreloaderOff());
		const error = err.message || err;
		console.log(`message error ::${error}`);
		yield put(walletDataFailure(error));
	}
}

export function* getWelcomeWallets(action) {
	try {
		yield put(actionCreatorPreloaderOn());

		const resultWallet = yield call(apiWallet.getListWallet, action.payload);

		yield put(walletDataSuccess({ walletData: resultWallet }));

		yield put(actionCreatorPreloaderOff());
	} catch (err) {
		const error = err.message || err;
		console.log(`message error ::${error}`);
		yield put(walletDataFailure(error));
		yield put(actionCreatorPreloaderOff());
	}
}

export function* getWalletTransactions({ payload }) {
	try {
		yield put(actionCreatorPreloaderOn());
		yield put(setTransactionsLoading(true));

		const results = yield call(apiWallet.getTransactions, payload);

		yield put(setWalletTransactions(results));
		yield put(setTransactionsLoading(false));

		yield put(actionCreatorPreloaderOff());
	} catch (err) {
		const error = err.message || err;
		console.log(`message error ::${error}`);
		yield put(walletDataFailure(error));
		yield put(actionCreatorPreloaderOff());
	}
}

export function* exportWalletTransactions({ payload }) {
	try {
		yield put(actionCreatorPreloaderOn());
		yield put(setTransactionsLoading(true));

		const result = yield call(apiWallet.exportTransactions, payload);
		if (result) {
			const blob = new Blob([result]);
			saveAs(blob, `transactions.csv`);
		}
		yield put(setTransactionsLoading(false));

		yield put(actionCreatorPreloaderOff());
	} catch (err) {
		const error = err.message || err;
		console.log(`message error ::${error}`);
		yield put(walletDataFailure(error));
		yield put(actionCreatorPreloaderOff());
	}
}

export function* downloadWalletReport(action) {
	try {
		yield put(actionCreatorPreloaderOn());
		const { date, format } = action.payload;

		const result = yield call(apiWallet.downloadReport, { date }, format);
		if (result && result.status === 'OK') {
			window.location.href = result.downloadUrl;
		}
		yield put(actionCreatorPreloaderOff());
	} catch (err) {
		const error = err.message || err;
		console.log(`message error ::${error}`);
		yield put(walletDataFailure(error));
		yield put(actionCreatorPreloaderOff());
	}
}

export function* GET_TRANSACTIONS_FILTERS({}) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'transactions/SET_STATE',
		payload: {
			loadingFilters: true
		}
	});

	try {
		const result = yield call(apiWallet.getTransactionsFilters);
		if (result) {
			yield put({
				type: 'transactions/SET_STATE',
				payload: {
					transactionsFilters: result
				}
			});
		}
	} catch (error) {
		console.log('GET_TRANSACTIONS_FILTERS ERROR', error);
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}

	yield put({
		type: 'transactions/SET_STATE',
		payload: {
			loadingFilters: false
		}
	});

	yield put(actionCreatorPreloaderOff());
}

export function* GET_WALLET_TRANSACTIONS(payload) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'transactions/SET_STATE',
		payload: {
			TRANSACTIONS_LOADING: true
		}
	});
	try {
		const results = yield call(apiWallet.newGetTransactions, payload);

		if (results) {
			yield put({
				type: 'transactions/SET_STATE',
				payload: {
					WALLET_TRANSACTIONS: results
				}
			});
		}
	} catch (error) {
		console.log('GET_WALLET_TRANSACTIONS ERROR', error);
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: 'transactions/SET_STATE',
		payload: {
			TRANSACTIONS_LOADING: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_WALLET(action) {
	yield put(actionCreatorPreloaderOn());
	yield put({ type: 'transactions/SET_STATE', payload: { WALLET_LOADING: true } });
	try {
		const resultWallet = yield call(apiWallet.newGetListWallet, action.payload);

		if (!Util.isErrorResponseWallets(resultWallet)) {
			yield put({
				type: 'transactions/SET_STATE',
				payload: {
					WALLET_DATA: {
						walletData: resultWallet,
						errorMessage: null
					},
					walletCached: true
				}
			});
		}
	} catch (error) {
		console.log('GET_WALLET', error);
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({ type: 'transactions/SET_STATE', payload: { WALLET_LOADING: false } });
	yield put(actionCreatorPreloaderOff());
}

export function* WALLET_TOPUP(action) {
	yield put(actionCreatorPreloaderOn());
	yield put({ type: 'transactions/SET_STATE', payload: { WALLET_LOADING: true } });
	try {
		const resultWalletTopUp = yield call(apiWallet.newSendTopUp, action.payload);

		if (resultWalletTopUp?.success) {
			yield put({
				type: 'transactions/SET_STATE',
				payload: {
					RESULT_WALLET: {
						status: 'success',
						message: resultWalletTopUp.message
					}
				}
			});
			yield put({
				type: 'transactions/SET_STATE',
				payload: {
					RESULT_WALLET: {
						status: '',
						message: ''
					}
				}
			});
		} else {
			yield put({
				type: 'transactions/SET_STATE',
				payload: {
					RESULT_WALLET: {
						status: 'error',
						message: 'Error while processing the transaction'
					}
				}
			});
			yield put({
				type: 'transactions/SET_STATE',
				payload: {
					RESULT_WALLET: {
						status: '',
						message: ''
					}
				}
			});
		}

		yield put({ type: 'transactions/GET_WALLET' });
	} catch (error) {
		console.log('WALLET_TOPUP ERROR', error);
		yield put({
			type: 'transactions/SET_STATE',
			payload: {
				RESULT_WALLET: {
					status: 'error',
					message: error?.response?.data?.message || 'Communication error - Unable to communicate properly with the endpoint'
				}
			}
		});
		yield put({
			type: 'transactions/SET_STATE',
			payload: {
				RESULT_WALLET: {
					status: '',
					message: ''
				}
			}
		});
	}
	yield put({ type: 'transactions/SET_STATE', payload: { WALLET_LOADING: false } });
	yield put(actionCreatorPreloaderOff());
}

export function* WALLET_WITHDRAW(action) {
	yield put(actionCreatorPreloaderOn());
	yield put({ type: 'transactions/SET_STATE', payload: { WALLET_LOADING: false } });
	try {
		yield put({ type: 'transactions/SET_STATE', payload: { WALLET_LOADING: true } });
		const resultWalletWithDraw = yield call(apiWallet.newSendWithDraw, action.payload);
		if (resultWalletWithDraw?.success) {
			yield put({
				type: 'transactions/SET_STATE',
				payload: {
					RESULT_WALLET: {
						status: 'success',
						message: resultWalletWithDraw.message
					}
				}
			});
			yield put({
				type: 'transactions/SET_STATE',
				payload: {
					RESULT_WALLET: {
						status: '',
						message: ''
					}
				}
			});
		} else {
			yield put({
				type: 'transactions/SET_STATE',
				payload: {
					RESULT_WALLET: {
						status: 'error',
						message: 'Error while processing the transaction'
					}
				}
			});
			yield put({
				type: 'transactions/SET_STATE',
				payload: {
					RESULT_WALLET: {
						status: '',
						message: ''
					}
				}
			});
		}

		yield put({ type: 'transactions/GET_WALLET' });
	} catch (error) {
		console.log('WALLET_TOPUP ERROR', error);
		yield put({
			type: 'transactions/SET_STATE',
			payload: {
				RESULT_WALLET: {
					status: 'error',
					message: error?.response?.data?.message || 'Communication error - Unable to communicate properly with the endpoint'
				}
			}
		});
		yield put({
			type: 'transactions/SET_STATE',
			payload: {
				RESULT_WALLET: {
					status: '',
					message: ''
				}
			}
		});
	}
	yield put({ type: 'transactions/SET_STATE', payload: { WALLET_LOADING: false } });
	yield put(actionCreatorPreloaderOff());
}

export function* DOWNLOAD_WALLET_REPORT(action) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'transactions/SET_STATE',
		payload: {
			RESULT_WALLET: {
				status: 'loading',
				message: ''
			}
		}
	});
	try {
		const { date, format } = action.payload;
		const result = yield call(apiWallet.newDownloadReport, action.payload);

		const formatedDate = new Date(date);
		let month = formatedDate.getMonth() + 1;
		const year = formatedDate.getFullYear();
		month = month < 10 ? '0' + month : month;

		if (format === 'csv' && result?.response?.status !== 500) {
			const blob = new Blob([result]);
			saveAs(blob, `wallet-report-${month}-${year}.csv`);
			yield put({
				type: 'transactions/SET_STATE',
				payload: {
					RESULT_WALLET: {
						status: 'success',
						message: 'Report downloaded in csv format.'
					}
				}
			});
			return;
		}

		if (format === 'pdf') {
			let binaryString = window.atob(result);
			let binaryLen = binaryString.length;
			let bytes = new Uint8Array(binaryLen);

			for (let i = 0; i < binaryLen; i++) {
				let ascii = binaryString.charCodeAt(i);
				bytes[i] = ascii;
			}

			const blob = new Blob([bytes], { type: 'application/pdf' });
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = `wallet-report-${month}-${year}.pdf`;
			link.click();

			yield put({
				type: 'transactions/SET_STATE',
				payload: {
					RESULT_WALLET: {
						status: 'success',
						message: 'Report downloaded in pdf format.'
					}
				}
			});
			return;
		}

		yield put({
			type: 'transactions/SET_STATE',
			payload: {
				RESULT_WALLET: {
					status: 'error',
					message: 'Error while downloading report'
				}
			}
		});
	} catch (err) {
		const error = err.message || err;
		console.log(`message error ::${error}`);
		yield put({
			type: 'transactions/SET_STATE',
			payload: {
				RESULT_WALLET: {
					status: 'error',
					message: 'Error while downloading report'
				}
			}
		});
	} finally {
		yield put(actionCreatorPreloaderOff());
		yield put({
			type: 'transactions/SET_STATE',
			payload: {
				RESULT_WALLET: {
					status: '',
					message: ''
				}
			}
		});
	}
}

export function* CSV_DOWNLOAD_WALLET_TRANSACTIONS(payload) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'transactions/SET_STATE',
		payload: {
			RESULT_WALLET: {
				status: 'loading',
				message: ''
			}
		}
	});
	try {
		const result = yield call(apiWallet.newExportTransactions, payload);
		if (!result) {
			yield put({
				type: 'transactions/SET_STATE',
				payload: {
					RESULT_WALLET: {
						status: 'error',
						message: 'Error while downloading csv'
					}
				}
			});
			return;
		}

		const blob = new Blob([result]);
		saveAs(blob, `transactions.csv`);
		yield put({
			type: 'transactions/SET_STATE',
			payload: {
				RESULT_WALLET: {
					status: 'success',
					message: 'Transaction´s CSV downloaded correctly'
				}
			}
		});
	} catch (err) {
		const error = err.message || err;
		console.log(`message error ::${error}`);
		yield put({
			type: 'transactions/SET_STATE',
			payload: {
				RESULT_WALLET: {
					status: 'error',
					message: 'Error while downloading csv'
				}
			}
		});
	} finally {
		yield put(actionCreatorPreloaderOff());
		yield put({
			type: 'transactions/SET_STATE',
			payload: {
				RESULT_WALLET: {
					status: '',
					message: ''
				}
			}
		});
	}
}

export default function* rootSaga() {
	yield all([takeEvery(actions.GET_TRANSACTIONS_FILTERS, GET_TRANSACTIONS_FILTERS)]);
	yield all([takeEvery(actions.GET_WALLET_TRANSACTIONS, GET_WALLET_TRANSACTIONS)]);
	yield all([takeEvery(actions.GET_WALLET, GET_WALLET)]);
	yield all([takeEvery(actions.WALLET_TOPUP, WALLET_TOPUP)]);
	yield all([takeEvery(actions.WALLET_WITHDRAW, WALLET_WITHDRAW)]);
	yield all([takeEvery(actions.DOWNLOAD_WALLET_REPORT, DOWNLOAD_WALLET_REPORT)]);
	yield all([takeEvery(actions.CSV_DOWNLOAD_WALLET_TRANSACTIONS, CSV_DOWNLOAD_WALLET_TRANSACTIONS)]);
}
