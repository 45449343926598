import React, { memo, useEffect } from 'react';
import { Chip } from '@material-ui/core';
import { NetworkWifi } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { OriginationHardwareProfiles, TerminationHardwareProfiles } from './components';
import styles from './styles.module.scss';

const HardwareProfileTab = memo(() => {
	const dispatch = useDispatch();
	const partitionInfo = useSelector(state => (state.routes && state.routes.partitionInfo) || {});

	useEffect(() => {
		dispatch({
			type: 'routes/GET_PARTITION_INFORMATION'
		});
	}, []);

	return (
		<div className={styles.container}>
			<div className="partition-ip-wrap">
				{(partitionInfo && partitionInfo.ip && <Chip color="primary" avatar={<NetworkWifi />} label={partitionInfo.ip} className="partition-ip" />) || ''}
			</div>

			<OriginationHardwareProfiles />
			<TerminationHardwareProfiles />
		</div>
	);
});

export default HardwareProfileTab;
