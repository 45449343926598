export const initialState = {};

export const initialRecoveryPasswordData = {
  email: null,
  password: null,
  errorMessage: null,
  successMessage: null
};
export const RECOVERY_PASSWORD_DATA = "RECOVERY_PASSWORD_DATA";

export const getRecoveryPasswordDataState = (state = initialState) =>
  state[RECOVERY_PASSWORD_DATA] || initialRecoveryPasswordData;
