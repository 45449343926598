import React from "react";
import { Card } from "reactstrap";

const ExplorerSmartContractItem = props => {
  const supplierWallet =
    props.data !== undefined &&
    props.data.initialValues !== undefined &&
    props.data.initialValues.supplierWallet !== undefined
      ? props.data.initialValues.supplierWallet
      : "";

  const clientWallet =
    props.data !== undefined &&
    props.data.initialValues !== undefined &&
    props.data.initialValues.clientWallet !== undefined
      ? props.data.initialValues.clientWallet
      : "";

  const amountAllocated =
    props.data !== undefined &&
    props.data.initialValues !== undefined &&
    props.data.initialValues.amountAllocated !== undefined
      ? props.data.initialValues.amountAllocated
      : "";

  const amountUsed =
    props.data !== undefined &&
    props.data.initialValues !== undefined &&
    props.data.initialValues.amountUsed !== undefined
      ? props.data.initialValues.amountUsed
      : "";

  const blockNumber =
    props.data !== undefined && props.data.blockNumber !== undefined
      ? props.data.blockNumber
      : "";

  return (
    <Card className="browser_card">
      <h5 className="explorer-card-title">Block Number {blockNumber} </h5>
      <div>
        <b>Transaction type: </b> Contract Creation <br />
        <div>
          <b>Client Wallet: </b>
          {clientWallet}
        </div>
        <div>
          <b>Supplier Wallet: </b>
          {supplierWallet}
        </div>
        <div>
          <b>Amount Allocated: </b>
          {amountAllocated}
        </div>
        <div>
          <b>Amount Used: </b>
          {amountUsed}
        </div>
      </div>
    </Card>
  );
};

export default ExplorerSmartContractItem;
