import React from 'react';
import { Field } from 'redux-form';
import { Grid, MenuItem, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { RenderButton, RenderInput, RenderSelect } from 'components';
import { useTranslation } from 'react-i18next';

const SendInterconnectionsForm = props => {
	const { handleSubmit } = props;
	const { t } = useTranslation('tradingpartners/interconnections');

	return (
		<form onSubmit={handleSubmit}>
			<Grid container alignItems="baseline" spacing={1}>
				<Grid item xs={12}>
					<Typography variant="h1" gutterBottom>
						{t('invite_new_partner')}
					</Typography>
				</Grid>
				<Grid item xs={10}>
					<Field name="mail" component={RenderInput} label={t('email_address')} type="text" fullWidth />
				</Grid>
				{/* <Grid item xs={5}>
					<Field
						type="hidden"
						id="interconnectionType"
						name="interconnectionType"
						label={t('interconnection_type')}
						component={RenderSelect}
						options={[
							{
								value: 'terminator',
								label: t('terminator')
							},
							{
								value: 'originator',
								label: t('originator')
							},
							{
								value: 'both',
								label: t('reciprocal')
							}
						]}
					/>
				</Grid> */}
				<Grid item xs={2}>
					<RenderButton color="primary" type="submit" fullWidth>
						{t('invite')}
					</RenderButton>
				</Grid>
			</Grid>
		</form>
	);
};

SendInterconnectionsForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired
};
export default SendInterconnectionsForm;
