import React, { useContext, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FinancialContext } from 'screens/Financials';
import { RenderButton, CurrencyNumber } from 'components';
import { Grid, Typography, TextField, InputAdornment, Dialog, DialogActions, DialogContent, Slide } from '@material-ui/core';
import { WALLET_TOPUP } from 'store/wallet/actions';

export default ({ modalOpen, toggleModalOpen }) => {
	const [amountTopUp, setAmountTopUp] = useState('');
	const { t } = useContext(FinancialContext);

	const dispatch = useDispatch();

	const paymentRef = useSelector(state => {
		return state?.wallet?.WALLET_DATA?.walletData?.paymentRef;
	});

	const userId = useSelector(state => state?.useraccount?.USER_INFO_DATA?.userInfo?.userId);
	const settings = useSelector(state => state?.settings?.PLATFORM_SETTINGS);

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="up" ref={ref} {...props} />;
	});

	const handleChangeTopUp = useCallback(event => {
		const reg = /^[0-9]+[.]?([0-9]+)?$/;

		if (event.target.value === '' || reg.test(event.target.value)) {
			setAmountTopUp(event.target.value);
		}
	}, []);

	const sendTopUp = useCallback(amountTopUp => {
		dispatch({ type: 'transactions/WALLET_TOPUP', payload: { amountTopUp, paymentRef, userId } });
	});

	return (
		<Dialog maxWidth="sm" fullWidth open={modalOpen} onClose={toggleModalOpen} Transition={Transition}>
			<DialogContent>
				<Grid container alignItems="center">
					<Grid item xs>
						<TextField
							fullWidth
							variant="filled"
							label={t('deposit_modal.amount')}
							value={amountTopUp}
							onChange={handleChangeTopUp}
							InputProps={{
								startAdornment: (
									<InputAdornment variant="filled" position="start">
										<CurrencyNumber amount="" />
									</InputAdornment>
								)
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography valiant="headline">Tess Bank Information</Typography>
						<br />
						<p>
							Please reach out to us by email at <a href="mailto:accounting@tesspay.io">accounting@tesspay.io</a> in order to get the bank account details.
						</p>

						{/*{(settings?.currency?.currencyName || 'USD') === 'EUR' && (
							<>
								<p>
									<strong>{t('bank.account_name')}:</strong> TessPay Finance Inc.
								</p>
								<p>
									<strong>{t('bank.benef_address')}:</strong> 9160 Forum Corporate Parkway, Suite 350, Fort Myers, FL 33905
								</p>
								<p>
									<strong>{t('bank.benef_acc_no')}:</strong> 3304018981
								</p>
								<p>
									<strong>{t('bank.bank_name')}:</strong> Standard Chartered Bank
								</p>
								<p>
									<strong>{t('bank.bank_address')}:</strong> Frankfurt, Germany
								</p>
								<p>
									<strong>{t('bank.bank_aba')}:</strong> N/A
								</p>
								<p>
									<strong>{t('bank.bank_swift')}:</strong> SCBLDEFX
								</p>
							</>
						) || null}
						{(settings?.currency?.currencyName || 'USD') === 'USD' && (
							<>
								<p>
									<strong>{t('bank.account_name')}:</strong> TessPay Finance Inc.
								</p>
								<p>
									<strong>{t('bank.benef_address')}:</strong> 9160 Forum Corporate Parkway, Suite 350, Fort Myers, FL 33905
								</p>
								<p>
									<strong>{t('bank.benef_acc_no')}:</strong> 3304018981
								</p>
								<p>
									<strong>{t('bank.bank_name')}:</strong> Silicon Valley Bank
								</p>
								<p>
									<strong>{t('bank.bank_address')}:</strong> 3003 TASMAN DRIVE, SANTA CLARA, United States
								</p>
								<p>
									<strong>{t('bank.bank_aba')}:</strong> 121140399
								</p>
								<p>
									<strong>{t('bank.bank_swift')}:</strong> SVBKUS6SXXX
								</p>
							</>
						) || null}*/}


						{/*<TextField fullWidth variant="filled" label={t('deposit_modal.ref')} value={paymentRef} disabled error />
						<input type="hidden" name="tesspay-ref" value={paymentRef} />

						<p style={{ textAlign: 'center' }}>
							<img src="https://s3.ca-central-1.amazonaws.com/tesspay-cdn/assets/PP_Acceptance_Marks_for_LogoCenter_76x48.png" />
						</p>
						<p>{t('deposit_modal.paypal')}</p>*/}
					</Grid>
				</Grid>
			</DialogContent>
			{paymentRef && (
				<DialogActions>
					<RenderButton
						color="primary"
						onClick={() => {
							sendTopUp(amountTopUp);
							toggleModalOpen();
						}}
					>
						{t('deposit_modal.btn_submit')}
					</RenderButton>
					<RenderButton color="danger" onClick={toggleModalOpen}>
						{t('translations:close')}
					</RenderButton>
				</DialogActions>
			)}
		</Dialog>
	);
};
