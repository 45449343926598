import { Dialog, DialogContent } from "@material-ui/core";
import React from "react";

const RenderDateRangePickerDialog = props => {
    const {
        open,
        dateRangePicker,
        setDisplayExternalPicker
    } = props

    return (
        <React.Fragment>
            <Dialog
            PaperProps={{
                style: { 
                    backgroundColor: 'transparent',
                    boxShadow: 'none'
                 }
            }}
            maxWidth={'md'} 
            onClose={() => setDisplayExternalPicker(false)}
            open={open}>
                <DialogContent>
                    {dateRangePicker}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )  
}

export default RenderDateRangePickerDialog
