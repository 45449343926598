import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  actionCreatorPreloaderOn,
  actionCreatorPreloaderOff,
  getInitialWelcomeWallet,
  actionSmartContractCountGetDataRequest
} from "store/actions";
import PropTypes from "prop-types";
import { Dashboard } from "components";
import {
  fromWallet,
  fromUseraccount,
  fromSmartcontracts
} from "store/selectors";

class WelcomeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.actionCreatorPreloaderOff();
  }

  render() {
    return <Dashboard />;
  }
}

function mapStateToProps(state) {
  return {
    dataUser: fromUseraccount.getUserInfoDataState(state),
    walletInfo: fromWallet.getWalletData(state),
    resultWallet: fromWallet.getResultWallet(state),
    smartContractsCountData: fromSmartcontracts.getSmartContractCountData(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionCreatorPreloaderOn,
      actionCreatorPreloaderOff,
      getInitialWelcomeWallet,
      actionSmartContractCountGetDataRequest
    },
    dispatch
  );
}

WelcomeContainer.propTypes = {
  actionCreatorPreloaderOn: PropTypes.func.isRequired,
  actionCreatorPreloaderOff: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WelcomeContainer);
