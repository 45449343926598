import React from 'react';

import { Typography, CircularProgress } from '@material-ui/core';

export default ({ loading, invoicesFound = true, invoicesType = 'general' }) => {
	const message = {
		general: 'No invoices found',
		monthly_invoices_per_cust: 'No monthly invoices found',
		external_invoices: 'No external invoices found'
	};

	return (
		<div>
			<Typography variant="body1" align="center">
				{loading && <CircularProgress color="secondary" size={20} />}
				{loading && <span>&nbsp;&nbsp;&nbsp;Loading invoices ...</span>}
				{!loading && !invoicesFound && <Typography variant="body1">{message[invoicesType]}</Typography>}
			</Typography>
		</div>
	);
};
