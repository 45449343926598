import React, {memo, useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import actions from "store/contracts/actions"
import {Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Chip, CardContent} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";
import {contractIdFormatter} from "utils";
import {RenderButton, RenderTable, RenderBadge} from "components";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import moment from "moment"
import uuidv4 from 'uuid/v4';
import styles from "./style.module.scss"


export default memo(() => {
	const dispatch = useDispatch()
	const { t } = useTranslation('routing/routing');

	const [filters, setFilters] = useState({})

	const contracts = useSelector(state => state?.contracts.smartContracts || [])

	const statusFormatter = ({ status, updatedAt }) => {
		if (status === 'draft') {
			return <RenderBadge color="warning">Draft</RenderBadge>;
		}
		if (status === 'terminated') {
			return <RenderBadge color="danger">Terminated</RenderBadge>;
		}
		if (status === 'active') {
			return (
				<RenderBadge color="primary">
					Executed {moment(updatedAt).fromNow()}
				</RenderBadge>
			);
		}
		return <RenderBadge color="warning">{t('pending')}</RenderBadge>;
	};

	const columns = [
		{
			Header: "Contract ID",
			Cell: ({ original: { _id, contractName, partner } }) => contractIdFormatter(_id, contractName, partner?.participantName),
		},
		{
			Header: "Type",
			Cell: ({ original: { isOriginContract } }) => isOriginContract && <Chip label="Origination" color="primary" size="small" /> || <Chip label="Standard" color="secondary" size="small" />,
		},
		{
			Header: "Status",
			Cell: ({ original }) => statusFormatter(original),
		},
		{
			Header: "Actions",
			width: 100,
			Cell: ({ original: { _id } }) => (
				<div>
					<RenderButton color="primary" component={Link} to={`/smart-contracts/${_id}`}>View</RenderButton>
				</div>
			),
		},
	]

	useEffect(() => {
		dispatch({
			type: actions.GET_SMART_CONTRACTS,
			payload: filters
		})
	},[])

	return(
		<div className={styles.container}>
			<Grid container spacing={2}>
				<Grid item xs={10} />
				<Grid item xs={2} justifyContent="flex-end" className="display-flex">
					<RenderButton color="primary" component={Link} to={`/smart-contracts/new`}>
						New Smart Contract
					</RenderButton>
				</Grid>
			</Grid>

			{(!Array.isArray(contracts) || (contracts || []).length === 0) && (
				<Paper>
					<CardContent className="text-center">
						<strong>You have no smart contracts created</strong>
					</CardContent>
				</Paper>
			) || null}

			<div className="contracts-list">



				{Array.isArray(contracts) && (contracts || []).map((item, index) => (
					<Paper key={index} className="contract-item">
						<Accordion elevation={0} >
							<AccordionSummary expandIcon={<ExpandMore />} className="contract-item-header">
								<Grid container spacing={2}>
									<Grid item>
										<div className="heading-title">{item.partner?.participantName}</div>
										<div className="heading-subtitle">{(item.contracts || []).length} contracts</div>
									</Grid>
								</Grid>
							</AccordionSummary>
							<AccordionDetails>
								<RenderTable
									data={item.contracts || []}
									columns={columns}
									showPagination={false}
									sortable={false}
									minRows={2}
									noDataText={(
										<div>
											<strong>There are no smart contracts associated with this partner</strong>
										</div>
									)}
								/>
							</AccordionDetails>
						</Accordion>
					</Paper>
				))}
			</div>

		</div>
	)
})
