import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import invoiceHeaderStyle from './style';

const dateFormatterMMDDYY = v => moment(v, 'MM-DD-YYYY').format('MMM DD YYYY');
const dateFormatter = v => moment(v, 'YYYYMMDD').format('MMM DD YYYY');
const datePlusOneDayFormatter = v =>
	moment(v, 'YYYYMMDD')
		.add(1, 'day')
		.format('MMM DD YYYY');

const CustomTableRow = withStyles({
	root: {
		height: 'auto'
	}
})(TableRow);

const CustomRow = ({ firstCellContent, secondCellContent, thirdCellContent, classes, hideFirst }) => (
	<CustomTableRow>
		{hideFirst || <TableCell className={[classes.w450, classes.noBorderBottom].join(' ')}>{firstCellContent}</TableCell>}
		<TableCell className={[classes.w150, classes.noBorderBottom].join(' ')}>{secondCellContent}</TableCell>
		<TableCell className={[classes.w100, classes.noBorderBottom, classes.alignRight].join(' ')}>{thirdCellContent}</TableCell>
	</CustomTableRow>
);

CustomRow.defaultProps = {
	firstCellContent: '',
	secondCellContent: '',
	thirdCellContent: '',
	hideFirst: false
};

CustomRow.propTypes = {
	hideFirst: PropTypes.bool,
	classes: PropTypes.shape().isRequired,
	firstCellContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	secondCellContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	thirdCellContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

const InvoiceHeader = props => {
	const { t } = useTranslation('financials/general');

	const { data, classes, page, isMaster } = props;
	const invoiceUpperRightFieldMappings =
		page === '1'
			? {
					'': { val: 'NAME', formatter: () => {} },
					[t('invoices.table.inv_number')]: { val: 'INV_NUMBER', formatter: v => v },
					[t('invoices.table.inv_date')]: {
						val: 'DATE_END',
						formatter: datePlusOneDayFormatter
					},
					[t('invoices.table.payment_due_date')]: {
						val: 'dueDate',
						formatter: dateFormatterMMDDYY
					},
					[t('invoices.table.page')]: { val: 'page', formatter: v => v }
			  }
			: {
					[t('invoices.table.customer')]: { val: 'NAME', formatter: v => v },
					[t('invoices.table.inv_number')]: { val: 'INV_NUMBER', formatter: v => v },
					[t('invoices.table.page')]: { val: 'page', formatter: v => v }
			  };

	data.page = page;
	data.customer = data.customer || data.customerData;
	data.supplier = data.supplier || data.supplierData;
	return (
		<Table className={classes.table}>
			<TableBody>
				{page === '1' && (
					<CustomTableRow>
						<TableCell rowSpan={6} className={[classes.w450, classes.noBorderBottom].join(' ')}>
							<div>
								{data.supplier && data.supplier.logo && (
									<img
										src={data.supplier.logo}
										alt={data.supplier.name}
										style={{
											maxWidth: 200,
											maxHeight: 200,
											float: 'left',
											marginRight: 15
										}}
									/>
								)}
								<Typography variant="body1">{data.supplier && data.supplier.name}</Typography>
								<Typography variant="body1">{data.supplierAddress && data.supplierAddress.addressLine1}</Typography>
								<Typography variant="body1">
									{data.supplierAddress && data.supplierAddress.city}
									{data.supplierAddress && data.supplierAddress.zip ? `, ${data.supplierAddress.zip}` : ''}
									{data.supplierAddress && data.supplierAddress.state ? `, ${data.supplierAddress.state}` : ''}
								</Typography>
								<Typography variant="body1">{data.supplier && data.supplier.taxId}</Typography>
							</div>
						</TableCell>
					</CustomTableRow>
				)}
				{Object.keys(invoiceUpperRightFieldMappings).map((key, idx) => {
					const valObj = invoiceUpperRightFieldMappings[key];
					const value = <Typography variant="body1">{valObj.formatter(data[valObj.val])}</Typography>;
					const name = <Typography variant="body2">{key}</Typography>;
					if (valObj && valObj.val === 'dueDate' && valObj.formatter(data[valObj.val]) === 'Invalid date')
						return <CustomRow key={idx} classes={classes} hideFirst={page === '1'} />;
					return <CustomRow key={idx} secondCellContent={name} thirdCellContent={value} classes={classes} hideFirst={page === '1'} />;
				})}
				{page === '1' && (
					<CustomRow
						secondCellContent={<Typography variant="body2">{t('invoices.usage_period')}:</Typography>}
						thirdCellContent={
							<Typography variant="body2">
								{dateFormatter(data.DATE_START)} - {dateFormatter(data.DATE_END)}
							</Typography>
						}
						classes={classes}
					/>
				)}
				{page === '1' && (
					<CustomTableRow>
						<TableCell rowSpan={2} className={[classes.w450, classes.noBorderBottom].join(' ')}>
							<Typography variant="body2">{t('invoices.customer')}</Typography>
							<Typography variant="body1">{data.customer && data.customer.name}</Typography>
							<Typography variant="body1">{data.customerAddress && data.customerAddress.addressLine1}</Typography>
							<Typography variant="body1">
								{data.customerAddress && data.customerAddress.city}
								{data.customerAddress && data.customerAddress.zip ? `, ${data.customerAddress.zip}` : ''}
								{data.customerAddress && data.customerAddress.state ? `, ${data.customerAddress.state}` : ''}
							</Typography>
							<Typography variant="body1">{data.customer && data.customer.taxId}</Typography>
							<Typography variant="body1">
								{t('invoices.customer_number')}: {data.contractName && !isMaster 
								? `${data.contractName} (${data.customer && data.customer.customerNumber})` 
								: `${data.customer && data.customer.customerNumber}`}
							</Typography>
						</TableCell>
					</CustomTableRow>
				)}
			</TableBody>
		</Table>
	);
};

InvoiceHeader.defaultProps = {
	page: '1'
};

InvoiceHeader.propTypes = {
	classes: PropTypes.shape().isRequired,
	data: PropTypes.shape().isRequired,
	page: PropTypes.string
};

export default withStyles(invoiceHeaderStyle)(InvoiceHeader);
