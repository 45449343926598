export const GET_PLATFORM_SETTINGS = 'GET_PLATFORM_SETTINGS';
export const PLATFORM_SETTINGS = 'PLATFORM_SETTINGS';

export const getPlatformSettings = () => ({
	type: GET_PLATFORM_SETTINGS
});

export const setPlatformSettings = data => ({
	type: PLATFORM_SETTINGS,
	payload: data
});
