import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import badgeStyle from "./style";

const RenderBadge = ({ ...props }) => {
  const { classes, color, children } = props;
  return (
    <span className={`${classes.badge} ${classes[color]}`}>{children}</span>
  );
};

RenderBadge.defaultProps = {
  color: "grey"
};

RenderBadge.propTypes = {
  classes: PropTypes.shape().isRequired,
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "warning",
    "danger",
    "success",
    "grey"
  ])
};

export default withStyles(badgeStyle)(RenderBadge);
