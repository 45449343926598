import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { SmartContracts, SmartContractRequestFundingModal, SmartContractAllocateFundingModal, SmartContractUpdateName} from 'components';
import {
	initialSmartContracts,
	loadCatalogsSmartContract,
	loadFundersSmartContract,
	loadBiddingSmartContract,
	terminateSmartContract,
	loadSmartContractsLink,
	saveFunderSmartContract,
	saveBiddingPortalSmartContract,
	saveAllocateSmartContract,
	saveFundingRequest,
	saveAllocateFunding,
	allocateSmartContractFunds,
	getInitialWallet,
	saveUpdateContractName
} from 'store/actions';
import { fromSmartcontracts, fromUseraccount, fromMyaccount, fromWallet } from 'store/selectors';

class SmartContractsContainer extends Component {
	constructor(props) {
		super(props);
		this.terminateSmartContract = this.terminateSmartContract.bind(this);
		this.toggleTerminateSmartContract = this.toggleTerminateSmartContract.bind(this);

		this.allocateSmartContract = this.allocateSmartContract.bind(this);

		this.toggleAllocateSell = this.toggleAllocateSell.bind(this);
		this.changeAllocateSellSelect = this.changeAllocateSellSelect.bind(this);
		this.saveAllocateSell = this.saveAllocateSell.bind(this);
		this.onFieldChangeAllocateSell = this.onFieldChangeAllocateSell.bind(this);

		this.toggleAllocateBuy = this.toggleAllocateBuy.bind(this);
		this.changeAllocateBuySelect = this.changeAllocateBuySelect.bind(this);
		this.saveAllocateBuy = this.saveAllocateBuy.bind(this);
		this.onFieldChangeAllocateBuy = this.onFieldChangeAllocateBuy.bind(this);

		this.handleFundingReview = this.handleFundingReview.bind(this);
		this.sendAllocateFunding = this.sendAllocateFunding.bind(this);
		this.calculateWalletAmmount = this.calculateWalletAmmount.bind(this);
		this.saveContractName = this.saveContractName.bind(this);

		this.state = {
			settlementAmount: undefined,
			interestRate: undefined,
			percentage: undefined,
			idSmartContractTerminate: undefined,
			contractData: undefined,
			modalTerminateSmartContract: false,
			idSmartContractAllocate: undefined,
			modalAllocateSell: false,
			classSelectBidding: false,
			classSelectFunder: false,
			allocateSellInfo: {
				typeAllocateSell: 'funder',
				funderId: undefined,
				dailyAllocatedAmount: undefined,
				paymentTermId: undefined,
				paymentTermNetId: undefined,
				interestRate: undefined
			},
			errorsAllocateSell: [],
			modalAllocateBuy: false,
			classSelectAllocate: false,
			classSelectSmartContractLink: false,
			allocateBuyInfo: {
				typeAllocateBuy: 'allocateAmount',
				smartContractId: undefined,
				allocatedAmount: undefined
			},
			errorsAllocateBuy: [],
			requestFundingModal: null,
			allocateFundingModal: false,
			amountTotal: 0,
			amountAvailable: 0
		};
	}

	handleFundingReview(dataFromChild) {
		this.setState({
			fundingReview: dataFromChild
		});
	}

	componentWillMount() {
		const { dispatch, loadSmartContracts, loadCatalogs, participantInfo, loadInitialWallet } = this.props;

		if (participantInfo != null && participantInfo._id !== undefined) {
			loadSmartContracts();
			loadCatalogs();
			loadInitialWallet(participantInfo.userId);
		} else {
			dispatch(push('/account/index'));
		}
	}

	componentWillReceiveProps(nextProps) {
		const { dailyAllocatedAmount, interestRate } = this.props;

		if (dailyAllocatedAmount !== nextProps.dailyAllocatedAmount) {
			this.setState(prevState => {
				const allocateSellInfo = {
					...prevState.allocateSellInfo
				};
				allocateSellInfo.dailyAllocatedAmount = nextProps.dailyAllocatedAmount;
				return {
					...prevState,
					allocateSellInfo
				};
			});
		}
		if (interestRate !== nextProps.interestRate) {
			this.setState(prevState => {
				const allocateSellInfo = {
					...prevState.allocateSellInfo
				};
				allocateSellInfo.interestRate = nextProps.interestRate;
				return {
					...prevState,
					allocateSellInfo
				};
			});
		}
		const { walletInfo } = nextProps;
		if (walletInfo) {
			let amountTotal = 0;
			let amountAvailable = 0;

			if (walletInfo.wallet) {
				walletInfo.wallet.map(obj => {
					amountTotal += Number.parseFloat(obj.fiatAmount);
				});
				amountAvailable = amountTotal - Number.parseFloat(walletInfo.lockedOnSmartContracts);
			}

			this.setState({
				amountTotal,
				amountAvailable
			});
		}
	}

	calculateWalletAmmount() {
		const { walletInfo } = this.props;
		if (walletInfo) {
			let amountTotal = 0;
			let amountAvailable = 0;

			if (walletInfo.wallet) {
				walletInfo.wallet.map(obj => {
					amountTotal += Number.parseFloat(obj.fiatAmount);
				});
				amountAvailable = amountTotal - Number.parseFloat(walletInfo.lockedOnSmartContracts);
			}

			this.setState({
				amountTotal,
				amountAvailable
			});
		}
	}

	onFieldChangeAllocateSell(nameField, valueField) {
		this.setState(prevState => {
			const allocateSellInfo = {
				...prevState.allocateSellInfo
			};
			allocateSellInfo[nameField] = valueField;
			return {
				...prevState,
				errorsAllocateSell: [],
				allocateSellInfo
			};
		});
	}

	onFieldChangeAllocateBuy(nameField, valueField) {
		this.setState(prevState => {
			const allocateBuyInfo = {
				...prevState.allocateBuyInfo
			};
			allocateBuyInfo[nameField] = valueField;
			return {
				...prevState,
				errorsAllocateBuy: [],
				allocateBuyInfo
			};
		});
	}

	terminateSmartContract(smartContractId) {
		this.setState({
			idSmartContractTerminate: smartContractId,
			modalTerminateSmartContract: true
		});
	}

	toggleTerminateSmartContract() {
		this.setState({
			idSmartContractTerminate: undefined,
			modalTerminateSmartContract: false
		});
	}

	allocateSmartContract(smartContractId, type) {
		if (type === 'Sell') {
			const { loadAllocateSell } = this.props;
			loadAllocateSell(smartContractId);
			this.setState({
				idSmartContractAllocate: smartContractId,
				errorsAllocateSell: [],
				modalAllocateSell: true,
				classSelectBidding: false,
				classSelectFunder: false,
				dailyAllocatedAmount: undefined,
				interestRate: undefined,
				repaymentTerm: undefined,
				allocateSellInfo: {
					typeAllocateSell: undefined,
					funderId: undefined,
					dailyAllocatedAmount: undefined,
					paymentTermId: undefined,
					paymentTermNetId: undefined,
					interestRate: undefined
				}
			});
		} else if (type === 'Buy' || type === 'Funder') {
			const { loadAllocateBuy } = this.props;
			loadAllocateBuy(smartContractId);
			this.setState({
				idSmartContractAllocate: smartContractId,
				errorsAllocateBuy: [],
				modalAllocateBuy: true,
				classSelectAllocate: false,
				classSelectSmartContractLink: false,
				allocateBuyInfo: {
					typeAllocateBuy: undefined,
					smartContractId: undefined,
					allocatedAmount: undefined
				}
			});
		}
	}

	toggleRequestFundingModal = contractData => {
		const { requestFundingModal } = this.state;
		this.setState({
			requestFundingModal: requestFundingModal ? null : (
				<SmartContractRequestFundingModal
					open
					openCloseHandler={this.toggleRequestFundingModal}
					onSubmit={this.sendRequestFunding}
					contractData={contractData}
					initialValues={{
						contractId: contractData.contractTerms.contractId
					}}
				/>
			)
		});
	};

	sendRequestFunding = data => {
		const { sendFundingRequest } = this.props;
		sendFundingRequest(data);
		this.setState({ requestFundingModal: null });
	};

	toggleAllocateFundingModal = contractId => {
		const { allocateFundingModal } = this.state;
		this.setState({
			contractId
		});

		this.setState({ allocateFundingModal: !allocateFundingModal });
	};

	toggleSmartContractUpdateModal = contractId => {
		const { smartContractUpdateModal } = this.state;
		
		this.setState({
			contractId
		});
		this.setState({ smartContractUpdateModal: !smartContractUpdateModal });
	};

	saveAllocateFunding = () => {
		// add logic for aproving the request here
		const { allocateFundingModal, contractId } = this.state;
		const { saveAllocateFunding } = this.props;

		saveAllocateFunding({
			contractId,
			settlementAmount: this.state.fundingReview.settlementAmount,
			interestRate: this.state.fundingReview.interestRate,
			percentage: this.state.fundingReview.percentage
		});

		this.setState({ allocateFundingModal: !allocateFundingModal });
	};

	toggleAllocateSell() {
		this.setState(prevState => {
			const modalAllocateSell = {
				...prevState.modalAllocateSell
			};
			return {
				...prevState,
				modalAllocateSell: !modalAllocateSell,
				idSmartContractAllocate: undefined
			};
		});
	}

	changeAllocateSellSelect(typeAllocateSell) {
		let classSelectBidding;
		let classSelectFunder;
		if (typeAllocateSell === 'bidding') {
			classSelectBidding = true;
			classSelectFunder = false;
		} else {
			classSelectBidding = true;
			classSelectFunder = true;
		}

		this.setState(prevState => {
			const allocateSellInfo = {
				...prevState.allocateSellInfo
			};
			allocateSellInfo.typeAllocateSell = typeAllocateSell;
			return {
				...prevState,
				allocateSellInfo,
				classSelectBidding,
				classSelectFunder,
				errorsAllocateSell: []
			};
		});
	}

	saveAllocateSell() {
		const { allocateSellInfo, idSmartContractAllocate } = this.state;
		const { dailyAllocatedAmount, interestRate, repaymentTerm, saveBiddingPortal, saveFunder } = this.props;
		const errorsAllocateSell = [];

		/* PTI-137 temporary hide */
		// if (allocateSellInfo.typeAllocateSell === undefined) {
		//   errorsAllocateSell.push(
		//     "is necessary to select a funder or send to bidding portal"
		//   );
		//   this.setState({
		//     errorsAllocateSell
		//   });
		//   return;
		// }

		const dataAllocate = {
			smartContractId: idSmartContractAllocate,
			paymentTermId: allocateSellInfo.paymentTermId,
			paymentTermNetId: allocateSellInfo.paymentTermNetId,
			dailyAllocatedAmount: allocateSellInfo.dailyAllocatedAmount,
			interestRate: allocateSellInfo.interestRate
		};
		if (dataAllocate.dailyAllocatedAmount === undefined) dataAllocate.dailyAllocatedAmount = dailyAllocatedAmount;
		else dataAllocate.dailyAllocatedAmount = allocateSellInfo.dailyAllocatedAmount;
		if (dataAllocate.interestRate === undefined) dataAllocate.interestRate = interestRate;
		else dataAllocate.interestRate = allocateSellInfo.interestRate;

		if (_.isNil(dataAllocate.dailyAllocatedAmount)) errorsAllocateSell.push('is necessary to enter daily amount');
		if (_.isNil(dataAllocate.interestRate)) errorsAllocateSell.push('is necessary to enter interes rate');

		if (allocateSellInfo.typeAllocateSell === 'bidding') {
			dataAllocate.repaymentTerm = repaymentTerm;
		} else if (allocateSellInfo.typeAllocateSell === 'funder') {
			dataAllocate.funderId = allocateSellInfo.funderId;
			if (_.isNil(dataAllocate.funderId)) errorsAllocateSell.push('is necessary to select a funder');
			if (_.isNil(dataAllocate.paymentTermId)) errorsAllocateSell.push('is necessary to select a Payment Terms');
			if (_.isNil(dataAllocate.interestRate)) errorsAllocateSell.push('is necessary to enter interes rate');
		}

		if (errorsAllocateSell.length > 0) {
			this.setState({
				errorsAllocateSell
			});
			return;
		}

		if (allocateSellInfo.typeAllocateSell === 'bidding') {
			saveBiddingPortal(dataAllocate);
		} else if (allocateSellInfo.typeAllocateSell === 'funder') {
			saveFunder(dataAllocate);
		}

		this.toggleAllocateSell();
	}

	toggleAllocateBuy() {
		this.setState(prevState => {
			const modalAllocateBuy = {
				...prevState.modalAllocateBuy
			};
			return {
				...prevState,
				modalAllocateBuy: !modalAllocateBuy,
				idSmartContractAllocate: undefined
			};
		});
	}

	changeAllocateBuySelect(typeAllocateBuy) {
		let classSelectAllocate;
		let classSelectSmartContractLink;
		if (typeAllocateBuy === 'allocateAmount') {
			classSelectAllocate = true;
			classSelectSmartContractLink = false;
		} else {
			classSelectAllocate = true;
			classSelectSmartContractLink = true;
		}

		this.setState(prevState => {
			const allocateBuyInfo = {
				...prevState.allocateBuyInfo
			};
			allocateBuyInfo.typeAllocateBuy = typeAllocateBuy;
			return {
				...prevState,
				allocateBuyInfo,
				classSelectAllocate,
				classSelectSmartContractLink,
				errorsAllocateBuy: []
			};
		});
	}

	saveAllocateBuy() {
		const { allocateBuyInfo, idSmartContractAllocate } = this.state;
		const { allocate } = this.props;
		const errorsAllocateBuy = [];

		/* PTI-137 temporary hide */
		// if (allocateBuyInfo.typeAllocateBuy === undefined) {
		//   errorsAllocateBuy.push(
		//     "is necessary to select a funder or send to bidding portal"
		//   );
		//   this.setState({
		//     errorsAllocateBuy
		//   });
		//   return;
		// }

		if (_.isNil(allocateBuyInfo.allocatedAmount)) errorsAllocateBuy.push('is necessary enter allocated amount');

		if (errorsAllocateBuy.length > 0) {
			this.setState({
				errorsAllocateBuy
			});
			return;
		}
		allocate({
			smartContractId: idSmartContractAllocate,
			allocatedContractId: allocateBuyInfo.smartContractId,
			allocatedAmountRequest: allocateBuyInfo.allocatedAmount
		});
		this.toggleAllocateBuy();
	}

	smartContractUpdateModalToggle = contractData => {
		const { smartContractUpdateModal, nickname } = this.state;
		this.setState({
			smartContractUpdateModal: smartContractUpdateModal ? null : (
				<SmartContractUpdateName
					open
					openCloseHandler={this.smartContractUpdateModalToggle}
					onSubmit={this.saveContractName}
					contractData={contractData}
					nickname={nickname}
					initialValues={{
						idContract: contractData.contractTerms.contractId
					}}
				/>
			)
		});
	};

	allocateFundingModalToggle = contractData => {
		const { allocateFundingModal, amountTotal } = this.state;
		const { loadInitialWallet, participantInfo } = this.props;
		loadInitialWallet(participantInfo.userId);
		this.calculateWalletAmmount();
		this.setState({
			allocateFundingModal: allocateFundingModal ? null : (
				<SmartContractAllocateFundingModal
					open
					openCloseHandler={this.allocateFundingModalToggle}
					onSubmit={this.sendAllocateFunding}
					contractData={contractData}
					availableWalletAmount={amountTotal}
					initialValues={{
						contractId: contractData.contractTerms.contractId
					}}
				/>
			)
		});
	};

	sendAllocateFunding = data => {
		this.props.allocateSmartContractFunds(data);
		const { loadInitialWallet, participantInfo } = this.props;
		loadInitialWallet(participantInfo.userId);
		this.calculateWalletAmmount();
		this.setState({ allocateFundingModal: null });
	};
	saveContractName  = data => {
		this.props.saveNickName(data);
		this.setState({ smartContractUpdateModal: null });
	};
	render() {
		const { listSmartContracts } = this.props;
		const { requestFundingModal, allocateFundingModal, smartContractUpdateModal} = this.state;

		return (
			listSmartContracts !== undefined && (
				<div>
					<SmartContracts
						{...this.props}
						terminateSmartContract={this.terminateSmartContract}
						toggleTerminateSmartContract={this.toggleTerminateSmartContract}
						allocateSmartContract={this.allocateSmartContract}
						toggleAllocateSell={this.toggleAllocateSell}
						changeAllocateSellSelect={this.changeAllocateSellSelect}
						onFieldChangeAllocateSell={this.onFieldChangeAllocateSell}
						saveAllocateSell={this.saveAllocateSell}
						toggleAllocateBuy={this.toggleAllocateBuy}
						changeAllocateBuySelect={this.changeAllocateBuySelect}
						onFieldChangeAllocateBuy={this.onFieldChangeAllocateBuy}
						saveAllocateBuy={this.saveAllocateBuy}
						toggleRequestFundingModal={this.toggleRequestFundingModal}
						toggleAllocateFundingModal={this.allocateFundingModalToggle}
						toggleSmartContractUpdateModal={this.smartContractUpdateModalToggle}
						sendRequestFunding={this.sendRequestFunding}
						saveAllocateFunding={this.saveAllocateFunding}
						handleFundingReview={this.handleFundingReview}
						{...this.state}
					/>
					{requestFundingModal}
					{allocateFundingModal}
					{smartContractUpdateModal}
				</div>
			)
		);
	}
}

SmartContractsContainer.propTypes = {
	dispatch: PropTypes.func.isRequired,
	loadSmartContracts: PropTypes.func.isRequired,
	loadAllocateSell: PropTypes.func.isRequired,
	loadAllocateBuy: PropTypes.func.isRequired,
	loadCatalogs: PropTypes.func.isRequired,
	// listSmartContracts: PropTypes.arrayOf(
	//   PropTypes.shape({
	//     _id: PropTypes.string.isRequired,
	//     partner: PropTypes.string.isRequired,
	//     type: PropTypes.string.isRequired,
	//     status: PropTypes.string.isRequired,
	//     isFundedContract: PropTypes.bool.isRequired,
	//     fundingRequested: PropTypes.bool.isRequired,
	//     isOriginContract: PropTypes.bool.isRequired
	//   })
	// ),
	participantInfo: PropTypes.shape({
		id: PropTypes.string
	}).isRequired,
	dailyAllocatedAmount: PropTypes.number,
	interestRate: PropTypes.number,
	repaymentTerm: PropTypes.number
};

SmartContractsContainer.defaultProps = {
	listSmartContracts: undefined,
	dailyAllocatedAmount: undefined,
	interestRate: undefined,
	repaymentTerm: undefined
};

const showButtonNewSmart = roles => roles.includes(ROLES.CARRIER);
const isFunder = roles => roles.includes(ROLES.FUNDER);

const mapStateToProps = state => ({
	listSmartContracts: fromSmartcontracts.getListSmartContractData(state),
	showNewContract: showButtonNewSmart(fromUseraccount.getUserRol(state)),
	isFunder: isFunder(fromUseraccount.getUserRol(state)),
	participantInfo: fromMyaccount.getMyAccountData(state).participant,
	// participantInfo: fromNotifications.getNotifications(state).participant,
	catalogs: fromSmartcontracts.getSmartContractsCatalogDataState(state),
	funderList: fromSmartcontracts.getSmartContractsDataState(state).funderList,
	messageStatusError: fromSmartcontracts.getSmartContractsDataState(state).messageStatusError,
	messageStatus: fromSmartcontracts.getSmartContractsDataState(state).messageStatus,
	dailyAllocatedAmount: fromSmartcontracts.getSmartContractsDataState(state).biddingPortal.dailyAllocatedAmount,
	interestRate: fromSmartcontracts.getSmartContractsDataState(state).biddingPortal.interestRate,
	buySmartcontracts: fromSmartcontracts.getSmartContractsDataState(state).smartContractsLink,
	walletInfo: fromWallet.getWalletData(state)
});

const mapDispatchToProps = dispatch => ({
	dispatch,
	loadSmartContracts() {
		dispatch(initialSmartContracts());
	},
	loadCatalogs() {
		dispatch(loadCatalogsSmartContract());
	},
	loadAllocateSell(smartContractId) {
		dispatch(loadFundersSmartContract());
		dispatch(loadBiddingSmartContract(smartContractId));
	},
	loadAllocateBuy(smartContractId) {
		dispatch(loadSmartContractsLink(smartContractId));
	},
	confirmTerminateSmartContract(smartContractId) {
		dispatch(terminateSmartContract(smartContractId));
	},
	saveFunder(data) {
		dispatch(saveFunderSmartContract(data));
	},
	sendFundingRequest(data) {
		dispatch(saveFundingRequest(data));
	},
	saveAllocateFunding(data) {
		dispatch(saveAllocateFunding(data));
	},
	saveBiddingPortal(data) {
		dispatch(saveBiddingPortalSmartContract(data));
	},
	allocate(data) {
		dispatch(saveAllocateSmartContract(data));
	},
	allocateSmartContractFunds(data) {
		dispatch(allocateSmartContractFunds(data));
	},
	loadInitialWallet(value) {
		dispatch(getInitialWallet(value));
	},
	saveNickName(data) {
		dispatch(saveUpdateContractName(data.idContract,{name:data.nickname}));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(SmartContractsContainer);
