import React, { memo } from 'react';
import { Typography } from '@material-ui/core';
import { RenderTable, CurrencyNumber } from 'components';
import {useTranslation} from "react-i18next";

const ReportSaleAndCost = memo(props => {
	const { tableData, isLoading } = props;
	const { t } = useTranslation('reports/general');

	// const suppliers = tableData || [];
	//
	// const masterTotals = {
	//   minutes: 0,
	//   sale: 0,
	//   cost: 0,
	//   profit: 0
	// };
	//
	// suppliers.map(customer => {
	//   masterTotals.minutes += customer.total.minutes;
	//   masterTotals.sale += customer.total.sale;
	//   masterTotals.cost += customer.total.cost;
	//   masterTotals.profit += customer.total.profit;
	//
	//   return masterTotals;
	// });

	const columns = [
		{
			Header: t('report.country'),
			accessor: 'customer',
			Cell: d => <Typography variant="h1">{d.value}</Typography>,
			style: {
				overflow: 'visible'
			}
			// Footer: <strong style={{ fontSize: "1.2em" }}>Grand Total:</strong>
		},
		{
			Header: t('report.destination_type'),
			accessor: 'customer',
			Cell: () => null
		},
		{
			Header: t('report.destination_carrier'),
			accessor: 'customer',
			Cell: () => null
		},
		{
			Header: t('report.minutes'),
			headerClassName: 'rt-text-right',
			className: 'rt-text-right rt-no-ellipsis',
			accessor: 'customer',
			Cell: () => null
			// Footer: (
			//   <strong style={{ fontSize: "1.2em" }}>
			//     {Number(parseFloat(masterTotals.minutes).toFixed(0)).toLocaleString(
			//       "en"
			//     )}
			//   </strong>
			// )
		},
		{
			Header: t('report.sale_rate'),
			headerClassName: 'rt-text-right',
			accessor: 'customer',
			Cell: () => null
		},
		{
			Header: t('report.buy_rate'),
			headerClassName: 'rt-text-right',
			accessor: 'customer',
			Cell: () => null
		},
		{
			Header: t('report.margin_net'),
			headerClassName: 'rt-text-right',
			accessor: 'customer',
			Cell: () => null
		},
		{
			Header: t('report.billing_inc'),
			headerClassName: 'rt-text-right',
			accessor: 'customer',
			Cell: () => null
		},
		{
			Header: t('report.asr'),
			headerClassName: 'rt-text-right',
			accessor: 'customer',
			Cell: () => null
		},
		{
			Header: t('report.acd'),
			headerClassName: 'rt-text-right',
			accessor: 'customer',
			Cell: () => null
		},
		{
			Header: t('report.connected_calls'),
			headerClassName: 'rt-text-right',
			accessor: 'customer',
			Cell: () => null
		},
		{
			Header: t('report.sale'),
			headerClassName: 'rt-text-right',
			className: 'rt-text-right rt-no-ellipsis',
			accessor: 'customer',
			Cell: () => null
			// Footer: (
			//   <strong style={{ fontSize: "1.2em" }}>
			//     $
			//     {Number(parseFloat(masterTotals.sale).toFixed(0)).toLocaleString(
			//       "en"
			//     )}
			//   </strong>
			// )
		},
		{
			Header: t('report.cost'),
			headerClassName: 'rt-text-right',
			accessor: 'customer',
			className: 'rt-text-right rt-no-ellipsis',
			Cell: () => null
			// Footer: (
			//   <strong style={{ fontSize: "1.2em" }}>
			//     $
			//     {Number(parseFloat(masterTotals.cost).toFixed(0)).toLocaleString(
			//       "en"
			//     )}
			//   </strong>
			// )
		},
		{
			Header: t('report.gross_profit'),
			headerClassName: 'rt-text-right',
			className: 'rt-text-right rt-no-ellipsis',
			accessor: 'customer',
			Cell: () => null
			// Footer: (
			//   <strong style={{ fontSize: "1.2em" }}>
			//     $
			//     {Number(parseFloat(masterTotals.profit).toFixed(0)).toLocaleString(
			//       "en"
			//     )}
			//   </strong>
			// )
		}
	];

	return (
		<RenderTable
			className="defaultExpanded"
			loading={isLoading}
			style={{
				maxHeight: '700px'
			}}
			expanded={{
				0: true,
				1: true,
				2: true,
				3: true,
				4: true,
				5: true,
				6: true,
				7: true,
				8: true,
				9: true
			}}
			data={tableData}
			columns={columns}
			filterable={false}
			sortable={false}
			resizable={false}
			isExpandable
			showExpander={false}
			expanderDefaults={{
				sortable: false,
				resizable: false,
				filterable: false,
				width: 0
			}}
			SubComponent={row => (
				<div>
					<RenderTable
						loading={isLoading}
						className="defaultExpanded"
						expanded={{
							0: true,
							1: true,
							2: true,
							3: true,
							4: true,
							5: true,
							6: true,
							7: true,
							8: true,
							9: true
						}}
						filterable={false}
						sortable={false}
						resizable={false}
						isExpandable
						showExpander={false}
						expanderDefaults={{
							sortable: false,
							resizable: false,
							filterable: false,
							width: 0
						}}
						data={row.original.data}
						columns={[
							{
								Header: '',
								accessor: 'vendor',
								Cell: d => <Typography variant="h2">{d.value}</Typography>,
								Footer: <strong style={{ fontSize: '1.2em' }}>Total:</strong>,
								style: {
									overflow: 'visible'
								}
							},
							{
								Header: '',
								accessor: 'vendor',
								Cell: () => null
							},
							{
								Header: '',
								accessor: 'vendor',
								Cell: () => null
							},
							{
								Header: '',
								accessor: 'vendor',
								className: 'rt-text-right rt-no-ellipsis',
								Cell: () => null,
								Footer: table => {
									let total = 0;
									table.data.map(obj => {
										if (obj._original.data) {
											obj._original.data.map(subobj => {
												total += parseFloat(subobj.minutes);
											});
										}
									});
									return <strong>{total.toFixed(2)}</strong>;
								}
							},
							{
								Header: '',
								accessor: 'vendor',
								Cell: () => null
							},
							{
								Header: '',
								accessor: 'vendor',
								Cell: () => null
							},
							{
								Header: '',
								accessor: 'vendor',
								Cell: () => null
							},
							{
								Header: '',
								accessor: 'vendor',
								Cell: () => null
							},
							{
								Header: '',
								accessor: 'vendor',
								Cell: () => null
							},
							{
								Header: '',
								accessor: 'vendor',
								Cell: () => null
							},
							{
								Header: '',
								accessor: 'vendor',
								Cell: () => null
							},
							{
								Header: '',
								accessor: 'vendor',
								className: 'rt-text-right rt-no-ellipsis',
								Cell: () => null,
								Footer: table => {
									let total = 0;
									table.data.map(obj => {
										if (obj._original.data) {
											obj._original.data.map(subobj => {
												total += parseFloat(subobj.sale);
											});
										}
									});
									return <strong>${total.toFixed(6)}</strong>;
								}
							},
							{
								Header: '',
								accessor: 'vendor',
								className: 'rt-text-right rt-no-ellipsis',
								Cell: () => null,
								Footer: table => {
									let total = 0;
									table.data.map(obj => {
										if (obj._original.data) {
											obj._original.data.map(subobj => {
												total += parseFloat(subobj.cost);
											});
										}
									});
									return <strong>${total.toFixed(6)}</strong>;
								}
							},
							{
								Header: '',
								accessor: 'vendor',
								className: 'rt-text-right rt-no-ellipsis',
								Cell: () => null,
								Footer: table => {
									let total = 0;
									table.data.map(obj => {
										if (obj._original.data) {
											obj._original.data.map(subobj => {
												total += parseFloat(subobj.grossProfit);
											});
										}
									});
									return <strong>${total.toFixed(6)}</strong>;
								}
							}
						]}
						SubComponent={subrow => (
							<div>
								<RenderTable
									resizable={false}
									sortable={false}
									data={subrow.original.data}
									columns={[
										{
											Header: '',
											accessor: 'country',
											Cell: data => data.value.replace(/['"]+/g, '')
										},
										{
											Header: '',
											accessor: 'destinationType'
										},
										{
											Header: '',
											accessor: 'destinationCarrier'
										},
										{
											Header: '',
											className: 'rt-text-right',
											accessor: 'minutes',
											Cell: data => parseFloat(data.value).toFixed(2),
											Footer: table => {
												let total = 0;
												table.data.map(obj => {
													total += parseFloat(obj.minutes);
												});
												return <strong>{total.toFixed(2)}</strong>;
											}
										},
										{
											Header: '',
											className: 'rt-text-right',
											accessor: 'saleRate',
											Cell: props => <CurrencyNumber amount={parseFloat(props.value).toFixed(6)} precision={6} />
										},
										{
											Header: '',
											className: 'rt-text-right',
											accessor: 'buyRate',
											Cell: props => <CurrencyNumber amount={parseFloat(props.value).toFixed(6)} precision={6} />
										},
										{
											Header: '',
											className: 'rt-text-right',
											accessor: 'margin',
											Cell: data => <strong>{parseFloat(data.value).toFixed(2)}%</strong>
										},
										{
											Header: '',
											className: 'rt-text-right',
											accessor: 'billingIncrement'
										},
										{
											Header: '',
											className: 'rt-text-right',
											accessor: 'asr',
											Cell: data => `${parseFloat(data.value).toFixed(0)}%`
										},
										{
											Header: '',
											className: 'rt-text-right',
											accessor: 'acd'
										},
										{
											Header: '',
											className: 'rt-text-right',
											accessor: 'connected_calls'
										},
										{
											Header: '',
											className: 'rt-text-right',
											accessor: 'sale',
											Cell: props => <CurrencyNumber amount={parseFloat(props.value).toFixed(6)} precision={6} />,
											Footer: table => {
												let total = 0;
												table.data.map(obj => {
													total += parseFloat(obj.sale);
												});
												return (
													<strong>
														<CurrencyNumber amount={total.toFixed(6)} precision={6} />
													</strong>
												);
											}
										},
										{
											Header: '',
											className: 'rt-text-right',
											accessor: 'cost',
											Cell: props => <CurrencyNumber amount={parseFloat(props.value).toFixed(6)} precision={6} />,
											Footer: table => {
												let total = 0;
												table.data.map(obj => {
													total += parseFloat(obj.cost);
												});
												return (
													<strong>
														<CurrencyNumber amount={total.toFixed(6)} precision={6} />
													</strong>
												);
											}
										},
										{
											Header: '',
											className: 'rt-text-right',
											accessor: 'grossProfit',
											Cell: props => <CurrencyNumber amount={parseFloat(props.value).toFixed(6)} precision={6} />,
											Footer: table => {
												let total = 0;
												table.data.map(obj => {
													total += parseFloat(obj.grossProfit);
												});
												return (
													<strong>
														<CurrencyNumber amount={total.toFixed(6)} precision={6} />
													</strong>
												);
											}
										}
									]}
									filterable={false}
								/>
							</div>
						)}
					/>
				</div>
			)}
		/>
	);
});

export default ReportSaleAndCost;
