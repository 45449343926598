import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import {
	InvoiceHeader,
	InvoiceSummary,
	InvoiceFooter,
	UsageSummary
} from "components";
import Divider from "@material-ui/core/Divider";
import invoiceStyle from "./style";

class Invoice extends React.Component {
	render() {
		const { classes, data } = this.props;
		return (
			<div>
				{data && data.original ? (
					<Paper className={classes.root} elevation={1}>
						<InvoiceHeader data={data.original} isMaster={data.isMaster} />
						<InvoiceSummary data={data.original} isMaster={data.isMaster} />
						<Divider className={classes.divider} />
						<InvoiceFooter
							isStandard={data.isStandard}
							bankData={data.original.bankData}
							customData={data.original.customData}
						/>
					</Paper>
				) : (
					"Invoice not found"
				)}
				{data && data.original && data.usageSummary ? (
					<Paper className={classes.root} elevation={1}>
						<InvoiceHeader data={data.original} page="2" />
						<UsageSummary data={data.usageSummary} />
					</Paper>
				) : (
					""
				)}
			</div>
		);
	}
}

Invoice.propTypes = {
	classes: PropTypes.shape().isRequired,
	data: PropTypes.shape().isRequired
};

export default withStyles(invoiceStyle)(Invoice);
