import React, { memo, useCallback } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import { RenderButton, RenderFormikInput, RenderFormikSelect, RenderFormikCheckbox } from 'components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const RoutesDestinationRow = memo(({ formik }) => {
	const { smartContracts, hardwareProfiles } = useSelector(state => (state.routes && state.routes.details) || {});
	const { t } = useTranslation('routing/routing');
	const removeField = useCallback(
		index => {
			formik.setFieldValue(
				'destinations',
				formik.values.destinations.filter((el, i) => i !== index)
			);
		},
		[formik, JSON.stringify(formik.values)]
	);
	const moveField = useCallback(
		(from, to) => {
			formik.values.destinations.splice(to, 0, formik.values.destinations.splice(from, 1)[0]);
			formik.setFieldValue('destinations', formik.values.destinations);
		},
		[formik, JSON.stringify(formik.values)]
	);

	const triggerAddNewRoute = useCallback(() => {
		formik.values.destinations.push({
			id: '',
			type: 'smartContract',
			percentage: '',
			sessions: '20000',
			cps: '100',
			active: true
		});
		formik.setFieldValue('destinations', formik.values.destinations);
	}, [formik, JSON.stringify(formik.values)]);
	return (
		<div>
			<Grid container>
				<Grid item xs={2}>
					<Typography variant="h1">{t('routes')}</Typography>
				</Grid>
				<Grid item xs={10} className="text-right">
					<RenderButton color="primary" onClick={triggerAddNewRoute}>
						{t('add_new_route')}
					</RenderButton>
				</Grid>
			</Grid>
			{formik.values.destinations.map((destination, i) => (
				<Grid container key={i} spacing={2}>
					<Grid item xs={formik.values.routeType === 'P' ? 4 : 5}>
						<Grid container spacing={2}>
							<Grid item xs={4}>
								<RenderFormikSelect
									formik={formik}
									label={t('route_types')}
									name={`destinations.${i}.type`}
									value={formik.values.destinations[i].type || ''}
									options={[
										{
											value: 'smartContract',
											label: t('smart_contract')
										},
										{
											value: 'external',
											label: t('external')
										}
									]}
								/>
							</Grid>
							{formik.values.destinations[i].type === 'smartContract' ? (
								<Grid item xs={8}>
									<RenderFormikSelect
										formik={formik}
										label={t('smart_contract')}
										name={`destinations.${i}.id`}
										value={formik.values.destinations[i].id || ''}
										options={smartContracts || []}
									/>
								</Grid>
							) : (
								<Grid item xs={8}>
									<RenderFormikSelect
										formik={formik}
										label={t('connection_profile')}
										name={`destinations.${i}.id`}
										value={formik.values.destinations[i].id || ''}
										options={hardwareProfiles || []}
									/>
								</Grid>
							)}
						</Grid>
					</Grid>
					{formik.values.routeType === 'P' && (
						<Grid item xs={2}>
							<RenderFormikInput
								label={t('percentage')}
								type="number"
								name={`destinations.${i}.percentage`}
								value={formik.values.destinations[i].percentage || ''}
								formik={formik}
							/>
						</Grid>
					)}
					<Grid item xs={2}>
						<RenderFormikInput label={t('ports')} type="number" name={`destinations.${i}.sessions`} value={formik.values.destinations[i].sessions || ''} formik={formik} />
					</Grid>
					<Grid item xs={formik.values.routeType === 'S' ? 1 : 1}>
						<RenderFormikInput label="CPS" type="number" name={`destinations.${i}.cps`} value={formik.values.destinations[i].cps || ''} formik={formik} />
					</Grid>
					{formik.values.routeType === 'S' ? (
						<Grid item xs={1}>
							{i > 0 ? (
								<Button onClick={() => moveField(i, i - 1)}>
									<ArrowUpward />
								</Button>
							) : (
								''
							)}
							{i + 1 < formik.values.destinations.length ? (
								<Button onClick={() => moveField(i, i + 1)}>
									<ArrowDownward />
								</Button>
							) : (
								''
							)}
						</Grid>
					) : (
						''
					)}
					<Grid item xs={1}>
						<RenderFormikCheckbox label={t('active')} name={`destinations.${i}.active`} value={formik.values.destinations[i].active || ''} formik={formik} />
					</Grid>
					<Grid item xs={(formik.values.routeType === 'R' && 3) || 2} className="text-right">
						<RenderButton color="danger" onClick={() => removeField(i)}>
							{t('delete')}
						</RenderButton>
					</Grid>
				</Grid>
			))}
		</div>
	);
});

export default RoutesDestinationRow;
