import { call, put, select } from "redux-saga/effects";
import NotificationsApi from "services/api/NotificationsApi";
import {
  actionCreatorPreloaderOn,
  actionCreatorPreloaderOff,
} from "../preloader/actions";
import { fromNotifications } from "store/selectors";
import { Util } from "components";
import { setNotifications } from "./actions";

export function* getNotifications(action) {
  try {
    yield put(actionCreatorPreloaderOn());
    const dataResult = yield call(
      NotificationsApi.getNotifications,
      action.payload,
    );
    if (Util.isErrorResponseNotifications(dataResult)) {
      const dataInitial = yield select(
        fromNotifications.getInitialNotifications,
      );
      yield put(setNotifications(dataInitial.notifications));
    } else {
      yield put(setNotifications(dataResult));
    }

    yield put(actionCreatorPreloaderOff());
  } catch (err) {
    yield put(setNotifications(undefined));
    yield put(actionCreatorPreloaderOff());
    const error = err.message || err;
    console.error(error);
  }
}
