import React, { Component } from "react";

const InterconnectionsTypeRadio = ({ input, label, type,className,name, meta: { touched, error } }) => {
    return (
        <div>
          <input {...input}  type={type} className={className}/>&nbsp;Terminator &
          Originator&nbsp;<br/>
            {touched && error && <span className="label label-danger">{error}</span>}
        </div>
    );
};

export default InterconnectionsTypeRadio
