import React, { memo, useCallback, useEffect, useState } from 'react';
import { CardContent, Grid, Paper, Typography, Chip } from '@material-ui/core';
import { RenderButton, RenderFormikInput, Loader } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

export default memo(() => {
	const dispatch = useDispatch();

	const loading = useSelector(state => state.myaccount && state.myaccount.loading);
	const user = useSelector(state => (state.myaccount && state.myaccount.accountData) || {});
	const { t } = useTranslation('myaccount/general_information')

	const [changePassword, setChangePassword] = useState(false);

	const formik = useFormik({
		initialValues: {
			email: user && user.email,
			oldPassword: '',
			newPassword: '',
			confirmPassword: ''
		},
		onSubmit: (values, { resetForm }) => {
			dispatch({
				type: 'myaccount/UPDATE_ACCOUNT_PASSWORD',
				payload: values
			});
			setChangePassword(false);
			resetForm();
		},
		validationSchema: Yup.object().shape({
			email: Yup.string().required(),
			oldPassword: Yup.string().required(t('please_input_bank_help', { label: t('old_password') })),
			newPassword: Yup.string().required(t('please_input_bank_help', { label: t('new_password') })),
			confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], t('passwords_not_match'))
		}),
		enableReinitialize: true
	});

	const triggerChangePassword = useCallback(() => {
		formik.resetForm();
		setChangePassword(state => !state);
	}, []);

	return (
		<div className={styles.container}>
			<Paper>
				{(loading && <Loader loading />) || ''}
				<CardContent>
					<div className="header">
						<Typography variant="h1" gutterBottom>
							{t('account_password')}
						</Typography>
					</div>
					{(!changePassword && (
						<p>
							<RenderButton onClick={triggerChangePassword} color="primary">
								{t('change_password')}
							</RenderButton>
						</p>
					)) || (
						<form onSubmit={formik.handleSubmit}>
							<RenderFormikInput type="password" peek label={t('old_password')} name="oldPassword" formik={formik} />
							<RenderFormikInput type="password" peek label={t('new_password')} name="newPassword" formik={formik} />
							<RenderFormikInput type="password" peek label={t('confirm_password')} name="confirmPassword" formik={formik} />

							<div className="actions">
								<RenderButton type="submit" color="primary" disabled={formik.isSubmitting}>
									{`${t('save')} ${t('password')}`}
								</RenderButton>
								<RenderButton
									type="button"
									color="default"
									disabled={formik.isSubmitting}
									onClick={() => {
										formik.resetForm();
										setChangePassword(false);
									}}
								>
									{t('cancel')}
								</RenderButton>
							</div>
						</form>
					)}
				</CardContent>
			</Paper>
		</div>
	);
});
