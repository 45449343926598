import { call, put, takeEvery, all } from 'redux-saga/effects';
import myAccountApiService from 'services/api/MyAccountApi';
import awsamplifyApi from 'services/api/awsamplify';
import { push } from 'connected-react-router';
import actions from './actions';
import { loginSuccess, userInfoSuccess } from '../actions';

export function* VERIFY_IMPERSONATE_CODE({ payload }) {
	try {
		const result = yield call(myAccountApiService.verifyImpersonateToken, payload);
		if (result && result.status && result.status === 'OK') {
			yield put({
				type: 'useraccount/SET_STATE',
				payload: {
					tokenResponse: result
				}
			});
		}
		// eslint-disable-next-line no-empty
	} catch (error) {}
}

export function* IMPERSONATE_LOGIN({ payload: { username, code } }) {
	try {
		const result = yield call(awsamplifyApi.impersonateSignIn, username, code);
		if (result.username != null) {
			const userData = {
				userId: result.signInUserSession.idToken.payload['cognito:username'],
				email: result.signInUserSession.idToken.payload.email,
				name: result.signInUserSession.idToken.payload.name,
				roles: result.signInUserSession.idToken.payload['cognito:groups']
			};

			yield call(myAccountApiService.getIndex, {});

			yield put(userInfoSuccess(userData));
			yield put(loginSuccess());
			yield put(push('/account/index'));
		}

		// eslint-disable-next-line no-empty
	} catch (error) {}
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.VERIFY_IMPERSONATE_CODE, VERIFY_IMPERSONATE_CODE),
		takeEvery(actions.IMPERSONATE_LOGIN, IMPERSONATE_LOGIN),
	]);
}
