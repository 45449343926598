import React, { Component } from "react";
import { Modal, ModalHeader, ModalFooter } from "reactstrap";
import PropTypes from "prop-types";

class CancelOrderForm extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <Modal isOpen={this.props.cancelOrderForm}>
        <form onSubmit={this.props.handleSubmit}>
          <ModalHeader>Are you sure?</ModalHeader>
          <ModalFooter>
            <button
              type="button"
              className="btn-confirm-cancel btn btn-danger"
              data-dismiss="modal"
              onClick={() => this.props.handleSubmit(this.props.idOpenOrder)}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onClick={() => this.props.hideCancelOpenOrder()}
            >
              No
            </button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}
CancelOrderForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  hideCancelOpenOrder: PropTypes.func.isRequired,
};
CancelOrderForm.defaultProps = {
  idOpenOrder: 0,
};
export default CancelOrderForm;
