import { PLATFORM_SETTINGS } from "./actions";

export const defaultState = {
  currency: {
    currencyName: "USD",
    currencySymbol: "$",
    symbolPosition: "prefix"
  }
};

export const getPlatformSettings = (state = {}) =>
  state[PLATFORM_SETTINGS] || defaultState;
