import React, { memo, useState } from 'react';
import { RouteFilters, RouteTable } from './components';
import styles from './styles.module.scss';

const RoutesList = memo(() => {
	const [routeFilters, setRouteFilters] = useState({
		contractType: 'selling',
		customer: '',
		supplier: '',
		country: '',
		destinationType: '',
		destinationCarrier: '',
		contract: ''
	});

	return (
		<div className={styles.container}>
			<RouteFilters triggerOnSubmit={setRouteFilters} />
			<RouteTable filters={routeFilters} />
		</div>
	);
});

export default RoutesList;
