import React from 'react';
import { PrivateTemplate, BreadCrumb } from 'components';
import { useTranslation } from 'react-i18next';
import AcpSmartContractsScreen from 'screens/AcpSmartContracts';

const AcpSmartContractsPage = () => {
	const { t } = useTranslation('acpSmartContract/general');

	return (
		<PrivateTemplate>
			<BreadCrumb name={t('title')} firstLevel="Home" secondLevel={t('title')} />
			<AcpSmartContractsScreen />
		</PrivateTemplate>
	);
};

export default AcpSmartContractsPage;
