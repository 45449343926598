import React from "react";
import { InfoTessPay } from "components";

const InfoTessPayContainer = () => (
  <div>
    <InfoTessPay />
  </div>
);

export default InfoTessPayContainer;
