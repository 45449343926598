const invoiceSummaryStyle = theme => ({
  table: {
    minWidth: 660
  },
  w285: {
    minWidth: 285
  },
  w125: {
    minWidth: 125
  },
  headline: {
	  padding: 25,
	  fontWeight: 'bold',
	  display: 'block',
	  fontSize: '16px'
  }
});

export default invoiceSummaryStyle;
