import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ExplorerSmartContractItem } from "components";
import { fromExplorer } from "store/selectors";

class ExplorerSmartContractsContainer extends Component {
  render() {
    const { smartContracts, address, status } = this.props;

    if (
      smartContracts !== undefined &&
      Object.keys(smartContracts).length > 0
    ) {
      const filtered =
        smartContracts[address] !== undefined
          ? smartContracts[address]
          : undefined;

      if (filtered !== undefined) {
        const { response } = filtered;

        if (response !== undefined) {
          return Object.values(response).map(item => (
            <ExplorerSmartContractItem key={Math.random()} data={item} />
          ));
        }
      }
    }

    if (status !== "finished") {
      return `${status} ${"..."}`;
    }

    return "No Transactions";
  }
}

function mapStateToProps(state) {
  return {
    smartContracts: fromExplorer.getSmartContractExplorerGetDataRequest(state),
    status: fromExplorer.getSmartContractExplorerDataStatus(state)
  };
}

export default connect(
  mapStateToProps,
  null
)(ExplorerSmartContractsContainer);
