import actions from './actions';

const initialState = {
	TICKETS: {
		data: [],
		loading: false,
		metadata: {
			page: 1,
			perPage: 10,
			total: 0
		}
	},
	THREADS: {
		data: [],
		loading: false,
		metadata: {
			page: 1,
			perPage: 10,
			total: 0
		}
	}
};

export default function(state = initialState, action) {
	switch (action.type) {
		case actions.SET_STATE:
			return { ...state, ...action.payload };

		case actions.SET_TICKETS_STATE:
			return { ...state, TICKETS: { ...state.TICKETS, ...action.payload } };

		case actions.SET_THREADS_STATE:
			return { ...state, THREADS: { ...state.THREADS, ...action.payload } };
		default:
			return state;
	}
}
