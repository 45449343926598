/* eslint-disable react/prop-types */
import { CircularProgress, DialogActions, Grid, Typography } from '@material-ui/core';
import { ExternalClientFields, RenderButton, RenderFileUpload, RenderInput, RenderMaterialSwitch, RenderSelect, TechnicalInformationIpAddresses } from 'components';
import { Field, FieldArray, change, reduxForm } from 'redux-form';
import React, { Component } from 'react';
import { commaSeparatedDigits, createValidator, minLengthChecked, required, requiredString, validateTechnicalInformationIpAddresses } from 'services/validation';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import techProfileStyles from './style';

const ignoreEarlyMediaOptions = [
	{
		value: 'none',
		label: 'NONE'
	}
];
const dtmfOptions = [
	{
		value: 'sip_info',
		label: 'SIP INFO'
	},
	{
		value: 'rfc_2833',
		label: 'RFC 2833'
	},
	{
		value: 'inband',
		label: 'INBAND'
	}
];
// PTI-89 - User Dipping hidden for now
const userDippingFromOptions = [
	{
		value: 'lrn_server',
		label: 'LRN Server'
	}
];

class TechnicalInformationForm extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		const { dispatch, form, technicalFormType } = this.props;
		dispatch(change(form, 'type', technicalFormType));
	}

	showLoader = (data, classes) => {
		if (data && data.loading) {
			const fname = data.file && data.file.name;
			return (
				<div>
					<Typography variant="body1" align="center" className={classes.loadingText}>
						<CircularProgress color="secondary" size={20} />
						<span>&nbsp;&nbsp;&nbsp;Uploading {fname} ...</span>
					</Typography>
				</div>
			);
		}
		return false;
	};

	renderVendorRatesSection() {
		const allowedFileTypes = ['.csv', '.xlsx', '.xls'];
		const {
			technicalFormType,
			uploadRates,
			initialValues: { tempId }
		} = this.props;
		if (technicalFormType === 'egress') {
			return (
				<Grid item xs={12}>
					<Grid container style={{ paddingTop: 15 }}>
						{technicalFormType === 'egress' ? (
							<Field
								type="file"
								component={RenderFileUpload}
								allowedFileTypes={allowedFileTypes}
								btnText="Upload rates"
								className="form-control"
								name="ratesFile"
								handleUploadFn={evt => {
									const fd = evt && evt.target && evt.target.files && evt.target.files[0];
									// handle Vendor Rate Upload
									uploadRates(tempId, fd);
								}}
							/>
						) : (
							''
						)}
					</Grid>
				</Grid>
			);
		}
		return false;
	}

	renderExternalRatesSection() {
		const allowedFileTypes = ['.csv', '.xlsx', '.xls'];
		const {
			uploadExternalRates,
			initialValues: { tempId },
			externalRatePlanUploadData,
			classes
		} = this.props;

		return (
			this.showLoader(externalRatePlanUploadData, classes) || (
				<Grid item xs={12}>
					<Grid container style={{ paddingTop: 15 }}>
						<Field
							type="file"
							component={RenderFileUpload}
							allowedFileTypes={allowedFileTypes}
							btnText="Upload external client rates"
							className="form-control"
							name="ratesFile"
							handleUploadFn={evt => {
								const fd = evt && evt.target && evt.target.files && evt.target.files[0];
								// handle external Rate Upload
								uploadExternalRates(tempId, fd);
							}}
						/>
					</Grid>
				</Grid>
			)
		);
	}

	renderExternalClientSection() {
		const {
			technicalFormType,
			initialValues: { tempId },
			countries,
			statesDataArray,
			getStatesData
		} = this.props;

		if (technicalFormType === 'ingress') {
			return (
				<React.Fragment>
					<ExternalClientFields tempId={tempId} countries={countries} statesDataArray={statesDataArray} getStatesData={getStatesData} />
					{this.renderExternalRatesSection()}
				</React.Fragment>
			);
		}
		return false;
	}

	render() {
		const showLoader = data => {
			const { classes } = this.props;
			if (data && data.loading) {
				const fname = data.file && data.file.name;
				return (
					<div>
						<Typography variant="body1" align="center" className={classes.loadingText}>
							<CircularProgress color="secondary" size={20} />
							<span>&nbsp;&nbsp;&nbsp;Uploading {fname} ...</span>
						</Typography>
					</div>
				);
			}
			return false;
		};

		const {
			cancel,
			handleSubmit,
			hardwareProfileOptionsData: { voipProtocols, codecs },
			submitSucceeded,
			technicalFormType,
			validateUniqueName,
			customError,
			ratePlanUploadData
		} = this.props;

		return (
			<form onSubmit={handleSubmit} autoComplete="off">
				<Field component="input" type="hidden" name="_id" />
				<Field component="input" type="hidden" name="type" />
				<Field component="input" type="hidden" name="tempId" />
				<Grid container>
					<Grid item xs={12} md={6}>
						<Field component={RenderInput} label="Profile Name *" name="name" onChange={validateUniqueName} customError={customError} />
					</Grid>
					{technicalFormType === 'egress' && (
						<Grid item xs={12} md={3}>
							<Field component={RenderInput} label="Tech Prefix" name="techPrefix" />
						</Grid>
					)}
					<Grid item xs={12}>
						<br />
						<FieldArray component={TechnicalInformationIpAddresses} name="ipAddresses" />
					</Grid>
					<Grid item xs={12}>
						<br />
						<Typography variant="h2" gutterBottom>
							Basic
						</Typography>
					</Grid>
					<Grid item xs={12} md={1}>
						<Field
							name="voipProtocolId"
							label="VoIP Protocol"
							component={RenderSelect}
							disabled
							options={voipProtocols.map(option => ({
								value: option.voipProtocolId,
								label: option.voipProtocolName
							}))}
						/>
					</Grid>
					<Grid item xs={12} md={8}>
						<Field
							name="hardwareCodecs"
							label="Codecs"
							component={RenderSelect}
							multiple
							disabled
							options={codecs.map(option => ({
								value: option.codexId,
								label: option.codexName
							}))}
						/>
					</Grid>
					<Grid item xs={12} md={1}>
						<Field
							name="dtmf"
							label="DTMF"
							component={RenderSelect}
							disabled
							options={dtmfOptions.map(option => ({
								value: option.value,
								label: option.label
							}))}
						/>
					</Grid>
					<Grid item xs={12} md={1}>
						<Field component={RenderInput} label="Dial String Format" name="dialStringFormat" disabled />
					</Grid>
					{technicalFormType === 'egress' && (
						<Grid item xs={12}>
							<br />
							<Typography variant="h2" gutterBottom>
								Advanced Options
							</Typography>

							<Grid container>
								<Grid item xs={12} md={3}>
									<Field
										name="ignoreEarlyMedia"
										label="Ignore Early Media"
										component={RenderSelect}
										options={ignoreEarlyMediaOptions.map(option => ({
											value: option.value,
											label: option.label
										}))}
									/>
								</Grid>
								<Grid item xs={12} md={1}>
									<Field component={RenderInput} label="CPS Limit" name="cpsLimit" />
								</Grid>
								<Grid item xs={12} md={2}>
									<Field component={RenderInput} label="PDD Timeout (s)" name="pddTimeout" />
								</Grid>
								<Grid item xs={12} md={2}>
									<Field component={RenderInput} label="Max Duration (s)" name="maxDuration" />
								</Grid>
								<Grid item xs={12} md={1}>
									<Field component={RenderInput} label="Ring Timer (s)" name="ringTimer" />
								</Grid>
								<Grid item xs={12}>
									<Grid container>
										<Grid item>
											<Field component={RenderMaterialSwitch} label="Active" name="active" />
										</Grid>
										<Grid item>
											<Field component={RenderMaterialSwitch} label="T.38" name="t38" />
										</Grid>
										<Grid item>
											<Field component={RenderMaterialSwitch} label="Ignore Early NOSDP" name="ignoreEarlyNOSDP" />
										</Grid>
										<Grid item>
											<Field component={RenderMaterialSwitch} label="Proxy Media" name="proxyMedia" />
										</Grid>
									</Grid>
								</Grid>
								{showLoader(ratePlanUploadData) || this.renderVendorRatesSection()}
							</Grid>
							{/* // PTI-89 - User Dipping hidden for now
                    <div className="form-group">

                      <Field
                        name="userDippingFrom"
                        label="User Dipping From"
                        component={RenderMaterialSelect}
                      >
                        {userDippingFromOptions.map((item, i) => (
                          <MenuItem value={item.value} label={item.label} key={i}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </div> */}
							{/* // PTI-89 - User Dipping hidden for now
                    <div className="form-group">
                      <Field
                        component={RenderInput}
                        label="Min Duration (s)"
                        name="minDuration"
                      />
                  </div> */}
						</Grid>
					)}
					{this.renderExternalClientSection()}
				</Grid>

				<DialogActions>
					{typeof cancel !== 'undefined' ? (
						<RenderButton onClick={() => cancel()} color="danger">
							Cancel
						</RenderButton>
					) : null}

					<RenderButton color="primary" type="submit" disabled={customError !== false || submitSucceeded}>
						Save
					</RenderButton>
				</DialogActions>
			</form>
		);
	}
}

TechnicalInformationForm.defaultProps = {
	cancel: undefined
};

TechnicalInformationForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	cancel: PropTypes.func,
	uploadRates: PropTypes.func.isRequired
};

const validate = createValidator({
	techPrefix: [commaSeparatedDigits],
	name: [requiredString],
	voipProtocolId: [required],
	hardwareCodecs: [required, minLengthChecked(1)],
	dtmf: [required],
	dialStringFormat: [requiredString],
	ipAddresses: [validateTechnicalInformationIpAddresses]
});

TechnicalInformationForm = withStyles(techProfileStyles)(TechnicalInformationForm);

export default reduxForm({
	destroyOnUnmount: true,
	touchOnBlur: false,
	validate,
	form: 'TechnicalInformationForm'
})(TechnicalInformationForm);
