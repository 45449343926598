import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Paper,
  Grid,
  Typography,
  CircularProgress,
  CardContent
} from "@material-ui/core";
import AttachMoney from "@material-ui/icons/AttachMoney";
import { Scrollbars } from "react-custom-scrollbars";
import { RenderChart, RenderTable } from "components";
import welcomeChartsStyles from "./style";

const alcConfig = {
  chart: {
    type: "area",
    spacingBottom: 8,
    spacingTop: 8,
    spacingLeft: 8,
    spacingRight: 8,
    height: "50%"
  },
  credits: {
    enabled: false
  },
  title: {
    text: "",
    floating: true,
    margin: 0
  },
  legend: {
    enabled: true,
    floating: false,
    align: "left"
  },
  tooltip: {
    headerFormat: "",
    pointFormat:
      '<span style="color:{point.color}">●</span> ACD: <b>{point.y}m</b>'
  },
  yAxis: {
    min: 0,
    title: {
      text: "seconds"
    }
  },
  xAxis: {
    categories: [
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23"
    ]
  },
  series: [
    {
      name: "ACD",
      data: [
        29.9,
        71.5,
        106.4,
        129.2,
        144.0,
        176.0,
        135.6,
        148.5,
        194.1,
        295.6,
        454.4,
        216.4,
        176.0,
        106.4,
        71.5,
        29.9,
        129.2,
        144.0,
        135.6,
        148.5,
        216.4,
        194.1,
        295.6,
        454.4
      ]
    }
  ]
};

const pieConfig = {
  chart: {
    type: "pie",
    spacingBottom: 8,
    spacingTop: 8,
    spacingLeft: 8,
    spacingRight: 8,
    height: "50%"
  },
  credits: {
    enabled: false
  },
  title: {
    text: "",
    floating: true,
    margin: 0
  },
  tooltip: {
    headerFormat: "",
    pointFormat:
      '<span style="color:{point.color}">●</span> Calls: <b>{point.y}%</b>'
  },
  series: [
    {
      minPointSize: 10,
      innerSize: "60%",
      zMin: 0,
      name: "countries",
      data: [
        {
          name: "Spain",
          y: 35,
          sliced: true,
          selected: true
        },
        {
          name: "France",
          y: 15
        },
        {
          name: "Poland",
          y: 10
        },
        {
          name: "Czech Republic",
          y: 8
        },
        {
          name: "Italy",
          y: 12
        },
        {
          name: "Switzerland",
          y: 10
        },
        {
          name: "Germany",
          y: 10
        }
      ]
    }
  ]
};

const otherConfig = {
  chart: {
    type: "area",
    spacingBottom: 8,
    spacingTop: 8,
    spacingLeft: 8,
    spacingRight: 8,
    height: "50%"
  },
  credits: {
    enabled: false
  },
  title: {
    text: "",
    floating: true,
    margin: 0
  },
  legend: {
    enabled: true,
    floating: false,
    align: "left"
  },
  xAxis: {
    categories: [
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23"
    ],
    crosshair: true
  },
  yAxis: {
    min: 0,
    title: {
      text: "hourly avg"
    }
  },
  tooltip: {
    headerFormat: "",
    pointFormat:
      '<span style="color:{point.color}">●</span> ASR: <b>{point.y}%</b>'
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0
    }
  },
  series: [
    {
      name: "ASR",
      data: [
        49.9,
        71.5,
        99.4,
        100,
        44.0,
        76.0,
        35.6,
        48.5,
        16.4,
        94.1,
        95.6,
        54.4,
        49.9,
        71.5,
        6.4,
        29.2,
        44.0,
        76.0,
        35.6,
        48.5,
        16.4,
        94.1,
        95.6,
        54.4,
        45.2,
        58.3
      ]
    }
  ]
};

const columns = [
  {
    Header: "Contract",
    accessor: "contract"
  },
  {
    Header: "Customer",
    accessor: "customer"
  },
  {
    Header: "Minutes",
    accessor: "minutes"
  },
  {
    Header: "ASR",
    accessor: "asr",
    Cell: props => props.value
  },
  {
    Header: "ACD",
    accessor: "acd"
  },
  {
    Header: "Earnings",
    accessor: "earnings",
    Cell: props => `$${props.value}`
  }
];

const columns2 = [
  {
    Header: "Contract",
    accessor: "contract"
  },
  {
    Header: "Supplier",
    accessor: "supplier"
  },
  {
    Header: "Minutes",
    accessor: "minutes"
  },
  {
    Header: "ASR",
    accessor: "asr",
    Cell: props => props.value
  },
  {
    Header: "ACD",
    accessor: "acd"
  },
  {
    Header: "Earnings",
    accessor: "earnings",
    Cell: props => `$${props.value}`
  }
];

const data = {
  result: {
    items: [
      {
        contract: "12A43",
        customer: "My Company",
        minutes: "120m",
        asr: "99%",
        acd: "2m",
        earnings: "5698"
      },
      {
        contract: "12A43",
        customer: "My Company",
        minutes: "120m",
        asr: "99%",
        acd: "2m",
        earnings: "5698"
      },
      {
        contract: "12A43",
        customer: "My Company",
        minutes: "120m",
        asr: "99%",
        acd: "2m",
        earnings: "5698"
      },
      {
        contract: "12A43",
        customer: "My Company",
        minutes: "120m",
        asr: "99%",
        acd: "2m",
        earnings: "5698"
      },
      {
        contract: "12A43",
        customer: "My Company",
        minutes: "120m",
        asr: "99%",
        acd: "2m",
        earnings: "5698"
      },
      {
        contract: "12A43",
        customer: "My Company",
        minutes: "120m",
        asr: "99%",
        acd: "2m",
        earnings: "5698"
      },
      {
        contract: "12A43",
        customer: "My Company",
        minutes: "120m",
        asr: "99%",
        acd: "2m",
        earnings: "5698"
      },
      {
        contract: "12A43",
        customer: "My Company",
        minutes: "120m",
        asr: "99%",
        acd: "2m",
        earnings: "5698"
      },
      {
        contract: "12A43",
        customer: "My Company",
        minutes: "120m",
        asr: "99%",
        acd: "2m",
        earnings: "5698"
      },
      {
        contract: "12A43",
        customer: "My Company",
        minutes: "120m",
        asr: "99%",
        acd: "2m",
        earnings: "5698"
      },
      {
        contract: "12A43",
        customer: "My Company",
        minutes: "120m",
        asr: "99%",
        acd: "2m",
        earnings: "5698"
      },
      {
        contract: "12A43",
        customer: "My Company",
        minutes: "120m",
        asr: "99%",
        acd: "2m",
        earnings: "5698"
      },
      {
        contract: "12A43",
        customer: "My Company",
        minutes: "120m",
        asr: "99%",
        acd: "2m",
        earnings: "5698"
      }
    ]
  }
};

const data2 = {
  result: {
    items: [
      {
        contract: "12A43",
        supplier: "Other Company",
        minutes: "120m",
        asr: "99%",
        acd: "2m",
        earnings: "5698"
      },
      {
        contract: "12A43",
        supplier: "Other Company",
        minutes: "120m",
        asr: "99%",
        acd: "2m",
        earnings: "5698"
      },
      {
        contract: "12A43",
        supplier: "Other Company",
        minutes: "120m",
        asr: "99%",
        acd: "2m",
        earnings: "5698"
      },
      {
        contract: "12A43",
        supplier: "Other Company",
        minutes: "120m",
        asr: "99%",
        acd: "2m",
        earnings: "5698"
      },
      {
        contract: "12A43",
        supplier: "Other Company",
        minutes: "120m",
        asr: "99%",
        acd: "2m",
        earnings: "5698"
      },
      {
        contract: "12A43",
        supplier: "Other Company",
        minutes: "120m",
        asr: "99%",
        acd: "2m",
        earnings: "5698"
      },
      {
        contract: "12A43",
        supplier: "Other Company",
        minutes: "120m",
        asr: "99%",
        acd: "2m",
        earnings: "5698"
      },
      {
        contract: "12A43",
        supplier: "Other Company",
        minutes: "120m",
        asr: "99%",
        acd: "2m",
        earnings: "5698"
      },
      {
        contract: "12A43",
        supplier: "Other Company",
        minutes: "120m",
        asr: "99%",
        acd: "2m",
        earnings: "5698"
      },
      {
        contract: "12A43",
        supplier: "Other Company",
        minutes: "120m",
        asr: "99%",
        acd: "2m",
        earnings: "5698"
      },
      {
        contract: "12A43",
        supplier: "Other Company",
        minutes: "120m",
        asr: "99%",
        acd: "2m",
        earnings: "5698"
      },
      {
        contract: "12A43",
        supplier: "Other Company",
        minutes: "120m",
        asr: "99%",
        acd: "2m",
        earnings: "5698"
      }
    ]
  }
};

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { classes } = this.props;

    return (
      <Grid container style={{ opacity: ".6" }}>
        <Grid item xs={12}>
          <Typography variant="display1" align="center" gutterBottom>
            Past 24 hours
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper>
            <CardContent>
              <div style={{ position: "relative" }}>
                <Typography>Top Destinations</Typography>

                <RenderChart data={pieConfig} />

                <div
                  style={{
                    position: "absolute",
                    top: "-8px",
                    right: "8px",
                    fontSize: "11px",
                    textAlign: "right"
                  }}
                >
                  <div style={{ color: "#2196F3" }}>
                    Max Spain - 12.845 calls
                  </div>
                  <div style={{ color: "#f44336" }}>
                    Min Czech Republic - 4368 calls
                  </div>
                </div>
              </div>
            </CardContent>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper>
            <CardContent>
              <div style={{ position: "relative" }}>
                <Typography>ASR (Answer Seizure Ratio)</Typography>

                <RenderChart data={otherConfig} />

                <div
                  style={{
                    position: "absolute",
                    bottom: "8px",
                    right: "8px",
                    fontSize: "11px",
                    textAlign: "right"
                  }}
                >
                  <strong>99.8%</strong> <small>Based on 4.583 calls</small>
                </div>

                <div
                  style={{
                    position: "absolute",
                    top: "-8px",
                    right: "8px",
                    fontSize: "11px",
                    textAlign: "right"
                  }}
                >
                  <div style={{ color: "#2196F3" }}>Highest - 99.5%</div>
                  <div style={{ color: "#f44336" }}>Lowest - 12%</div>
                </div>
              </div>
            </CardContent>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper>
            <CardContent>
              <div style={{ position: "relative" }}>
                <Typography>ACD (Avg. Call Duration)</Typography>

                <RenderChart data={alcConfig} />

                <div
                  style={{
                    position: "absolute",
                    bottom: "8px",
                    right: "8px",
                    fontSize: "11px",
                    textAlign: "right"
                  }}
                >
                  <strong>10m:8s</strong> <small>Based on 4.583 calls</small>
                </div>

                <div
                  style={{
                    position: "absolute",
                    top: "-8px",
                    right: "8px",
                    fontSize: "11px",
                    textAlign: "right"
                  }}
                >
                  <div style={{ color: "#2196F3" }}>Max - 121m5s</div>
                  <div style={{ color: "#f44336" }}>Min - 2s</div>
                </div>
              </div>
            </CardContent>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper>
            <CardContent>
              <div style={{ position: "relative" }}>
                <Typography>Customer Smart Contract Activity</Typography>

                <div
                // style={{
                //   width: "100%",
                //   paddingTop: "50%",
                //   position: "relative"
                // }}
                >
                  <div
                  // style={{
                  //   top: 0,
                  //   bottom: 0,
                  //   right: 0,
                  //   left: 0,
                  //   position: "absolute"
                  // }}
                  >
                    <div>
                      <div
                        className={classes.smartContractsContainer}
                        style={{ paddingTop: 16 }}
                      >
                        <RenderTable
                          data={data.result.items}
                          columns={columns}
                          filterable={false}
                        />

                        {/* <div className={`${classes.smartContract} active`}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              46500,&nbsp;
                            </span>
                            <i>Buyer C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $89.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 hours ago</i>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    position: "absolute",
                    top: "-8px",
                    right: "8px",
                    fontSize: "11px",
                    textAlign: "right"
                  }}
                >
                  {/* <div style={{ color: "#2196F3" }}>Max ALC - 121m5s calls</div>
                  <div style={{ color: "#f44336" }}>Min ALC - 2s</div> */}
                </div>
              </div>
            </CardContent>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <CardContent>
              <div style={{ position: "relative" }}>
                <Typography>Supplier Smart Contract Activity</Typography>

                <div
                // style={{
                //   width: "100%",
                //   paddingTop: "50%",
                //   position: "relative"
                // }}
                >
                  <div
                  // style={{
                  //   top: 0,
                  //   bottom: 0,
                  //   right: 0,
                  //   left: 0,
                  //   position: "absolute"
                  // }}
                  >
                    <div>
                      <div
                        className={classes.smartContractsContainer}
                        style={{ paddingTop: 16 }}
                      >
                        <RenderTable
                          data={data2.result.items}
                          columns={columns2}
                          filterable={false}
                        />

                        {/* <div className={`${classes.smartContract} active`}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              46500,&nbsp;
                            </span>
                            <i>Buyer C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $89.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 hours ago</i>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    position: "absolute",
                    top: "-8px",
                    right: "8px",
                    fontSize: "11px",
                    textAlign: "right"
                  }}
                >
                  {/* <div style={{ color: "#2196F3" }}>Max ALC - 121m5s calls</div>
                  <div style={{ color: "#f44336" }}>Min ALC - 2s</div> */}
                </div>
              </div>
            </CardContent>
          </Paper>
        </Grid>

        {/* <Grid item xs={12} md={4}>
          <Paper>
            <CardContent>
              <div style={{ position: "relative" }}>
                <Typography>Top destinations</Typography>

                <ReactHighmaps config={mapConfig} />

                <div
                  style={{
                    position: "absolute",
                    top: "-8px",
                    right: "-8px",
                    fontSize: "11px"
                  }}
                >
                  <div style={{ color: "#2196F3" }}>Max Canada - 229 calls</div>
                  <div style={{ color: "#f44336" }}>
                    Min Greenland - 10 calls
                  </div>
                </div>
              </div>
            </CardContent>
          </Paper>
        </Grid> */}
      </Grid>
    );
  }
}

export default withStyles(welcomeChartsStyles, { withTheme: true })(Dashboard);
