import actions, {
	CREATE_EXTERNAL_INVOICE,
	CREATE_EXTERNAL_INVOICE_ERROR,
	CREATE_EXTERNAL_INVOICE_SUCCESS,
	EXTERNAL_INVOICE,
	EXTERNAL_INVOICE_ERROR,
	EXTERNAL_INVOICE_SUCCESS,
	EXTERNAL_RATES,
	EXTERNAL_RATES_ERROR,
	EXTERNAL_RATES_SUCCESS,
	ROUTES_SMARTCONTRACTS_LIST_FILTERS,
	ROUTE_CODES,
	SMARTCONTRACTS_LIST,
	SMARTCONTRACTS_ROUTE_DATA,
	UPLOAD_EXTERNAL_RATE_PLAN,
	UPLOAD_EXTERNAL_RATE_PLAN_ERROR,
	UPLOAD_EXTERNAL_RATE_PLAN_SUCCESS,
	UPLOAD_VENDOR_RATE_PLAN,
	UPLOAD_VENDOR_RATE_PLAN_ERROR,
	UPLOAD_VENDOR_RATE_PLAN_SUCCESS,
	VENDOR_RATES,
	VENDOR_RATES_ERROR,
	VENDOR_RATES_SUCCESS
} from './actions';
import {
	EXTERNAL_INVOICE_DATA,
	EXTERNAL_RATES_DATA,
	EXTERNAL_RATE_PLAN_UPLOAD_DATA,
	VENDOR_RATES_DATA,
	VENDOR_RATE_PLAN_UPLOAD_DATA,
	getExternalInvoiceDataState,
	getExternalRatePlanUploadState,
	getExternalRatesDataState,
	getVendorRatePlanUploadState,
	getVendorRatesDataState
} from './selectors';

const initialState = {
	loading: false,
	loadingCreate: false,
	loadingFilters: false,
	routeCodeLoading: false,
	loadingRoutesList: false,
	loadingRouteUpdate: false,
	loadingPartitionInfo: false,
	loadingHardwareProfile: false,
	loadingHardwareProfileUpdate: false,
	loadingHardwareProfileRateUpload: false,
	loadingOriginationHardwareProfiles: false,
	loadingTerminationHardwareProfiles: false,

	list: [],
	states: [],
	countries: [],
	routesList: [],
	originationHardwareProfiles: [],
	terminationHardwareProfiles: [],

	filters: {},
	details: {},
	routeDetails: {},
	sellingFilters: {},
	hardwareProfileRateUploadData: {},
	hardwareProfileRateData: [],
	hardwareProfileData: {},
	partitionInfo: {
		ip: ''
	},
	routeCodeMatches: {
		countries: [],
		matchCount: 0,
		matches: [],
		types: []
	},
	routeUpdateCodeMatches: {
		countries: [],
		matchCount: 0,
		matches: [],
		types: []
	},

	hardwareProfileOptions: {
		dtmfOptions: [],
		voipProtocols: [],
		codecs: []
	},
};

export default function(state = initialState, action) {
	switch (action.type) {
		case actions.SET_STATE:
			return { ...state, ...action.payload };
		// case SMARTCONTRACTS_LIST:
		//   return {
		//     ...state,
		//     [SMARTCONTRACTS_LIST]: action.payload
		//   };
		// case SMARTCONTRACTS_ROUTE_DATA:
		//   return {
		//     ...state,
		//     [SMARTCONTRACTS_ROUTE_DATA]: action.payload
		//   };
		// case ROUTE_CODES:
		//   return {
		//     ...state,
		//     [ROUTE_CODES]: action.payload
		//   };
		// case ROUTES_SMARTCONTRACTS_LIST_FILTERS:
		//   return {
		//     ...state,
		//     [ROUTES_SMARTCONTRACTS_LIST_FILTERS]: action.payload
		//   };
		// case UPLOAD_VENDOR_RATE_PLAN:
		//   return {
		//     ...state,
		//     [VENDOR_RATE_PLAN_UPLOAD_DATA]: {
		//       ...getVendorRatePlanUploadState(state),
		//       file: action.payload.ratePlanFile,
		//       loading: true
		//     }
		//   };
		// case UPLOAD_VENDOR_RATE_PLAN_SUCCESS:
		//   return {
		//     ...state,
		//     [VENDOR_RATE_PLAN_UPLOAD_DATA]: {
		//       ...getVendorRatePlanUploadState(state),
		//       file: action.payload.ratePlanFile,
		//       loading: false,
		//       error: undefined,
		//       ...action.payload
		//     }
		//   };
		// case UPLOAD_VENDOR_RATE_PLAN_ERROR:
		//   return {
		//     ...state,
		//     [VENDOR_RATE_PLAN_UPLOAD_DATA]: {
		//       ...getVendorRatePlanUploadState(state),
		//       file: action.payload.ratePlanFile,
		//       loading: false,
		//       info: undefined,
		//       ...action.payload
		//     }
		//   };
		// case VENDOR_RATES:
		//   return {
		//     ...state,
		//     [VENDOR_RATES_DATA]: {
		//       ...getVendorRatesDataState(state),
		//       [action.payload.externalProfileId]: {
		//         data: [],
		//         total: 0,
		//         startOffset: 0,
		//         loading: true,
		//         error: undefined
		//       }
		//     }
		//   };
		// case VENDOR_RATES_SUCCESS:
		//   return {
		//     ...state,
		//     [VENDOR_RATES_DATA]: {
		//       ...getVendorRatesDataState(state),
		//       [action.payload.externalProfileId]: {
		//         data: action.payload.data,
		//         total: action.payload.total,
		//         startOffset: action.payload.startOffset,
		//         loading: false,
		//         error: undefined
		//       }
		//     }
		//   };
		// case VENDOR_RATES_ERROR:
		//   return {
		//     ...state,
		//     [VENDOR_RATES_DATA]: {
		//       ...getVendorRatesDataState(state),
		//       [action.payload.externalProfileId]: {
		//         loading: false,
		//         error: action.payload.data
		//       }
		//     }
		//   };
		case EXTERNAL_RATES:
			return {
				...state,
				[EXTERNAL_RATES_DATA]: {
					...getExternalRatesDataState(state),
					[action.payload.externalProfileId]: {
						data: [],
						total: 0,
						startOffset: 0,
						loading: true,
						error: undefined
					}
				}
			};
		case EXTERNAL_RATES_SUCCESS:
			return {
				...state,
				[EXTERNAL_RATES_DATA]: {
					...getExternalRatesDataState(state),
					[action.payload.externalProfileId]: {
						data: action.payload.data,
						total: action.payload.total,
						startOffset: action.payload.startOffset,
						loading: false,
						error: undefined
					}
				}
			};
		case EXTERNAL_RATES_ERROR:
			return {
				...state,
				[EXTERNAL_RATES_DATA]: {
					...getExternalRatesDataState(state),
					[action.payload.externalProfileId]: {
						loading: false,
						error: action.payload.data
					}
				}
			};
		case EXTERNAL_INVOICE:
			return {
				...state,
				[EXTERNAL_INVOICE_DATA]: {
					...getExternalInvoiceDataState(state),
					[action.payload.externalProfileId]: {
						data: {},
						loading: true,
						error: undefined
					}
				}
			};
		case EXTERNAL_INVOICE_SUCCESS:
			return {
				...state,
				[EXTERNAL_INVOICE_DATA]: {
					...getExternalInvoiceDataState(state),
					[action.payload.externalProfileId]: {
						data: action.payload,
						loading: false,
						error: undefined
					}
				}
			};
		case EXTERNAL_INVOICE_ERROR:
			return {
				...state,
				[EXTERNAL_INVOICE_DATA]: {
					...getExternalInvoiceDataState(state),
					[action.payload.externalProfileId]: {
						loading: false,
						error: action.payload
					}
				}
			};
		case CREATE_EXTERNAL_INVOICE:
			return {
				...state,
				[EXTERNAL_INVOICE_DATA]: {
					...getExternalInvoiceDataState(state),
					[action.payload.tempId]: {
						data: {},
						loading: true,
						error: undefined
					}
				}
			};
		case CREATE_EXTERNAL_INVOICE_SUCCESS:
			return {
				...state,
				[EXTERNAL_INVOICE_DATA]: {
					...getExternalInvoiceDataState(state),
					[action.payload.tempId]: {
						data: {},
						loading: false,
						error: undefined
					}
				}
			};
		case CREATE_EXTERNAL_INVOICE_ERROR:
			return {
				...state,
				[EXTERNAL_INVOICE_DATA]: {
					...getExternalInvoiceDataState(state),
					[action.payload.tempId]: {
						data: {},
						loading: false,
						error: action.payload.error
					}
				}
			};
		case UPLOAD_EXTERNAL_RATE_PLAN:
			return {
				...state,
				[EXTERNAL_RATE_PLAN_UPLOAD_DATA]: {
					...getExternalRatePlanUploadState(state),
					file: action.payload.ratePlanFile,
					loading: true
				}
			};
		case UPLOAD_EXTERNAL_RATE_PLAN_SUCCESS:
			return {
				...state,
				[EXTERNAL_RATE_PLAN_UPLOAD_DATA]: {
					...getExternalRatePlanUploadState(state),
					file: action.payload.ratePlanFile,
					loading: false,
					error: undefined,
					...action.payload
				}
			};
		case UPLOAD_EXTERNAL_RATE_PLAN_ERROR:
			return {
				...state,
				[EXTERNAL_RATE_PLAN_UPLOAD_DATA]: {
					...getExternalRatePlanUploadState(state),
					file: action.payload.ratePlanFile,
					loading: false,
					info: undefined,
					...action.payload
				}
			};
		default:
			return state;
	}
}
