import React from "react";
import PropTypes from "prop-types";

const SmartContractsOverview = props => (
  <div className="row">
    <div className="col-lg-12 col-md-12">
      <div className="card">
        <div className="card-body">
          <div className="d-flex">
            <div>
              <h3 className="card-title m-b-5">
                <span className="lstick" />
                {props.title}
              </h3>
            </div>
          </div>
        </div>
        <div className="bg-theme stats-bar">
          <div className="row">
            <div className="col-lg-3 col-md-3">
              <div className="p-20 active">
                <h3 className="text-white m-b-0">
                  <i className="fa fa-play-circle" aria-hidden="true" /> Active
                  ({props.activeNumber})
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="p-20">
                <h3 className="text-white m-b-0">
                  <i className="fa fa-pause" aria-hidden="true" /> Pending ({
                    props.pendingNumber
                  })
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="p-20">
                <h3 className="text-white m-b-0">
                  <i className="fas fa-stopwatch" /> Awaiting your action ({
                    props.awaitingNumber
                  })
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="p-20">
                <h3 className="text-white m-b-0">
                  <i className="fas fa-times-circle" />
                  Terminated ({props.terminatedNumber})
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

SmartContractsOverview.propTypes = {
  title: PropTypes.string,
  activeNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pendingNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  awaitingNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  terminatedNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

SmartContractsOverview.defaultProps = {
  title: "",
  activeNumber: "",
  pendingNumber: "",
  awaitingNumber: "",
  terminatedNumber: ""
};

export default SmartContractsOverview;
