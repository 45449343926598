import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Grid
} from "@material-ui/core";

import { RenderRadio, RenderNotification } from "components";

const styles = {
  root: {
    "&:before": {
      display: "none"
    }
  }
};

class SmartContractAllocateSell extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      funderId: "",
      dailyAllocatedAmount: undefined,
      paymentTermId: "",
      interestRate: undefined,
      paymentTermNetId: ""
    };
  }

  handleChange(key, value) {
    this.setState({
      [key]: value
    });
  }

  render() {
    const {
      catalogs,
      funderList,
      classSelectBidding,
      classSelectFunder,
      changeAllocateSellSelect,
      onFieldChangeAllocateSell,
      errorsAllocateSell,
      classes
    } = this.props;

    return (
      <div>
        <RenderNotification
          variant="danger"
          floating={false}
          open={errorsAllocateSell && errorsAllocateSell.length > 0}
          message={errorsAllocateSell.map((error, i) => (
            <p key={i}>{error}</p>
          ))}
        />
        {/* PTI-137 temporary hide */}
        {/* <RenderRadio label="Send to Bidding Portal" name="radio" onChange={() => changeAllocateSellSelect("bidding")} checked={classSelectBidding && !classSelectFunder} />
        <RenderRadio label="Select Funder" name="radio" onChange={() => changeAllocateSellSelect("funder")} checked={classSelectFunder && classSelectBidding} /> */}

        {/* <Accordion expanded={classSelectFunder} classes={{root: classes.root}}>
          <AccordionDetails> */}
        <FormControl style={{ minWidth: "200px" }}>
          <InputLabel htmlFor="fundersList">Select a funder</InputLabel>
          <Select
            value={this.state.funderId}
            onChange={event => {
              this.handleChange("funderId", event.target.value);
              onFieldChangeAllocateSell("funderId", event.target.value);
            }}
            inputProps={{
              name: "fundersList",
              id: "fundersList"
            }}
          >
            <MenuItem value="">None</MenuItem>
            {funderList.map(funder => (
              <MenuItem key={funder.id} value={funder.id}>
                {funder.participantName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* </AccordionDetails>
        </Accordion> */}

        {/* <Accordion expanded={classSelectBidding} classes={{root: classes.root}}>
          <AccordionDetails> */}
        <Grid container>
          <Grid item xs={12}>
            <TextField
              label="Daily allocated amount"
              value={this.state.dailyAllocatedAmount}
              onChange={event => {
                this.handleChange("dailyAllocatedAmount", event.target.value);
                onFieldChangeAllocateSell(
                  "dailyAllocatedAmount",
                  event.target.value
                );
              }}
              type="number"
              style={{ minWidth: "100%" }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl style={{ minWidth: "200px" }}>
              <InputLabel htmlFor="fundersList">Repayment term</InputLabel>
              <Select
                value={this.state.paymentTermId}
                onChange={event => {
                  this.handleChange("paymentTermId", event.target.value);
                  onFieldChangeAllocateSell(
                    "paymentTermId",
                    event.target.value
                  );
                }}
                inputProps={{
                  name: "paymentTerms",
                  id: "paymentTerms"
                }}
              >
                <MenuItem value="">None</MenuItem>
                {catalogs.paymentTerms.map(item => (
                  <MenuItem
                    key={item.invoicePeriodId}
                    value={item.invoicePeriodType}
                  >
                    {item.invoicePeriodType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {/* <FormControl style={{minWidth: "200px"}}> */}
            {/* <InputLabel htmlFor="fundersList">Net</InputLabel> */}
            {/* <Select */}
            {/* value={this.state.paymentTermNetId} */}
            {/* onChange={event => { */}
            {/* this.handleChange("paymentTermNetId", event.target.value); */}
            {/* onFieldChangeAllocateSell("paymentTermNetId", event.target.value); */}
            {/* } */}
            {/* } */}
            {/* inputProps={{ */}
            {/* name: "payTermNet", */}
            {/* id: "payTermNet" */}
            {/* }} */}
            {/* > */}
            {/* <MenuItem value=""> */}
            {/* None */}
            {/* </MenuItem> */}
            {/* {catalogs.paymentTermsNet.map(payTermNet => ( */}
            {/* <MenuItem key={payTermNet} value={payTermNet}> */}
            {/* {payTermNet} */}
            {/* </MenuItem> */}
            {/* ))} */}
            {/* </Select> */}
            {/* </FormControl> */}
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Interest rate"
              value={this.state.interestRate}
              onChange={event => {
                this.handleChange("interestRate", event.target.value);
                onFieldChangeAllocateSell("interestRate", event.target.value);
              }}
              type="number"
              style={{ minWidth: "100%" }}
            />
          </Grid>
        </Grid>
        {/* </AccordionDetails>
        </Accordion> */}
      </div>
    );
  }
}

SmartContractAllocateSell.propTypes = {
  catalogs: PropTypes.shape({
    paymentTerms: PropTypes.array.isRequired
    // paymentTermsNet: PropTypes.array.isRequired
  }).isRequired,
  // funderList: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     id: PropTypes.string.isRequired,
  //     participantName: PropTypes.string.isRequired
  //   })
  // ),
  // allocateSellInfo: PropTypes.shape({
  //   dailyAllocatedAmount: PropTypes.number,
  //   interestRate: PropTypes.number
  // }),
  classSelectBidding: PropTypes.bool,
  classSelectFunder: PropTypes.bool,
  changeAllocateSellSelect: PropTypes.func.isRequired,
  onFieldChangeAllocateSell: PropTypes.func.isRequired,
  errorsAllocateSell: PropTypes.arrayOf(PropTypes.string)
};
SmartContractAllocateSell.defaultProps = {
  classSelectBidding: false,
  classSelectFunder: false,
  funderList: [],
  errorsAllocateSell: [],
  allocateSellInfo: {
    dailyAllocatedAmount: 0,
    interestRate: 0
  }
};
export default withStyles(styles)(SmartContractAllocateSell);
