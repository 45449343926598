import React from 'react';
import moment from 'moment';
import { ActiveFormatter, RenderButton, RenderTable } from 'components';
import { Grid, Paper, CardContent, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const TableInterconnections = props => {
	const { acceptInterconnections, agreementSign, agreementDownload, agreementCounterSign, user } = props;
	const { t } = useTranslation('tradingpartners/interconnections');

	const onClickStatus = field => {
		acceptInterconnections(field.guid, field.participantName);
	};

	const onClickInitiateSign = field => {
		agreementSign(field);
	};

	const onClickInitiateDownload = field => {
		agreementDownload(field);
	};

	const onClickAcceptSign = field => {
		agreementCounterSign(field);
	};

	const { data } = props;
	const tableColumns1 = [
		{
			Header: t('name'),
			accessor: 'participantName',
			Cell: cell => <span style={{ whiteSpace: 'nowrap' }}>{cell.value}</span>
		},
		// {
		// 	Header: () => <div style={{ textAlign: 'center' }}>{t('customer')}</div>,
		// 	accessor: 'originator',
		// 	Cell: cell => <ActiveFormatter active={cell.value} />
		// },
		// {
		// 	Header: () => <div style={{ textAlign: 'center' }}>{t('supplier')}</div>,
		// 	accessor: 'terminator',
		// 	Cell: cell => <ActiveFormatter active={cell.value} />
		// },
		{
			Header: () => <div style={{ textAlign: 'right' }}>{t('invite_date')}</div>,
			accessor: 'terminator',
			Cell: cell => {
				if (cell.original.status === 'Pending') {
					return (
						<div style={{ textAlign: 'right' }}>
							<RenderButton onClick={onClickStatus.bind(this, cell.original)} color="danger" small>
								{t('pending_confirmation')}
							</RenderButton>
						</div>
					);
				}
				return <div style={{ textAlign: 'right' }}>{moment(cell.original.createdAt).format('MMM DD YYYY')}</div>;
			}
		}
	];

	const tableColumns2 = [
		{
			Header: t('name'),
			accessor: 'participantName',
			Cell: cell => <span style={{ whiteSpace: 'nowrap' }}>{cell.value}</span>
		},
		// {
		// 	Header: () => <div style={{ textAlign: 'center' }}>{t('customer')}</div>,
		// 	accessor: 'originator',
		// 	Cell: cell => <ActiveFormatter active={cell.value} />
		// },
		// {
		// 	Header: () => <div style={{ textAlign: 'center' }}>{t('supplier')}</div>,
		// 	accessor: 'terminator',
		// 	Cell: cell => <ActiveFormatter active={cell.value} />
		// },
		{
			Header: () => <div style={{ textAlign: 'center' }}>{t('active_contracts')}</div>,
			accessor: 'activeContracts',
			Cell: cell => <div style={{ textAlign: 'center' }}>{cell.value}</div>
		},
		{
			Header: () => <div style={{ textAlign: 'center' }}>{t('total_contracts')}</div>,
			accessor: 'totalContracts',
			Cell: cell => <div style={{ textAlign: 'center' }}>{cell.value}</div>
		},
		{
			Header: () => <div style={{ textAlign: 'right' }}>{t('active_date')}</div>,
			accessor: 'terminator',
			Cell: cell => <div style={{ textAlign: 'right' }}>{moment(cell.original.updatedAt).format('MMM DD YYYY')}</div>
		},
		{
			Header: () => <div style={{ textAlign: 'right' }}>Agreement Status</div>,
			accessor: 'terminator',
			Cell: cell => {
				const { original } = cell;
				let buttonText = t('pending');
				let disabled = true;

				if (original.currentUserStatus === 'signed' && original.otherUserStatus === 'signed') {
					buttonText = t('view_agreement');
					disabled = false;
				}

				if (original.currentUserStatus !== 'signed' && original.otherUserStatus === 'signed') {
					buttonText = t('review_sign');
					disabled = false;
				}

				if (original.currentUserStatus === 'initiate_document') {
					buttonText = t('sign');
					disabled = false;
				}

				return (
					<RenderButton onClick={() => agreementSign(original)} color="warning" disabled={disabled} small>
						{buttonText}
					</RenderButton>
				);
			}
		}
	];

	const tableData = {
		pending: data.pending || [],
		active: data.active || []
	};

	const tableOptions = {
		selectableRows: false,
		filter: false,
		print: false,
		download: false,
		viewColumns: false,
		responsive: 'scroll'
	};

	return (
		<Grid container>
			<Grid item xs={12}>
				<Paper>
					<CardContent>
						<Typography variant="h1" gutterBottom>
							{t('pending_invitations')}
						</Typography>
						<RenderTable data={tableData.pending} columns={tableColumns1} />
					</CardContent>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				<Paper>
					<CardContent>
						<Typography variant="h1" gutterBottom>
							{t('active_partners')}
						</Typography>
						<RenderTable data={tableData.active} columns={tableColumns2} options={tableOptions} />
					</CardContent>
				</Paper>
			</Grid>
		</Grid>
	);
};

TableInterconnections.defaultProps = {
	data: []
};

TableInterconnections.propTypes = {
	acceptInterconnections: PropTypes.func.isRequired,
	data: PropTypes.object
};
export default TableInterconnections;
