import { PLATFORM_SETTINGS } from './actions';

const initialState = {
	notifications: []
};

export default function(state = initialState, action) {
	switch (action.type) {
		case PLATFORM_SETTINGS:
			return {
				...state,
				[PLATFORM_SETTINGS]: action.payload
			};
		default:
			return state;
	}
}
