import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { RenderTable } from 'components';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';
import TableExternalInvoicesStyle from './style';
import MainTableHeader from './main.table.header';

// eslint-disable-next-line react/prefer-stateless-function
class TableExternalInvoices extends Component {
	render() {
		const { data, toggleInvoiceModal, t } = this.props;
		const { columns } = MainTableHeader({ toggleInvoiceModal }, t);
		return (
			<React.Fragment>
				<RenderTable
					data={data}
					columns={columns}
					defaultSorted={[
						{
							id: 'period',
							desc: true
						}
					]}
				/>
			</React.Fragment>
		);
	}
}

TableExternalInvoices.defaultProps = {};

TableExternalInvoices.propTypes = {
	data: PropTypes.array.isRequired,
	toggleInvoiceModal: PropTypes.func.isRequired
};

const TableExternalInvoicesWithStyles = withStyles(TableExternalInvoicesStyle)(TableExternalInvoices);
const TableExternalInvoicesWithTranslations = withTranslation(['financials/general'])(TableExternalInvoicesWithStyles);

export default TableExternalInvoicesWithTranslations;
