import React, { Component } from "react";
import { Field } from "redux-form";
import { RenderInput, RenderButton } from "components";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { SendInterconnectionsContainer } from "containers";

class InterconnectionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <SendInterconnectionsContainer
        emailInterconnections={
          this.props.dataModalInterconnections.emailInterconnections
        }
      />
    );
  }
}
InterconnectionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};
export default InterconnectionForm;
