import { call, put } from "redux-saga/effects";
import InterconnectionsApi from "services/api/InterconnectionsApi";
import { push } from "connected-react-router";
import { Util } from "components";
import {
  setInterconnections,
  setResultInterconnection,
  setModalAcceptInterconnections,
  setRequestResponse,
} from "./actions";
import {
  actionCreatorPreloaderOn,
  actionCreatorPreloaderOff
} from "../preloader/actions";
import { actionCreatorSelectItem } from "../privatemenu/actions";

const responseError = {
  status: "error",
  message: "There was an error!"
};

export function* initializeSmartContractCounterSign(action) {
  try {
    yield put(actionCreatorPreloaderOn());
    const dataResult = yield call(
      InterconnectionsApi.agreementAccept,
      action.payload
    );

    if (dataResult !== null && dataResult.signingUrl !== undefined) {
      window.location.href = dataResult.signingUrl;
    }

    yield put(actionCreatorPreloaderOff());
  } catch (err) {
    yield put(actionCreatorPreloaderOff());
    const error = err.message || err;
    console.error(error);
  }
}

export function* initializeSmartContractDownload(action) {
  try {
    yield put(actionCreatorPreloaderOn());
    const dataResult = yield call(
      InterconnectionsApi.agreementDownload,
      action.payload
    );

    if (dataResult !== null && dataResult.url !== undefined) {
      window.location.href = dataResult.url;
    } else {
      // yield put(setPostResponse({
      //     status: "ERROR",
      //     message: "Unable to locate Agreement, issue has been reported."
      //   })
      //   );
    }

    yield put(actionCreatorPreloaderOff());
  } catch (err) {
    yield put(actionCreatorPreloaderOff());
    const error = err.message || err;
    console.error(error);
  }
}

export function* initializeSmartContractSign(action) {
  try {
    yield put(actionCreatorPreloaderOn());
    yield put(setRequestResponse(false));
    const dataResult = yield call(
      InterconnectionsApi.agreementSign,
      action.payload
    );

    if (dataResult !== null && dataResult.signingUrl !== undefined) {
      window.location.href = dataResult.signingUrl;
    } else {
      yield put(setRequestResponse(dataResult));
    }

    yield put(actionCreatorPreloaderOff());
  } catch (err) {
    yield put(actionCreatorPreloaderOff());
    const error = err.message || err;
    console.error(error);
  }
}

export function* initializeInterconnections(action) {
  try {
    yield put(actionCreatorPreloaderOn());
    const dataResult = yield call(
      InterconnectionsApi.initializeInterconnections,
      action.payload
    );
    if (!Util.isErrorResponseInterconnection(dataResult)) {
      yield put(setInterconnections(dataResult));
    }

    yield put(actionCreatorPreloaderOff());
  } catch (err) {
    yield put(actionCreatorPreloaderOff());
    const error = err.message || err;
    console.error(error);
  }
}

export function* sendInterconnection(action) {
  try {
    yield put(actionCreatorPreloaderOn());
    const dataResult = yield call(
      InterconnectionsApi.sendInterconnection,
      action.payload
    );
    let responseObject;
    if (Util.isErrorResponseInterconnection(dataResult)) {
      responseObject = {
        status: "error",
        message: dataResult.details
      };
    } else {
      const dataResult = yield call(
        InterconnectionsApi.initializeInterconnections,
        action.payload
      );
      if (!Util.isErrorResponseInterconnection(dataResult)) {
        yield put(setInterconnections(dataResult));
      }
      responseObject = {
        status: "success",
        message: "Interconnection invite sent!"
      };
    }
    yield put(setResultInterconnection(responseObject));
    yield put(actionCreatorPreloaderOff());
  } catch (err) {
    yield put(setResultInterconnection(responseError));
    yield put(actionCreatorPreloaderOff());
    const error = err.message || err;
    console.error(error);
  }
}

export function* acceptInterconnection(action) {
  try {
    yield put(actionCreatorPreloaderOn());
    const dataResult = yield call(
      InterconnectionsApi.acceptInterconnection,
      action.payload
    );
    let responseObject;
    if (Util.isErrorResponseInterconnection(dataResult)) {
      responseObject = {
        status: "error",
        message: dataResult.details
      };
    } else {
      const dataResult = yield call(
        InterconnectionsApi.initializeInterconnections,
        action.payload
      );
      if (!Util.isErrorResponseInterconnection(dataResult)) {
        yield put(setInterconnections(dataResult));
      }
      responseObject = {
        status: "success",
        message: "You successfully accepted!"
      };
    }
    yield put(setResultInterconnection(responseObject));
    yield put(actionCreatorPreloaderOff());
  } catch (err) {
    yield put(setResultInterconnection(responseError));
    yield put(actionCreatorPreloaderOff());
    const error = err.message || err;
    console.error(error);
  }
}

export function* processAcceptInterconnection(action) {
  try {
    yield put(actionCreatorPreloaderOn());
    const dataResult = yield call(
      InterconnectionsApi.validateInterconnection,
      action.payload
    );
    if (!Util.isErrorResponseInterconnection(dataResult)) {
      yield put(
        setModalAcceptInterconnections(
          true,
          action.payload.connectGuid,
          dataResult.name
        )
      );
    }
    yield put(actionCreatorSelectItem("interconnections"));
    yield put(push("/interconnections/index"));
  } catch (err) {
    yield put(actionCreatorPreloaderOff());
    const error = err.message || err;
    console.error(error);
  }
}
