import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { DummyComponent } from "components";
import {
  actionCreatorPreloaderOn,
  actionCreatorPreloaderOff
} from "store/actions";

class DummyContainer extends Component {
  constructor(props) {
    super(props);
    // this.props.actionCreatorPreloaderOn();
  }

  componentDidMount() {
    // this.props.actionCreatorPreloaderOn();
    this.props.actionCreatorPreloaderOff();
  }
  render() {
    return <DummyComponent />;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionCreatorPreloaderOn,
      actionCreatorPreloaderOff
    },
    dispatch
  );
}

export default connect(
  null,
  mapDispatchToProps
)(DummyContainer);
