import React from 'react';
import { PrivateTemplate, BreadCrumb } from 'components';
import SmartContractsScreen from 'screens/SmartContracts/list'
import { useTranslation } from 'react-i18next';

const SmartContractsPage = props => {
	const { t } = useTranslation();
	return (
		<PrivateTemplate>
			<BreadCrumb name="Smart contracts" firstLevel="Home" secondLevel="Smart contracts" />
			<SmartContractsScreen {...props} />
		</PrivateTemplate>
	);
};

export default SmartContractsPage;
