import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { PreLoader } from "components";
import { fromPreloader } from "store/selectors";
import { actionCreatorPreloaderOn } from "store/actions";
import PropTypes from "prop-types";

const PreLoaderContainer = props => (
  <PreLoader showLoader={props.preloaderState} />
);

function mapStateToProps(state) {
  return {
    preloaderState: fromPreloader.getPreloaderState(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionCreatorPreloaderOn
    },
    dispatch
  );
}

PreLoaderContainer.propTypes = {
  preloaderState: PropTypes.string.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreLoaderContainer);
