import React from 'react';
import { PrivateTemplate, BreadCrumb } from 'components';
import { RoutesEditScreen } from 'screens';

const RoutesEditPage = (props) => (
	<PrivateTemplate>
		<BreadCrumb name="Routes" firstLevel="Home" secondLevel="Routes" />
		<RoutesEditScreen {...props} />
	</PrivateTemplate>
);
export default RoutesEditPage;
