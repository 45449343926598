export const initialState = {};

export const initialPasswordChallengeData = {
	challengeName: null,
	successMessage: null,
	email: null,
	errorMessage: null,
	password: null,
	session: null,
	userId: null,
	userName: null,
	loading: false
};
export const PASSWORD_CHALLENGE_DATA = 'PASSWORD_CHALLENGE_DATA';

export const getPasswordChallengeDataState = (state = initialState) => state[PASSWORD_CHALLENGE_DATA] || initialPasswordChallengeData;
