import React, { Component } from "react";
import { connect } from "react-redux";
import { processInterconnection } from "store/actions";
import { fromUseraccount } from "store/selectors";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

class InterconnectAcceptContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    //console.log(user.userInfo.userId);
    this.props.processInterconnection("userId", this.props.match.params.guid);
  }

  render() {
    return <div />;
  }
}

const mapStateToProps = state => ({
  user: fromUseraccount.getUserInfoDataState(state),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
        processInterconnection
    },
    dispatch
  );
}

InterconnectAcceptContainer.propTypes = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InterconnectAcceptContainer);
