export const initialState = {};

export const getSelectedMenuItem = (state = initialState) =>
  state.MENU !== undefined && state.MENU.selected !== undefined
    ? state.MENU.selected
    : "";

export const getMenuState = (state = initialState) =>
  state.MENU_OPEN !== undefined && state.MENU_OPEN.open !== undefined
    ? state.MENU_OPEN.open
    : true;

export const getMenuSectionsState = (state = initialState) =>
  state.MENU_SECTIONS !== undefined
    ? state.MENU_SECTIONS
    : {};