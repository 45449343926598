export const initialState = {};
export const initialDataModalInterconnections = {
  dataModalInterconnections: {
    modalInterconnections: false,
    emailInterconnections: null
  }
};
export const initialDataModalAcceptInterconnections = {
  dataModalAcceptInterconnections: {
    modalAcceptInterconnections: false,
    connectGuid: null,
    participantName: null
  }
};

export const MODAL_INTERCONNECTIONS = "MODAL_INTERCONNECTIONS";
export const RESULT_INTERCONNECTION = "RESULT_INTERCONNECTION";
export const INTERCONNECTIONS = "INTERCONNECTIONS";
export const MODAL_ACCEPT_INTERCONNECTIONS = "MODAL_ACCEPT_INTERCONNECTIONS";
export const INTERCONNECTION = "INTERCONNECTION";
export const INTERCONNECT_REQUEST_RESPONSE = "INTERCONNECT_REQUEST_RESPONSE";

export const dataModalAcceptInterconnections = (state = initialState) =>
  state[MODAL_ACCEPT_INTERCONNECTIONS] ||
  initialDataModalAcceptInterconnections;

export const getDataModalAcceptInterconnections = (state = initialState) =>
  dataModalAcceptInterconnections(state).dataModalAcceptInterconnections;

export const dataModalInterconnections = (state = initialState) =>
  state[MODAL_INTERCONNECTIONS] || initialDataModalInterconnections;

export const getDataModalInterconnections = (state = initialState) =>
  dataModalInterconnections(state).dataModalInterconnections;

export const resultInterconnection = (state = initialState) =>
  state[RESULT_INTERCONNECTION] || { resultInterconnection: undefined };

export const getResultInterconnection = (state = initialState) =>
  resultInterconnection(state).resultInterconnection;

export const interconnections = (state = initialState) =>
  state[INTERCONNECTIONS] || { interconnections: undefined };

export const getInterconnections = (state = initialState) =>
  interconnections(state).interconnections;

export const getRequestResponse = (state = {}) =>
  state[INTERCONNECT_REQUEST_RESPONSE] || false;
