import React from "react";
import { Check, Block } from "@material-ui/icons";

const ActiveFormatter = props => (
  <div style={{ textAlign: "center", fontSize: "18px" }}>
    {props && props.active ? (
      <Check color="primary" fontSize="inherit" />
    ) : (
      <Block color="error" fontSize="inherit" />
    )}
  </div>
);

ActiveFormatter.defaultProps = {
  active: false
};
export default ActiveFormatter;
