import { API } from "aws-amplify";

const InfoTextApi = {
  getInfoText(data) {
    return API.post("NODE_SERVICE_API", "/content/get", { body: data })
      .then(response => response)
      .catch(err => err);
  }
};

export default InfoTextApi;
