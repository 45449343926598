
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { InvoiceFooter, InvoiceHeader, InvoiceSummary, UsageSummary } from 'components';
import invoiceStyle from './style';

class ExternalInvoice extends React.Component {
	render() {
		const { classes, data } = this.props;
		return (
			<div>
				{data && data.original ? (
					<Paper className={classes.root} elevation={1}>
						<InvoiceHeader data={data.original} />
						<InvoiceSummary data={data.original} isMaster={data.isMaster} />
						<Divider className={classes.divider} />
						<InvoiceFooter isStandard={data.isStandard} />
					</Paper>
				) : (
					'Invoice not found'
				)}
				{data && data.original && data.usageSummary ? (
					<Paper className={classes.root} elevation={1}>
						<InvoiceHeader data={data.original} page="2" />
						<UsageSummary data={data.usageSummary} />
					</Paper>
				) : (
					''
				)}
			</div>
		);
	}
}

ExternalInvoice.propTypes = {
	classes: PropTypes.shape().isRequired,
	data: PropTypes.shape().isRequired
};

export default withStyles(invoiceStyle)(ExternalInvoice);
