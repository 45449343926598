import React from 'react';
import { PrivateTemplate, BreadCrumb } from 'components';
import { SupportTessPayContainer } from 'containers';
import { Paper, CardContent } from '@material-ui/core';
import { Support } from 'screens';

const SupportTessPayPage = () => (
	<PrivateTemplate>
		<BreadCrumb name={'Support'} firstLevel={'Home'} secondLevel={'Support'} />
		<CardContent>
			<Support />
		</CardContent>
	</PrivateTemplate>
);
export default SupportTessPayPage;
