import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PrivateHeader, PrivateFooter } from 'components';
import { PrivateMenuContainer } from 'containers';
import { CardContent, Zoom, Button, IconButton, Card } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { getPlatformSettings } from 'store/actions';
import { useSnackbar } from 'notistack';
import templateStyle from './style';
import CloseIcon from "@material-ui/icons/Close";
import RenderConfirmDialog from "../../molecules/RenderConfirmDialog";
import {useTranslation} from "react-i18next";
import WarningIcon from '@material-ui/icons/Warning';

// eslint-disable-next-line react/prop-types
const PrivateTemplate = memo(({ classes, children }) => {
	const dispatch = useDispatch();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { t } = useTranslation('myaccount/general_information')

	const [userIsIdle, setUserIsIdle] = React.useState(false);

	const menuState = useSelector(state => (state.privatemenu && state.privatemenu.MENU_OPEN) || false);
	const notifications = useSelector(state => state.notifications && state.notifications.notifications);
	const idle = useSelector(state => state.idle && state.idle.idleStatus);
	const kycApproved = useSelector(state => (state?.myaccount?.accountData?.kycApproved) || false);

	const timeConvert = millis => Math.floor(millis / 60000);


	useEffect(() => {
		switch (idle) {
			case 'INACTIVE':
				setUserIsIdle(true);
				break
			case 'ACTIVE':
				setUserIsIdle(false);
				break
			default:
				setUserIsIdle(false);
				break
		}
	}, [idle]);


	useEffect(() => {
		dispatch({
			type: 'notifications/SET_STATE',
			payload: {
				notifications: []
			}
		});
		dispatch(getPlatformSettings());
	}, []);

	useEffect(() => {
		if (Array.isArray(notifications)) {
			const action = key => (
				<IconButton
					size="small"
					onClick={() => {
						closeSnackbar(key);
					}}
				>
					<CloseIcon />
				</IconButton>
			);

			notifications.map((notification, key) => {
				enqueueSnackbar(notification.message, {
					key: `notification-${key}`,
					action: action(`notification-${key}`),
					variant: notification.type || 'success',
					onExited: () => {
						dispatch({
							type: 'notifications/SET_STATE',
							payload: {
								notifications: notifications.filter((el, i) => i !== key)
							}
						});
					}
				});
			});
		}
	}, [notifications]);

	return (
		<div>
			<PrivateHeader />
			<PrivateMenuContainer />
			{/* eslint-disable-next-line react/prop-types */}
			<div className={`${classes.content} ${menuState.open === true || menuState === true ? classes.menuOpen : ''}`}>
				<Zoom in>
					<div className={classes.pageContent}>
						{!kycApproved && <div className={classes.kycBanner} >
							<WarningIcon color='success.main'/>
							<div>
								<span className={classes.highlightedText}>Your KYC is under review. </span> Once approved, you'll have access to all your account features.
							</div>
						</div>
						}
						<div className={!kycApproved ? classes.children : ''}>
						{children}
						</div>
					</div>
				</Zoom>
				<PrivateFooter />

				{userIsIdle && (
					<RenderConfirmDialog
						openCloseHandler={() => {}}
						title={t('timeout_title')}
						contentText={t('timeout_content', { time: timeConvert(AUTH_TIMEOUT - 60 * 10 * 1000)})}
						open={userIsIdle}
						removeButtons
					/>
				) || null}
			</div>
		</div>
	);
});

export default withStyles(templateStyle)(PrivateTemplate);
