// New Way
const actions = {
	SET_STATE: 'notifications/SET_STATE',
	SET_NOTIFICATION: 'notifications/SET_NOTIFICATION'
};

export const triggerNotification = (message, type) => ({
	type: actions.SET_NOTIFICATION,
	payload: {
		message,
		type
	}
});

export default actions;

// old way

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const NOTIFICATIONS = 'NOTIFICATIONS';

export const getNotifications = userId => ({
	type: GET_NOTIFICATIONS,
	payload: userId
});

export const setNotifications = result => ({
	type: NOTIFICATIONS,
	payload: {
		notifications: result
	}
});
