import React, { memo } from 'react';
import { Grid } from '@material-ui/core';
import { InfoTextContainer } from 'containers';
import { TwoFactorAuthentication, ChangePassword } from './components';
import styles from './styles.module.scss';

export default memo(() => {
	return (
		<div className={styles.container}>
			<Grid container spacing={1}>
				<Grid item xs={12} md={8}>
					<TwoFactorAuthentication />
					<ChangePassword />
				</Grid>
				<Grid item xs={12} md={4}>
					<InfoTextContainer page="My Account" section="Security" />
				</Grid>
			</Grid>
		</div>
	);
});
