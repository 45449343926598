import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, reset } from "redux-form";

import { VerificationCodeForm } from "components";
import { verificationCodeRequest, cleanDataVerificationCode } from "store/actions";
import {
  createValidator,
  required,
  email,
  minLength,
  maxLength,
  match,
  validatePassword
} from "services/validation";
import { fromVerificationcode } from "store/selectors";
import PropTypes from "prop-types";

class VerificationCodeContainer extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(reset("verificationCodeForm"));
    dispatch(cleanDataVerificationCode());
  }

  render() {
    return (
      <VerificationCodeForm
        {...this.props}
        handleSubmit={this.props.handleSubmit}
      />
    );
  }
}

VerificationCodeContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  form: state.form,
  info: fromVerificationcode.getVerificationCodeDataState(state)
});

const onSubmit = (data, dispatch) => {
  dispatch(verificationCodeRequest(data.code, data.email, data.password));
};

const validate = createValidator({
  code: [required, minLength(6), maxLength(6)],
  email: [required, email, maxLength(50)],
  password: [
    required,
    validatePassword
    ],
    passwordConfirmation: [required, match('password', 'Password')],
});

const VerificationCodeContainerConnect = connect(
  mapStateToProps,
  null
)(VerificationCodeContainer);

export default reduxForm({
  form: "verificationCodeForm",
  destroyOnUnmount: false,
  onSubmit,
  validate
})(VerificationCodeContainerConnect);
