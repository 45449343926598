import { call, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import awsAmplifyService from "services/api/awsamplify";
import {
  verificationCodeFailure,
  verificationCodeSuccess,
  loginSuccess
} from "../actions";

export function* verificationCode(action) {
  try {
    const result = yield call(
      awsAmplifyService.recoveryPasswordSubmit,
      action.payload.email,
      action.payload.code,
      action.payload.password
    );
    if (result.status === 'ok') {
      yield put(verificationCodeSuccess());
      yield put(loginSuccess("Password successfully recovered!"));
      yield put(push("/login"));
    } else {
      yield put(verificationCodeFailure(result.message || 'Unable to change your password at this time'));
    }
  } catch (err) {
    const error = err.message || err;
    yield put(verificationCodeFailure(error));
  }
}
