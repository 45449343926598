import React, { memo, useCallback } from 'react';
import moment from 'moment';
import { RenderTable, RenderButton } from 'components';
import Countdown from 'react-countdown-now';
import { contractIdFormatter } from 'utils';
import { useTranslation } from 'react-i18next';

const ReportCDRDownload = memo(props => {
	const { tableData, isLoading } = props;
	const { t } = useTranslation('reports/general');

	const statusFormatter = useCallback(
		data => {
			if (data.processing) {
				return t('report.status-processing');
			}
			if (data.isReady) {
				return t('report.status-ready');
			}
			return (
				<Countdown
					date={moment(data.updatedAt)
						.add('5', 'minutes')
						.toDate()}
					renderer={({ minutes, completed }) => {
						if (completed) {
							return t('report.status-no_data');
						}
						return <span>{t('report.status-remaining', { minutes })}</span>;
					}}
				/>
			);
		},
		[t]
	);

	const columns = [
		{
			Header: t('report.smartcontract'),
			accessor: 'smartContract',
			Cell: data => (data.value ? contractIdFormatter(data.value) : '')
		},
		{
			Header: t('report.partner'),
			accessor: 'partner'
		},
		{
			Header: t('report.contract_type'),
			accessor: 'type'
		},
		{
			Header: t('report.start_date'),
			accessor: 'startDate',
			Cell: data => `${moment(data.value).format('DD MMM YYYY')} - ${data.original.startHour ? data.original.startHour : ''}H`
		},
		{
			Header: t('report.end_date'),
			accessor: 'endDate',
			Cell: data => `${moment(data.value).format('DD MMM YYYY')} - ${data.original.endHour ? data.original.endHour : ''}H`
		},
		{
			Header: t('report.status'),
			accessor: 'isReady',
			Cell: data => statusFormatter(data.original)
		},
		{
			Header: '',
			headerClassName: 'rt-text-right',
			className: 'rt-text-right',
			accessor: 'downloadUrl',
			Cell: data =>
				data.original.isReady ? (
					<RenderButton color="primary" href={data.value}>
						{t('report.download')}
					</RenderButton>
				) : (
					''
				)
		}
	];

	return (
		<RenderTable
			minRows={2}
			loading={isLoading}
			data={tableData}
			columns={columns}
			filterable={false}
			style={{ maxHeight: 500 }}
			defaultSortDesc
			defaultSorted={['totalSale']}
		/>
	);
});

export default ReportCDRDownload;
