import { Grid, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import { RenderInput, RenderSelect } from 'components';

import { Field } from 'redux-form';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prefer-stateless-function
class ExternalClientFields extends Component {
	constructor(props) {
		super(props);
		this.state = {
			countryId: ''
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.initialValues && nextProps.initialValues.externalClient && nextProps.initialValues.externalClient.countryId !== prevState.countryId) {
			const country = {
				countryId: nextProps.initialValues.externalClient.countryId
			};

			nextProps.getStatesData(country);

			return {
				countryId: nextProps.initialValues.externalClient.countryId
			};
		}

		return null;
	}

	changeCountry = e => {
		const { getStatesData } = this.props;
		getStatesData({ countryId: e.value });
	};

	render() {
		const { statesDataArray, countries } = this.props;
		const sc = countries.sort((a, b) => a.countryName.toLowerCase().localeCompare(b.countryName.toLowerCase()));

		const sortedCountries = sc.map(option => ({
			value: parseInt(option.countryId, 10),
			label: option.countryName
		}));
		return (
			<Grid container>
				<Grid item xs={12} />
				<Grid item xs={12}>
					<Typography variant="h1" gutterBottom>
						External client
					</Typography>
					<Grid container alignItems="flex-end">
						<Grid item xs={4}>
							<Field id="companyName" name="externalClient.companyName" component={RenderInput} type="text" label="Company Name" margin="none" />
						</Grid>
						<Grid item xs={4}>
							<Field
								id="countryId"
								name="externalClient.countryId"
								label="Country"
								component={RenderSelect}
								options={sortedCountries}
								callback={this.changeCountry}
							/>
						</Grid>
						<Grid item xs={4}>
							<Field id="taxId" name="externalClient.taxId" component={RenderInput} type="text" label="Tax ID" margin="none" />
						</Grid>
						<Grid item xs={6}>
							<Field id="addressLine1" name="externalClient.addressLine1" component={RenderInput} type="text" label="Registered Address" margin="none" />
						</Grid>
						<Grid item xs={4}>
							<Field id="city" name="externalClient.city" component={RenderInput} type="text" label="City" margin="none" />
						</Grid>
						<Grid item xs={4}>
							<Field
								id="stateId"
								name="externalClient.stateId"
								label="State/Province/Region"
								component={RenderSelect}
								options={statesDataArray.map(option => ({
									value: parseInt(option.stateId, 10),
									label: option.stateName
								}))}
							/>
						</Grid>

						<Grid item xs={2}>
							<Field id="zipPostalCode" name="externalClient.zipPostalCode" component={RenderInput} type="text" label="Zip/Postal Code" margin="none" />
						</Grid>
						<Grid item xs={6} />
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

ExternalClientFields.propTypes = {
	countries: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	statesDataArray: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	getStatesData: PropTypes.func.isRequired
};

export default ExternalClientFields;
