import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardContent, Grid, Paper, Typography, Chip, TextField } from '@material-ui/core';
import _, { isEmpty } from 'lodash';
import moment from 'moment-timezone';

import { RenderTable } from 'components';

import Loader from 'components/atoms/Loader';
import CurrencyNumber from 'components/atoms/CurrencyNumber';

import actions from 'store/acp/actions';
import styles from './styles.module.scss';

export default memo(() => {
	const dispatch = useDispatch();

	const isLoading = useSelector(state => (state.acp && state.acp.loading) || false);
	const payments = useSelector(state => (state.acp && state.acp.payments) || []);

	const DateFilter = ({ filter, onChange, type = 'date' }) => (
		<TextField
			type={type}
			value={filter ? filter.value : ''}
			onChange={e => onChange(e.target.value)}
			style={{ width: '80%' }}
			variant="standard"
			InputLabelProps={{
				shrink: true
			}}
		/>
	);

	const columns = [
		{
			Header: <div className="number-column">DDA File Amount</div>,
			accessor: 'ddaFileAmount',
			Cell: cell => {
				return (
					<div className="number-column">
						<CurrencyNumber amount={cell.original?.govSumAmount} precision={0} />
					</div>
				);
			},
			width: 200,
			Filter: ({ filter, onChange }) => <TextField value={filter ? filter.value : ''} onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard" />
		},
		{
			Header: <div className="number-column">Holdback Release</div>,
			accessor: 'holdbackRelease',
			Cell: cell => {
				return (
					<div className="number-column">
						<CurrencyNumber amount={cell.original?.holdbackRelease} precision={0} />
					</div>
				);
			},
			width: 200,
			Filter: ({ filter, onChange }) => <TextField value={filter ? filter.value : ''} onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard" />
		},
		{
			Header: <div className="number-column">Lender Financed</div>,
			accessor: 'lenderFinanced',
			Cell: cell => {
				return (
					<div className="number-column">
						<CurrencyNumber amount={cell.original?.lenderFinanced} precision={0} />
					</div>
				);
			},
			width: 200,
			Filter: ({ filter, onChange }) => <TextField value={filter ? filter.value : ''} onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard" />
		},
		{
			Header: 'Claim Period',
			accessor: 'claimPeriod',
			Cell: cell => {
				return <span>{moment(cell.original?.claimPeriod).format('MMMM YYYY') ?? ''}</span>;
			},
			width: 170,
			Filter: ({ filter, onChange }) => <DateFilter filter={filter} onChange={onChange} type="month" />
		},
		{
			Header: 'Claim Numbers',
			accessor: 'claimNumber',
			Cell: cell => {
				return <span className={styles.claimNumers}>{cell.original?.claimNumber.join(', ')}</span>;
			},
			width: 250,
			Filter: ({ filter, onChange }) => <TextField value={filter ? filter.value : ''} onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard" />
		},
		{
			Header: 'Created At',
			accessor: 'createdAt',
			Cell: cell => {
				return <span>{moment(cell.original?.createdAt).format('MMMM DD YYYY') ?? ''}</span>;
			},
			width: 170,
			Filter: ({ filter, onChange }) => <DateFilter filter={filter} onChange={onChange} type="date" />
		}
	];

	useEffect(() => {
		dispatch({ type: actions.GET_PAYMENTS });
	}, []);

	return (
		<div className={styles.container}>
			<Paper>
				<Grid container className="grid-container">
					<Grid item xs={12}>
						<Typography variant="h1" gutterBottom>
							Payments
						</Typography>
					</Grid>
					<Grid item xs={12}>
						{(isLoading && <Loader isLoading />) || (
							<RenderTable data={payments ?? []} loading={isLoading} minRows={2} columns={columns} filterable={!isLoading} sortable={true} />
						)}
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
});
