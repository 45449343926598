import { call, put } from "redux-saga/effects";
import BiddingPortalApi from "services/api/BiddingPortalApi";
import {
  setResultProcessOrder,
  setOrder,
  setInitializeBiddingPortal,
  initializeBiddingPortal,
} from "./actions";
import {
  actionCreatorPreloaderOn,
  actionCreatorPreloaderOff,
} from "../preloader/actions";
import { Util } from "components";

export function* initializeBidding(action) {
  try {
    yield put(actionCreatorPreloaderOn());
    const dataResult = yield call(
      BiddingPortalApi.initializeBiddingPortal,
      action.payload,
    );
    if (!Util.isErrorResponseBidding(dataResult)) {
      yield put(setInitializeBiddingPortal(dataResult));
    }

    yield put(actionCreatorPreloaderOff());
  } catch (err) {
    yield put(actionCreatorPreloaderOff());
    const error = err.message || err;
    console.error(error);
  }
}

export function* createOrder(action) {
  try {
    yield put(actionCreatorPreloaderOn());
    const dataResult = yield call(BiddingPortalApi.createOrder, action.payload);
    let responseObject;
    if (Util.isErrorResponseBidding(dataResult)) {
    } else {
    }
    if (dataResult.status === "success") {
      responseObject = {
        status: "success",
        message: "Order placed successfully!",
      };
    } else {
      responseObject = {
        status: "error",
        message: "There was an error",
      };
    }
    yield put(setResultProcessOrder(responseObject));
    yield put(actionCreatorPreloaderOff());
    yield put(initializeBiddingPortal());
  } catch (err) {
    yield put(actionCreatorPreloaderOff());
    const error = err.message || err;
    console.error(error);
  }
}

export function* cancelOrder(action) {
  try {
    yield put(actionCreatorPreloaderOn());
    const dataResult = yield call(BiddingPortalApi.cancelOrder, action.payload);
    let responseObject;
    if (Util.isErrorResponseBidding(dataResult)) {
      responseObject = {
        status: "error",
        message: "There was an error",
      };
    } else {
      responseObject = {
        status: "success",
        message: "Order canceled successfully!",
      };
    }
    yield put(setResultProcessOrder(responseObject));
    yield put(actionCreatorPreloaderOff());
    yield put(initializeBiddingPortal());
  } catch (err) {
    yield put(actionCreatorPreloaderOff());
    const error = err.message || err;
    console.error(error);
  }
}

export function* acceptOrder(action) {
  try {
    yield put(actionCreatorPreloaderOn());
    const dataResult = yield call(BiddingPortalApi.acceptOrder, action.payload);
    let responseObject;
    if (Util.isErrorResponseBidding(dataResult)) {
      responseObject = {
        status: "error",
        message: "There was an error",
      };
    } else {
      responseObject = {
        status: "success",
        message: "Order accepted successfully!",
      };
    }
    yield put(setResultProcessOrder(responseObject));
    yield put(actionCreatorPreloaderOff());
    yield put(initializeBiddingPortal());
  } catch (err) {
    yield put(actionCreatorPreloaderOff());
    const error = err.message || err;
    console.error(error);
  }
}

export function* order(action) {
  try {
    yield put(actionCreatorPreloaderOn());
    const dataResult = yield call(BiddingPortalApi.order, action.payload);
    if (!Util.isErrorResponseBidding(dataResult)) {
      yield put(setOrder(dataResult.smartContractBiddingPortal));
    }
    yield put(actionCreatorPreloaderOff());
  } catch (err) {
    yield put(actionCreatorPreloaderOff());
    const error = err.message || err;
    console.error(error);
  }
}
