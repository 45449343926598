export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";
export const SET_COGNITO_INFO = "SET_COGNITO_INFO";

export const changePasswordRequest = (oldPassword, newPassword, email) => ({
  type: CHANGE_PASSWORD_REQUEST,
  payload: { oldPassword, newPassword, email }
});

export const cognitoInfo = userName => ({
  type: SET_COGNITO_INFO,
  payload: { userName: userName }
});

export const changePasswordSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: {}
});

export const changePasswordFailed = erroMessage => ({
  type: CHANGE_PASSWORD_FAILED,
  payload: { errorMessage: erroMessage, detailedMessage: erroMessage }
});
