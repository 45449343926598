// eslint-disable-next-line import/no-named-as-default
import actions from './actions';

const initialState = {
	loading: {
		orders: false,
		availableClaims: false,
		participants: false,
		contracts: false,
		pof: false
	},
	orders: [],
	availableClaims: [],
	participatingPartners: [],
	contracts: [],
	pofUrl: ''
};

export default function(state = initialState, action) {
	switch (action.type) {
		case actions.ADD_NEW_ORDER:
			return { ...state, orders: state.orders.concat(action.payload) };

		case actions.SET_STATE:
			return { ...state, ...action.payload };

		case actions.SET_LOADING:
			return { ...state, loading: { ...state.loading, ...action.payload } };

		default:
			return state;
	}
}
