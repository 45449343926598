import React, { memo, useCallback, useEffect, useState } from 'react';
import { CardContent, Grid, Paper, Typography, IconButton } from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import { InfoTextContainer } from 'containers';
import { RenderButton, RenderTable, AuthManager, ConfirmationDialog, Loader } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { CreateNewUser, UpdateUser } from './components';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

export default memo(() => {
	const dispatch = useDispatch();

	const [updateUserId, setUpdateUserId] = useState(false);

	const loading = useSelector(state => state.myaccount && state.myaccount.loadingUserAccounts);
	const users = useSelector(state => (state.myaccount && state.myaccount.userAccounts) || []);
	const { t } = useTranslation('myaccount/general_information');

	const [createNewUserModalOpen, setCreateNewUserModalOpen] = useState(false);
	const toggleCreateNewUserForm = useCallback((forceClose = false) => setCreateNewUserModalOpen(state => (forceClose && false) || !state));
	const triggerRemoveUser = useCallback(userId => {
		dispatch({
			type: 'myaccount/REMOVE_USER_SUBACOUNT',
			payload: { _id: userId }
		});
	}, []);

	const columns = [
		{
			Header: t('name'),
			accessor: 'name'
		},
		{
			Header: t('email'),
			accessor: 'email'
		},
		{
			Header: '',
			accessor: 'actions',
			width: 100,
			Cell: cell => {
				const { original } = cell;

				return (
					<div className="table-actions">
						<AuthManager permissions={['myaccount.userAdministration.delete-users']}>
							<ConfirmationDialog
								title={t('delete_confirm_msg')}
								onConfirm={() => triggerRemoveUser(original._id)}
								okText={t('ok_text')}
								cancelText={t('no')}
							>
								<IconButton type="danger" size="small">
									<Delete />
								</IconButton>
							</ConfirmationDialog>
						</AuthManager>
						<AuthManager permissions={['myaccount.userAdministration.update-users']}>
							<IconButton onClick={() => setUpdateUserId(original)} color="primary" size="small">
								<Edit />
							</IconButton>
						</AuthManager>
					</div>
				);
			}
		}
	];

	useEffect(() => {
		dispatch({ type: 'myaccount/GET_USER_ACCOUNTS' });
	}, []);

	return (
		<AuthManager redirectTo="/account" permissions={['myaccount.userAdministration']}>
			<div className={styles.container}>
				{(loading && <Loader loading />) || (
					<Grid container spacing={1}>
						<Grid item xs={12} md={8}>
							<Paper>
								<CardContent>
									<div className="header">
										<Typography variant="h1" gutterBottom>
											{t('user_administration')}
										</Typography>
										<AuthManager permissions={['myaccount.userAdministration.create-users']}>
											<RenderButton onClick={() => toggleCreateNewUserForm()} color="danger" small>
												{t('add_user')}
											</RenderButton>
										</AuthManager>
									</div>

									<RenderTable minRows={2} data={(users && Array.isArray(users) && users) || []} columns={columns} filterable={false} />
								</CardContent>
							</Paper>
						</Grid>
						<Grid item xs={12} md={4}>
							{(t('info_text.user_administration', { defaultValue: [] }) || []).map(p => (
								// eslint-disable-next-line react/no-danger
								<div dangerouslySetInnerHTML={{ __html: p }} />
							))}
						</Grid>
					</Grid>
				)}
				<CreateNewUser open={createNewUserModalOpen} openCloseHandler={toggleCreateNewUserForm} />
				<UpdateUser open={!!updateUserId} openCloseHandler={() => setUpdateUserId(false)} initialValues={updateUserId} />
			</div>
		</AuthManager>
	);
});
