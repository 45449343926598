import React, { memo, useCallback, useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@material-ui/core';
import { RenderButton } from 'components';
import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';

export default memo(({ open, openCloseHandler, templateId }) => {
	const dispatch = useDispatch();
	const triggerConfirmDelete = useCallback(() => {
		dispatch({
			type: 'reports/DELETE_REPORT_TEMPLATE',
			payload: templateId
		});
		openCloseHandler();
	}, [templateId]);

	return (
		<Dialog open={open} className={styles.container}>
			<DialogTitle>Are you sure?</DialogTitle>
			<DialogContent>Are you sure you want to delete this template ?</DialogContent>
			<DialogActions>
				<RenderButton onClick={openCloseHandler} color="transparent">
					No
				</RenderButton>
				<RenderButton color="primary" onClick={triggerConfirmDelete}>
					Yes, delete template
				</RenderButton>
			</DialogActions>
		</Dialog>
	);
});
