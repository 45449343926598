import { initialPasswordChallengeData } from './selectors';
import actions from './actions';

export default (state = initialPasswordChallengeData, { type, payload }) => {
	switch (type) {
		case actions.SET_STATE:
			return {
				...state,
				...payload
			};

		case actions.CLEAR_STATE:
			return initialPasswordChallengeData;

		default:
			return state;
	}
};
