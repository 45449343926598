import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Grid, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core';

import { TableSmartContracts, RenderButton, RenderNotification, AuthManager } from 'components';
import { useTranslation } from 'react-i18next';

const Transition = props => <Slide direction="up" {...props} />;

const SmartContracts = props => {
	const { t } = useTranslation('smartcontracts/list');
	const {
		showNewContract,
		messageStatus,
		messageStatusError,
		modalTerminateSmartContract,
		toggleTerminateSmartContract,
		confirmTerminateSmartContract,
		idSmartContractTerminate
	} = props;

	return (
		<Grid container justify="flex-end" spacing={1}>
			<Grid item xs={12} style={{ textAlign: 'right' }}>
				<RenderNotification variant="danger" floating open={messageStatusError} message={messageStatusError} />
				<RenderNotification variant="success" floating open={messageStatus} message={messageStatus} />
				<AuthManager userRole={['carrier']} permissions={['smartContracts.create-contracts']}>
					<RenderButton color="primary" component={Link} to="/smart-contracts/new">
						{t('new_smart_contract')}
					</RenderButton>
				</AuthManager>
			</Grid>
			<Grid item xs={12}>
				<TableSmartContracts action={props.handleFundingReview} {...props} />

				<Dialog open={modalTerminateSmartContract} onBackdropClick={toggleTerminateSmartContract} TransitionComponent={Transition}>
					<DialogTitle>{t('terminate_modal.title')}</DialogTitle>
					<DialogContent>{t('terminate_modal.text')}</DialogContent>
					<DialogActions>
						<RenderButton color="danger" onClick={toggleTerminateSmartContract}>
							{t('terminate_modal.decline')}
						</RenderButton>
						<RenderButton
							color="primary"
							onClick={() => {
								confirmTerminateSmartContract(idSmartContractTerminate);
								toggleTerminateSmartContract();
							}}
						>
							{t('terminate_modal.confirm')}
						</RenderButton>
					</DialogActions>
				</Dialog>
			</Grid>
		</Grid>
	);
};

export default SmartContracts;
