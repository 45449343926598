import React from "react";
import { PrivateTemplate, BreadCrumb } from "components";
import SmartContractNewScreen from "screens/SmartContracts/update";

const SmartContractUpdatePage2 = () => (
  <PrivateTemplate>
    <BreadCrumb
      name="Update Smart Contract"
      firstLevel="Home"
      secondLevel="Update smart contract"
    />

    <SmartContractNewScreen />
  </PrivateTemplate>
);
export default SmartContractUpdatePage2;
