import React, {memo, useCallback, useEffect, useState} from 'react';
import { CardContent, Paper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment-timezone";
import { RenderTable, Loader, RenderButton } from "components";
import { contractIdFormatter } from "utils"
import Countdown from "react-countdown-now";
import FileDownload from '@material-ui/icons/FileDownload';
import { useTranslation } from 'react-i18next';

import ReportFilters from "../ReportFilters";
import styles from './styles.module.scss';

export default memo(() => {
	const dispatch = useDispatch();
	const { t } = useTranslation('reports/reports');

	const loading = useSelector(state => state.reports && state.reports.loading || false);
	const tableData = useSelector(state => state.reports && state.reports.CDRReport || [])

	const [filters, setFilters] = useState({
		startDate: moment().utc().hour(0).minute(0).seconds(0).millisecond(0),
		endDate: moment().utc().hour(23).minute(0).seconds(0).millisecond(0),
	})

	const triggerCDRRequest = useCallback(() => {
		dispatch({ type: 'reports/REQUEST_CDR_REPORT', payload: filters })
	},[filters])

	const triggerDownloadCDRReport = useCallback((id) => {
		dispatch({ type: 'reports/DOWNLOAD_CDR_REPORT', payload: id })
	},[])

	const statusFormatter = data => {
		if (data.processing) {
			return t('processing').toString().concat("...");
		}
		if (data.isReady) {
			return t('ready');
		}
		return (
			<Countdown
				date={moment(data.updatedAt)
					.add("5", t('minutes'))
					.toDate()}
				renderer={({ hours, minutes, seconds, completed }) => {
					if (completed) {
						return t('no_data_to_load');
					}
					return <span>{t('CDRDownloadTab.remaining', { minutes })}</span>;
				}}
			/>
		);
	}

	const columns = [
		{
			Header: t('smart_contract'),
			id: row=> row.smartContract,
			accessor: row=>row.name? `${row.name} (${contractIdFormatter(row.smartContract)})`: contractIdFormatter(row.smartContract),
		},
		{
			Header: t('partner'),
			accessor: "partner"
		},
		{
			Header: t('contract_type'),
			accessor: "type"
		},
		{
			Header: t('start_date'),
			accessor: "startDate",
			Cell: data =>
				`${moment(data.value).format("DD MMM YYYY")} - ${
					data.original.startHour ? data.original.startHour : ""
				}H`
		},
		{
			Header: t('end_date'),
			accessor: "endDate",
			Cell: data =>
				`${moment(data.value).format("DD MMM YYYY")} - ${
					data.original.endHour ? data.original.endHour : ""
				}H`
		},
		{
			Header: t('status'),
			accessor: "isReady",
			Cell: data => statusFormatter(data.original)
		},
		{
			Header: "",
			headerClassName: "rt-text-right",
			className: "rt-text-right",
			accessor: "downloadUrl",
			Cell: data =>
				data.original.isReady ? (
					<RenderButton color="primary" onClick={() => triggerDownloadCDRReport(data.original._id)}>
						<FileDownload /> Download
					</RenderButton>
				) : (
					""
				)
		}
	];

	useEffect(() => {

		dispatch({
			type: 'reports/SET_STATE',
			payload: {
				CDRReport: []
			}
		})
		dispatch({ type: 'reports/GET_CDR_REPORT' })
	},[])

	return (
		<div className={styles.container}>
			<ReportFilters
				reportType="cdr-report"
				onChange={setFilters}
				values={filters}
				onRunReport={triggerCDRRequest}
			/>
			{loading && <Loader loading /> || (
				<Paper>
					<CardContent>
						<RenderTable
							minRows={3}
							resizable={false}
							sortable={false}
							data={tableData}
							style={{ width: "100%" }}
							columns={columns}
							filterable={false}
						/>
					</CardContent>
				</Paper>
			)}
		</div>
	);
});
