import React, { memo, useEffect } from "react";
import { CardContent, Grid, Paper, Typography } from '@material-ui/core';
import moment from 'moment';
import style from './style.module.scss';
import { useSelector } from "react-redux";
import { Loader } from "components";
import { useTranslation } from 'react-i18next';

const ContractDetails = memo(({ data }) => {
	const isLoading = useSelector(state => (state.routes && state.routes.loading) || false);
	const { t } = useTranslation('routing/routing');
	return (
		<div className={style.container}>
			<Paper>
				{(isLoading && <Loader loading />) || ''}
				{(data && (
					<CardContent>
						<h3>{t('smart_contract')}&nbsp;{data.contractRef}&nbsp;{t('details')}</h3>
						<Grid container spacing={2} className="top-row">
							<Grid item xs={4}>
								<h4>{t('partner')}</h4>
								{(data.partner && data.partner.name) || ''}
							</Grid>
							<Grid item xs={2}>
								<h4>{t('contract_start_date')}</h4>
								{(data.startDate && moment(data.startDate).isValid() && moment(data.startDate).format('MMM DD YYYY')) || ''}
							</Grid>
							<Grid item xs={2}>
								<h4>{t('contract_end_date')}</h4>
								{(data.endDate && moment(data.endDate).isValid() && moment(data.endDate).format('MMM DD YYYY')) || 'Open'}
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={2}>
								<h4>{t('contract_type')}</h4>
								{data && data.isOriginContract ? 'Origination' : 'Standard'}
							</Grid>
							<Grid item xs={2}>
								<h4>{t('traffic_type')}</h4>
								{data.trafficType || ''}
							</Grid>
							<Grid item xs={2}>
								<h4>{t('billing_bycle')}</h4>
								{data.billingCycle || ''}
							</Grid>
							<Grid item xs={2}>
								<h4>{t('payment_terms')}</h4>
								{(data.paymentTerms !== null && `${data.paymentTerms} Days`) || ''}
							</Grid>
						</Grid>
					</CardContent>
				)) ||
				''}
				{(!data && <CardContent>{t('loading')}</CardContent>) || ''}
			</Paper>
		</div>
	)
});

export default ContractDetails;
