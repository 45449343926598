import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

export const useModal = ({ openedSmartContract, openedOrder }) => {
	const dispatch = useDispatch();
	const { partner, scQty, status, pof, _id } = openedSmartContract || {};

	const [selectedInvoice, setSelectedInvoice] = useState(null);
	const [selectedTab, setSelectedTab] = useState('existingPartners');

	const { myaccount, escrowmanaging, notifications } = useSelector(state => state || {});
	const { participatingPartners = [], loading, contracts } = escrowmanaging || {};
	const { name } = myaccount?.accountData || {};
	const transactionResult = notifications.notifications;

	//************ HIDING CLOSING THE MODAL AFTER SUCCESSFUL TRANSACTION *****************/

	//************ HIDING CLOSING THE MODAL AFTER SUCCESSFUL TRANSACTION *****************/
	useEffect(() => {
		if (
			!loading.orders &&
			transactionResult.length > 0 &&
			(transactionResult[0]?.message === 'Proof of fulfillment approved' || transactionResult[0]?.message === 'Proof of fulfillment rejected')
		) {
			setSelectedInvoice(null);
		}
	}, [loading.orders, transactionResult]);

	//*********** LOADING POF PDF ON SELECTION *******************************************/
	useEffect(() => {
		if (selectedInvoice?._id && _id) {
			dispatch({
				type: 'escrowManaging/GET_POF_URL',
				payload: { contractId: _id, proofId: selectedInvoice._id }
			});
		}
	}, [selectedInvoice?._id, _id]);

	//*********** PREPARING THE MODAL ***************************************************/

	const itemsUnderReview = useMemo(() => {
		return pof?.reduce((acc, curr) => {
			if (curr.status === 'under-review') acc += parseInt(curr.deliveredQty);
			return acc;
		}, 0);
	}, [pof]);

	const options = useMemo(() => {
		return participatingPartners?.map(partner => ({ value: partner._id, label: partner.participantName }));
	}, [participatingPartners]);

	useEffect(() => {
		dispatch({
			type: 'escrowManaging/GET_PARTICIPATING_PARTNERS'
		});
	}, []);

	//************* HANDLING POF OPERATIONS  ******************************************/
	const handleUpdatePof = useCallback(
		status => {
			dispatch({
				type: 'escrowManaging/UPDATE_CONTRACT_POF',
				payload: {
					smartContractId: openedSmartContract._id,
					pofId: selectedInvoice._id,
					status,
					approvedOn: moment().format('MMM Do YY'),
					approvedBy: name,
					orderId: openedOrder._id
				}
			});

			if (status === 'approved') {
				//UPDATING CONTRACT STATUS TO FULFILLED IF ALL POF ARE APPROVED
				const deliveredDevices = pof?.reduce((acc, curr) => {
					if (curr.status === 'approved') acc += parseInt(curr.deliveredQty);
					return acc;
				}, 0);

				const thisPofQty = parseInt(pof.find(p => p._id === selectedInvoice._id).deliveredQty) || 0;

				if (deliveredDevices + thisPofQty >= scQty) {
					dispatch({
						type: 'escrowManaging/UPDATE_CONTRACT_STATUS',
						payload: {
							_id: openedSmartContract._id,
							status: 'fulfilled'
						}
					});

					//UPDATING ORDER STATUS TO FULFILLED IF ALL CONTRACTS ARE FULFILLED
					if (openedOrder?.orderTotalFulfilled + scQty >= openedOrder.orderQty) {
						dispatch({
							type: 'escrowManaging/UPDATE_ORDER_STATUS',
							payload: {
								_id: openedOrder._id,
								status: 'fulfilled'
							}
						});
					}
				}
			}
		},
		[openedSmartContract, selectedInvoice, pof, name, scQty]
	);

	const handleStatusChange = useCallback((_id, status) => {
		dispatch({
			type: 'escrowManaging/UPDATE_CONTRACT_STATUS',
			payload: {
				_id,
				status
			}
		});
	}, []);

	return {
		itemsUnderReview,
		selectedInvoice,
		setSelectedInvoice,
		handleUpdatePof,
		handleStatusChange,
		loading
	};
};
