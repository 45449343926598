import { API, Auth } from 'aws-amplify';
import axios from 'axios';

const axiosAuthHeader = () =>
	Auth.currentAuthenticatedUser()
		.then(user => {
			const headers = {
				AuthorizationInfo: user.signInUserSession.idToken.jwtToken
			};

			const impersonateCode = window.localStorage.getItem('IMPERSONATE_CODE');
			if (impersonateCode) {
				headers.impersonateCode = impersonateCode;
			}

			return headers;
		})
		.catch(err => console.log(err));

const contractsApi = {

	async getContracts(queryStringParameters) {
		return API.get('CONTRACT_SERVICE_API', '', { queryStringParameters })
			.then(response => response)
			.catch(err => err);
	},
	async getNewSmartContractData() {
		return API.get('CONTRACT_SERVICE_API', '/create')
			.then(response => response)
			.catch(err => err);
	},
	async getSampleRateplan() {
		return API.get('CONTRACT_SERVICE_API', '/rateplan/sample/download',{ responseType: 'blob' })
			.then(response => response)
			.catch(err => err);
		// return (await getInstance('SMART_CONTRACT_SERVICE_API'))
		// 	.get('rates/sample-rateplan/download', {
		// 		responseType: 'blob'
		// 	})
		// 	.then(res => res.data)
		// 	.catch(err => {
		// 		console.error(err);
		// 		return err;
		// 	});
	},


	async getProjects() {
		return API.get('CONTRACT_SERVICE_API', '/projects', {})
			.then(response => response)
			.catch(err => err);
	},
	async getProject(projectId) {
		return API.get('CONTRACT_SERVICE_API', `/projects/${projectId}`, {})
			.then(response => response)
			.catch(err => err);
	},
	async createProject(payload) {
		return API.post('CONTRACT_SERVICE_API',`/projects`, { body: payload })
			.then(response => response)
			.catch(err => err);
	},
	async deleteProject(projectId) {
		return API.del('CONTRACT_SERVICE_API', `/projects/${projectId}`, {})
			.then(response => response)
			.catch(err => err);
	},
	async updateProject(payload) {
		return API.put('CONTRACT_SERVICE_API',`/projects/${payload.id}`,  { body: payload })
			.then(response => response)
			.catch(err => err);
	},
	async getContract(smartContractId) {
		return API.get('CONTRACT_SERVICE_API', `/${smartContractId || ''}`, {})
			.then(response => response)
			.catch(err => err);
	},
	async saveSmartContractDraft(data) {
		return API.post('CONTRACT_SERVICE_API', '/saveDraft', { body: data })
			.then(response => response)
			.catch(err => err);
	},
	async secondSignature(smartContractId) {
		return API.patch('CONTRACT_SERVICE_API', `/second-signature/${smartContractId}`, {})
			.then(response => response)
			.catch(err => err);
	},
	async createContract(data) {
		return API.post('CONTRACT_SERVICE_API', '/create', { body: data })
			.then(response => response)
			.catch(err => err);
	},
	async updateSmartContract(data) {
		return API.patch('CONTRACT_SERVICE_API', `/${data._id}`, { body: data })
			.then(response => response)
			.catch(err => err);
	},
	async uploadRates(data) {
		return API.post('CONTRACT_SERVICE_API', `/rates`, { body: data })
			.then(response => response)
			.catch(err => err);
	},
	async getRateFileData(tempId) {
		return API.get('CONTRACT_SERVICE_API', `/ratePlans/${tempId || ''}`, {})
			.then(response => response)
			.catch(err => err);
	},
	async downloadRateFile(rateplanId) {
		return API.get('CONTRACT_SERVICE_API', `/rateplan/${rateplanId || ''}/download`, {})
			.then(response => response)
			.catch(err => err);
	},
};

export default contractsApi;
