import React, { Component } from 'react';
import { Report } from 'components';
import { bindActionCreators } from 'redux';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { getReportsData, setReportsData, getReportsCDRData, getReportLoading } from 'store/actions';
import { fromReports, fromUseraccount } from 'store/selectors';

class ReportContainer extends Component {
	constructor(props) {
		super(props);
		const { role } = this.props;
		const isFunder = role.indexOf(ROLES.FUNDER) !== -1;
		this.state = {
			activeTab: isFunder ? 'lenderReport' : 'customerTraffic',
			isDownload: false
		};

		this.generateReport = this.generateReport.bind(this);
		this.setDownload = this.setDownload.bind(this);
	}

	componentWillMount() {
		const { activeTab } = this.state;
		this.props.setReportsData([]); // reset data on tab change
		this.props.getReportsData({}, activeTab);
	}

	handleTabChange = (event, activeTab) => {
		const { filterOptions } = this.props;
		this.props.setReportsData([]); // reset data on tab change
		if (activeTab !== 'callSearch') {
			this.props.getReportsData(filterOptions, activeTab);
		} else {
			this.props.getReportsCDRData();
		}
		this.setState({ activeTab });
	};

	generateReport = data => {
		const { activeTab, isDownload } = this.state;
		const { getReportsData } = this.props;
		getReportsData(data, `${activeTab}${isDownload ? 'Download' : ''}`);
	};

	setDownload(val) {
		this.setState({ isDownload: val });
	}

	render() {
		const { activeTab } = this.state;
		const { reportData, reportCDRData, role, reportLoading } = this.props;

		const isFunder = role.indexOf(ROLES.FUNDER) !== -1;

		return (
			<div>
				<Report
					isFunder={isFunder}
					activeTab={activeTab}
					isLoading={reportLoading}
					reportData={Array.isArray(reportData) ? reportData : []}
					reportCDRData={Array.isArray(reportCDRData) ? reportCDRData : []}
					handleTabChange={this.handleTabChange}
					generateReportHandler={this.generateReport}
					setDownload={this.setDownload}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	reportLoading: fromReports.getReportLoading(state),
	reportData: fromReports.getReportsData(state),
	reportCDRData: fromReports.getCDRReportsData(state),
	filterOptions: getFormValues('ReportFiltersForm')(state),
	role: fromUseraccount.getUserRol(state)
});

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			getReportsCDRData,
			getReportsData,
			setReportsData
		},
		dispatch
	);
}

ReportContainer.propTypes = {
	// filterData: PropTypes.shape().isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportContainer);
