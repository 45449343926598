import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Dialog, DialogContent, DialogActions, Chip, Checkbox, FormControlLabel } from '@material-ui/core';
import _, { isEmpty } from 'lodash';
import moment from 'moment-timezone';

import { RenderTable, RenderButton } from 'components';

import Loader from 'components/atoms/Loader';
import CurrencyNumber from 'components/atoms/CurrencyNumber';

import actions from 'store/acp/actions';
import styles from './styles.module.scss';

export default memo(({ isOpen, openCloseHandler, data }) => {
	const dispatch = useDispatch();	
	console.log('data', data);

	return (
		<div className={styles.container}> 
		  <Dialog maxWidth="xl" open={isOpen} onClose={openCloseHandler}>
			<DialogContent>
			  <Grid container className={styles.container}>
				<Grid item xs={3}>
				  <h3>Claim period: </h3>
				</Grid>
				<Grid item xs={3} style={{paddingTop:"5px"}}>
				  <p>{moment(data?.claimPeriod).format('MMMM YYYY')}</p>
				</Grid>
	  
				<Grid item xs={3}>
				  <h3>Type: </h3>
				</Grid>
				<Grid item xs={3} style={{paddingTop:"12px"}}>
				  <Chip
					style={{
						backgroundColor: 
						data?.claimType === 'sales'
						? '#2db7f5'
						: data?.claimType === 'mrc'
						? '#108ee9'
						: data?.claimType === 'claimable-devices'
						? '#87d068'
						: 'gray'
					}}
					label={data?.claimType ?? ''}
				  >
					{data?.claimType ?? ''}
				  </Chip>
				</Grid>
	  
				<Grid item xs={3}>
				  <h3>Claim number: </h3>
				</Grid>
				<Grid item xs={3} style={{paddingTop:"5px"}}>
				  <p>{data?.claimNumber ?? ''}</p>
				</Grid>
	  
				<Grid item xs={3}>
				  <h3>Status: </h3>
				</Grid>
				<Grid item xs={3} style={{paddingTop:"12px"}}>
				<Chip
					style={{
					backgroundColor: 
						data?.nladVerificationStatus === 'pending'
						? 'orange'
						: data?.nladVerificationStatus === 'rejected'
						? 'red'
						: data?.nladVerificationStatus === 'processed'
						? 'green'
						: 'orange'
					}}
					label={data?.nladVerificationStatus ?? ''}
				/>
				</Grid>
				<Grid item xs={3}>
            <h3>Quantity: </h3>
          </Grid>
          <Grid item xs={3} style={{paddingTop:"5px"}}>
            <p>{data?.quantity ?? ''}</p>
          </Grid>

          <Grid item xs={3}>
            <h3>Uploaded at: </h3>
          </Grid>
          <Grid item xs={3} style={{paddingTop:"5px"}}>
            <p>{moment(data?.createdAt).format('LL') ?? ''}</p>
          </Grid>

          {data?.nladVerificationStatus !== 'rejected' && (
            <Grid container>
              <Grid item xs={3}>
                <h3>Value: </h3>
              </Grid>
              <Grid item xs={3}>
                <div className="number-column">
                  <CurrencyNumber value={data?.value} />
                </div>
              </Grid>
            </Grid>
          )}

        </Grid>
      </DialogContent>
      <DialogActions>
        {data?.isArchived ? (
          <RenderButton color="warning" onClick={() => {
            dispatch({ type: actions.UNARCHIVE_NLAD_SUBMISSION, payload: data?._id });
            openCloseHandler();
          }}>
            Unarchive
          </RenderButton>
        ) : (
          <RenderButton color="danger" onClick={() => {
            dispatch({ type: actions.ARCHIVE_NLAD_SUBMISSION, payload: data?._id });
            openCloseHandler();
          }}>
            Archive
          </RenderButton>
        )}
        <RenderButton onClick={openCloseHandler} color="primary">
          Back
        </RenderButton>
      </DialogActions>
    </Dialog>
  </div>
);
});
