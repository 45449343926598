export const initialState = {};

export const initialVerificationCodeData = {
  code: null,
  email: null,
  password: null,
  errorMessage: null,
  loginSuccess: null
};
export const VERIFICATION_CODE_DATA = "VERIFICATION_CODE_DATA";

export const getVerificationCodeDataState = (state = initialState) =>
  state[VERIFICATION_CODE_DATA] || initialVerificationCodeData;
