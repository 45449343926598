import React, { memo, useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography, Paper, TextField, Checkbox, Button, MenuItem } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import * as moment from 'moment';

import { RenderTable } from 'components';
import Loader from 'components/atoms/Loader';
import RenderButton from 'components/atoms/RenderButton';
import CurrencyNumber from 'components/atoms/CurrencyNumber';
import RenderAcpType from 'components/atoms/AcpType';

// import RejectedSubmissionsModal from './RejectedSubmissionsModal';
import actions from 'store/acp/actions';
import AprovedSubmissionsModal from './AprovedSubmissionsModal';
import styles from './styles.module.scss';

export default memo(({ openedTable, setOpenedTable, isLoading, 
	nladSubmissions, numberOfSubmissions, numberOfSubmissionsArchived, 
	nladSubmissionsArchived, nladSubmissionsCurrentMonth,
	nladSubmissionsLastMonth  }) => {
	const dispatch = useDispatch();

	const [selectedSubmissionsIds, setSelectedSubmissionsIds] = useState([]);
	const [showActiveSubmissions, setShowActiveSubmissions] = useState(true);
	const [showArchivedSubmissions, setShowArchivedSubmissions] = useState(false);
	const [processedNladSubmissionsCurrentMonth, setProcessedNladSubmissionsCurrentMonth] = useState({
		active: [],
		archived: [],
		total: 0
	});
	const [processedNladSubmissionsLastMonth, setProcessedNladSubmissionsLastMonth] = useState({
		active: [],
		archived: [],
		total: 0
	});
	const [allProcessedNladSubmissions, setAllProcessedNladSubmissions] = useState({
		active: [],
		archived: []
	});

	const [aprovedModalOpen, setAprovedModalOpen] = useState(false);
	const [aprovedModalData, setAprovedModalData] = useState({});

	const handleArchiveSubmission = useCallback((e, _id) => {
		const submissionId = _id;
		e.preventDefault();
		e.stopPropagation();
		dispatch({
			type: actions.ARCHIVE_NLAD_SUBMISSION,
			payload: [submissionId]
		});
	}, []);

	const handleUnarchiveSubmission = useCallback((e, _id) => {
		const submissionId = _id;
		e.preventDefault();
		e.stopPropagation();
		dispatch({
			type: actions.UNARCHIVE_NLAD_SUBMISSION,
			payload: [submissionId]
		});
	}, []);

	const handleArchiveSelection = useCallback(() => {
		dispatch({
			type: actions.ARCHIVE_NLAD_SUBMISSION,
			payload: selectedSubmissionsIds
		});
		setSelectedSubmissionsIds([]);
	}, [selectedSubmissionsIds, dispatch]);

	const handleUnarchiveSelection = useCallback(() => {
		dispatch({
			type: actions.UNARCHIVE_NLAD_SUBMISSION,
			payload: selectedSubmissionsIds
		});
		setSelectedSubmissionsIds([]);
	}, [selectedSubmissionsIds, dispatch]);

	const handleSubmissionChecked = useCallback(
		e => {
			const { checked, value } = e.target;
			if (checked) {
				setSelectedSubmissionsIds([...selectedSubmissionsIds, value]);
			} else {
				setSelectedSubmissionsIds(selectedSubmissionsIds.filter(id => id !== value));
			}
		},
		[selectedSubmissionsIds]
	);

	const triggerDownloadNladSubmission = (e, _id, claimNumber, type) => {
		e.preventDefault();
		e.stopPropagation();
		dispatch({
			type: actions.DOWNLOAD_NLAD_SUBMISSION,
			payload: {
				nladSubmissionId: _id,
				claimNumber,
				type
			}
		});
	};

	useEffect(() => {
		const processedCurrentMonth = {
			active : nladSubmissionsCurrentMonth?.active?.filter(submission => submission.nladVerificationStatus === 'processed') || [],
			archived : nladSubmissionsCurrentMonth?.archived?.filter(submission => submission.nladVerificationStatus === 'processed') || [] 
		}
		processedCurrentMonth.total = processedCurrentMonth.active.length + processedCurrentMonth.archived.length;
		const processedLastMonth = {
			active : nladSubmissionsLastMonth?.active?.filter(submission => submission.nladVerificationStatus === 'processed') || [],
			archived : nladSubmissionsLastMonth?.archived?.filter(submission => submission.nladVerificationStatus === 'processed') || []
		}
		processedLastMonth.total = processedLastMonth.active.length + processedLastMonth.archived.length;
		const allProcessedNladSubmissions = {
			active : nladSubmissions?.filter(submission => submission.nladVerificationStatus === 'processed') || [],
			archived : nladSubmissionsArchived?.filter(submission => submission.nladVerificationStatus === 'processed') || []
		}
		setAllProcessedNladSubmissions(allProcessedNladSubmissions);
		setProcessedNladSubmissionsCurrentMonth(processedCurrentMonth);
		setProcessedNladSubmissionsLastMonth(processedLastMonth);
	}, [nladSubmissionsCurrentMonth, nladSubmissionsLastMonth, nladSubmissions, nladSubmissionsArchived]);

	const DateFilter = ({ filter, onChange, type = 'date' }) => (
		<TextField
			type={type}
			value={filter ? filter.value : ''}
			onChange={e => onChange(e.target.value)}
			style={{ width: '80%' }}
			variant="standard"
			InputLabelProps={{
				shrink: true
			}}
		/>
	);

	const columnsVerified = [
		{
			Header: 'Selected',
			filterable: false,
			Cell: cell => {
				return (
					<Checkbox
						style={{ margin: 0, padding: 0 }}
						value={cell?.original?._id}
						checked={selectedSubmissionsIds.includes(cell?.original?._id)}
						onChange={handleSubmissionChecked}
					/>
				);
			},
			maxWidth: 80
		},
		{
			Header: 'Upload Date',
			accessor: 'uploadDate',
			Cell: cell => {
				return <span>{moment(cell.original?.uploadDate).format('MMM Do YYYY')}</span>;
			},
			width: 200,
			Filter: ({ filter, onChange }) => <DateFilter filter={filter} onChange={onChange} type="date" />
		},
		{
			Header: 'Verification Date',
			accessor: 'createdAt',
			Cell: cell => {
				return <span>{moment(cell.original?.createdAt).format('MMM Do YYYY')}</span>;
			},
			width: 170,
			Filter: ({ filter, onChange }) => <DateFilter filter={filter} onChange={onChange} type="date" />
		},
		{
			Header: 'Claim Number',
			accessor: 'claimNumber',
			Cell: cell => {
				return <span>{cell.original?.claimNumber}</span>;
			},
			width: 125,
			Filter: ({ filter, onChange }) => <TextField value={filter ? filter.value : ''} onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard" />
		},
		{
			Header: 'Claim Period',
			accessor: 'claimPeriod',
			Cell: cell => <span>{moment(cell.original?.claimPeriod).format('MMMM YYYY')}</span>,
			width: 200,
			Filter: ({ filter, onChange }) => <DateFilter filter={filter} onChange={onChange} type="month" />
		},
		{
			Header: 'Type',
			accessor: 'type',
			Cell: cell => {
				return <RenderAcpType size="small" claimType={cell.original?.claimType} />;
			},
			width: 125,
			Filter: ({ filter, onChange }) => (
				<TextField value={filter ? filter.value : ''} style={{ width: '80%' }} select onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard">
					<MenuItem value="">All</MenuItem>
					<MenuItem value="sales">Sales</MenuItem>
					<MenuItem value="mrc">MRC</MenuItem>
					<MenuItem value="claimable-devices">Claimable Devices</MenuItem>
				</TextField>
			)
		},
		{
			Header: <div className="number-column">Quantity</div>,
			accessor: 'quantity',
			Cell: cell => {
				return <div className="number-column">{cell.original?.quantity}</div>;
			},
			width: 125,
			Filter: ({ filter, onChange }) => <TextField value={filter ? filter.value : ''} onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard" />
		},
		{
			Header: <div className="number-column">Value</div>,
			accessor: 'value',
			Cell: cell => {
				return (
					<div className="number-column">
						<CurrencyNumber amount={cell.original?.value} precision={0} />
					</div>
				);
			},
			Filter: ({ filter, onChange }) => <TextField value={filter ? filter.value : ''} onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard" />
		},
		{
			Header: 'Notes',
			accessor: 'notes',
			filterable: false,
			Cell: cell => {
				return <span>{cell.original?.notes ?? ''}</span>;
			}
		},
		{
			Header: 'Actions',
			filterable: false,
			width: 250,
			Cell: cell => {
				const { original } = cell;
				const { _id, claimNumber, isArchived } = original;
				return (
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-start'
						}}
					>
						<RenderButton
							onClick={e => triggerDownloadNladSubmission(e, _id, claimNumber, 'approved')}
							// color="primary"
							small
							style={{
								marginRight: '5px'
							}}
						>
							Download
						</RenderButton>
						<RenderButton
							onClick={() => {
								setAprovedModalOpen(true);
								setAprovedModalData(original);
							}}
							color="primary"
							small
							style={{
								marginRight: '10px'
							}}
						>
							View
						</RenderButton>
						{isArchived ? (
							<RenderButton
								onClick={e => handleUnarchiveSubmission(e, _id)}
								color="warning"
								small
								style={{
									marginRight: '10px'
								}}
							>
								Unarchive
							</RenderButton>
						) : (
							<RenderButton
								onClick={e => handleArchiveSubmission(e, _id)}
								color="danger"
								small
								style={{
									marginRight: '10px'
								}}
							>
								Archive
							</RenderButton>
						)}
					</div>
				);
			}
		}
	];

	return (
		<div className={styles.container}>
			<Paper>
				<Grid container className="grid-container">
					<Grid
						container
						onClick={() => {
							if (openedTable === 'verified') {
								setOpenedTable('');
							} else setOpenedTable('verified');
						}}
					>
						<Grid item xs={11}>
							<Typography variant="h1">
								Approved Submissions 
							</Typography>
						</Grid>
						<Grid item xs={1} align="right">
							<Typography variant="h1" gutterBottom style={{ marginRight: '20px' }}>
								{(openedTable === 'verified' && <Remove color="primary" fontSize="large" />) ||
									(openedTable !== 'verified' && <Add color="primary" fontSize="large" />)}
							</Typography>
						</Grid>
					</Grid>
					{openedTable === 'verified' && (
						<Grid container item xs={12}>
							<Grid container spacing={2}>
							<Grid item container xs={12} style={{ marginRight: '20px' }}>
									<Grid item container xs={8} justifyContent='space-evenly'>
										<Typography variant="h3" gutterBottom="true">
											Total : {allProcessedNladSubmissions.active.length + allProcessedNladSubmissions.archived.length}
										</Typography>
										<Typography variant="h3" gutterBottom>
											Archived : {allProcessedNladSubmissions.archived.length}
										</Typography>
										<Typography variant="h3" gutterBottom>
											Total Current Month : {processedNladSubmissionsCurrentMonth.total}
										</Typography>
										<Typography variant="h3" gutterBottom>
											Total Last Month : {processedNladSubmissionsLastMonth.total}
										</Typography>
        							</Grid>
									<Grid item container xs={4} justifyContent='flex-end'>
										<Button variant="contained" color="primary" size="medium" onClick={handleArchiveSelection}>
											Archive Selection
										</Button>
										<Button variant="contained" color="secondary" size="medium" onClick={handleUnarchiveSelection}>
											Unarchive Selection
										</Button>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<label>
										<Checkbox checked={showActiveSubmissions} onChange={() => setShowActiveSubmissions(!showActiveSubmissions)} />
										Active Submissions
									</label>
									<label>
										<Checkbox checked={showArchivedSubmissions} onChange={() => setShowArchivedSubmissions(!showArchivedSubmissions)} />
										Archived Submissions ({numberOfSubmissionsArchived.approved})
									</label>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								{(isLoading && <Loader isLoading />) || (
									<RenderTable
										data={[
											...allProcessedNladSubmissions.active,
											...allProcessedNladSubmissions.archived
										]}
										loading={isLoading}
										minRows={2}
										columns={columnsVerified}
										filterable={!isLoading}
										sortable={true}
										getTrProps={(state, rowInfo) => {
											if (rowInfo && rowInfo.original.isArchived) {
												return {
													className: 'archived-row'
												};
											}
											return {};
										}}
										style={{
											marginRight: '20px'
										}}
									/>
								)}
							</Grid>
						</Grid>
					)}
				</Grid>
			</Paper>
			{!!aprovedModalOpen && <AprovedSubmissionsModal isOpen openCloseHandler={() => setAprovedModalOpen(false)} data={aprovedModalData} />}
		</div>
	);
});
