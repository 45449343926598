import React, { memo, useCallback, useEffect, useState } from 'react';
import { CardContent, Grid, Paper, Typography, Chip } from '@material-ui/core';
import { RenderButton, RenderFormikInput, Loader } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

const timeoutSeconds = 30;
export default memo(() => {
	const dispatch = useDispatch();

	const loading = useSelector(state => state.myaccount && state.myaccount.loadingSecurity);
	const accountMfaActive = useSelector(state => state.myaccount && state.myaccount.accountMfaActive);
	const qrCodeData = useSelector(state => state.myaccount && state.myaccount.accountMfaQRCodeData);

	const [timeoutTime, setTimeoutTime] = useState(timeoutSeconds); // 32 because 2 seconds is needed for the animation to finish
	const [intervalInstance, setIntervalInstance] = useState(false);
	const [displayQRCodeTriggered, setDisplayQRCodeTriggered] = useState(false);
	const [displayQRCode, setDisplayQRCode] = useState(false);
	const { t } = useTranslation('myaccount/general_information');

	const triggerDisableMFA = useCallback(() => {
		dispatch({ type: 'myaccount/UPDATE_ACCOUNT_MFA_DISABLE' });
	}, []);
	const triggerEnableMFA = useCallback(() => {
		setDisplayQRCodeTriggered(true);
		dispatch({ type: 'myaccount/GET_ACCOUNT_MFA_QR_CODE' });
	}, []);

	const resetMFA = useCallback(() => {
		clearInterval(intervalInstance);
		setIntervalInstance(false);
		setDisplayQRCodeTriggered(false);
		setTimeoutTime(timeoutSeconds);
		setDisplayQRCode(false);
	}, [intervalInstance]);

	const formik = useFormik({
		initialValues: {
			mfaBase32: (qrCodeData && qrCodeData.mfaBase32) || '',
			mfaHex: (qrCodeData && qrCodeData.mfaHex) || '',
			code: ''
		},
		onSubmit: (values, { resetForm }) => {
			resetMFA();
			dispatch({
				type: 'myaccount/UPDATE_ACCOUNT_MFA_ENABLE',
				payload: values
			});
			resetForm();
		},
		validationSchema: Yup.object().shape({
			code: Yup.string().required(t('please_input_google_auth'))
		}),
		enableReinitialize: true
	});

	useEffect(() => {
		setDisplayQRCode(false);
		setIntervalInstance(false);
		setTimeoutTime(timeoutSeconds);

		dispatch({ type: 'myaccount/SET_STATE', payload: { accountMfaQRCodeData: {} } }); // reset the state
		dispatch({ type: 'myaccount/GET_ACCOUNT_MFA_STATUS' });
	}, []);

	useEffect(() => {
		if (displayQRCodeTriggered) {
			setDisplayQRCode(true);
		}
	}, [qrCodeData, displayQRCodeTriggered]);

	useEffect(() => {
		if (displayQRCode && !intervalInstance) {
			setIntervalInstance(setInterval(() => setTimeoutTime(state => (state > 0 ? state - 1 : 0)), 1000));
		}
	}, [displayQRCode, timeoutTime]);
	useEffect(() => {
		if (timeoutTime <= 0) {
			resetMFA();
		}
	}, [timeoutTime]);

	return (
		<div className={styles.container}>
			<Paper>
				{(loading && <Loader loading />) || ''}
				<CardContent>
					<div className="header">
						<Typography variant="h1" gutterBottom>
							{t('two_step_verification')}
							<div className="pull-right">
								<Chip size="small" color={(accountMfaActive && 'primary') || 'default'} label={`${(accountMfaActive && t('active')) || t('inactive')}`} />
							</div>
						</Typography>
					</div>
					<p>
						{t('use_an_app_like_google')} <br />
						{t('use_an_app_like_google_description')}
					</p>
					{(displayQRCode && qrCodeData && qrCodeData.qrCode && (
						<div className="enable-qr-code">
							<Grid container spacing={1}>
								<Grid item xs={12} md={6}>
									<img src={qrCodeData.qrCode} className="qr-code" alt={t('auth_code')} />
								</Grid>
								<Grid item xs={12} md={6}>
									<form onSubmit={formik.handleSubmit}>
										<p>{t('scan_qr_google_help', { timeout: timeoutSeconds })}</p>
										<RenderFormikInput label={t('auth_code')} name="code" formik={formik} />
										<p>
											<RenderButton type="submit" color="primary" disabled={formik.isSubmitting}>
												{t('confirm_enable_mfa')}
											</RenderButton>
										</p>
									</form>
								</Grid>
							</Grid>
						</div>
					)) ||
						''}

					{(!displayQRCode && (
						<p>
							{(accountMfaActive && (
								<RenderButton onClick={triggerDisableMFA} color="danger">
									{t('disable_2fa')}
								</RenderButton>
							)) || (
								<RenderButton onClick={triggerEnableMFA} color="warning">
									{t('enable_2fa')}
								</RenderButton>
							)}
						</p>
					)) ||
						''}

					<p>
						{/* eslint-disable-next-line react/jsx-no-target-blank */}
						<a target="_blank" href="https://www.youtube.com/watch?v=mVIxzH4EWmA">
							{t('how_to_google')}
						</a>
					</p>
				</CardContent>
			</Paper>
		</div>
	);
});
