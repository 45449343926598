import { call, select, put } from "redux-saga/effects";
import awsAmplifyService from "services/api/awsamplify";
import { fromUseraccount } from "store/selectors";
import { push } from "connected-react-router";
import { delay } from "redux-saga";
import {
  changePasswordSuccess,
  actionCreatorInfomessagesOn,
  actionCreatorInfomessagesOff,
  actionCreatorPreloaderOff,
  actionCreatorPreloaderOn,
  changePasswordFailed,
  setPostResponse,
  loginSuccess,
  mfaSuccess,
  mfaFailure,
  loginFailure
} from "../actions";

export function* changePassword(action) {

  try {
    yield put(actionCreatorPreloaderOn());
    const result = yield call(
      awsAmplifyService.changePassword,
      action.payload.oldPassword,
      action.payload.newPassword,
      action.payload.email
    );
    if (result.status === "OK") {
      yield put(changePasswordSuccess());
      yield put(
        setPostResponse({
          status: "OK",
          message: "Your password was successfully updated"
        })
      );
      // yield put(push("/login"));
    } else {
      yield put(
        setPostResponse({
          status: "ERROR",
          message: result.message
            ? result.message === "Incorrect username or password."
              ? "Old password mismatch"
              : result.message
            : "Could not update your password. Please try again later"
        })
      );
    }

    yield put(actionCreatorPreloaderOff());
  } catch (err) {
    // yield put(actionCreatorInfomessagesOn({ message: error }, "danger"));
    console.log(`error${error}`);
    yield put(loginFailure(error));
  }
}
