import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, FieldArray, reduxForm, getFormValues } from 'redux-form';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, Grid, Slide } from '@material-ui/core';
import { RoutesDestinationRow, RenderInput, RenderRadioGroup, RenderSelect, RenderButton, RenderRadio, RenderTable } from 'components';
import { createValidator, required, validateDepentValue, validateRouteDestinations } from 'services/validation';
import routesApiService from 'services/api/routesApi';

const Transition = props => <Slide direction="up" {...props} />;

class RoutesForm extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		const { initialValues } = this.props;
		this.state = {
			matchesPerPage: 20,
			matchesPage: 1,
			routeType: initialValues.routeType ? initialValues.routeType : 'S',
			matchType: initialValues.matchType ? initialValues.matchType : 'N',
			selectedCountry: initialValues.countryMatch ? initialValues.countryMatch : '',
			selectedType: initialValues.countryMatchType ? initialValues.countryMatchType : '',
			loading: true,
			codeMatchModalOpen: false,
			countries: [],
			types: [],
			matches: [],
			matchCount: 0
		};

		this.handleRouteTypeChange = this.handleRouteTypeChange.bind(this);
		this.handleMatchTypeChange = this.handleMatchTypeChange.bind(this);
		this.handleCountryChange = this.handleCountryChange.bind(this);
		this.handleCountryTypeChange = this.handleCountryTypeChange.bind(this);
		this.handleCodeMatchModal = this.handleCodeMatchModal.bind(this);
		this.matchTablePagination = this.matchTablePagination.bind(this);
		this.matchTablePageSize = this.matchTablePageSize.bind(this);
		this.loadRouteCountries = this.loadRouteCountries.bind(this);
	}

	componentDidMount() {
		this._isMounted = true;
		const { selectedCountry, selectedType, matchesPerPage, matchesPage } = this.state;
		const search = {
			perPage: matchesPerPage,
			page: matchesPage
		};
		if (selectedCountry) search.country = selectedCountry;
		if (selectedType) search.type = selectedType;
		this.loadRouteCountries(search);
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleMatchTypeChange(value) {
		this.setState({ matchType: value[0] });
	}

	handleRouteTypeChange(value) {
		this.setState({ routeType: value[0] });
	}

	handleCountryChange(e) {
		this.loadRouteCountries({
			country: e.value,
			perPage: 20,
			page: 1
		});
		this.setState({
			selectedCountry: e.value,
			matchesPerPage: 20,
			matchesPage: 1
		});
	}

	handleCountryTypeChange(e) {
		const { selectedCountry } = this.state;
		this.loadRouteCountries({
			country: selectedCountry,
			type: e.value,
			perPage: 20,
			page: 1
		});
		this.setState({
			selectedType: e.value,
			matchesPerPage: 20,
			matchesPage: 1
		});
	}

	handleCodeMatchModal() {
		const { codeMatchModalOpen } = this.state;
		this.setState({ codeMatchModalOpen: !codeMatchModalOpen });
	}

	matchTablePagination(pageNum) {
		const { selectedCountry, selectedType, matchesPerPage } = this.state;
		this.setState({
			matchesPage: pageNum,
			loading: true
		});
		this.loadRouteCountries({
			country: selectedCountry,
			type: selectedType,
			perPage: matchesPerPage,
			page: pageNum + 1
		});
	}

	matchTablePageSize(pageSize, pageIndex) {
		const { selectedCountry, selectedType } = this.state;
		this.setState({
			matchesPerPage: pageSize,
			matchesPage: pageIndex,
			loading: true
		});
		this.loadRouteCountries({
			country: selectedCountry,
			type: selectedType,
			perPage: pageSize,
			page: pageIndex + 1
		});
	}

	loadRouteCountries(search) {
		routesApiService.getRouteCodes(search).then(response => {
			if (this._isMounted) {
				this.setState({
					...response,
					loading: false
				});
			}
		});
	}

	render() {
		const {
			handleSubmit,
			routesData: { smartContracts, hardwareProfiles, onlyExternalRoutes },
			invalid,
			submitSucceeded,
			pristine
		} = this.props;
		const { codeMatchModalOpen, routeType, matchType, destinations, matchesPerPage, loading, countries, types, matches, matchCount } = this.state;

		const tableColumns = [
			{
				Header: 'Code',
				accessor: 'code'
			},
			{
				Header: 'Country',
				accessor: 'country'
			},
			{
				Header: 'Type',
				accessor: 'type'
			},
			{
				Header: 'Carrier',
				accessor: 'carrier'
			}
		];
		return (
			<div>
				<Dialog maxWidth="md" fullWidth open={codeMatchModalOpen} onBackdropClick={this.handleCodeMatchModal} TransitionComponent={Transition}>
					<DialogTitle>Code Matches</DialogTitle>
					<DialogContent>
						<RenderTable
							data={matches}
							columns={tableColumns}
							loading={loading}
							manual
							showPagination
							tableTotal={matchCount}
							pages={Math.ceil(matchCount / matchesPerPage)}
							onPageChange={this.matchTablePagination}
							defaultPageSize={matchesPerPage}
							onPageSizeChange={this.matchTablePageSize}
							// onFetchData={() => {
							//   this.setState({ loading: false });
							// }}
							sortable={false}
							filterable={false}
						/>
					</DialogContent>
					<DialogActions>
						<RenderButton color="danger" onClick={this.handleCodeMatchModal}>
							Close
						</RenderButton>
					</DialogActions>
				</Dialog>
				<form onSubmit={handleSubmit}>
					<Grid container>
						<Grid item xs={2}>
							<FormControl component="fieldset">
								<FormLabel component="legend">Match Type</FormLabel>
								<Field name="matchType" component={RenderRadioGroup} inline onChange={this.handleMatchTypeChange} value={matchType}>
									<RenderRadio value="N" label="Name" />
									<RenderRadio value="D" label="Digit" />
								</Field>
							</FormControl>
						</Grid>
						<Grid item xs={10}>
							{matchType === 'N' ? (
								<Grid container>
									<Grid item xs={5}>
										<Field
											component={RenderSelect}
											callback={this.handleCountryChange}
											name="countryMatch"
											label="Country"
											options={
												countries
													? countries.map(option => ({
															value: option,
															label: option
													  }))
													: []
											}
										/>
									</Grid>
									<Grid item xs={5}>
										<Field
											component={RenderSelect}
											name="countryMatchType"
											callback={this.handleCountryTypeChange}
											label="Type"
											options={types.map(option => ({
												value: option,
												label: option !== '*' ? option : 'All'
											}))}
										/>
									</Grid>
									<Grid item xs={2}>
										<RenderButton onClick={this.handleCodeMatchModal}>view codes ({matchCount})</RenderButton>
									</Grid>
								</Grid>
							) : (
								<Field component={RenderInput} name="digitMatch" label="Digit Match" />
							)}
						</Grid>
						<Grid item xs={12}>
							<Field component={RenderInput} name="description" label="Description" />
						</Grid>
						<Grid item xs={7}>
							<FormControl component="fieldset">
								<FormLabel component="legend">Route type</FormLabel>
								<Field name="routeType" component={RenderRadioGroup} inline onChange={this.handleRouteTypeChange} value={routeType}>
									<RenderRadio value="S" label="Static" />
									<RenderRadio value="P" label="Percentage" />
									<RenderRadio value="R" label="LCR" />
								</Field>
							</FormControl>
						</Grid>

						<Grid item xs={12}>
							<FieldArray
								name="destinations"
								destinationOptions={{
									smartContracts,
									hardwareProfiles,
									onlyExternalRoutes
								}}
								component={RoutesDestinationRow}
								routeType={routeType}
								destinations={destinations}
							/>
						</Grid>
					</Grid>
					<div style={{ textAlign: 'right' }}>
						<RenderButton color="primary" type="Submit" disabled={invalid}>
							Save Route
						</RenderButton>
					</div>
				</form>
			</div>
		);
	}
}

RoutesForm.defaultProps = {
	countries: [],
	types: [],
	routesData: {
		codes: {
			countries: [],
			types: []
		},
		smartContracts: [],
		hardwareProfiles: []
	}
};

const validate = createValidator({
	matchType: [required],
	digitMatch: [validateDepentValue('matchType', 'D', required)],
	countryMatch: [validateDepentValue('matchType', 'N', required)],
	routeType: [required],
	destinations: [validateRouteDestinations]
});
//
// const RoutesFormRedux = reduxForm({
//   form: "RoutesForm",
//   destroyOnUnmount: true,
//   enableReinitialize: true,
//   validate,
//   touchOnBlur: false
// })(RoutesForm);
export default compose(
	connect((state, props) => ({
		destinations: (getFormValues(props.form)(state) || {}).destinations
	})),
	reduxForm({
		form: 'RoutesForm',
		destroyOnUnmount: true,
		enableReinitialize: true,
		validate,
		touchOnBlur: false
	})
)(RoutesForm);
