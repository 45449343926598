import React from "react";
import { Grid, MenuItem } from "@material-ui/core";
import { Field } from "redux-form";
import { RenderSelect } from "components";

class RoutesDestinationSwitch extends React.Component {
  constructor(props) {
    super(props);
    const { fieldProps, onlyExternalRoutes } = this.props;
    this.state = {
      type: fieldProps.type
        ? fieldProps.type
        : onlyExternalRoutes
        ? "external"
        : "smartContract"
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.fieldProps) {
      const { type } = nextProps.fieldProps;
      this.setState({ type });
    }
  }

  handleChange(ev) {
    this.setState({ type: ev.value });
  }

  render() {
    const { hardwareProfiles, smartContracts, name } = this.props;
    const { type } = this.state;
    return (
      <Grid container>
        <Grid item xs={4}>
          <Field
            name={`${name}.type`}
            label="Route Type"
            callback={this.handleChange}
            component={RenderSelect}
            options={[
              {
                value: "smartContract",
                label: "Smart Contract"
              },
              {
                value: "external",
                label: "External"
              }
            ]}
          />
        </Grid>
        {type === "smartContract" ? (
          <Grid item xs={8}>
            <Field
              name={`${name}.id`}
              label="Smart Contract"
              component={RenderSelect}
              options={smartContracts.map(option => {
                const padded = `${option._id}000000`.slice(
                  0,
                  -option._id.length
                );
                const smartContractRef = parseInt(padded)
                  .toString(16)
                  .toUpperCase();
                return {
                  value: option._id,
                  label: `${
                    option.supplier.participantName
                  } - SmartContract ${smartContractRef} - ${
                    option.isOriginContract ? "Origin" : "Standard"
                  } - ${option.trafficType}`
                };
              })}
              noOptionsMessage={() => (
                <MenuItem value="">There are no buying contracts</MenuItem>
              )}
            />
          </Grid>
        ) : (
          <Grid item xs={8}>
            <Field
              component={RenderSelect}
              name={`${name}.id`}
              label="Connection Profile"
              options={hardwareProfiles.map(option => ({
                value: option._id,
                label: option.name
              }))}
              noOptionsMessage={() => (
                <MenuItem value="">There are no connection profiles</MenuItem>
              )}
            />
          </Grid>
        )}
      </Grid>
    );
  }
}

export default RoutesDestinationSwitch;
