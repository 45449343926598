import React from "react";

import {
  AppBar,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Slide,
  Tab,
  Tabs,
  Typography
} from "@material-ui/core";

import moment from "moment";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  RenderButton,
  RenderTable,
  RenderBadge,
  FundingOfferActiveForm
} from "components";
import styles from "./style";

const Transition = props => <Slide direction="up" {...props} />;
class FundingOfferDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "offerDetails"
    };
  }

  handleTabChange = (ev, activeTab) => {
    this.setState({ activeTab });
  };

  contractIdFormatter = contractId => {
    const padded = `${contractId}000000`.slice(0, -contractId.length);
    return parseInt(padded)
      .toString(16)
      .toUpperCase();
  };

  transactionAmount(amount, type) {
    if (type === "advance") {
      return <RenderBadge color="danger">(${amount.toFixed(2)})</RenderBadge>;
    }
    return <RenderBadge color="success">${amount.toFixed(2)}</RenderBadge>;
  }

  render() {
    const {
      classes,
      dialogOpen,
      dialogOpenCloseHandler,
      handleActiveFundingOfferSubmit,
      data,
      data: {
        contractTerms: {
          contractId = null,
          supplier = null,
          customer = null,
          billingCycle = null,
          paymentTerms = null,
          settlementPeriod = null,
          billingTimezone = null,
          contractEndDate = null
        } = null,
        fundingRequestedAmount = 0,
        dailyFundingAmount = 0,
        percent = 100,
        interestRate = null,
        transactions = []
      }
    } = this.props;
    const { activeTab } = this.state;

    let creditUsed = 0;
    transactions.map(obj => {
      if (obj.type === "return") {
        creditUsed -= parseFloat(obj.amount);
      }
      if (obj.type === "advance") {
        creditUsed += parseFloat(obj.amount);
      }
    });

    const creditRemaining =
      (fundingRequestedAmount * percent) / 100 - creditUsed;

    const transactionsHeader = [
      {
        Header: "Date",
        accessor: "createdAt",
        Cell: props => moment(props.value).format("MMM DD YYYY")
      },
      {
        Header: "Type",
        accessor: "type",
        Cell: props => {
          if (props.value === "advance") return "Advance Payment";
          return "Return of advance";
        }
      },
      {
        Header: "Margin",
        accessor: "margin",
        Cell: props => `${props.value ? `${props.value}%` : "-"}`
      },
      {
        Header: "Amount",
        accessor: "amount",
        headerStyle: {
          textAlign: "right"
        },
        style: {
          textAlign: "right"
        },
        Cell: props => this.transactionAmount(props.value, props.original.type)
      }
    ];

    return (
      <Dialog
        fullWidth
        open={dialogOpen}
        onBackdropClick={dialogOpenCloseHandler}
        TransitionComponent={Transition}
        maxWidth="md"
      >
        <DialogContent
          style={{
            padding: 0
          }}
        >
          <AppBar position="static">
            <Tabs value={activeTab} onChange={this.handleTabChange}>
              <Tab label="Funding Offer Details" value="offerDetails" />
              <Tab label="Smart Contract Details" value="contractDetails" />
            </Tabs>
          </AppBar>
          {activeTab === "offerDetails" && (
            <CardContent>
              <Grid container>
                <Grid item xs={2}>
                  <Typography className={classes.label}>
                    Requested amount
                  </Typography>
                  <Typography>${fundingRequestedAmount}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={classes.label}>
                    Daily Amount
                  </Typography>
                  <Typography>
                    ${dailyFundingAmount || fundingRequestedAmount}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={classes.label}>
                    Financing Fee (%/invoice)
                  </Typography>
                  <Typography>{interestRate}%</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={classes.label}>
                    Coverage Percent
                  </Typography>
                  <Typography>{percent || 100}%</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={classes.label}>Credit Used</Typography>
                  <Typography>${creditUsed.toFixed(2)}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className={classes.label}>
                    Credit Remaining
                  </Typography>
                  <Typography>${creditRemaining.toFixed(2)}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h2" style={{ marginTop: 30 }}>
                    Transactions
                  </Typography>
                  {transactions && transactions.length ? (
                    <RenderTable
                      data={transactions}
                      filterable={false}
                      columns={transactionsHeader}
                      // defaultSorted={[
                      //   {
                      //     id: "startDate",
                      //     desc: true
                      //   }
                      // ]}
                    />
                  ) : (
                    <Typography variant="alignCenter">
                      There are no transactions recorded for this Funding Offer
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FundingOfferActiveForm
                    onSubmit={handleActiveFundingOfferSubmit}
                    initialValues={data}
                  />
                </Grid>
              </Grid>
            </CardContent>
          )}
          {activeTab === "contractDetails" && (
            <CardContent>
              <Grid container>
                <Grid item xs={3}>
                  <Typography className={classes.label}>
                    Smart Contract ID
                  </Typography>
                  <Typography>
                    {this.contractIdFormatter(contractId)}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.label}>Customer</Typography>
                  <Typography>{customer}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.label}>Supplier</Typography>
                  <Typography>{supplier}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.label}>
                    Contract end date
                  </Typography>
                  <Typography>
                    {contractEndDate === undefined ||
                    contractEndDate === 0 ||
                    contractEndDate === null
                      ? "Open"
                      : moment(contractEndDate).format("DD MMM YYYY")}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.label}>
                    Billing Cycle
                  </Typography>
                  <Typography>{billingCycle}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.label}>
                    Payment Terms
                  </Typography>
                  <Typography>
                    {parseInt(paymentTerms) > 1
                      ? `${paymentTerms} Days`
                      : `${paymentTerms} Day`}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.label}>
                    Settlement Period
                  </Typography>
                  <Typography>{settlementPeriod}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.label}>
                    Billing Timezone
                  </Typography>
                  <Typography>{billingTimezone}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          )}
        </DialogContent>
        <DialogActions>
          <RenderButton color="primary" onClick={dialogOpenCloseHandler}>
            Close
          </RenderButton>
        </DialogActions>
      </Dialog>
    );
  }
}

FundingOfferDetailsModal = withStyles(styles)(FundingOfferDetailsModal);

export default FundingOfferDetailsModal;
