import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';

import { CurrencyNumber } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import invoiceSummaryStyle from './style';
import { useTranslation } from 'react-i18next';

const CutomTableHeadCell = withStyles(theme => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white
	}
}))(TableCell);

const CustomTableRow = withStyles({
	root: {
		height: 'auto'
	}
})(TableRow);

const CustomRow = ({ firstCellContent, secondCellContent, thirdCellContent, forthCellContent }) => (
	<CustomTableRow>
		<TableCell>{firstCellContent}</TableCell>
		<TableCell numeric>{secondCellContent}</TableCell>
		<TableCell numeric>{thirdCellContent}</TableCell>
		<TableCell numeric>{forthCellContent}</TableCell>
	</CustomTableRow>
);

CustomRow.defaultProps = {
	firstCellContent: '',
	secondCellContent: '',
	thirdCellContent: '',
	forthCellContent: ''
};

CustomRow.propTypes = {
	firstCellContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	secondCellContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	thirdCellContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	forthCellContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

const AddThousandSeparator = amount => (amount !== undefined ? Number(amount).toLocaleString() : 0);
//const AddThousandSeparator = (amount) => amount !== undefined ? Number(amount).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : 0;

function formatToEUR(number) {
	if (number !== undefined && number !== null && !isNaN(number) && number !== 0) {
		return new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'EUR',
			minimumFractionDigits: 0,
			maximumFractionDigits: 2
		}).format(number);
	}
	return '';
}

const InvoiceSummary = props => {
	const { data, classes, isMaster } = props;
	const { t } = useTranslation('financials/general');

	return (
		<div>
			<Typography variant="headline" align="center" className={classes.headline}>
				{isMaster ? t('invoices.master_invoice_summary') : t('invoices.daily_invoice_summary')}
			</Typography>

			<Table className={classes.table}>
				<TableHead>
					<CustomTableRow>
						<CutomTableHeadCell>{t('invoices.description')}</CutomTableHeadCell>
						<CutomTableHeadCell numeric>{t('invoices.calls')}</CutomTableHeadCell>
						<CutomTableHeadCell numeric>{t('invoices.minutes')}</CutomTableHeadCell>
						<CutomTableHeadCell numeric>{t('invoices.amount')}</CutomTableHeadCell>
					</CustomTableRow>
				</TableHead>
				<TableBody>
					<CustomRow
						firstCellContent={<Typography variant="body2">{t('invoices.total_usage')}</Typography>}
						secondCellContent={AddThousandSeparator(data.TOT_CALLS)}
						thirdCellContent={AddThousandSeparator(data.TOT_MIN)}
						forthCellContent={<CurrencyNumber amount={`${Number(`${Math.round(`${data.TOT_AMOUNT}e2`)}e-2`)}`} />}
					/>
					<CustomRow firstCellContent={t('invoices.tax')} forthCellContent={<CurrencyNumber amount={`${Number(`${Math.round(`${data.TOT_PERCT}e2`)}e-2`)}`} />} />
					<CustomRow firstCellContent={t('invoices.payments')} forthCellContent={<CurrencyNumber amount="0" />} />
					<CustomRow firstCellContent={t('invoices.adjustments')} forthCellContent={<CurrencyNumber amount={0} />} />
					<CustomRow firstCellContent={<Typography variant="body2">{t('invoices.tot_bal_forward')}</Typography>} forthCellContent={<CurrencyNumber amount={0} />} />
					<CustomRow
						firstCellContent={t('invoices.late_payment_fee')}
						forthCellContent={<CurrencyNumber amount={`${Number(`${Math.round(`${data.FINANCE}e2`)}e-2`)}`} />}
					/>
					<CustomRow
						firstCellContent={<Typography variant="body2">{t('invoices.tot_due')}</Typography>}
						forthCellContent={<CurrencyNumber amount={`${Number(`${Math.round(`${data.AMOUNTDUE - data.TOTBALFWD}e2`)}e-2`)}`} />}
					/>
					{data.exchange && (
						<CustomRow
							thirdCellContent={<Typography variant="subtitle2">{`${data.exchange.conversion} rate ${data.exchange.rate}`}</Typography>}
							forthCellContent={
								<Typography variant="subtitle2">
									Amount&nbsp;
									{formatToEUR(Number((data.AMOUNTDUE - data.TOTBALFWD) * data.exchange.rate))}
								</Typography>
							}
						/>
					)}
				</TableBody>
			</Table>
		</div>
	);
};

InvoiceSummary.defaultProps = {
	isMaster: false
};

InvoiceSummary.propTypes = {
	classes: PropTypes.shape().isRequired,
	data: PropTypes.shape().isRequired,
	isMaster: PropTypes.bool
};

export default withStyles(invoiceSummaryStyle)(InvoiceSummary);
