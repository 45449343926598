import React from "react";
import PropTypes from "prop-types";
import { FormattedNumber, IntlProvider } from "react-intl";

const Balance = props => (
  <div className="row">
    <div className="col-lg-12 col-md-12">
      <div className="card">
        <div className="card-body">
          <div className="d-flex">
            <div>
              <h3 className="card-title m-b-5">
                <span className="lstick" />
                {props.title}{" "}
              </h3>
            </div>
          </div>
        </div>
        <div className="bg-theme stats-bar">
          <div className="row">
            <div className="col-lg-3 col-md-3">
              <div className="p-20 active">
                <h6 className="text-white">{props.currency}</h6>
                <h3 className="text-white m-b-0">
                  <IntlProvider locale="en">
                    <FormattedNumber
                      value={props.availableBalance}
                      currency={props.currency}
                    />
                  </IntlProvider>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

Balance.propTypes = {
  title: PropTypes.string,
  availableBalance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.string
};

Balance.defaultProps = {
  title: "",
  availableBalance: 0,
  currency: ""
};

export default Balance;
