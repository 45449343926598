import React, { memo, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { RenderFormikSelect, RenderFormikDateTimePicker, RenderFormikCheckbox } from 'components';
import { ButtonGroup, CardContent, Grid, Paper, Button, Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import FileDownload from '@material-ui/icons/FileDownload';
import { useTranslation } from 'react-i18next';

import moment from 'moment-timezone';
import { Field } from 'redux-form';
import styles from './styles.module.scss';

export default memo(({ reportType = 'customer', onChange = () => {}, onRunReport = () => {}, onDownload = false, values = {} }) => {
	const dispatch = useDispatch();

	const loading = useSelector(state => state.reports && state.reports.loadingFilters);
	const formFilterData = useSelector(state => (state.reports && state.reports.reportsFilters) || {});

	const { t } = useTranslation('reports/reports');

	let validationSchema;

	if (reportType === 'cdr-report') {
		validationSchema = Yup.object().shape({
			smartContract: Yup.string().required(t('req_please_select_help', { label: t('smart_contract') }))
		});
	}

	const formik = useFormik({
		initialValues: {
			customer: '',
			supplier: '',
			smartContract: '',
			startDate: moment()
				.utc()
				.hour(0)
				.minute(0)
				.seconds(0)
				.millisecond(0),
			endDate: moment()
				.utc()
				.hour(23)
				.minute(0)
				.seconds(0)
				.millisecond(0),
			country: undefined,
			destinationType: undefined,
			destinationCarrier: undefined,
			...values
		},
		onSubmit: (values, { resetForm }) => {
			onRunReport(values);
		},
		validationSchema
	});

	useEffect(() => {
		dispatch({ type: 'reports/GET_FILTER_DATA' });
		return () => {
			formik.resetForm();
		};
	}, [reportType]);

	useEffect(() => {
		dispatch({
			type: 'reports/GET_FILTER_DATA',
			payload: formik.values
		});
	}, [formik.values.country, formik.values.destinationType, formik.values.destinationCarrier]);

	// trigger onchange event (if defined)
	useEffect(() => {
		if (onChange) {
			onChange(formik.values);
		}
	}, [formik.values]);

	// reset destination type and destination carrier when country changes
	useEffect(() => {
		formik.setFieldValue('destinationType', null);
		formik.setFieldValue('destinationCarrier', null);
	}, [formik.values.country]);

	// reset destination carrier when destination type changes
	useEffect(() => {
		formik.setFieldValue('destinationCarrier', null);
	}, [formik.values.destinationType]);

	return (
		<div className={styles.container}>
			<form onSubmit={formik.handleSubmit}>
				<Paper>
					<CardContent>
						<Grid container spacing={1} justifyContent="space-around">
							{((reportType === 'customer' || reportType === 'sale-and-cost') && (
								<Grid item xs={12} sm={12} md={3}>
									<RenderFormikSelect
										label={t('customer')}
										name="customer"
										disabled={loading}
										formik={formik}
										options={(formFilterData && formFilterData.customers) || []}
									/>
								</Grid>
							)) ||
								null}
							{((reportType === 'supplier' || reportType === 'sale-and-cost') && (
								<Grid item xs={12} sm={12} md={3}>
									<RenderFormikSelect
										label={t('supplier')}
										name="supplier"
										disabled={loading}
										formik={formik}
										options={(formFilterData && formFilterData.suppliers) || []}
									/>
								</Grid>
							)) ||
								null}

							{(reportType !== 'sale-and-cost' && reportType !== 'cdr-report' && (
								<Grid item xs={12} md={2}>
									<RenderFormikSelect
										label={t('smart_contract')}
										name="smartContract"
										disabled={loading}
										formik={formik}
										options={(formFilterData && formFilterData.contracts) || []}
									/>
								</Grid>
							)) ||
								null}
							{(reportType === 'cdr-report' && (
								<React.Fragment>
									<Grid item xs={12} md={3}>
										<RenderFormikSelect
											label={t('buying_smart_contracts')}
											name="smartContract"
											disabled={loading}
											formik={formik}
											options={(formFilterData && formFilterData.filteredContracts && formFilterData.filteredContracts.buying) || []}
										/>
									</Grid>
									<Grid item xs={12} md={3}>
										<RenderFormikSelect
											label={t('selling_smart_contracts')}
											name="smartContract"
											disabled={loading}
											formik={formik}
											options={(formFilterData && formFilterData.filteredContracts && formFilterData.filteredContracts.selling) || []}
										/>
									</Grid>
								</React.Fragment>
							)) ||
								null}

							<Grid item xs={12} sm={12} md>
								<Grid container spacing={1}>
									<Grid item xs={6} md={6}>
										<RenderFormikDateTimePicker
											formik={formik}
											disabled={loading}
											name="startDate"
											label={t('start_date')}
											dateFormat="MMM DD YYYY - HH"
											disableFuture
											clearable
											views={'date' | 'year' | 'month' | 'hours'}
										/>
									</Grid>
									<Grid item xs={6} md={6}>
										<RenderFormikDateTimePicker
											formik={formik}
											disabled={loading}
											name="endDate"
											label={t('end_date')}
											dateFormat="MMM DD YYYY - HH"
											clearable
											disableFuture
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Grid container spacing={1}>
							{(reportType === 'cdr-report' && (
								<React.Fragment>
									<Grid item xs={12} md={10} className="cdr-report-checkbox-wrap">
										<RenderFormikCheckbox formik={formik} name="includeUnansweredCalls" label={t('include_unanswered_calls')} labelPlacement="start" />
									</Grid>
								</React.Fragment>
							)) || (
								<React.Fragment>
									<Grid item xs={12} md={4}>
										<RenderFormikSelect
											label={t('country')}
											name="country"
											disabled={loading}
											formik={formik}
											options={(formFilterData && formFilterData.countries) || []}
										/>
									</Grid>
									<Grid item xs={6} md={3}>
										<RenderFormikSelect
											label={t('destination_type')}
											name="destinationType"
											formik={formik}
											disabled={loading || !formik.values.country}
											options={((formFilterData && formFilterData.destTypes) || []).map(el => ({
												value: el,
												title: el
											}))}
										/>
									</Grid>
									<Grid item xs={12} md={3}>
										<RenderFormikSelect
											label={t('destination_carrier')}
											name="destinationCarrier"
											formik={formik}
											disabled={loading || !formik.values.country || !formik.values.destinationType}
											options={((formFilterData && formFilterData.destCarriers) || []).map(el => ({
												value: el,
												title: el
											}))}
										/>
									</Grid>
								</React.Fragment>
							)}

							<Grid item xs={12} md={2}>
								<ButtonGroup className="report-actions">
									{(!!onDownload && (
										<Tooltip title={t('export_excel_to_xls_file')}>
											<Button onClick={() => onDownload(formik.values)}>
												<FileDownload />
											</Button>
										</Tooltip>
									)) ||
										null}

									<Button onClick={formik.handleSubmit}>{t('run_report')}</Button>
								</ButtonGroup>
							</Grid>
						</Grid>
					</CardContent>
				</Paper>
			</form>
		</div>
	);
});
