const templateStyle = theme => ({
	'@global': {
		body: {
			fontFamily: theme.typography.fontFamily
		},
		'html, body, #app, #app > div': {
			height: '100%'
		},
		footer: {
			textAlign: 'center',
			backgroundColor: theme.palette.light,
			padding: theme.spacing(1)
		},
		':focus': {
			outline: 'none'
		},
		strong: {
			fontWeight: theme.typography.fontWeightBold
		},
		'.inactive-tab': {
			backgroundColor: theme.palette.lightGrey.main,
			opacity: 1,
			color: theme.palette.dark
		}
	},
	content: {
		marginLeft: '64px',
		transition: 'margin 225ms ease',
		paddingTop: theme.spacing(1) * 6,
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	menuOpen: {
		marginLeft: '200px'
	},
	pageContent: {
		flexGrow: '1',
		padding: theme.spacing(1)
	},
	kycBanner: {
		backgroundColor: theme.palette.warning.light,
		width: '100%',
		borderRadius: 6,
		padding: theme.spacing(2),
		display: 'flex',
		alignItems: 'center',
	},
	highlightedText:{
		fontWeight: 600,
		paddingLeft: theme.spacing(1)
	},
	children: {
		marginTop: 20
	}
});

export default templateStyle;
