import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
// import MomentUtils from "material-ui-pickers/utils/moment-utils";
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import { RenderFileUpload } from 'components';
import { withTranslation } from 'react-i18next';
import rateAddendumPickerStyle from './style';

// eslint-disable-next-line react/prefer-stateless-function
class RateAddendumPicker extends Component {
	constructor(props) {
		super(props);
		const { minimumNoticeDays } = props;
		this.state = {
			selectedDate: moment()
				.add(minimumNoticeDays, 'days')
				.toDate()
		};
	}

	render() {
		const { selectedDate } = this.state;
		const { classes, minimumNoticeDays, allowedFileTypes, handleRateUpload, tempId, t } = this.props;
		return (
			<React.Fragment>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<DatePicker
						className={classes.fullWidth}
						leftArrowIcon={<KeyboardArrowLeft color="primary" />}
						rightArrowIcon={<KeyboardArrowRight color="primary" />}
						label={t('rates.select_rate_addendum_date')}
						format="MMM  DD YYYY"
						minDate={moment()
							.add(minimumNoticeDays, 'days')
							.toDate()}
						value={selectedDate}
						onChange={date => {
							this.setState({
								selectedDate: date
							});
						}}
						disablePast
						autoOk
					/>
				</MuiPickersUtilsProvider>
				<Field
					type="file"
					component={RenderFileUpload}
					allowedFileTypes={allowedFileTypes}
					btnText={t('rates.upload_rates')}
					className="form-control"
					name="ratesFile"
					handleUploadFn={evt => {
						const fd = evt && evt.target && evt.target.files && evt.target.files[0];
						handleRateUpload(tempId, fd, moment(selectedDate).format('MM/DD/YYYY'));
					}}
				/>
			</React.Fragment>
		);
	}
}

RateAddendumPicker.propTypes = {
	allowedFileTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
	handleRateUpload: PropTypes.func.isRequired,
	tempId: PropTypes.string.isRequired,
	classes: PropTypes.shape().isRequired,
	minimumNoticeDays: PropTypes.number.isRequired
};

const RateAddendumPickerTranslated = withTranslation(['smartcontracts/edit', 'translations'])(RateAddendumPicker);

export default withStyles(rateAddendumPickerStyle)(RateAddendumPickerTranslated);
