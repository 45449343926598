import React from 'react';
import { connect } from 'react-redux';
import { fromSmartcontracts } from 'store/selectors';
import { loadCatalogsSmartContract } from 'store/actions';
import { Grid, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

class SmartContractsTechnicalTermsReadOnly extends React.Component {
	componentWillMount() {
		this.props.loadCatalogs();
	}

	render() {
		const { smartContract, t } = this.props;

		return (
			<div>
				<Typography variant="h2" gutterBottom>
					{t('technical_terms')}
				</Typography>
				<Grid container>
					<Grid item xs={12} md={6}>
						<Grid container>
							<Grid item xs={4}>
								<strong>{t('supplier')}:</strong>
							</Grid>
							<Grid item xs={8}>
								{smartContract.supplierName}
							</Grid>
							<Grid item xs={4}>
								<strong>{t('traffic_type')}:</strong>
							</Grid>
							<Grid item xs={8}>
								{smartContract.contract.trafficType}
							</Grid>
							<Grid item xs={4}>
								<strong>{t('contract_type')}:</strong>
							</Grid>
							<Grid item xs={8}>
								{smartContract.contract.isOriginContract ? t('contract_type.origination') : t('contract_type.standard')}
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} md={6}>
						<Grid container>
							<Grid item xs={4}>
								<strong>{t('customer')}:</strong>
							</Grid>
							<Grid item xs={8}>
								{smartContract.customerName}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		);
	}
}

SmartContractsTechnicalTermsReadOnly.defaultProps = {
	voipProtocols: [{}]
};

const mapStateToProps = state => ({
	voipProtocols: fromSmartcontracts.getVoipProtocols(state)
});

const mapDispatchToProps = dispatch => ({
	loadCatalogs() {
		dispatch(loadCatalogsSmartContract());
	}
});

const SmartContractsTechnicalTermsReadOnlyTranslated = withTranslation(['smartcontracts/edit', 'translations'])(SmartContractsTechnicalTermsReadOnly);

export default connect(mapStateToProps, mapDispatchToProps)(SmartContractsTechnicalTermsReadOnlyTranslated);
