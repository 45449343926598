import React, { memo, useState } from 'react';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import { SmartContractsTab, ProjectsTab } from './components';

export default memo(() => {
	const [selectedTab, setSelectedTab] = useState('smart-contracts');

	return (
		<div>
			<AppBar position="static">
				<Tabs value={selectedTab} onChange={(_, value) => setSelectedTab(value)}>
					<Tab value="smart-contracts" label="smart Contracts" />
					<Tab value="projects" label="projects" />
				</Tabs>
			</AppBar>
			<div>
				{(selectedTab === 'smart-contracts' && <SmartContractsTab />) || ''}
				{(selectedTab === 'projects' && <ProjectsTab />) || ''}
			</div>
		</div>
	);
});
