import React, { memo } from 'react';
import moment from 'moment';
import { ReportFilters, ReportTraffic, ReportSaleAndCost, MuiPermissionTabs, ReportLender, ReportCDRDownload, AuthManager } from 'components';
import { AppBar, Tabs, Tab, Paper, CardContent, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default memo(props => {
	const { activeTab, reportData, reportCDRData, handleTabChange, generateReportHandler, setDownload, isLoading } = props;
	const { t } = useTranslation('reports/general');

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<AppBar position="static">
					<Tabs value={activeTab} onChange={handleTabChange}>
						<MuiPermissionTabs label={t('tabs.lender_report')} value="lenderReport" permissions={['reports.view-lender']} userRole="funder"/>
						<MuiPermissionTabs label={t('tabs.customer_traffic_report')} value="customerTraffic" permissions={['reports.view-customer']} userRole="carrier"/>
						<MuiPermissionTabs label={t('tabs.supplier_traffic_report')} value="supplierTraffic" permissions={['reports.view-supplier']} userRole="carrier"/>
						<MuiPermissionTabs label={t('tabs.sale_and_cost_report')} value="saleAndCost" permissions={['reports.view-sale-cost']} userRole="carrier"/>
						<MuiPermissionTabs label={t('tabs.cdr_download_report')} value="callSearch" permissions={['reports.view-cdr']} userRole="carrier"/>
					</Tabs>
				</AppBar>
			</Grid>

			<AuthManager permissions={['reports']}>
				<Grid item xs={12}>
					<Paper>
						<CardContent>
							<ReportFilters
								setDownload={setDownload}
								reportType={activeTab}
								isLoading={isLoading}
								onSubmit={generateReportHandler}
								initialValues={{
									reportType: activeTab,
									startDate: moment()
										.utc()
										.startOf('day')
										.format('YYYY-MM-DD HH:mm'),
									endDate: moment()
										.utc()
										.endOf('day')
										.format('YYYY-MM-DD HH:mm'),
									startHour: '00',
									endHour: '23'
								}}
							/>
						</CardContent>
					</Paper>
				</Grid>

				<Grid item xs={12}>
					<Paper>
						<CardContent>
							<div>
								{activeTab === 'saleAndCost' && <ReportSaleAndCost tableData={reportData} isLoading={isLoading} />}
								{/* {activeTab === "margin" && ( */}
								{/* <ReportMargin tableData={reportData} /> */}
								{/* )} */}
								{activeTab === 'customerTraffic' && <ReportTraffic tableData={reportData} isLoading={isLoading} />}
								{activeTab === 'supplierTraffic' && <ReportTraffic tableData={reportData} isLoading={isLoading} />}
								{activeTab === 'lenderReport' && <ReportLender tableData={reportData} isLoading={isLoading} />}
								{activeTab === 'callSearch' && <ReportCDRDownload tableData={reportCDRData} isLoading={isLoading} />}
							</div>
						</CardContent>
					</Paper>
				</Grid>
			</AuthManager>
		</Grid>
	);
});
