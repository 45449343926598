import React from "react";
import { PrivateTemplate } from "components";
import { DummyContainer } from "containers";

const PrivateExamplePage = () => (
  <PrivateTemplate>
    <div className="row">
      <div className="col-md-3">&nbsp;</div>
      <div className="col-md-6">
        <DummyContainer />
      </div>
      <div className="col-md-3">&nbsp;</div>
    </div>
  </PrivateTemplate>
);

export default PrivateExamplePage;
