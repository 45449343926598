import React from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Button,
  CardTitle,
  CardText,
  Row,
  Col
} from "reactstrap";
import { ExplorerContent, ExplorerWalletItem } from "components";
import classnames from "classnames";

export default class ExplorerTabs extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1"
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    const { smartContracts, wallet, getSmartContractsExplorer } = this.props;
    return (
      <div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "1" })}
              onClick={() => {
                this.toggle("1");
              }}
            >
              Wallet
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "2" })}
              onClick={() => {
                this.toggle("2");
              }}
            >
              Smart Contracts
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                {wallet !== undefined && Object.keys(wallet).length > 0 ? (
                  Object.values(wallet).map((item, index) => (
                    <ExplorerWalletItem key={index} data={item} />
                  ))
                ) : (
                  <Card>
                    <CardBody>No Transactions</CardBody>
                  </Card>
                )}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <ExplorerContent
                  data={smartContracts}
                  type="smartContracts"
                  getSmartContractsExplorer={getSmartContractsExplorer}
                />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
