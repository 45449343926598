import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { persistStore } from 'redux-persist';
import { asyncLocalStorage } from 'redux-persist/storages';
import configureStore from 'store/configure';
import App from 'components/App';
import 'whatwg-fetch';
import 'assets/tesspay/styles.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './lang';

const history = require('history').createBrowserHistory();

const store = configureStore({}, history);

persistStore(store, { storage: asyncLocalStorage }, () => {
	const root = document.getElementById('app');

	render(<AppMain />, root);
});

class AppMain extends React.Component {
	componentDidMount() {
		const state = store.getState();

		if (state.useraccount.USER_INFO_DATA) {
			store.dispatch({ type: 'START_IDLE_MONITOR' });
		}
	}

	render() {
		return (
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<App state={store.getState()} />
				</ConnectedRouter>
			</Provider>
		);
	}
}

export { history };
