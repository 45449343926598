import React, { Component } from "react";
import PropTypes from "prop-types";
import { Paper, CardContent, Typography } from "@material-ui/core";
import { RenderTable } from "components";
import moment from "moment";

const roundNumber = (num, n) =>
  parseFloat(Math.round(num * Math.pow(10, n)) / Math.pow(10, n)).toFixed(n);

const rowFormatter = amount => {
  if (amount === 0) {
    return "";
  }
  if (amount !== undefined && String(amount).indexOf("-") > -1) {
    return (
      <span
        style={{
          color: "red",
          borderRadius: "2px",
          transition: "all .2s ease-out"
        }}
      >
        {amount.replace("-", "")}
      </span>
    );
  }
  return (
    <span
      style={{
        borderRadius: "2px",
        transition: "all .2s ease-out"
      }}
    >
      {amount}
    </span>
  );
};

class SettlementsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, loggedInParticipant } = this.props;

    const tableColumns = [
      {
        Header: "Posting Date",
        accessor: "settledAt",
        Cell: props => moment(props.value).format("MMM DD YYYY")
      },
      {
        Header: "Traffic Date",
        accessor: "trafficDate",
        Cell: props => moment(props.value).format("MMM DD YYYY")
      },
      {
        Header: "Contract ID",
        accessor: "contractId"
      },
      {
        Header: "Contract Type",
        accessor: "contractType"
      },
      {
        Header: "Partner",
        accessor: "partner"
      },
      {
        Header: "Minutes",
        accessor: "minutes",
        Cell: props => `${props.value}`
      },
      {
        Header: "Billing increment",
        accessor: "billingIncrement"
      },
      {
        Header: "Minutes with billing increment",
        accessor: "mWbillingIncrement",
        Cell: props => `${props.value}`
      },
      {
        Header: "Amount",
        accessor: "trafficAmount",
        Cell: row => rowFormatter(row.value)
      },
      {
        Header: "Interest",
        accessor: "interest",
        Cell: row => rowFormatter(row.value)
      },
      {
        Header: "TP fee",
        accessor: "tpFee",
        Cell: row => rowFormatter(row.value)
      },
      {
        Header: "Net",
        accessor: "net",
        Cell: row => rowFormatter(`${row.value}`) // temporary fix
        // Cell: props => `${props.value}`
      }
    ];

    const tableData = [];

    if (data.result && data.result.items)
      data.result.items.map(row => {
        let lenderFee = ""; // if = 0 display nothing in this field as per jeff's request @ 8.02.2019
        let tpFee = ""; // if = 0 display nothing in this field as per jeff's request @ 8.02.2019

        let partner = null;

        if (loggedInParticipant.id === row.buyer.id) {
          partner = row.seller.name;
        }

        if (loggedInParticipant.id === row.seller.id) {
          partner = row.buyer.name;
        }

        // convert amount to a number
        let amount = 0;
        let displayAmount = "";
        let contractType = "Sell";
        let netAmount = 0;

        if (row.fromContract[0] !== undefined) {
          if (loggedInParticipant.id === row.fromContract[0].customerId) {
            contractType = "Buy";
          }
        }

        if (loggedInParticipant.id === row.buyer.id) {
          displayAmount = `-($${parseFloat(row.amount.toFixed(2))})`;
          amount = parseFloat(row.amount.toFixed(2));
          netAmount = `-($${parseFloat((amount - tpFee).toFixed(2))})`;
        } else {
          amount = parseFloat(row.amount.toFixed(2));
          displayAmount = `$${roundNumber(row.amount, 2)}`;

          if (row.tesspayFee !== undefined && row.tesspayFee > 0) {
            // round tesspay fee up
            tpFee = `-($${roundNumber(row.tesspayFee, 2)})`;
          }

          if (
            row.lender &&
            row.lender.lenderFee !== undefined &&
            row.lender.lenderFee > 0
          ) {
            // round interest fee up
            lenderFee = `-($${roundNumber(row.lender.lenderFee, 2)})`;
          }

          netAmount = `($${parseFloat(
            (amount - row.tesspayFee - (row.lender && row.lender.lenderFee ? row.lender.lenderFee : 0)).toFixed(2)
          )})`;
        }

        const contractId = parseInt(
          `${row.contractId}000000`.slice(0, -row.contractId.length)
        )
          .toString(16)
          .toUpperCase();

        tableData.push({
          trafficDate: row.startDate, // display the date of the settlements and not the date when the settlement was posted
          settledAt: row.settledAt,
          contractId,
          contractType,
          buyer: row.buyer.name,
          partner,
          minutes: row.summary.raw_duration ? row.summary.raw_duration : 0,
          billingIncrement: "1/1",
          mWbillingIncrement: row.summary.rnd_dur_cust
            ? row.summary.rnd_dur_cust
            : 0,
          trafficAmount: displayAmount,
          interest: lenderFee,
          tpFee,
          net: netAmount
        });
        return tableData;
      });

    return (
      tableData && (
        <Paper>
          <CardContent>
            <Typography variant="h1" gutterBottom>
              Daily Settlements
            </Typography>
            <RenderTable data={tableData} columns={tableColumns} />
          </CardContent>
        </Paper>
      )
    );
  }
}

export default SettlementsTable;
