import React, { Component }  from "react";
import { SupportTessPay } from "components";

class SupportTessPayContainer extends Component{
    render() {
        return (
            <div>
                <SupportTessPay />
            </div>
        );
    }
    }

export default SupportTessPayContainer;
