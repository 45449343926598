import React, { useState, useEffect, useCallback } from 'react';

import { Typography, Box, CardContent, CircularProgress, Collapse, Zoom } from '@material-ui/core';
import RenderButton from 'components/atoms/RenderButton';
import TicketCard from './components/TicketCard';
import NewTicketCard from './components/NewTicketCard';
import useSectionInfo from './hooks/useSectionInfo';

export const TicketsSection = ({ selectedTicket, handleSelectTicket }) => {
	const { TICKETS, handleSubmitTicket, loadNewTickets, toggleNewTicketCard, showNewTicketCard } = useSectionInfo();

	return (
		<>
			<Box style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Typography variant="h6"> My tickets</Typography>
				<RenderButton color="primary" onClick={toggleNewTicketCard}>
					{showNewTicketCard ? 'Cancel' : 'New ticket'}
				</RenderButton>
			</Box>

			<Box sx={{ maxHeight: 1000, overflow: 'scroll' }}>
				<Collapse in={TICKETS.loading}>
					<Box style={{ marginTop: 8, marginButton: 8 }}>
						<CardContent style={{ display: 'flex', justifyContent: 'center' }}>
							<CircularProgress />
						</CardContent>
					</Box>
				</Collapse>
				<NewTicketCard handleSubmitTicket={handleSubmitTicket} loading={TICKETS.loading} showNewTicketCard={showNewTicketCard} />
				{!TICKETS?.loading && TICKETS?.data?.length === 0 ? (
					<Box style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
						<Typography>You don't have any ticket yet.</Typography>
					</Box>
				) : (
					TICKETS?.data?.map((ticket, index) => {
						return <TicketCard key={`ticket-${index}`} ticket={ticket} handleSelectTicket={handleSelectTicket} selectedTicket={selectedTicket} />;
					})
				)}
				<Collapse in={TICKETS.loading && TICKETS.data.length > 6}>
					<Box style={{ marginTop: 8, marginButton: 8 }}>
						<CardContent style={{ display: 'flex', justifyContent: 'center' }}>
							<CircularProgress />
						</CardContent>
					</Box>
				</Collapse>
				{!TICKETS.loading && (
					<Zoom in={!TICKETS.loading}>
						<Box style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
							<RenderButton color="primary" onClick={() => loadNewTickets()}>
								Load more tickets
							</RenderButton>
						</Box>
					</Zoom>
				)}
			</Box>
		</>
	);
};
