export const initialState = {};
export const initialBiddingPortalExample = {
  biddingPortalExample: undefined,
};
export const initialPlaceOrder = {
  orderInterestRate: null,
  orderDailyAmount: null,
  orderMinimumCreditRating: null,
  orderPaymentTerms: 30,
  orderPaymentTermsNet: 30,
};
export const initialDataCreateOrder = {
  interestRate: null,
  dailyAmount: null,
  minimumCreditRating: null,
  paymentTerms: 30,
  paymentTermsNet: 30,
};

export const initialAcceptOrder = {
  id: null,
  interestRate: null,
  dailyAllocatedAmount: null,
  minimumCreditRating: null,
  maximumPaymentTerms: null,
};

export const BIDDING_PORTAL_EXAMPLE = "BIDDING_PORTAL_EXAMPLE";
export const PLACE_ORDER = "PLACE_ORDER";
export const RESULT_PROCESS_ORDER = "RESULT_PROCESS_ORDER";
export const RESULT_ORDER = "RESULT_ORDER";
export const RESULT_INITIALIZE_BIDDING_PORTAL =
  "RESULT_INITIALIZE_BIDDING_PORTAL";

export const biddingPortalExample = (state = initialState) =>
  state[BIDDING_PORTAL_EXAMPLE] || initialBiddingPortalExample;

export const getBiddingPortalExample = (state = initialState) =>
  biddingPortalExample(state).biddingPortalExample;

export const getPlaceOrder = (state = initialState) =>
  state[PLACE_ORDER] || initialPlaceOrder;

export const initialCreateOrder = (state = initialState) =>
  initialDataCreateOrder;

export const resultProcessOrder = (state = initialState) =>
  state[RESULT_PROCESS_ORDER] || { resultProcessOrder: undefined };

export const getResultProcessOrder = (state = initialState) =>
  resultProcessOrder(state).resultProcessOrder;

export const order = (state = initialState) =>
  state[RESULT_ORDER] || { order: undefined };

export const getOrder = (state = initialState) => order(state).order;

export const getInitialAcceptOrder = () => initialAcceptOrder;

export const initializeBiddingPortal = (state = initialState) =>
  state[RESULT_INITIALIZE_BIDDING_PORTAL] || {
    dataBiddingPortal: {
      walletAmount: undefined,
      funders: undefined,
      suppliers: undefined,
      contracts: undefined,
    },
  };

export const getInitializeBiddingPortal = (state = initialState) =>
  initializeBiddingPortal(state).dataBiddingPortal;
