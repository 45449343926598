export const SELECT_MENU_ITEM = "SELECT_MENU_ITEM";
export const MENU = "MENU";
export const MENU_SECTIONS = 'MENU_SECTIONS';
export const OPEN_MENU = "OPEN_MENU";
export const MENU_OPEN = "MENU_OPEN";
export const SET_MENU_SECTIONS = 'SET_MENU_SECTIONS';

export const actionCreatorSelectItem = data => ({
  type: SELECT_MENU_ITEM,
  payload: data
});

export const actionCreatorGetMenuState = data => ({
  type: OPEN_MENU,
  payload: data
});

export const actionCreatorGetMenuSectionState = data => ({
  type: SET_MENU_SECTIONS,
  payload: data
});
