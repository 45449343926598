import React, { useEffect, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { KeyboardArrowLeft, KeyboardArrowRight, ArrowForward, ArrowBack } from '@material-ui/icons';
import { IconButton, Typography, Select, MenuItem, Input } from '@material-ui/core';
import RenderTablePaginationStyle from './style';
import {useTranslation} from "react-i18next";

const RenderTablePagination = props => {
	const { t } = useTranslation(['translations']);

	const { page, pageSize, canPrevious, canNext, classes, sortedData, pageSizeOptions, tableTotal } = props;

	const [rowsPerPage, setRowsPerPage] = useState(pageSize);

	const changePage = pg => {
		const { onPageChange } = props;
		const activePage = page + 1;

		if (pg === activePage) {
			return;
		}
		onPageChange(pg - 1);
	};

	const changeRowsPerPage = rows => {
		const { onPageSizeChange } = props;

		if (rows === pageSize) {
			return;
		}

		onPageSizeChange(rows);
	};

	const handleRowsPerPageChange = event => {
		setRowsPerPage(event.target.value);
		changeRowsPerPage(event.target.value);
	};

	const activePage = page + 1;
	const totalPages = Math.ceil(tableTotal / rowsPerPage);

	useEffect(() => {
		changePage(props.page + 1);
	}, [page]);

	return (
		<div className="rt-pagination-container">
			<div>
				<Typography variant="caption">{t('table.pagination.per_page')}:</Typography>

				<Select
					value={rowsPerPage}
					onChange={handleRowsPerPageChange}
					classes={{
						root: classes.selectRoot,
						select: classes.select,
						icon: classes.selectIcon
					}}
					input={<Input disableUnderline className={classes.input} />}
				>
					{pageSizeOptions.map(size => (
						<MenuItem value={size} key={size}>
							{size}
						</MenuItem>
					))}
				</Select>
			</div>

			<div>
				{tableTotal ? (
					<Typography variant="caption" className={classes.marginRight}>
						{sortedData.length === 0 ? 0 : page * rowsPerPage + 1}-{page * rowsPerPage + sortedData.length} of {tableTotal}
					</Typography>
				) : (
					<Typography variant="caption" className={classes.marginRight}>
						{sortedData.length === 0 ? 0 : page * rowsPerPage + 1}-{Math.min(sortedData.length, (page + 1) * rowsPerPage)} of {sortedData.length}
					</Typography>
				)}
			</div>

			<div>
				<IconButton
					onClick={() => {
						changePage(1);
					}}
					disabled={!canPrevious}
				>
					<ArrowBack />
				</IconButton>

				<IconButton
					onClick={() => {
						changePage(activePage - 1);
					}}
					disabled={!canPrevious}
				>
					<KeyboardArrowLeft />
				</IconButton>

				<IconButton
					onClick={() => {
						changePage(activePage + 1);
					}}
					disabled={!canNext}
				>
					<KeyboardArrowRight />
				</IconButton>

				<IconButton
					onClick={() => {
						changePage(totalPages);
					}}
					disabled={!canNext}
				>
					<ArrowForward />
				</IconButton>
			</div>
		</div>


	);
};

export default withStyles(RenderTablePaginationStyle)(RenderTablePagination);
