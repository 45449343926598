import React, { memo, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { API } from 'aws-amplify';
import {CardContent, Paper} from "@material-ui/core";
import {CurrencyNumber, RenderTable} from "components";
import {useTranslation} from "react-i18next";
import moment from "moment";
import { contractIdFormatter } from "utils"

export default memo(({ id: contractId, partner, filters }) => {
	const { t } = useTranslation('reports/reports');

	if(!contractId) {
		return null
	}

	const [loading, setLoading] = useState(false)
	const [data, setData] = useState([])

	const [totalRecords, setTotalRecords] = useState(0)
	const [perPage, setPerPage] = useState(10)
	const [page, setPage] = useState(1)

	const columns = [
		{
			Header: t('date'),
			Cell: ({ original }) => original && original.date && moment(original.date).isValid() && moment(original.date).format("MM/DD/YYYY") || ''
		},
		{
			Header: t('country'),
			accessor: 'country',
			Cell: data => data.value.replace(/['"]+/g, '')
		},
		{
			Header: t('destination_type'),
			accessor: 'destinationType'
		},
		{
			Header: t('destination_carrier'),
			accessor: 'destinationCarrier'
		},
		{
			Header: t('Minutes'),
			className: 'rt-text-right',
			accessor: 'minutes',
			Cell: data => parseFloat(data.value).toFixed(2),
			Footer: table => {
				let total = 0;
				table.data.map(obj => {
					total += parseFloat(obj.minutes);
				});
				return <strong>{total.toFixed(2)}</strong>;
			}
		},
		{
			Header: t('attempts'),
			className: 'rt-text-right',
			accessor: 'attempts'
		},
		{
			Header: t('connected_calls'),
			className: 'rt-text-right',
			accessor: 'connected_calls'
		},
		{
			Header: 'ASR',
			className: 'rt-text-right',
			accessor: 'asr',
			Cell: data => `${parseFloat(data.value).toFixed(0)}%`
		},
		{
			Header: 'ACD',
			className: 'rt-text-right',
			accessor: 'acd'
		},
		{
			Header: 'PDD',
			className: 'rt-text-right',
			accessor: 'pdd'
		},
		{
			Header: t('rate'),
			className: 'rt-text-right',
			accessor: 'rate',
			Cell: props => <CurrencyNumber amount={parseFloat(props.value).toFixed(6)} precision={6} />
		},
		{
			Header: t('billing_inc'),
			className: 'rt-text-right',
			accessor: 'billingIncrement'
		},
		{
			Header: t('amount'),
			className: 'rt-text-right',
			accessor: 'amount',
			Cell: props => <CurrencyNumber amount={parseFloat(props.value).toFixed(6)} precision={6} />,
			Footer: table => {
				let total = 0;
				table.data.map(obj => {
					total += parseFloat(obj.amount);
				});
				return (
					<strong>
						<CurrencyNumber amount={total.toFixed(6)} precision={6} />
					</strong>
				);
			}
		}
	];


	const getData = useCallback(async () => {
		setLoading(true)
		return API.post('REPORTS_SERVICE_API',`/athena/advanced-report/${contractId}`,{
			body: {
				filters: {
					...filters,
					contractId: contractId
				},
				page: page + 1,
				perPage
			}
		})
			.then((response) => {
				setTotalRecords(response.metaData && response.metaData.total || 0)
				setLoading(false)
				setData(response.data || [])
			})
			.catch(e => {
			return {
				error: true,
				data: []
			}
		})
	},[page, perPage])

	const triggerPage = useCallback((page) => {
		setPage(page)
		getData()
	},[page, perPage])
	const triggerPerPage = useCallback((perPage) => {
		setPerPage(perPage)
		getData()
	},[page, perPage])

	useEffect(() => {
		getData();
	},[])

	return (
		<div className={styles.container}>
			<Paper>
				<CardContent>
					<h3>{partner} - ${contractIdFormatter(`${contractId}`)}</h3>
					<RenderTable
						resizable={false}
						sortable={false}
						data={data}
						style={{ width: '100%' }}
						columns={columns}
						filterable={false}
						loading={loading}


						manual
						showPagination
						tableTotal={totalRecords}
						pages={Math.ceil(totalRecords / perPage)}
						onPageChange={triggerPage}
						defaultPageSize={perPage}
						onPageSizeChange={triggerPerPage}
					/>
				</CardContent>
			</Paper>
		</div>
	);
});


