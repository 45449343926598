import React from "react";
import { Grid } from "@material-ui/core";

const RenderSIPProfileDetails = props => {
  const {
    voipProtocolName,
    prefix,
    codecsChecked,
    catalogsNewSmartContract
  } = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        <strong>Protocol:</strong> {voipProtocolName}
      </Grid>
      <Grid item xs={12}>
        <strong>Prefix:</strong> {prefix}
      </Grid>
      <Grid item xs={12}>
        <strong>Codecs Allowed:</strong>{" "}
        {catalogsNewSmartContract != null ||
        catalogsNewSmartContract !== undefined
          ? catalogsNewSmartContract.codecs
              .filter(codec => codecsChecked[codec.codexId])
              .map((codec, i) => <span key={i}>{`${codec.codexName}, `}</span>)
          : ""}
      </Grid>
    </Grid>
  );
};

export default RenderSIPProfileDetails;
