const notificationStyle = theme => ({
  success: {
    backgroundColor: theme.palette.success.main
  },
  danger: {
    backgroundColor: theme.palette.danger.main
  },
  warning: {
    backgroundColor: theme.palette.warning.main
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    position: "absolute",
    top: "50%",
    left: "10px",
    transform: "translateY(-50%)"
  },
  container: {
    position: "relative"
  },
  message: {
    padding: `10px 20px`
  },
  close: {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)"
  }
});

export default notificationStyle;
