import { put, takeEvery, all, call } from 'redux-saga/effects';
import actions from './actions';
import { actionCreatorPreloaderOff, actionCreatorPreloaderOn } from 'store/preloader/actions';
import { getSmartContractsCatalogDataState } from 'store/smartcontracts/selectors';
import apiZoho from 'services/api/zohoApi';
import { triggerNotification } from 'store/notifications/actions';

export function* GET_TICKETS(action) {
	yield put(actionCreatorPreloaderOn);
	yield put({
		type: 'support/SET_TICKETS_STATE',
		payload: {
			loading: true
		}
	});
	try {
		const list = yield call(apiZoho.getTicketsByUser, action.payload);

		const { newTicket } = action?.payload;
		const { tickets } = list;

		yield put({
			type: 'support/SET_TICKETS_STATE',
			payload: {
				data: newTicket && tickets === undefined ? [newTicket] : tickets || [],
				metadata: list?.metadata
			}
		});
	} catch (error) {
		console.log('GET_TICKETS ERROR:', error);
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	} finally {
		yield put({
			type: 'support/SET_TICKETS_STATE',
			payload: {
				loading: false
			}
		});
		yield put(actionCreatorPreloaderOff());
	}
}

export function* GET_THREADS(action) {
	yield put(actionCreatorPreloaderOn);
	yield put({
		type: 'support/SET_THREADS_STATE',
		payload: {
			loading: true
		}
	});
	try {
		const list = yield call(apiZoho.getTicketsThreads, action.payload.selectedTicket);

		if (!list?.success) throw new Error('Could not fetch threads data');
		yield put({
			type: 'support/SET_THREADS_STATE',
			payload: {
				data: list?.threads || []
			}
		});
	} catch (error) {
		console.log('ERROR GETTING THE THREADS:', error);
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	} finally {
		yield put({
			type: 'support/SET_THREADS_STATE',
			payload: {
				loading: false
			}
		});
		yield put(actionCreatorPreloaderOff());
	}
}

export function* CREATE_TICKET(action) {
	yield put(actionCreatorPreloaderOn);
	yield put({
		type: 'support/SET_TICKETS_STATE',
		payload: {
			loading: true
		}
	});
	try {
		const response = yield call(apiZoho.createTicket, action.payload);

		if (response.success) {
			yield put({
				type: 'support/GET_TICKETS',
				payload: { email: action.payload.email, from: 0, limit: action.payload.ticketQty, newTicket: response.newTicket }
			});
		}

		if (!response.success) {
			yield put(triggerNotification(response.message || 'Error - Unable to create the ticket', 'error'));
		}
	} catch (error) {
		console.log('GET_TICKETS ERROR =>', error);
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	} finally {
		yield put({
			type: 'support/SET_TICKETS_STATE',
			payload: {
				loading: false
			}
		});
		yield put(actionCreatorPreloaderOff());
	}
}

export default function* rootSaga() {
	yield all([takeEvery(actions.GET_TICKETS, GET_TICKETS)]);
	yield all([takeEvery(actions.GET_THREADS, GET_THREADS)]);
	yield all([takeEvery(actions.CREATE_TICKET, CREATE_TICKET)]);
}
