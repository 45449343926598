import React, { useState } from 'react';
import { Field } from 'redux-form';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { RenderCheckbox } from 'components';
import { Trans, useTranslation } from 'react-i18next';

const SmartContractTermsConditions = () => {
	const { t } = useTranslation(['smartcontracts/create', 'translations']);

	const [termsModalOpen, setTermsModalOpen] = useState(false);
	return (
		<div>
			<Field
				component={RenderCheckbox}
				type="checkbox"
				name="terms"
				id="terms"
				required
				label={
					<Trans
						ns="smartcontracts/create"
						i18nKey="terms_agreement"
						components={{
							TermsLink: <Link to="#" onClick={() => setTermsModalOpen(true)} />
						}}
					/>
				}
			/>

			<Dialog open={termsModalOpen} onBackdropClick={() => setTermsModalOpen(state => !state)}>
				<DialogTitle toggle={() => setTermsModalOpen(false)}>{t('translations:terms_and_conditions.title')}</DialogTitle>
				<DialogContent>
					<p>{t('translations:terms_and_conditions.text')}</p>
				</DialogContent>
				<DialogActions>
					<Button color="danger" onClick={() => setTermsModalOpen(false)}>
						{t('translations:close')}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default SmartContractTermsConditions;
