import React, {
  Component,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { RenderTable, RenderButton } from "components";
import { useDispatch, useSelector } from "react-redux";
import {
  CardContent,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide
} from "@material-ui/core";
import { Link } from "react-router-dom";

const Transition = props => <Slide direction="up" {...props} />;

const RoutesSmartContractsList = memo(({ filters }) => {
  const dispatch = useDispatch();

  const [destinations, setDestinations] = useState([]);
  const [destinationsModalOpen, setDestinationsModalOpen] = useState(false);

  const tableData = useSelector( state => (state.routes && state.routes.list) || []);
  const isLoading = useSelector( state => (state.routes && state.routes.loading) || false);

  const { contractType: filterType } = filters;

  const showDestinations = useCallback(
    val => {
      let dest = val;
      if (val.length === undefined) {
        dest = [];
      }
      const destobj = [];
      dest.map(item => {
        destobj.push({ destination: item });
        return dest;
      });

      setDestinations(destobj);
      setDestinationsModalOpen(state => !state);
    },
    [destinationsModalOpen]
  );

  const tableColumns = [
    {
      Header: filterType === "selling" ? "Match Type" : "Destination",
      accessor: "partnerName",
      Cell: d => <Typography variant="title">{d.value}</Typography>,
      style: {
        overflow: "visible"
      }
    },
    {
      Header: "Destination",
      accessor: "partnerName",
      Cell: () => null,
      show: filterType === "selling"
    },
    {
      Header: "Description",
      accessor: "partnerName",
      Cell: () => null,
      show: filterType === "selling"
    },
    {
      Header: "Route Type",
      accessor: "partnerName",
      Cell: () => null,
      show: filterType === "selling"
    },
    {
      Header: "Ingress - Ports (CPS)",
      accessor: "partnerName",
      Cell: () => null
    },
    {
      Header: "Egress - Ports (CPS)",
      accessor: "partnerName",
      show: filterType === "selling",
      Cell: () => null
    },
    {
      Header: "Traffic",
      accessor: "partnerName",
      Cell: () => null
    },
    {
      Header: "ContractType",
      accessor: "partnerName",
      Cell: () => null
    },
    {
      Header: "Smart contract ID",
      accessor: "partnerName",
      Cell: () => null
    },
    {
      Header: "Actions",
      id: "actions"
    }
  ];

  const subTableColumns = [
    {
      Header: () => null,
      accessor: "matchType",
      show: filterType === "selling",
      Cell: d => (d.value ? (d.value === "D" ? "Digit" : "Country") : "-")
    },
    {
      Header: () => null,
      accessor: "destination",
      Cell: d => {
        if (filterType === "selling") {
          if (d.original.matchType === "D") {
            return d.original.digitMatch;
          }
          return `${d.original.countryMatch} ${d.original.countryMatchType}`;
        }
        return (
          <span style={{ cursor: "pointer" }}>
            {d.original.rates ? d.original.rates.join(", ") : "-"}
          </span>
        );
      }
    },
    {
      Header: () => null,
      accessor: "description",
      show: filterType === "selling"
    },
    {
      Header: () => null,
      accessor: "routeType",
      show: filterType === "selling",
      Cell: d =>
        d.value
          ? d.value === "S"
          ? "Static"
          : d.value === "P"
            ? "Percentage"
            : "LCR"
          : "-"
    },
    {
      Header: () => null,
      accessor: "ingressPorts",
      Cell: row =>
        `${row.original.ingressPorts || "-"} ${
          row.original.ingressCPS ? `(${row.original.ingressCPS})` : ""
        }`
    },
    {
      Header: () => null,
      accessor: "egressPorts",
      show: filterType === "selling",
      Cell: row =>
        `${row.original.egressPorts || "-"} ${
          row.original.egressCPS ? `(${row.original.egressCPS})` : ""
        }`
    },
    {
      Header: () => null,
      accessor: "traffic",
      Cell: row => row.original.traffic || "-"
    },
    {
      Header: () => null,
      accessor: "originContract",
      Cell: row => (row.original.originContract ? "Origin" : "Standard")
    },
    {
      Header: () => null,
      accessor: "contractId",
      Cell: d => {
        const padded = `${d.value}000000`.slice(0, -d.value.length);
        return parseInt(padded)
          .toString(16)
          .toUpperCase();
      }
    },
    {
      Header: () => null,
      id: "actionsRender",
      Cell: d => (
        <RenderButton
          color="primary"
          component={Link}
          to={`/routes/edit/${d.original.contractId}`}
        >
          {filterType === "buying" ? "View" : "Update Routing"}
        </RenderButton>
      )
    }
  ];

  const destinationsTableColumns = [
    {
      Header: "Destination",
      accessor: "destination"
    }
  ];

  useEffect(() => {
    dispatch({
      type: "routes/GET_LIST",
      payload: {
        filterType: filters.contractType,
        filters
      }
    });

    return () => {
      dispatch({
        type: "routes/SET_STATE",
        payload: {
          list: []
        }
      });
    }
  }, [filters]);

  return (
    <Paper>
      <CardContent>
        <RenderTable
          className="defaultExpanded"
          style={{
            maxHeight: "700px"
          }}
          expanded={{
            0: true,
            1: true,
            2: true,
            3: true,
            4: true,
            5: true,
            6: true
          }}
          resizable={false}
          filterable={false}
          sortable={false}
          isExpandable
          showExpander={false}
          data={isLoading ? [] : (tableData || [])}
          columns={tableColumns}
          expanderDefaults={{
            sortable: false,
            resizable: false,
            filterable: false,
            width: 0
          }}
          SubComponent={row => (
            <div>
              <RenderTable
                resizable={false}
                sortable={false}
                data={
                  filterType === "selling"
                    ? row.original.routes || []
                    : row.original.routes || []
                }
                columns={subTableColumns}
                filterable={false}
                getTdProps={(state, rowInfo, column, instance) => ({
                  onClick: (e, handleOriginal) => {
                    if (
                      filterType === "buying" &&
                      column.id === "destination"
                    ) {
                      showDestinations(rowInfo.original.rates);
                    }
                    if (handleOriginal) {
                      handleOriginal();
                    }
                  }
                })}
              />
            </div>
          )}
        />

        <Dialog
          maxWidth="md"
          fullWidth
          open={destinationsModalOpen}
          onBackdropClick={showDestinations}
          TransitionComponent={Transition}
        >
          <DialogTitle>Destinations</DialogTitle>
          <DialogContent>
            <RenderTable
              data={destinations}
              columns={destinationsTableColumns}
              sortable={false}
              filterable={false}
            />
          </DialogContent>
          <DialogActions>
            <RenderButton color="danger" onClick={showDestinations}>
              Close
            </RenderButton>
          </DialogActions>
        </Dialog>
        <br />
        <br />
      </CardContent>
    </Paper>
  );
});

export default RoutesSmartContractsList;
