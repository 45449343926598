const monthlyInvoiceHeaderStyle = () => ({
  table: {
    minWidth: 700
  },
  w450: {
    minWidth: 450
  },
  w150: {
    minWidth: 150
  },
  w100: {
    minWidth: 100
  },
  noBorderBottom: {
    borderBottom: "none"
  },
  alignRight: {
    textAlign: "right"
  }
});

export default monthlyInvoiceHeaderStyle;
