import React from 'react';
import { Typography, Box, Paper, CardContent, CardActions, Slide } from '@material-ui/core';

export default function TicketCard({ ticket, handleSelectTicket, selectedTicket }) {
	const { createdTime, ticketNumber, subject, statusType, id } = ticket || {};

	return (
		<Slide direction="up" in={true} mountOnEnter unmountOnExit>
			<div style={cardSelectedStyle(selectedTicket, id)}>
				<Paper onClick={() => handleSelectTicket(id)}>
					<CardContent>
						<Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<Typography>{`Date: ${new Date(createdTime).toLocaleTimeString()}`}</Typography>
							<Typography style={{ display: 'flex', alignItems: 'center' }}>
								Ticket number:
								<Typography variant="h6" style={{ paddingLeft: '4px' }}>
									{ticketNumber}
								</Typography>
							</Typography>
						</Box>
						<Typography variant="body2" style={{ marginTop: 10 }}>
							{subject}
						</Typography>
					</CardContent>
					<CardActions style={{ display: 'flex', justifyContent: 'center' }}>
						<Typography variant="caption" style={{ display: 'flex', alignItems: 'center' }}>
							Status: <Typography style={{ paddingLeft: '4px', color: statusType === 'Closed' ? 'green' : 'DarkGoldenRod' }}>{statusType}</Typography>
						</Typography>
					</CardActions>
				</Paper>
			</div>
		</Slide>
	);
}

const cardSelectedStyle = (selectedTicket, id) => {
	const isSelected = selectedTicket === id;

	return {
		marginTop: 8,
		marginButton: 8,
		cursor: 'pointer',
		border: isSelected ? '3px solid green' : '3px solid transparent',
		borderRadius: '6px',
		transition: 'border-color 0.4s'
	};
};
