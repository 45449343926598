import React from "react";

const PublicHeader = () => (
  <nav className="navbar navbar-expand-md fixed-top topbar">
    <div className="container">
      <div className="navbar-brand">
        <a href="/">
          <img src="/images/logo-light-text.png" alt="Logo TessPay" />
        </a>
      </div>
      <div className="collapse navbar-collapse" />
    </div>
  </nav>
);

export default PublicHeader;
