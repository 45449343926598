import { SELECT_MENU_ITEM, OPEN_MENU, MENU, MENU_OPEN, MENU_SECTIONS, SET_MENU_SECTIONS } from "./actions";

const initialState = {
	MENU_OPEN: true,
  MENU_SECTIONS: {}
};

export default function(state = initialState, action) {    
  switch (action.type) {
    case SELECT_MENU_ITEM:
      return {
        ...state,
        [MENU]: {
          selected: action.payload
        }
      };
    case OPEN_MENU:
      return {
        ...state,
        [MENU_OPEN]: {
          open: action.payload
        }
      };
    case SET_MENU_SECTIONS:
      return {
        ...state,
        [MENU_SECTIONS]: {
          ...state[MENU_SECTIONS],
          ...action.payload
        }
      }
    default:
      return state;
  }
}
