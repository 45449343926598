import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RenderCheckbox } from 'components';
import { reduxForm, Field } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class RatesTableCustomFilterForm extends Component {
	render() {
		const { handleChange, t } = this.props;
		const onClick = e => {
			e.persist();
			if (e && e.target) {
				handleChange({ [e.target.name]: e.target.checked });
			}
		};
		return (
			<React.Fragment>
				<fieldset>
					<legend>Filter</legend>
					<Field component={RenderCheckbox} name="currentRateOnly" label={t('rates.only_current_rates')} onClick={onClick} />
					<Field component={RenderCheckbox} name="showEnded" label={t('rates.only_ended_rates')} onClick={onClick} />
					<Field component={RenderCheckbox} name="showFuture" label={t('rates.only_future_rates')} onClick={onClick} />
				</fieldset>
			</React.Fragment>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({}, dispatch);
}
RatesTableCustomFilterForm.propTypes = {
	handleChange: PropTypes.func.isRequired
};

const RatesTableCustomFilterReduxForm = reduxForm({
	form: 'RatesTableCustomFilterForm'
})(RatesTableCustomFilterForm);

const RatesTableCustomFilterReduxFormTranslated = withTranslation(['smartcontracts/edit', 'translations'])(RatesTableCustomFilterReduxForm);

export default connect(null, mapDispatchToProps)(RatesTableCustomFilterReduxFormTranslated);
