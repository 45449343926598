const invoiceStyle = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: 25
  },
  divider: {
    marginTop: theme.spacing(1) * 3,
    marginBottom: theme.spacing(1) * 3
  }
});

export default invoiceStyle;
