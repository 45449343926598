import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useAccordion = () => {
	const dispatch = useDispatch();
	const [createModalOpened, setCreateModalOpened] = useState(false);
	const [detailsModalOpened, setDetailsModalOpened] = useState(false);
	const [openedOrder, setOpenedOrder] = useState(null);
	const [openedSmartContract, setOpenedSmartContract] = useState(true);

	const { escrowmanaging, notifications } = useSelector(state => state || []);
	const { orders } = escrowmanaging || {};
	const transactionResult = notifications.notifications;

	useEffect(() => {
		dispatch({ type: 'escrowManaging/GET_ORDERS' });
	}, []);

	useEffect(() => {
		if (
			(transactionResult.length > 0 && transactionResult?.[0]?.message === 'ACP Smart contract created successfully') ||
			transactionResult?.[0]?.message === 'Self-fulfillment order created successfully'
		) {
			setCreateModalOpened(false);
		}
	}, [transactionResult]);

	const handleToggleDetailsModal = useCallback(() => {
		setDetailsModalOpened(!detailsModalOpened);
	}, [detailsModalOpened]);

	useEffect(() => {
		if (!detailsModalOpened) {
			setOpenedSmartContract(null);
			setOpenedOrder(null);
		}
	}, [detailsModalOpened]);

	useEffect(() => {
		if (!createModalOpened) {
			setOpenedOrder(null);
		}
	}, [createModalOpened]);

	useEffect(() => {
		const updatedSmartContracts = orders.flatMap(order => order.contracts);
		const updatedSmartContract = updatedSmartContracts.find(contract => contract?._id === openedSmartContract?._id);
		setOpenedSmartContract(updatedSmartContract);
	}, [orders]);

	return {
		createModalOpened,
		setCreateModalOpened,
		detailsModalOpened,
		openedOrder,
		setOpenedOrder,
		openedSmartContract,
		setOpenedSmartContract,
		handleToggleDetailsModal,
		orders
	};
};
