import React, { Component } from "react";
import { ChangePasswordForm } from "components";
import { reduxForm, reset } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changePasswordRequest } from "store/actions";
import {
  createValidator,
  required,
  minLength,
  match,
  validaRegExp,
  isChecked,
  email
} from "services/validation";

class ChangePasswordContainer extends Component {
  componentWillUnmount() {
    this.props.reset("changePasswordForm");
  }

  render() {
    return (
      <ChangePasswordForm
        {...this.props}
        handleSubmit={this.props.handleSubmit}
      />
    );
  }
}

const mapStateToProps = state => ({
  form: state.form,
  info: state.changepassword.CHANGE_PASSWORD
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      reset
    },
    dispatch
  );
}

const onSubmit = (data, dispatch) => {
  dispatch(
    changePasswordRequest(data.passwordOld, data.passwordNew, data.email),
    reset("changePasswordForm")
  );
};

const validate = createValidator({
  email: [required, email],
  passwordOld: [required, minLength(8)],
  passwordNew: [
    required,
    minLength(8),
    match("passwordNewConfirmation", "Password confirmation"),
    validaRegExp(
      "^(?=.*\\d)(?=.*[!@#$%^.&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$",
      "The password is too weak"
    )
  ],
  passwordNewConfirmation: [
    required,
    minLength(8),
    match("passwordNew", "New password")
  ],
  agree: [required, isChecked]
});

ChangePasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordContainer);

ChangePasswordContainer = reduxForm({
  form: "changePasswordForm",
  destroyOnUnmount: true,
  enableReinitialize: true,
  onSubmit,
  validate
})(ChangePasswordContainer);

export default ChangePasswordContainer;
