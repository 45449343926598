import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PendingSubmissions from './PendingSubmissions';
import RejectedSubmissions from './RejectedSubmissions';
import ApprovedSubmissions from './ApprovedSubmissions';
import ArchivingSettings from './ArchivingSettings';

import actions from 'store/acp/actions';
import styles from './styles.module.scss';

export default memo(() => {
	const dispatch = useDispatch();

	const isLoading = useSelector(state => (state.acp && state.acp.loading) || false);
	const nladSubmissions = useSelector(state => (state.acp && state.acp.nladSubmissions) || []);
	const nladSubmissionsArchived = useSelector(state => (state.acp && state.acp.nladSubmissionsArchived) || []);
	const nladSubmissionsCurrentMonth = useSelector(state => (state.acp && state.acp.nladSubmissionsCurrentMonth) || []);
	const nladSubmissionsLastMonth = useSelector(state => (state.acp && state.acp.nladSubmissionsLastMonth) || []);

	const [openedTable, setOpenedTable] = useState('');

	const [numberOfSubmissions, setNumberOfSubmissions] = useState({
		pending: 0,
		rejected: 0,
		verified: 0
	});

	const [numberOfSubmissionsArchived, setNumberOfSubmissionsArchived] = useState({
		pending: 0,
		rejected: 0,
		verified: 0
	});

	useEffect(() => {
		dispatch({
			type: actions.GET_NLAD_SUBMISSIONS
		});
	}, []);

	useEffect(() => {
		setNumberOfSubmissions({
			pending: nladSubmissions?.filter(submission => submission.nladVerificationStatus === 'pending').length,
			rejected: nladSubmissions?.filter(submission => submission.nladVerificationStatus === 'rejected').length,
			approved: nladSubmissions?.filter(submission => submission.nladVerificationStatus === 'processed').length
		});
		setNumberOfSubmissionsArchived({
			pending: nladSubmissionsArchived?.filter(submission => submission.nladVerificationStatus === 'pending').length,
			rejected: nladSubmissionsArchived?.filter(submission => submission.nladVerificationStatus === 'rejected').length,
			approved: nladSubmissionsArchived?.filter(submission => submission.nladVerificationStatus === 'processed').length
		});
	}, [nladSubmissions, nladSubmissionsArchived]);

	return (
		<div className={styles.container}>
			<ArchivingSettings />

			<PendingSubmissions
				openedTable={openedTable}
				setOpenedTable={setOpenedTable}
				isLoading={isLoading}
				nladSubmissions={nladSubmissions}
				numberOfSubmissions={numberOfSubmissions}
			/>
			<br />

			<RejectedSubmissions
				openedTable={openedTable}
				setOpenedTable={setOpenedTable}
				isLoading={isLoading}
				nladSubmissions={nladSubmissions}
				nladSubmissionsArchived={nladSubmissionsArchived}
				numberOfSubmissions={numberOfSubmissions}
				numberOfSubmissionsArchived={numberOfSubmissionsArchived}
				nladSubmissionsCurrentMonth={nladSubmissionsCurrentMonth}
				nladSubmissionsLastMonth={nladSubmissionsLastMonth}
			/>
			<br />

			<ApprovedSubmissions
				openedTable={openedTable}
				setOpenedTable={setOpenedTable}
				isLoading={isLoading}
				nladSubmissions={nladSubmissions}
				nladSubmissionsArchived={nladSubmissionsArchived}
				numberOfSubmissions={numberOfSubmissions}
				numberOfSubmissionsArchived={numberOfSubmissionsArchived}
				nladSubmissionsCurrentMonth={nladSubmissionsCurrentMonth}
				nladSubmissionsLastMonth={nladSubmissionsLastMonth}
			/>
		</div>
	);
});
