import React, { memo, useCallback, useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, RenderButton, RenderFormikRadioGroup, RenderFormikSelect, RenderRadio, RenderFormikInput } from 'components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { RoutesDestinationRow, CodeListModal } from './components';
import style from './style.module.scss';
import { useTranslation } from 'react-i18next';

const Transition = props => <Slide direction="up" {...props} />;

const UpdateRoute = memo(({ open, triggerOpenClose, smartContractId, routeId }) => {
	const dispatch = useDispatch();
	const isLoading = useSelector(state => (state.routes && state.routes.loadingRouteUpdate) || false);
	const codesLoading = useSelector(state => (state.routes && state.routes.routeCodeLoading) || false);
	const routeDetails = useSelector(state => (state.routes && state.routes.routeDetails) || false);
	const { countries, matchCount, types } = useSelector(state => state.routes && state.routes.routeUpdateCodeMatches);

	const [codeMatchModal, setCodeMatchModal] = useState(false);
	const { t } = useTranslation('routing/routing');
	const formik = useFormik({
		initialValues: Object.assign(
			{
				matchType: 'N',
				countryMatch: '',
				countryMatchType: '',
				digitMatch: '*',
				description: '',
				routeType: 'S',
				destinations: [
					{
						id: '',
						type: 'smartContract',
						percentage: '',
						sessions: '20000',
						cps: '100',
						active: true
					}
				]
			},
			routeDetails || {}
		),
		onSubmit: (values, { resetForm }) => {
			dispatch({
				type: 'routes/UPDATE_ROUTE',
				payload: {
					smartContractId,
					...values
				}
			});
			resetForm();
			triggerOpenClose();
		},
		validationSchema: Yup.object().shape({
			matchType: Yup.string().required(t('req_please_select_help', {label: t('match_type_help')})),
			countryMatch: Yup.string().when('matchType', { is: 'N', then: Yup.string().required(t('req_country_help', {label: t('country_help')}))}),
			countryMatchType: Yup.string(),
			digitMatch: Yup.string().when('matchType', { is: 'D', then: Yup.string().required(t('req_digit_help', {label: t('digit_help')})) }),
			description: Yup.string(),
			routeType: Yup.string().required(t('req_please_select_help', {label: t('match_type_help')})),
			destinations: Yup.array().of(
				Yup.object().shape({
					type: Yup.string().required(t('req_route_type_help', {label: t('route_type_help')})),
					id: Yup.string().required(t('req_please_select_help', {label: t('route')})),
					percentage: Yup.number().nullable(),
					sessions: Yup.number().required(t('req_please_select_help', {label: t('ports')})),
					cps: Yup.number().required(t('req_please_select_help', {label: t('cps_help')})),
					active: Yup.boolean()
				})
			)
		}),
		enableReinitialize: true
	});

	const triggerLoadCodes = useCallback(() => {
		dispatch({
			type: 'routes/GET_UPDATE_ROUTE_CODE_MATCHES',
			payload: {
				country: formik.values.countryMatch,
				type: formik.values.countryMatchType
			}
		});
	}, [formik.values.countryMatch, formik.values.countryMatchType]);

	useEffect(() => {
		triggerLoadCodes();
	}, []);

	useEffect(() => {
		triggerLoadCodes();
	}, [formik.values.countryMatch, formik.values.countryMatchType]);

	useEffect(() => {
		if (open) {
			dispatch({
				type: 'routes/GET_ROUTE_DETAILS',
				payload: routeId
			});
		}
	}, [open, routeId]);

	return (
		<Dialog maxWidth="md" fullWidth open={open} onBackdropClick={triggerOpenClose} TransitionComponent={Transition} className={style.container}>
			<DialogTitle>{t('code_matches')}</DialogTitle>
			<DialogContent>
				{(isLoading && <Loader loading />) || ''}
				<form onSubmit={formik.handleSubmit}>
					<Grid container spacing={2}>
						<Grid item xs={3}>
							<RenderFormikRadioGroup formik={formik} inline name="matchType">
								<RenderRadio value="N" label={t('name_up')} />
								<RenderRadio value="D" label={t('digit_up')} />
							</RenderFormikRadioGroup>
						</Grid>
						<Grid item xs={9}>
							{formik.values.matchType === 'N' ? (
								<Grid container spacing={1}>
									<Grid item xs={5}>
										<RenderFormikSelect
											disabled={codesLoading}
											formik={formik}
											label={t('country')}
											name="countryMatch"
											options={(countries && Array.isArray(countries) && countries.map(el => ({ label: el, value: el }))) || []}
										/>
									</Grid>
									<Grid item xs={4}>
										<RenderFormikSelect
											disabled={codesLoading}
											formik={formik}
											label={t('type')}
											name="countryMatchType"
											options={(types && Array.isArray(types) && types.map(el => ({ label: el, value: el }))) || []}
										/>
									</Grid>
									<Grid item xs={3}>
										<RenderButton disabled={codesLoading} onClick={() => setCodeMatchModal(state => !state)}>
										{t('view_codes')} ({matchCount})
										</RenderButton>
									</Grid>
								</Grid>
							) : (
								<RenderFormikInput label={t('digit_match')} name="digitMatch" formik={formik} />
							)}
						</Grid>
						<Grid item xs={12}>
							<RenderFormikInput label={t('description')} name="description" formik={formik} />
						</Grid>
						<Grid item xs={7}>
							<RenderFormikRadioGroup formik={formik} inline name="routeType">
								<RenderRadio value="S" label={t('static')} />
								<RenderRadio value="P" label={t('percentage')} />
								<RenderRadio value="R" label="LCR" />
							</RenderFormikRadioGroup>
						</Grid>

						<Grid item xs={12}>
							<RoutesDestinationRow formik={formik} />
						</Grid>
					</Grid>
				</form>
			</DialogContent>
			<DialogActions>
				<RenderButton color="danger" onClick={triggerOpenClose}>
				{t('close')}
				</RenderButton>
				<RenderButton color="primary" onClick={formik.handleSubmit} disabled={(formik.values.destinations || []).length === 0}>
					{t('update_route')}
				</RenderButton>
			</DialogActions>

			<CodeListModal
				open={codeMatchModal}
				triggerOpenClose={() => setCodeMatchModal(state => !state)}
				formik={formik}
				country={formik.values.countryMatch}
				type={formik.values.countryMatchType}
			/>
		</Dialog>
	);
});

export default UpdateRoute;
