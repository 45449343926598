import customTheme from 'assets/customTheme';

export const styles = {
	root: { overflow: 'visible' },
	cardHeader: {
		textAlign: 'center',
		marginLeft: '20px',
		marginRight: '20px',
		marginBottom: '-20px',
		borderRadius: '3px',
		padding: '1rem 15px',
		top: '-40px',
		position: 'relative',
		backgroundColor: customTheme.palette.light,
		border: `1px solid ${customTheme.palette.lightGrey.main}`
	},
	form: {
		maxWidth: '500px'
	},
	field: {
		width: '100%'
	}
};
