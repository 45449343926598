import React, { useContext, memo } from 'react';
import { RenderTable, InvoiceLoader } from 'components';
import { Paper, CardContent, Typography } from '@material-ui/core';
import ExternalTableHeader from './external.table.header';
import MonthlyTableHeader from './monthly.table.header';

export default memo(({ invoices, invoicesType, FinancialContext }) => {
	const { t, toggleInvoiceModal } = useContext(FinancialContext);
	const { data = [], loading } = invoices;
	const invoicesFound = data.length !== 0;

	const clientDataKey = {
		monthly_invoices_per_cust: 'customerData',
		external_invoices: 'customerData',
		monthly_invoices_per_supplier: 'supplierData'
	};

	const { columns } =
		invoicesType === 'monthly_invoices_per_cust' || invoicesType === 'monthly_invoices_per_supplier'
			? MonthlyTableHeader({ toggleInvoiceModal, clientDataKey: clientDataKey[invoicesType] }, t)
			: ExternalTableHeader({ toggleInvoiceModal }, t);

	return (
		<Paper>
			<CardContent>
				<Typography variant="h1" align="left">
					{t(invoicesType)}
				</Typography>
				{!loading && invoicesFound ? (
					<RenderTable
						data={data}
						columns={columns}
						defaultSorted={[
							{
								id: 'period',
								desc: true
							}
						]}
					/>
				) : (
					<InvoiceLoader loading={loading} invoicesFound={invoicesFound} invoicesType={invoicesType} />
				)}
			</CardContent>
		</Paper>
	);
});
