import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { RenderTable } from 'components';
import withStyles from '@material-ui/core/styles/withStyles';
import TableInvoicePerCustomerStyle from './style';
import MainTableHeader from './main.table.header';
import TableExternalInvoicesStyle from '../TableExternalInvoices/style';
import { withTranslation } from 'react-i18next';

// eslint-disable-next-line react/prefer-stateless-function
class TableInvoicePerCustomer extends Component {
	render() {
		const { data, toggleInvoiceModal, clientDataKey, t } = this.props;

		const { columns } = MainTableHeader({ toggleInvoiceModal, clientDataKey }, t);
		return (
			<React.Fragment>
				<RenderTable
					data={data}
					columns={columns}
					defaultSorted={[
						{
							id: 'period',
							desc: true
						}
					]}
				/>
			</React.Fragment>
		);
	}
}

TableInvoicePerCustomer.defaultProps = {
	clientDataKey: 'customerData'
};

TableInvoicePerCustomer.propTypes = {
	clientDataKey: PropTypes.string,
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.array.isRequired,
	toggleInvoiceModal: PropTypes.func.isRequired
};

const TableInvoicePerCustomerWithStyles = withStyles(TableInvoicePerCustomerStyle)(TableInvoicePerCustomer);
const TableInvoicePerCustomerTranslations = withTranslation(['financials/general'])(TableInvoicePerCustomerWithStyles);

export default TableInvoicePerCustomerTranslations;
