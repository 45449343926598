import React from "react";
import { PrivateTemplate, BreadCrumb } from "components";
import { SmartContractsNewContainer } from "containers";

const SmartContractNewPage = () => (
  <PrivateTemplate>
    <BreadCrumb
      name="New Smart Contract"
      firstLevel="Home"
      secondLevel="New smart contract"
    />
    <SmartContractsNewContainer />
  </PrivateTemplate>
);
export default SmartContractNewPage;
