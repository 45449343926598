import React, { memo, useEffect } from 'react';
import { CardContent, FormControl, Grid, Paper, Typography } from '@material-ui/core';
import { InfoTextContainer } from 'containers';
import { RenderButton, Loader, RenderFormikSwitch, RenderFormikCheckbox, RenderFormikInput, AuthManager } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './styles.module.scss';

export default memo(() => {
	const dispatch = useDispatch();

	const loading = useSelector(state => state.myaccount && state.myaccount.loadingLenderSettings);
	const lenderSettingsData = useSelector(state => state.myaccount && state.myaccount.lenderSettingsData);

	const formik = useFormik({
		initialValues: Object.assign(
			{
				generation: {
					active: false,
					isPDFInvoiceSyncActive: false,
					isCSVInvoiceSyncActive: false,
					isDebtorFileSyncActive: false,
					isCDRSyncActive: false
				},
				sftp: {
					active: false
				}
			},
			lenderSettingsData
		),
		onSubmit: (values, { resetForm }) => {
			dispatch({
				type: 'myaccount/SET_LENDER_SETTINGS_DATA',
				payload: values
			});
			resetForm();
		},
		validationSchema: Yup.object().shape({
			// participantName: Yup.string().required('Please input company name'),
		}),
		enableReinitialize: true
	});

	useEffect(() => {
		dispatch({ type: 'myaccount/GET_LENDER_SETTINGS_DATA' });
	}, []);

	return (
		<div className={styles.container}>
			<Grid container spacing={1}>
				<Grid item xs={12} md={8}>
					<Paper>
						{(loading && <Loader loading />) || ''}
						<CardContent>
							<form onSubmit={formik.handleSubmit}>
								<Typography variant="h1" gutterBottom>
									Invoice and CDR Synchronization
								</Typography>
								<Grid container alignItems="flex-end">
									<Grid item xs={4}>
										<FormControl component="fieldset">
											<RenderFormikSwitch label="Activate generation" name="generation.active" formik={formik} disabled={formik.values.sftp.active} />
										</FormControl>
									</Grid>
								</Grid>
								<Grid container alignItems="flex-end">
									<Grid item xs={12}>
										<Typography variant="body2" gutterBottom>
											Select synchronization data
										</Typography>
									</Grid>
									<Grid item xs={4}>
										<FormControl component="fieldset">
											<RenderFormikCheckbox label="Invoice in PDF format" name="generation.isPDFInvoiceSyncActive" formik={formik} />
										</FormControl>
									</Grid>
									<Grid item xs={4}>
										<FormControl component="fieldset">
											<RenderFormikCheckbox label="Invoice in CSV format" name="generation.isCSVInvoiceSyncActive" formik={formik} />
										</FormControl>
									</Grid>
									<Grid item xs={4}>
										<FormControl component="fieldset">
											<RenderFormikCheckbox label="Debtor file" name="generation.isDebtorFileSyncActive" formik={formik} />
										</FormControl>
									</Grid>
									<Grid item xs={4}>
										<FormControl component="fieldset">
											<RenderFormikCheckbox label="CDRs" name="generation.isCDRSyncActive" formik={formik} />
										</FormControl>
									</Grid>
								</Grid>
								<Grid container alignItems="flex-end">
									<Grid item xs={4}>
										<Typography variant="h1" gutterBottom>
											SFTP Configuration
										</Typography>
									</Grid>
									<Grid item xs={8}>
										<FormControl component="fieldset">
											<RenderFormikSwitch
												label="Enable Synchronization"
												name="sftp.active"
												formik={formik}
												onChange={() => {
													if (!formik.values.generation.active) {
														formik.setFieldValue('generation.active', true);
													}
												}}
											/>
										</FormControl>
									</Grid>
								</Grid>
								<Grid container alignItems="flex-end">
									<Grid item xs={4}>
										<RenderFormikInput label="Hostname" name="sftp.credentials.host" formik={formik} disabled={!formik.values.sftp.active} />
									</Grid>
									<Grid item xs={4}>
										<RenderFormikInput label="Port" name="sftp.credentials.port" formik={formik} disabled={!formik.values.sftp.active} />
									</Grid>
									<Grid item xs={4}>
										<RenderFormikInput label="Remote Path" name="sftp.credentials.remotePath" formik={formik} disabled={!formik.values.sftp.active} />
									</Grid>
									<Grid item xs={4}>
										<RenderFormikInput label="Username" name="sftp.credentials.username" formik={formik} disabled={!formik.values.sftp.active} />
									</Grid>
									<Grid item xs={4}>
										<RenderFormikInput
											type="password"
											peek
											label="Password"
											name="sftp.credentials.pwd"
											formik={formik}
											disabled={!formik.values.sftp.active}
										/>
									</Grid>
								</Grid>
								<Grid container justify="center" spacing={4}>
									<Grid item>
										<br />
										<AuthManager permissions={['myaccount.lender.update-settings']}>
											<RenderButton type="submit" color="primary" disabled={formik.isSubmitting} onClick={formik.handleSubmit}>
												Update settings
											</RenderButton>
										</AuthManager>
									</Grid>
								</Grid>
							</form>
						</CardContent>
					</Paper>
				</Grid>
				<Grid item xs={12} md={4}>
					<InfoTextContainer page="My Account" section="Lender Settings" />
				</Grid>
			</Grid>
		</div>
	);
});
