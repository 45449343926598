import React from 'react';
import moment from 'moment';
import { Box, Card, CardContent, Zoom, Typography } from '@material-ui/core';

import colors from 'assets/variables/colors';
import { containerStyle, bubbleStyle, triangleStyle, dateStyle } from './styles';

const Triangle = props => {
	return (
		<div style={triangleStyle(props.thread, props.position)}>
			<svg viewBox="0 0 539 240" width={110} height={40} fill="none" {...props}>
				<path
					fill={props.position !== 'before' ? colors.primary.main : '#ffffff'}
					d="M274.696 237.562a8 8 0 0 1-10.392 0L3.232 14.583C-2.421 9.755.993.5 8.427.5h522.145c7.435 0 10.849 9.255 5.196 14.083L274.696 237.562Z"
				/>
			</svg>
		</div>
	);
};

export default function MessageBubble({ thread }) {
	const { summary, createdTime } = thread || {};
	const endOfmessage = summary.indexOf('From:');
	const message = endOfmessage === -1 ? summary : summary.slice(0, endOfmessage);

	return (
		<Zoom in={true}>
			<Box style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
				<Typography variant="caption" style={dateStyle(thread)}>
					{moment(createdTime).calendar()}
				</Typography>
				<Box style={containerStyle(thread)}>
					<Triangle position="before" thread={thread} />
					<Card style={bubbleStyle({ thread })}>
						<CardContent>
							<Typography>{`${message}`}</Typography>
						</CardContent>
					</Card>
					<Triangle position="after" thread={thread} />
				</Box>
			</Box>
		</Zoom>
	);
}
