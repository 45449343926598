class Util {
	static getHexaDecimal(data) {
		return Number(String(data).padEnd(6, '0')).toString(16);
	}

	static isEmpty(obj) {
		if (obj == null) return true;
		if (obj.length === undefined) {
			if (typeof obj === 'number') return false;
			return !(Object.keys(obj).length > 0);
		}
		if (obj.length > 0) return false;
		if (obj.length === 0) return true;
		if (typeof obj === 'number') return false;
		if (typeof obj !== 'object') return true;
		return true;
	}

	static getMaximumPaymentTerms(data) {
		return !Util.isEmpty(data.maximumPaymentTerms) && String(data.maximumPaymentTerms).includes('net')
			? Number(
					String(data.maximumPaymentTerms)
						.replace('net', '')
						.trim()
			  ) * data.dailyAllocatedAmount
			: '';
	}

	static isErrorResponseInterconnection(dataResult) {
		return Util.isErrorResponse(dataResult);
	}

	static isErrorResponseNotifications(dataResult) {
		return Util.isErrorResponse(dataResult);
	}

	static isErrorResponseWallets(dataResult) {
		return Util.isErrorResponse(dataResult);
	}

	static isErrorResponseBidding(dataResult) {
		return Util.isErrorResponse(dataResult);
	}

	static isErrorResponse(dataResult) {
		return (
			(typeof dataResult === 'string' && (String(dataResult).includes('502 Bad') || String(dataResult).includes('404 Not found'))) ||
			(dataResult.hasOwnProperty('status') &&
				(dataResult.status === 'BAD' || dataResult.status === 'Error' || dataResult.status === 500 || dataResult.status === 400 || dataResult.status === 401)) ||
			(dataResult.hasOwnProperty('message') &&
				(String(dataResult.message).includes('Endpoint request timed out') ||
					String(dataResult.message).includes('Network Error') ||
					String(dataResult.message).includes('Network error communicating with endpoint')))
		);
	}

	static isFunder(user) {
		if (user !== undefined && user !== null && user.roles !== undefined && user.roles !== null) {
			return !user.roles.indexOf(CONFIG_PORTAL.acountTypeFunder);
		}
		return false;
	}
}
export default Util;
