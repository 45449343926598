import React, { memo, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { RenderFormikInput, RenderFormikSelect, RenderButton } from 'components';
import { useSelector } from 'react-redux';
import {useTranslation} from "react-i18next";
import styles from './styles.module.scss';

const GeneralTab = memo(({ formik }) => {
	const { t } = useTranslation('routing/routing');
	const hardwareProfileOptions = useSelector(state => (state.routes && state.routes.hardwareProfileOptions) || {});

	const triggerRemoveIPAddress = useCallback(
		index => {
			formik.setFieldValue(
				'ipAddresses',
				formik.values.ipAddresses.filter((ip, i) => i !== index)
			);
		},
		[JSON.stringify(formik.values.ipAddresses)]
	);

	const triggerAddIPAddress = useCallback(() => {
		formik.values.ipAddresses.push({
			ip: '',
			netmask: '32',
			port: '5060'
		});
		formik.setFieldValue('ipAddresses', formik.values.ipAddresses);
	}, [JSON.stringify(formik.values.ipAddresses)]);

	return (
		<div className={styles.container}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<RenderFormikInput label={t('profile_name')} name="name" formik={formik} />
				</Grid>
				<Grid item xs={12} md={2}>
					<RenderFormikSelect
						disabled
						formik={formik}
						label={t('voIP_protocol')}
						name="voipProtocolId"
						options={(hardwareProfileOptions && hardwareProfileOptions.voipProtocols) || []}
					/>
				</Grid>
				<Grid item xs={12} md={8}>
					<RenderFormikSelect disabled formik={formik} label={t('codecs')} name="codecs" multiple options={(hardwareProfileOptions && hardwareProfileOptions.codecs) || []} />
				</Grid>
				<Grid item xs={12} md={1}>
					<RenderFormikSelect disabled formik={formik} label="DTMF" name="dtmf" options={(hardwareProfileOptions && hardwareProfileOptions.dtmfOptions) || []} />
				</Grid>
				<Grid item xs={12} md={1}>
					<RenderFormikInput disabled label={t('dial_string_format')} name="dialStringFormat" formik={formik} />
				</Grid>

				<Grid item xs={12} md={12}>
					{formik.values.ipAddresses.map((ipaddress, i) => (
						<div className="ip-address-wrap" key={i}>
							<Grid container spacing={2}>
								<Grid item xs={4}>
									<RenderFormikInput label={t('ip_address')} name={`ipAddresses.${i}.ip`} formik={formik} />
								</Grid>
								<Grid item xs={2}>
									<RenderFormikInput type="number" label={t('netmask')} name={`ipAddresses.${i}.netmask`} formik={formik} />
								</Grid>
								<Grid item xs={2}>
									<RenderFormikInput type="number" label={t('port')} name={`ipAddresses.${i}.port`} formik={formik} />
								</Grid>
								<Grid item xs={4}>
									{(i > 0 && (
										<RenderButton color="danger" onClick={() => triggerRemoveIPAddress(i)}>
											{t('delete')}
										</RenderButton>
									)) ||
										''}
									{(i < 1 && (
										<RenderButton color="primary" onClick={() => triggerAddIPAddress(i)}>
											{t('add_ip_address')}
										</RenderButton>
									)) ||
										''}
								</Grid>
							</Grid>
						</div>
					))}
				</Grid>

			</Grid>
		</div>
	);
});

export default GeneralTab;
