import React, { memo, useEffect } from 'react';
import { Loader } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { SellingRoutesList, BuyingRoutesList } from "./components"
import styles from './styles.module.scss';

const RoutesSmartContractsList = memo(({ filters }) => {
	const dispatch = useDispatch();

	const data = useSelector(state => (state.routes && state.routes.list) || []);
	const isLoading = useSelector(state => (state.routes && state.routes.loading) || false);

	useEffect(() => {
		dispatch({
			type: 'routes/GET_LIST',
			payload: {
				filterType: filters.contractType,
				filters
			}
		});

		return () => {
			dispatch({
				type: 'routes/SET_STATE',
				payload: {
					list: []
				}
			});
		};
	}, [filters]);

	return (
		<div className={styles.container}>
			{(isLoading && <Loader loading />) || (
				<div className="routes-list">
					{filters.contractType === "selling" && (<SellingRoutesList data={data} />) || (<BuyingRoutesList data={data} />)}
				</div>
			)}
		</div>
	);
});

export default RoutesSmartContractsList;
