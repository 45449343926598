import React, { memo } from 'react';
import { RenderTable } from 'components';
import { useTranslation } from 'react-i18next';

const ReportLender = memo(props => {
	const { tableData, isLoading } = props;
	const { t } = useTranslation('reports/general');

	const columns = [
		{
			Header: t('report.borrower'),
			accessor: 'borrower'
		},
		{
			Header: t('report.advanced_to_sc'),
			headerClassName: 'rt-text-right',
			className: 'rt-text-right',
			accessor: 'advanced',
			Cell: data => `$${Number(parseFloat(data.value).toFixed(0)).toLocaleString('en')}`,
			Footer: table => {
				let total = 0;
				table.data.map(obj => {
					total += parseFloat(obj.advanced);
				});
				return <strong>${Number(total.toFixed(0)).toLocaleString('en')}</strong>;
			}
		},
		{
			Header: t('report.funds_used'),
			headerClassName: 'rt-text-right',
			className: 'rt-text-right',
			accessor: 'fundsUsed',
			Cell: data => `$${Number(parseFloat(data.value).toFixed(0)).toLocaleString('en')}`,
			Footer: table => {
				let total = 0;
				table.data.map(obj => {
					total += parseFloat(obj.fundsUsed);
				});
				return <strong>${Number(total.toFixed(0)).toLocaleString('en')}</strong>;
			}
		},
		{
			Header: t('report.funancing_fee'),
			headerClassName: 'rt-text-right',
			className: 'rt-text-right',
			accessor: 'interestRate',
			Cell: data => `${Number(parseFloat(data.value).toFixed(0)).toLocaleString('en')}%`
		},
		{
			Header: t('report.interest_earned'),
			headerClassName: 'rt-text-right',
			className: 'rt-text-right',
			accessor: 'interestEarned',
			Cell: data => `$${Number(parseFloat(data.value).toFixed(0)).toLocaleString('en')}`,
			Footer: table => {
				let total = 0;
				table.data.map(obj => {
					total += parseFloat(obj.interestEarned);
				});
				return <strong>${Number(total.toFixed(0)).toLocaleString('en')}</strong>;
			}
		}
	];

	return <RenderTable minRows={2} loading={isLoading} data={tableData} columns={columns} filterable={false} style={{ maxHeight: 500 }} />;
});

export default ReportLender;
