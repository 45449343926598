import React, { useCallback, useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import { FinancialContext } from 'screens/Financials';
import { RenderTable } from 'components';
import InvoiceLoader from 'components/atoms/InvoiceLoader';
import useGetColumns from './hooks/useGetColumns';
import DailyInvoicesDetail from './DailyInvoicesDetail';
import moment from 'moment';

export default ({ invoices, contractType }) => {
	const { t, clientType } = useContext(FinancialContext);
	const { originationColumns, standardColumns } = useGetColumns(clientType);
	const [subInvoices, setSubInvoices] = useState(null);
	const [expanded, setExpanded] = useState({});
	const paymentInfo = useSelector(state => state?.reports?.INVOICE_REPORT_DATA?.paymentInfo);
	const dispatch = useDispatch();

	const title = {
		origination: 'invoices.origination_sc_invoices',
		standard: 'invoices.standard_sc_invoices'
	};

	const loading = invoices[contractType].loading;
	const invoicesFound = invoices[contractType].data && invoices[contractType].data instanceof Array && invoices[contractType].data.length !== 0;

	const columns = {
		origination: originationColumns,
		standard: standardColumns
	};

	const getTdProps = useCallback((state, rowInfo) => ({
		onClick: (e, handleOriginal) => {
			const { original } = rowInfo;
			const path = rowInfo.nestingPath[0];
			const diff = { [path]: !expanded[path] };
			const currencyRate = original.exchangeRate;
			setExpanded(diff);
			const localSubInvoices =
				original &&
				original.subInvoices &&
				original.subInvoices.map(sinv => {
					const { invoice, usageSummary } = sinv;
					return {
						startDate: moment(invoice.DATE_START, 'YYYYMMDD').format('MM-DD-YYYY'),
						endDate: moment(invoice.DATE_END, 'YYYYMMDD').format('MM-DD-YYYY'),
						billingCycle: t('invoices.table.daily'),
						dueDate: original.dueDate,
						invoiceDate: moment(invoice.DATE_START, 'YYYYMMDD').format('MM-DD-YYYY'),
						amount: invoice.TOT_AMOUNT,
						...(currencyRate
							? {
									exchange: {
										conversion: currencyRate.conversion,
										rate: currencyRate.rate
									}
							  }
							: {}),
						total: invoice.AMOUNTDUE - invoice.TOTBALFWD,
						taxes: invoice.TOT_PERCT,
						contractId: original.contractId,
						lateFees: 0,
						paid: 0,
						outstandingTotal: invoice.AMOUNTDUE,
						status: t('invoices.table.pending_payments'),
						invoice,
						usageSummary
					};
				});

			setSubInvoices(localSubInvoices);
			if (handleOriginal) {
				handleOriginal();
			}
		}
	}));

	useEffect(() => {
		dispatch({ type: 'reports/GET_INVOICES', payload: { type: clientType, isOrigination: contractType === 'origination', invoicesCached: invoices.invoicesCached } });
	}, [clientType]);

	useEffect(() => {
		if (paymentInfo?.status === 'success') {
			setExpanded({});
		}
	}, [paymentInfo]);
	return (
		<React.Fragment>
			<Typography variant="h2">{t(title[contractType])}</Typography>
			{!loading && invoicesFound ? (
				<RenderTable
					filterable
					forceSingleExpand
					isExpandable
					data={invoices[contractType].data}
					columns={columns[contractType]}
					defaultSorted={[
						{
							id: 'startDate',
							desc: true
						}
					]}
					expanded={expanded}
					SubComponent={row => <DailyInvoicesDetail row={row} subInvoices={subInvoices} contractType={contractType} />}
					onPageChange={() => setExpanded({})}
					getTdProps={getTdProps}
				/>
			) : (
				<InvoiceLoader loading={loading} invoicesFound={invoicesFound} />
			)}
		</React.Fragment>
	);
};
