import React,{Component} from "react";

class SupportTessPay extends Component{
 
    render(){
        return(
    <div className="row">
    <div className="col-md-12">
      <div className="card">
        <div className="card-body">No content.</div>
      </div>
    </div>
  </div>

);
    }
}
export default SupportTessPay;
