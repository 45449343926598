import actions, {
	BANKSTATES,
	BENEFICIARYSTATES,
	BUSINESS_TYPE,
	CHANGE_PASSWORD_ACTION_POST_DATA_REQUEST,
	CLEAR_LENDER_SETTINGS_MESSAGES,
	CORRESPONDENTSTATES,
	COUNTRIES,
	GET_BANKSTATES_ACTION_POST_DATA_RESPONSE,
	GET_BENEFICIARYSTATES_ACTION_POST_DATA_RESPONSE,
	GET_BUSINESS_TYPE_ACTION_POST_DATA_RESPONSE,
	GET_CODECS_ACTION_POST_DATA_RESPONSE,
	GET_CORRESPONDENTSTATES_ACTION_POST_DATA_RESPONSE,
	GET_COUNTRIES_ACTION_POST_DATA_RESPONSE,
	GET_HARDWARE_PROFILE_OPTIONS_ACTION_POST_DATA_RESPONSE,
	GET_PHONEPREFIX_ACTION_POST_DATA_RESPONSE,
	GET_STATES_ACTION_POST_DATA_RESPONSE,
	HARDWARE_CODECS,
	HARDWARE_PROFILE_OPTIONS,
	LENDER_SETTINGS_ERROR,
	LENDER_SETTINGS_POST_DATA_REQUEST,
	LENDER_SETTINGS_SUCCESS,
	MFAQR,
	MYACCOUNT_ACTION_GET_DATA_REQUEST,
	MYACCOUNT_ACTION_GET_DATA_RESPONSE,
	MYACCOUNT_ACTION_POST_DATA_REQUEST,
	MYACCOUNT_ACTION_POST_DATA_RESPONSE,
	MYACCOUNT_ACTION_UPDATE,
	MYACCOUNT_MFA_QR_CODE_GET_DATA_PNG,
	PHONEPREFIX,
	POST_RESPONSE,
	STATES
} from './actions';

import { EXTERNAL_CLIENT_DATA, LENDER_SETTINGS_DATA, lenderSettingsInitialState } from './selectors';

const initialState = {
	loading: false,
	loadingBankAccounts: false,
	loadingUserAccounts: false,
	loadingSecurity: false,
	loadingPlatformAgreement: false,
	loadingFinancingAgreement: false,
	loadingContractAgreements: false,
	loadingLenderSettings: false,
	loadingACPPlatformAgreement: false,
	loadingACPFinancingAgreement: false,
	loadingLegalDocs: false,

	countries: [],
	states: [],
	allStates: [],
	currencies: [],
	bankAccounts: [],
	businessTypes: [],
	permissions: [],
	userAccounts: [],
	accountData: {},
	lenderSettingsData: {},
	createUserSuccess: false,
	updateUserSuccess: false,
	platformAgreementStatus: false,
	financingAgreementStatus: false,
	acpPlatformAgreementStatus: false,
	acpFinancingAgreementStatus: false,
	contractAgreementsList: [],
	legalDocumentsList: [],

	apiKeys: [],

	accountMfaActive: false,
	accountMfaQRCodeData: {}
};

export default function(state = initialState, action) {
	switch (action.type) {
		case CLEAR_LENDER_SETTINGS_MESSAGES:
			return {
				...state,
				[LENDER_SETTINGS_DATA]: {
					...state[LENDER_SETTINGS_DATA],
					info: false,
					error: false
				}
			};
		case LENDER_SETTINGS_POST_DATA_REQUEST:
			return {
				...state,
				[LENDER_SETTINGS_DATA]: {
					...action.payload,
					isLoading: true
				}
			};
		case LENDER_SETTINGS_SUCCESS:
			return {
				...state,
				[LENDER_SETTINGS_DATA]: {
					...lenderSettingsInitialState,
					info: action.payload.info,
					error: false,
					generation: {
						...lenderSettingsInitialState.generation,
						...action.payload.generation
					},
					sftp: {
						...lenderSettingsInitialState.sftp,
						...action.payload.sftp
					}
				}
			};
		case LENDER_SETTINGS_ERROR:
			return {
				...state,
				[LENDER_SETTINGS_DATA]: {
					...state[LENDER_SETTINGS_DATA],
					isLoading: false,
					info: false,
					error: action.payload
				}
			};
		case MYACCOUNT_ACTION_POST_DATA_REQUEST:
			return {
				...state,
				[MYACCOUNT_ACTION_POST_DATA_REQUEST]: {
					payload: action.payload,
					status: 'sending'
				}
			};
		case MYACCOUNT_ACTION_POST_DATA_RESPONSE:
			return {
				...state,
				[MYACCOUNT_ACTION_POST_DATA_RESPONSE]: {
					payload: action.payload,
					status: 'finished'
				}
			};
		case MYACCOUNT_ACTION_GET_DATA_REQUEST:
			return {
				...state,
				[MYACCOUNT_ACTION_GET_DATA_REQUEST]: {
					payload: action.payload,
					status: 'sending'
				}
			};
		case MYACCOUNT_ACTION_GET_DATA_RESPONSE:
			return {
				...state,
				[MYACCOUNT_ACTION_GET_DATA_RESPONSE]: {
					payload: action.payload,
					status: 'finished'
				}
			};
		case GET_COUNTRIES_ACTION_POST_DATA_RESPONSE:
			return {
				...state,
				[COUNTRIES]: {
					payload: action.payload,
					status: 'finished'
				}
			};
		case MYACCOUNT_ACTION_UPDATE:
			return {
				...state,
				MYACCOUNT_ACTION_GET_DATA_RESPONSE: {
					...state.MYACCOUNT_ACTION_GET_DATA_RESPONSE,
					payload: {
						...state.MYACCOUNT_ACTION_GET_DATA_RESPONSE.payload,
						participant: {
							...state.MYACCOUNT_ACTION_GET_DATA_RESPONSE.payload.participant,
							phonePrefixId: action.payload
						}
					}
				}
			};
		case GET_CODECS_ACTION_POST_DATA_RESPONSE:
			return {
				...state,
				[HARDWARE_CODECS]: {
					payload: action.payload,
					status: 'finished'
				}
			};
		case GET_HARDWARE_PROFILE_OPTIONS_ACTION_POST_DATA_RESPONSE:
			return {
				...state,
				[HARDWARE_PROFILE_OPTIONS]: {
					payload: action.payload,
					status: 'finished'
				}
			};
		case GET_STATES_ACTION_POST_DATA_RESPONSE:
			return {
				...state,
				[STATES]: {
					payload: action.payload,
					status: 'finished'
				}
			};
		case GET_BANKSTATES_ACTION_POST_DATA_RESPONSE:
			return {
				...state,
				[BANKSTATES]: {
					payload: action.payload,
					status: 'finished'
				}
			};
		case GET_BENEFICIARYSTATES_ACTION_POST_DATA_RESPONSE:
			return {
				...state,
				[BENEFICIARYSTATES]: {
					payload: action.payload,
					status: 'finished'
				}
			};
		case GET_CORRESPONDENTSTATES_ACTION_POST_DATA_RESPONSE:
			return {
				...state,
				[CORRESPONDENTSTATES]: {
					payload: action.payload,
					status: 'finished'
				}
			};
		case GET_BUSINESS_TYPE_ACTION_POST_DATA_RESPONSE:
			return {
				...state,
				[BUSINESS_TYPE]: {
					payload: action.payload,
					status: 'finished'
				}
			};
		case POST_RESPONSE:
			return {
				...state,
				[POST_RESPONSE]: {
					payload: action.payload,
					status: 'finished'
				}
			};
		case GET_PHONEPREFIX_ACTION_POST_DATA_RESPONSE:
			return {
				...state,
				[PHONEPREFIX]: {
					payload: action.payload,
					status: 'finished'
				}
			};
		case MYACCOUNT_MFA_QR_CODE_GET_DATA_PNG:
			return {
				...state,
				[MFAQR]: {
					payload: action.payload,
					status: 'finished'
				}
			};
		case CHANGE_PASSWORD_ACTION_POST_DATA_REQUEST:
			return {
				...state,
				[CHANGE_PASSWORD_ACTION_POST_DATA_REQUEST]: {
					payload: action.payload,
					status: 'sending'
				}
			};
		case 'SET_EXTERNAL_CLIENTS':
			return {
				...state,
				[EXTERNAL_CLIENT_DATA]: {
					...action.payload
				}
			};
		case actions.SET_STATE:
			return { ...state, ...action.payload };
		default:
			return state;
	}
}
