import React from "react";
import MUIDataTable from "mui-datatables";
import { EditUserForm, AddUserForm, RenderButton } from "components";

const TableUsers = props => {

  const { loggedInUser } = props.account;

  const { handleNewUser } = props;

  const data = {
    result: {
      items: props.users
    }
  };

  const data2 = {
    result: {
      items:props.admins
    }
  };

  const tableColumns = [
    {
      name: "Name",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "Email",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "Role",
      options: {
        filter: true,
        sort: true
      }
    },
    // {
    //   name: "Updated at",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender: (value, tableMeta, updateValue) =>
    //       moment(value).format("MMM DD YYYY")
    //   }
    // },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        customBodyRender: () => { 
          if(loggedInUser.isAdmin === true) {
            return <EditUserForm />;
          }
        }
      }
    }
  ];

  const tableData = [];
  const tableData2 = [];

  if (data && data.result && data.result.items) {
    data.result.items.map(row => {
      tableData.push([
        row.name,
        row.email,
        row.role,
        row.createdAt,
        row.updatedAt,
        ""
      ]);
      return tableData;
    });
    data2.result.items.map(row => {
      tableData2.push([
        row.name,
        row.email,
        row.role,
        row.createdAt,
        row.updatedAt,
        ""
      ]);
      return tableData2;
    });
  }

  const tableOptions = {
    selectableRows: false,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    responsive: "scroll",
    rowsPerPage: 8
  };

  const tableOptions2 = {
    selectableRows: false,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    responsive: "scroll",
    rowsPerPage: 2
  };

  return (
    tableData && (
      <div>
        <p>
          <AddUserForm 
          onSubmit={handleNewUser}
          />
          <br />          
        </p>

        <MUIDataTable
          title="Administrators"
          data={tableData2}
          columns={tableColumns}
          options={tableOptions2}
        />
        <br />
        <MUIDataTable
          title="Users"
          data={tableData}
          columns={tableColumns}
          options={tableOptions}
        />
      </div>
    )
  );
};

export default TableUsers;
