import actions, { NOTIFICATIONS } from './actions';

const initialState = {
	notifications: []
};

export default function(state = initialState, action) {
	switch (action.type) {
		case NOTIFICATIONS:
			return {
				...state,
				[NOTIFICATIONS]: {
					...action.payload
				}
			};
		case actions.SET_STATE:
			return { ...state, ...action.payload };
		case actions.SET_NOTIFICATION:
			const check = state.notifications.filter(d => d.message === action.payload.message);
			if (check.length === 0) {
				return {
					...state,
					notifications: [...state.notifications, action.payload]
				};
			}
			return state;
		default:
			return state;
	}
}
