import React, { memo, useEffect } from "react";
import { Grid, Paper, CardContent } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import {
  RenderButton,
  FormikRadioGroup,
  RenderRadio,
  FormikSelect
} from "components";
import { useSelector, useDispatch } from "react-redux";

const RoutesFilterFields = memo(() => {
  const dispatch = useDispatch();

  const {
    setFieldValue,
    submitForm,
    values,
    values: { contractType: filterType }
  } = useFormikContext();

  const isLoading = useSelector(
    state => (state.routes && state.routes.loadingFilters) || false
  );

  const filters = useSelector(
    state => (state.routes && state.routes.filters) || false
  );

  useEffect(() => {
    const {
      contractType: filtertype,
      country,
      destinationType,
      destinationCarrier
    } = values;
    dispatch({
      type: "routes/GET_LIST_FILTERS",
      payload: {
        filterType: filtertype,
        filters: {
          country,
          destinationType,
          destinationCarrier
        }
      }
    });
  }, [values]);

  return (
    <Paper>
      <CardContent>
        <Field
          name="contractType"
          component={FormikRadioGroup}
          inline
          callback={() => {
            setFieldValue("supplier", "");
            setFieldValue("customer", "");
            setFieldValue("country", "");
            setFieldValue("contract", "");
            setFieldValue("destinationType", "");
            setFieldValue("destinationCarrier", "");
            submitForm();
          }}
        >
          <RenderRadio value="selling" label="Selling" />
          <RenderRadio value="buying" label="Buying" />
        </Field>
        <Grid container spacing={8} justify="space-around">
          {filterType === "selling" && (
            <Grid item xs={12} md>
              <Field
                disabled={isLoading}
                name="customer"
                label="Customer"
                component={FormikSelect}
                options={(filters && filters.customers) || []}
              />
            </Grid>
          )}

          {filterType === "buying" && (
            <Grid item xs={12} md>
              <Field
                disabled={isLoading}
                name="supplier"
                label="Supplier"
                component={FormikSelect}
                options={(filters && filters.suppliers) || []}
              />
            </Grid>
          )}

          <Grid item xs={12} md>
            <Field
              disabled={isLoading}
              name="country"
              label="Country"
              component={FormikSelect}
              callback={() => {
                setFieldValue("destinationType", "");
                setFieldValue("destinationCarrier", "");
              }}
              options={(filters && filters.countries) || []}
            />
          </Grid>

          <Grid item xs={12} md>
            <Field
              disabled={isLoading}
              name="destinationType"
              label="Destination Type"
              component={FormikSelect}
              callback={() => setFieldValue("destinationCarrier", "")}
              options={(filters && filters.destTypes) || []}
            />
          </Grid>

          <Grid item xs={12} md>
            <Field
              disabled={isLoading}
              name="destinationCarrier"
              label="Destination Carrier"
              component={FormikSelect}
              options={(filters && filters.destCarriers) || []}
            />
          </Grid>

          <Grid item xs={12} md>
            <Field
              disabled={isLoading}
              name="contract"
              label="Contract"
              component={FormikSelect}
              options={(filters && filters.contracts) || []}
            />
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={8} justify="flex-end">
          <Grid item>
            <RenderButton color="primary" type="submit">
              Search
            </RenderButton>
          </Grid>
        </Grid>
      </CardContent>
    </Paper>
  );
});

export default RoutesFilterFields;
