import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { Loader } from 'components';

const ImpersonateAuthPage = memo(
	({
		match: {
			params: { code }
		}
	}) => {
		const dispatch = useDispatch();

		const [loaded, setLoaded] = useState(false);
		const tokenResponse = useSelector(state => (state.useraccount && state.useraccount.tokenResponse) || false);

		console.log("tokenResponse",tokenResponse);

		if (!code) {
			window.location.href = '/';
		}

		useEffect(() => {
			dispatch({
				type: 'useraccount/VERIFY_IMPERSONATE_CODE',
				payload: code
			});
			dispatch({
				type: 'useraccount/SET_STATE',
				payload: {
					tokenValid: false
				}
			});
			setLoaded(true);
		}, [code]);

		if (loaded && tokenResponse) {
			dispatch({
				type: 'useraccount/IMPERSONATE_LOGIN',
				payload: {
					username: tokenResponse.user,
					code
				}
			});
		}

		return <Loader loading fullscreen />;
	}
);
export default ImpersonateAuthPage;
