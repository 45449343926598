const actions = {
	SET_STATE: 'contracts/SET_STATE',
	GET_SMART_CONTRACTS: 'contracts/GET_SMART_CONTRACTS',
	GET_SMART_CONTRACT: 'contracts/GET_SMART_CONTRACT',
	GET_NEW_SMART_CONTRACT_DATA: 'contracts/GET_NEW_SMART_CONTRACT_DATA',
	CREATE_SMART_CONTRACT: 'contracts/CREATE_SMART_CONTRACT',
	DOWNLOAD_SAMPLE_RATEPLAN: 'contracts/DOWNLOAD_SAMPLE_RATEPLAN',
	SAVE_SMART_CONTRACT_DRAFT: 'contracts/SAVE_SMART_CONTRACT_DRAFT',
	UPDATE_SMART_CONTRACT: 'contracts/UPDATE_SMART_CONTRACT',
	SECOND_SIGNATURE: 'contracts/SECOND_SIGNATURE',
	UPLOAD_RATES_FILE: 'contracts/UPLOAD_RATES_FILE',
	GET_RATE_FILES_DATA: 'contracts/GET_RATE_FILES_DATA',
	DOWNLOAD_RATE_FILE: 'contracts/DOWNLOAD_RATE_FILE',

	GET_PROJECTS: 'contracts/GET_PROJECTS',
	GET_PROJECT: 'contracts/GET_PROJECT',
	CREATE_PROJECT: 'contracts/CREATE_PROJECT',
	DELETE_PROJECT: 'contracts/DELETE_PROJECT',
	UPDATE_PROJECT: 'contracts/UPDATE_PROJECT',
};

export default actions;
