import { API, Auth } from 'aws-amplify';
import axios from 'axios';

const axiosAuthHeader = () =>
	Auth.currentAuthenticatedUser()
		.then(user => {
			const headers = {
				AuthorizationInfo: user.signInUserSession.idToken.jwtToken
			};

			const impersonateCode = window.localStorage.getItem('IMPERSONATE_CODE');
			if (impersonateCode) {
				headers.impersonateCode = impersonateCode;
			}

			return headers;
		})
		.catch(err => console.log(err));

const smartContractsApi = {
	async handleProposedRates(smartContractId, options) {
		return axios.post(`${await API.endpoint('NODE_SERVICE_API')}/contract/${smartContractId}/proposedRate`, options, { headers: await axiosAuthHeader() }).then(response => response.data && response.data.message);
	},

	async upsertSmartContractCarrier(smartContractId) {
		return axios
			.get(`${await API.endpoint('NODE_SERVICE_API')}/contract/${smartContractId}/handleCarrier`, { headers: await axiosAuthHeader() })
			.then(response => response.data)
			.catch(err => err);
	},

	async downloadSampleRatePlan() {
		return axios
			.get(`${await API.endpoint('NODE_SERVICE_API')}/contract/sampleRatePlan/download`, {
				responseType: 'blob',
				headers: await axiosAuthHeader()
			})
			.then(response => response.data)
			.catch(err => err);
	},

	async downloadSmartContractRatePlan(smartContractId, ratePlanId) {
		return axios
			.get(`${await API.endpoint('NODE_SERVICE_API')}/contract/${smartContractId}/ratePlan/${ratePlanId}/download`, {
				responseType: 'blob',
				headers: await axiosAuthHeader()
			})
			.then(response => response.data)
			.catch(err => err);
	},

	async getSmartContractRates(smartContractId, options) {
		return axios
			.get(`${await API.endpoint('NODE_SERVICE_API')}/contract/${smartContractId}/rate`, {
				params: {
					options
				},
				headers: await axiosAuthHeader()
			})
			.then(response => (response.data ? response.data : []));
	},

	async getSmartContractRatePlans(smartContractId) {
		return axios
			.get(`${await API.endpoint('NODE_SERVICE_API')}/contract/${smartContractId}/ratePlan`, {
				timeout: 1000 * 60 * 5,
				headers: await axiosAuthHeader()
			})
			.then(response => (response.data ? response.data : []))
			.catch(err => err);
	},

	async assignRatePlanToSmartContract(smartContractId) {
		const instance = axios.create({
			baseURL: await API.endpoint('NODE_SERVICE_API')
		});
		return instance
			.post(`/contract/${smartContractId}/assignRatePlan`, null, {
				timeout: 1000 * 60 * 5,
				headers: await axiosAuthHeader()
			})
			.then(response => response.data);
	},

	async uploadSmartContractImmediateRates(smartContractId, file) {
		const fd = new FormData();
		fd.append('ratesFile', file, file.name);
		fd.append('id', smartContractId);

		return axios
			.post(`${await API.endpoint('NODE_SERVICE_API')}/contract/immediateRatePlan`, fd, {headers: await axiosAuthHeader()})
			.then(response => response.data)
			.catch(err => err);
	},

	async uploadSmartContractRates(tempSmartContractId, file, effDate) {
		const fd = new FormData();
		fd.append('ratesFile', file, file.name);
		fd.append('tempId', tempSmartContractId);
		if (effDate) {
			fd.append('effDate', effDate);
		}

		return axios
			.post(`${await API.endpoint('NODE_SERVICE_API')}/contract/ratePlan`, fd, { headers: await axiosAuthHeader() })
			.then(response => response.data)
			.catch(err => err);
	},

	async sendSmartContractRates(smartContractId) {
		return axios
			.post(`${await API.endpoint('NODE_SERVICE_API')}/contract/${smartContractId}/ratePlan/send`, {}, {headers: await axiosAuthHeader()})
			.then(response => response)
			.catch(err => err);
	},

	getCatalogsSmartContract() {
		return API.get('SMARTCONTRACTAPI', '/catalogs', {})
			.then(response => response)
			.catch(err => err);
	},

	getDataNewSmartContract() {
		return API.get('SMARTCONTRACTAPI', '/newSmartContract', {})
			.then(response => response)
			.catch(err => err);
	},

	getNewSmartContractData(data) {
		const request = {
			body: data
		};
		return API.post('NODE_SERVICE_API', '/contract/newSmartContract', request)
			.then(response => response)
			.catch(err => err);
	},

	getDestinationsType(country) {
		return API.get('SMARTCONTRACTAPI', `/country/${country}/destinationTypes`, {})
			.then(response => response)
			.catch(err => err);
	},

	getDestinationCarrierCountry(country) {
		return API.get('SMARTCONTRACTAPI', `/country/${country}/destinationCarrier`, {})
			.then(response => response)
			.catch(err => err);
	},

	getDestinationCarrierType(country, destinationType) {
		return API.get('SMARTCONTRACTAPI', `/country/${country}/${destinationType}/destinationCarrier`, {})
			.then(response => response)
			.catch(err => err);
	},

	getDialCodes(country, destinationType, destinationCarrier) {
		if (destinationType === undefined) {
			destinationType == '';
		}
		if (destinationCarrier === undefined) {
			destinationCarrier == '';
		}
		const init = {
			body: {
				countryId: country,
				destinationType,
				destinationCarrier
			}
		};
		return API.post('SMARTCONTRACTAPI', '/codes', init)
			.then(response => response)
			.catch(err => err);
	},

	getIpAddressParticipant(idUser) {
		const init = {
			body: {
				customerId: idUser
			}
		};
		return API.post('SMARTCONTRACTAPI', '/ipParticipant', init)
			.then(response => response)
			.catch(err => err);
	},

	createNewSmartContract(dataSmartContract) {
		const init = {
			body: {
				...dataSmartContract
			}
		};

		return API.post('SMARTCONTRACTAPI', '/storeSmartContract', init)
			.then(response => response)
			.catch(err => err);
	},

	updateSmartContractRoutingDetails(smartContractId, dataSmartContract) {
		const init = {
			body: {
				...dataSmartContract
			}
		};

		return API.post('NODE_SERVICE_API', `/contract/${smartContractId}/update-routing-settings`, init)
			.then(response => response)
			.catch(err => err);
	},

	updateNewSmartContract(smartContractId, dataSmartContract) {
		const init = {
			body: {
				...dataSmartContract
			}
		};

		return API.post('SMARTCONTRACTAPI', `/updateSmartContract/${smartContractId}`, init)
			.then(response => response)
			.catch(err => err);
	},

	getCatalogs() {
		return API.get('SMARTCONTRACTAPI', '/catalogsSmartContract', {})
			.then(response => response)
			.catch(err => err);
	},

	getFunders() {
		return API.get('SMARTCONTRACTAPI', '/funders', {})
			.then(response => response)
			.catch(err => err);
	},

	getBiddingPortal(smartContractId) {
		return API.get('SMARTCONTRACTAPI', `/${smartContractId}/biddingPortal`, {})
			.then(response => response)
			.catch(err => err);
	},

	getBuyContractsExcept(smartContractId) {
		return API.get('SMARTCONTRACTAPI', `/buyContracts/except/${smartContractId}`, {})
			.then(response => response)
			.catch(err => err);
	},

	saveFunder(data) {
		const init = {
			body: {
				...data
			}
		};

		return API.post('SMARTCONTRACTAPI', '/save/funder', init)
			.then(response => response)
			.catch(err => err);
	},

	saveBiddingPortal(data) {
		const init = {
			body: {
				...data
			}
		};

		return API.post('SMARTCONTRACTAPI', '/save/biddingPortal', init)
			.then(response => response)
			.catch(err => err);
	},

	saveAllocate(data) {
		const init = {
			body: {
				...data
			}
		};

		return API.post('SMARTCONTRACTAPI', '/allocate', init)
			.then(response => response)
			.catch(err => err);
	},

	sign(smartContractId) {
		return API.get('SMARTCONTRACTAPI', `/sign/${smartContractId}`, {})
			.then(response => response)
			.catch(err => err);
	},
	//
	// getSmartContract(smartContractId) {
	//   return API.get("SMARTCONTRACTAPI", `/edit/${smartContractId}`, {})
	//     .then(response => response)
	//     .catch(err => err);
	// },
	getSmartContract(smartContractId) {
		return API.get('NODE_SERVICE_API', `/contract/${smartContractId}/edit`, {})
			.then(response => response)
			.catch(err => err);
	},

	getSmartContractCountDataRequest() {
		return API.get('SMARTCONTRACTAPI', '/smartContractCount', {})
			.then(response => response)
			.catch(err => err);
	},
	getSmartContractAddressDataRequest() {
		return API.get('SMARTCONTRACTAPI', '/smartContractAddress', {})
			.then(response => response)
			.catch(err => err);
	},
	getSmartContractFundingOffers(contractId) {
		return API.get('NODE_SERVICE_API', `/funding-offers/${contractId}`, {})
			.then(response => response)
			.catch(err => err);
	},
	smartContractAllocateFunds(data) {
		const postData = {
			body: data
		};
		return API.post('NODE_SERVICE_API', '/contract/allocate', postData)
			.then(response => response)
			.catch(err => err);
	},
	smartContractManageFundingOffers(offerId, data) {
		const postData = {
			body: data
		};
		return API.post('NODE_SERVICE_API', `/funding-offers/offer/${offerId}/manage`, postData)
			.then(response => response)
			.catch(err => err);
	},
	smartContractGetWalletDetails(contractId) {
		return API.get('NODE_SERVICE_API', `/contract/${contractId}/wallet-details`)
			.then(response => response)
			.catch(err => err);
	},

	//// new endpoint
	getSmartContracts() {
		return API.get('CONTRACT_SERVICE_API', '/', {})
			.then(response => response)
			.catch(err => err);
	},
	terminate(smartContractId) {
		return API.del('CONTRACT_SERVICE_API', `/${smartContractId || ''}`, {})
			.then(response => response)
			.catch(err => err);
	},
	saveUpdateContractName(contractId, data) {
		const postData = {
			body: data
		};
		return API.post('CONTRACT_SERVICE_API', `/${contractId}/name`, postData)
			.then(response => response)
			.catch(err => err);
	},

	async getContract(smartContractId) {
		return API.get('CONTRACT_SERVICE_API', `/${smartContractId || ''}`, {})
			.then(response => response)
			.catch(err => err);
	},

	async getProjects() {
		return API.get('CONTRACT_SERVICE_API', '/projects', {})
			.then(response => response)
			.catch(err => err);
	},
	async getProject(projectId) {
		return API.get('CONTRACT_SERVICE_API', `/projects/${projectId}`, {})
			.then(response => response)
			.catch(err => err);
	},
	async createProject(payload) {
		return API.post('CONTRACT_SERVICE_API',`/projects`, { body: payload })
			.then(response => response)
			.catch(err => err);
	},
	async deleteProject(projectId) {
		return API.del('CONTRACT_SERVICE_API', `/projects/${projectId}`, {})
			.then(response => response)
			.catch(err => err);
	},
	async updateProject(payload) {
		return API.put('CONTRACT_SERVICE_API',`/projects/${payload.id}`,  { body: payload })
			.then(response => response)
			.catch(err => err);
	},
	async getSampleRateplan() {
		return API.get('CONTRACT_SERVICE_API', '/rateplan/sample/download', { responseType: 'blob' })
			.then(res => {
				return res;
			})
			.catch(err => {
				console.error(err);
				return err;
			});
	},
	async getNewSmartContractDataNew() {
		return API.get('CONTRACT_SERVICE_API', '/create')
			.then(response => response)
			.catch(err => err);
	},
	async uploadRatesFile(data) {
		return axios
			.post('https://api.tpio.us/v1/contract/ratePlan', data, { headers: await axiosAuthHeader() })
			.then(response => response.data)
			.catch(err => err);
	},
	async deleteRatePlan(data) {
		return API.del('CONTRACT_SERVICE_API', `/ratePlan/${data}`)
			.then(response => response.data)
			.catch(err => err);
	},
	async deleteRatePlansByTempId(tempId) {
		return API.del('CONTRACT_SERVICE_API', `/ratePlans/${tempId}`)
			.then(response => response)
			.catch(err => err);
	},
	async saveSmartContractDraft(data) {
		return API.post('CONTRACT_SERVICE_API', '/saveDraft', { body: data })
			.then(res => res.data)
			.catch(err => {
				console.error(err);
				return err;
			});
	},
	async createContract(data) {
		return API.post('CONTRACT_SERVICE_API', '/create', { body: data })
			.then(res => res.data)
			.catch(err => {
				console.error(err);
				return err;
			});
	},

	async updateSmartContract(data) {
		return API.patch('CONTRACT_SERVICE_API', `/${data._id}`, { body: data })
			.then(res => res.data)
			.catch(err => {
				console.error(err);
				return err;
			});
	}
};

export default smartContractsApi;
