export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const MFA_SUCCESS = 'MFA_SUCCESS';
export const MFA_FAILURE = 'MFA_FAILURE';
export const CLEAN_DATA_LOGIN = 'CLEAN_DATA_LOGIN';
export const MFA_REQUEST = 'MFA_REQUEST';
export const SET_LOGIN_LOADING = 'SET_LOGIN_LOADING';

export const loginRequest = (userName, password) => ({
	type: LOGIN_REQUEST,
	payload: { userName: userName, password: password }
});

export const mfaRequest = data => ({
	type: MFA_REQUEST,
	payload: data
});
export const loginFailure = errorMessage => ({
	type: LOGIN_FAILURE,
	payload: errorMessage
});

export const loginSuccess = successMessage => ({
	type: LOGIN_SUCCESS,
	payload: successMessage
});

export const mfaSuccess = successMessage => ({
	type: MFA_SUCCESS,
	payload: successMessage
});

export const mfaFailure = errorMessage => ({
	type: MFA_FAILURE,
	payload: errorMessage
});

export const cleanDataLogin = () => ({
	type: CLEAN_DATA_LOGIN
});
