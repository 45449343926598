import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { PublicTemplate } from "components";
import { VerificationCodeContainer } from "containers";
import {Grid} from "@material-ui/core";
import verificationStyle from "./style";

const VerificationCodePage = ({...props}) => {
  const { classes } = props;
  return (
    <PublicTemplate>
      <Grid container justify="center" alignItems="center" className={classes.content}>
          <Grid item>
          <VerificationCodeContainer />
          </Grid>
      </Grid>
  </PublicTemplate>
  );
}

export default withStyles(verificationStyle)(VerificationCodePage);
