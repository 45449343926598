const styles = theme => ({
	messageDate: {
	  display: "block",
	  fontSize: "10px",
	  textAlign: "right",
	  marginBottom: "5px",
	  fontWeight: "bold"
	},
	label: {
	  color: theme.palette.grey.light,
	  fontSize: "12px"
	},
	grey: {
	  color: theme.palette.grey.light
	},
	divider: {
	  margin: "20px 0",
	  opacity: "0.1"
	},
	lendingMessage: {
	  root: {
		fontSize: "18px"
	  }
	}
  });
  
  export default styles;
  