import {
  SUCCESS_BLOCKS_REQUEST,
  EXPLORER_SMART_CONTRACTS_ACTION_GET_DATA_RESPONSE,
  EXPLORER_WALLET_ACTION_GET_DATA_RESPONSE,
  STATUS_EXPLORER_INIT_BLOCKS_POLLING,
  EXPLORER_SMART_CONTRACTS_ACTION_GET_DATA_REQUEST
} from "./actions";

const getNumberOfBlocksPerDay = blocks => {
  if (blocks && blocks !== "not authenticated") {
    const grouping = blocks.reduce(
      (acc, block) => {
        const date = new Date(block.timestamp * 1000).toLocaleDateString();
        acc[date] = acc[date] ? acc[date] + 1 : 1;
        acc.total += 1;
        return acc;
      },
      { total: 0 }
    );
    return (grouping.total / Object.keys(grouping).length).toFixed(1);
  }
  return undefined;
};

export default function func(state = {}, action) {
  const { payload } = action;
  switch (action.type) {
    case SUCCESS_BLOCKS_REQUEST:
      return {
        ...state,
        SUCCESS_BLOCKS_REQUEST: {
          isLoadingBlocks: false,
          latestBlocks: payload,
          numberOfBlocksPerDay: getNumberOfBlocksPerDay(payload)
        }
      };
    case EXPLORER_SMART_CONTRACTS_ACTION_GET_DATA_REQUEST:
      return {
        ...state,
        EXPLORER_SMART_CONTRACTS: {
          ...state.EXPLORER_SMART_CONTRACTS,
          status: "loading"
        }
      };
    case EXPLORER_SMART_CONTRACTS_ACTION_GET_DATA_RESPONSE:
      return {
        ...state,
        EXPLORER_SMART_CONTRACTS: {
          ...state.EXPLORER_SMART_CONTRACTS,
          DATA: {
            ...state.EXPLORER_SMART_CONTRACTS.DATA,
            [payload.address]: payload
          },
          status: "finished"
        }
      };
    case EXPLORER_WALLET_ACTION_GET_DATA_RESPONSE:
      return {
        ...state,
        [EXPLORER_WALLET_ACTION_GET_DATA_RESPONSE]: {
          payload: action.payload,
          status: "finished"
        }
      };

    case STATUS_EXPLORER_INIT_BLOCKS_POLLING:
      return {
        ...state,
        [STATUS_EXPLORER_INIT_BLOCKS_POLLING]: {
          payload: action.payload
        }
      };
    default:
      return state;
  }
}
