import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { FinancialContext } from 'screens/Financials';
import moment from 'moment';
import { RenderTransactionAmount, RenderBadge } from 'components';
import { IntlProvider, FormattedNumber, FormattedDate, FormattedTime } from 'react-intl';

export default filters => {
	const { t } = useContext(FinancialContext);

	const { currency } = useSelector(state => {
		return state.settings.PLATFORM_SETTINGS;
	});

	const role = useSelector(state => state?.myaccount?.accountData?.role);

	const columns = [
		{
			Header: 'Traffic date',
			accessor: 'trafficDate',
			Cell: ({ original: transaction }) =>
				(transaction?.trafficDateStr && moment.utc(transaction?.trafficDateStr).format('MMM DD, YYYY')) ||
				(transaction?.trafficDate && moment.utc(transaction.trafficDate).format('MMM DD, YYYY')) ||
				'',
			width: 150
		},
		{
			Header: 'Posting date',
			accessor: 'createdAt',
			Cell: ({ original: transaction }) =>
				(transaction?.transactionDateStr && moment.utc(transaction?.transactionDateStr).format('MMM DD, YYYY')) ||
				(transaction?.createdAt && moment.utc(transaction.createdAt).format('MMM DD, YYYY')) ||
				'',
			width: 150
		},
		{
			Header: t('table.details'),
			accessor: 'description',
			Cell: ({ original: transaction }) => (
				<div
					style={{
						overflow: 'auto',
						whiteSpace: 'normal'
					}}
				>
					{transaction?.description}
				</div>
			),
			width: 280
		},
		{
			Header: t('table.partner'),
			accessor: 'partner',
			Cell: ({ original: transaction }) => transaction.paymentTo || ''
		},
		{
			Header: <div className="rt-text-right">{t('table.amount')}</div>,
			className: 'rt-text-right',
			accessor: '',
			Cell: ({ original: transaction }) => <RenderTransactionAmount amount={parseFloat(transaction.fiatAmount || 0)} />,
			width: 200
		},
		{
			Header: <div className="rt-text-right">{filters ? 'Total' : `${t('table.running_balance')}`}</div>,
			className: 'rt-text-right',
			accessor: '',
			Cell: ({ original: transaction }) =>
				transaction?.balance && parseFloat(transaction.balance) < 0 ? (
					<RenderBadge color="danger">
						<FormattedNumber value={Math.abs(transaction.balance)} style="currency" currency={currency?.currencyName || 'USD'} />
					</RenderBadge>
				) : (
					<RenderBadge color="success">
						<FormattedNumber value={(transaction && transaction.balance) || 0} style="currency" currency={currency?.currencyName || 'USD'} />
					</RenderBadge>
				),
			width: 200
		}
	];

	if (role == 'funder') {
		const funderColumns = [
			{
				Header: 'Customer Name',
				accessor: 'buyername',
				Cell: ({ original: transaction }) => transaction.buyerName || ''
			},
			{
				Header: 'Invoice Number',
				accessor: 'invoiceNumber',
				Cell: ({ original: transaction }) => transaction.invoiceNumber || ''
			},
			{
				Header: 'Due Date',
				accessor: 'invoiceDueDate',
				Cell: ({ original: transaction }) => (transaction?.invoiceDueDate && moment.utc(transaction.invoiceDueDate).format('MMM DD, YYYY')) || ''
			}
		];
		columns.splice(4, 0, ...funderColumns);
	}

	return columns;
};
