import { CardContent, CircularProgress, Paper, Typography } from '@material-ui/core';
import { CurrencyNumber, RenderButton, RenderTable } from 'components';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import TableCustomerInvoicesStyle from './style';
import { withTranslation } from 'react-i18next';

class TableCustomerInvoices extends Component {
	constructor(props) {
		super(props);
		this.subInvoices = [];
		this.standardSubInvoices = [];
	}

	componentWillMount() {
		const { loadOriginationCustomerInvoices, loadStandardCustomerInvoices } = this.props;
		loadOriginationCustomerInvoices();
		loadStandardCustomerInvoices();
	}

	showLoader = (origination = true) => {
		const subType = origination ? 'origination' : 'standard';
		const { data } = this.props;

		if (data && data[subType] && data[subType].loading) {
			return (
				<div>
					<Typography variant="body1" align="center">
						<CircularProgress color="secondary" size={20} />
						<span>&nbsp;&nbsp;&nbsp;Loading invoices ...</span>
					</Typography>
				</div>
			);
		}
		if (!data || !data[subType] || !data[subType].data || !data[subType].data.length) {
			return (
				<div>
					<Typography variant="body1" align="center">
						No invoices found
					</Typography>
				</div>
			);
		}
		return false;
	};

	render() {
		const {
			t,
			handleInvoiceView,
			handleOriginationExpansion,
			handleStandardExpansion,
			originationExpanded,
			standardExpanded,
			classes,
			data: { origination, standard }
		} = this.props;

		const columns = [
			{
				columns: [
					{
						expander: true,
						width: 65,
						Expander: ({ isExpanded }) => <div>{isExpanded ? <KeyboardArrowDown color="primary" /> : <KeyboardArrowRight color="primary" />}</div>,
						style: {
							cursor: 'pointer',
							fontSize: 25,
							padding: '0',
							textAlign: 'center',
							userSelect: 'none'
						}
					}
				]
			},
			{
				Header: t('invoices.table.start_date'),
				accessor: 'startDate',
				Cell: props => moment(props.value, 'MM-DD-YYYY').format('MMM DD YYYY'),
				maxWidth: 100,
				sortMethod: (a, b) => {
					if (moment(a, 'MM-DD-YYYY').isSame(moment(b, 'MM-DD-YYYY'))) {
						return 0;
					}
					return moment(a, 'MM-DD-YYYY').isSameOrAfter(moment(b, 'MM-DD-YYYY')) ? 1 : -1;
				}
			},
			{
				Header: t('invoices.table.end_date'),
				accessor: 'endDate',
				Cell: props => (props.value === '' ? 'Open' : moment(props.value, 'MM-DD-YYYY').format('MMM DD YYYY')),
				maxWidth: 100,
				sortMethod: (a, b) => {
					if (a === 'Open' || b === 'Open') {
						return 0;
					}
					if (moment(a, 'MM-DD-YYYY').isSame(moment(b, 'MM-DD-YYYY'))) {
						return 0;
					}
					return moment(a, 'MM-DD-YYYY').isSameOrAfter(moment(b, 'MM-DD-YYYY')) ? 1 : -1;
				}
			},
			{
				Header: t('invoices.table.customer'),
				accessor: 'masterInvoice.NAME',
				Cell: props => props.value || 'None',
				maxWidth: 200
			},
			{
				Header: t('invoices.table.contract_id'),
				accessor: 'contractId',
				maxWidth: 215
			},
			{
				Header: t('invoices.table.billing_cycle'),
				accessor: 'billingCycle',
				maxWidth: 90
			},
			{
				Header: t('invoices.table.due_date'),
				accessor: 'dueDate',
				Cell: props => moment(props.value, 'MM-DD-YYYY').format('MMM DD YYYY'),
				maxWidth: 100,
				sortMethod: (a, b) => {
					if (moment(a, 'MM-DD-YYYY').isSame(moment(b, 'MM-DD-YYYY'))) {
						return 0;
					}
					return moment(a, 'MM-DD-YYYY').isSameOrAfter(moment(b, 'MM-DD-YYYY')) ? 1 : -1;
				}
			},
			{
				Header: t('invoices.table.inv_date'),
				accessor: 'invoiceDate',
				Cell: props => moment(props.value, 'MM-DD-YYYY').format('MMM DD YYYY'),
				maxWidth: 100,
				sortMethod: (a, b) => {
					if (moment(a, 'MM-DD-YYYY').isSame(moment(b, 'MM-DD-YYYY'))) {
						return 0;
					}
					return moment(a, 'MM-DD-YYYY').isSameOrAfter(moment(b, 'MM-DD-YYYY')) ? 1 : -1;
				}
			},
			{
				Header: t('invoices.table.amount'),
				accessor: 'amount',
				Cell: props => <CurrencyNumber amount={props.value} />,
				maxWidth: 100
			},
			{
				Header: t('invoices.table.taxes'),
				accessor: 'taxes',
				Cell: props => (props.value && <CurrencyNumber amount={props.value} />) || '',
				maxWidth: 80
			},
			{
				Header: t('invoices.table.total'),
				accessor: 'total',
				Cell: props => <CurrencyNumber amount={props.value} />,
				maxWidth: 100
			}
		];

		const columns2 = [
			{
				Header: t('invoices.table.inv_date'),
				accessor: 'endDate',
				Cell: props =>
					moment(props.value, 'MM-DD-YYYY')
						.add(1, 'day')
						.format('MMM DD YYYY'),
				maxWidth: 100,
				sortMethod: (a, b) => {
					if (moment(a, 'MM-DD-YYYY').isSame(moment(b, 'MM-DD-YYYY'))) {
						return 0;
					}
					return moment(a, 'MM-DD-YYYY').isSameOrAfter(moment(b, 'MM-DD-YYYY')) ? 1 : -1;
				}
			},
			{
				Header: t('invoices.table.traffic_date'),
				accessor: 'endDate',
				Cell: props => moment(props.value, 'MM-DD-YYYY').format('MMM DD YYYY'),
				maxWidth: 100,
				sortMethod: (a, b) => {
					if (moment(a, 'MM-DD-YYYY').isSame(moment(b, 'MM-DD-YYYY'))) {
						return 0;
					}
					return moment(a, 'MM-DD-YYYY').isSameOrAfter(moment(b, 'MM-DD-YYYY')) ? 1 : -1;
				}
			},
			{
				Header: t('invoices.table.due_date'),
				accessor: 'dueDate',
				Cell: props => moment(props.value, 'MM-DD-YYYY').format('MMM DD YYYY'),
				maxWidth: 100,
				sortMethod: (a, b) => {
					if (moment(a, 'MM-DD-YYYY').isSame(moment(b, 'MM-DD-YYYY'))) {
						return 0;
					}
					return moment(a, 'MM-DD-YYYY').isSameOrAfter(moment(b, 'MM-DD-YYYY')) ? 1 : -1;
				}
			},
			{
				Header: t('invoices.table.amount'),
				accessor: 'amount',
				Cell: props => <CurrencyNumber amount={props.value} />,
				maxWidth: 100
			},
			{
				Header: t('invoices.table.taxes'),
				accessor: 'taxes',
				Cell: props => (props.value && <CurrencyNumber amount={props.value} />) || '',
				maxWidth: 80
			},
			{
				Header: t('invoices.table.total'),
				accessor: 'total',
				Cell: props => <CurrencyNumber amount={props.value} />,
				maxWidth: 100
			}
		];

		const standardContractColumns = [
			{
				columns: [
					{
						expander: true,
						width: 65,
						Expander: ({ isExpanded }) => <div>{isExpanded ? <KeyboardArrowDown color="primary" /> : <KeyboardArrowRight color="primary" />}</div>,
						style: {
							cursor: 'pointer',
							fontSize: 25,
							padding: '0',
							textAlign: 'center',
							userSelect: 'none'
						}
					}
				]
			},
			{
				Header: t('invoices.table.start_date'),
				accessor: 'startDate',
				Cell: props => moment(props.value, 'MM-DD-YYYY').format('MMM DD YYYY'),
				maxWidth: 100,
				sortMethod: (a, b) => {
					if (moment(a, 'MM-DD-YYYY').isSame(moment(b, 'MM-DD-YYYY'))) {
						return 0;
					}
					return moment(a, 'MM-DD-YYYY').isSameOrAfter(moment(b, 'MM-DD-YYYY')) ? 1 : -1;
				}
			},
			{
				Header: t('invoices.table.end_date'),
				accessor: 'endDate',
				Cell: props => (props.value === '' ? 'Open' : moment(props.value, 'MM-DD-YYYY').format('MMM DD YYYY')),
				maxWidth: 100,
				sortMethod: (a, b) => {
					if (moment(a, 'MM-DD-YYYY').isSame(moment(b, 'MM-DD-YYYY'))) {
						return 0;
					}
					return moment(a, 'MM-DD-YYYY').isSameOrAfter(moment(b, 'MM-DD-YYYY')) ? 1 : -1;
				}
			},
			{
				Header: t('invoices.table.customer'),
				accessor: 'masterInvoice.customer.name',
				Cell: props => props.value || 'None',
				maxWidth: 120
			},
			{
				Header: t('invoices.table.contract_id'),
				accessor: 'contractId',
				maxWidth: 215
			},
			{
				Header: t('invoices.table.billing_cycle'),
				accessor: 'billingCycle',
				maxWidth: 90
			},
			{
				Header: t('invoices.table.amount'),
				accessor: 'amount',
				Cell: props => <CurrencyNumber amount={props.value} />,
				maxWidth: 100
			},
			{
				Header: t('invoices.table.taxes'),
				accessor: 'taxes',
				Cell: props => (props.value && <CurrencyNumber amount={props.value} />) || '',
				maxWidth: 80
			},
			{
				Header: t('invoices.table.total'),
				accessor: 'total',
				Cell: props => <CurrencyNumber amount={props.value} />,
				maxWidth: 100
			}
		];
		const standardContractColumns2 = columns2.filter(col => ['dueDate'].indexOf(col.accessor) === -1);

		return (
			<Paper>
				<CardContent>
					<Typography variant="h1" gutterBottom>
						{t('invoices.customer_inv')}
					</Typography>
					<Typography variant="h2">{t('invoices.origination_sc_invoices')}</Typography>
					{this.showLoader() || (
						<RenderTable
							filterable
							forceSingleExpand
							isExpandable
							data={origination.data}
							columns={columns}
							defaultSorted={[
								{
									id: 'startDate',
									desc: true
								}
							]}
							expanded={originationExpanded}
							SubComponent={row => (
								<CardContent>
									<div className={classes.titleContainer}>
										<Typography variant="h1" align="left">
											{t('invoices.daily_invoices')}
										</Typography>
										<div className={classes.viewMaster}>
											<RenderButton
												color="primary"
												onClick={evt =>
													handleInvoiceView(
														evt,
														'customer',
														row &&
															row.original && {
																...row.original.masterInvoice,
																dueDate: row.original.dueDate
															},
														row && row.original && row.original.usageSummary,
														true
													)
												}
											>
												{t('invoices.view_master_invoice')}
											</RenderButton>
										</div>
									</div>
									<RenderTable
										data={this.subInvoices}
										columns={columns2}
										defaultSorted={[
											{
												id: 'endDate',
												desc: true
											}
										]}
										getTrProps={(state, rowInfo) => ({
											onClick: evt =>
												handleInvoiceView(
													evt,
													'customer',
													rowInfo &&
														rowInfo.original && {
															...rowInfo.original.invoice,
															dueDate: rowInfo.original.dueDate
														},
													rowInfo && rowInfo.original && rowInfo.original.usageSummary
												),
											style: {
												cursor: 'pointer'
											}
										})}
									/>
								</CardContent>
							)}
							onPageChange={() => handleOriginationExpansion({})}
							getTdProps={(state, rowInfo) => ({
								onClick: (e, handleOriginal) => {
									const { original } = rowInfo;
									const path = rowInfo.nestingPath[0];
									const diff = { [path]: !originationExpanded[path] };
									handleOriginationExpansion(diff);
									const subInvoices =
										original &&
										original.subInvoices &&
										original.subInvoices.map(sinv => {
											const { invoice, usageSummary } = sinv;
											return {
												startDate: moment(invoice.DATE_START, 'YYYYMMDD').format('MM-DD-YYYY'),
												endDate: moment(invoice.DATE_END, 'YYYYMMDD').format('MM-DD-YYYY'),
												billingCycle: t('invoices.table.daily'),
												dueDate: original.dueDate,
												invoiceDate: moment(invoice.DATE_START, 'YYYYMMDD').format('MM-DD-YYYY'),
												amount: invoice.TOT_AMOUNT,
												total: invoice.AMOUNTDUE - invoice.TOTBALFWD,
												taxes: invoice.TOT_PERCT,
												contractId: original.contractId,
												lateFees: 0,
												paid: 0,
												outstandingTotal: invoice.AMOUNTDUE,
												status: t('invoices.table.pending_payments'),
												invoice,
												usageSummary
											};
										});
									this.subInvoices = subInvoices;
									if (handleOriginal) {
										handleOriginal();
									}
								}
							})}
						/>
					)}

					<Typography variant="h2">{t('invoices.standard_sc_invoices')}</Typography>

					{this.showLoader(false) || (
						<RenderTable
							filterable
							forceSingleExpand
							isExpandable
							data={standard.data}
							columns={standardContractColumns}
							defaultSorted={[
								{
									id: 'startDate',
									desc: true
								}
							]}
							expanded={standardExpanded}
							SubComponent={row => (
								<CardContent>
									<div className={classes.titleContainer}>
										<Typography variant="h1" align="left">
											{t('invoices.daily_invoices')}
										</Typography>
										<div className={classes.viewMaster}>
											<RenderButton
												color="primary"
												onClick={evt =>
													handleInvoiceView(
														evt,
														'customer',
														row &&
															row.original && {
																...row.original.masterInvoice,
																dueDate: row.original.dueDate
															},
														row && row.original && row.original.usageSummary,
														true,
														true
													)
												}
											>
												{t('invoices.view_master_invoice')}
											</RenderButton>
										</div>
									</div>
									<RenderTable
										data={this.standardSubInvoices}
										columns={standardContractColumns2}
										defaultSorted={[
											{
												id: 'endDate',
												desc: true
											}
										]}
										getTrProps={(state, rowInfo) => ({
											onClick: evt =>
												handleInvoiceView(
													evt,
													'customer',
													rowInfo &&
														rowInfo.original && {
															...rowInfo.original.invoice,
															dueDate: rowInfo.original.dueDate
														},
													rowInfo && rowInfo.original && rowInfo.original.usageSummary,
													false,
													true
												),
											style: {
												cursor: 'pointer'
											}
										})}
									/>
								</CardContent>
							)}
							onPageChange={() => handleStandardExpansion({})}
							getTdProps={(state, rowInfo) => ({
								onClick: (e, handleOriginal) => {
									const { original } = rowInfo;
									const path = rowInfo.nestingPath[0];
									const diff = { [path]: !standardExpanded[path] };
									handleStandardExpansion(diff);
									const subInvoices =
										original &&
										original.subInvoices &&
										original.subInvoices.map(sinv => {
											const { invoice, usageSummary } = sinv;
											return {
												startDate: moment(invoice.DATE_START, 'YYYYMMDD').format('MM-DD-YYYY'),
												endDate: moment(invoice.DATE_END, 'YYYYMMDD').format('MM-DD-YYYY'),
												billingCycle: t('invoices.table.daily'),
												dueDate: original.dueDate,
												invoiceDate: moment(invoice.DATE_START, 'YYYYMMDD').format('MM-DD-YYYY'),
												amount: invoice.TOT_AMOUNT,
												total: invoice.AMOUNTDUE,
												taxes: invoice.TOT_PERCT,
												contractId: original.contractId,
												lateFees: 0,
												paid: 0,
												outstandingTotal: invoice.AMOUNTDUE,
												status: t('invoices.table.pending_payments'),
												invoice,
												usageSummary
											};
										});
									this.standardSubInvoices = subInvoices;
									if (handleOriginal) {
										handleOriginal();
									}
								}
							})}
						/>
					)}
				</CardContent>
			</Paper>
		);
	}
}

TableCustomerInvoices.propTypes = {
	loadOriginationCustomerInvoices: PropTypes.func.isRequired,
	loadStandardCustomerInvoices: PropTypes.func.isRequired,
	classes: PropTypes.shape().isRequired,
	data: PropTypes.shape().isRequired
};

const TableCustomerInvoicesTranslated = withTranslation(['financials/general', 'translations'])(TableCustomerInvoices);

export default withStyles(TableCustomerInvoicesStyle)(TableCustomerInvoicesTranslated);
