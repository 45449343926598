import React from 'react';
import { PrivateTemplate, BreadCrumb } from 'components';
import MyAccountScreen from 'screens/MyAccount';
import { useTranslation } from 'react-i18next';

const MyAccountPage = props => {
	const { t } = useTranslation();
	return (
		<PrivateTemplate>
			<BreadCrumb name="Account" firstLevel="Home" secondLevel={t('my_account')} />
			<MyAccountScreen {...props} />
		</PrivateTemplate>
	);
};

export default MyAccountPage;
