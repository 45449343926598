import React, { memo, useCallback, useEffect, useState } from 'react';
import { CardContent, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Tab, Typography } from '@material-ui/core';
import { InfoTextContainer } from 'containers';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { RenderButton, Loader, AuthManager } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';

import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

export default memo(() => {
	const dispatch = useDispatch();

	const loadingPlatform = useSelector(state => state.myaccount && state.myaccount.loadingPlatformAgreement);
	const loadingFinancing = useSelector(state => state.myaccount && state.myaccount.loadingFinancingAgreement);
	const loadingACPPlatform = useSelector(state => state.myaccount && state.myaccount.loadingAcpPlatformAgreement);
	const loadingACPFinancing = useSelector(state => state.myaccount && state.myaccount.loadingAcpFinancingAgreement);
	const loadingContractAgreements = useSelector(state => state.myaccount && state.myaccount.loadingContractAgreements);
	const loadingLegalDocs = useSelector(state => state.myaccount && state.myaccount.loadingLegalDocs);
	const platformAgreementStatus = useSelector(state => state.myaccount && state.myaccount.platformAgreementStatus);
	const financingAgreementStatus = useSelector(state => state.myaccount && state.myaccount.financingAgreementStatus);
	const acpPlatformAgreementStatus = useSelector(state => state.myaccount && state.myaccount.acpPlatformAgreementStatus);
	const acpFinancingAgreementStatus = useSelector(state => state.myaccount && state.myaccount.acpFinancingAgreementStatus);
	const contractAgreements = useSelector(state => (state.myaccount && state.myaccount.contractAgreementsList) || []);
	const legalDocuments = useSelector(state => (state.myaccount && state.myaccount.legalDocumentsList) || []);
	const isAcpProgramActive = useSelector(state => (state.myaccount && state.myaccount.accountData?.acpProgram?.active) || false);

	const { t } = useTranslation('myaccount/general_information');

	const initiatePortalAgreementSigning = useCallback(() => {
		dispatch({ type: 'myaccount/GET_PLATFORM_AGREEMENT_INITIATE' });
	}, []);

	const initiateFinancingAgreementSigning = useCallback(() => {
		dispatch({ type: 'myaccount/GET_FINANCING_AGREEMENT_INITIATE' });
	}, []);

	const initiateACPPortalAgreementSigning = useCallback(() => {
		dispatch({ type: 'myaccount/GET_ACP_PLATFORM_AGREEMENT_INITIATE' });
	}, []);

	const initiateACPFinancingAgreementSigning = useCallback(() => {
		dispatch({ type: 'myaccount/GET_ACP_FINANCING_AGREEMENT_INITIATE' });
	}, []);

	const triggerDownloadAgreement = useCallback(guid => {
		dispatch({ type: 'myaccount/GET_CONTRACT_AGREEMENT_BY_GUID', payload: guid });
	}, []);

	const triggerDownloadLegalDocument = useCallback(docId => {
		dispatch({ type: 'myaccount/DOWNLOAD_LEGAL_DOCUMENT', payload: { id: docId } });
	}, []);

	useEffect(() => {
		dispatch({ type: 'myaccount/GET_PLATFORM_AGREEMENT_STATUS' });
		dispatch({ type: 'myaccount/GET_FINANCING_AGREEMENT_STATUS' });
		dispatch({ type: 'myaccount/GET_CONTRACT_AGREEMENTS' });
		dispatch({ type: 'myaccount/GET_LEGAL_DOCUMENTS' });
	}, []);

	useEffect(() => {
		if (isAcpProgramActive) {
			dispatch({ type: 'myaccount/GET_ACP_PLATFORM_AGREEMENT_STATUS' });
			dispatch({ type: 'myaccount/GET_ACP_FINANCING_AGREEMENT_STATUS' });
		}
	}, [isAcpProgramActive]);

	const tableColumns = [
		{
			name: 'partner',
			label: t('name'),
			options: {
				filter: false,
				sort: false
			}
		},
		{
			name: 'signedAt',
			label: t('signature_date'),
			options: {
				filter: false,
				sort: false,
				customBodyRender: date => (date && moment(date).isValid() && moment(date).format('MMM DD YYYY')) || ''
			}
		},
		{
			name: 'guid',
			label: t('agreement'),
			options: {
				filter: false,
				sort: false,
				customBodyRender: guid => (
					<RenderButton color="primary" size="small" onClick={() => triggerDownloadAgreement(guid)}>
						{t('download')}
					</RenderButton>
				)
			}
		}
	];

	const tableOptions = {
		selectableRows: 'none',
		filter: false,
		print: false,
		download: false,
		viewColumns: false,
		responsive: 'scroll',
		rowsPerPage: 10,
		elevation: 1
	};

	return (
		<div className={styles.container}>
			<AuthManager permissions={['myaccount.agreements.view-agreements']}>
				<Grid container spacing={1}>
					<Grid item xs={12} md={8}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={12}>
								<Paper>
									{(loadingPlatform && <Loader loading />) || ''}
									<CardContent>
										<Typography variant="h1" gutterBottom>
											{t('tesspay_platform_services_ageement')}
										</Typography>
										<List>
											<ListItem>
												<ListItemIcon>
													<DownloadIcon />
												</ListItemIcon>
												<ListItemText>
													{t('tesspay_platform_services_ageement')} &nbsp;
													<AuthManager permissions={['myaccount.agreements.download-agreements']}>
														{(platformAgreementStatus && platformAgreementStatus.status === 'COMPLETED' && (
															<RenderButton href={platformAgreementStatus.agreementUrl || '#'} color="danger">
																{t('download')}&nbsp;{t('agreement')}
															</RenderButton>
														)) ||
															''}
													</AuthManager>
													{(platformAgreementStatus && platformAgreementStatus.status === 'ACCOUNT_NOT_READY' && (
														<RenderButton color="danger">{t('please_update_account_information_first')}</RenderButton>
													)) ||
														''}
													<AuthManager permissions={['myaccount.agreements.sign-agreements']}>
														{(((platformAgreementStatus && platformAgreementStatus.status === 'NOT_INITIALIZED') ||
															(platformAgreementStatus && platformAgreementStatus.status === 'PENDING_USER_SIGNATURE')) && (
															<RenderButton color="primary" onClick={initiatePortalAgreementSigning}>
																{t('review_sign_agreement')}
															</RenderButton>
														)) ||
															''}
													</AuthManager>
												</ListItemText>
											</ListItem>
										</List>
									</CardContent>
								</Paper>
							</Grid>

							<Grid item xs={12} md={12}>
								<Paper>
									{(loadingFinancing && <Loader loading />) || ''}
									<CardContent>
										<Typography variant="h1" gutterBottom>
											{t('tesspay_financing_services_agreement')}
										</Typography>
										<List>
											<ListItem>
												<ListItemIcon>
													<DownloadIcon />
												</ListItemIcon>
												<ListItemText>
													{t('tesspay_financing_services_agreement')} &nbsp;
													<AuthManager permissions={['myaccount.agreements.download-agreements']}>
														{(financingAgreementStatus && financingAgreementStatus.status === 'COMPLETED' && (
															<RenderButton href={financingAgreementStatus.agreementUrl || '#'} color="danger">
																{t('download')}&nbsp;{t('agreement')}
															</RenderButton>
														)) ||
															''}
													</AuthManager>
													{(financingAgreementStatus && financingAgreementStatus.status === 'ACCOUNT_NOT_READY' && (
														<RenderButton color="danger">{t('please_update_account_information_first')}</RenderButton>
													)) ||
														''}
													<AuthManager permissions={['myaccount.agreements.sign-agreements']}>
														{(((financingAgreementStatus && financingAgreementStatus.status === 'NOT_INITIALIZED') ||
															(financingAgreementStatus && financingAgreementStatus.status === 'PENDING_USER_SIGNATURE')) && (
															<RenderButton color="primary" onClick={initiateFinancingAgreementSigning}>
																{t('review_sign_agreement')}
															</RenderButton>
														)) ||
															''}
													</AuthManager>
												</ListItemText>
											</ListItem>
										</List>
									</CardContent>
								</Paper>
							</Grid>

							{(isAcpProgramActive && (
								<>
									<Grid item xs={12} md={12}>
										<Paper>
											{(loadingACPPlatform && <Loader loading />) || ''}
											<CardContent>
												<Typography variant="h1" gutterBottom>
													ACP Program Platform Services Agreement
												</Typography>
												<List>
													<ListItem>
														<ListItemIcon>
															<DownloadIcon />
														</ListItemIcon>
														<ListItemText>
															{t('tesspay_platform_services_ageement')} &nbsp;
															<AuthManager permissions={['myaccount.agreements.download-agreements']}>
																{(acpPlatformAgreementStatus && acpPlatformAgreementStatus.status === 'COMPLETED' && (
																	<RenderButton href={acpPlatformAgreementStatus.agreementUrl || '#'} color="danger">
																		{t('download')}&nbsp;{t('agreement')}
																	</RenderButton>
																)) ||
																	''}
															</AuthManager>
															{(acpPlatformAgreementStatus && acpPlatformAgreementStatus.status === 'ACCOUNT_NOT_READY' && (
																<RenderButton color="danger">{t('please_update_account_information_first')}</RenderButton>
															)) ||
																''}
															<AuthManager permissions={['myaccount.agreements.sign-agreements']}>
																{(((acpPlatformAgreementStatus && acpPlatformAgreementStatus.status === 'NOT_INITIALIZED') ||
																	(acpPlatformAgreementStatus && acpPlatformAgreementStatus.status === 'PENDING_USER_SIGNATURE')) && (
																	<RenderButton color="primary" onClick={initiateACPPortalAgreementSigning}>
																		{t('review_sign_agreement')}
																	</RenderButton>
																)) ||
																	''}
															</AuthManager>
														</ListItemText>
													</ListItem>
												</List>
											</CardContent>
										</Paper>
									</Grid>
									<Grid item xs={12} md={12}>
										<Paper>
											{(loadingACPFinancing && <Loader loading />) || ''}
											<CardContent>
												<Typography variant="h1" gutterBottom>
													ACP Program Financing Services Agreement
												</Typography>
												<List>
													<ListItem>
														<ListItemIcon>
															<DownloadIcon />
														</ListItemIcon>
														<ListItemText>
															{t('tesspay_financing_services_agreement')} &nbsp;
															<AuthManager permissions={['myaccount.agreements.download-agreements']}>
																{(acpFinancingAgreementStatus && acpFinancingAgreementStatus.status === 'COMPLETED' && (
																	<RenderButton href={acpFinancingAgreementStatus.agreementUrl || '#'} color="danger">
																		{t('download')}&nbsp;{t('agreement')}
																	</RenderButton>
																)) ||
																	''}
															</AuthManager>
															{(acpFinancingAgreementStatus && acpFinancingAgreementStatus.status === 'ACCOUNT_NOT_READY' && (
																<RenderButton color="danger">{t('please_update_account_information_first')}</RenderButton>
															)) ||
																''}
															<AuthManager permissions={['myaccount.agreements.sign-agreements']}>
																{(((acpFinancingAgreementStatus && acpFinancingAgreementStatus.status === 'NOT_INITIALIZED') ||
																	(acpFinancingAgreementStatus && acpFinancingAgreementStatus.status === 'PENDING_USER_SIGNATURE')) && (
																	<RenderButton color="primary" onClick={initiateACPFinancingAgreementSigning}>
																		{t('review_sign_agreement')}
																	</RenderButton>
																)) ||
																	''}
															</AuthManager>
														</ListItemText>
													</ListItem>
												</List>
											</CardContent>
										</Paper>
									</Grid>
								</>
							)) ||
								null}

							<Grid item xs={12} md={12}>
								<div className="table-wrapper">
									{(loadingContractAgreements && <Loader loading />) || ''}
									<MUIDataTable
										title={t('tesspay_smart_contract_carrier_services_agreements')}
										data={(Array.isArray(contractAgreements) && contractAgreements) || []}
										columns={tableColumns}
										options={tableOptions}
									/>
								</div>
							</Grid>
							<Grid item xs={12} md={12}>
								<div className="table-wrapper">
									{(loadingLegalDocs && <Loader loading />) || ''}
									<MUIDataTable
										title={t('tesspay_legal_documents')}
										data={(Array.isArray(legalDocuments) && legalDocuments) || []}
										columns={[
											{
												name: 'filename',
												label: t('filename'),
												options: {
													filter: false,
													sort: false
												}
											},
											{
												name: 'createdAt',
												label: t('uploadedAt'),
												options: {
													filter: false,
													sort: false,
													customBodyRender: date => moment(date).format('LL')
												}
											},
											{
												name: '_id',
												label: <span style={{ float: 'inline-end' }}>{t('action')}</span>,
												options: {
													filter: false,
													sort: false,
													customBodyRender: _id => (
														<RenderButton color="primary" size="small" onClick={() => triggerDownloadLegalDocument(_id)}>
															{t('download')}
														</RenderButton>
													)
												}
											}
										]}
										options={tableOptions}
									/>
								</div>
								{/*<InfoTextContainer page="My Account" section="Legal Agreements" />*/}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</AuthManager>
		</div>
	);
});
