import React, { memo, useEffect } from 'react';
import { Grid, ButtonGroup } from '@material-ui/core';
import { FileDownload } from '@material-ui/icons';
import { Field, reduxForm, reset, formValueSelector } from 'redux-form';
import { RenderDateTimePicker, RenderInput, RenderSelect, RenderButton, RenderCheckbox } from 'components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getReportsFilters, setReportsFilters } from 'store/actions';
import { fromReports } from 'store/selectors';
import { createValidator, required, validateReportsSmartContract } from 'services/validation';
import { useTranslation } from 'react-i18next';

const selector = formValueSelector('ReportFiltersForm');

const ReportFilters = memo(props => {
	const { t } = useTranslation('reports/general');

	const {
		reportType,
		filterData: { customers, suppliers, countries, destTypes, destCarriers, contracts, filteredContracts },
		filterOptions: { country, destinationType },
		setDownload,
		isLoading
	} = props;

	const handleResetChange = field => {
		props.change(field, '');
	};

	const handleSubmit = data => {
		// eslint-disable-next-line no-shadow
		const { handleSubmit, setDownload } = props;
		handleSubmit(data);
		setDownload(false);
	};

	const showStartDate =
		reportType === 'margin' ||
		reportType === 'lending' ||
		reportType === 'lenderReport' ||
		reportType === 'customerTraffic' ||
		reportType === 'supplierTraffic' ||
		reportType === 'saleAndCost' ||
		reportType === 'callSearch';

	const showEndDate =
		reportType === 'margin' ||
		reportType === 'lending' ||
		reportType === 'lenderReport' ||
		reportType === 'customerTraffic' ||
		reportType === 'supplierTraffic' ||
		reportType === 'saleAndCost' ||
		reportType === 'callSearch';

	// const showStartHour = reportType === "callSearch";
	const showStartHour = false;
	const showEndHour = false;

	const showSmartContract =
		reportType === 'margin' || reportType === 'lending' || reportType === 'lenderReport' || reportType === 'customerTraffic' || reportType === 'supplierTraffic';

	const showDestination =
		// reportType === "margin" ||
		reportType === 'customerTraffic' || reportType === 'supplierTraffic' || reportType === 'saleAndCost';

	const showSupplier =
		// reportType === "margin" ||
		reportType === 'supplierTraffic' || reportType === 'saleAndCost';

	const showCustomer = reportType === 'margin' || reportType === 'lending' || reportType === 'customerTraffic' || reportType === 'saleAndCost';

	// const showCarrier = reportType === 'callSearch';

	const showIncludeUnanswered = reportType === 'callSearch';

	let smartContracts = contracts;
	if (reportType === 'customerTraffic') {
		smartContracts = (filteredContracts && filteredContracts.selling) || [];
	}
	if (reportType === 'supplierTraffic') {
		smartContracts = (filteredContracts && filteredContracts.buying) || [];
	}

	useEffect(() => {
		const { filterOptions } = props;
		props.reset('ReportFiltersForm');
		props.getReportsFilters(filterOptions);
	}, []);

	useEffect(() => {
		// eslint-disable-next-line no-shadow
		const { filterOptions, setReportsFilters, filterData } = props;
		const resetFields = {
			...filterData,
			destTypes: [],
			destCarriers: []
		};
		setReportsFilters(resetFields);
		props.getReportsFilters(filterOptions);
	}, [country, destinationType]);

	return (
		<form onSubmit={handleSubmit}>
			<Grid container spacing={1} justify="space-around">
				{showCustomer && (
					<Grid item xs={12} sm={12} md={3}>
						<Field name="customer" label={t('filters.customer')} component={RenderSelect} options={customers} />
					</Grid>
				)}

				{showSupplier && (
					<Grid item xs={12} sm={12} md={3}>
						<Field name="supplier" label={t('filters.supplier')} component={RenderSelect} options={suppliers} />
					</Grid>
				)}

				{showSmartContract && (
					<Grid item xs={12} md={2}>
						<Field name="smartContract" label={t('filters.smartcontract')} component={RenderSelect} options={smartContracts} />
					</Grid>
				)}

				{reportType === 'callSearch' && (
					<Grid item xs={12} md={3}>
						<Field
							name="smartContract"
							label={t('filters.buying_smartcontract')}
							component={RenderSelect}
							options={(filteredContracts && filteredContracts.buying) || []}
						/>
					</Grid>
				)}

				{reportType === 'callSearch' && (
					<Grid item xs={12} md={3}>
						<Field
							name="smartContract"
							label={t('filters.selling_smartcontract')}
							component={RenderSelect}
							options={(filteredContracts && filteredContracts.selling) || []}
						/>
					</Grid>
				)}

				{showStartDate && showEndDate ? (
					<Grid item xs={12} sm={12} md>
						<Grid container spacing={1}>
							<Grid item xs={6} md={6}>
								<Field
									component={RenderDateTimePicker}
									label={t('filters.start_date')}
									dateFormat="MMM DD YYYY - HH"
									disableFuture
									disableMinutes={false}
									autoOk
									name="startDate"
								/>
							</Grid>
							<Grid item xs={6} md={6}>
								<Field
									component={RenderDateTimePicker}
									label={t('filters.end_date')}
									dateFormat="MMM DD YYYY - HH"
									disableMinutes={false}
									disableFuture
									autoOk
									name="endDate"
								/>
							</Grid>
						</Grid>
					</Grid>
				) : (
					''
				)}

				{showStartDate && !showEndDate ? (
					<Grid item xs={12} md>
						<Field component={RenderDateTimePicker} label={t('filters.start_date')} dateFormat="MMM DD YYYY - HH" disableFuture autoOk name="startDate" />
					</Grid>
				) : (
					''
				)}

				{showStartHour && (
					<Grid item xs={12} md>
						<Field type="number" name="startHour" label={t('filters.start_hour')} component={RenderInput} />
					</Grid>
				)}

				{showEndDate && !showStartDate ? (
					<Grid item xs={12} md>
						<Field component={RenderDateTimePicker} label={t('filters.end_date')} dateFormat="MMM DD YYYY - HH" disableFuture autoOk name="endDate" />
					</Grid>
				) : (
					''
				)}

				{showEndHour && (
					<Grid item xs={12} md>
						<Field type="number" name="endHour" label={t('filters.end_hour')} component={RenderInput} />
					</Grid>
				)}
				{showDestination ? (
					<Grid container spacing={1}>
						<Grid item xs={12} md={4}>
							<Field
								name="country"
								label={t('filters.country')}
								callback={() => {
									handleResetChange('destinationType');
									handleResetChange('destinationCarrier');
								}}
								component={RenderSelect}
								options={countries || []}
							/>
						</Grid>

						<Grid item xs={6} md={3}>
							<Field
								name="destinationType"
								label={t('filters.destination_type')}
								disabled={!country}
								component={RenderSelect}
								callback={() => handleResetChange('destinationCarrier')}
								options={
									destTypes && Array.isArray(destTypes)
										? destTypes.map(option => ({
												value: option && option.value ? option.value : option,
												label: option && option.label ? option.label : option
										  }))
										: []
								}
							/>
						</Grid>

						<Grid item xs={12} md={3}>
							<Field
								name="destinationCarrier"
								label={t('filters.destination_carrier')}
								disabled={!destinationType || !country}
								component={RenderSelect}
								onChange={props.handleChange}
								options={
									destCarriers && Array.isArray(destCarriers)
										? destCarriers.map(option => ({
												value: option && option.value ? option.value : option,
												label: option && option.label ? option.label : option
										  }))
										: []
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={2}
							style={{
								textAlign: 'right',
								paddingTop: 20
							}}
						>
							<ButtonGroup className="report-actions">
								<RenderButton title={t('filters.download')} color="primary" type="submit" onClick={() => setDownload(true)} disabled={isLoading}>
									<FileDownload />
								</RenderButton>
								<RenderButton style={{ width: '100%' }} color="primary" type="submit" disabled={isLoading}>
									{t('filters.submit')}
								</RenderButton>
							</ButtonGroup>
						</Grid>
					</Grid>
				) : (
					''
				)}
			</Grid>
			{!showDestination && (
				<Grid container justify="flex-end" spacing={1}>
					{showIncludeUnanswered ? (
						<Grid
							item
							alignItems="center"
							alignContent="center"
							style={{
								display: 'flex'
							}}
						>
							<Field name="includeUnansweredCalls" label={t('filters.include_unanswered')} component={RenderCheckbox} value="1" />
						</Grid>
					) : (
						''
					)}
					<Grid item>
						<RenderButton
							disabled={isLoading}
							color="primary"
							type="submit"
							style={{
								marginTop: '10px'
							}}
						>
							{t('filters.submit')}
						</RenderButton>
					</Grid>
				</Grid>
			)}
		</form>
	);
});

ReportFilters.defaultProps = {
	filterData: {
		customers: [],
		suppliers: [],
		countries: [],
		destTypes: [],
		destCarriers: [],
		contracts: [],
		filteredContracts: {
			selling: [],
			buying: []
		}
	},
	setDownload: () => {}
};

const mapStateToProps = state => ({
	filterData: fromReports.getReportsFilters(state),
	filterOptions: selector(state, 'country', 'destinationType')
});

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			reset,
			getReportsFilters,
			setReportsFilters
		},
		dispatch
	);
}

const validate = createValidator({
	startDate: [required],
	smartContract: [validateReportsSmartContract],
	endDate: [required]
});

const ReduxReportFilters = reduxForm({
	form: 'ReportFiltersForm',
	destroyOnUnmount: false,
	enableReinitialize: true,
	touchOnBlur: false,
	validate
})(ReportFilters);

export default connect(mapStateToProps, mapDispatchToProps)(ReduxReportFilters);
