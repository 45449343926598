import React from 'react';
import PropTypes from 'prop-types';
import { RenderButton } from 'components';
import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';

const RenderFormikFileUpload = ({ formik: { values, errors, touched, setFieldValue, handleBlur }, name, label, allowedFileTypes, btnText, PreviewComponent, onChange, ...rest }) => {
	let fileInput;

	let hasError = (errors[name] && touched[name] && true) || false;
	let errorMsg = (hasError && !!errors[name] && errors[name]) || '';

	let value = values[name] || '';
	if (name.indexOf('.') > -1) {
		value = name.split('.').reduce((o, i) => (o && o[i]) || '', values);
		const error = name.split('.').reduce((o, i) => (o && o[i]) || '', errors);
		const touchedEl = name.split('.').reduce((o, i) => (o && o[i]) || '', touched);
		hasError = (error && touchedEl && true) || false;
		errorMsg = (hasError && !!error && error) || '';
	}

	const selectedFile = value[0];

	return (
		<FormControl fullWidth error={hasError}>
			{(label && <InputLabel>{label}</InputLabel>) || ''}
			<input
				ref={ref => {
					fileInput = ref;
				}}
				style={{ display: 'none' }}
				type="file"
				onBlur={handleBlur}
				accept={(allowedFileTypes && allowedFileTypes.join(',')) || null}
				// value={values[name] && values[name][0] || ''}
				onChange={evt => {
					setFieldValue(name, evt.target.files);
					if (onChange && typeof onChange === 'function') onChange(evt.target.files);
				}}
			/>
			{(PreviewComponent && (
				<button title="Click here to upload a new file" className="formik-upload-button" type="button" onClick={() => fileInput.click()}>
					{PreviewComponent}
				</button>
			)) || (
				<RenderButton color="primary" onClick={() => fileInput.click()}>
					{btnText || 'Select file'}
				</RenderButton>
			)}
			{selectedFile ? (
				<FormHelperText>
					<b>{selectedFile.name}</b> was selected
				</FormHelperText>
			) : (
				''
			)}
			{hasError && <FormHelperText>{errorMsg}</FormHelperText>}
		</FormControl>
	);
};

RenderFormikFileUpload.defaultProps = {
	label: '',
	btnText: '',
	PreviewComponent: '',
	allowedFileTypes: []
};

RenderFormikFileUpload.propTypes = {
	formik: PropTypes.object.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	btnText: PropTypes.string,
	PreviewComponent: PropTypes.node,
	allowedFileTypes: PropTypes.arrayOf(PropTypes.string)
};

export default RenderFormikFileUpload;
