import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import { RenderField } from "components";

import { Field } from "redux-form";

import {
  RenderInput,
  RenderButton,
  RenderNotification,
  RenderRadioGroup,
  RenderRadio
} from "components";

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  InputAdornment,
  IconButton
} from "@material-ui/core";

import {
  VpnKey,
  Email,
  Visibility,
  VisibilityOff,
  LockOpen
} from "@material-ui/icons";

import verificationStyle from "./style";

class VerificationCodeForm extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    showPassword: false
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  render() {
    const { handleSubmit, pristine, reset, submitting, classes } = this.props;
    return (
      <form onSubmit={handleSubmit} className={classes.form}>
        <Card className={classes.root}>
          <CardHeader
            subheader={
              <img src="/images/logo-light-text.png" alt="Logo TessPay" />
            }
            className={classes.cardHeader}
          />
          <CardContent>
            <Field
              name="email"
              label="E-Mail Address"
              type="email"
              component={RenderInput}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton disabled>
                      <Email />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <Field
              name="code"
              label="Verification code"
              type="text"
              component={RenderInput}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton disabled>
                      <VpnKey />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <Field
              name="password"
              label="Password"
              type={this.state.showPassword ? "text" : "password"}
              component={RenderInput}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <Field
              name="passwordConfirmation"
              label="Confirm Password"
              type={this.state.showPassword ? "text" : "password"}
              component={RenderInput}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <RenderNotification
              variant="danger"
              floating={false}
              open={this.props.info.errorMessage}
              message={this.props.info.errorMessage}
            />
          </CardContent>
          <CardActions>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <RenderButton color="primary" type="submit">
                  Change
                </RenderButton>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </form>
    );
  }
}

VerificationCodeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  info: PropTypes.object.isRequired
};

export default withStyles(verificationStyle)(VerificationCodeForm);
