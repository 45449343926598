import React from "react";
import { PrivateTemplate, BreadCrumb } from "components";
import { SmartContractsEditContainer } from "containers";

const SmartContractEditPage = ({ ...props }) => (
  <PrivateTemplate>
    <BreadCrumb
      name="Editing Smart Contract"
      firstLevel="Home"
      secondLevel="Edit smart contract"
    />
    <SmartContractsEditContainer {...props} />
  </PrivateTemplate>
);
export default SmartContractEditPage;
