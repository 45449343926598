import React from "react";
import { Card } from "reactstrap";

const formatTimestamp = timestamp =>
  new Date(timestamp * 1000).toLocaleString();
const ExplorerWalletItem = props => {
  const {
    data: { blockNumber, hash, timestamp, to, decoded, initialValues }
  } = props;

  const name =
    decoded !== undefined && decoded.name !== undefined ? decoded.name : "";

  const input =
    decoded !== undefined &&
    decoded.params !== undefined &&
    Object.values(decoded.params).length > 0 &&
    decoded.params[0].value !== undefined
      ? decoded.params[0].value
      : "";

  const fiatAmount =
    initialValues !== undefined && initialValues.fiatAmount !== undefined
      ? initialValues.fiatAmount
      : "";

  return (
    <Card className="browser_card">
      <h5 className="explorer-card-title">Block number: # {blockNumber} </h5>
      <div>
        <b>Date: </b> {formatTimestamp(timestamp)}
        <br />
        {to ? (
          <div>
            <b>Transaction type: </b> {name} <br />
            <b>Transaction input: </b> {input} <br />
          </div>
        ) : (
          <div>
            <b>Transaction type: </b> Wallet Creation <br />
            <b>Initial Fiat amount: </b>
            {fiatAmount} <br />
          </div>
        )}
      </div>
    </Card>
  );
};

export default ExplorerWalletItem;
