import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  actionCreatorPreloaderOn,
  actionCreatorPreloaderOff,
  initBlocksPolling,
  actionWalletExplorerGetDataRequest,
  actionMyAccountGetDataRequest,
  statusInitBlocksPolling,
  actionSmartContractAddressGetDataRequest,
  actionSmartContractExplorerGetDataRequest
} from "store/actions";
import _ from "lodash";
import PropTypes from "prop-types";
import { Blocks, ExplorerTabs } from "components";
import {
  fromExplorer,
  fromUseraccount,
  fromNotifications,
  fromSmartcontracts
} from "store/selectors";

class ExplorerContainer extends Component {
  debouncePreloader = _.debounce(() => {
    this.props.actionCreatorPreloaderOff();
  }, 800);

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const {
      initBlocksPolling: polling,
      statusInitBlocksPolling: statusPolling,
      actionCreatorPreloaderOn: preloaderOn,
      notifications,
      actionWalletExplorerGetDataRequest: walletExplorerGetDatarequest,
      actionSmartContractAddressGetDataRequest: smartContractAddressGetDataRequest
    } = this.props;
    statusPolling(true);
    smartContractAddressGetDataRequest({});
    polling();
    preloaderOn();
    this.debouncePreloader();

    const walletAddress =
      notifications !== undefined &&
      notifications !== null &&
      notifications.participant !== undefined &&
      notifications.participant !== null &&
      notifications.participant.walletAddress
        ? notifications.participant.walletAddress
        : "";

    if (walletAddress) {
      walletExplorerGetDatarequest(walletAddress);
    }
  }

  componentWillUnmount() {
    const { statusInitBlocksPolling: statusPolling } = this.props;

    statusPolling(false);
  }

  getSmartContractsExplorer = (address, e) => {
    this.props.actionSmartContractExplorerGetDataRequest(address);
  };

  render() {
    const {
      latestBlocks,
      numberOfBlocksPerDay,
      walletExplorerData,
      smartContractAddressData
    } = this.props;

    return (
      <div className="row">
        <div className="col-md-4">
          <Blocks
            blocks={latestBlocks}
            numberOfBlocksPerDay={numberOfBlocksPerDay}
          />
        </div>
        <div className="col-md-8">
          <div className="card">
            <div className="card-body">
              <ExplorerTabs
                wallet={walletExplorerData}
                smartContracts={smartContractAddressData}
                getSmartContractsExplorer={this.getSmartContractsExplorer}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    latestBlocks: fromExplorer.getExplorerLatestBlocksData(state),
    numberOfBlocksPerDay: fromExplorer.getNumberOfBlocksPerDayData(state),
    walletExplorerData: fromExplorer.getWalletExplorerGetDataResponse(state),
    user: fromUseraccount.getUserInfoDataState(state),
    notifications: fromNotifications.getNotifications(state),
    smartContractAddressData: fromSmartcontracts.getSmartContractAddressData(
      state
    )
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionCreatorPreloaderOn,
      actionCreatorPreloaderOff,
      actionWalletExplorerGetDataRequest,
      actionMyAccountGetDataRequest,
      initBlocksPolling,
      statusInitBlocksPolling,
      actionSmartContractAddressGetDataRequest,
      actionSmartContractExplorerGetDataRequest
    },
    dispatch
  );
}

ExplorerContainer.propTypes = {
  actionCreatorPreloaderOn: PropTypes.func.isRequired,
  actionCreatorPreloaderOff: PropTypes.func.isRequired,
  actionWalletExplorerGetDataRequest: PropTypes.func.isRequired,
  initBlocksPolling: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExplorerContainer);
