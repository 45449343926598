const { middleware: thunkMiddleware } = require("redux-saga-thunk");

const req = require.context(
  ".",
  true,
  /\.\/((?!__mocks__|__snapshots__).)*\/middleware\.js$/
);

module.exports = req
  .keys()
  .map(key => req(key).default)
  .concat([thunkMiddleware]);
