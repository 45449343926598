// New Way
const actions = {
	SET_STATE: 'support/SET_STATE',
	SET_TICKETS_STATE: 'support/SET_TICKETS_STATE',
	SET_THREADS_STATE: 'support/SET_THREADS_STATE',
	GET_TICKETS: 'support/GET_TICKETS',
	GET_THREADS: 'support/GET_THREADS',
	CREATE_TICKET: 'support/CREATE_TICKET'
};

export default actions;
