import { API, Auth } from 'aws-amplify';
import axios from 'axios';

const AcpProgramApi = {
	getAcpCustomerClaim() {
		return API.get('ACP_SERVICE_API', '/claims')
			.then(response => response)
			.catch(err => err);
	},
	uploadClaim(payload) {
		const fileData = new FormData();
		fileData.append('file', payload.claimFile, payload.claimFile.name);
		fileData.append('type', payload.claimType);
		fileData.append('claimPeriod', payload.claimPeriod);
		return Auth.currentSession().then(async session =>
			axios
				.post(`${await API.endpoint('ACP_SERVICE_API')}/claims/upload`, fileData, {
					headers: {
						AuthorizationInfo: session.idToken.jwtToken,
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(response => response)
				.catch(err => {
					throw err.response;
				})
		);
	},

	downloadClaim(data) {
		const { acpClaimId } = data;
		return API.post('ACP_SERVICE_API', `/claims/download/${acpClaimId}`, {
			responseType: 'blob'
		})
			.then(response => response)
			.catch(err => {
				throw err.response;
			});
	},
	downloadNladSubmission(data) {
		const { nladSubmissionId } = data;
		return API.post('ACP_SERVICE_API', `/claims/nlad-submissions/download/${nladSubmissionId}`, {
			responseType: 'blob'
		})
			.then(response => response)
			.catch(err => {
				throw err.response;
			});
	},
	downloadClaimableDeviceTemplate() {
		return API.get('ACP_SERVICE_API', '/claims/download/claimable-device-template', {
			responseType: 'blob'
		})
			.then(response => response)
			.catch(err => {
				throw err.response;
			});
	},
	downloadMrcSalesTemplate() {
		return API.get('ACP_SERVICE_API', '/claims/download/mrc-sales-template', {
			responseType: 'blob'
		})
			.then(response => response)
			.catch(err => {
				throw err.response;
			});
	},
	getAcpInvoices() {
		return API.get('ACP_SERVICE_API', '/acp-invoices')
			.then(response => response)
			.catch(err => {
				throw err.response;
			});
	},
	getAcpInvoice(id) {
		return API.get('ACP_SERVICE_API', `/acp-invoices/${id}`)
			.then(response => response)
			.catch(err => {
				throw err.response;
			});
	},

	deleteClaimById(claimId) {
		return API.del('ACP_SERVICE_API', `/claims/${claimId}`)
			.then(response => response)
			.catch(err => {
				throw err.response;
			});
	},

	getNladSubmissions() {
		return API.get('ACP_SERVICE_API', '/claims/nlad-submissions')
			.then(response => response)
			.catch(err => {
				throw err.response;
			});
	},

	archiveNladSubmissions(payload) {
		return API.post('ACP_SERVICE_API', '/claims/nlad-submissions/archive', {
			body: {
				nladSubmissionIds: payload
			}
		})
			.then(response => response)
			.catch(err => {
				throw err.response;
			});
	},

	unArchiveNladSubmissions(payload) {
		return API.post('ACP_SERVICE_API', '/claims/nlad-submissions/un-archive', {
			body: {
				nladSubmissionIds: payload
			}
		})
			.then(response => response)
			.catch(err => {
				throw err.response;
			});
	},

	getArchiveSettings() {
		return API.get('ACP_SERVICE_API', '/claims/nlad-submissions/archive/settings')
			.then(response => response)
			.catch(err => {
				throw err.response;
			});
	},
	updateArchiveSettings(payload) {
		return API.patch('ACP_SERVICE_API', '/claims/nlad-submissions/archive/settings', {
			body: payload
		})
			.then(response => response)
			.catch(err => {
				throw err.response;
			});
	},

	getProFormaClaims() {
		return API.get('ACP_SERVICE_API', '/claims/pro-forma-claims')
			.then(response => response)
			.catch(err => {
				throw err.response;
			});
	},
	getNewProFormaClaims() {
		return API.get('ACP_SERVICE_API', '/claims/new-pro-forma-claims')
			.then(response => response)
			.catch(err => {
				throw err.response;
			});
	},
	getPayments() {
		return API.get('ACP_SERVICE_API', '/claims/payments')
			.then(response => response)
			.catch(err => {
				throw err.response;
			});
	},
	getStatements() {
		return API.get('ACP_SERVICE_API', '/statements')
			.then(response => response)
			.catch(err => {
				throw err.response;
			});
	},
	getProFormaClaimsParticipantInfo() {
		return API.get('ACP_SERVICE_API', '/claims/pro-forma-claims/participant-info')
			.then(response => response)
			.catch(err => {
				throw err.response;
			});
	}
};

export default AcpProgramApi;
