import React, { Component } from "react";
import { Field } from "redux-form";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PropTypes from "prop-types";
import { RenderField, RenderFieldSelect } from "components";

class CreateOrderForm extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { handleSubmit, valid } = this.props;
    return (
      <Modal isOpen>
        <form onSubmit={handleSubmit}>
          <ModalHeader>Order Form</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label htmlFor="interestRate">Interest Rate *</label>
              <Field
                className="form-control"
                type="number"
                name="interestRate"
                id="interestRate"
                component={RenderField}
              />
            </div>
            <div className="form-group">
              <label htmlFor="dailyAmount">Daily Settlement Amount *</label>
              <Field
                type="number"
                className="form-control"
                placeholder=""
                name="dailyAmount"
                id="dailyAmount"
                component={RenderField}
              />
            </div>
            <div className="form-group">
              <label htmlFor="minimumCreditRating">
                Minimum Credit Rating you require *
              </label>
              <Field
                  id="minimumCreditRating"
                  name="minimumCreditRating"
                  component={RenderFieldSelect}
                  className="form-control"
              >
                <option value="">
                  Select a value...
                </option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
              </Field>
            </div>
            <div className="form-group">
              <label htmlFor="paymentTerms">
                Maximum Payment Terms you allow
              </label>
              <div className="form-group row">
                <div className="col-4">
                  <Field
                    id="paymentTerms"
                    name="paymentTerms"
                    component="select"
                    className="form-control"
                  >
                    <option value={30}>30</option>
                    <option value={15}>15</option>
                    <option value={7}>7</option>
                    <option value={1}>1</option>
                  </Field>
                </div>
                <label
                  htmlFor="paymentTermsNet"
                  className="col-2 text-right col-form-label"
                >
                  Net
                </label>
                <div className="col-4">
                  <Field
                    id="paymentTermsNet"
                    name="paymentTermsNet"
                    component="select"
                    className="form-control"
                  >
                    <option value={30}>30</option>
                    <option value={15}>15</option>
                    <option value={7}>7</option>
                    <option value={1}>1</option>
                  </Field>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button type="submit" className="btn btn-danger btn-place-order">
              Place Order
            </button>
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onClick={() => this.props.hideOpenOrder()}
            >
              Cancel
            </button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}
CreateOrderForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  hideOpenOrder: PropTypes.func.isRequired,
};
export default CreateOrderForm;
