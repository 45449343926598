import React, { memo } from 'react';
import { Document, Page, View, Image, StyleSheet, Text } from '@react-pdf/renderer';

import moment from 'moment-timezone';

function formatToUSD(number) {
	if (number !== undefined && number !== null && !isNaN(number) && number !== 0) {
		return '$' + number.toLocaleString('en-US');
	}
	return '';
}
function formatToEUR(number) {
	if (number !== undefined && number !== null && !isNaN(number) && number !== 0) {
		return new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'EUR',
			minimumFractionDigits: 0,
			maximumFractionDigits: 0
		}).format(number);
	}
	return '';
}

export default memo(({ data }) => {
	const { claim, participant, accountData } = data;
	return (
		<Document orientation="landscape">
			<Page style={styles.page}>
				<View style={styles.title}>
					<Text>Affordable Connectivity Program</Text>
				</View>

				<View style={styles.pageHeader}>
					<View style={styles.logoWrapper}>{accountData?.logo ? <Image src={accountData?.logo} style={styles.logo} /> : ''}</View>
					<View style={styles.invoiceDetails}>
						<View style={styles.detailPairLine}>
							<Text>Claim Number:</Text>
							<Text>{claim.claimNumber}</Text>
						</View>
						<View style={styles.detailPairLine}>
							<Text>Claim Period:</Text>
							<Text>{moment(claim.claimPeriod).format('MMMM YYYY')}</Text>
						</View>
						<View style={styles.detailPairLine}>
							<Text>Claim Submission Date:</Text>
							<Text>{moment(claim.createdAt).format('LL')}</Text>
						</View>
						<View style={styles.detailPairLine}>
							<Text>Claim Payment Due Date:</Text>
							<Text>
								{moment(claim.claimPeriod)
									.add(1, 'month')
									.endOf('month')
									.format('LL')}
							</Text>
						</View>
					</View>
				</View>

				<View style={styles.pageSubHeader}>
					<View style={styles.details}>
						<Text style={styles.licensedACPProvider}>Licensed ACP Provider:</Text>
						<Text>{participant?.participantName}</Text>
						<Text>{participant.address.addressLine1}</Text>
						<Text>{participant.address?.addressLine2}</Text>
						<Text>
							{participant.address.city}, {participant.state[0]?.stateName} {participant.address.zipPostalCode}
						</Text>
					</View>

					<View style={styles.customerDetails}>
						<Text>Claims submitted to Universal Services Administrative Co.</Text>
						<Text>Address: 700 12th St NW, Washington, DC 20005</Text>
						<Text>Submitted via TessPay Portal and verified against NLAD database</Text>
					</View>
				</View>

				<View style={styles.summaryTitle}>
					<Text>ACP Daily Claim</Text>
				</View>

				<View style={styles.table}>
					<View style={styles.tableHeader}>
						<View style={styles.secondTableFirstCol}>
							<Text style={styles.tableCell}>Description</Text>
						</View>
						<View style={styles.secondTableSecondCol}>
							<Text style={styles.tableCell}>Unit</Text>
						</View>
						<View style={styles.secondTableThirdCol}>
							<Text style={styles.tableCell}>Amount</Text>
						</View>
						<View style={styles.secondTableFourthCol}>
							<Text style={styles.tableCell}>Price</Text>
						</View>
					</View>
					<View style={styles.tableRow}>
						<View style={styles.secondTableFirstCol}>
							<Text style={styles.tableCell}>ACP Claimable Devices</Text>
						</View>
						<View style={styles.secondTableSecondCol}>
							<Text style={styles.tableCell}>{(claim?.summary?.claimableDevices?.unit === 0 ? '' : claim?.summary?.claimableDevices?.unit) ?? ''}</Text>
						</View>
						<View style={styles.secondTableThirdCol}>
							<Text style={styles.tableCell}>{formatToUSD(claim.summary?.claimableDevices?.total)}</Text>
						</View>
						<View style={styles.secondTableFourthCol}>
							<Text style={styles.tableCell}>{formatToUSD(100)}</Text>
						</View>
					</View>
					<View style={styles.tableRow}>
						<View style={styles.secondTableFirstCol}>
							<Text style={styles.tableCell}>ACP Regular Subscription</Text>
						</View>
						<View style={styles.secondTableSecondCol}>
							<Text style={styles.tableCell}>{(claim?.summary?.regularSubscription?.unit === 0 ? '' : claim?.summary?.regularSubscription?.unit) ?? ''}</Text>
						</View>
						<View style={styles.secondTableThirdCol}>
							<Text style={styles.tableCell}>{formatToUSD(claim?.summary?.regularSubscription?.total)}</Text>
						</View>
						<View style={styles.secondTableFourthCol}>
							<Text style={styles.tableCell}>{formatToUSD(30)}</Text>
						</View>
					</View>
					<View style={styles.tableRow}>
						<View style={styles.secondTableFirstCol}>
							<Text style={styles.tableCell}>ACP Tribal Subscription</Text>
						</View>
						<View style={styles.secondTableSecondCol}>
							<Text style={styles.tableCell}>{(claim?.summary?.tribalSubscription?.unit === 0 ? '' : claim?.summary?.tribalSubscription?.unit) ?? ''}</Text>
						</View>
						<View style={styles.secondTableThirdCol}>
							<Text style={styles.tableCell}>{formatToUSD(claim?.summary?.tribalSubscription?.total)}</Text>
						</View>
						<View style={styles.secondTableFourthCol}>
							<Text style={styles.tableCell}>{formatToUSD(75)}</Text>
						</View>
					</View>
					<View style={styles.tableRow}>
						<View style={styles.secondTableFirstCol}>
							<Text style={styles.tableCell}>Totals</Text>
						</View>
						<View style={styles.secondTableSecondCol}>
							<Text style={styles.tableCell}>{''}</Text>
						</View>
						<View style={styles.secondTableThirdCol}>
							<Text style={styles.tableCell}>{formatToUSD(claim?.value)}</Text>
						</View>
						<View style={styles.secondTableFourthCol}>
							<Text style={styles.tableCell}>{''}</Text>
						</View>
					</View>
					{claim?.exchangeRate && (
						<View style={{ ...styles.tableRow, border: 0, backgroundColor: 'white' }}>
							<View style={{ ...styles.secondTableFirstCol, borderRight: 0 }}>
								<Text style={styles.tableCell}>{''}</Text>
							</View>
							<View style={{ ...styles.secondTableSecondCol, borderRight: 0 }}>
								<Text style={styles.tableCell}>{''}</Text>
								<View style={styles.exchangeRate}>
									<Text style={{ ...styles.tableCell }}>Total in EUR</Text>
									<Text style={{ ...styles.tableCell, fontSize: '7px' }}>USD/EUR rate</Text>
								</View>
							</View>
							<View style={{ ...styles.secondTableThirdCol, borderRight: 0 }}>
								<>
									<View style={{ ...styles.exchangeRate }}>
										<Text style={{ ...styles.tableCell }}>{formatToEUR(claim?.exchangeRate?.totalAfterConversion)}</Text>
									</View>
									<View style={{ ...styles.exchangeRate }}>
										<Text style={{ ...styles.tableCell, fontSize: '7px' }}>{claim?.exchangeRate?.rate}</Text>
									</View>
								</>
							</View>
							<View style={{ ...styles.secondTableFourthCol }}>
								<Text style={styles.tableCell}>{''}</Text>
							</View>
						</View>
					)}
				</View>

				<View style={styles.disclaimer}>
					<Text>{participant?.acpProgram?.customInvoiceFooter ?? ''}</Text>
				</View>
				<Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `Page ${pageNumber} / ${totalPages}`} fixed />
			</Page>
		</Document>
	);
});

const styles = StyleSheet.create({
	page: {
		padding: '0.75in 0.75in'
	},
	title: {
		fontSize: '24px',
		textAlign: 'center',
		marginBottom: '20px',
		fontWeight: 'bold'
	},
	pageHeader: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		fontSize: '9px',
		marginBottom: '20px'
	},
	pageSubHeader: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		fontSize: '9px',
		marginBottom: '20px'
	},
	logoWrapper: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		verticalAlign: 'top',
		width: '45%',
		height: '50px',
		paddingRight: '50px'
	},
	logo: {
		display: 'block',
		width: '100%',
		height: '100%',
		maxWidth: '150px',
		objectFit: 'contain'
	},
	details: {
		width: '45%'
	},
	licensedACPProvider: {
		fontSize: '9px',
		fontWeight: 'bold'
	},
	invoiceDetails: {
		flex: 1,
		width: '55%'
	},
	customerDetails: {
		width: '55%'
	},
	disclaimer: {
		margin: '5px',
		fontSize: '9px'
	},
	summaryTitle: {
		margin: '12px 0px',
		fontSize: '18px',
		fontWeight: 'bold',
		textAlign: 'center'
	},
	exchangeRate: {
		// position: 'absolute',
		// top: 16,
		// left: 38,
		margin: 0,
		padding: 0
	},
	detailPairLine: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: 5 // Adjust this value as needed
	},
	table: {
		margin: '12px 0px',
		display: 'table',
		width: 'auto',
		borderStyle: 'solid',
		borderWidth: 1,
		borderRightWidth: 0,
		borderBottomWidth: 0
	},
	tableRow: {
		margin: 'auto',
		flexDirection: 'row'
	},
	tableHeader: {
		margin: 'auto',
		flexDirection: 'row',
		backgroundColor: 'black',
		color: 'white'
	},
	firstTablefirstCol: {
		width: '25%',
		borderStyle: 'solid',
		textAlign: 'left',
		paddingTop: '2px',
		paddingBottom: '2px',
		paddingLeft: '5px',
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0
	},
	firstTableSecondCol: {
		width: '75%',
		borderStyle: 'solid',
		textAlign: 'right',
		paddingTop: '2px',
		paddingBottom: '2px',
		paddingRight: '5px',
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0
	},
	secondTableFirstCol: {
		width: '25%',
		borderStyle: 'solid',
		textAlign: 'left',
		paddingTop: '2px',
		paddingBottom: '2px',
		paddingLeft: '5px',
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0
	},
	secondTableSecondCol: {
		width: '45%',
		borderStyle: 'solid',
		textAlign: 'right',
		paddingTop: '2px',
		paddingBottom: '2px',
		paddingRight: '5px',
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0
	},
	secondTableThirdCol: {
		width: '15%',
		borderStyle: 'solid',
		textAlign: 'right',
		paddingTop: '2px',
		paddingBottom: '2px',
		paddingRight: '5px',
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0
	},
	secondTableFourthCol: {
		width: '15%',
		borderStyle: 'solid',
		textAlign: 'right',
		paddingTop: '2px',
		paddingBottom: '2px',
		paddingRight: '5px',
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0
	},
	tableCell: {
		fontSize: '9px'
	},
	pageNumber: {
		position: 'absolute',
		bottom: '0.25in',
		right: '50vw',
		margin: 'auto',
		fontSize: '9px'
	}
});
