import React from "react";
import PropTypes from "prop-types";

import { Field } from "redux-form";
import { RenderFileUpload } from "components";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// eslint-disable-next-line react/prefer-stateless-function
class ConfirmFileUpload extends React.Component {
  render() {
    const {
      open,
      openCloseHandler,
      allowedFileTypes,
      handleUpload,
      isUploadDisabled,
      uploadId,
      dialogTitle,
      dialogMessage
    } = this.props;
    return (
      <div>
        <Dialog
          open={open}
          onBackdropClick={() => openCloseHandler()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                openCloseHandler();
              }}
              color="secondary"
            >
              No
            </Button>
            <Field
              type="file"
              component={RenderFileUpload}
              allowedFileTypes={allowedFileTypes}
              btnText="Yes"
              className="form-control"
              name="immediateChangeRatesFile"
              handleUploadFn={evt => {
                const fd =
                  evt && evt.target && evt.target.files && evt.target.files[0];
                handleUpload(uploadId, fd);
              }}
              disabled={isUploadDisabled}
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ConfirmFileUpload.defaultProps = {
  allowedFileTypes: [],
  isUploadDisabled: false
};

ConfirmFileUpload.propTypes = {
  allowedFileTypes: PropTypes.arrayOf(String),
  dialogTitle: PropTypes.string.isRequired,
  dialogMessage: PropTypes.string.isRequired,
  handleUpload: PropTypes.func.isRequired,
  isUploadDisabled: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  openCloseHandler: PropTypes.func.isRequired,
  uploadId: PropTypes.string.isRequired
};

export default ConfirmFileUpload;
