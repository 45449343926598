import { API } from 'aws-amplify';

const ReportApi = {
	getReportsFilters(data) {
		const postData = {
			body: data
		};
		return API.post('REPORTS_SERVICE_API', '/reports/getFilterData', postData)
			.then(response => response)
			.catch(err => err);
	},

	getMarginReport(data) {
		const postData = {
			body: data
		};
		return API.post('REPORTS_SERVICE_API', '/reports/margin', postData)
			.then(response => response)
			.catch(err => err);
	},
	getLendingReport(data) {
		const postData = {
			body: data
		};
		return API.post('REPORTS_SERVICE_API', '/reports/lending', postData)
			.then(response => response)
			.catch(err => err);
	},

	getLenderReport(data) {
		const postData = {
			body: data
		};
		return API.post('NODE_SERVICE_API', '/report/lender', postData)
			.then(response => response)
			.catch(err => err);
	},

	downloadSaleAndCostReport(data) {
		const postData = {
			responseType: 'blob',
			body: data
		};
		return API.post('REPORTS_SERVICE_API', '/reports/sale-and-cost/download', postData)
			.then(response => response)
			.catch(err => err);
	},
	downloadCustomerReport(data) {
		const postData = {
			responseType: 'blob',
			body: data
		};
		return API.post('REPORTS_SERVICE_API', '/reports/customer/download', postData)
			.then(response => response)
			.catch(err => err);
	},
	downloadSupplierReport(data) {
		const postData = {
			responseType: 'blob',
			body: data
		};
		return API.post('REPORTS_SERVICE_API', '/reports/supplier/download', postData)
			.then(response => response)
			.catch(err => err);
	},

	getCDRDownload(data) {
		const postData = {
			body: data
		};
		return API.post('REPORTS_SERVICE_API', '/reports/cdr-download', postData)
			.then(response => response)
			.catch(err => err);
	},

	getReportCDRData(data) {
		return API.get('REPORTS_SERVICE_API', '/reports/cdr-download')
			.then(response => response)
			.catch(err => err);
	},

	// TODO - this function below mmight not be useful anymore
	initializeReport() {
		return API.post('REPORT_API', '/getIndex', {})
			.then(response => response)
			.catch(err => err);
	},
	createReport(data) {
		const init = {
			body: {
				report: {
					id: data.id,
					carrierId: data.carrierId,
					countryId: data.countryId,
					contractId: data.contractId,
					destinationType: data.destinationType,
					destinationCarrier: data.destinationCarrier,
					intervalStart: data.intervalStart,
					intervalEnd: data.intervalEnd
				}
			}
		};
		return API.post('REPORT_API', '/postGenerate', init)
			.then(response => response)
			.catch(err => err);
	},

	getReport(data) {
		const init = {
			body: {
				report: {
					id: data
				}
			}
		};

		return API.post('REPORT_API', '/getData', init)
			.then(response => response)
			.catch(err => err);
	},

	getCustomerInvoices(isOriginContract = true) {
		return API.post('NODE_SERVICE_API', '/report/invoice/customer', {
			body: {
				isOriginContract
			}
		})
			.then(response => response)
			.catch(err => err);
	},

	getExternalInvoices() {
		return API.post('NODE_SERVICE_API', '/report/invoice/external', {})
			.then(response => response)
			.catch(err => err);
	},

	getCustomerMonthlyInvoices() {
		return API.post('NODE_SERVICE_API', '/report/invoice/monthly/customer', {})
			.then(response => response)
			.catch(err => err);
	},

	getSupplierMonthlyInvoices() {
		return API.post('NODE_SERVICE_API', '/report/invoice/monthly/supplier', {})
			.then(response => response)
			.catch(err => err);
	},

	getSupplierInvoices(isOriginContract = true) {
		return API.post('NODE_SERVICE_API', '/report/invoice/supplier', {
			body: {
				isOriginContract
			}
		})
			.then(response => response)
			.catch(err => err);
	},

	downloadInvoice(data) {
		const { invoiceId, invoiceNumber, type, isOriginContract } = data;
		return API.post('NODE_SERVICE_API', `/report/invoice/${invoiceId}/download`, {
			responseType: 'blob',
			body: {
				invoiceId,
				invoiceNumber,
				isOriginContract,
				type
			}
		})
			.then(response => response)
			.catch(err => err);
	},
	payInvoice(data) {
		return API.post('NODE_SERVICE_API', '/report/invoice/pay', {
			body: data
		})
			.then(response => response)
			.catch(err => err);
	},
	supplierPayInvoice(data) {
		return API.post('FINANCIALS_SERVICE_API', '/invoices/supplierPayInvoice', { body: { data } })
			.then(response => response)
			.catch(err => err);
	},
	// / NEWWW

	getFilterData(data) {
		return API.post('REPORTS_SERVICE_API', '/getFilterData', {
			body: {
				...data
			}
		});
	},
	getCustomerReport(data, download = false) {
		return API.post('REPORTS_SERVICE_API', '/customer', {
			responseType: (download && 'blob') || undefined,
			body: {
				...data
			},
			queryStringParameters: {
				csv: !!download || undefined
			}
		});
	},
	getSupplierReport(data, download = false) {
		return API.post('REPORTS_SERVICE_API', '/supplier', {
			responseType: (download && 'blob') || undefined,
			body: {
				...data
			},
			queryStringParameters: {
				csv: !!download || undefined
			}
		});
	},
	getSaleAndCostReport(data, download = false) {
		return API.post('REPORTS_SERVICE_API', '/sale-and-cost', {
			responseType: (download && 'blob') || undefined,
			body: {
				...data
			},
			queryStringParameters: {
				csv: !!download || undefined
			}
		});
	},
	getCDRReport(data) {
		return API.get('REPORTS_SERVICE_API', '/cdr', {
			body: {
				...data
			}
		});
	},
	requestCDRReport(data) {
		return API.post('REPORTS_SERVICE_API', '/cdr', {
			body: {
				...data
			}
		});
	},
	downloadCDRReport(id) {
		return API.get('REPORTS_SERVICE_API', `/cdr/${id}`, {
			responseType: 'blob'
		});
	},
	getPartitionStats() {
		return API.get('REPORTS_SERVICE_API', '/partition/stats').catch(e => {
			console.log(e);
			return {
				error: true,
				e
			};
		});
	},
	getPartitionActiveCalls() {
		return API.get('REPORTS_SERVICE_API', '/partition/active-calls').catch(e => {
			console.log(e);
			return {
				error: true,
				e
			};
		});
	},
	getAdvancedReport(payload, params) {
		return API.post('REPORTS_SERVICE_API', '/advanced-report', { body: payload, params }).catch(e => {
			return {
				error: true,
				e
			};
		});
	},
	getReportTemplates() {
		return API.get('REPORTS_SERVICE_API', `/templates`)
			.then(response => response)
			.catch(err => err);
	},
	getReportTemplate(id) {
		return API.get('REPORTS_SERVICE_API', `/templates/${id}`, {}).catch(err => err);
	},
	saveTemplateReport(data) {
		const postData = {
			body: data
		};
		return API.post('REPORTS_SERVICE_API', '/templates', postData)
			.then(response => response)
			.catch(err => err);
	},
	updateTemplateReport(data) {
		const postData = {
			body: data
		};
		return API.put('REPORTS_SERVICE_API', '/templates', postData)
			.then(response => response)
			.catch(err => err);
	},
	deleteTemplateReport(templateId) {
		const postData = {
			body: {
				_id: templateId
			}
		};
		return API.del('REPORTS_SERVICE_API', '/templates', postData)
			.then(response => response)
			.catch(err => err);
	}
};

export default ReportApi;
