const loginStyle = theme => ({
  root: {
    overflow: "visible"
  },
  cardHeader: {
    textAlign: "center",
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "-20px",
    borderRadius: "3px",
    padding: "1rem 15px",
    top: "-40px",
    position: "relative",
    backgroundColor: theme.palette.light,
    border: `1px solid ${theme.palette.lightGrey.main}`
  },
  form: {
    maxWidth: "400px"
  }
});

export default loginStyle;
