import React from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputMask from 'react-input-mask';

const RenderMaterialMaskField = ({ input, label, mask, maskChar, alwaysShowMask, formatChars, value, defaultValue, meta: { touched, error } }) => {
  const hasError = typeof error !== typeof undefined && error.length > 0 && touched;
  return (
    <FormControl error={hasError} fullWidth>
      <InputMask

        mask={mask}
        maskChar={maskChar}
        alwaysShowMask={alwaysShowMask}
        formatChars={formatChars}
        name={input.name}
        fullWidth
        className={hasError ? " is-invalid" : "is-valid"}
      >
        {() => <TextField label={label} helperText={hasError ? error : ""} error={hasError}/>}
      </InputMask>
    </FormControl>
  );
};

export default RenderMaterialMaskField;
