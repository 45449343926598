import React from 'react';
import { DropzoneArea, DropzoneDialog } from 'material-ui-dropzone';
import { FormControl, FormHelperText } from '@material-ui/core';

const RenderFormikDropzoneArea = ({
	formik,
	name,
	multiple = false,
	onChange,
	acceptFiles,
	isDialogArea,
	dialogOpen,
	dialogOnClose,
	dialogOnSave,
	...rest }) => {
	if (!name || !formik) {
		console.log('FORMIK RenderFormikDropzoneArea ERORR!: name or formik required', { name, formik });
		return '';
	}

	let { error: fieldError, touched: fieldTouched, value: fieldValue } = formik.getFieldMeta(name);
	let fieldProps = formik.getFieldProps(name);

	const hasError = (!!fieldError && fieldTouched && true) || false;
	return !isDialogArea ? (
		<FormControl error={hasError} fullWidth>
			<DropzoneArea
				{...fieldProps}
				onChange={e => {
					formik.setFieldValue(name, (multiple && e) || (e && e[0]) || null);
					if (onChange && typeof onChange === 'function') onChange(e);
				}}
				showFileNamesInPreview={true}
				acceptedFiles={acceptFiles ? acceptFiles : []}
				showPreviewsInDropzone={true}
				showFileNames={true}
				useChipsForPreview={true}
				error={hasError}
				{...rest}
			/>
			{hasError && <FormHelperText>{fieldError}</FormHelperText>}
		</FormControl>
	) : (
		<FormControl>
			<DropzoneDialog
				open={dialogOpen || false}
				onSave={eventFile => dialogOnSave(eventFile)}
				acceptedFiles={acceptFiles ? acceptFiles : []}
				showPreviews={true}
				onClose={dialogOnClose}
			/>
		</FormControl>
	);
};

export default RenderFormikDropzoneArea;
