import React from "react";

import { Field } from "redux-form";
import { RenderField } from "components";

const ChangePasswordForm = props => {
  const { handleSubmit, valid, error, info } = props;

  return (
    <div>
      <div className="login-box card">
        <div className="card-body">
          <form className="form-horizontal" onSubmit={handleSubmit}>
            <h3 className="box-title m-b-20">Change Password</h3>
            <div className="form-group">
              <label htmlFor="email" className="col-md-12 control-label">
                E-Mail Address
              </label>
              <div className="col-md-12">
                <Field
                  name="email"
                  component={RenderField}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="passwordOld" className="col-md-12 control-label">
                Old Password
              </label>
              <div className="col-md-12">
                <Field
                  name="passwordOld"
                  component={RenderField}
                  type="password"
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="passwordNew" className="col-md-12 control-label">
                Old Password
              </label>
              <div className="col-md-12">
                <Field
                  name="passwordNew"
                  component={RenderField}
                  type="password"
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group">
              <label
                htmlFor="passwordNewConfirmation"
                className="col-md-12 control-label"
              >
                New Password Confirmation
              </label>
              <div className="col-md-12">
                <Field
                  name="passwordNewConfirmation"
                  component={RenderField}
                  type="password"
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="agree" className="col-md-12 control-label">
                I agree to the terms of service
              </label>
              <div className="col-md-12">
                <Field
                  label="I agree to the terms of service"
                  name="agree"
                  type="checkbox"
                  component={RenderField}
                />
              </div>
            </div>

            <div className="form-group">
              {props.info &&
                props.info.errorMessage && (
                  <div className="col-md-12 text-center">
                    <span className="label label-danger">
                      {props.info.errorMessage}
                    </span>
                  </div>
                )}

              <div className="col-md-12 col-md-offset-4">
                <button type="submit" className="btn btn-primary">
                  Change Password
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordForm;
