const styles = {
  orderButtons: {
    root: {
      border: "none !important",
      "&after": {
        display: "none !important"
      }
    }
  }
};

export default styles;
