import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, reset, getFormValues } from 'redux-form';
import PropTypes from 'prop-types';
import { fromSmartcontracts } from 'store/selectors';
import { SmartContractsNewForm, RenderNotification } from 'components';
import { createValidator, required, isChecked, validateRequiredDepentValue, validateDepentValue, validateSmartContractMembersArray } from 'services/validation';
import {
	initialSmartContractNew,
	getNewSmartContractData,
	createSmartContract,
	uploadSmartContractRatePlan,
	loadDestinationTypeCarrier,
	loadDestinationsCarrier,
	loadDialCodes,
	clearRatePlan,
	initializeRatePlanUpload
} from 'store/actions';

class SmartContractsNewContainer extends Component {
	static contextTypes = {
		store: PropTypes.object
	};

	constructor(props) {
		super(props);
		this.showOptionsContractType = this.showOptionsContractType.bind(this);
		this.changeStatus = this.changeStatus.bind(this);

		this.state = {
			showOptionsSell: 'block',
			showOptionBuy: 'none',
			valCountry: undefined,
			varDesType: undefined,
			styleCountrySel: '',
			modalDialCodes: false,
			destinationsSelected: [],
			destinationsCarrierSelected: [],
			dialCodesSelected: [],
			codeRowIndexSelected: undefined,
			selectedHardwareId: undefined
		};
	}

	componentWillMount() {
		const { dispatch, loadInitialSmartContractNew, getNewSmartContractData } = this.props;
		dispatch(reset('newSmartContractForm'));
		dispatch(initializeRatePlanUpload());
		loadInitialSmartContractNew();
		getNewSmartContractData();
	}

	changeStatus(status) {
		const { change } = this.props;
		change('status', status);
	}

	showOptionsContractType(event) {
		const { change } = this.props;
		const idOption = event.value;
		if (idOption === 'sell' || idOption === 'sell-cli' || idOption === 'sell-non-cli') {
			this.setState({
				showOptionsSell: 'block',
				showOptionBuy: 'none'
			});
		} else if (idOption === 'buy' || idOption === 'buy-cli' || idOption === 'buy-non-cli') {
			this.setState({
				showOptionsSell: 'none',
				showOptionBuy: 'block'
			});
		}
	}

	render() {
		const { handleSubmit, catalogsNewSmartContract, dataNewSmartContract, messageErrorCreate } = this.props;
		return (
			<div>
				<RenderNotification variant="danger" floating open={messageErrorCreate != undefined} message={messageErrorCreate} />
				{catalogsNewSmartContract !== undefined && dataNewSmartContract !== undefined && (
					<SmartContractsNewForm
						{...this.props}
						showOptionsContractType={this.showOptionsContractType}
						handleSubmit={handleSubmit}
						changeStatus={this.changeStatus}
						initialValues={{
							ingressMaxSessions: 1000,
							ingressCps: 10
						}}
						{...this.state}
					/>
				)}
			</div>
		);
	}
}

SmartContractsNewContainer.propTypes = {
	dispatch: PropTypes.func.isRequired,
	loadInitialSmartContractNew: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	dataNewSmartContract: PropTypes.shape({}),
	catalogsNewSmartContract: PropTypes.shape({})
};

SmartContractsNewContainer.defaultProps = {
	dataNewSmartContract: undefined,
	catalogsNewSmartContract: undefined
};

const getIdCodecs = catalogs => {
	const codecs = {};
	catalogs.forEach(codec => {
		codecs[codec.codexId] = true;
	});
	return codecs;
};

const getTempId = dataNewSmartContract => dataNewSmartContract && dataNewSmartContract.tempId;

const transformDataSubmit = dataForm => {
	const dataSubmit = Object.assign({}, dataForm);
	if (dataSubmit.endDate != null && dataSubmit.endDate !== undefined) dataSubmit.endDate = dataSubmit.endDate.valueOf();
	if (dataSubmit.smartContractCodecs != null && dataSubmit.smartContractCodecs !== undefined) {
		dataSubmit.smartContractCodecs = Object.keys(dataSubmit.smartContractCodecs)
			.map(codec => (dataSubmit.smartContractCodecs[codec] ? codec : null))
			.filter(codec => codec);
	}
	if (dataSubmit.members != null && dataSubmit.members !== undefined) {
		dataSubmit.smartContractOffers = dataSubmit.members
			.map(offer => {
				if (!offer.countryId) return null;

				if (!offer.offerPrice) return null;

				if (offer.smartContractOfferCodes !== null && offer.smartContractOfferCodes !== undefined) {
					offer.smartContractOfferCodes = offer.smartContractOfferCodes.split(',');
				}
				return { ...offer };
			})
			.filter(offer => offer);
		delete dataSubmit.members;
	}

	return dataSubmit;
};

const mapStateToProps = state => ({
	selectedFormValues: getFormValues('newSmartContractForm')(state),
	catalogsNewSmartContract: fromSmartcontracts.getCatalogNewSmartContract(state),
	dataNewSmartContract: fromSmartcontracts.getDataNewSmartContract(state),
	newSmartContractData: fromSmartcontracts.getNewSmartContractData(state),
	messageErrorCreate: fromSmartcontracts.getErrorCreate(state),
	initialValues: {
		status: 'draft',
		contractType: 'sell',
		trafficType: 'CLI',
		isOriginContract: false,
		ratesFile: '',
		settlementPeriodId: fromSmartcontracts.getDefaultSettlementId(state),
		smartContractCodecs: getIdCodecs(fromSmartcontracts.getSmartContractCatalogCodecs(state)),
		tempId: getTempId(fromSmartcontracts.getDataNewSmartContract(state))
	},
	hardwareProfiles: fromSmartcontracts.getHardwareProfiles(state),
	enableReinitialize: true,
	nameForm: 'newSmartContractForm',
	destinations: fromSmartcontracts.getDestinationsType(state),
	destinationsCarrier: fromSmartcontracts.getDestinationsCarrier(state),
	dialCodes: fromSmartcontracts.getDialCodes(state),
	ratePlanData: fromSmartcontracts.getSmartContractRatePlanState(state)
});

const mapDispatchToProps = dispatch => ({
	clearUploadError() {
		dispatch(clearRatePlan());
	},
	uploadRatePlan(tempSmartContractId, ratesFile) {
		dispatch(uploadSmartContractRatePlan(tempSmartContractId, ratesFile));
	},
	loadInitialSmartContractNew() {
		dispatch(initialSmartContractNew());
	},
	loadDestinationTypeCarrierDispatch(country) {
		dispatch(loadDestinationTypeCarrier(country));
	},
	loadDestinationsCarrierDispatch(country, destinationType) {
		dispatch(loadDestinationsCarrier(country, destinationType));
	},
	loadDialCodesDispatch(destinationCarries, country, destinationType) {
		dispatch(loadDialCodes(destinationCarries, country, destinationType));
	},
	getNewSmartContractData(data) {
		dispatch(getNewSmartContractData(data));
	}
});

const onSubmit = (dataSmartContract, dispatch) => {
	dispatch(createSmartContract(transformDataSubmit(dataSmartContract)));
};

const validate = createValidator({
	customerId: [validateDepentValue('contractType', 'sell', required)],
	supplierId: [validateDepentValue('contractType', 'buy', required)],
	terms: [required, isChecked],
	minimumNoticeDays: [validateDepentValue('status', 'pending', required)],
	members: [validateDepentValue('status', 'pending', validateSmartContractMembersArray)],
	invoicePeriodId: [validateRequiredDepentValue('status', 'pending')]
});

const SmartContractsNewContainerConnect = reduxForm({
	form: 'newSmartContractForm',
	enableReinitialize: true,
	destroyOnUnmount: false,
	onSubmit,
	validate
})(SmartContractsNewContainer);

export default connect(mapStateToProps, mapDispatchToProps)(SmartContractsNewContainerConnect);
