import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
// import { autoRehydrate } from "redux-persist";
import thunkMiddleware from 'redux-thunk';
import { getNotifications, urlRedirectPostLogin } from 'store/actions';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { autoRehydrate } from 'redux-persist';
import { createLogger } from 'redux-logger';
import { middleware as idleMiddleware } from './idle/configure';
import middlewares from './middlewares';
import reducer from './reducer';
import sagas from './sagas';

const rootReducer = (state, action) => {
	if (action.type === 'LOG_OUT') {
		console.log('Before LOG_OUT .....');
	} else if (action.type === 'LOG_OUT_FINISH') {
		console.log('LOG_OUT .....');
		state = {};
	}

	return reducer(state, action);
};

const createRootReducer = history =>
	combineReducers({
		router: connectRouter(history),
		...reducer
	});

export function functionGetNotifications(dispatch, userId) {
	dispatch(getNotifications(userId));
}
const logger = createLogger({
	predicate: (getState, action) => !action.type.includes('IDLE_TESSPAY_ACTIVITY_DETECTION') || !action.type.includes('IDLE_TESSPAY_ACTIVITY')
});

const configureStore = (initialState, history, services = {}, ...rest) => {
	const sagaMiddleware = createSagaMiddleware();
	const middleware = routerMiddleware(history);
	const customMiddleWare = store => next => action => {
		if (action.type === 'USER_INFO_SUCCESS') {
			functionGetNotifications(store.dispatch, 'userId');
		}
		if (action.type === 'NOTIFICATIONS') {
			store.dispatch(urlRedirectPostLogin());
		}
		next(action);
	};

	const enhancers = [
		applyMiddleware(...middlewares, thunkMiddleware, idleMiddleware, customMiddleWare, sagaMiddleware, middleware),
		autoRehydrate({ log: false })
		// window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
	];

	const store = createStore(createRootReducer(history), initialState, compose(...enhancers));
	sagaMiddleware.run(sagas, services);

	return store;
};
export default configureStore;
