import {
	AppBar,
	CardContent,
	Grid,
	Paper,
	Tab,
	Tabs,
	Tooltip,
	Typography
} from "@material-ui/core";
import {
	CreateTechnicalInformationForm,
	RenderBadge,
	RenderButton,
	RenderNotification,
	RoutesFilters,
	RoutesSmartContractsList,
	TechnicalInformationList
} from "components";
import React, { Component } from "react";
import {
	actionExternalClientDetailsPostDataRequest,
	actionGetExternalClientData,
	actionGetHardwareProfileOptionsPostDataRequest,
	actionGetStatesPostDataRequest,
	actionMyAccountGetDataRequest,
	actionNewHardwarePostDataRequest,
	actionRemoveHardwareProfile,
	actionTechnicalDetailsPostDataRequest,
	actionUpdateHardwareProfile,
	createExternalInvoice,
	getNotifications,
	getRoutesSmartContractsList,
	getRoutesSmartContractsListFilters,
	initializeReport,
	loadExternalInvoicePreviewData,
	loadExternalRates,
	loadVendorRates,
	setPostResponse,
	uploadExternalRateplan,
	uploadVendorRateplan
} from "store/actions";
import {
	fromMyaccount,
	fromNotifications,
	fromRoutes,
	fromUseraccount
} from "store/selectors";
import { reduxForm, reset } from "redux-form";

import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import uuidv4 from "uuid/v4";

const rol_group_1 = [ROLES.FUNDER, ROLES.CARRIER];
const rol_group_2 = [ROLES.CARRIER];
class RoutesContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			newTechnicalStateForm: false,
			technicalInformationFormName: "TechnicalInformationForm",
			technicalFormType: "",
			value: 0,
			filterType: "selling"
		};
		this.handleRouteFilterSubmit = this.handleRouteFilterSubmit.bind(this);
	}

	componentDidMount() {
		const {
			actionGetExternalClientData,
			getRoutesSmartContractsList,
			actionMyAccountGetDataRequest,
			actionGetHardwareProfileOptionsPostDataRequest,
			getRoutesSmartContractsListFilters,
			getNotifications,
			initializeReport
		} = this.props;
		initializeReport();
		getRoutesSmartContractsList();
		actionMyAccountGetDataRequest({});
		actionGetHardwareProfileOptionsPostDataRequest({});
		getRoutesSmartContractsListFilters({});
		getNotifications();
		actionGetExternalClientData();
	}

	partnerFormatter(row) {
		return row.partner.cutomerName === ""
			? row.partner.cutomerName
			: row.partner.supplierName === ""
				? row.partner.cutomerName
				: `Customer: ${row.partner.cutomerName} / Supplier: ${row.partner.supplierName}`;
	}

	fundedFormatter(row) {
		return !row.funded.isAmountFunder ? (
			<RenderBadge color="danger">No</RenderBadge>
		) : (
			<RenderBadge color="success">{`Yes ${
				row.funded.amountTrafficTotal ? row.funded.amountTrafficTotal : 0
			}  of  ${
				row.funded.amountTotal ? row.funded.amountTotal : 0
			}`}</RenderBadge>
		);
	}

	statusFormatter(row) {
		// return <RenderBadge color="warning">{row.status.value}</RenderBadge>;
		return <RenderBadge color="warning">Not Configured</RenderBadge>;
	}

	actionFormatter(row) {
		return (
			<RenderButton
				color="primary"
				component={Link}
				to={`/routes/edit/${row.contractId}`}
			>
				{row.type === "Buy" ? "View" : "Update Routing"}
			</RenderButton>
		);
	}

	handleNotificationClose = () => {
		this.props.setPostResponse(undefined);
	};

	handleTabChange = (event, value) => {
		if (value === 2) return;
		this.setState({ value });
	};

	setNewTechnicalStateForm = type => {
		if (this.state.newTechnicalStateForm) {
			this.setState({
				tempId: uuidv4(),
				newTechnicalStateForm: false,
				technicalFormType: ""
			});
		} else {
			this.setState({
				tempId: uuidv4(),
				newTechnicalStateForm: true,
				technicalFormType: type
			});
		}
	};

	changeType = event => {
		this.setState({ filterType: event.target.value });
	};

	newTechnicalInformationSubmit = data => {
		const {
			type,
			name,
			voipProtocolId,
			hardwareCodecs,
			codecs,
			dtmf,
			techPrefix,
			dialStringFormat,
			proxyMedia,
			ipAddresses,
			ignoreEarlyMedia,
			cpsLimit,
			pddTimeout,
			maxDuration,
			ringTimer,
			active,
			t38,
			rfc2833,
			ignoreEarlyNOSDP,
			tempId,
			externalClient
		} = data;

		const { technicalInformationFormName } = this.state;
		const { generalInformationData, participantData } = this.props;

		const {
			id,
			participantName,
			businessTypeId,
			contactPerson,
			phonePrefixId,
			phoneNumber,
			faxNumber
		} = generalInformationData;

		const {
			carrierId,
			addressId,
			participantTypeId,
			bankAccountId,
			taxId,
			hardwareId,
			status,
			walletAddress
		} = participantData;

		const hardwareCodecObjs = [];
		if (hardwareCodecs !== undefined) {
			hardwareCodecs.forEach(element => {
				const codecObj = {
					id: element,
					codexId: element,
					hardwareId: ""
				};
				hardwareCodecObjs.push(codecObj);
			});
		} else {
			codecs.forEach(element => {
				const codecObj = {
					id: element.value,
					codexId: element.value,
					hardwareId: ""
				};
				hardwareCodecObjs.push(codecObj);
			});
		}

		const technicalInformationData = {
			participant: {
				id,
				carrierId,
				userId: id,
				businessTypeId,
				addressId,
				participantTypeId,
				bankAccountId,
				participantName,
				contactPerson,
				taxId,
				phoneNumber,
				faxNumber,
				hardwareId,
				phonePrefixId,
				status,
				walletAddress
			},
			hardwareProfile: {
				type,
				participantId: id,
				name,
				voipProtocolId,
				hardwareCodecs,
				dtmf,
				techPrefix,
				dialStringFormat,
				proxyMedia,
				ignoreEarlyMedia,
				cpsLimit,
				pddTimeout,
				maxDuration,
				ringTimer,
				active,
				t38,
				rfc2833,
				ignoreEarlyNOSDP,
				tempId
			},
			ipAddresses
		};
		this.props.actionExternalClientDetailsPostDataRequest({
			...externalClient,
			tempId
		});
		this.props.actionTechnicalDetailsPostDataRequest(technicalInformationData);
		this.props.reset(technicalInformationFormName);
		this.setNewTechnicalStateForm();
	};

	updateTechnicalInfoSubmit = data => {
		data.smartContracts = null;
		const postData = {
			participant: this.props.participantData,
			hardwareProfileData: data
		};
		this.props.actionExternalClientDetailsPostDataRequest({
			...data.externalClient,
			tempId: data.tempId
		});
		this.props.actionUpdateHardwareProfile(postData);
	};

	deleteTechnicalInfoSubmit = (data, dispatch) => {
		this.props.actionRemoveHardwareProfile(data);
	};

	handleRouteFilterSubmit(data) {
		this.props.getRoutesSmartContractsListFilters(data);
		this.props.getRoutesSmartContractsList(data);
	}

	render() {
		const {
			routesData,
			hardwareDataArray,
			hardwareProfileOptionsArray,
			rol,
			notification: { completeProfile },
			postResponseData,
			filterData,
			participantData,
			ratePlanUploadData,
			externalRatePlanUploadData,
			// eslint-disable-next-line no-shadow
			uploadVendorRateplan,
			uploadExternalRateplan,
			loadVendorRates,
			loadExternalRates,
			vendorRatesData,
			externalRatesData,
			statesDataArray,
			countriesDataArray,
			actionGetStatesPostDataRequest,
			externalClientData,
			externalInvoiceData,
			loadExternalInvoicePreviewData,
			createExternalInvoice
		} = this.props;
		const {
			value,
			newTechnicalStateForm,
			technicalInformationFormName,
			technicalFormType,
			filterType,
			tempId
		} = this.state;

		const tableOptions = {
			selectableRows: false,
			filter: false,
			print: false,
			download: false,
			viewColumns: false,
			responsive: "scroll"
		};

		const connections = {
			ingress: [],
			egress: []
		};

		hardwareDataArray.map(row => {
			if (row.type === "ingress") {
				connections.ingress.push(row);
			} else {
				connections.egress.push(row);
			}

			return connections;
		});

		return (
			<div>
				<AppBar position="static">
					<Tabs value={value} onChange={this.handleTabChange}>
						<Tab label="Routing" />
						{rol.some(r => rol_group_2.indexOf(r) >= 0) ? (
							<Tab
								label={
									!completeProfile ? (
										<Tooltip title="Please complete your general information first !">
											<div>External Connections</div>
										</Tooltip>
									) : (
										"External Connections"
									)
								}
								disabled={!completeProfile}
							/>
						) : (
							""
						)}
						{/* <Tab
              label="SBC"
              component="a"
              href="https://cms.tpio.us:8443/cataleya/"
              target="_blank"
            />
            <Tab label="Routes simulation" className="inactive-tab" /> */}
					</Tabs>
				</AppBar>
				<br />
				{value === 0 && (
					<div>
						<RoutesFilters
							{...this.props}
							onSubmit={this.handleRouteFilterSubmit}
							filterType={filterType}
							customers={filterData.customers}
							suppliers={filterData.suppliers}
							countries={filterData.countries}
							contracts={filterData.contracts}
							destinationCarriers={filterData.destCarriers}
							destinationTypes={filterData.destTypes}
							changeType={this.changeType}
							initialValues={{
								contractType: "selling"
							}}
						/>
						<br />
						<RoutesSmartContractsList
							title=""
							routesData={routesData}
							tableOptions={tableOptions}
							filterType={filterType}
						/>
					</div>
				)}
				{value === 1 && (
					<div>
						<Grid container spacing={16}>
							{participantData.partition && (
								<Grid item md={12}>
									<Typography
										variant="title"
										gutterBottom
										style={{ marginBottom: "20px" }}
									>
										{participantData.participantName} Ip Address:{" "}
										{participantData.partitionIp}
									</Typography>
								</Grid>
							)}
							<Grid item>
								<Typography variant="title" gutterBottom>
									Origination Connection Profiles (Ingress)
								</Typography>
							</Grid>
							<Grid item>
								<RenderButton
									color="primary"
									onClick={() => this.setNewTechnicalStateForm("ingress")}
								>
									New Origination connection
								</RenderButton>
							</Grid>
							<Grid item xs={12}>
								<TechnicalInformationList
									hardwareProfiles={connections.ingress}
									hardwareProfileOptionsData={hardwareProfileOptionsArray}
									updateTechnicalInfoSubmit={this.updateTechnicalInfoSubmit}
									deleteTechnicalInfoSubmit={this.deleteTechnicalInfoSubmit}
									countries={countriesDataArray}
									statesDataArray={statesDataArray}
									getStatesData={actionGetStatesPostDataRequest}
									externalClientData={externalClientData}
									externalRatesData={externalRatesData}
									externalRatePlanUploadData={externalRatePlanUploadData}
									uploadExternalRates={uploadExternalRateplan}
									loadExternalRates={loadExternalRates}
									externalInvoiceData={externalInvoiceData}
									loadExternalInvoicePreviewData={loadExternalInvoicePreviewData}
									createExternalInvoice={createExternalInvoice}
								/>
							</Grid>

							<Grid item>
								<Typography variant="title" gutterBottom>
									Termination Connection Profiles (Egress)
								</Typography>
							</Grid>
							<Grid item>
								<RenderButton
									color="primary"
									onClick={() => this.setNewTechnicalStateForm("egress")}
								>
									New Termination connection
								</RenderButton>
							</Grid>
							<Grid item xs={12}>
								<TechnicalInformationList
									hardwareProfiles={connections.egress}
									hardwareProfileOptionsData={hardwareProfileOptionsArray}
									updateTechnicalInfoSubmit={this.updateTechnicalInfoSubmit}
									deleteTechnicalInfoSubmit={this.deleteTechnicalInfoSubmit}
									loadVendorRates={loadVendorRates}
									loadExternalRates={loadExternalRates}
									vendorRatesData={vendorRatesData}
									externalRatesData={externalRatesData}
									uploadRates={uploadVendorRateplan}
									uploadExternalRates={uploadExternalRateplan}
									ratePlanUploadData={ratePlanUploadData}
									externalRatePlanUploadData={externalRatePlanUploadData}
									countries={countriesDataArray}
									statesDataArray={statesDataArray}
									getStatesData={actionGetStatesPostDataRequest}
									externalClientData={externalClientData}
								/>
							</Grid>
						</Grid>

						<CreateTechnicalInformationForm
							newTechnicalStateForm={newTechnicalStateForm}
							setStateForm={this.setNewTechnicalStateForm}
							handleSubmit={this.newTechnicalInformationSubmit}
							form={technicalInformationFormName}
							hardwareProfileOptionsData={hardwareProfileOptionsArray}
							technicalFormType={technicalFormType}
							hardwareProfiles={connections}
							uploadRates={uploadVendorRateplan}
							uploadExternalRates={uploadExternalRateplan}
							ratePlanUploadData={ratePlanUploadData}
							externalRatePlanUploadData={externalRatePlanUploadData}
							tempId={tempId}
							countries={countriesDataArray}
							statesDataArray={statesDataArray}
							getStatesData={actionGetStatesPostDataRequest}
						/>
					</div>
				)}

				{value === 3 && (
					<Grid container spacing={16} style={{ opacity: ".6" }}>
						<Grid item xs={12} md={6}>
							<Paper>
								<CardContent>
									<Typography variant="title" gutterBottom>
										Routes simulation
									</Typography>
								</CardContent>
							</Paper>
						</Grid>
					</Grid>
				)}

				<RenderNotification
					variant={
						(postResponseData && postResponseData.status === "OK") ||
						(postResponseData && postResponseData.status === 200) ||
						(postResponseData && postResponseData.status === "Ok")
							? "success"
							: "danger"
					}
					floating
					open={postResponseData != undefined}
					message={
						(postResponseData &&
							postResponseData.data &&
							postResponseData.data.message) ||
						(postResponseData && postResponseData.message)
					}
					onClose={this.handleNotificationClose}
				/>
			</div>
		);
	}
}

RoutesContainer.defaultProps = {
	smartContracts: []
};

const mapStateToProps = state => ({
	rol: fromUseraccount.getUserRol(state),
	notification: fromNotifications.getNotifications(state),
	participantData: fromMyaccount.getParticipantData(state),
	generalInformationData: fromMyaccount.getGeneralInformation(state),
	routesData: fromRoutes.getSmartContracts(state),
	hardwareDataArray: fromMyaccount.getHardwareDataArray(state),
	hardwareProfileOptionsArray: fromMyaccount.getHardwareProfileOptionsDataArray(
		state
	),
	statesDataArray: fromMyaccount.getStatesDataArray(state),
	countriesDataArray: fromMyaccount.getCountriesDataArray(state),
	postResponseData: fromMyaccount.getPostResponse(state),
	filterData: fromRoutes.getSmartContractsRouteDataFilters(state),
	ratePlanUploadData: fromRoutes.getVendorRatePlanUploadState(state),
	vendorRatesData: fromRoutes.getVendorRatesDataState(state),
	externalRatePlanUploadData: fromRoutes.getExternalRatePlanUploadState(state),
	externalRatesData: fromRoutes.getExternalRatesDataState(state),
	externalClientData: fromMyaccount.getExternalClientDataState(state),
	externalInvoiceData: fromRoutes.getExternalInvoiceDataState(state)
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			getRoutesSmartContractsList,
			actionNewHardwarePostDataRequest,
			actionTechnicalDetailsPostDataRequest,
			actionUpdateHardwareProfile,
			actionRemoveHardwareProfile,
			actionGetHardwareProfileOptionsPostDataRequest,
			getNotifications,
			actionMyAccountGetDataRequest,
			getRoutesSmartContractsListFilters,
			reset,
			setPostResponse,
			initializeReport,
			uploadVendorRateplan,
			uploadExternalRateplan,
			loadVendorRates,
			loadExternalRates,
			actionGetStatesPostDataRequest,
			actionExternalClientDetailsPostDataRequest,
			actionGetExternalClientData,
			loadExternalInvoicePreviewData,
			createExternalInvoice
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(RoutesContainer);
