import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Tooltip from "@material-ui/core/Tooltip";

const MyAccountSectionItem = props => {
  const disabled = (props.disabled !== undefined) ? props.disabled : false;
  const cssStyle = `nav-link ${props.itemState} ${disabled ? "menu-disabled" : ""}`;
  const expanded = !!(props.itemState !== undefined && props.itemState !== "");
  const roles = !!(props.roles !== undefined && props.roles !== []);

  if (props.currentRol.some(r => props.roles.indexOf(r) >= 0)) {
    return (
      <li>
        <a
          className={cssStyle}
          data-toggle="tab"
          role="tab"
          aria-expanded={expanded}
          onClick={() => disabled ? false : props.callbackFromParent(props.id)}
        >
          {disabled ? (
            <Tooltip title="Please complete your general information first !">
              <div>
                <span className="hidden-sm-up">
                <i className={props.icon} />
              </span>
                <span className="hidden-xs-down">{props.title}</span>
              </div>
            </Tooltip>
          ) : (
            <div>
              <span className="hidden-sm-up">
                <i className={props.icon} />
              </span>
              <span className="hidden-xs-down">{props.title}</span>
            </div>
          )}
        </a>
      </li>
    );
  }
  return false;
};

MyAccountSectionItem.propTypes = {
  itemState: PropTypes.string,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  callbackFromParent: PropTypes.func.isRequired,
  icon: PropTypes.string
};

MyAccountSectionItem.defaultProps = {
  itemState: "",
  icon: ""
};

export default MyAccountSectionItem;
