export const initialState = {}

export const initialChangePasswordState = {
    userName:null,
    oldPassword: null,
    newPassword:null,
    errorMessage:null,
    detailedMessage:null
}

export const getChangePasswordState = (state = initialState) =>
    state['CHANGE_PASSWORD'] || initialChangePasswordState


export const getUserName = (state = initialState) =>
    getChangePasswordState(state).userName

