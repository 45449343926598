import React, { Component } from "react";
import {
  Util,
  TableSuppliers,
  TableOpenOrders,
  TableFunders,
} from "components";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

class BiddingPortal extends Component {
  constructor(props) {
    super(props);
  }

  componentWillUnmount() {}

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card">
              <div className="card-body">
                {Util.isFunder(this.props.user) ? (
                  <h4 className="card-title">
                    {Util.isEmpty(this.props.dataBiddingPortal.walletAmount) ? (
                      <Link
                        to="/financials"
                        className="btn btn-sm btn-info btn-rounded"
                        onClick={event =>
                          this.props.actionCreatorSelectItem("mywallet")
                        }
                      >
                        <i className="fa fa-plus-square" aria-hidden="true" />
                        Place Order
                      </Link>
                    ) : (
                      <a
                        href="javascript:void(0)"
                        onClick={() => this.props.processAddOpenOrder()}
                        className="btn btn-sm btn-info btn-rounded btn-place-order-modal"
                      >
                        <i className="fa fa-plus-square" aria-hidden="true" />
                        &nbsp;Place Order
                      </a>
                    )}
                  </h4>
                ) : (
                  <div />
                )}
                <div className="card-header bg-info">
                  <h6 className="m-b-0 text-white">Open orders</h6>
                </div>
                <TableOpenOrders
                  contracts={this.props.dataBiddingPortal.contracts}
                  processCancelOpenOrder={this.props.processCancelOpenOrder}
                />

                <div className="row">
                  <div className="col-md-6">
                    <div className="card-header bg-info">
                      <h6 className="m-b-0 text-white">Supplier</h6>
                    </div>
                    <TableSuppliers
                      suppliers={this.props.dataBiddingPortal.suppliers}
                      processAcceptOpenOrder={this.props.processAcceptOpenOrder}
                    />
                  </div>

                  <div className="col-md-6">
                    <div className="card-header bg-info">
                      <h6 className="m-b-0 text-white">Funder</h6>
                    </div>
                    <TableFunders
                      funders={this.props.dataBiddingPortal.funders}
                      processAcceptOpenOrder={this.props.processAcceptOpenOrder}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
BiddingPortal.propTypes = {
  processCancelOpenOrder: PropTypes.func.isRequired,
  processAcceptOpenOrder: PropTypes.func.isRequired,
  processAddOpenOrder: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  dataBiddingPortal: PropTypes.object.isRequired,
};
export default BiddingPortal;
