import React from "react";
import { fromSmartcontracts } from "store/selectors";
import { bindActionCreators } from "redux";
import {
  getSmartContractFundingOffers,
  smartContractManageFundingRequest
} from "store/actions";
import { connect } from "react-redux";
import { SmartContractFundingOffers } from "components";

class SmartContractLendingContainer extends React.Component {
  constructor(props) {
    super(props);
    this.manageFundingOfferSubmit = this.manageFundingOfferSubmit.bind(this);
  }

  componentWillMount() {
    const { match } = this.props;
    if (match.params.smartContractid) {
      this.props.getSmartContractFundingOffers(match.params.smartContractid);
    }
  }

  manageFundingOfferSubmit = (offerId, data) => {
    const { match } = this.props;
    this.props.smartContractManageFundingRequest(match.params.smartContractid, offerId, data);
  };

  render() {
    const { fundingOffers } = this.props;
    return (
      <SmartContractFundingOffers
        fundingOffers={fundingOffers}
        manageFundingOfferSubmit={this.manageFundingOfferSubmit}
      />
    );
  }
}

SmartContractLendingContainer.defaultProps = {
  fundingOffers: {
    activeOffers: [],
    pendingOffers: [],
    declinedOffers: []
  }
};

function mapStateToProps(state) {
  return {
    fundingOffers: fromSmartcontracts.getSmartContractFundingOffers(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSmartContractFundingOffers,
      smartContractManageFundingRequest
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmartContractLendingContainer);
