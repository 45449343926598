import { API, Auth } from "aws-amplify";
import axios from "axios";

const BlockchainApi = {
  async postSendFundingRequest(data) {
    const requestData = {
      body: data
    };
    return API.post(
      "NODE_SERVICE_API",
      `/funding-requests/new`,
      requestData
    )
      .then(response => response)
      .catch(err => err);
  },
  async postSendFundingReview(data) {
    const requestData = {
      body: data
    };
    return API.post("NODE_SERVICE_API", `/contract/funding/review`, requestData)
      .then(response => response)
      .catch(err => err);
  },
  /**
   * Add a new participantwallet to the ledger
   * @param {object} data
   * {
   *  "participantId": "string",
   *  "name": "string",
   *  "type": "carrier-buyer",
   *  "taxId": "string",
   *  "registerDate": "2018-08-07",
   *  "walletBalance": 0
   * }
   */
  postCreateParticipantWallet(data) {
    const today = new Date();
    Auth.currentSession()
      .then(async session => {
        const newParticipantWallet = {
          participantId: data.participant.id,
          name: data.participant.participantName,
          type: "carrier-buyer",
          taxId: data.participant.taxId,
          registerDate: `${today.getFullYear()}-${today.getMonth() +
            1}-${today.getDate()}`,
          walletBalance: 0
        };
        return axios.post(
          `${await API.endpoint('NODE_SERVICE_API')}/participant/wallet`,
          newParticipantWallet,
          {
            headers: {
              token: session.idToken.jwtToken,
              "Content-Type": "application/json"
            }
          }
        );
      })
      .then(response => {
        console.log(response);
      })
      .catch(err => {
        console.log(err);
      });
  },

  /**
   * Find ParticipantWallet by Id
   * @param {int} participantId
   */
  getParticipantWalletByParticipantId(data) {
    return Auth.currentSession()
      .then(async session =>
        axios.get(`${await API.endpoint('NODE_SERVICE_API')}/participantwallet/${data.participant.id}`, {
          headers: {
            token: session.idToken.jwtToken,
            "Content-Type": "application/x-www-form-urlencoded"
          }
        })
      )
      .then(response => response.data)
      .catch(err => err);
  },

  /**
   * Find participantWalletTransaction by ID
   * @param {int} participwantWalletTransaction ID
   */
  getParticipantWalletTransactions(data) {
    return Auth.currentSession()
      .then(async session =>
        axios.get(
          `${await API.endpoint('NODE_SERVICE_API')}/participantwallet/${
            data.participant.id
          }/participantwallettransaction?lessThan=201808010000`,
          {
            headers: {
              token: session.idToken.jwtToken,
              "Content-Type": "application/json"
            }
          }
        )
      )
      .then(response => response.data)
      .catch(err => err);
  },

  /**
   * Create Wallet Transaction
   * @param {object} data
   * {
   *  "participantWalletTransactionId": "string",
   *  "participantId": "string",
   *  "timeStamp": "2018-08-07T21:14:47.558Z",
   *  "transactionType": "debit",
   *  "transferAmount": 0
   * }
   */
  postParticipantWalletTransaction(
    data,
    walletAction,
    initialData,
    transferType
  ) {
    let amount, transactionId;
    if (transferType === "credit") {
      amount = parseFloat(data);
      transactionId = Math.random()
        .toString(36)
        .substr(2, 9);
    } else {
      amount = parseFloat(data.amountTopUp);
      transactionId = data.paymentRef;
    }

    Auth.currentSession()
      .then(async session => {
        const newWalletTransaction = {
          participantWalletTransactionId: transactionId,
          participantId: initialData.participant.id,
          timeStamp: new Date().toISOString(),
          transactionType: transferType,
          transferAmount: amount
        };
        return axios.post(
          `${await API.endpoint('NODE_SERVICE_API')}/participantwallettransaction`,
          newWalletTransaction,
          {
            headers: {
              token: session.idToken.jwtToken,
              "Content-Type": "application/json"
            }
          }
        );
      })
      .then(response => {
        console.log(response);
      })
      .catch(err => {
        console.log(err);
      });
  },

  /**
   * Find participantWalletTransaction by ID
   * @param {int} transactionId
   */
  getParticipantWalletTransaction(transactionId) {
    return axios
      .get(`participantwallettransaction/${transactionId}`, data)
      .then(response => response)
      .catch(err => err);
  },

  /**
   * Create Contract Wallet
   * @param {object} data
   * {
   *  "contractId": "string",
   *  "buyerId": "string",
   *  "sellerId": "string",
   *  "walletBalance": 0,
   *  "contactLendingTerm": {
   *      "lenderId": "string",
   *      "intrersetRate": 0,
   *      "paymentTerms": "weekly"
   *  }
   * }
   */
  postContractWallet(data) {
    Auth.currentSession()
      .then(async session => {
        const newWallet = {
          contractId: data.smartContractId,
          buyerId: data.supplierId,
          sellerId: data.customerId,
          walletBalance: 0,
          contactLendingTerm: {
            lenderId: 0,
            intrerestRate: 0,
            paymentTerms: "weekly"
          }
        };
        return axios.post(`${await API.endpoint('NODE_SERVICE_API')}/contract/wallet`, newWallet, {
          headers: {
            token: session.idToken.jwtToken,
            "Content-Type": "application/json"
          }
        });
      })
      .then(response => response)
      .catch(err => err);
  },
  /**
   * Get Full Contract Information in one call
   * @param {int} contractId
   */
  getContractById(contractId) {
    return Auth.currentSession()
      .then(async session =>
        axios.get(`${await API.endpoint('NODE_SERVICE_API')}/contract/${contractId}`, {
          headers: {
            token: session.idToken.jwtToken,
            "Content-Type": "application/x-www-form-urlencoded"
          }
        })
      )
      .then(response => response.data)
      .catch(err => err);
  },

  /**
   * Get Contract Wallet by Contract Id
   * @param {int} contractId
   */
  async getContractWalletByContractId(contractId) {
    return axios
      .get(`${await API.endpoint('NODE_SERVICE_API')}/contractwallet/${contractId}`, data)
      .then(response => response)
      .catch(err => err);
  },

  /**
   * Get Contract Term by Contract Id
   * @param {int} contractId
   */
  async getContractTermByContractid(contractId) {
    return axios
      .get(`${await API.endpoint('NODE_SERVICE_API')}/contractwallet/${contractId}/contractrateterm`, data)
      .then(response => response)
      .catch(err => err);
  },
  /**
   * Get Contract Invoice by Contract Id
   * @param {int} contractId
   */
  async getContractInvoiceByContractId(contractId) {
    return axios
      .get(`${await API.endpoint('NODE_SERVICE_API')}/contractwallet/${contractId}/contractinvoice`, data)
      .then(response => response)
      .catch(err => err);
  },
  /**
   * Get Contract Settlement by Contract Id
   * @param {int} contractId
   */
  async getContractSettlementByContractId(contractId) {
    return axios
      .get(`${await API.endpoint('NODE_SERVICE_API')}/contractwallet/${contractId}/contractsettlement`, data)
      .then(response => response)
      .catch(err => err);
  },

  /**
   * Create Contract Rate Term
   * @param {object} data
   * {
   *  "contractId": "string",
   *  "rateId": "string",
   *  "timeStamp": "2018-08-07T21:14:47.601Z",
   *  "destination": "string",
   *  "startDate": "2018-08-07T21:14:47.601Z",
   *  "endDate": "2018-08-07T21:14:47.601Z"
   * }
   */
  async postContractRateTerm(data) {
    return axios
      .post(`${await API.endpoint('NODE_SERVICE_API')}/contractrateterm`, data)
      .then(response => response)
      .catch(err => err);
  },

  /**
   * Get Contract Term by Rate Id
   * @param {int} rateId
   */
  async getContractTermByRateId(rateId) {
    return axios
      .get(`${await API.endpoint('NODE_SERVICE_API')}/contractrateterm/${rateId}`, data)
      .then(response => response)
      .catch(err => err);
  },

  /**
   * Create Contract Invoice
   * @param {object} data
   * {
   *   "contractId": "string",
   *   "invoiceId": "string",
   *   "timeStamp": "2018-08-07T21:14:47.613Z",
   *   "totalCalls": 0,
   *   "totalMinutes": 0,
   *   "totalCharge": 0,
   *   "taxes": 0
   *   }
   */
  async postContractInvoice(data) {
    return axios
      .post(`${await API.endpoint('NODE_SERVICE_API')}/contractinvoice`, data)
      .then(response => response)
      .catch(err => err);
  },

  /**
   * Get Contract Invoice by invoice id
   * @param {int} invoiceId
   */
  async getContractInvoiceByInvoiceId(invoiceId) {
    return axios
      .get(`${await API.endpoint('NODE_SERVICE_API')}/contractinvoice/${rateId}`, data)
      .then(response => response)
      .catch(err => err);
  },

  /**
   * Create Contract Settlement
   * @param {object} data
   * {
   *  "contractId": "string",
   *  "settlementId": "string",
   *  "timeStamp": "2018-08-07T21:14:47.624Z",
   *  "dailyTotalCalls": 0,
   *  "dailyTotalMinutes": 0,
   *  "dailyTotalCharge": 0,
   *  "trafficFee": 0,
   *  "tesspayFee": 0,
   *  "interestFee": 0
   * }
   */
  async postContractSettlement(data) {
    return axios
      .post(`${await API.endpoint('NODE_SERVICE_API')}/contractsettlement`, data)
      .then(response => response)
      .catch(err => err);
  },

  /**
   * Get Contract Settlement by Id
   * @param {int} settlementId
   */
  async getContractSettlementBySettlementId(settlementId) {
    return axios
      .get(`${await API.endpoint('NODE_SERVICE_API')}/contractsettlement/${settlementId}${rateId}`, data)
      .then(response => response)
      .catch(err => err);
  }
};

export default BlockchainApi;
