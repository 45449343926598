import { TRANSACTIONS_LOADING, WALLET_LOADING, WALLET_TRANSACTIONS } from './actions';

export const initialState = {};
export const initialStateVoipApiData = {};

export const initialWalletData = {
	walletData: {
		bankAccount: [],
		wallet: null,
		lockedOnSmartContracts: '',
		totalAvailable: 0,
		totalBalance: 0,
		totalPending: 0,
		totalProjected: 0,
		pendingFunds: [],
		paymentRef: ''
	}
};

export const WALLET_DATA = 'WALLET_DATA';
export const VOIP_API_DATA = 'VOIP_API_DATA';
export const RESULT_WALLET = 'RESULT_WALLET';
export const LOAD_VOIP_API_DATA_SUCCESS = 'LOAD_VOIP_API_DATA_SUCCESS';

export const getWalletDataState = (state = initialState) => state[WALLET_DATA] || initialWalletData;
export const getWalletTransactions = (state = initialState) => state[WALLET_TRANSACTIONS] || { data: [] };

export const getWalletData = (state = initialState) => {
	const walletInfor = getWalletDataState(state).walletData;
	// console.log("walletInfor::" + walletInfor);
	return walletInfor;
};

export const getfiatAmountAvailable = (state = initialState) => {
	const wallet = getWalletDataState(state).walletData;
	let fiatAmountAvailable = 0;
	console.log(`wallet selector ::: getfiatAmountAvailable:::: ${wallet}`);
	if (wallet != null) {
		if (wallet.wallet != undefined || wallet.wallet != null) {
			for (let i = 0; i < wallet.wallet.length; i++) {
				fiatAmountAvailable = wallet.wallet[i].fiatAmount;
			}
		}
	}
	return fiatAmountAvailable;
};

export const getAmountTotal = (state = initialState) => {
	let amountTotal = 0;
	const wallet = getWalletDataState(state).walletData;
	const fiatAmount = getfiatAmountAvailable(state);
	if (wallet != null) {
		if (wallet.wallet != undefined || wallet.wallet != null) {
			amountTotal = wallet.lockedOnSmartContracts + fiatAmount;
		}
	}
	return amountTotal;
};

export const getButtonShow = (state = initialState) => {
	const wallet = getWalletDataState(state).walletData;
	let buttonToShow = true;
	if (wallet != null) {
		if (wallet.bankAccount != null || wallet.bankAccount != undefined) {
			const bankAccount = wallet.bankAccount[0];
			if (bankAccount.bankName == undefined || bankAccount.bankName == '') {
				buttonToShow;
			} else {
				buttonToShow = false;
			}
		}
		return buttonToShow;
	}
};

export const getDataBank = (state = initialState) => {
	const wallet = getWalletDataState(state).walletData;
	if (wallet != null && wallet.bankAccount !== undefined && wallet.bankAccount.size > 0) {
		var bankAccount = wallet.bankAccount[0];
	}

	return bankAccount;
};

export const resultWallet = (state = initialState) => state[RESULT_WALLET] || { resultWallet: undefined };

export const getResultWallet = (state = initialState) => resultWallet(state).resultWallet;

export const getVoipApiDataResponse = (state = initialStateVoipApiData) =>
	state.VOIP_API_DATA !== null && state.VOIP_API_DATA !== undefined && state.VOIP_API_DATA.data !== null && state.VOIP_API_DATA.data !== undefined
		? state.VOIP_API_DATA.data
		: '';

export const getVoipApiData = (state = initialStateVoipApiData) => getVoipApiDataResponse(state) || {};

export const getTransactionsLoading = (state = initialState) => state[TRANSACTIONS_LOADING] || false;
export const getWalletLoading = (state = initialState) => state[WALLET_LOADING] || false;
