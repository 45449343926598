import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default () => {
	// DEFINING FINANCIAL STATE
	const [selectedTab, setSelectedTab] = useState('wallet');
	const { t } = useTranslation('financials/general');

	// DEFINING INVOICE MODAL STATE
	const [modalVisible, setModalVisible] = useState(false);
	const [selectedInvoice, setSelectedInvoice] = useState({});
	const [selectedInvoiceType, setSelectedInvoiceType] = useState('');
	const [clientType, setClientType] = useState('customer');
	const [isOriginationInvoice, setIsOriginationInvoice] = useState(false);

	const toggleInvoiceModal = useCallback(
		(invoiceData, selectedInvoiceType) => {
			setSelectedInvoice(modalVisible ? {} : invoiceData);
			setSelectedInvoiceType(modalVisible ? '' : selectedInvoiceType);
			if (modalVisible) setIsOriginationInvoice(false);
			setModalVisible(!modalVisible);
		},
		[modalVisible]
	);

	//GETTING ISFUNDER VALUE
	const user = useSelector(state => (state.myaccount && state.myaccount.accountData) || {});

	const { role } = user;
	const isFunder = role.indexOf('funder') !== -1;

	//BULDING CONTEXT OBJECT

	const context = {
		t,
		modalVisible,
		setModalVisible,
		selectedInvoice,
		setSelectedInvoice,
		toggleInvoiceModal,
		selectedInvoiceType,
		setClientType,
		clientType,
		setIsOriginationInvoice,
		isOriginationInvoice
	};

	return { isFunder, selectedTab, setSelectedTab, context, t };
};
