import React, { useContext } from 'react';
import { FinancialContext } from 'screens/Financials';
import { CardContent, Typography } from '@material-ui/core';
import styles from './styles.module.scss';
import { RenderButton, RenderTable } from 'components';
import useGetColumns from '../hooks/useGetColumns';

export default ({ row, subInvoices, contractType }) => {
	const { t, toggleInvoiceModal, setIsOriginationInvoice, clientType } = useContext(FinancialContext);
	const { dailyInvoicesColumns } = useGetColumns();

	const handleInvoiceView = (data, usageData, isMaster) => {
		setIsOriginationInvoice(contractType === 'origination');
		toggleInvoiceModal(
			{
				original: data,
				usageSummary: usageData,
				isMaster,
				isStandard: contractType !== 'origination',
				invoiceType: clientType,
				subInvoices
			},
			'daily'
		);
	};

	return (
		<CardContent>
			<div className={styles.titleContainer}>
				<Typography variant="h1" align="left">
					{t('invoices.daily_invoices')}
				</Typography>
				<div className={styles.viewMaster}>
					<RenderButton
						color="primary"
						onClick={() => handleInvoiceView(
								row?.original && {
									...row.original.masterInvoice,
									paymentStatus: row.original.paymentStatus,
									dueDate: row.original.dueDate,
									exchange: row.original.exchangeRate
								},
								row?.original?.usageSummary,
								true
							)
						}
					>
						{t('invoices.view_master_invoice')}
					</RenderButton>
				</div>
			</div>
			<RenderTable
				data={subInvoices}
				columns={dailyInvoicesColumns}
				defaultSorted={[
					{
						id: 'endDate',
						desc: true
					}
				]}
				getTrProps={(state, rowInfo) => ({
					onClick: evt => handleInvoiceView(
						rowInfo?.original && {
							...rowInfo.original.invoice,
							dueDate: rowInfo.original.dueDate,
							exchange: rowInfo.original.invoice?.exchangeRate
						},
						rowInfo && rowInfo.original && rowInfo.original.usageSummary,
						false
					),
					style: {
						cursor: 'pointer'
					}
				})}
			/>
		</CardContent>
	);
};
