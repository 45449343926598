import camelCase from "lodash/camelCase";
import { reducer as form } from "redux-form";

import { reducer as idle } from "./idle/configure";

const reducers = {
  form,
  idle
};

const req = require.context(
  ".",
  true,
  /\.\/((?!__mocks__|__snapshots__).)*\/reducer\.js$/
);

req.keys().forEach(key => {
  const storeName = camelCase(key.replace(/\.\/(.+)\/.+$/, "$1"));
  reducers[storeName] = req(key).default;
});

export default reducers;
