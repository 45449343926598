import React, { useContext, memo } from 'react';
import { useSelector } from 'react-redux';
import { Paper, CardContent, Typography } from '@material-ui/core';

import { FinancialContext } from 'screens/Financials';
import InvoicesTable from './InvoicesTable';

export default ({ type }) => {
	const { t, setClientType } = useContext(FinancialContext);
	const { customer, supplier } = useSelector(state => state.reports.INVOICE_REPORT_DATA);

	let invoices = null;
	let title = '';

	switch (type) {
		case 'customer': {
			invoices = customer;
			title = 'invoices.customer_inv';
			setClientType('customer');
			break;
		}
		case 'supplier': {
			invoices = supplier;
			title = 'invoices.supplier_inv';
			setClientType('supplier');
			break;
		}
		default:
			invoices = customer;
			setClientType('customer');
	}

	return (
		<Paper>
			<CardContent>
				<Typography variant="h1" gutterBottom>
					{t(title)}
				</Typography>
				<InvoicesTable invoices={invoices} contractType="origination" />
				<InvoicesTable invoices={invoices} contractType="standard" />
			</CardContent>
		</Paper>
	);
};
