export const FINANCIAL = 'FINANCIAL';
export const RESULT_FINANCIAL = 'RESULT_FINANCIAL';
export const FINANCIAL_FAILURE = 'FINANCIAL_FAILURE';
export const SETTLEMENTS = 'SETTLEMENTS';
export const RESULT_SETTLEMENTS = 'RESULT_SETTLEMENTS';
export const SETTLEMENTS_FAILURE = 'SETTLEMENTS_FAILURE';
export const LOAD_LENDER_INVOICES_APPROVAL = 'LOAD_LENDER_INVOICES_APPROVAL';
export const LENDER_INVOICES_APPROVAL = 'LENDER_INVOICES_APPROVAL';
export const LENDER_INVOICES_APPROVAL_AGREE = 'LENDER_INVOICES_APPROVAL_AGREE';
export const CUSTOMER_PAY_INVOICE = 'CUSTOMER_PAY_INVOICE';
export const SUPPLIER_PAY_INVOICE = 'SUPPLIER_PAY_INVOICE';

export const getFinancial = userid => ({
	type: FINANCIAL,
	payload: { userid }
});
export const setFinancial = result => ({
	type: RESULT_FINANCIAL,
	payload: { result }
});
export const getFinancialError = getError => ({
	type: FINANCIAL_FAILURE,
	payload: { getError }
});

export const getSettlements = () => ({
	type: SETTLEMENTS
});
export const setSettlements = result => ({
	type: RESULT_SETTLEMENTS,
	payload: { result }
});
export const getSettlementsError = getError => ({
	type: SETTLEMENTS_FAILURE,
	payload: { getError }
});

export const loadLenderInvoices = data => ({
	type: LOAD_LENDER_INVOICES_APPROVAL,
	payload: data
});

export const setLenderInvoices = data => ({
	type: LENDER_INVOICES_APPROVAL,
	payload: data
});

export const lenderApproveInvoice = data => ({
	type: LENDER_INVOICES_APPROVAL_AGREE,
	payload: data
});

export const payInvoice = data => ({
	type: CUSTOMER_PAY_INVOICE,
	payload: data
});

export const supplierPayInvoice = data => {
	return {
		type: SUPPLIER_PAY_INVOICE,
		payload: data
	};
};
