export const initialState = {};

export const initialFinancialData = {
  result: {
    totalAmount: null,
    totalAvailable: null,
    row: [
      {
        created_at: null,
        type: null,
        payment_ref: null,
        in: null,
        out: 0,
        balance: 0
      }
    ]
  }
};

export const initialSettlementsData = {
  items: []
};

export const RESULT_FINANCIAL = "RESULT_FINANCIAL";
export const RESULT_SETTLEMENTS = "RESULT_SETTLEMENTS";
export const LENDER_INVOICES_APPROVAL = "LENDER_INVOICES_APPROVAL";

export const getFinancialState = (state = initialState) =>
  state[RESULT_FINANCIAL] || initialFinancialData;

export const getSettlements = (state = initialState) =>
  state[RESULT_SETTLEMENTS] || initialSettlementsData;

export const getLenderInvoicesApproval = (state = []) =>
  state[LENDER_INVOICES_APPROVAL] || [];
