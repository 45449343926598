export const INITIALIZE_BIDDING_PORTAL = "INITIALIZE_BIDDING_PORTAL";
export const CREATE_ORDER = "CREATE_ORDER";
export const CANCEL_ORDER = "CANCEL_ORDER";
export const ACCEPT_ORDER = "ACCEPT_ORDER";
export const ORDER = "ORDER";
export const RESULT_ORDER = "RESULT_ORDER";
export const RESULT_PROCESS_ORDER = "RESULT_PROCESS_ORDER";
export const RESULT_INITIALIZE_BIDDING_PORTAL =
  "RESULT_INITIALIZE_BIDDING_PORTAL";

export const createOrder = data => ({
  type: CREATE_ORDER,
  payload: data
});

export const initializeBiddingPortal = userId => ({
  type: INITIALIZE_BIDDING_PORTAL,
  payload: userId
});

export const cancelOrder = orderId => ({
  type: CANCEL_ORDER,
  payload: orderId
});

export const acceptOrder = orderId => ({
  type: ACCEPT_ORDER,
  payload: orderId
});

export const order = orderId => ({
  type: ORDER,
  payload: orderId
});

export const setResultProcessOrder = result => ({
  type: RESULT_PROCESS_ORDER,
  payload: {
    resultProcessOrder: result
  }
});

export const setOrder = result => ({
  type: RESULT_ORDER,
  payload: {
    order: result
  }
});

export const setInitializeBiddingPortal = result => ({
  type: RESULT_INITIALIZE_BIDDING_PORTAL,
  payload: {
    dataBiddingPortal: result
  }
});
