import React, { memo, useCallback, useEffect, useState } from 'react';
import { CardContent, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, RenderTable, AuthManager, ConfirmationDialog } from 'components';
import { Delete, Edit } from '@material-ui/icons';
import UpdateRoute from "../UpdateRoute"
import style from './style.module.scss';
import { useTranslation } from 'react-i18next';
import {contractIdFormatter} from "../../../../../utils";

const RoutesList = memo(({ smartContractId }) => {
	const dispatch = useDispatch();

	const isLoading = useSelector(state => (state.routes && state.routes.loadingRoutesList) || false);
	const isLoadingUpdateRoute = useSelector(state => (state.routes && state.routes.loadingRouteUpdate) || false);

	const routes = useSelector(state => (state.routes && state.routes.routesList) || []);

	const [updateRouteModalOpen, setUpdateRouteModalOpen] = useState(false);
	const [updateRouteId, setUpdateRouteId] = useState(false);
	const { t } = useTranslation('routing/routing');
	const triggerUpdateRoute = useCallback(
		routeId => {
			if (updateRouteModalOpen) {
				setUpdateRouteModalOpen(false);
				setUpdateRouteId(false);
				return;
			}
			setUpdateRouteModalOpen(true);
			setUpdateRouteId(routeId);
		},
		[updateRouteModalOpen]
	);
	const triggerRemoveRoute = useCallback(
		routeId => {
			dispatch({
				type: 'routes/DELETE_ROUTE',
				payload: {
					routeId,
					smartContractId
				}
			});
		},
		[updateRouteModalOpen]
	);

	useEffect(() => {
		dispatch({
			type: 'routes/GET_ROUTES_LIST',
			payload: smartContractId
		});
	}, []);

	const columns = [
		{
			Header: t('match_type'),
			accessor: 'matchType',
			width: 100,
			Cell: cell => {
				const { original } = cell;
				return original.matchType === 'D' ? 'Digit' : 'Name';
			}
		},
		{
			Header: t('match'),
			accessor: 'digitMatch',
			Cell: cell => {
				const { original } = cell;
				if (original.matchType === 'D') {
					return `${original.digitMatch}`;
				}
				let match = '';
				if (original.countryMatch && original.countryMatch.length) {
					match += `${original.countryMatch}`;
				}
				if (original.countryMatchType && original.countryMatchType.length) {
					match += ` (${original.countryMatchType}}`;
				}

				return match;
			}
		},
		// {
		// 	Header: t('description'),
		// 	description: 'description',
		// 	Cell: ({ original: { destinations, routeType } }) => {
		// 		return (
		// 			<div>
		// 				{destinations.map((destination) => (
		// 					<div>
		// 						{routeType === "P" && `${destination.percentage}% - `}
		// 						{destination.contract && contractIdFormatter(destination.contract._id, destination.contract.contractName, destination.contract.partnerName) || destination.hardwareProfile && destination.hardwareProfile.name || destination.id}
		// 					</div>
		// 				))}
		// 			</div>
		// 		)
		// 	}
		// },
		{
			Header: t('route_type'),
			routeType: 'routeType',
			width: 200,
			Cell: cell => {
				const { original } = cell;
				if (original.routeType === 'S') return 'Static';
				if (original.routeType === 'P') return 'Percentage';
				if (original.routeType === 'R') return 'LCR';
				return original.routeType;
			}
		},
		{
			Header: t('route_up'),
			accessor: '',
			Cell: ({ original: { destinations, routeType } }) => {
				return (
					<div>
						{destinations.map((destination) => (
							<div>
								{routeType === "P" && `${destination.percentage}% - `}
								{destination.contract && contractIdFormatter(destination.contract._id, destination.contract.contractName, destination.contract.partnerName) || destination.hardwareProfile && destination.hardwareProfile.name || destination.id}
							</div>
						))}
					</div>
				)
			}
		},
		{
			Header: '',
			accessor: 'actions',
			width: 150,
			Cell: cell => {
				const { original } = cell;

				return (
					<div className="table-actions">
						<AuthManager permissions={['routing.update-routes']}>
							<IconButton onClick={() => triggerUpdateRoute(original._id)} color="primary" size="small" disabled={isLoadingUpdateRoute}>
								<Edit />
							</IconButton>
						</AuthManager>
						<AuthManager permissions={['routing.delete-routes']}>
							<ConfirmationDialog
								title={t('confirm_delete_route')}
								onConfirm={() => triggerRemoveRoute(original._id)}
								okText={t('okText_delete')}
								cancelText="No"
								buttonProps={{
									type: 'danger',
									size: 'small',
									disabled: isLoadingUpdateRoute
								}}
							>
								<Delete />
							</ConfirmationDialog>
						</AuthManager>
					</div>
				);
			}
		}
	];

	const forceSortData = (data) => {
		return data.sort((a, b) => {
			const aValue = a.matchType && a.matchType === 'D' && a.digitMatch || a.matchType && a.matchType === 'N' && a.countryMatch || "0"
			const bValue = b.matchType && b.matchType === 'D' && b.digitMatch || b.matchType && b.matchType === 'N' && b.countryMatch || "0"
			if(aValue === "*") return -1
			if(bValue === "*") return 1

			return parseInt(aValue) - parseInt(bValue)
		})
	}

	return (
		<div className={style.container}>
			<Paper>
				{((isLoading || isLoadingUpdateRoute) && <Loader loading />) || ''}
				<CardContent>
					<RenderTable data={forceSortData((routes && Array.isArray(routes) && routes) || [])} columns={columns} filterable={false} />
				</CardContent>
			</Paper>

			<UpdateRoute open={updateRouteModalOpen} triggerOpenClose={triggerUpdateRoute} routeId={updateRouteId} smartContractId={smartContractId} />
		</div>
	);
});

export default RoutesList;
