import React, { memo, useCallback, useState } from 'react';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import { AuthManager } from 'components';
import { RoutingTab, HardwareProfilesTab } from './components';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

export default memo(() => {
	const [selectedTab, setSelectedTab] = useState('routing');
	const { t } = useTranslation('routing/routing');
	return (
		<div className={styles.container}>
			<div className="head-tabs">
				<AppBar position="static">
					<Tabs value={selectedTab} onChange={(e, value) => setSelectedTab(value)}>
						<AuthManager permissions={['routing.view-routes']} value="routing">
							<Tab label={t('routing')} value="routing" onChange={(e, value) => setSelectedTab(value)} />
						</AuthManager>
						<AuthManager permissions={['routing.view-routes']} userRole={['carrier']}>
							<Tab label={t('external_connections')} value="external-connections" onChange={(e, value) => setSelectedTab(value)} />
						</AuthManager>
						<AuthManager permissions={['routing.view-routes']} userRole={['sbc']}>
							<Tab label="SBC" component="a" href="https://cms.tpio.us:8443/cataleya/" target="_blank" />
						</AuthManager>
						<AuthManager permissions={['routing.view-routes']} userRole={['routes-simulation']}>
							<Tab label={t('routing_simulation')} className="inactive-tab" />
						</AuthManager>
					</Tabs>
				</AppBar>
			</div>
			{(selectedTab === 'routing' && <RoutingTab />) || ''}
			{(selectedTab === 'external-connections' && <HardwareProfilesTab />) || ''}
		</div>
	);
});
