import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { RenderButton } from "components";

const SmartContractDialCodesModal = props => {
  const { selectCodes, codeRowIndex, dialsCodes, checkSelect } = props;
  return (
    <Grid container>
      <Grid item xs={12}>
        <RenderButton
          color="primary"
          onClick={() => selectCodes(codeRowIndex, true)}
        >
          Check All
        </RenderButton>
        <RenderButton
          color="warning"
          onClick={() => selectCodes(codeRowIndex, false)}
        >
          Clear All
        </RenderButton>
      </Grid>
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="10%">#</TableCell>
              <TableCell width="20%">Dial Code</TableCell>
              <TableCell width="20%">Select</TableCell>
              <TableCell width="50%">Destination</TableCell>
              <TableCell width="20%">Type</TableCell>
            </TableRow>
          </TableHead>
          {dialsCodes && (
            <TableBody>
              {dialsCodes.map((codeDial, i) => (
                <TableRow key={codeDial.code}>
                  <TableCell> {i + 1} </TableCell>
                  <TableCell> {codeDial.code} </TableCell>
                  <TableCell>
                    <FormControl>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={codeDial.id}
                              name="offer[codes_selected][]"
                              checked={
                                codeDial.selected !== undefined
                                  ? codeDial.selected
                                  : true
                              }
                              onClick={event =>
                                checkSelect(
                                  event.target.value,
                                  codeRowIndex,
                                  event.target.checked
                                )
                              }
                            />
                          }
                        />
                      </FormGroup>
                    </FormControl>
                  </TableCell>
                  <TableCell> {codeDial.destination} </TableCell>
                  <TableCell> {codeDial.type} </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </Grid>
    </Grid>
  );
};

export default SmartContractDialCodesModal;
