export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const CLEAN_DATA_REGISTER = "CLEAN_DATA_REGISTER";

export const registerRequest = data => ({
  type: REGISTER_REQUEST,
  payload: data
});
export const registerFailure = errorMessage => ({
  type: REGISTER_FAILURE,
  payload: errorMessage
});
export const registerSuccess = successMessage => ({
  type: REGISTER_SUCCESS,
  payload: successMessage
});

export const cleanDataRegister = () => ({
  type: CLEAN_DATA_REGISTER
});
