import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Dialog, DialogActions, DialogContent, Grid, InputAdornment, Slide, Typography } from '@material-ui/core';
import { RenderSelect, RenderInput, RenderButton, CurrencyNumber } from 'components';
import { createValidator, required, validateWithdrawAmount } from 'services/validation';
import { withTranslation } from 'react-i18next';

const Transition = props => <Slide direction="up" {...props} />;

class WithDrawForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bankAccountId: false,
			selectedBankAccount: false
		};

		this.handleBankAccountChange = this.handleBankAccountChange.bind(this);
	}

	componentDidUpdate(newProps) {
		if (newProps && newProps.bankAccountId) {
			this.handleBankAccountChange(newProps.bankAccountId);
		}
	}

	handleBankAccountChange(option) {
		const { bankAccounts } = this.props;
		const selectedBankAccount = bankAccounts.find(obj => obj._id === option.value);

		this.setState({
			bankAccountId: option.value,
			selectedBankAccount
		});
	}

	render() {
		const { bankAccounts, handleSubmit, openCloseHandler, open, t } = this.props;
		const { selectedBankAccount } = this.state;

		return (
			<Dialog maxWidth="sm" fullWidth open={open} onBackdropClick={openCloseHandler} TransitionComponent={Transition}>
				<form onSubmit={handleSubmit} autoComplete="off">
					<DialogContent>
						<Grid container alignItems="center">
							<Grid item xs>
								<Field
									name="withdrawAmount"
									label={t('withdraw_modal.amount')}
									type="number"
									component={RenderInput}
									value=""
									InputProps={{
										startAdornment: (
											<InputAdornment variant="filled" position="start" style={{ marginTop: 0 }}>
												<CurrencyNumber amount="" />
											</InputAdornment>
										)
									}}
								/>
							</Grid>
							<Grid item xs>
								<Field
									name="bankAccountId"
									label={t('withdraw_modal.bank_account')}
									component={RenderSelect}
									callback={this.handleBankAccountChange}
									options={
										bankAccounts
											? bankAccounts.map(option => ({
													value: option._id,
													label: option.bankName
											  }))
											: []
									}
								/>
							</Grid>
							{selectedBankAccount ? (
								<Grid item xs={12}>
									<br />
									<Typography variant="h5">{t('bank.your_bank_title')}</Typography>
									<br />
									<p>
										<strong>{t('bank.account_name')}:</strong> {selectedBankAccount.accountName}
									</p>
									<p>
										<strong>{t('bank.benef_address')}:</strong> {selectedBankAccount.accountAddress}
									</p>
									<p>
										<strong>{t('bank.benef_acc_no')}:</strong> {selectedBankAccount.accountNumber}
									</p>
									<p>
										<strong>{t('bank.bank_name')}:</strong> {selectedBankAccount.bankName}
									</p>
									<p>
										<strong>{t('bank.bank_address')}:</strong> {selectedBankAccount.bankAddress}
									</p>
									<p>
										<strong>{t('bank.bank_aba')}:</strong> {selectedBankAccount.abaAch}
									</p>
									<p>
										<strong>{t('bank.bank_swift')}:</strong> {selectedBankAccount.swift}
									</p>
								</Grid>
							) : (
								<Grid item xs={12}>
									<Typography variant="h4" align="center">
										{t('withdraw_modal.please_select_bank')}
									</Typography>
								</Grid>
							)}
						</Grid>
					</DialogContent>
					<DialogActions>
						<RenderButton color="danger" onClick={openCloseHandler}>
							{t('translations:close')}
						</RenderButton>
						<RenderButton color="primary" type="submit">
							{t('withdraw_modal.btn_submit')}
						</RenderButton>
					</DialogActions>
				</form>
			</Dialog>
		);
	}
}

const validate = createValidator({
	withdrawAmount: [validateWithdrawAmount],
	bankAccountId: [required]
});

const WithDrawFormTranslated = withTranslation(['financials/general', 'translations'])(WithDrawForm);

export default reduxForm({
	form: 'withdrawForm',
	enableReinitialize: true,
	destroyOnUnmount: true,
	validate
})(WithDrawFormTranslated);
