import React, { useEffect, useState } from 'react';
import * as locales from 'react-date-range/dist/locale';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';



const RenderDateRangePicker = ({ formik: { setFieldValue }, formik, value, name, onChange, label, setDisplayExternalPicker, ...rest }) => {
    if (!name || !formik) {
		console.log('FORMIK RenderDateRangePicker ERORR!: name or formik required', { name, formik });
		return '';
	}
	const { i18n } = useTranslation();
    const [mutatedDates, setMutatedDates] = useState(false)
    const [dateRange, setDateRange] = useState([
        {
            startDate: value && value[0] && new Date(value[0]) || undefined,
			endDate: value && value[1] && new Date(value[1]) || undefined,
            key: 'selection'
        }
    ])

    useEffect(() => {
        const dates = [moment(dateRange[0].startDate), moment(dateRange[0].endDate)]
        setFieldValue(name, dates)
        if (!dates[0].isSame(dates[1]) && mutatedDates) {
            setDisplayExternalPicker(false)
        }
    }, [ dateRange, mutatedDates ])

    return (
            <DateRangePicker
            {...rest}
            inputRanges={[]}
            maxDate={ new Date() }
            locale={i18n.language === 'en' ? locales['enUS'] : locales['es']}
            onChange={item => {
                setDateRange([ item.selection ])
                setMutatedDates(true)
            }}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            rangeColors={['#0c553e', '#0c553e', '#0c553e']}
            editableDateInputs={false}
            months={2}
            ranges={dateRange}
            direction='horizontal'
            />
    )

}

export default RenderDateRangePicker
