import React, { memo, useEffect, useState } from 'react';
import { Tab } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

export default memo(({ userRole, permissions, ...rest }) => {
	const dispatch = useDispatch();

	const accountData = useSelector(state => (state.myaccount && state.myaccount.accountData) || false);

	const [operationPermitted, setOperationPermitted] = useState(false);
	const [loaded, setLoaded] = useState(false);

	useEffect(
		() => () => {
			setLoaded(false);
		},
		[]
	);

	useEffect(() => {
		if (!accountData) {
			dispatch({ type: 'myaccount/GET_ACCOUNT_DETAILS' });
		}
	}, [accountData]);

	useEffect(() => {
		let hasPermission = false;

		if (accountData && accountData.permissions) {
			permissions.map(permission => {
				accountData.permissions.map(upermission => {
					// console.log(upermission.toLowerCase(), " - ", permission.toLowerCase(), " ==== ", upermission.toLowerCase().indexOf(permission.toLowerCase()))
					if (upermission.toLowerCase().indexOf(permission.toLowerCase()) > -1) {
						hasPermission = true;
					}
				});
			});
		}

		if (accountData && accountData.primaryAccount) {
			hasPermission = true;
		}

		if (userRole && typeof userRole !== 'undefined' && accountData.role && accountData.role.toLowerCase() !== userRole.toLowerCase()) {
			hasPermission = false;
		}

		setOperationPermitted(hasPermission);

		setLoaded(true);
	}, [accountData]);

	if (loaded && operationPermitted && accountData && accountData.role) {
		return <Tab {...rest} hi />;
	}
	return <Tab hidden style={{ display: 'none' }} />
});
