import { USER_INFO_DATA } from './selectors';
import actions, { USER_INFO_SUCCESS } from './actions';

const initialState = {
	loading: false,
	tokenResponse: false
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case USER_INFO_SUCCESS:
			return {
				...state,
				[USER_INFO_DATA]: {
					userInfo: payload
				}
			};
		case actions.SET_STATE:
			return { ...state, ...payload };
		default:
			return state;
	}
};
