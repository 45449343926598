import React, { memo, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Grid, Paper, CardContent, Button, CardHeader } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useFormik } from 'formik';
import actions from 'store/contracts/actions';
import * as Yup from 'yup';
import moment from 'moment';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { RenderButton, RenderFormikInput, RenderFormikSelect, RenderFormikDropzoneArea, RenderFormikDatePicker, RenderFormikCheckbox, RenderModal, RenderTable } from 'components';
import styles from './styles.module.scss';

export default memo(() => {
	const { smartContractid } = useParams();

	const dispatch = useDispatch();
	const [termsModalOpen, setTermsModalOpen] = useState(false);
	const [expanded, setExpanded] = useState(null);

	const isLoading = useSelector(state => state.contracts.loading);
	const { customers, suppliers, invoicePeriods, trafficTypes, paymentTerms } = useSelector(state => state.contracts.newSmartContractData || {});
	const smartContract = useSelector(state => state.contracts.smartContract || {});
	const rateFilesData = useSelector(state => state.contracts.uploadedRateFiles || {});

	const ratesTotal = rateFilesData?.length

	const formik = useFormik({
		initialValues: {
			contractType: '',
			supplierId: '',
			customerId: '',
			trafficType: '',
			isOriginContract: '',
			minimumNoticeDays: '',
			invoicePeriodId: '',
			paymentTerms: '',
			endDate: '',
			ratesFile: '',
		},
		onSubmit: values => {
			dispatch({
				type: actions.UPDATE_SMART_CONTRACT,
				payload: {
					_id: smartContractid,
					isOriginContract: values.isOriginContract === 'true' ? true : false,
					status: 'pending',
					...values
				}
			});
		},
		validationSchema: Yup.object().shape({
			supplierId: Yup.string().when('contractType', {
				is: 'buy',
				then: Yup.string().required('Please select a supplier')
			}),
			customerId: Yup.string().when('contractType', {
				is: 'sell',
				then: Yup.string().required('Please select a customer')
			}),
			minimumNoticeDays: Yup.string().required('Please input a minimum notice period'),
			invoicePeriodId: Yup.string().required('Please select billing cycle'),
			paymentTerms: Yup.string()
			.when('invoicePeriodId', (invoicePeriodId, schema) => (invoicePeriodId === '5' && schema) || schema.required('Please select billing cycle'))
			.nullable(),
		})
	});

	const formikRates = useFormik({
		initialValues: {
			ratesFile: {}
		},
		onSubmit: values => {
			const formData = new FormData();
			formData.append('tempId', smartContract.tempId);
			formData.append('ratesFile', values.ratesFile, values.ratesFile.name || null);

			dispatch({
				type: actions.UPLOAD_RATES_FILE,
				payload: formData,
			})
		}
	})

	const columns = [
		{
			Header: "Country Name",
			Cell: props => props.original.countryName || ''
		},
		{
			Header: "Country Code",
			Cell: props => props.original.countryCode || ''
		},
		{
			Header: "Effective Date",
			Cell: props => props.original.effectiveDate || ''
		},
		{
			Header: "End Date",
			Cell: props => props.original.endDate || ''
		},
		{
			Header: "Rate",
			Cell: props => props.original.rate || ''
		},
		{
			Header: "Old Rate",
			Cell: props => props.original.oldRate || ''
		},
		{
			Header: "Initial Increment",
			Cell: props => props.original.initialIncrement || ''
		},
		{
			Header: "Additional Increment",
			Cell: props => props.original.additionalIncrement || ''
		},
	]

	const handleSignContract = useCallback(() => {
		formik.setFieldValue('draft', false);
		formik.handleSubmit();
	}, []);

	const handleDownloadSample = useCallback(e => {
		e.preventDefault();
		e.stopPropagation();
		dispatch({
			type: 'smartcontracts/DOWNLOAD_SAMPLE_RATEPLAN'
		});
	}, []);

	const handleSecondSignature = useCallback(() => {
		dispatch({
			type: actions.SECOND_SIGNATURE,
			payload: smartContractid
		});
	}, [])

	const downloadRateFile = useCallback((rateplanId) => {
		dispatch({
			type: actions.DOWNLOAD_RATE_FILE,
			payload: rateplanId
		});
	}, []);

	useEffect(() => {
		formik.setFieldValue('contractType', smartContract.type);
		formik.setFieldValue('supplierId', smartContract?.supplier?.id);
		formik.setFieldValue('customerId', smartContract?.customer?.id);
		formik.setFieldValue('trafficType', smartContract.trafficType);
		formik.setFieldValue('isOriginContract', smartContract.isOriginContract ? 'true' : 'false');
		formik.setFieldValue('minimumNoticeDays', smartContract.minimumNoticeDays);
		formik.setFieldValue('paymentTerms', smartContract.paymentTerms);
		formik.setFieldValue('endDate', smartContract.endDate || '');
		if (smartContract.customer?.name) {
			formik.setFieldValue('customerName', smartContract?.customer?.name || '')
		} else {
			formik.setFieldValue('supplierName', smartContract?.supplier?.name || '')
		}
	}, [smartContract])

	useEffect(() => {
		if (invoicePeriods?.length > 0 && smartContract?.billingCycle) {
			formik.setFieldValue('invoicePeriodId', invoicePeriods.find(invPeriod => invPeriod?.label === smartContract?.billingCycle ? invPeriod?.value : '')?.value );
		}
	}, [smartContract, invoicePeriods])

	useEffect(() => {
		dispatch({
			type: actions.GET_SMART_CONTRACT,
			payload: smartContractid
		});
		dispatch({ type: actions.GET_NEW_SMART_CONTRACT_DATA });
	}, []);

	useEffect(() => {
		dispatch({
			type: actions.GET_RATE_FILES_DATA,
			payload: smartContract.tempId
		});
	}, [smartContract])

	return (
		<div className={styles.container}>
			<Grid container spacing={2}>
				<Grid item xs={8}>
					<Paper>
						<CardContent>
							<Grid container spacing={2}>
								<Grid item xs={4}>
									<RenderFormikSelect
										formik={formik}
										label="Contract type"
										name="contractType"
										disabled={smartContract.status !== 'draft'}
										options={[
											{
												value: 'sell',
												label: 'Sell'
											},
											{
												value: 'buy',
												label: 'Buy'
											}
										]}
									/>
								</Grid>
								{smartContract.isOwner && (
									<Grid item xs={8}>
										{(formik.values.contractType === 'buy' && (
											<RenderFormikSelect disabled={smartContract.status !== 'draft'} label="Select Supplier" name="supplierId" formik={formik} options={suppliers || []} required />
										)) ||
											null}
										{(formik.values.contractType === 'sell' && (
											<RenderFormikSelect disabled={smartContract.status !== 'draft'} label="Select Customer" name="customerId" formik={formik} options={customers || []} required />
										)) ||
											null}
									</Grid>
								) || ''}
								{!smartContract.isOwner && smartContract.customer?.name && (
									<Grid item xs={8}>
										<RenderFormikInput disabled={smartContract.status !== 'draft'} label="Customer Name" name="customerName" formik={formik} />
									</Grid>
								) || ''}
								{!smartContract.isOwner && smartContract.supplier?.name && (
									<Grid item xs={8}>
										<RenderFormikInput disabled={smartContract.status !== 'draft'} label="Supplier rName" name="supplierName" formik={formik} />
									</Grid>
								) || ''}
							</Grid>
						</CardContent>
					</Paper>

					<Paper>
						<CardHeader title="Rates" />
						<CardContent>
							{rateFilesData?.length > 0 && !smartContract.isOwner && rateFilesData.map((rateFile, idx) => (
								<>
									<RenderTable
										data={rateFile.data || []}
										tableTotal={ratesTotal}
										columns={columns}
										showPagination={true}
										sortable={false}
										defaultPageSize={10}
										minRows={2}
										noDataText={(
											<div>
												<strong>There are no rate files data</strong>
											</div>
										)}
									/>
									<Accordion key={idx} expanded={expanded === `panel${idx}`} onChange={() => setExpanded(state => state !== `panel${idx}` && `panel${idx}` || null)}>
										<AccordionSummary expandIcon={<ExpandMoreIcon />}>
											<div className='row'>
												<Typography>
													{rateFile?.ratePlan?.fileName} uploaded at {moment(rateFile?.ratePlan?.createdAt).format('MMM DD YYYY HH:mm')}
												</Typography>
												<Typography variant="caption">
													{rateFile.ratePlan.billingSystemStatus || ''}
												</Typography>
											</div>
										</AccordionSummary>
										<AccordionDetails>
											<Button onClick={() => downloadRateFile(rateFile.ratePlan._id)}>
												Download
											</Button>
										</AccordionDetails>
									</Accordion>
								</>
							)) || ''}

							{rateFilesData?.length > 0 && smartContract.isOwner && (
								<RenderFormikDropzoneArea
									filesLimit={1}
									multiple={false}
									disabled={smartContract.status !== 'draft'}
									dropzoneText={
										<React.Fragment>
											<p>Upload rates file</p>
											<div>
												<RenderButton color="secondary" type="button" onClick={handleDownloadSample}>
													Download sample
												</RenderButton>
											</div>
										</React.Fragment>
									}
									formik={formikRates}
									name="ratesFile"
									onChange={() => {
										formikRates.handleSubmit()
									}}
								/>
							)}

						</CardContent>
					</Paper>
				</Grid>
				<Grid item xs={4}>
					<Paper>
						<CardHeader title="Technical terms" />
						<CardContent>
							<RenderFormikSelect disabled={smartContract.status !== 'draft'} label="Traffic type" name="trafficType" formik={formik} options={trafficTypes || []} />

							<RenderFormikSelect
								label="Contract Type"
								name="isOriginContract"
								formik={formik}
								disabled={smartContract.status !== 'draft'}
								options={[
									{
										value: 'true',
										label: 'Origination'
									},
									{
										value: 'false',
										label: 'Standard'
									}
								]}
							/>
						</CardContent>
					</Paper>
					<Paper>
						<CardHeader
							title="Commercial Terms"
							subheader={
								<div>
									<strong>Settlement period:</strong> 24 Hours <br />
									<strong>Billing timezone: </strong> GMT
								</div>
							}
						/>
						<CardContent>
							<RenderFormikInput disabled={smartContract.status !== 'draft'} label="Minimum Notice Period (days)" name="minimumNoticeDays" formik={formik} type="number" />
							<RenderFormikSelect
								label="Billing Cycle"
								name="invoicePeriodId"
								disabled={smartContract.status !== 'draft'}
								formik={formik}
								options={invoicePeriods || []}
								onChange={e => {
									formik.setFieldValue('paymentTerms', null);
								}}
							/>
							{(formik.values.invoicePeriodId !== '5' && <RenderFormikSelect disabled={smartContract.status !== 'draft'} label="Payment Terms" name="paymentTerms" formik={formik} options={paymentTerms || []} />) ||
								null}

							<RenderFormikDatePicker disabled={smartContract.status !== 'draft'} formik={formik} name="endDate" label="Contract end date" dateFormat="MM DD YYYY" clearable disablePast emptyLabel="Open" />
						</CardContent>
					</Paper>
				</Grid>
			</Grid>
			{rateFilesData?.length > 0 && smartContract.isOwner && (
				<CardHeader title="Uploaded Rates" />
			) || ''}
			{rateFilesData?.length > 0 && smartContract.isOwner && rateFilesData.map((rateFile, idx) => (
				<Paper>
					<CardContent>
						<RenderTable
							data={rateFile.data || []}
							columns={columns}
							showPagination={true}
							sortable={false}
							minRows={2}
							noDataText={(
								<div>
									<strong>There are no rate files data</strong>
								</div>
							)}
						/>
						<Accordion key={idx} expanded={expanded === `panel${idx}`} onChange={() => setExpanded(state => state !== `panel${idx}` && `panel${idx}` || null)}>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<div className='row'>
									<Typography>
										{rateFile?.ratePlan?.fileName} uploaded at {moment(rateFile?.ratePlan?.createdAt).format('MMM DD YYYY HH:mm')}
									</Typography>
									<Typography variant="caption">
										{rateFile.ratePlan.billingSystemStatus || ''}
									</Typography>
								</div>
							</AccordionSummary>
							<AccordionDetails>
								<Button onClick={() => downloadRateFile(rateFile.ratePlan._id)}>
									Download
								</Button>
							</AccordionDetails>
						</Accordion>
					</CardContent>
				</Paper>
			)) || ''}

			<Paper>
				<CardContent className="footer-buttons">
					<Button href="/smart-contracts" variant="text">
						Back
					</Button>
					<div>
						{smartContract.isOwner && smartContract.status === 'draft' && (
							<RenderButton color="primary" type="submit" disabled={isLoading} onClick={handleSignContract}>
								Sign contract
							</RenderButton>
						) || ''}
						{smartContract.isOwner && smartContract.status !== 'draft' && smartContract.status !== 'active' && (
							<RenderButton color="secondary" disabled>
								Awaiting signature
							</RenderButton>
						) || ''}
						{!smartContract.isOwner && smartContract.status !== 'draft' && smartContract.status !== 'active' && (
							<RenderButton color="primary" type="submit" disabled={isLoading} onClick={handleSecondSignature}>
								Sign contract
							</RenderButton>
						) || ''}
						{smartContract.status === 'active' && (
							<RenderButton color="secondary" disabled>
								Active
							</RenderButton>
						) || ''}
					</div>
				</CardContent>
			</Paper>

			<RenderModal title={'Tesspay Terms & Conditions'} open={termsModalOpen} onModalClose={() => setTermsModalOpen(false)}>
				Component and sub-component supply chain settlements (payments) are about to undergo a dramatic transition from the present state wherein payments down the chain
				are essentially manual (triggered by a manger or trusted party), to a blockchain protocol that mandates simultaneous payment of all component and sub-component
				suppliers when the primary party makes a payment into the chain. Using TESSPAY Smart Contracts a payment from the primary party in the chain will be automatically
				apportioned to all other eligible parties in the chain concomitant with payment by the primary party. The creditworthy financial condition of the primary party will
				facilitate lender involvement in the supply chain under the security afforded by the TESSPAY Smart Contract. Inefficient manual pay mechanisms will be replaced by
				programmed peer to peer algorithmically driven payment structures that will reside in sophisticated smart contracts and public or semi-public ledgers. Billions of
				cryptocurrency values are already transacted securely in distributed public ledgers. Bitcoin, Ethereum and other blockchain networks have demonstrated the viability
				of payments made without a central manager or a trusted party who endorses the payment events. The TESSPAY transaction settlement service will be first applied, by
				way of proof of concept, to the global telecommunications industry to settle cross border voice call minutes transferred between telecommunications carriers.
				Transactions between telecos will be effected under TESSPAY Smart Contracts using fixed value closed loop TESSPAY-Minute-Tokens. Miners who will operate and secure
				the blockchain semi-public ledgers in support of these transactions will be rewarded by receipt of TESSPAY-Minute-Tokens dispensed by TESSPAY for work done
			</RenderModal>
		</div>
	);
});
