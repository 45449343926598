import React from 'react';
import { Drawer, Typography } from '@material-ui/core';
import Loader from 'components/atoms/Loader';
import RenderButton from 'components/atoms/RenderButton';
import { ThumbDown, ThumbUp } from '@material-ui/icons';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { colorStatusText, handleCurrency } from 'utils';
import moment from 'moment';

export const ReviewDrawer = ({ selectedInvoice, setSelectedInvoice, handleUpdatePof }) => {
	const { loading, pofUrl } = useSelector(state => state.escrowmanaging || {});
	return (
		<Drawer className={styles.drawer} anchor="right" open={!!selectedInvoice} onClose={() => setSelectedInvoice(null)}>
			<div className="content">
				{loading.orders && <Loader loading={true} fullscreen />}
				<Typography>Invoice</Typography>
				<Typography variant="h1">{selectedInvoice?.invoiceNumber}</Typography>
				{loading.pof ? <Loader className="pdf" /> : <object className="pdf" data={pofUrl} type="application/pdf" />}
				<div className="invoice-data-container">
					<div className="row">
						<Typography className="label">Unit Cost</Typography>
						<Typography className="dark-text">{handleCurrency(selectedInvoice?.itemPrice, 'USD')}</Typography>
					</div>
					<div className="row">
						<Typography className="label">Device Quantity</Typography>
						<Typography className="dark-text">{selectedInvoice?.deliveredQty}</Typography>
					</div>
					<div className="row">
						<Typography className="label">Total Value</Typography>
						<Typography className="dark-text">{handleCurrency(selectedInvoice?.itemPrice * selectedInvoice?.deliveredQty, 'USD')}</Typography>
					</div>
					<div className="row">
						<Typography className="label">Submission Date</Typography>
						<Typography className="dark-text">{moment(selectedInvoice?.fulfillmentDate).format('MMM Do YY')}</Typography>
					</div>
				</div>

				{selectedInvoice?.status === 'under-review' ? (
					<div className="button-container">
						<RenderButton className="button" color="danger" startIcon={<ThumbDown />} onClick={() => handleUpdatePof('rejected')}>
							Reject
						</RenderButton>
						<RenderButton className="button" color="primary" startIcon={<ThumbUp />} onClick={() => handleUpdatePof('approved')}>
							Accept
						</RenderButton>
					</div>
				) : (
					<div className="status-section">
						<Typography className="status" variant="h1" style={colorStatusText(selectedInvoice?.status, undefined, { fontWeight: '700' })}>
							{selectedInvoice?.status?.toUpperCase() || ''}
						</Typography>
						<Typography variant="caption">on {selectedInvoice?.approvedOn}</Typography>
						<Typography variant="caption">by {selectedInvoice?.approvedBy}</Typography>
					</div>
				)}
			</div>
		</Drawer>
	);
};
