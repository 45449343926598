import React from "react";
import PropTypes from "prop-types";
import { LinearProgress, Fade } from "@material-ui/core";

const PreLoader = props => {
  const message = `${props.loadingText} ${props.section}`;

  return (
    <Fade in={props.showLoader === "show"}>
      <div>
        <LinearProgress color="secondary" />
      </div>
    </Fade>
  );
};

PreLoader.propTypes = {
  loadingText: PropTypes.string,
  section: PropTypes.string,
  showLoader: PropTypes.string
};

PreLoader.defaultProps = {
  loadingText: "loading",
  section: "",
  showLoader: "none"
};

export default PreLoader;
