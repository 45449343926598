const techProfileListStyles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    width: "100%",
    flex: "0 0 100%"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(10),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.text.secondary,
    width: "100%",
    flex: "0 0 100%"
  },
  profileIcon: {
    marginRight: theme.typography.pxToRem(5),
    height: theme.typography.pxToRem(35)
  },
  activeProfileIcon: {
    marginRight: theme.typography.pxToRem(5),
    height: theme.typography.pxToRem(35),
    color: "#02918d"
  },
  dangerProfileIcon: {
    marginRight: theme.typography.pxToRem(5),
    height: theme.typography.pxToRem(35),
    color: "#ff0000"
  }
});
export default techProfileListStyles;
