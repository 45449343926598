import {
  CardContent,
  CircularProgress,
  Paper,
  Typography
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons";
import React, { Component } from "react";
import { RenderButton, RenderTable } from "components";

import PropTypes from "prop-types";
import TableLendingInvoicesStyle from "./style";
import moment from "moment";
import withStyles from "@material-ui/core/styles/withStyles";

class TableLendingInvoices extends Component {
  componentWillMount() {
    const { loadLenderInvoices } = this.props;
    loadLenderInvoices();
  }

  render() {
    const {
      tableData = [],
      handleInvoiceView,
      handleLenderInvoiceApproveView
    } = this.props;
    const columns = [
      {
        Header: "Invoice Date",
        accessor: "invoice.DATE_START",
        Cell: props => moment(props.value, "YYYYMMDD").format("MMM DD YYYY"),
        maxWidth: 100,
        sortMethod: (a, b) => {
          if (moment(a, "YYYYMMDD").isSame(moment(b, "MM-DD-YYYY"))) {
            return 0;
          }
          return moment(a, "YYYYMMDD").isSameOrAfter(moment(b, "MM-DD-YYYY"))
            ? 1
            : -1;
        }
      },
      {
        Header: "Smart Contract",
        accessor: "invoice.customerData.customerNumber"
      },
      {
        Header: "Supplier",
        accessor: "invoice.supplierData.name"
      },
      {
        Header: "Traffic amount",
        accessor: "invoice.TOT_AMOUNT",
        Cell: (props, row) =>
          `$${Math.round(parseFloat(props.value) * 100) / 100}`
      },
      {
        Header: "Lended amount",
        accessor: "lendingOffer.dailyFundingAmount",
        Cell: (props, row) =>
          `$${Math.round(
            parseFloat(props.original.invoice.TOT_AMOUNT) *
              parseFloat(props.original.lendingOffer.percentage || 100)
          ) / 100}`
      },
      {
        Header: "Daily alocated amount",
        accessor: "lendingOffer.dailyFundingAmount",
        Cell: (props, row) => `$${Math.round(parseFloat(props.value))}`
      },
      {
        Header: "Coverage",
        accessor: "lendingOffer.percent",
        Cell: (props, row) =>
          `${props.original.lendingOffer.percentage || 100}%`
      },
      {
        Header: "",
        accessor: "status",
        Cell: rowInfo => (
          <div>
            <RenderButton
              color="primary"
              onClick={evt => {
                handleInvoiceView(
                  evt,
                  "lender",
                  rowInfo &&
                    rowInfo.original && {
                      ...rowInfo.original.invoice,
                      dueDate: rowInfo.original.dueDate
                    },
                  rowInfo && rowInfo.original && rowInfo.original.usageSummary
                );
              }}
            >
              View
            </RenderButton>
            <RenderButton
              color="primary"
              onClick={() =>
                handleLenderInvoiceApproveView({
                  _id: rowInfo.original.invoice._id,
                  amount:
                    Math.round(
                      parseFloat(rowInfo.original.invoice.TOT_AMOUNT) *
                        parseFloat(
                          rowInfo.original.lendingOffer.percentage || 100
                        )
                    ) / 100
                })
              }
            >
              Approve
            </RenderButton>
          </div>
        )
      }
    ];

    return (
      <Paper>
        <CardContent>
          <RenderTable
            data={tableData || []}
            columns={columns}
            filterable={false}
            defaultSorted={[
              {
                id: "invoiceDate",
                desc: true
              }
            ]}
          />
        </CardContent>
      </Paper>
    );
  }
}

TableLendingInvoices.propTypes = {
  loadLenderInvoices: PropTypes.func.isRequired,
  classes: PropTypes.shape().isRequired,
  // data: PropTypes.shape().isRequired,
  handleInvoiceView: PropTypes.func.isRequired
};

export default withStyles(TableLendingInvoicesStyle)(TableLendingInvoices);
