import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { Component } from 'react';

import { TechnicalInformationForm } from 'components';

const defaultInitialValues = {
	voipProtocolId: '1',
	hardwareCodecs: [
		{ value: '1', label: 'PCMU-G711U' },
		{ value: '2', label: 'G723' },
		{ value: '3', label: 'G729' },
		{ value: '5', label: 'GSM' },
		{ value: '6', label: 'G729AB' },
		{ value: '4', label: 'G7221' },
		{ value: '7', label: 'PCMA-G711A' },
		{ value: '8', label: 'G7221-32' }
	],
	dtmf: 'sip_info',
	techPrefix: '',
	dialStringFormat: 'e.164',
	manufacturer: '',
	softwareVersion: '',
	proxyMedia: true,
	cpsLimit: '5',
	pddTimeout: '30',
	ignoreEarlyMedia: 'none',
	userDippingFrom: 'lrn_server',
	minDuration: '',
	maxDuration: '0',
	ringTimer: '60',
	active: true,
	t38: false,
	rfc2833: true,
	ignoreEarlyNOSDP: false,
	ipAddresses: [
		{
			ip: '',
			netmask: '32',
			port: '5060'
		}
	]
};

class CreateTechnicalInformationForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			customError: false,
			updating: typeof this.props.updating !== typeof undefined ? this.props.updating : false,
			initialValues: typeof this.props.initialValues !== typeof undefined ? this.props.initialValues : defaultInitialValues
		};
	}

	componentWillReceiveProps(nextProps) {
		const initialValues = typeof nextProps.initialValues !== typeof undefined ? nextProps.initialValues : defaultInitialValues;
		this.setState({
			initialValues: {
				...initialValues,
				tempId: initialValues.tempId || nextProps.tempId
			}
		});
	}

	validateUniqueName = event => {
		const { hardwareProfiles } = this.props;
		const obj = hardwareProfiles.ingress.find(o => o.name === event.target.value) || hardwareProfiles.egress.find(o => o.name === event.target.value);

		if (obj) {
			this.setState({ customError: 'The name needs to be unique' });
		} else {
			this.setState({ customError: false });
		}
	};

	render() {
		const {
			handleSubmit,
			hardwareProfileOptionsData,
			newTechnicalStateForm,
			setStateForm,
			technicalFormType,
			uploadRates,
			uploadExternalRates,
			ratePlanUploadData,
			externalRatePlanUploadData,
			statesDataArray,
			countries,
			getStatesData
		} = this.props;
		const { customError } = this.state;
		const { initialValues, updating } = this.state;
		return (
			<Dialog maxWidth={false} open={newTechnicalStateForm} onBackdropClick={setStateForm}>
				<DialogTitle>{technicalFormType === 'ingress' ? 'Add New Origination Connection' : 'Add New Termination Connection'}</DialogTitle>
				<DialogContent style={{ maxWidth: 1400 }}>
					<TechnicalInformationForm
						onSubmit={handleSubmit}
						cancel={setStateForm}
						hardwareProfileOptionsData={hardwareProfileOptionsData}
						initialValues={initialValues}
						updating={updating}
						technicalFormType={technicalFormType}
						validateUniqueName={this.validateUniqueName}
						customError={customError}
						uploadRates={uploadRates}
						uploadExternalRates={uploadExternalRates}
						ratePlanUploadData={ratePlanUploadData}
						externalRatePlanUploadData={externalRatePlanUploadData}
						countries={countries}
						statesDataArray={statesDataArray}
						getStatesData={getStatesData}
					/>
				</DialogContent>
			</Dialog>
		);
	}
}

export default CreateTechnicalInformationForm;
