import React, { memo, useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogActions, Tabs, Tab, AppBar } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, RenderButton } from 'components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import uuidv4 from 'uuid/v4';
import { GeneralTab, VendorRatesTab } from './components';

import style from './styles.module.scss';

const UpdateHardwareProfile = memo(({ open, triggerOpenClose, hardwareProfileId }) => {
	const dispatch = useDispatch();

	const isLoading = useSelector(state => (state.routes && state.routes.loadingHardwareProfileUpdate) || false);
	const ratesIsLoading = useSelector(state => (state.routes && state.routes.loadingHardwareProfileRateUpload) || false);
	const hardwareProfileData = useSelector(state => (state.routes && state.routes.hardwareProfileData) || {});

	const [selectedTab, setSelectedTab] = useState('general');

	const formik = useFormik({
		initialValues: Object.assign(
			{
				type: 'egress',
				tempId: uuidv4(),
				name: '',
				voipProtocolId: '1',
				codecs: ['1', '2', '3', '4', '5', '6', '7', '8'],
				dtmf: 'sip_info',
				techPrefix: '',
				dialStringFormat: 'e.164',
				manufacturer: '',
				softwareVersion: '',
				proxyMedia: true,
				cpsLimit: '5',
				pddTimeout: '30',
				ignoreEarlyMedia: 'none',
				userDippingFrom: 'lrn_server',
				minDuration: '',
				maxDuration: '0',
				ringTimer: '60',
				active: true,
				t38: false,
				rfc2833: true,
				ignoreEarlyNOSDP: false,
				ipAddresses: [
					{
						ip: '',
						netmask: '32',
						port: '5060'
					}
				]
			},
			hardwareProfileData || {}
		),
		onSubmit: (values, { resetForm }) => {
			dispatch({
				type: 'routes/UPDATE_HARDWARE_PROFILE',
				payload: values
			});
			resetForm();
			triggerOpenClose();
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required('Please input connection name'),
			ipAddresses: Yup.array()
				.of(
					Yup.object().shape({
						ip: Yup.string().required('Please input IP address'),
						netmask: Yup.number().required('Please input netmask'),
						port: Yup.number().required('Please input port')
					})
				)
				.required('Please input at least one IP address')
		}),
		enableReinitialize: true
	});

	useEffect(() => {
		if (open && hardwareProfileId) {
			dispatch({ type: 'routes/GET_HARDWARE_PROFILE_OPTIONS' });
			dispatch({
				type: 'routes/GET_HARDWARE_PROFILE',
				payload: hardwareProfileId
			});
		}
	}, [open, hardwareProfileId]);

	useEffect(() => {
		// if (!open) {
		// 	formik.resetForm();
		// 	setSelectedTab('general');
		// }
	}, [open]);

	return (
		<Dialog maxWidth="md" fullWidth open={open} onBackdropClick={triggerOpenClose} className={style.container}>
			{/* <DialogTitle>Create origination connection</DialogTitle> */}
			<AppBar position="static">
				<Tabs value={selectedTab} onChange={(e, value) => setSelectedTab(value)}>
					<Tab label="General" value="general" />
					<Tab label="Vendor rates" value="vendor-rates" />
				</Tabs>
			</AppBar>
			<DialogContent>
				{(isLoading && <Loader loading />) || ''}
				<form onSubmit={formik.handleSubmit}>
					{(selectedTab === 'general' && <GeneralTab formik={formik} />) || ''}
					{(selectedTab === 'vendor-rates' && <VendorRatesTab formik={formik} />) || ''}
				</form>
			</DialogContent>
			<DialogActions>
				<RenderButton color="primary" onClick={formik.handleSubmit} disabled={isLoading || ratesIsLoading}>
					Save Connection
				</RenderButton>
				<RenderButton color="danger" onClick={triggerOpenClose}>
					Close
				</RenderButton>
			</DialogActions>
		</Dialog>
	);
});

export default UpdateHardwareProfile;
