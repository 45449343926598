import PropTypes from "prop-types";
import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import externalInvoiceHeaderStyle from "./style";

const dateFormatterMMDDYY = v => moment(v, "MM-DD-YYYY").format("MMM DD YYYY");

const CustomTableRow = withStyles({
  root: {
    height: "auto"
  }
})(TableRow);

const CustomRow = ({
  firstCellContent,
  secondCellContent,
  thirdCellContent,
  classes,
  hideFirst
}) => (
  <CustomTableRow>
    {hideFirst || (
      <TableCell className={[classes.w450, classes.noBorderBottom].join(" ")}>
        {firstCellContent}
      </TableCell>
    )}
    <TableCell className={[classes.w150, classes.noBorderBottom].join(" ")}>
      {secondCellContent}
    </TableCell>
    <TableCell
      className={[
        classes.w100,
        classes.noBorderBottom,
        classes.alignRight
      ].join(" ")}
    >
      {thirdCellContent}
    </TableCell>
  </CustomTableRow>
);

CustomRow.defaultProps = {
  firstCellContent: "",
  secondCellContent: "",
  thirdCellContent: "",
  hideFirst: false
};

CustomRow.propTypes = {
  hideFirst: PropTypes.bool,
  classes: PropTypes.shape().isRequired,
  firstCellContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  secondCellContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  thirdCellContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

const ExternalInvoiceHeader = props => {
  const { data, classes, page } = props;
  const invoiceUpperRightFieldMappings =
    page === "1"
      ? {
          "": { val: "NAME", formatter: () => {} },
          "Invoice Number": { val: "displayId", formatter: v => v },
          "Invoice Date": {
            val: "invoiceDate"
          },
          "Payment Due Date": {
            val: "dueDate",
            formatter: dateFormatterMMDDYY
          },
          Page: { val: "page", formatter: v => v }
        }
      : {
          Customer: { val: "customerData.name", formatter: v => v },
          "Invoice Number": { val: "displayId", formatter: v => v },
          Page: { val: "page", formatter: v => v }
        };

  data.page = page;
  return (
    <Table className={classes.table}>
      <TableBody>
        {page === "1" && (
          <CustomTableRow>
            <TableCell
              rowSpan={6}
              className={[classes.w450, classes.noBorderBottom].join(" ")}
            >
              <div>
                {data.supplierData && data.supplierData.logo && (
                  <img
                    src={data.supplierData.logo}
                    alt={data.supplierData.name}
                    style={{
                      maxWidth: 200,
                      maxHeight: 200,
                      float: "left",
                      marginRight: 15
                    }}
                  />
                )}
                <Typography variant="body1">
                  {data.supplierData && data.supplierData.name}
                </Typography>
                <Typography variant="body1">
                  {data.supplierData.address &&
                    data.supplierData.address.addressLine1}
                </Typography>
                <Typography variant="body1">
                  {data.supplierData.address && data.supplierData.address.city}
                  {data.supplierData.address && data.supplierData.address.zip
                    ? `, ${data.supplierData.address.zip}`
                    : ""}
                  {data.supplierData.address && data.supplierData.address.state
                    ? `, ${data.supplierData.address.state}`
                    : ""}
                </Typography>
                <Typography variant="body1">
                  {data.supplierData && data.supplierData.taxId}
                </Typography>
              </div>
            </TableCell>
          </CustomTableRow>
        )}
        {Object.keys(invoiceUpperRightFieldMappings).map((key, idx) => {
          const valObj = invoiceUpperRightFieldMappings[key];
          const value = (
            <Typography variant="body1">
              {typeof valObj.formatter === "function"
                ? valObj.formatter(data[valObj.val])
                : data[valObj.val]}
            </Typography>
          );
          const name = <Typography variant="body2">{key}</Typography>;
          if (
            valObj &&
            valObj.val === "dueDate" &&
            typeof valObj.formatter === "function" &&
            valObj.formatter(data[valObj.val]) === "Invalid date"
          )
            return (
              <CustomRow key={idx} classes={classes} hideFirst={page === "1"} />
            );
          return (
            <CustomRow
              key={idx}
              secondCellContent={name}
              thirdCellContent={value}
              classes={classes}
              hideFirst={page === "1"}
            />
          );
        })}
        {page === "1" && (
          <CustomRow
            secondCellContent={
              <Typography variant="body2">Usage Period:</Typography>
            }
            thirdCellContent={
              <Typography variant="body2">
                {data.period.start} - {data.period.end}
              </Typography>
            }
            classes={classes}
          />
        )}
        {page === "1" && (
          <CustomTableRow>
            <TableCell
              rowSpan={2}
              className={[classes.w450, classes.noBorderBottom].join(" ")}
            >
              <Typography variant="body2">Customer</Typography>
              <Typography variant="body1">
                {data.customerData && data.customerData.name}
              </Typography>
              <Typography variant="body1">
                {data.customerData.address &&
                  data.customerData.address.addressLine1}
              </Typography>
              <Typography variant="body1">
                {data.customerData.address && data.customerData.address.city}
                {data.customerData.address && data.customerData.address.zip
                  ? `, ${data.customerData.address.zip}`
                  : ""}
                {data.customerData.address && data.customerData.address.state
                  ? `, ${data.customerData.address.state}`
                  : ""}
              </Typography>
              <Typography variant="body1">
                {data.customerData && data.customerData.taxId}
              </Typography>
            </TableCell>
          </CustomTableRow>
        )}
      </TableBody>
    </Table>
  );
};

ExternalInvoiceHeader.defaultProps = {
  page: "1"
};

ExternalInvoiceHeader.propTypes = {
  classes: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
  page: PropTypes.string
};

export default withStyles(externalInvoiceHeaderStyle)(ExternalInvoiceHeader);
