import React, { memo, useCallback, useEffect, useInsertionEffect, useRef, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { Add, ArrowUpward, Delete, Send } from '@material-ui/icons';
import { Loader, RenderButton, RenderFormikInput, RenderFormikDatePicker } from 'components';
import { useForm } from './hooks/useForm';
import styles from './styles.module.scss';
import moment from 'moment';
import { colorStatusText, handleCurrency } from 'utils';
import { useSelector } from 'react-redux';

export const InvoiceModal = memo(({ modalOpened, handleToggleModal, selectedSmartContract, selectedPof }) => {
	const uploadInvoiceInputRef = useRef(null);

	const { loading, pofUrl } = useSelector(state => state.escrowmanaging || {});

	const { formik, handleResetInputValue, fileAddress, setFileAddress, pdfUploadError } = useForm({
		selectedSmartContract,
		uploadInvoiceInputRef,
		modalOpened,
		selectedPof
	});

	return (
		<Dialog open={modalOpened} className={styles.modal} onClose={handleToggleModal}>
			{loading.contracts && <Loader fullscreen />}
			<DialogTitle>{selectedPof ? 'Invoice Details' : 'Submit Invoice'}</DialogTitle>
			<DialogContent className="content">
				<Box className="left-side">
					{!selectedPof ? (
						<>
							<RenderFormikInput name="invoiceNumber" label="Invoice Number" formik={formik} />
							<RenderFormikDatePicker
								disableFuture={true}
								name="fulfillmentDate"
								label="Fulfillment Date"
								formik={formik}
								minDate={selectedSmartContract?.createdAt}
							/>
							<RenderFormikInput type="number" name="itemPrice" label="Price" formik={formik} disabled />
							<RenderFormikInput type="number" name="deliveredQty" label="Delivered Quantity" formik={formik} />
						</>
					) : (
						<>
							<div className="field">
								<Typography variant="caption">Upload Date:</Typography>
								<Typography variant="body1">{moment(selectedPof?.createdAt).format('MMM Do YY')}</Typography>
							</div>
							<div className="field">
								<Typography variant="caption">Fulfillment Date:</Typography>
								<Typography variant="body1">{moment(selectedPof?.fulfillmentDate).format('MMM Do YY')}</Typography>
							</div>
							<div className="field">
								<Typography variant="caption">Unit Cost:</Typography>
								<Typography variant="body1">{handleCurrency(selectedPof?.itemPrice, 'USD')}</Typography>
							</div>
							<div className="field">
								<Typography variant="caption">Delivered Quantity:</Typography>
								<Typography variant="body1">{selectedPof?.deliveredQty}</Typography>
							</div>
							<div className="field">
								<Typography variant="caption">Total Value:</Typography>
								<Typography variant="body1">{handleCurrency(selectedPof?.itemPrice * selectedPof?.deliveredQty, 'USD')}</Typography>
							</div>
							<div className="field">
								<Typography variant="caption">Status:</Typography>
								<Typography variant="body1" style={colorStatusText(selectedPof?.status, undefined, { fontWeight: 700 })}>
									{selectedPof.status.toUpperCase().replace(/-/g, ' ')}
								</Typography>
								{selectedPof.status === 'approved' && (
									<div className="col">
										<Typography variant="caption">on {selectedPof?.approvedOn}</Typography>
										<Typography variant="caption">by {selectedPof?.approvedBy}</Typography>
									</div>
								)}
							</div>
						</>
					)}
				</Box>
				<Box className="right-side">
					{loading.pof ? (
						<Loader />
					) : selectedPof ? (
						<object width="300" height="386" data={`${pofUrl}#toolbar=0`} type="application/pdf" />
					) : (
						<>
							<input
								ref={uploadInvoiceInputRef}
								accept="application/pdf"
								type="file"
								hidden
								onChange={e => {
									setFileAddress(`${URL.createObjectURL(e?.target?.files[0])}#toolbar=0` || '');
									formik.setFieldValue('file', e?.target?.files[0]);
								}}
							/>

							{formik?.values?.file ? (
								<>
									<div className="delete-container" onClick={handleResetInputValue}>
										<Delete className="delete-icon" fontSize="large" />
									</div>
									<object width="300" height="386" data={fileAddress} type="application/pdf" />
								</>
							) : (
								<div className="unselected-file-container">
									<Box className={`button-container ${pdfUploadError ? 'button-container-with-error' : ''}`}>
										<RenderButton color="primary" startIcon={<ArrowUpward />} onClick={() => uploadInvoiceInputRef?.current.click()}>
											Upload Invoice PDF
										</RenderButton>
									</Box>
									{pdfUploadError && (
										<Typography className="text" variant="caption">
											{formik?.errors?.file}
										</Typography>
									)}
								</div>
							)}
						</>
					)}
				</Box>
			</DialogContent>
			<DialogActions className="buttons">
				<RenderButton onClick={handleToggleModal}>{selectedPof ? 'Close' : 'Cancel'}</RenderButton>
				{!selectedPof && (
					<RenderButton className="create-order-button" color="primary" startIcon={<Send />} onClick={() => formik.submitForm()}>
						Submit
					</RenderButton>
				)}
			</DialogActions>
		</Dialog>
	);
});
