import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@material-ui/core";

const RenderFormikCheckbox = ({ formik: { values, errors, setFieldValue }, name, label, ...rest }) => {
	const hasError = !!errors[name];
	let value = values[name] || '';
	if (name.indexOf('.') > -1) {
		value = name.split('.').reduce((o, i) => (o && o[i]) || '', values);
	}
	return (
		<FormControl error={hasError}>
			<FormControlLabel
				control={
					<Checkbox
						// value={value}
						// checked={input.value ? true : false}
						// onChange={input.onChange}

						value={value}
						checked={!!value}
						onChange={e => setFieldValue(name, e.target.checked)}
					/>
				}
				label={label}
			/>
			{hasError && <FormHelperText>{(!!errors[name] && errors[name]) || ''}</FormHelperText>}
		</FormControl>
	);
};

RenderFormikCheckbox.defaultProps = {
	// margin: 'none'
};

RenderFormikCheckbox.propTypes = {
	formik: PropTypes.object.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string
};

export default RenderFormikCheckbox;
