import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { RenderFileUpload, RatePlansAccordion, RenderNotification, RateAddendumPicker, RenderButton } from 'components';
import { CircularProgress, Typography, Grid, CardContent, Paper } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import smartContractRatesStyle from './style';
import {useTranslation} from "react-i18next";

const SmartContractRates = ({
	classes,
	ratePlans,
	handleDownloadRatePlan,
	handleDownloadSample,
	handleRateUpload,
	ratePlanData,
	clearUploadError,
	isUploadDisabled,
	isRateAddendum,
	minimumNoticeDays,
	tempId,
	toggleImmediateRateChangeDialog
}) => {
	const error = ratePlanData && ratePlanData.info && ratePlanData.info.error;
	const allowedFileTypes = ['.csv', '.xlsx', '.xls'];

	const { t } = useTranslation(['smartcontracts/create', 'translations']);


	const showLoader = data => {
		if (data && data.loading) {
			const fname = data && data.file && data.file.name;
			return (
				<div>
					<Typography variant="body1" align="center" className={classes.loadingText}>
						<CircularProgress color="secondary" size={20} />
						<span>&nbsp;&nbsp;&nbsp;{t('translations:uploading_file',{ file: fname })} ...</span>
					</Typography>
				</div>
			);
		}
		return '';
	};
	const renderControls = isAddendum => {
		if (!isAddendum) {
			return (
				<Field
					type="file"
					component={RenderFileUpload}
					allowedFileTypes={allowedFileTypes}
					btnText={t('upload_rates')}
					className="form-control"
					name="ratesFile"
					handleUploadFn={evt => {
						const fd = evt && evt.target && evt.target.files && evt.target.files[0];
						handleRateUpload(tempId, fd);
					}}
					disabled={isUploadDisabled}
				/>
			);
		}
		return (
			<React.Fragment>
				<RenderButton
					color="danger"
					type="button"
					className={classes.marginBottom}
					onClick={() => {
						toggleImmediateRateChangeDialog();
					}}
				>
					{t('immediate_rate_change')}
				</RenderButton>
				<RateAddendumPicker allowedFileTypes={allowedFileTypes} handleRateUpload={handleRateUpload} minimumNoticeDays={minimumNoticeDays} tempId={tempId} />
			</React.Fragment>
		);
	};

	const renderWrappedControls = isAddendum => (
		<React.Fragment>
			<RenderButton
				color="secondary"
				type="button"
				className={classes.marginBottom}
				onClick={() => {
					handleDownloadSample();
				}}
			>
				{t('download_sample')}
			</RenderButton>
			{renderControls(isAddendum)}
		</React.Fragment>
	);

	return (
		<Grid container>
			{(ratePlans && ratePlans.length > 0 && (
				<Grid item xs={12}>
					<Paper>
						<CardContent>
							<RatePlansAccordion ratePlans={ratePlans} handleDownloadRatePlan={handleDownloadRatePlan} />
						</CardContent>
					</Paper>
				</Grid>
			)) ||
				''}

			<RenderNotification
				variant="danger"
				floating
				open={!!error}
				message={error}
				onClose={() => {
					clearUploadError();
				}}
			/>

			<Grid item xs={12}>
				<Paper>
					<CardContent>{showLoader(ratePlanData) || renderWrappedControls(isRateAddendum)}</CardContent>
				</Paper>
			</Grid>
		</Grid>
	);
};

SmartContractRates.defaultProps = {
	minimumNoticeDays: 1
};

SmartContractRates.propTypes = {
	ratePlans: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	handleDownloadRatePlan: PropTypes.func.isRequired,
	handleDownloadSample: PropTypes.func.isRequired,
	handleRateUpload: PropTypes.func.isRequired,
	ratePlanData: PropTypes.shape().isRequired,
	classes: PropTypes.shape().isRequired,
	clearUploadError: PropTypes.func.isRequired,
	isUploadDisabled: PropTypes.bool.isRequired,
	tempId: PropTypes.string.isRequired,
	isRateAddendum: PropTypes.bool.isRequired,
	minimumNoticeDays: PropTypes.number,
	toggleImmediateRateChangeDialog: PropTypes.func.isRequired
};

export default withStyles(smartContractRatesStyle)(SmartContractRates);
