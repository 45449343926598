import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

export default function useFormValues() {
	const { passwordchallenge } = useSelector(state => state);
	const { email, errorMessage, successMessage, loading } = passwordchallenge;

	const dispatch = useDispatch();

	const formik = useFormik({
		initialValues: {
			password: '',
			passwordConfirmation: ''
		},
		onSubmit: values => {
			dispatch({ type: 'passwordChallenge/UPDATE_PASSWORD', payload: { ...passwordchallenge, password: values.password } });
		},
		validationSchema: Yup.object().shape({
			password: Yup.string()
				.min(8, 'Password minimum lenght is 8 characters')
				.matches(/[a-z]/, 'Password must have at least 1 lowerCase')
				.matches(/[A-Z]/, 'Password must have at least 1 upperCase')
				.matches(/[0-9]/, 'Password must have at least 1 number')
				.matches(/[!@#%&._:]/, 'Password must have at least 1 special character'),
			passwordConfirmation: Yup.string()
				.required('Confirm Password Required')
				.oneOf([Yup.ref('password'), null], 'Passwords must match')
		})
	});
	return { formik, email, errorMessage, successMessage, loading };
}
