const buttonStyle = theme => ({
	root: {
		border: `1px solid ${theme.palette.lightGrey.main}`,
	},
	primary: {
		border: `1px solid ${theme.palette.primary.main}`,
		backgroundColor: theme.palette.primary.main,
		color: "#FFF",

		"&:hover": {
			backgroundColor: theme.palette.primary.dark,
		}
	},
	secondary: {
		border: `1px solid ${theme.palette.secondary.main}`,
		backgroundColor: theme.palette.secondary.main,
		color: "#FFF",

		"&:hover": {
			border: `1px solid ${theme.palette.secondary.dark}`,
			backgroundColor: theme.palette.secondary.dark,
		}
	},
	warning: {
		color: theme.palette.warning.main,
		border: `1px solid ${theme.palette.warning.main}`,
		"&:hover": {
			backgroundColor: theme.palette.warning.main,
			color: "#FFF"
		}
	},
	danger: {
		color: theme.palette.danger.main,
		border: `1px solid ${theme.palette.danger.main}`,
		"&:hover": {
			backgroundColor: theme.palette.danger.main,
			color: "#FFF"
		}
	},
	success: {
		color: theme.palette.success.main,
		border: `1px solid ${theme.palette.success.main}`,
		"&:hover": {
			backgroundColor: theme.palette.success.main,
			color: "#FFF"
		}
	},
	grey: {
		color: theme.palette.grey.main,
		border: `1px solid ${theme.palette.grey.main}`,
		"&:hover": {
			backgroundColor: theme.palette.grey.main,
			color: "#FFF"
		}
	},
	block: {
		display: "block",
		width: "100%"
	},
	underlined: {
		fontSize: 13,
		border: "none",
		"&:hover": {
			textDecoration: "underline",
			backgroundColor: "transparent",
			color: "inherit"
		}
	},
	small: {
		fontSize: 12
	},
	disabled: {
		color: 'initial',
		backgroundColor: theme.palette.lightGrey.main,
		borderColor: theme.palette.lightGrey.main,
		opacity: 0.7
	},
	justIcon: {
		padding: 0,
		minWidth: '36px',
		minHeight: '36px',
		border: 'none !important'
	},
	anchor: {
		background: 'none',
		border: 'none',
		textTransform: 'none',
		padding:0,
		fontSize: 'inherit',
		lineHeight: 'inherit',
		margin: '0 !important',
		color: theme.palette.primary.main,
		textDecoration: 'underline',
		'> .MuiButton-label': {
			padding:0
		}
	}
});

export default buttonStyle;
