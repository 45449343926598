import { AppBar, Box, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Tab, Tabs, Typography } from '@material-ui/core';
import Loader from 'components/atoms/Loader';
import RenderButton from 'components/atoms/RenderButton';
import RenderFormikInput from 'components/atoms/RenderFormikInput';
import React, { memo, useEffect, useMemo } from 'react';
import styles from './styles.module.scss';
import Add from '@material-ui/icons/Add';
import { useForm } from './hooks/useForm';
import RenderFormikDatePicker from 'components/atoms/RenderFormikDatePicker';
import RenderFormikSelect from 'components/atoms/RenderFormikSelect';
import { use } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';

export default memo(({ modalOpened, handleToggleModal, openedOrder }) => {
	const { formik, selectedTab, setSelectedTab, loading, options, availableItems } = useForm(openedOrder, modalOpened);
	return (
		<Dialog open={modalOpened} className={styles.modal} onClose={handleToggleModal}>
			{loading?.orders && <Loader fullscreen />}
			<DialogTitle>Create new Smart Contract</DialogTitle>
			<DialogContent className="content">
				<AppBar position="static">
					<Tabs value={selectedTab} onChange={(_, value) => setSelectedTab(value)}>
						<Tab value="existingPartners" label="Existing Partners" />
						<Tab value="selfFulfill" label="Self-fulfill" />
					</Tabs>
				</AppBar>
				<div className="tabs-content">
					{(selectedTab === 'existingPartners' && (
						<>
							<RenderFormikSelect formik={formik} name="partnerId" label="Select a partner" options={options} />
							<Typography variant="caption">If you can't find the partner invite one.</Typography>
							<RenderFormikDatePicker disablePast={true} formik={formik} name="dueDate" label="Due Date" />
							<Box className="qty-input-container">
								<RenderFormikInput type="number" formik={formik} name="scQty" label="Item quantity" />
								<Chip label={`${availableItems} available for assignment`} variant="outlined" className="chip" />
							</Box>
							<RenderFormikInput type="number" formik={formik} name="cost" label="Cost" />
						</>
					)) ||
						''}

					{(selectedTab === 'selfFulfill' && (
						<>
							<Typography variant="caption">How many devices do you want to self-fulfill?</Typography>
							<Box className="qty-input-container">
								<RenderFormikInput type="number" formik={formik} name="scQty" label="Item quantity" />
								<Chip label={`${availableItems} available for assignment`} variant="outlined" className="chip" />
							</Box>
						</>
					)) ||
						''}
				</div>
			</DialogContent>
			<DialogActions>
				<RenderButton onClick={handleToggleModal}>Cancel</RenderButton>
				<RenderButton className="create-order-button" color="primary" onClick={() => formik.submitForm()} startIcon={<Add />}>
					{selectedTab !== 'selfFulfill' ? 'Create smart contract' : 'Self-fulfill'}
				</RenderButton>
			</DialogActions>
		</Dialog>
	);
});
