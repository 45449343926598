import { call, put } from "redux-saga/effects";
import awsAmplifyService from "services/api/awsamplify";
import {loginSuccess, recoveryPasswordFailure, recoveryPasswordSuccess} from "../actions";
import {push} from "connected-react-router";

export function* recoveryPassword(action) {
	try {
		const result = yield call(
			awsAmplifyService.recoveryPassword,
			action.payload.email
		);
		if (result.CodeDeliveryDetails != null) {
			console.log("recovery Success");
			yield put(
				recoveryPasswordSuccess(
					"An email has been sent to your account with details to reset your password."
				)
			);
			yield put(loginSuccess("An email has been sent to your account with details to reset your password."));
			yield put(push("/login"));
		} else {
			console.log("recovery Failed");
			yield put(recoveryPasswordFailure(result.message));
		}
		console.log("Finish Saga");
	} catch (err) {
		const error = err.message || err;
		console.log(`eror---${error}`);
		yield put(recoveryPasswordFailure(error));
	}
}
