import React, { useEffect, useState, useRef } from 'react';
import { InfoText, Loader } from 'components';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, Box, Modal } from '@material-ui/core';

import BalanceSection from './BalanceSection';
import PendingTransactions from './PendingTransactions';
import TransactionHistory from './TransactionHistory';
import DepositModal from './DepositModal';

export default () => {
	const [modalOpen, setModalOpen] = useState(false);
	const infotext = useSelector(state => state.infotext.INFO_TEXT_REQUEST);

	const dispatch = useDispatch();
	const userId = useSelector(state => state?.useraccount?.userInfo?.userId);

	const walletCached = useSelector(state => state?.wallet?.walletCached ?? false);
	const { wallet } = useSelector(state => state);
	const { WALLET_LOADING = true, RESULT_WALLET } = wallet;

	const toggleModalOpen = () => {
		setModalOpen(!modalOpen);
	};

	useEffect(() => {
		dispatch({ type: 'transactions/GET_WALLET_TRANSACTIONS', payload: { userId, walletCached } });
	}, []);

	return (
		<React.Fragment>
			<Modal open={RESULT_WALLET.status === 'loading'} fullscreen>
				<Loader fullcreen overlay loading />
			</Modal>
			<Box style={{ position: 'relative' }}>
				{(WALLET_LOADING && <Loader overlay />) || null}
				<BalanceSection toggleModalOpen={toggleModalOpen} />
				<PendingTransactions />
			</Box>
			<TransactionHistory />
			<Grid item xs={12} md={6}>
				<InfoText infoText={infotext instanceof Object ? infotext : {}} section={'Wallet'} />
			</Grid>
			<DepositModal modalOpen={modalOpen} toggleModalOpen={toggleModalOpen} />
		</React.Fragment>
	);
};
