import React from "react";

const RenderFieldSelect = ({
  input,
  className,
  children,
  style,
  meta: { touched, error },
  ...custom
}) => (
  <div>
    <select
      {...input}
      {...custom}
      style={style}
      className={className + (touched && error ? " is-invalid" : "")}
    >
      {children}
    </select>
    {touched && error && <span className="label label-danger">{error}</span>}
  </div>
);

export default RenderFieldSelect;
