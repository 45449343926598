import React, { Component } from "react";
import { Util } from "components";

const BiddingFunder = ({ funder, processAcceptOpenOrder }) => {
  return (
    <tr
      className="order-row"
      data-id={funder.id}
      key={"key" + funder.id}
      onClick={() => processAcceptOpenOrder(funder.id, "Funder")}
    >
      <td>{funder.interestRate}</td>
      <td>
        {Util.isEmpty(funder.paymentTerms)
          ? Util.getMaximumPaymentTerms(funder)
          : funder.paymentTerms * funder.dailyAllocatedAmount}
      </td>
      <td>{funder.dailyAllocatedAmount}</td>
      <td>5</td>
      <td>{funder.paymentTerms + "net" + funder.paymentTermsNet}</td>
    </tr>
  );
};

export default BiddingFunder;
