import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SendInterconnectionsForm } from 'components';
import { acceptOrder, order, setOrder, setModalInterconnections, sendInterconnection } from 'store/actions';
import { reduxForm, reset } from 'redux-form';
import { fromBiddingportal, fromInterconnections } from 'store/selectors';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { createValidator, requiredInterconnectionType } from 'services/validation';

class SendInterconnectionsContainer extends Component {
	constructor(props) {
		super(props);
		this.sendInterconnections = this.sendInterconnections.bind(this);
	}

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(reset('sendInterconnectionForm'));
	}

	componentWillUnmount() {
		const { dispatch } = this.props;
		dispatch(reset('sendInterconnectionForm'));
	}
	sendInterconnections(data) {
		this.props.handleSubmit(data);
	}

	render() {
		return <SendInterconnectionsForm {...this.props} handleSubmit={this.props.handleSubmit} />;
	}
}
const mapStateToProps = state => ({
	order: fromBiddingportal.getOrder(state),
	initialValues: {
		mail: null,
		interconnectionType: 'both'
	}
});

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			order,
			setOrder
		},
		dispatch
	);
}

const onSubmit = (data, dispatch) => {
	dispatch(setModalInterconnections(false, null));
	dispatch(sendInterconnection(data));
};

const validate = createValidator({
	interconnectionType: [requiredInterconnectionType]
});
SendInterconnectionsContainer.propTypes = {
	emailInterconnections: PropTypes.string
};

const reduxFormSendInterconnectionsContainer = reduxForm({
	form: 'sendInterconnectionForm',
	destroyOnUnmount: true,
	onSubmit,
	validate
})(SendInterconnectionsContainer);

export default connect(mapStateToProps, mapDispatchToProps)(reduxFormSendInterconnectionsContainer);
