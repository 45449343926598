import React, { memo, useCallback, useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@material-ui/core';
import { RenderButton, RenderFormikCheckbox, RenderFormikSelect, RenderFormikInput } from 'components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

export default memo(({ open, openCloseHandler }) => {
	const dispatch = useDispatch();

	const countries = useSelector(state => (state.myaccount && state.myaccount.countries) || []);
	const allStates = useSelector(state => (state.myaccount && state.myaccount.allStates) || []);
	const currencies = useSelector(state => (state.myaccount && state.myaccount.currencies) || []);
	const accountData = useSelector(state => (state.myaccount && state.myaccount.accountData) || {});
	const { t } = useTranslation('myaccount/general_information');


	const [bankStates, setBankStates] = useState([]);
	const [beneficiaryStates, setBeneficiaryStates] = useState([]);
	const [corespondentStates, setCorespondentStates] = useState([]);

	const formik = useFormik({
		initialValues: {
			bankName: '',
			bankNickname: '',
			bankCountryId: '',
			bankAddress: '',
			bankCity: '',
			bankStateId: '',
			bankZipPostalCode: '',
			accountNumber: '',
			currencyId: '',
			swift: '',
			accountName: '',
			beneficiaryCountryId: '',
			accountAddress: '',
			beneficiaryCity: '',
			beneficiaryStateId: '',
			beneficiaryZipPostalCode: ''
		},
		onSubmit: (values, { resetForm }) => {
			dispatch({
				type: 'myaccount/CREATE_NEW_BANK_ACCOUNT',
				payload: values
			});
			resetForm();
			openCloseHandler();
		},
		validationSchema: Yup.object().shape({
			bankName: Yup.string().required(t('please_input_bank_help', {label: t('bank_name')})),
			bankNickname: Yup.string().required(t('please_input_bank_help', {label: t('bank_nickname')})),
			bankCountryId: Yup.string().required(t('please_input_bank_help', {label: t('bank_country')})),
			bankAddress: Yup.string().required(t('please_input_bank_help', {label: t('bank_address')})),
			bankCity: Yup.string().required(t('please_input_bank_help', {label: t('bank_city')})),
			bankStateId: Yup.string().required(t('please_input_bank_help', {label: t('bank_state_province')})),
			bankZipPostalCode: Yup.string().required(t('please_input_bank_help', {label: t('bank_zip')})),
			accountNumber: Yup.string().required(t('please_input_bank_help', {label: t('account_number_iban')})),
			currencyId: Yup.string().required(t('please_input_bank_help', {label: t('currency')})),
			swift: Yup.string().required(t('please_input_bank_help', {label: t('swift')})),
			accountName: Yup.string().required(t('please_input_bank_help', {label: t('beneficiary_name')})),
			beneficiaryCountryId: Yup.string().required(t('please_input_bank_help', {label: t('beneficiary_country')})),
			accountAddress: Yup.string().required(t('please_input_bank_help', {label: t('beneficiary_address')})),
			beneficiaryCity: Yup.string().required(t('please_input_bank_help', {label: t('beneficiary_city')})),
			beneficiaryStateId: Yup.string().required(t('please_input_bank_help', {label: t('beneficiary_state_province')})),
			beneficiaryZipPostalCode: Yup.string().required(t('please_input_bank_help', {label: t('beneficiary_zip_code')}))
		}),
		enableReinitialize: true
	});

	const triggerCopyFromBeneficiary = useCallback(
		() => [
			formik.setValues({
				...formik.values,
				accountName: accountData.participantName,
				beneficiaryCountryId: accountData.countryId,
				accountAddress: accountData.addressLine1,
				beneficiaryCity: accountData.city,
				beneficiaryStateId: accountData.stateId,
				beneficiaryZipPostalCode: accountData.zipPostalCode
			})
		],
		[accountData, formik]
	);

	useEffect(() => {
		formik.setFieldValue('bankNickname', formik.values.bankName);
	}, [formik.values.bankName]);

	useEffect(() => {
		setBankStates((formik.values.bankCountryId && allStates && Array.isArray(allStates) && (allStates || []).filter(s => s.countryId === formik.values.bankCountryId)) || []);
	}, [formik.values.bankCountryId, allStates]);

	useEffect(() => {
		setBeneficiaryStates(
			(formik.values.beneficiaryCountryId && allStates && Array.isArray(allStates) && (allStates || []).filter(s => s.countryId === formik.values.beneficiaryCountryId)) || []
		);
	}, [formik.values.beneficiaryCountryId, allStates]);

	useEffect(() => {
		setCorespondentStates(
			(formik.values.correspondentBankCountryId &&
				allStates &&
				Array.isArray(allStates) &&
				(allStates || []).filter(s => s.countryId === formik.values.correspondentBankCountryId)) ||
				[]
		);
	}, [formik.values.correspondentBankCountryId, allStates]);

	useEffect(() => {
		if (open) {
			dispatch({ type: 'myaccount/GET_COUNTRIES' });
			dispatch({ type: 'myaccount/GET_ALL_STATES' });
			dispatch({ type: 'myaccount/GET_CURRENCIES' });
			dispatch({ type: 'myaccount/GET_BUSINESS_TYPES' });
			// dispatch({ type: 'myaccount/GET_ACCOUNT_DETAILS' });

			formik.resetForm();
		}

		return () => {
			formik.resetForm();
		};
	}, [open]);

	return (
		<Dialog maxWidth="md" open={open} className={styles.container}>
			<DialogTitle>{t('add_new_bank_account')}</DialogTitle>
			<DialogContent>
				<form onSubmit={formik.handleSubmit}>
					<Grid container>
						<Grid item xs={12} md={6}>
							<Grid container>
								<Grid item xs={4}>
									<RenderFormikInput label={t('bank_name')} name="bankName" formik={formik} />
								</Grid>

								<Grid item xs={4}>
									<RenderFormikInput label={t('bank_nickname')} name="bankNickname" formik={formik} />
								</Grid>

								<Grid item xs={4}>
									<RenderFormikSelect
										formik={formik}
										label={t('bank_country')}
										name="bankCountryId"
										options={(countries && Array.isArray(countries) && countries.map(c => ({ value: c._id, title: c.name }))) || []}
									/>
								</Grid>

								<Grid item xs={8}>
									<RenderFormikInput label={t('bank_address')} name="bankAddress" formik={formik} />
								</Grid>

								<Grid item xs={4}>
									<RenderFormikInput label={t('bank_city')} name="bankCity" formik={formik} />
								</Grid>

								<Grid item xs={5}>
									<RenderFormikSelect
										formik={formik}
										label={t('bank_state_province')}
										name="bankStateId"
										options={(bankStates && Array.isArray(bankStates) && bankStates.map(c => ({ value: c._id, title: c.name }))) || []}
									/>
								</Grid>

								<Grid item xs={3}>
									<RenderFormikInput label={t('bank_zip')} name="bankZipPostalCode" formik={formik} />
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12} md={6}>
							<Grid container>
								<Grid item xs={6}>
									<RenderFormikInput label={t('beneficiary_name')} name="accountName" formik={formik} />
								</Grid>

								<Grid item xs={6}>
									<RenderFormikSelect
										formik={formik}
										label={t('beneficiary_country')}
										name="beneficiaryCountryId"
										options={(countries && Array.isArray(countries) && countries.map(c => ({ value: c._id, title: c.name }))) || []}
									/>
								</Grid>

								<Grid item xs={8}>
									<RenderFormikInput label={t('beneficiary_address')} name="accountAddress" formik={formik} />
								</Grid>

								<Grid item xs={4}>
									<RenderFormikInput label={t('beneficiary_city')} name="beneficiaryCity" formik={formik} />
								</Grid>

								<Grid item xs={5}>
									<RenderFormikSelect
										formik={formik}
										label={t('beneficiary_state_province')}
										name="beneficiaryStateId"
										options={(beneficiaryStates && Array.isArray(beneficiaryStates) && beneficiaryStates.map(c => ({ value: c._id, title: c.name }))) || []}
									/>
								</Grid>

								<Grid item xs={3}>
									<RenderFormikInput label={t('beneficiary_zip_code')} name="beneficiaryZipPostalCode" formik={formik} />
								</Grid>
								<Grid item xs={4}>
									<RenderButton onClick={triggerCopyFromBeneficiary} color="transparent" size="small">
										{t('copy_from_business_address')}
									</RenderButton>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={4}>
							<RenderFormikInput label={t('beneficiary_number_iban')} name="accountNumber" formik={formik} />
						</Grid>
						<Grid item xs={2}>
							<RenderFormikSelect
								formik={formik}
								label={t('currency')}
								name="currencyId"
								options={(currencies && Array.isArray(currencies) && currencies.map(c => ({ value: c._id, title: c.name }))) || []}
							/>
						</Grid>
						<Grid item xs={6} />
						<Grid item xs={3}>
							<RenderFormikInput label={t('aba')} name="aba" formik={formik} />
						</Grid>
						<Grid item xs={3}>
							<RenderFormikInput label={t('sort_code')} name="sortCode" formik={formik} />
						</Grid>
						<Grid item xs={3}>
							<RenderFormikInput label={t('swift')} name="swift" formik={formik} />
						</Grid>
						<Grid item xs={3} />

						<Grid item xs={3}>
							<RenderFormikInput label={t('ach')} name="ach" formik={formik} />
						</Grid>

						<Grid item xs={3}>
							<RenderFormikSelect
								formik={formik}
								label={t('account_type')}
								name="achAccountType"
								options={[
									{ value: 'checking', title: t('checking') },
									{ value: 'savings', title: t('savings') }
								]}
							/>
						</Grid>

						<Grid item xs={12}>
							<RenderFormikCheckbox label={t('correspondent_bank')} name="correspondentBank" formik={formik} />
						</Grid>

						{formik.values.correspondentBank && (
							<Grid item xs={12} md={12}>
								<Grid container>
									<Grid item xs={4}>
										<RenderFormikInput label={t('correspondent_name')} name="correspondentBankName" formik={formik} />
									</Grid>

									<Grid item xs={4}>
										<RenderFormikSelect
											formik={formik}
											label={t('correspondent_country')}
											name="correspondentBankCountryId"
											options={(countries && Array.isArray(countries) && countries.map(c => ({ value: c._id, title: c.name }))) || []}
										/>
									</Grid>

									<Grid item xs={8}>
										<RenderFormikInput label={t('correspondent_address')} name="correspondentBankAddress" formik={formik} />
									</Grid>

									<Grid item xs={4}>
										<RenderFormikInput label={t('correspondent_city')} name="correspondentBankCity" formik={formik} />
									</Grid>

									<Grid item xs={5}>
										<RenderFormikSelect
											formik={formik}
											label={t('correspondent_state_province')}
											name="correspondentBankStateId"
											options={
												(corespondentStates && Array.isArray(corespondentStates) && corespondentStates.map(c => ({ value: c._id, title: c.name }))) || []
											}
										/>
									</Grid>

									<Grid item xs={3}>
										<RenderFormikInput label={t('correspondent_zip_postal')} name="correspondentBankZipPostalCode" formik={formik} />
									</Grid>
								</Grid>
							</Grid>
						)}

						{formik.values.correspondentBank && (
							<Grid container>
								<Grid item xs={4}>
									<RenderFormikInput label={t('correspondent_number_iban')} name="correspondentAccountNumber" formik={formik} />
								</Grid>
								<Grid item xs={2}>
									<RenderFormikSelect
										formik={formik}
										label={t('correspondent_currency')}
										name="correspondentCurrencyId"
										options={(currencies && Array.isArray(currencies) && currencies.map(c => ({ value: c._id, title: c.name }))) || []}
									/>
								</Grid>
								<Grid item xs={6} />
								<Grid item xs={3}>
									<RenderFormikInput label={t('correspondent_sort_code')} name="correspondentSortCode" formik={formik} />
								</Grid>
								<Grid item xs={3}>
									<RenderFormikInput label={t('correspondent_swift')} name="correspondentSwift" formik={formik} />
								</Grid>
							</Grid>
						)}
					</Grid>
				</form>
			</DialogContent>
			<DialogActions>
				<RenderButton onClick={openCloseHandler} color="transparent">
					{t('cancel')}
				</RenderButton>
				<RenderButton color="primary" onClick={formik.handleSubmit}>
					{t('save')}
				</RenderButton>
			</DialogActions>
		</Dialog>
	);
});
