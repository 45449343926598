import React from 'react';
import { Modal, ModalHeader, ModalBody, Card } from 'reactstrap';
import FlipMove from 'react-flip-move';
import Cube from '../Cube';
import Stats from '../Stats';

const formatTimestamp = timestamp => new Date(timestamp * 1000).toLocaleString();
export default class Blocks extends React.Component {
	state = {
		isOpen: false,
		modal: false,
		backdrop: true
	};

	toggle = () => {
		this.setState({
			modal: !this.state.modal
		});
	};

	changeBackdrop = e => {
		let value = e.target.value;
		if (value !== 'static') {
			value = JSON.parse(value);
		}
		this.setState({ backdrop: value });
	};

	formatTimestamp = timestamp => new Date(timestamp * 1000).toLocaleString();

	render() {
		const { blocks, numberOfBlocksPerDay } = this.props;
		const { isOpen, block } = this.state;

		return (
			<div>
				<Stats numberOfBlocksPerDay={numberOfBlocksPerDay} />

				<h5 className="stats_title">LATEST BLOCKS </h5>
				<Card className="browser_card">
					<div className="blockWrapper">
						<Cube isAnimated />
					</div>
					<h5>Status: On demand mining </h5>
					<p>Next Block: {blocks[0] ? blocks[0].number + 1 : 'x'} </p>
				</Card>
				<FlipMove duration={500} easing="ease-out" enterAnimation="accordionVertical">
					{blocks.map(b => (
						<div key={b.number}>
							<Card
								key={b.number}
								className="browser_card"
								onClick={() => {
									this.toggle();
									this.setState({ block: b });
								}}
							>
								<div className="blockWrapper">
									<Cube color="#d3d4d3" />
								</div>
								<h5>Block Number: {b.number} </h5>
								<p>
									<b>Mined On:</b> {formatTimestamp(b.timestamp)} <br />
									<b>Transactions:</b> {b.transactions.length} <br />
									<b>Hash:</b> {b.hash}
								</p>
							</Card>
						</div>
					))}
				</FlipMove>

				<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} backdrop={this.state.backdrop}>
					<ModalHeader toggle={this.toggle}>
						<i className="fas fa-inbox" />
						&nbsp; Block Transactions
					</ModalHeader>
					<ModalBody className="explorer-modal-body">
						{block &&
							block.transactions.map(t => (
								<div key={t.transactionIndex}>
									<h5>Transaction index: {t.transactionIndex} </h5>
									<b>Transaction type: </b>
									{t.to ? 'Blockchain Method Call' : 'Smart Contract Creation'}
									<br />
									{t.to && t.decoded ? (
										<div>
											<b>Method Name: </b> {t.decoded.name} <br />
											<b>Method Input: </b> {t.decoded.params[0].value} <br />
										</div>
									) : (
										<div className="modal_input">
											<b>Encoded Input: </b> {t.input} <br />
										</div>
									)}
								</div>
							))}
					</ModalBody>
				</Modal>
			</div>
		);
	}
}
