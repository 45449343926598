import React, { Component } from 'react';
import { Field } from 'redux-form';
import { Grid, Typography } from '@material-ui/core';
import { RenderSelect, RenderDatePicker, RenderInput } from 'components';
import { withTranslation } from 'react-i18next';

class SmartContractsCommercialTerms extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const { newSmartContractData, t } = this.props;

		return (
			<div>
				<Typography variant="h2" gutterBottom>
					{t('commercial_terms')}
				</Typography>
				<Grid container>
					<Grid item xs={12}>
						<Field component={RenderInput} type="number" step="1" min="1" name="minimumNoticeDays" label={t('min_notice_period')} />
					</Grid>
					<Grid item xs={12}>
						<Field
							name="invoicePeriodId"
							id="invoicePeriodId"
							label={t('billing_cycle')}
							component={RenderSelect}
							options={newSmartContractData.invoicePeriods || []}
						/>
					</Grid>

					<Grid item xs={12}>
						<Field
							name="paymentTerms"
							id="paymentTerms"
							label={t('payment_terms')}
							component={RenderSelect}
							options={[
								{
									value: 1,
									label: t('translations:x_day', { num: 1 })
								},
								{
									value: 3,
									label: t('translations:x_days', { num: 3 })
								},
								{
									value: 5,
									label: t('translations:x_days', { num: 5 })
								},
								{
									value: 7,
									label: t('translations:x_days', { num: 7 })
								},
								{
									value: 14,
									label: t('translations:x_days', { num: 14 })
								},
								{
									value: 15,
									label: t('translations:x_days', { num: 15 })
								},
								{
									value: 30,
									label: t('translations:x_days', { num: 30 })
								},
								{
									value: 60,
									label: t('translations:x_days', { num: 60 })
								},
								{
									value: 90,
									label: t('translations:x_days', { num: 90 })
								},
								{
									value: 120,
									label: t('translations:x_days', { num: 120 })
								}
							]}
						/>
					</Grid>

					<Grid item xs={12}>
						<Field component={RenderDatePicker} name="endDate" label={t('contract_end_date')} dateFormat="MMM DD YYYY" clearable disablePast emptyLabel="Open" />
					</Grid>
					<Grid item xs={12} md={6}>
						<strong>{t('settlement_period')}: </strong>
						{(newSmartContractData.defaultSettlementPeriod && newSmartContractData.defaultSettlementPeriod.settlementPeriod) || ''}
						<Field name="settlementPeriodId" component="input" type="hidden" />
					</Grid>
					<Grid item xs={12} md={6}>
						<strong>{t('billing_timezone')}: </strong> GMT
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default withTranslation(['smartcontracts/edit', 'translations'])(SmartContractsCommercialTerms);
