import React, { memo } from 'react';
import { CardContent, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RenderTable, CurrencyNumber } from 'components';
import styles from './styles.module.scss';

export default memo(() => {
	const { t } = useTranslation('reports/reports');

	// Dummy data for demonstration purposes
	const tableData = [
		{
			carrier: 'Carrier 1',
			data: [
				{
					country: 'USA',
					destinationType: 'Mobile',
					destinationCarrier: 'AT&T',
					minutes: 1200.5,
					attempts: 1500,
					connected_calls: 1400,
					asr: 93.33,
					acd: 3.5,
					pdd: 2.1,
					rate: 0.015,
					billingIncrement: 60,
					amount: 18.0075,
				},
				// Add more rows as needed
			],
		},
		{
			carrier: 'Carrier 2',
			data: [
				{
					country: 'Canada',
					destinationType: 'Landline',
					destinationCarrier: 'Bell Canada',
					minutes: 800.0,
					attempts: 1000,
					connected_calls: 950,
					asr: 95.0,
					acd: 4.0,
					pdd: 1.8,
					rate: 0.020,
					billingIncrement: 60,
					amount: 16.0000,
				},
				// Add more rows as needed
			],
		},
		// Add more carriers as needed
	];

	const columns = [
		{
			Header: t('country'),
			accessor: 'country',
			Cell: data => data.value.replace(/['"]+/g, '')
		},
		{
			Header: t('destination_type'),
			accessor: 'destinationType'
		},
		{
			Header: t('destination_carrier'),
			accessor: 'destinationCarrier'
		},
		{
			Header: t('Minutes'),
			className: 'rt-text-right',
			accessor: 'minutes',
			Cell: data => parseFloat(data.value).toFixed(2),
			Footer: table => {
				let total = 0;
				table.data.forEach(obj => {
					total += parseFloat(obj.minutes);
				});
				return <strong>{total.toFixed(2)}</strong>;
			}
		},
		{
			Header: t('attempts'),
			className: 'rt-text-right',
			accessor: 'attempts'
		},
		{
			Header: t('connected_calls'),
			className: 'rt-text-right',
			accessor: 'connected_calls'
		},
		{
			Header: 'ASR',
			className: 'rt-text-right',
			accessor: 'asr',
			Cell: data => `${parseFloat(data.value).toFixed(0)}%`
		},
		{
			Header: 'ACD',
			className: 'rt-text-right',
			accessor: 'acd'
		},
		{
			Header: 'PDD',
			className: 'rt-text-right',
			accessor: 'pdd'
		},
		{
			Header: t('rate'),
			className: 'rt-text-right',
			accessor: 'rate',
			Cell: props => <CurrencyNumber amount={parseFloat(props.value).toFixed(6)} precision={6} />
		},
		{
			Header: t('billing_inc'),
			className: 'rt-text-right',
			accessor: 'billingIncrement'
		},
		{
			Header: t('amount'),
			className: 'rt-text-right',
			accessor: 'amount',
			Cell: props => <CurrencyNumber amount={parseFloat(props.value).toFixed(6)} precision={6} />,
			Footer: table => {
				let total = 0;
				table.data.forEach(obj => {
					total += parseFloat(obj.amount);
				});
				return (
					<strong>
						<CurrencyNumber amount={total.toFixed(6)} precision={6} />
					</strong>
				);
			}
		}
	];

	return (
		<div className={styles.container}>
			<div>
				{tableData.map((carrier, i) => (
					<Paper key={i}>
						<CardContent>
							<h3>{carrier.carrier}</h3>
							<RenderTable
								resizable={false}
								sortable={false}
								data={carrier.data}
								style={{ width: '100%' }}
								columns={columns}
								filterable={false}
							/>
						</CardContent>
					</Paper>
				))}
			</div>
		</div>
	);
});
