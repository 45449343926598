import React, { Component } from "react";
import { connect } from "react-redux";
import { AcceptInterconnectionsForm } from "components";
import {
  acceptInterconnection,
  setModalAcceptInterconnections,
} from "store/actions";
import { reduxForm, reset } from "redux-form";
import { fromBiddingportal, fromInterconnections } from "store/selectors";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import {
  createValidator,
  requiredInterconnectionType,
} from "services/validation";

class AcceptInterconnectionsContainer extends Component {
  constructor(props) {
    super(props);
    this.sendInterconnections = this.sendInterconnections.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(reset("acceptInterconnectionForm"));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(reset("acceptInterconnectionForm"));
    this.props.hideAcceptInterconnections();
  }
  sendInterconnections(data) {
    this.props.hideAcceptInterconnections();
    this.props.handleSubmit(data);
  }

  render() {
    return (
      <AcceptInterconnectionsForm
        {...this.props}
        handleSubmit={this.props.handleSubmit}
      />
    );
  }
}
const mapStateToProps = state => ({
  acceptInterconnection: fromInterconnections.getDataModalAcceptInterconnections(
    state,
  ),
  initialValues: fromInterconnections.getDataModalAcceptInterconnections(state)
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      acceptInterconnection,
      setModalAcceptInterconnections,
    },
    dispatch
  );
}

const onSubmit = (data, dispatch) => {
  dispatch(setModalAcceptInterconnections(false, null));
  dispatch(acceptInterconnection(data.connectGuid));
};

const validate = createValidator({});
AcceptInterconnectionsContainer.propTypes = {
  hideAcceptInterconnections: PropTypes.func.isRequired
};

const reduxFormAcceptInterconnectionsContainer = reduxForm({
  form: "acceptInterconnectionForm",
  destroyOnUnmount: true,
  onSubmit,
  validate,
})(AcceptInterconnectionsContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxFormAcceptInterconnectionsContainer);
