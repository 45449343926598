import actions, {
	DOWNLOAD_INVOICE,
	INVOICE_DOWNLOAD_COMPLETED,
	LOAD_EXTERNAL_INVOICES,
	LOAD_INVOICES,
	LOAD_INVOICES_FAILURE,
	LOAD_MONTHLY_INVOICES,
	NEW_REPORT,
	REPORTS_FILTERS,
	REPORT_CDR_DATA,
	REPORT_DATA,
	SET_CREATE_REPORT,
	SET_CUSTOMER_INVOICE_DATA,
	SET_EXTERNAL_INVOICES_DATA,
	SET_EXTERNAL_INVOICES_DATA_ERROR,
	SET_INVOICE_REPORT_ERROR,
	SET_MONTHLY_CUSTOMER_INVOICE_DATA,
	SET_MONTHLY_INVOICE_REPORT_ERROR,
	SET_MONTHLY_SUPPLIER_INVOICE_DATA,
	SET_SUPPLIER_INVOICE_DATA,
	REPORT_LOADING,
	SET_PAYMENT_STATUS
} from './actions';
import {
	EXTERNAL_INVOICE_REPORT_DATA,
	INVOICE_DOWNLOAD_DATA,
	INVOICE_REPORT_DATA,
	MONTHLY_INVOICE_REPORT_DATA,
	getInvoices,
	getMonthlyInvoices,
	getExternalInvoices
} from './selectors';

const initialState = {
	loading: false,
	loadingFilters: false,
	loadingPartitionStats: false,
	loadingAdvancedReports: false,
	loadingReportTemplates: false,

	reportsFilters: [],
	advancedReport: {
		data: [],
		metaData: {
			total: 0,
			page: 1,
			perPage: 1
		}
	},
	customerReport: [],
	supplierReport: [],
	saleAndCostReport: [],
	CDRReport: [],
	partitionStats: {
		sessionsKpi: [],
		sessionsActive: []
	},
	partitionActiveCalls: [],
	advancedReportIndexes: [],
	reportTemplates: [],
	reportTemplate: {},
	[MONTHLY_INVOICE_REPORT_DATA]: { ...getMonthlyInvoices() },
	[EXTERNAL_INVOICE_REPORT_DATA]: { ...getExternalInvoices() },
	[INVOICE_REPORT_DATA]: { ...getInvoices() }
};

export default function(state = initialState, action) {
	let customerInvoicesData;
	let invoiceSubType;
	let supplierInvoicesData;
	let supplierMonthlyInvoicesData;
	let customerMonthlyInvoicesData;
	let invoicesData;
	let invoiceType;
	let invoices;

	switch (action.type) {
		case actions.SET_STATE:
			return { ...state, ...action.payload };

		case actions.SET_MONTHLY_INVOICES_STATE:
			customerInvoicesData = {
				customer: {
					...getMonthlyInvoices(state).customer,
					...action.payload.payload
				}
			};
			supplierInvoicesData = {
				supplier: {
					...getMonthlyInvoices(state).supplier,
					...action.payload.payload
				}
			};
			invoicesData = action.payload.clientType === 'customer' ? customerInvoicesData : supplierInvoicesData;

			return {
				...state,
				[MONTHLY_INVOICE_REPORT_DATA]: {
					...getMonthlyInvoices(state),
					...invoicesData
				}
			};

		case actions.SET_INVOICES_STATE_BY_TYPE:
			invoiceType = action.payload.invoiceType;
			invoiceSubType = action.payload.isOrigination;
			invoices = getInvoices(state)[invoiceType];
		
			invoicesData = {
				[invoiceType]: {
					...invoices,
					[invoiceSubType]: {
						...invoices[invoiceSubType],
						...action.payload.payload
					}
				}
			};

			return {
				...state,
				[INVOICE_REPORT_DATA]: {
					...getInvoices(state),
					...invoicesData,
					...action.payload.generalInvoiceInfo
				}
			};

		case actions.SET_INVOICES_GENERAL_STATE:
			return {
				...state,
				[INVOICE_REPORT_DATA]: {
					...getInvoices(state),
					...action.payload
				}
			};

		case DOWNLOAD_INVOICE:
			return {
				...state,
				[INVOICE_DOWNLOAD_DATA]: {
					isDownloading: true
				}
			};
		case INVOICE_DOWNLOAD_COMPLETED:
			return {
				...state,
				[INVOICE_DOWNLOAD_DATA]: {
					isDownloading: false
				}
			};
		case REPORTS_FILTERS:
			return {
				...state,
				[REPORTS_FILTERS]: action.payload
			};
		case REPORT_DATA:
			return {
				...state,
				[REPORT_DATA]: action.payload
			};
		case REPORT_CDR_DATA:
			return {
				...state,
				[REPORT_CDR_DATA]: action.payload
			};
		case NEW_REPORT:
			return {
				...state,
				[NEW_REPORT]: {
					payload: action.payload,
					status: 'sending'
				}
			};
		case SET_CREATE_REPORT:
			return {
				...state,
				[SET_CREATE_REPORT]: action.payload
			};
		case LOAD_MONTHLY_INVOICES:
			customerInvoicesData = {
				customer: {
					...getMonthlyInvoices(state).customer,
					loading:
						// !action?.payload?.monthlyInvoicesCached ??
						true
				}
			};
			supplierInvoicesData = {
				supplier: {
					...getMonthlyInvoices(state).supplier,
					loading:
						// !action?.payload?.monthlyInvoicesCached ??
						true
				}
			};
			invoicesData = action.payload.type === 'customer' ? customerInvoicesData : supplierInvoicesData;
			return {
				...state,
				[MONTHLY_INVOICE_REPORT_DATA]: {
					...getMonthlyInvoices(state),
					...invoicesData
				}
			};

		case LOAD_EXTERNAL_INVOICES:
			return {
				...state,
				[EXTERNAL_INVOICE_REPORT_DATA]: {
					data: [],
					loading:
						// !action?.payload?.externalInvoicesCached ??
						true,
					error: null
				}
			};
		case SET_EXTERNAL_INVOICES_DATA:
			return {
				...state,
				[EXTERNAL_INVOICE_REPORT_DATA]: {
					data: action.payload,
					loading: false,
					error: null,
					externalInvoicesCached: true
				}
			};
		case SET_EXTERNAL_INVOICES_DATA_ERROR:
			return {
				...state,
				[EXTERNAL_INVOICE_REPORT_DATA]: {
					data: [],
					loading: false,
					error: action.payload.info
				}
			};
		case LOAD_INVOICES: {
			invoiceType = action.payload.type;
			invoiceSubType = action.payload.isOrigination ? 'origination' : 'standard';
			invoices = getInvoices(state)[invoiceType];

			invoicesData = {
				[invoiceType]: {
					...invoices,
					[invoiceSubType]: {
						...invoices[invoiceSubType],
						loading:
							//  !action?.payload?.invoicesCached ??
							true
					}
				}
			};

			return {
				...state,
				[INVOICE_REPORT_DATA]: {
					...getInvoices(state),
					...invoicesData
				}
			};
		}

		case SET_CUSTOMER_INVOICE_DATA:
			customerInvoicesData = getInvoices(state).customer;

			return {
				...state,
				[INVOICE_REPORT_DATA]: {
					...getInvoices(state),
					customer: {
						...customerInvoicesData,
						[action.payload.type]: {
							data: action.payload.data,
							loading: action.payload.loading,
							info: action.payload.info,
							invoiceError: undefined
						},
						invoicesCached: true
					}
				}
			};
		case SET_MONTHLY_SUPPLIER_INVOICE_DATA:
			supplierMonthlyInvoicesData = getMonthlyInvoices(state).supplier;
			return {
				...state,
				[MONTHLY_INVOICE_REPORT_DATA]: {
					...getMonthlyInvoices(state),
					supplier: {
						...supplierMonthlyInvoicesData,
						data: action.payload.data,
						loading: action.payload.loading,
						info: action.payload.info,
						invoiceError: undefined
					}
				}
			};
		case SET_MONTHLY_CUSTOMER_INVOICE_DATA:
			customerMonthlyInvoicesData = getMonthlyInvoices(state).customer;
			return {
				...state,
				[MONTHLY_INVOICE_REPORT_DATA]: {
					...getMonthlyInvoices(state),
					customer: {
						...customerMonthlyInvoicesData,
						data: action.payload.data,
						loading: action.payload.loading,
						info: action.payload.info,
						invoiceError: undefined
					},
					monthlyInvoicesCached: true
				}
			};
		case SET_SUPPLIER_INVOICE_DATA:
			supplierInvoicesData = getInvoices(state).supplier;

			return {
				...state,
				[INVOICE_REPORT_DATA]: {
					...getInvoices(state),
					supplier: {
						...supplierInvoicesData,
						[action.payload.type]: {
							data: action.payload.data,
							loading: action.payload.loading,
							info: action.payload.info,
							invoiceError: undefined
						},
						invoicesCached: true
					}
				}
			};
		case SET_MONTHLY_INVOICE_REPORT_ERROR:
			customerInvoicesData = {
				customer: {
					...getMonthlyInvoices(state).customer,
					loading: false,
					data: [],
					info: action.payload.info
				}
			};
			supplierInvoicesData = {
				supplier: {
					...getMonthlyInvoices(state).supplier,
					loading: false,
					data: [],
					info: action.payload.info
				}
			};
			invoicesData = action.payload.type === 'customer' ? customerInvoicesData : supplierInvoicesData;
			return {
				...state,
				[MONTHLY_INVOICE_REPORT_DATA]: {
					...getMonthlyInvoices(state),
					...invoicesData
				}
			};
		case SET_INVOICE_REPORT_ERROR:
			invoiceSubType = action.payload.isOrigination ? 'origination' : 'standard';

			customerInvoicesData = {
				customer: {
					...getInvoices(state).customer,
					[invoiceSubType]: {
						loading: false,
						data: [],
						info: action.payload.info
					}
				}
			};
			supplierInvoicesData = {
				supplier: {
					...getInvoices(state).supplier,
					[invoiceSubType]: {
						loading: false,
						data: [],
						info: action.payload.info
					}
				}
			};
			invoicesData = action.payload.type === 'customer' ? customerInvoicesData : supplierInvoicesData;
			return {
				...state,
				[INVOICE_REPORT_DATA]: {
					...getInvoices(state),
					...invoicesData
				}
			};
		case LOAD_INVOICES_FAILURE:
			return {
				...state,
				[INVOICE_REPORT_DATA]: {},
				invoiceError: action.payload
			};
		case SET_PAYMENT_STATUS:
			return {
				...state,
				[INVOICE_REPORT_DATA]: {
					...getInvoices(state),
					paymentInfo: action.payload
				}
			};

		case REPORT_LOADING:
			return {
				...state,
				[REPORT_LOADING]: action.payload
			};

		default:
			return state;
	}
}
