import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import jwt_decode from "jwt-decode";
import { Redirect } from "react-router-dom";
import { urlRedirectRequest, loginSuccess } from "store/actions";

class ValidateURLContainer extends Component {
  componentWillMount() {
    const { match } = this.props;
    console.log(`componentWillMount:${match.params.token}`);
    this.validateToken(match.params.token);
  }

  validateToken(token) {
    const { validToken, redirectUrl } = this.props;
    try {
      const decodedData = window.atob(token);
      const decoded = jwt_decode(decodedData);
      console.log(`url:${decoded.url}`);
      redirectUrl(decoded.url, decoded.userId);
      validToken("Enter your credentials to continue");
    } catch (e) {
      console.log(`*******${e}`);
      validToken("The entered address is not valid");
    }
  }

  render() {
    return <Redirect to="/login" />;
  }
}

ValidateURLContainer.propTypes = {
  validToken: PropTypes.func.isRequired,
  redirectUrl: PropTypes.func.isRequired,
  match: PropTypes.objectOf(
    PropTypes.shape({
      params: PropTypes.objectOf(
        PropTypes.shape({
          token: PropTypes.string.isRequired
        })
      ).isRequired,
      path: PropTypes.string
    })
  ).isRequired
};

const mapDispatchToProps = dispatch => ({
  validToken: msg => {
    dispatch(loginSuccess(msg));
  },
  redirectUrl: (url, userId) => {
    dispatch(urlRedirectRequest(url, userId));
  }
});
const mapStateToProps = state => ({
  stateGen: state
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ValidateURLContainer);
