import React, { memo, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { FileDownload } from '@material-ui/icons';
import { Grid, Typography, Paper, TextField, MenuItem } from '@material-ui/core';
import * as moment from 'moment';

import CurrencyNumber from 'components/atoms/CurrencyNumber';
import ProFormaClaimPDF from './DailyClaimPDF';
import Loader from 'components/atoms/Loader';
import { RenderTable, RenderModal } from 'components';
import RenderButton from 'components/atoms/RenderButton';
import RenderAcpType from 'components/atoms/AcpType';

import { actionMyAccountGetDataRequest } from 'store/myaccount/actions';
import actions from 'store/acp/actions';
import styles from './styles.module.scss';

export default memo(() => {
	const dispatch = useDispatch();

	const [expanded, setExpanded] = useState({});
	const [viewClaimModalOpen, setViewClaimModalOpen] = useState(false);
	const [selectedClaim, setSelectedClaim] = useState(null);

	const isLoading = useSelector(state => (state.acp && state.acp.loading) || false);
	const accountLoading = useSelector(state => state.myaccount && state.myaccount.loading);
	const dailyClaims = useSelector(state => (state.acp && state.acp.proFormaClaims) || []);
	const participantInfo = useSelector(state => (state.acp && state.acp.proFormaClaimsParticipantInfo) || {});
	const accountData = useSelector(state => (state.myaccount && state.myaccount?.accountData) || {});

	useEffect(() => {
		dispatch({ type: actions.GET_PRO_FORMA_CLAIMS });
		dispatch({ type: actions.GET_PRO_FORMA_CLAIMS_PARTICIPANT_INFO });
		dispatch(actionMyAccountGetDataRequest());
	}, []);

	const DateFilter = ({ filter, onChange, type = 'date' }) => (
		<TextField
			type={type}
			value={filter ? filter.value : ''}
			onChange={e => onChange(e.target.value)}
			style={{ width: '80%' }}
			variant="standard"
			InputLabelProps={{
				shrink: true
			}}
		/>
	);

	const columns = [
		{
			Header: 'Upload Date',
			accessor: 'uploadDate',
			Cell: cell => {
				return <span>{moment(cell.original?.uploadDate).format('MMM Do YYYY')}</span>;
			},
			width: 200,
			Filter: ({ filter, onChange }) => <DateFilter filter={filter} onChange={onChange} type="date" />
		},
		{
			Header: 'Verification Date',
			accessor: 'nladVerificationDate',
			Cell: cell => {
				return <span>{moment(cell.original?.nladVerificationDate).format('MMM Do YYYY')}</span>;
			},
			width: 200,
			Filter: ({ filter, onChange }) => <DateFilter filter={filter} onChange={onChange} type="date" />
		},
		{
			Header: 'Claim Number',
			accessor: 'claimNumber',
			Cell: cell => {
				return <span>{cell.original?.claimNumber}</span>;
			},
			width: 125,
			Filter: ({ filter, onChange }) => <TextField value={filter ? filter.value : ''} onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard" />
		},
		{
			Header: 'Type',
			accessor: 'type',
			Cell: cell => {
				return <RenderAcpType size="small" claimType={cell.original?.type} />;
			},
			width: 125,
			Filter: ({ filter, onChange }) => (
				<TextField value={filter ? filter.value : ''} style={{ width: '80%' }} select onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard">
					<MenuItem value="">All</MenuItem>
					<MenuItem value="sales">Sales</MenuItem>
					<MenuItem value="mrc">MRC</MenuItem>
					<MenuItem value="claimable-devices">Claimable Devices</MenuItem>
				</TextField>
			)
		},
		{
			Header: <div className="number-column">Quantity</div>,
			accessor: 'quantity',
			Cell: cell => {
				return <div className="number-column">{cell.original?.quantity}</div>;
			},
			width: 125,
			Filter: ({ filter, onChange }) => <TextField value={filter ? filter.value : ''} onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard" />
		},
		{
			Header: <div className="number-column">Value</div>,
			accessor: 'value',
			Cell: cell => {
				return (
					<div className="number-column">
						<CurrencyNumber amount={cell.original?.value} precision={0} />
					</div>
				);
			},
			width: 125,
			Filter: ({ filter, onChange }) => <TextField value={filter ? filter.value : ''} onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard" />
		},
		{
			Header: <div className="number-column">Financed</div>,
			accessor: 'financed',
			Cell: cell => {
				return (
					<div className="number-column">
						<CurrencyNumber amount={cell.original?.financed} precision={0} />
					</div>
				);
			},
			// width: 125,
			Filter: ({ filter, onChange }) => <TextField value={filter ? filter.value : ''} onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard" />
		},
		{
			Header: 'Actions',
			filterable: false,
			accessor: 'actions',
			width: 200,
			Cell: cell => {
				return (
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-start'
						}}
					>
						<RenderButton
							color="primary"
							small
							style={{
								marginRight: '10px'
							}}
							disabled={isLoading && accountLoading}
							onClick={() => {
								setSelectedClaim({
									claim: cell.original,
									participant: participantInfo[0],
									accountData: accountData
								});
								setViewClaimModalOpen(true);
							}}
						>
							View
						</RenderButton>
						<PDFDownloadLink
							document={
								<ProFormaClaimPDF
									data={{
										claim: cell.original,
										participant: participantInfo[0],
										accountData: accountData
									}}
								/>
							}
							fileName={cell.original?.claimNumber}
						>
							<RenderButton color="primary" small disabled={isLoading && accountLoading}>
								Download
							</RenderButton>
						</PDFDownloadLink>
					</div>
				);
			}
		}
	];

	const getTdProps = useCallback((state, rowInfo) => ({
		onClick: (e, handleOriginal) => {
			const { original } = rowInfo;
			const path = rowInfo.nestingPath[0];
			const diff = { [path]: !expanded[path] };
			setExpanded(diff);
			if (handleOriginal) {
				handleOriginal();
			}
		}
	}));

	return (
		<div className={styles.container}>
			<Paper>
				<Grid container className="grid-container">
					<Grid item xs={12}>
						<Typography variant="h1" gutterBottom>
							Daily Claims
						</Typography>
					</Grid>
					<Grid item xs={12}>
						{(isLoading && accountLoading && <Loader isLoading />) || (
							<RenderTable data={dailyClaims ?? []} loading={isLoading && accountLoading} minRows={2} columns={columns} filterable={!isLoading} sortable={true} />
						)}
					</Grid>
				</Grid>
			</Paper>
			<RenderModal
				style={{
					padding: '0px'
				}}
				open={viewClaimModalOpen}
				onModalClose={() => setViewClaimModalOpen(false)}
			>
				{selectedClaim !== undefined && (
					<div
						style={{
							position: 'relative',
							left: '-21px',
							top: '-38px',
							textAlign: 'center'
						}}
					>
						<PDFViewer height={'842px'} width={'595px'}>
							<ProFormaClaimPDF data={selectedClaim} />
						</PDFViewer>
						<PDFDownloadLink document={<ProFormaClaimPDF data={selectedClaim} />} fileName={selectedClaim?.claimNumber}>
							<RenderButton color="primary" disabled={isLoading && accountLoading}>
								<FileDownload /> Download
							</RenderButton>
						</PDFDownloadLink>
					</div>
				)}
			</RenderModal>
		</div>
	);
});
