import React, { Component, useEffect, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field } from 'redux-form';
import { Link, Redirect } from 'react-router-dom';

import { RenderInput, RenderButton, RenderNotification } from 'components';

import { Card, CardHeader, CardContent, CardActions, Grid, InputAdornment, IconButton, Typography } from '@material-ui/core';

import { Email, Visibility, VisibilityOff, AddBox, LockOpen, Help, KeyboardCapslock } from '@material-ui/icons';
import loginStyle from './style';
import Loader from 'components/atoms/Loader';

class LoginForm extends Component {
	constructor(props) {
		super(props);
	}

	state = {
		showPassword: false,
		capsLockOn: false
	};

	handleMouseDownPassword = event => {
		event.preventDefault();
	};

	handleClickShowPassword = () => {
		this.setState(state => ({ showPassword: !state.showPassword }));
	};

	componentDidMount() {
		document.addEventListener('keypress', this.handleKeyPress);
	}

	componentWillUnmount() {
		document.removeEventListener('keypress', this.handleKeyPress);
	}

	handleKeyPress = e => {
		this.setState({ capsLockOn: e.getModifierState('CapsLock') });
	};

	render() {
		const {
			handleSubmit,
			classes,
			info: { errorMessage, successMessage },
			passwordChallengeInfo,
			loading
		} = this.props;

		const { environment } = window.config;

		return (
			<form onSubmit={handleSubmit} className={classes.form}>
				{loading && <Loader fullscreen />}
				<RenderNotification variant="danger" floating open={errorMessage !== null} message={errorMessage} />
				<RenderNotification variant="success" floating open={successMessage !== null && successMessage !== undefined} message={successMessage} />
				<Card className={classes.root}>
					<CardContent>
						<img src="/images/Portal_Logo.png" alt="Logo TessPay" />
						<br />
						<br />
						<Field
							name="email"
							label="Email"
							type="email"
							onKeyPress={this.handleKeyPress}
							component={RenderInput}
							InputLabelProps={{
								shrink: true
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton disabled>
											<Email />
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
						{this.state.capsLockOn && (
							<Typography variant="caption" color="error">
								Caps lock is On!
							</Typography>
						)}

						<Field
							name="password"
							label="Password"
							type={this.state.showPassword ? 'text' : 'password'}
							component={RenderInput}
							InputLabelProps={{
								shrink: true
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword} onMouseDown={this.handleMouseDownPassword}>
											{this.state.showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
					</CardContent>
					<CardActions>
						<Grid container justify="center" alignItems="center">
							<Grid item xs={8}>
								{environment && environment === 'development' ? (
									<RenderButton component={Link} to="/register" underlined>
										Register Now
									</RenderButton>
								) : null}
								<RenderButton component={Link} to="/reset" className="js-t-forgot-pw" underlined>
									Forgot My Password
								</RenderButton>
							</Grid>
							<Grid item xs={4} style={{ textAlign: 'right' }}>
								<RenderButton color="primary" type="submit">
									Login
								</RenderButton>
							</Grid>
						</Grid>
					</CardActions>
					{passwordChallengeInfo.challengeName === 'NEW_PASSWORD_REQUIRED' && <Redirect to="/password-challenge" />}
				</Card>
			</form>
		);
	}
}

export default withStyles(loginStyle)(LoginForm);
