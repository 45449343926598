import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, reset } from 'redux-form';
import { bindActionCreators } from 'redux';

import { createValidator, required, email, minLength } from 'services/validation';
import { LoginForm } from 'components';
import { loginRequest, loginSuccess, loginFailure, cleanDataLogin, changePasswordSuccess } from 'store/actions';
import { fromLoginaccount, fromChangepassword } from 'store/selectors';

class LoginFormContainer extends Component {
	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(reset('loginForm'));
		dispatch(cleanDataLogin());
	}

	render() {
		return <LoginForm {...this.props} handleSubmit={this.props.handleSubmit} />;
	}
}

const mapStateToProps = state => ({
	form: state.form,
	info: fromLoginaccount.getLoginDataState(state),
	passwordChallengeInfo: state.passwordchallenge,
	loading: state.loginaccount.loading
});

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			loginSuccess,
			loginFailure
		},
		dispatch
	);
}

const onSubmit = (data, dispatch) => {
	dispatch(loginRequest(data.email.toLowerCase(), data.password));
};

const validate = createValidator({
	email: [required, email],
	password: [required, minLength(8)]
});

LoginFormContainer = connect(mapStateToProps, mapDispatchToProps)(LoginFormContainer);

LoginFormContainer = reduxForm({
	form: 'loginForm',
	destroyOnUnmount: true,
	onSubmit,
	validate,
	touchOnBlur: false
})(LoginFormContainer);

export default LoginFormContainer;
