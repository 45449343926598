export const initialState = {};

export const initialUserInfoData = {
  userInfo: null
};
export const USER_INFO_DATA = "USER_INFO_DATA";

export const getUserInfoDataState = (state = initialState) =>
  state[USER_INFO_DATA] || initialUserInfoData;

export const getUserInfo = (state = initialState) =>
  getUserInfoDataState(state).userInfo !== null &&
  getUserInfoDataState(state).userInfo !== undefined
    ? getUserInfoDataState(state).userInfo
    : "";

export const getUserRol = (state = initialState) =>
  getUserInfo(state).roles !== undefined &&
  Object.keys(getUserInfo(state).roles).length > 0
    ? getUserInfo(state).roles
    : "";
