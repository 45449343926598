import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import radioStyle from './style';

const FormikRadioGroup = props => {
	const {
		field,
		classes,
		inline,
		callback,
		children,
		form: { touched, errors },
		...rest
	} = props;

	const handleChange = value => {
		field.onChange(value);

		if (callback) {
			callback(value);
		}
	};

	const hasError = (touched[field.name] && errors[field.name]) || false;

	return (
		<FormControl error={hasError} fullWidth>
			<RadioGroup
				{...field}
				{...rest}
				onChange={handleChange}
				className={inline && classes.inline}
				// error={error}
			>
				{children}
			</RadioGroup>
			{hasError ? <FormHelperText error>{errors[field.name]}</FormHelperText> : ''}
		</FormControl>
	);
};

FormikRadioGroup.defaultProps = {
	inline: true
};

FormikRadioGroup.propTypes = {
	classes: PropTypes.shape().isRequired,
	inline: PropTypes.bool
};

export default withStyles(radioStyle)(FormikRadioGroup);
