import { getWalletDataState, getVoipApiData, WALLET_DATA, VOIP_API_DATA } from './selectors';
import actions, {
	LOAD_INITIAL_WALLET,
	WALLET_DATA_SUCCESS,
	WALLET_TOPUP,
	WALLET_WITHDRAW,
	RESULT_WALLET,
	LOAD_INITIAL_WELCOME_WALLET,
	LOAD_VOIP_API_DATA_SUCCESS,
	TRANSACTIONS_LOADING,
	WALLET_LOADING,
	WALLET_TRANSACTIONS
} from './actions';

const initialState = {
	transactions: [],
	transactionsFilters: {},
	loadingFilters: false,
	walletCached: false,
	[WALLET_DATA]: getWalletDataState(),
	RESULT_WALLET: {
		status: '',
		message: ''
	}
};

export default (state = initialState, { type, payload, meta }) => {
	switch (type) {
		case actions.SET_STATE:
			return { ...state, ...payload };

		case LOAD_INITIAL_WELCOME_WALLET:
			return {
				...state,
				[WALLET_DATA]: {
					initialState
				}
			};

		case LOAD_INITIAL_WALLET:
			return {
				...state,
				[WALLET_DATA]: {
					initialState
				}
			};

		case WALLET_DATA_SUCCESS:
			return {
				...state,
				[WALLET_DATA]: {
					...getWalletDataState(state),
					...payload,
					errorMessage: null
				}
			};
		//
		case LOAD_VOIP_API_DATA_SUCCESS:
			return {
				...state,
				[VOIP_API_DATA]: {
					...getVoipApiData(state),
					...payload,
					errorMessage: null
				}
			};

		case WALLET_TOPUP:
			return {
				...state,
				[WALLET_DATA]: {
					initialState
				}
			};

		case WALLET_WITHDRAW:
			return {
				...state,
				[WALLET_DATA]: {
					initialState
				}
			};

		case RESULT_WALLET:
			return {
				...state,
				[RESULT_WALLET]: {
					...payload
				}
			};
		case TRANSACTIONS_LOADING:
			return {
				...state,
				[TRANSACTIONS_LOADING]: payload
			};
		case WALLET_LOADING:
			return {
				...state,
				[WALLET_LOADING]: payload
			};
		case WALLET_TRANSACTIONS:
			return {
				...state,
				[WALLET_TRANSACTIONS]: payload
			};

		default:
			return state;
	}
};
