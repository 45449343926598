import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Radio from "@material-ui/core/Radio";
import radioStyle from "./style";

const RenderRadio = ({ ...props }) => {
  const { classes, color, label, name, value, ...rest } = props;

  return (
    <FormControlLabel
      control={
        <Radio
          value={value}
          name={name}
          icon={<FiberManualRecord className={classes.radioUnchecked} />}
          checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
          classes={{
            checked: classes.radio
          }}
          {...rest}
        />
      }
      classes={{
        label: classes.label
      }}
      label={label}
    />
  );
};

RenderRadio.defaultProps = {
  color: "primary"
};

RenderRadio.propTypes = {
  classes: PropTypes.shape().isRequired,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent"
  ])
};

export default withStyles(radioStyle)(RenderRadio);
