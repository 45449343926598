import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";

import { CurrencyNumber } from "components";
import PropTypes from "prop-types";
import React from "react";
import externalInvoiceSummaryStyle from "./style";
import { withStyles } from "@material-ui/core/styles";

const CutomTableHeadCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  }
}))(TableCell);

const CustomTableRow = withStyles({
  root: {
    height: "auto"
  }
})(TableRow);

const CustomRow = ({
  firstCellContent,
  secondCellContent,
  thirdCellContent,
  forthCellContent
}) => (
  <CustomTableRow>
    <TableCell>{firstCellContent}</TableCell>
    <TableCell numeric>{secondCellContent}</TableCell>
    <TableCell numeric>{thirdCellContent}</TableCell>
    <TableCell numeric>{forthCellContent}</TableCell>
  </CustomTableRow>
);

CustomRow.defaultProps = {
  firstCellContent: "",
  secondCellContent: "",
  thirdCellContent: "",
  forthCellContent: ""
};

CustomRow.propTypes = {
  firstCellContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  secondCellContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  thirdCellContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  forthCellContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

const ExternalInvoiceSummary = props => {
  const { data, classes } = props;
  return (
    <div>
      <Typography
        variant="headline"
        align="center"
        className={classes.headline}
      >
        Invoice Summary
      </Typography>

      <Table className={classes.table}>
        <TableHead>
          <CustomTableRow>
            <CutomTableHeadCell>Description</CutomTableHeadCell>
            <CutomTableHeadCell numeric>Calls</CutomTableHeadCell>
            <CutomTableHeadCell numeric>Minutes</CutomTableHeadCell>
            <CutomTableHeadCell numeric>Amount</CutomTableHeadCell>
          </CustomTableRow>
        </TableHead>
        <TableBody>
          <CustomRow
            firstCellContent={
              <Typography variant="body2">Total Usage</Typography>
            }
            secondCellContent={data.calls}
            thirdCellContent={data.minutes}
            forthCellContent={
              <CurrencyNumber
                amount={`${Number(`${Math.round(`${data.amount}e2`)}e-2`)}`}
              />
            }
          />
          <CustomRow
            firstCellContent="Tax"
            forthCellContent={<CurrencyNumber amount="0" />}
          />
          <CustomRow
            firstCellContent="Payments"
            forthCellContent={<CurrencyNumber amount="0" />}
          />
          <CustomRow
            firstCellContent="Adjustments"
            forthCellContent={<CurrencyNumber amount={0} />}
          />
          <CustomRow
            firstCellContent={
              <Typography variant="body2">Total Amount Due</Typography>
            }
            forthCellContent={
              <CurrencyNumber
                amount={`${Number(`${Math.round(`${data.amount}e2`)}e-2`)}`}
              />
            }
          />
        </TableBody>
      </Table>
    </div>
  );
};

ExternalInvoiceSummary.defaultProps = {};

ExternalInvoiceSummary.propTypes = {
  classes: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired
};

export default withStyles(externalInvoiceSummaryStyle)(ExternalInvoiceSummary);
