import { initialLoginData, getLoginDataState, LOGIN_DATA } from './selectors';
import { LOGIN_REQUEST, MFA_REQUEST, LOGIN_FAILURE, LOGIN_SUCCESS, CLEAN_DATA_LOGIN, SET_LOGIN_LOADING } from './actions';

export default (state = initialLoginData, { type, payload, meta }) => {
	switch (type) {
		case LOGIN_REQUEST:
			return {
				...state,
				[LOGIN_DATA]: {
					...getLoginDataState(state),
					...payload
				}
			};
		case LOGIN_FAILURE:
			return {
				...state,
				[LOGIN_DATA]: {
					errorMessage: payload,
					loginSuccess: false
				}
			};
		case LOGIN_SUCCESS:
			return {
				...state,
				[LOGIN_DATA]: {
					...getLoginDataState(state),
					userName: null,
					password: null,
					successMessage: payload,
					loginSuccess: true
				}
			};
		case CLEAN_DATA_LOGIN:
			return {
				...state,
				[LOGIN_DATA]: {
					...initialLoginData,
					successMessage: getLoginDataState(state).successMessage
				}
			};

		case SET_LOGIN_LOADING:
			return {
				...state,
				loading: payload
			};
		default:
			return state;
	}
};
