import React, { memo, useState } from 'react';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import { AuthManager } from 'components';
import { CustomerReportTab, SupplierReportTab, SaleCostReportTab, CDRDownloadTab, AdvancedReportsTab, DashboardReportTab } from './components';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import {useSelector} from "react-redux";

export default memo(() => {
	const [selectedTab, setSelectedTab] = useState('dashboard-report');
	const userEmail = useSelector(state => state.myaccount && state.myaccount.accountData && state.myaccount.accountData.email || '')
	const { t } = useTranslation('reports/reports');

	return (
		<AuthManager permissions={['reports']}>
			<div className={styles.container}>
				<div className="head-tabs">
					<AppBar position="static">
						<Tabs value={selectedTab} onChange={(e, value) => setSelectedTab(value)}>
							<Tab value="dashboard-report" label="dashboard" />
							<Tab value="customer-report" label={t('customer_traffic_report')} />
							<Tab value="supplier-report" label={t('supplier_traffic_report')} />
							<Tab value="sale-cost-report" label={t('sale_cost_report')} />
							{userEmail && userEmail.indexOf('@tesspay') > -1 && (
								<Tab value="advanced-report" label={t('advanced_reports')} />
							) || null}
							<Tab value="cdr-report" label={t('cdr_download')} />
						</Tabs>
					</AppBar>
				</div>

				<div>
					{(selectedTab === 'dashboard-report' && <DashboardReportTab />) || ''}
					{(selectedTab === 'customer-report' && <CustomerReportTab />) || ''}
					{(selectedTab === 'supplier-report' && <SupplierReportTab />) || ''}
					{(selectedTab === 'sale-cost-report' && <SaleCostReportTab />) || ''}
					{(selectedTab === 'advanced-report' && <AdvancedReportsTab />) || ''}
					{(selectedTab === 'cdr-report' && <CDRDownloadTab />) || ''}
				</div>
			</div>
		</AuthManager>
	);
});
