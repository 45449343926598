import React, { memo, useCallback, useEffect, useState } from 'react';
import { CardContent, Paper, Typography, IconButton, Accordion, AccordionSummary, AccordionDetails, Grid } from '@material-ui/core';
import { RenderButton, Loader, AuthManager } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { ExpandMore, Delete } from '@material-ui/icons';
import { CreateNewBankAccount, UpdateBankAccount, ConfirmDeleteBankAccount } from './components';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

export default memo(() => {
	const dispatch = useDispatch();

	const loading = useSelector(state => state.myaccount && state.myaccount.loadingBankAccounts);
	const bankAccounts = useSelector(state => (state.myaccount && state.myaccount.bankAccounts) || []);
	const { t } = useTranslation('myaccount/general_information');

	const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
	const [expandedAccount, setExpandedAccount] = useState(false);
	const [createModalOpen, setCreateModalOpen] = useState(false);
	const triggerOpenCloseCreateModal = useCallback(() => setCreateModalOpen(state => !state));

	useEffect(() => {
		dispatch({ type: 'myaccount/GET_BANK_ACCOUNTS' });
	}, []);

	const toggleExpandedPanel = useCallback(
		panel => (event, expanded) => {
			setExpandedAccount(expanded ? panel : false);
		},
		[]
	);

	const triggerConfirmDeleteBankAccount = useCallback(bankAccountId => {
		setConfirmationModalOpen(bankAccountId || false);
	}, []);

	return (
		<div className={styles.container}>
			<div>
				<Paper>
					<CardContent>
						<div className="card-header">
							<Typography variant="h1" style={{ marginRight: 16 }}>
								{t('banking')}
							</Typography>
							<AuthManager permissions={['myaccount.general.create-banking']}>
								<div className="actions">
									<RenderButton color="primary" onClick={triggerOpenCloseCreateModal}>
										{t('new_bank')}
									</RenderButton>
								</div>
							</AuthManager>
						</div>
					</CardContent>
				</Paper>

				<AuthManager permissions={['myaccount.general.view-banking']}>
					<div className="expansion-panels-wrap">
						{(loading && <Loader loading overlay />) || ''}
						{(bankAccounts &&
							Array.isArray(bankAccounts) &&
							bankAccounts.map((item, i) => (
								<div key={i}>
									<Accordion expanded={expandedAccount === item._id} onChange={toggleExpandedPanel(item._id)}>
										<AccordionSummary expandIcon={<ExpandMore />} className="expansion-panel-summary">
											<span style={{ flexGrow: 1 }}>
												{i + 1} - {item.bankNickname ? item.bankNickname : item.bankName}
											</span>

											<AuthManager permissions={['myaccount.general.delete-banking']}>
												<IconButton
													onClick={e => {
														e.stopPropagation();
														triggerConfirmDeleteBankAccount(item._id);
													}}
													className="delete-bank-account"
												>
													<Delete />
												</IconButton>
											</AuthManager>
										</AccordionSummary>

										<AuthManager permissions={['myaccount.general.update-banking']}>
											<AccordionDetails>
												<UpdateBankAccount initialValues={item} expanded={expandedAccount === item._id} />
											</AccordionDetails>
										</AuthManager>
									</Accordion>
								</div>
							))) ||
							''}
					</div>
				</AuthManager>
			</div>

			<CreateNewBankAccount open={createModalOpen} openCloseHandler={triggerOpenCloseCreateModal} />
			<ConfirmDeleteBankAccount open={confirmationModalOpen} openCloseHandler={() => triggerConfirmDeleteBankAccount(false)} bankAccountId={confirmationModalOpen} />
		</div>
	);
});
