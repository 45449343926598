import React, { useEffect, useState } from 'react';
import { Paper, CardContent, CardActions, TextField, Select, MenuItem, InputLabel, Input, Grid } from '@material-ui/core';
import RenderButton from 'components/atoms/RenderButton';
import { Collapse } from '@material-ui/core';
import { FilePond, registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export default function TicketCard({ handleSubmitTicket, loading, showNewTicketCard }) {
	const [description, setDescription] = useState('');
	const [category, setCategory] = useState('accounting');
	const [files, setFiles] = useState([]);

	return (
		<Collapse in={showNewTicketCard}>
			<Paper style={{ marginTop: 8, marginButton: 8, transition: 'all 0.3s ease' }}>
				<CardContent>
					<InputLabel id="category-label">Category</InputLabel>
					<Select labelId="category-label" value={category} label="Category" onChange={e => setCategory(e.target.value)}>
						<MenuItem value={'Accounting'}>Accounting</MenuItem>
						<MenuItem value={'Contruction'}>Construction</MenuItem>
						<MenuItem value={'Telco voice'}>Telecomunications (Voice)</MenuItem>
						<MenuItem value={'Telco SMS'}>Telecomunications (SMS)</MenuItem>
						<MenuItem value={'ACP'}>ACP</MenuItem>
						<MenuItem value={'Other'}>Other</MenuItem>
					</Select>

					<TextField
						value={description}
						onChange={e => setDescription(e.target.value)}
						id="outlined-multiline-static"
						style={{ width: '100%', marginTop: 20, marginBottom: 20 }}
						label="Describe your problem"
						multiline
						minRows={4}
					/>

					<FilePond
						files={files}
						onupdatefiles={setFiles}
						allowMultiple={true}
						maxFiles={10}
						onChange={e => console.log('file changed =>', e.target)}
						name="files"
						labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
					/>
				</CardContent>
				<CardActions style={{ display: 'flex', justifyContent: 'center' }}>
					<RenderButton color="primary" onClick={() => handleSubmitTicket({ description, category, files })}>
						Submit
					</RenderButton>
				</CardActions>
			</Paper>
		</Collapse>
	);
}
