import React, { useState, useEffect, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { triggerNotification } from 'store/notifications/actions';
import { fileToBase64 } from 'utils';

const DEFAULT_TICKET_QTY = 6;

export default function useSectionInfo() {
  const dispatch = useDispatch();
  const { TICKETS } = useSelector(state => state.support);
  const [showNewTicketCard, setShowNewTicketCard] = useState(false);
  const [ticketQty, setTicketQty] = useState(DEFAULT_TICKET_QTY);

  const { email } = useSelector(state => state.useraccount.USER_INFO_DATA.userInfo);

  const toggleNewTicketCard = useCallback(() => {
    setShowNewTicketCard(prev => {
      return !prev;
    });
  }, []);

  const handleSubmitTicket = useCallback(async ({ description, category, files }) => {
    const processedFiles = files ? await Promise.all(files.flatMap(async fileObj => fileToBase64(fileObj.file))) : null;

    //validating file size
    const totalSizeInBytes = processedFiles.reduce((acc, file) => acc + file.length, 0);
    const totalSizeInMB = totalSizeInBytes / (1024 * 1024);

    if (totalSizeInMB > 10) {
      dispatch(triggerNotification('You can upload files up until 10mb', 'warning'));
      return;
    }

    const payload = { description, category, files: processedFiles };

    dispatch({ type: 'support/CREATE_TICKET', payload });
    toggleNewTicketCard();
  }, []);

  const loadNewTickets = useCallback(() => {
    setTicketQty(prev => prev + DEFAULT_TICKET_QTY);
  }, []);

  useEffect(() => {
    dispatch({ type: 'support/GET_TICKETS', payload: { email, from: 0, limit: ticketQty } });
  }, [ticketQty]);

  return { TICKETS, email, handleSubmitTicket, loadNewTickets, toggleNewTicketCard, showNewTicketCard };
}
