import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import radioStyle from "./style";

const RenderRadioGroup = ({ ...props }) => {
  const {
    input,
    classes,
    inline,
    callback,
    customError,
    meta: { touched, error },
    ...rest
  } = props;

  const handleChange = event => {
    input.onChange(event.target.value);

    if (callback) {
      callback(event);
    }
  };

  const hasError =
    (typeof error !== typeof undefined && touched) ||
    (customError && customError !== false);

  return (
    <FormControl error={hasError} fullWidth>
      <RadioGroup
        {...input}
        {...rest}
        value={input.value}
        onChange={handleChange}
        className={inline && classes.inline}
        error={error}
      />
      {hasError ? <FormHelperText error>{error}</FormHelperText> : ""}
    </FormControl>
  );
};

RenderRadioGroup.defaultProps = {
  inline: true
};

RenderRadioGroup.propTypes = {
  classes: PropTypes.shape().isRequired,
  inline: PropTypes.bool
};

export default withStyles(radioStyle)(RenderRadioGroup);
