import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import buttonStyle from './style';

const RenderButton = ({ ...props }) => {
	const { classes, color, round, children, disabled, simple, block, link, justIcon, className, noWrap, underlined, small, ...rest } = props;
	const btnClasses = classNames({
		[classes[color]]: color,
		[classes.round]: round,
		[classes.disabled]: disabled,
		[classes.simple]: simple,
		[classes.block]: block,
		[classes.link]: link,
		[classes.justIcon]: justIcon,
		[classes.noWrap]: noWrap,
		[classes.underlined]: underlined,
		[classes.small]: small,
		[className]: className
	});
	return (
		<Button {...rest} size={small && 'small' || undefined} className={btnClasses} disableRipple disableTouchRipple disabled={disabled}>
			{children}
		</Button>
	);
};

RenderButton.defaultProps = {
	color: 'transparent',
	simple: false,
	round: false,
	fullWidth: false,
	disabled: false,
	block: false,
	link: false,
	justIcon: false,
	noWrap: false,
	underlined: false,
	small: false
};

RenderButton.propTypes = {
	classes: PropTypes.shape().isRequired,
	color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'danger', 'transparent']),
	simple: PropTypes.bool,
	round: PropTypes.bool,
	fullWidth: PropTypes.bool,
	disabled: PropTypes.bool,
	block: PropTypes.bool,
	link: PropTypes.bool,
	justIcon: PropTypes.bool,
	noWrap: PropTypes.bool,
	underlined: PropTypes.bool,
	small: PropTypes.bool
};

export default withStyles(buttonStyle)(RenderButton);
