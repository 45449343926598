import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { CardContent, Grid, Paper, Typography, Chip, TextField, MenuItem } from '@material-ui/core';
import { FileDownload } from '@material-ui/icons';
import _, { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import * as Yup from 'yup';

import { RenderModal, RenderFormikRadioGroup, RenderRadio, RenderFormikSelect, RenderTable } from 'components';
import RenderButton from 'components/atoms/RenderButton';
import RenderFormikDropzoneArea from 'components/atoms/RenderFormikDropzoneArea';
import Loader from 'components/atoms/Loader';
import CurrencyNumber from 'components/atoms/CurrencyNumber';
import RenderAcpType from 'components/atoms/AcpType';

import actions from 'store/acp/actions';
import styles from './styles.module.scss';

export const ProviderSubmissionsTab = memo(props => {
	const dispatch = useDispatch();

	const [uploading, setUploading] = useState(false);
	const [uploadModalOpen, setUploadModalOpen] = useState(false);

	const loading = useSelector(state => (state.acp && state.acp.loading) || false);
	const loadingFile = useSelector(state => (state.acp && state.acp.loadingFile) || false);
	const tableData = useSelector(state => (state.acp && state.acp.acpClaims) || []);

	useEffect(() => {
		if (!loading && !loadingFile && uploading) {
			dispatch({ type: actions.GET_ACP_CUSTOMER_CLAIM });
			setUploading(false);
		}
	}, [loadingFile, loading, uploading]);

	useEffect(() => {
		dispatch({
			type: 'acp/SET_STATE',
			payload: {
				acpClaims: []
			}
		});
		dispatch({ type: actions.GET_ACP_CUSTOMER_CLAIM });
	}, []);

	const triggerDownloadAcpClaim = (e, _id, claimNumber) => {
		e.preventDefault();
		e.stopPropagation();
		dispatch({
			type: actions.DOWNLOAD_ACP_CLAIM,
			payload: {
				acpClaimId: _id,
				claimNumber
			}
		});
	};

	const handleUploadSubmitButton = async e => {
		e.preventDefault();
		e.stopPropagation();
		await formik.submitForm();
	};

	const handleDownloadClaimableDeviceTemplate = () => {
		dispatch({
			type: actions.DOWNLOAD_CLAIMABLE_DEVICE_TEMPLATE
		});
	};

	const handleDownloadMRCSalesTemplate = () => {
		dispatch({
			type: actions.DOWNLOAD_MRC_SALES_TEMPLATE
		});
	};

	const uploadClaim = useCallback(claim => {
		dispatch({
			type: actions.UPLOAD_NEW_CLAIM,
			payload: {
				...claim
			}
		});
	}, []);

	const formik = useFormik({
		initialValues: {
			claimFile: null,
			claimPeriod: null,
			claimType: null
		},
		onSubmit: (values, { resetForm }) => {
			uploadClaim(values);
			setUploading(true);
			setUploadModalOpen(false);
			resetForm();
		},
		validationSchema: Yup.object().shape({
			claimFile: Yup.mixed().required('* Claim file is required'),
			claimPeriod: Yup.date()
				.nullable()
				.required('* Claim period is required'),
			claimType: Yup.string()
				.nullable()
				.required('* Claim type is required')
		})
	});

	const claimTypeOptions = [
		{
			label: 'Sales',
			value: 'sales'
		},
		{
			label: 'MRC',
			value: 'mrc'
		},
		{
			label: 'Claimable Devices',
			value: 'claimable-devices'
		}
	];

	const DateFilter = ({ filter, onChange, type = 'date' }) => (
		<TextField
			type={type}
			value={filter ? filter.value : ''}
			onChange={e => onChange(e.target.value)}
			style={{ width: '80%' }}
			variant="standard"
			InputLabelProps={{
				shrink: true
			}}
		/>
	);

	const columns = [
		{
			Header: 'Upload Date',
			accessor: 'createdAt',
			Cell: props => <span>{moment(props.value).format('MMM Do YYYY')}</span>,
			width: 175,
			Filter: ({ filter, onChange }) => <DateFilter filter={filter} onChange={onChange} type="date" />
		},
		{
			Header: 'Type',
			accessor: 'type',
			Cell: props => <RenderAcpType size="small" claimType={props.value} />,
			width: 200,
			Filter: ({ filter, onChange }) => (
				<TextField value={filter ? filter.value : ''} style={{ width: '80%' }} select onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard">
					<MenuItem value="">All</MenuItem>
					<MenuItem value="sales">Sales</MenuItem>
					<MenuItem value="mrc">MRC</MenuItem>
					<MenuItem value="claimable-devices">Claimable Devices</MenuItem>
				</TextField>
			)
		},
		{
			Header: 'Claim Number',
			accessor: 'claimNumber',
			Cell: props => <span>{props.value}</span>,
			width: 200,
			Filter: ({ filter, onChange }) => <TextField value={filter ? filter.value : ''} onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard" />
		},
		{
			Header: 'Claim Period',
			accessor: 'claimPeriod',
			Cell: props => <span>{moment(props.value).format('MMMM YYYY')}</span>,
			width: 200,
			Filter: ({ filter, onChange }) => <DateFilter filter={filter} onChange={onChange} type="month" />
		},
		{
			Header: 'File name',
			accessor: 'fileName',
			Filter: ({ filter, onChange }) => <TextField value={filter ? filter.value : ''} onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard" />
		},
		{
			Header: 'Total',
			accessor: 'total',
			Filter: ({ filter, onChange }) => <TextField value={filter ? filter.value : ''} onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard" />,
			Cell: props => (
				<div className="number-column">
					<CurrencyNumber amount={props.value} precision={0} />
				</div>
			),
			width: 125
		},
		{
			Header: 'Actions',
			filterable: false,
			Cell: ({ original: { _id, claimNumber } }) => {
				return (
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-start'
						}}
					>
						<RenderButton
							onClick={e => triggerDownloadAcpClaim(e, _id, claimNumber)}
							color="primary"
							small
							style={{
								marginRight: '10px'
							}}
						>
							Download
						</RenderButton>
					</div>
				);
			},
			width: 150
		}
	];

	return (
		<div className={styles.container}>
			<form onSubmit={formik.handleSubmit}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={12}>
						<Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
							<Grid item>
								<Grid container xs={12} direction="row">
									<Grid item>
										<Chip
											color="primary"
											label="Devices Template"
											onClick={handleDownloadClaimableDeviceTemplate}
											disabled={loading}
											avatar={
												<div style={{ backgroundColor: 'white', borderRadius: '50%' }}>
													<FileDownload color="primary" />
												</div>
											}
										/>
									</Grid>
									<Grid item>
										<Chip
											color="primary"
											label="MRC/Sales Template"
											disabled={loading}
											onClick={handleDownloadMRCSalesTemplate}
											avatar={
												<div style={{ backgroundColor: 'white', borderRadius: '50%' }}>
													<FileDownload color="primary" />
												</div>
											}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid>
								<RenderButton
									color="primary"
									type="button"
									disabled={loading}
									onClick={() => {
										setUploadModalOpen(true);
									}}
								>
									Upload Claim
								</RenderButton>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12} md={12}>
						<Paper>
							<CardContent>
								{(loading && <Loader loading />) || (
									<div className="claim-data">
										<RenderTable data={tableData || []} columns={columns} filterable={true} sortable={true} minRows={2} />
									</div>
								)}
							</CardContent>
						</Paper>
					</Grid>
				</Grid>
			</form>
			<RenderModal
				open={uploadModalOpen}
				onModalClose={() => setUploadModalOpen(false)}
				className={styles.container}
				actions={
					<RenderButton onClick={handleUploadSubmitButton} color="secondary" type="button" className="submit-button">
						Submit Claim
					</RenderButton>
				}
			>
				<Typography align="center" gutterBottom variant="h1">
					Upload Claim
				</Typography>
				<RenderFormikDropzoneArea
					filesLimit={1}
					multiple={false}
					acceptFiles={['text/csv', 'application/vnd.ms-excel']}
					name="claimFile"
					formik={formik}
					dropzoneText={
						<React.Fragment>
							<p>Upload area for claims</p>
						</React.Fragment>
					}
				/>

				<br />
				<br />
				<Typography variant="caption">Claim period:</Typography>
				<RenderFormikRadioGroup formik={formik} inline name="claimPeriod">
					<RenderRadio
						label={`Previous month (${moment()
							.subtract(1, 'months')
							.format('MMM YYYY')})`}
						value={moment()
							.subtract(1, 'month')
							.startOf('month')
							.add(1, 'day')
							.format()}
					/>
					<RenderRadio
						label={`Current month (${moment().format('MMM YYYY')})`}
						value={moment()
							.startOf('month')
							.add(1, 'day')
							.format()}
					/>
				</RenderFormikRadioGroup>

				<Grid item xs={6}>
					<RenderFormikSelect formik={formik} name="claimType" label="Claim type" options={claimTypeOptions} />
				</Grid>
			</RenderModal>
		</div>
	);
});
