export const START_BLOCKS_REQUEST = "START_BLOCKS_REQUEST";
export const SUCCESS_BLOCKS_REQUEST = "SUCCESS_BLOCKS_REQUEST";
export const FAIL_BLOCKS_REQUEST = "FAIL_BLOCKS_REQUEST";
export const EXPLORER_SMART_CONTRACTS_ACTION_GET_DATA_REQUEST =
  "EXPLORER_SMART_CONTRACTS_ACTION_GET_DATA_REQUEST";
export const EXPLORER_SMART_CONTRACTS_ACTION_GET_DATA_RESPONSE =
  "EXPLORER_SMART_CONTRACTS_ACTION_GET_DATA_RESPONSE";
export const EXPLORER_WALLET_ACTION_GET_DATA_REQUEST =
  "EXPLORER_WALLET_ACTION_GET_DATA_REQUEST";
export const EXPLORER_WALLET_ACTION_GET_DATA_RESPONSE =
  "EXPLORER_WALLET_ACTION_GET_DATA_RESPONSE";
export const EXPLORER_INIT_BLOCKS_POLLING = "EXPLORER_INIT_BLOCKS_POLLING";
export const STATUS_EXPLORER_INIT_BLOCKS_POLLING =
    "STATUS_EXPLORER_INIT_BLOCKS_POLLING";

export const actionSmartContractExplorerGetDataRequest = data => ({
  type: EXPLORER_SMART_CONTRACTS_ACTION_GET_DATA_REQUEST,
  payload: data
});

export const actionSmartContractExplorerGetDataResponse = data => ({
  type: EXPLORER_SMART_CONTRACTS_ACTION_GET_DATA_RESPONSE,
  payload: data
});

export const initBlocksPolling = (data) => ({
  type: EXPLORER_INIT_BLOCKS_POLLING,
  payload: data
});

export const statusInitBlocksPolling = (data) => ({
    type: STATUS_EXPLORER_INIT_BLOCKS_POLLING,
    payload: data
});

export const successBlocksRequest = data => ({
  type: SUCCESS_BLOCKS_REQUEST,
  payload: data
});

export const actionWalletExplorerGetDataRequest = data => ({
  type: EXPLORER_WALLET_ACTION_GET_DATA_REQUEST,
  payload: data
});

export const actionWalletExplorerGetDataResponse = data => ({
  type: EXPLORER_WALLET_ACTION_GET_DATA_RESPONSE,
  payload: data
});
