import { API } from 'aws-amplify';

const FinancialApi = {
	initializeFinancial() {
		const myInit = {
			// OPTIONAL
			header: {},
			body: {} // replace this with attributes you need
		};
		return API.post('FINANCIAL_API', '/index', myInit)
			.then(response => response)
			.catch(err => err);
	},

	loadSettlements() {
		return API.post('NODE_SERVICE_API', '/wallet/settlements')
			.then(response => response)
			.catch(err => err);
	},

	getLenderInvoicesApproval() {
		return API.get('NODE_SERVICE_API', '/funding-requests/lender-invoices-approval', {})
			.then(response => response)
			.catch(err => err);
	},

	setLenderInvoicesApproval(data) {
		return API.post('NODE_SERVICE_API', '/funding-requests/lender-invoices-approval', {
			body: data
		})
			.then(response => response)
			.catch(err => err);
	}
};

export default FinancialApi;
