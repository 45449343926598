import React from 'react';
import MUIDataTable from 'mui-datatables';
import { RenderButton } from 'components';
import { AuthManager } from "../../index";

const tableOptions = {
	selectableRows: false,
	filter: false,
	print: false,
	download: false,
	viewColumns: false,
	responsive: 'scroll'
};

class RoutesList extends React.Component {
	parseDestinations(dests) {
		const dest = [];
		dests.map(obj => {
			dest.push(obj.description);
		});
		return dest.join(', ');
	}

	parseRouteType(type) {
		if (type === 'S') return 'Static';
		if (type === 'P') return 'Percentage';
		return 'LCR';
	}

	parseMatch(obj) {
		return obj.matchType === 'D' ? 'Digit' : 'Name';
	}

	parseMatchType(obj) {
		if (obj.matchType === 'D') {
			return `${obj.digitMatch}`;
		}
		let match = '';
		if (obj.countryMatch && obj.countryMatch.length) {
			match += `${obj.countryMatch}`;
		}
		if (obj.countryMatchType && obj.countryMatchType.length) {
			match += ` (${obj.countryMatchType}}`;
		}

		return match;
	}

	parseTableData(data) {
		return data.map((obj, i) => [
			this.parseMatch(obj),
			this.parseMatchType(obj),
			obj.description || '',
			this.parseRouteType(obj.routeType),
			this.parseDestinations(obj.destinations),
			<div>
				<AuthManager permissions={['routing.update-routes']}>
					<RenderButton size="small" color="primary" justIcon onClick={() => this.props.handleUpdateClick(obj._id)}>
						update
					</RenderButton>
				</AuthManager>
				<AuthManager permissions={['routing.delete-routes']}>
					<RenderButton onClick={() => this.props.handleDeleteRouteSubmit(obj._id)} size="small" color="danger" justIcon>
						remove
					</RenderButton>
				</AuthManager>
			</div>
		]);
	}

	render() {
		const {
			routesData: { routes }
		} = this.props;
		return (
			<div>
				<MUIDataTable
					title="Active Routes"
					data={this.parseTableData(routes)}
					columns={[
						{
							name: 'Match',
							options: {
								filter: true,
								sort: true
							}
						},
						{
							name: 'Match Type',
							options: {
								filter: true,
								sort: true
							}
						},
						{
							name: 'Description',
							options: {
								filter: true,
								sort: true
							}
						},
						{
							name: 'Route Type',
							options: {
								filter: true,
								sort: true
							}
						},
						{
							name: 'Route',
							options: {
								filter: true,
								sort: true
							}
						},
						{
							name: 'Action',
							options: {
								filter: false,
								sort: false
							}
						}
					]}
					options={tableOptions}
				/>
			</div>
		);
	}
}

RoutesList.defaultProps = {
	routesData: {
		routes: []
	}
};

export default RoutesList;
