import React from "react";
import { PrivateTemplate, BreadCrumb } from "components";
import { WelcomeContainer } from "containers";

const WelcomePage = () => (
  <PrivateTemplate>
    <BreadCrumb
      name="Dashboard"
      firstLevel="Dashboard"
      secondLevel="Dashboard"
    />
    <WelcomeContainer />
  </PrivateTemplate>
);

export default WelcomePage;
