import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Slide,
  Typography
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
import { Field, reduxForm } from "redux-form";
import {
  RenderInput,
  RenderButton,
  RenderRadioGroup,
  RenderRadio
} from "components";
import {
  createValidator,
  required,
  validateDepentValue
} from "services/validation";
import styles from "./style";

const Transition = props => <Slide direction="up" {...props} />;

class SmartContractManageFundingOfferModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offerStatus: null
    };
    this.handleOfferStatusChange = this.handleOfferStatusChange.bind(this);
  }

  contractIdFormatter = contractId => {
    const padded = `${contractId}000000`.slice(0, -contractId.length);
    return parseInt(padded)
      .toString(16)
      .toUpperCase();
  };

  handleOfferStatusChange(event) {
    this.setState({ offerStatus: event.target.value });
  }

  render = () => {
    const {
      classes,
      dialogOpen,
      dialogOpenCloseHandler,
      handleSubmit,
      contractData: {
        contractTerms: {
          contractId,
          supplier,
          customer,
          billingCycle,
          paymentTerms,
          settlementPeriod,
          billingTimezone,
          contractEndDate
        },
        lender,
        updatedAt,
        messages,
        lenderStatus,
        settlementAmount,
        interestRate
      }
    } = this.props;
    const { offerStatus } = this.state;
    const lenderMessages = messages
      ? messages.filter(obj => obj.from === "lender")
      : "";
    const declineReason =
      lenderMessages && lenderMessages.length > 0
        ? lenderMessages[lenderMessages.length - 1].message
        : "";
    return (
      <Dialog
        open={dialogOpen}
        onBackdropClick={dialogOpenCloseHandler}
        TransitionComponent={Transition}
        maxWidth="md"
      >
        <form onSubmit={handleSubmit} autoComplete="off">
          <DialogTitle>Manage Funding Offer</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={3}>
                <Typography className={classes.label}>
                  Smart Contract ID
                </Typography>
                <Typography>{this.contractIdFormatter(contractId)}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.label}>Customer</Typography>
                <Typography>{customer}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.label}>Supplier</Typography>
                <Typography>{supplier}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.label}>
                  Contract end date
                </Typography>
                <Typography>
                  {contractEndDate === undefined ||
                  contractEndDate === 0 ||
                  contractEndDate === null
                    ? "Open"
                    : moment(contractEndDate).format("DD MMM YYYY")}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.label}>Billing Cycle</Typography>
                <Typography>{billingCycle}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.label}>Payment Terms</Typography>
                <Typography>
                  {parseInt(paymentTerms) > 1
                    ? `${paymentTerms} Days`
                    : `${paymentTerms} Day`}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.label}>
                  Settlement Period
                </Typography>
                <Typography>{settlementPeriod}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.label}>
                  Billing Timezone
                </Typography>
                <Typography>{billingTimezone}</Typography>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid container>
              <Grid item xs={12}>
                {interestRate && settlementAmount ? (
                  <Typography className={classes.lendingMessage}>
                    <span>{lender}</span> offered funding for contract{" "}
                    <span>{this.contractIdFormatter(contractId)}</span> of{" "}
                    <span>${settlementAmount}</span> at{" "}
                    <span>{interestRate}%</span> interest.
                  </Typography>
                ) : (
                  ""
                )}
                {lenderStatus !== "declined" ? (
                  <Field
                    name="supplierStatus"
                    component={RenderRadioGroup}
                    callback={this.handleOfferStatusChange}
                    inline
                  >
                    <RenderRadio value="accepted" label="Accept Offer" />
                    <RenderRadio value="declined" label="Decline Offer" />
                  </Field>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12}>
                {offerStatus === "declined" ? (
                  <Field
                    component={RenderInput}
                    name="declineReason"
                    label="Message for Lender"
                  />
                ) : (
                  ""
                )}

                {lenderStatus === "declined" ? (
                  <Typography>
                    Offer declined on {moment(updatedAt).format("DD MMM YYYY")}{" "}
                    by the lender with the following message: <br />
                    {declineReason}
                  </Typography>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {lenderStatus !== "declined" ? (
              <RenderButton color="primary" type="Submit">
                Submit
              </RenderButton>
            ) : (
              ""
            )}
            <RenderButton color="danger" onClick={dialogOpenCloseHandler}>
              Cancel
            </RenderButton>
          </DialogActions>
        </form>
      </Dialog>
    );
  };
}

SmartContractManageFundingOfferModal.defaultProps = {
  contractData: {
    contractTerms: {
      contractId: "",
      supplier: "",
      customer: "",
      paymentTerms: "",
      settlementPeriod: "",
      billingTimezone: "",
      contractEndDate: ""
    }
  }
};

const validate = createValidator({
  declineReason: [validateDepentValue("supplierStatus", "declined", required)]
});

SmartContractManageFundingOfferModal = withStyles(styles)(
  SmartContractManageFundingOfferModal
);

export default reduxForm({
  form: "SmartContractManageFundingOfferModalForm",
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate
})(SmartContractManageFundingOfferModal);
