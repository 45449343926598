import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";

const RenderMaterialCheckboxGroup = ({ input, label, options, meta: { touched, error } }) => {

  const hasError = typeof error !== typeof undefined && error.length > 0 && touched;
  const checkboxGroup = options.map((option, index) => (
    <FormControlLabel
      control={
        <Checkbox
          // checked={input.state.checked}
          // onChange={event => { return !event.target.state.checked; }}
          color="primary"
          {...input}
        />
      }
      label={option.codexName}
      key={index}
    />
  ));
  return (
    <FormGroup error={hasError} fullWidth row>
      {checkboxGroup}
      {hasError && <FormHelperText>{error}</FormHelperText>}
    </FormGroup>
  );
};

export default RenderMaterialCheckboxGroup;
