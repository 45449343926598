import { PRELOADER, PRELOADER_ON, PRELOADER_OFF } from "./actions";

export default function(state = {}, action) {
  switch (action.type) {
    case PRELOADER_ON:
      return {
        ...state,
        [PRELOADER]: {
          status: "show"
        }
      };
    case PRELOADER_OFF:
      return {
        ...state,
        [PRELOADER]: {
          status: "none"
        }
      };
    default:
      return state;
  }
}
