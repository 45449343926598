import React from "react";
import { Field, reduxForm } from "redux-form";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createValidator,
  required,
  validateMaxValueParam
} from "services/validation";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Slide,
  Typography,
  FormHelperText
} from "@material-ui/core";
import { RenderButton, RenderInput, CurrencyNumber} from "components";
import moment from "moment";
import styles from "./style";

const Transition = props => <Slide direction="up" {...props} />;

class SmartContractAllocateFundingModal extends React.Component {
  contractIdFormatter = contractId => {
    const padded = `${contractId}000000`.slice(0, -contractId.length);
    return parseInt(padded)
      .toString(16)
      .toUpperCase();
  };

  render() {
    const {
      open,
      classes,
      openCloseHandler,
      handleSubmit,
      availableWalletAmount,
      contractData: {
        contractTerms: {
          contractId,
          supplier,
          customer,
          billingCycle,
          paymentTerms,
          settlementPeriod,
          billingTimezone,
          contractEndDate
        },
        name
      }    
    } = this.props;

    return (
      <Dialog
        open={open}
        onBackdropClick={openCloseHandler}
        TransitionComponent={Transition}
        maxWidth="sm"
      >
        <DialogTitle>Allocate funds</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container>
              <Grid item xs={3}>
                <Typography className={classes.label}>
                  Smart Contract ID
                </Typography>
                <Typography>{name? `${name} (${this.contractIdFormatter(contractId)})`: this.contractIdFormatter(contractId)}</Typography>
                
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.label}>Customer</Typography>
                <Typography>{customer}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.label}>Supplier</Typography>
                <Typography>{supplier}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.label}>
                  Contract end date
                </Typography>
                <Typography>
                  {contractEndDate === undefined ||
                  contractEndDate === 0 ||
                  contractEndDate === null
                    ? "Open"
                    : moment(contractEndDate).format("DD MMM YYYY")}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.label}>Billing Cycle</Typography>
                <Typography>{billingCycle}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.label}>Payment Terms</Typography>
                <Typography>
                  {parseInt(paymentTerms) > 1
                    ? `${paymentTerms} Days`
                    : `${paymentTerms} Day`}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.label}>
                  Settlement Period
                </Typography>
                <Typography>{settlementPeriod}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.label}>
                  Billing Timezone
                </Typography>
                <Typography>{billingTimezone}</Typography>
              </Grid>
            </Grid>

            <Divider className={classes.divider} />

            <Grid container>
              {parseInt(availableWalletAmount) > 0 ? (
                <Grid item xs={12}>
                  <Field
                    component={RenderInput}
                    name="amount"
                    max={availableWalletAmount}
                    type="number"
                    label="Amount"
                  />
                  <FormHelperText>
                    Wallet Balance:{" "}
                    <CurrencyNumber amount={availableWalletAmount.toFixed(2)} />
                  </FormHelperText>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Typography align="center">
                    You don't have enough funds in your wallet. Please Top up !
                  </Typography>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            {parseInt(availableWalletAmount) > 0 ? (
              <RenderButton color="primary" type="submit">
                Allocate
              </RenderButton>
            ) : (
              ""
            )}
            <RenderButton color="danger" onClick={openCloseHandler}>
              Cancel
            </RenderButton>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

SmartContractAllocateFundingModal.defaultProps = {
  contractData: {
    contractTerms: {
      contractId: "",
      supplier: "",
      customer: "",
      paymentTerms: "",
      settlementPeriod: "",
      billingTimezone: "",
      contractEndDate: ""
    }
  }
};

const validate = createValidator({
  amount: [required, validateMaxValueParam]
});

SmartContractAllocateFundingModal = withStyles(styles)(
  SmartContractAllocateFundingModal
);

export default reduxForm({
  form: "SmartContractRequestFundingForm",
  enableReinitialize: true,
  touchOnBlur: true,
  validate
})(SmartContractAllocateFundingModal);
