const typography = {
	weight: {
		light: '300',
		regular: '400',
		semiBold: '500',
		bold: '500',
		extraBold: '500'
	}
};

export default typography;
