import React from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import { FormHelperText, FormControl, MenuItem, Chip, Typography, TextField, Paper } from '@material-ui/core';
import { Cancel, KeyboardArrowDown } from '@material-ui/icons';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
	input: {
		display: 'flex',
		// padding: '2px 0 3px'
	},
	valueContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		alignItems: 'center',
		overflow: 'hidden'
	},
	chip: {
		margin: `0 ${theme.spacing(1) / 4}px`,
		height: 'auto'
	},
	chipFocused: {
		backgroundColor: emphasize(theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700], 0.08)
	},
	noOptionsMessage: {
		padding: `${theme.spacing(1)}px ${theme.spacing(1) * 2}px`
	},
	singleValue: {
		fontSize: 16
		// whiteSpace: "nowrap",
		// textOverflow: "ellipsis",
		// overflow: "hidden",
		// maxWidth: "95%"
	},
	placeholder: {
		position: 'absolute',
		left: 2,
		fontSize: 16
	},
	paper: {
		position: 'absolute',
		zIndex: 999,
		marginTop: theme.spacing(1),
		left: 0,
		right: 0
	},
	divider: {
		height: theme.spacing(1) * 2
	}
});

const NoOptionsMessage = ({ selectProps, innerProps, children }) => (
	<Typography color="textSecondary" className={selectProps.classes.noOptionsMessage} {...innerProps}>
		{children}
	</Typography>
);

const inputComponent = ({ inputRef, ...props }) => <div ref={inputRef} {...props} />;

const Control = ({ selectProps, innerRef, children, innerProps, isDisabled, isFocused }) => (
	<TextField
		fullWidth
		disabled={isDisabled}
		InputProps={{
			inputComponent,
			inputProps: {
				className: selectProps.classes.input,
				ref: innerRef,
				children,
				...innerProps
			}
		}}
		{...selectProps.textFieldProps}
		InputLabelProps={{
			shrink: isFocused || (selectProps.value && selectProps.value !== '' && selectProps.value.length > 0)
		}}
	/>
);

const Option = ({ innerRef, isFocused, innerProps, children, isDisabled }) => (
	<MenuItem
		buttonRef={innerRef}
		selected={isFocused}
		disabled={isDisabled}
		component="div"
		style={{
			fontWeight: 400
		}}
		{...innerProps}
	>
		{children}
	</MenuItem>
);

const Placeholder = ({ selectProps, innerProps, children }) => (
	<Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
		{children}
	</Typography>
);

const SingleValue = ({ selectProps, innerProps, children }) => (
	<span className={selectProps.classes.singleValue} {...innerProps}>
		{children}
	</span>
);

const ValueContainer = ({ selectProps, children }) => <div className={selectProps.classes.valueContainer}>{children}</div>;

const MultiValue = ({ children, selectProps, removeProps }) => <span className={classNames(selectProps.classes.chip)}>{children};</span>;

const Menu = ({ selectProps, innerProps, children }) => (
	<Paper className={selectProps.classes.paper} {...innerProps}>
		{children}
	</Paper>
);

const DropdownIndicator = () => <KeyboardArrowDown color="action" />;

const IndicatorSeparator = () => null;

const components = {
	Control,
	Menu,
	NoOptionsMessage,
	Option,
	Placeholder,
	SingleValue,
	MultiValue,
	ValueContainer,
	DropdownIndicator,
	IndicatorSeparator
};

class RenderSelect extends React.Component {
	constructor(props) {
		super(props);
		const { multiple, input } = this.props;
	}

	handleChange = event => {
		const { callback, input } = this.props;

		if (!Array.isArray(event)) {
			input.onChange(event.value);
		} else {
			input.onChange(event);
		}

		if (callback) {
			callback(event);
		}
	};

	render() {
		const {
			input: { value, onBlur },
			label,
			multiple,
			fullWidth,
			meta: { touched, error },
			margin,
			disabled,
			classes,
			theme,
			options
		} = this.props;

		const selectStyles = {
			input: base => ({
				...base,
				color: theme.palette.text.primary,
				'& input': {
					font: 'inherit'
				}
			}),
			singleValue: (provided, state) => {
				const opacity = state.isDisabled ? 0.5 : 1;
				const transition = 'opacity 300ms';

				return { ...provided, opacity, transition };
			}
		};

		const hasError = typeof error !== typeof undefined && error.length > 0 && touched;
		const isMultiple = typeof multiple !== typeof undefined && multiple;
		return (
			<FormControl error={hasError} fullWidth={fullWidth} margin={margin} disabled={disabled}>
				<Select
					classes={classes}
					styles={selectStyles}
					textFieldProps={{
						label
					}}
					options={options}
					components={components}
					value={Array.isArray(value) ? value : options && options.filter(option => option.value === value)}
					onChange={this.handleChange}
					onBlur={() => onBlur(value)}
					placeholder=""
					isMulti={isMultiple}
					isDisabled={disabled}
				/>
				{hasError && <FormHelperText>{error}</FormHelperText>}
			</FormControl>
		);
	}
}

RenderSelect.defaultProps = {
	callback: undefined,
	fullWidth: true,
	margin: 'none',
	disabled: false
};
export default withStyles(styles, { withTheme: true })(RenderSelect);
