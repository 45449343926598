import { InvoiceFooter, InvoiceSummary, MonthlyInvoiceHeader, UsageSummary } from 'components';

import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import monthlyInvoiceStyle from './style';

class MonthlyInvoice extends React.Component {
	render() {
		const { classes, data } = this.props;
		return (
			<div>
				{data ? (
					<Paper className={classes.root} elevation={1}>
						<MonthlyInvoiceHeader data={data} />
						<InvoiceSummary data={data} />
						<Divider className={classes.divider} />
						<InvoiceFooter isStandard />
					</Paper>
				) : (
					'Invoice not found'
				)}
				{data && data.usage ? (
					<Paper className={classes.root} elevation={1}>
						<MonthlyInvoiceHeader data={data} page="2" />
						<UsageSummary
							data={{
								usageCollection: data.usage.collection,
								total: data.usage.total
							}}
						/>
					</Paper>
				) : (
					''
				)}
			</div>
		);
	}
}

MonthlyInvoice.propTypes = {
	classes: PropTypes.shape().isRequired,
	data: PropTypes.shape().isRequired
};

export default withStyles(monthlyInvoiceStyle)(MonthlyInvoice);
