import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RenderCheckbox } from 'components';
import { reduxForm, Field } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Grid, Chip} from '@material-ui/core';
import { RadioButtonUnchecked, RadioButtonChecked, CheckBox} from '@material-ui/icons';




class AdvanRepTableCusFilterForm extends Component {

	
	render() {
		const { handleChange, t } = this.props;
		const onClick = e => {
			e.persist();
			if (e && e.target) {
				handleChange({ [e.target.name]: e.target.checked });
			}
		};
		const colsOptions = [
			{value: 'country', label: t('country')},
			{value: 'destination_type', label: t('destination_type')},
			{value: 'destination_carrier', label: t('destination_carrier')},
			{value: 'minutes', label: t('Minutes')},
			{value: 'attempts', label: t('attempts')},
			{value: 'connected_calls', label: t('connected_calls')},
			{value: 'asr', label: 'ASR'},
			{value: 'acd', label: 'ACD'},
			{value: 'pdd', label: 'PDD'},
			{value: 'rate', label: t('rate')},
			{value: 'bill_inc', label: t('billing_inc')},
			{value: 'amount', label: t('amount')},
		];

		const triggerAddRemoveGrouping = useCallback((value) => {
			let selected = [...(formik.values.groupingBy || [])];
			if (formik && formik.values && formik.values.groupingBy.indexOf(value) > -1 ) {
				selected = selected.filter(s => s !== value);
			} else {
				selected.push(value)
			}
			formik.setFieldValue('groupingBy', selected)
		}, [JSON.stringify(formik.values), checked]);
		
		return (
			<React.Fragment>
				<fieldset>
					<legend>Columns</legend>
					<Grid container spacing={1}>
										{(colsOptions || []).map((column) => {
											return (
					<Chip
						size="small"
						label={column.label}
						onClick={() => triggerAddRemoveGrouping(group.value)}
						// onDelete={() => this.triggerAddRemoveSmartContracts(contract._id)}
						color={'primary'}
						selected={false}
						icon={<CheckBox />
					}
					/>
					);
				})}
				</Grid>
					
				</fieldset>
			</React.Fragment>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({}, dispatch);
}
AdvanRepTableCusFilterForm.propTypes = {
	handleChange: PropTypes.func.isRequired
};

const AdvancedRepTableCusFilterReduxForm = reduxForm({
	form: 'AdvanRepTableCusFilterForm'
})(AdvanRepTableCusFilterForm);

const AdvancedRepTableCusFilterReduxFormTranslated = withTranslation(['reports/reports', 'translations'])(AdvancedRepTableCusFilterReduxForm);

export default connect(null, mapDispatchToProps)(AdvancedRepTableCusFilterReduxFormTranslated);
