import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const scStatuses = {
	'waiting-for-partner-confirmation': 'pending'
};

export const useAccordion = () => {
	const dispatch = useDispatch();

	const [modalOpened, setModalOpened] = useState(false);
	const [selectedSmartContract, setSelectedSmartContract] = useState(null);
	const [selectedPof, setSelectedPof] = useState(null);

	const { escrowmanaging, notifications } = useSelector(state => state || {});
	const { contracts } = escrowmanaging || {};
	const transactionResult = notifications.notifications;

	useEffect(() => {
		if (transactionResult.length > 0 && (transactionResult[0]?.message === 'Contract accepted' || transactionResult[0]?.message === 'Contract rejected')) {
			dispatch({
				type: 'escrowManaging/GET_CONTRACTS'
			});
		}

		if (
			(transactionResult.length > 0 && transactionResult[0]?.message === 'Fulfillment proof uploaded successfully') ||
			transactionResult[0]?.message === 'Proof of fulfillment rejected'
		) {
			setModalOpened(false);
			dispatch({
				type: 'escrowManaging/GET_CONTRACTS'
			});
		}
	}, [transactionResult]);

	const smartContracts = (contracts || []).map(sc => {
		const status = scStatuses[sc.status] || sc.status;
		return { ...sc, status };
	});

	const handleStatusChange = useCallback((_id, status) => {
		dispatch({
			type: 'escrowManaging/UPDATE_CONTRACT_STATUS',
			payload: {
				_id,
				status
			}
		});
	}, []);

	useEffect(() => {
		dispatch({
			type: 'escrowManaging/GET_CONTRACTS'
		});
	}, []);

	useEffect(() => {
		if (!modalOpened) setTimeout(() => setSelectedPof(null), 300);
	}, [modalOpened]);

	useEffect(() => {
		if (selectedPof) setModalOpened(true);
	}, [selectedPof]);

	return { modalOpened, selectedPof, setModalOpened, selectedSmartContract, setSelectedSmartContract, smartContracts, handleStatusChange, setSelectedPof };
};
