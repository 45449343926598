import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormControl,
  FormGroup
} from '@material-ui/core';
import checkboxStyle from "./style";

const FormikCheckbox = ({ field, label, classes, form: { touched, errors }, ...rest }) => {
  const hasError = (touched[field.name] && errors[field.name]) || false;
  return (
    <FormControl error={hasError}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              {...field}
              {...rest}
              checked={field.value || false}
              onChange={field.onChange}
            />
          }
          label={label}
        />
        {hasError && <span className={classes.errorLabel}><FormHelperText>{error}</FormHelperText></span>}
      </FormGroup>
    </FormControl>
  );
};

export default withStyles(checkboxStyle)(FormikCheckbox);

