import React, { Component } from 'react';
import moment from 'moment';
import { InfoTextContainer } from 'containers';
import { FormattedNumber, FormattedDate, FormattedTime } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import { WithDrawForm, RenderButton, RenderBadge, CurrencyNumber, WalletTransactionReportForm, RenderTable, Loader, AuthManager, WalletTransactionFilterForm } from 'components';
import {
	Paper,
	CardContent,
	Table,
	Grid,
	TableBody,
	TableHead,
	TableCell,
	TableRow,
	Typography,
	TextField,
	InputAdornment,
	Dialog,
	DialogActions,
	DialogContent,
	Slide,
	Chip
} from '@material-ui/core';
import {connect, useSelector} from 'react-redux';
import { bindActionCreators } from 'redux';
import { fromSettings, fromWallet } from 'store/selectors';
import { getPlatformSettings, getWalletTransactions, csvDownloadWalletTransactions } from 'store/actions';
import { withTranslation } from 'react-i18next';
import walletStyle from './style';

const Transition = props => <Slide direction="up" {...props} />;

class WalletTessPay extends Component {
	constructor(props) {
		super(props);

		this.state = {
			monthlyStatementsModal: false,
			depositOpen: false,
			withdrawOpen: false,
			page: 0,
			perPage: 20,
			filters: ''
		};

		this.handleWithdrawSubmit = this.handleWithdrawSubmit.bind(this);
	}

	componentWillMount() {
		const { page, perPage } = this.state;
		this.props.getPlatformSettings();
		this.props.getWalletTransactions({
			page: page + 1,
			perPage
		});
		// this.props.getTransactionsFilters();
		this.setState({ contracts: this.props.contracts || [] });
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.walletInfo && nextProps.walletInfo.contracts) {
			this.setState({ contracts: nextProps.walletInfo.contracts });
		}
	}

	handleDepositShow = () => {
		const { depositOpen } = this.state;
		this.setState({ depositOpen: !depositOpen });
	};

	handleWithdrawShow = () => {
		const { withdrawOpen } = this.state;
		this.setState({ withdrawOpen: !withdrawOpen });
	};

	handleWithdrawSubmit = data => {
		const { withDrawSubmit } = this.props;
		withDrawSubmit(data);
		this.handleWithdrawShow();
	};

	transactionAmount = amount => {
		return (
			<RenderBadge color={(amount < 0 && 'danger') || 'success'}>
				{(amount < 0 && '(') || null}
				<CurrencyNumber amount={(amount < 0 && amount * -1) || amount} />
				{(amount < 0 && ')') || null}
			</RenderBadge>
		);
	};

	balanceAmount = transaction => {
		const { settings } = this.props;
		if (transaction && transaction.balance && parseFloat(transaction.balance) < 0) {
			return (
				<RenderBadge color="danger">
					(
					<FormattedNumber value={Math.abs(transaction.balance)} style="currency" currency={(settings && settings.currency && settings.currency.currencyName) || 'USD'} />
					)
				</RenderBadge>
			);
		}
		return (
			<RenderBadge color="success">
				<FormattedNumber
					value={(transaction && transaction.balance) || 0}
					style="currency"
					currency={(settings && settings.currency && settings.currency.currencyName) || 'USD'}
				/>
			</RenderBadge>
		);
	};

	pendingTransactionDetails(pendingFund) {
		if (pendingFund.fiatAmount.indexOf('-') > -1) {
			return <span>Withdraw request</span>;
		}
		return <span>Deposit request</span>;
	}

	setPage(page = 1) {
		this.setState({ page });
		const { perPage, filters } = this.state;
		this.props.getWalletTransactions({
			page: page + 1,
			perPage,
			filters: JSON.stringify(filters || {})
		});
	}

	setPageSize(perPage = 20) {
		this.setState({ perPage });
		const { page, filters } = this.state;
		this.props.getWalletTransactions({
			page: page + 1,
			perPage,
			filters: JSON.stringify(filters || {})
		});
	}

	triggerTransactionFilterActions = filters => {
		const { perPage } = this.state;
		this.setState({ page: 0 });
		this.setState({ filters: filters });
		// eslint-disable-next-line no-shadow
		const { getWalletTransactions } = this.props;
		getWalletTransactions({
			page: 1,
			perPage,
			filters: JSON.stringify(filters || {})
		});

		if (!filters.startDate && !filters.endDate && !filters.transactionType && !filters.contracts) {
			this.setState({ filters: '' });
		}
	};

	triggerDownloadCSVActions = filters => {
		const { csvDownloadWalletTransactions } = this.props;
		csvDownloadWalletTransactions({ filters: JSON.stringify(filters || {}) });
	};

	toggleShowMonthlyStatementsModal = () => {
		const { monthlyStatementsModal } = this.state;
		this.setState({ monthlyStatementsModal: !monthlyStatementsModal });
	};

	render() {
		const {
			printButton,
			redirectAccount,
			sendTopUp,
			amountTopUp,
			handleChangeTopUp,
			walletInfo,
			paymentRef,
			triggerWalletReportSubmit,
			dataBank,
			voipData,
			transactions,
			settings,
			t,
			transactionsLoading,
			walletLoading,
		} = this.props;

		const { depositOpen, withdrawOpen, page, perPage, filters } = this.state;
		const { totalBalance, totalAvailable, totalPending, totalProjected, pendingFunds, lockedOnSmartContracts } = walletInfo || {};


		return (
			<Grid container spacing={1}>
				<AuthManager permissions={['financials.view-wallet']}>
					<Grid item xs={12} md={12}>
						<Grid item xs={12}>
							<Paper>
								<CardContent style={{ position: 'relative' }}>
									{(walletLoading && <Loader overlay />) || null}
									<Typography variant="h1" gutterBottom>
										{t('wallet', { currency: (settings && settings.currency && settings.currency.currencyName) || 'USD' })}
									</Typography>
									<Table>
										<TableBody>
											<TableRow>
												<TableCell>{t('balance')}</TableCell>
												<TableCell>{this.transactionAmount(parseFloat(totalBalance || 0))}</TableCell>
											</TableRow>

											<TableRow>
												<TableCell>{t('current_available')}</TableCell>
												<TableCell>{this.transactionAmount(parseFloat(totalAvailable || 0))}</TableCell>
											</TableRow>

											{(totalPending && (
													<TableRow>
														<TableCell>{t('pending_balance')}</TableCell>

														<TableCell>{this.transactionAmount(parseFloat(totalPending || 0))}</TableCell>
													</TableRow>
												)) ||
												null}

											{(totalPending && (
													<TableRow>
														<TableCell>{t('projected_balance')}</TableCell>
														<TableCell>{this.transactionAmount(parseFloat(totalProjected || 0))}</TableCell>
													</TableRow>
												)) ||
												null}

											{(!walletLoading && (
													<TableRow>
														<TableCell />
														<TableCell>
															{printButton === true && (
																<RenderButton onClick={() => redirectAccount()} color="danger">
																	{t('update_bank_info')}
																</RenderButton>
															)}

															{printButton === false && (
																<div>
																	<AuthManager permissions={['financials.create-deposit']}>
																		<RenderButton color="primary" onClick={() => this.handleDepositShow()} disabled={walletLoading}>
																			{t('deposit')}
																		</RenderButton>
																	</AuthManager>
																	<AuthManager permissions={['financials.create-withdraw']}>
																		<RenderButton color="danger" onClick={() => this.handleWithdrawShow()} disabled={walletLoading}>
																			{t('withdraw')}
																		</RenderButton>
																	</AuthManager>
																</div>
															)}
														</TableCell>
													</TableRow>
												)) ||
												null}
										</TableBody>
									</Table>
								</CardContent>
							</Paper>
						</Grid>

						<Grid item xs={12}>
							{(pendingFunds || []).length > 0 && (
								<Paper>
									<CardContent>
										<Typography variant="h1" gutterBottom>
											{t('pending_transactions.title')}
										</Typography>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell>{t('pending_transactions.date')}</TableCell>
													<TableCell>{t('pending_transactions.details')}</TableCell>
													<TableCell>{t('pending_transactions.amount')}</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{pendingFunds.map((transaction, i) => (
													<TableRow key={i}>
														<TableCell>
															<FormattedDate value={transaction.createdAt} day="numeric" month="long" year="numeric" />{' '}
															<FormattedTime value={transaction.createdAt} />
														</TableCell>
														<TableCell>{this.pendingTransactionDetails(transaction)}</TableCell>
														<TableCell>{this.transactionAmount(parseFloat(transaction.fiatAmount || 0))}</TableCell>
													</TableRow>
												))}
												{voipData && voipData.customer && voipData.customer.data && voipData.customer[0] && voipData.customer.data[0]['"New Usage"'] && (
													<TableRow>
														<TableCell>{t('todays_usage')}:</TableCell>
														<TableCell>
															<RenderBadge color="danger">${voipData.customer.data[0]['"New Usage"']}</RenderBadge>
														</TableCell>
													</TableRow>
												)}
											</TableBody>
										</Table>
									</CardContent>
								</Paper>
							)}

							<WithDrawForm
								open={withdrawOpen}
								openCloseHandler={this.handleWithdrawShow}
								initialValues={{
									amountAvailable: parseFloat(totalAvailable).toFixed(2)
								}}
								bankAccounts={dataBank}
								onSubmit={this.handleWithdrawSubmit}
							/>
						</Grid>
					</Grid>
				</AuthManager>
				<AuthManager permissions={['financials.view-transactions']}>
					<Grid item xs={12} md={12}>
						<Paper>
							<CardContent>
								<Grid container>
									<Grid item xs={8}>
										<Typography variant="h1" gutterBottom>
											{t('transaction_history')}
										</Typography>
									</Grid>
								</Grid>

								<WalletTransactionFilterForm
									onChange={this.triggerTransactionFilterActions}
									onDownloadCSV={this.triggerDownloadCSVActions}
									onDownloadMonthlyStatements={triggerWalletReportSubmit}
								/>

								<RenderTable
									data={(Array.isArray(transactions && transactions.data) && transactions.data) || []}
									minRows={2}
									loading={transactionsLoading}
									columns={[
										{
											Header: 'Traffic date',
											accessor: 'trafficDate',
											Cell: ({ original: transaction }) =>
												(transaction?.trafficDateStr && moment.utc(transaction?.trafficDateStr).format('MMM DD, YYYY')) ||
												(transaction?.trafficDate && moment.utc(transaction.trafficDate).format('MMM DD, YYYY')) ||
												'',
											width: 150
										},
										{
											Header: 'Posting date',
											accessor: 'createdAt',
											Cell: ({ original: transaction }) =>
												(transaction?.transactionDateStr && moment.utc(transaction?.transactionDateStr).format('MMM DD, YYYY')) ||
												(transaction?.createdAt && moment.utc(transaction.createdAt).format('MMM DD, YYYY')) ||
												'',
											width: 150
										},
										{
											Header: t('table.details'),
											accessor: 'description'
										},
										{
											Header: t('table.partner'),
											accessor: 'partner',
											Cell: ({ original: transaction }) => transaction.paymentTo || ''
										},
										{
											Header: <div className="rt-text-right">{t('table.amount')}</div>,
											className: 'rt-text-right',
											accessor: '',
											Cell: ({ original: transaction }) => this.transactionAmount(parseFloat(transaction.fiatAmount || 0)),
											width: 200
										},
										{
											Header: <div className="rt-text-right">{filters ? 'Total' : `${t('table.running_balance')}`}</div>,
											className: 'rt-text-right',
											accessor: '',
											Cell: ({ original: transaction }) => this.balanceAmount(transaction),
											width: 200
										}
									]}
									page={page}
									defaultPageSize={perPage}
									pageSize={perPage}
									manual
									pages={Math.ceil(((transactions && transactions._metaData && transactions._metaData.total) || 0) / perPage)}
									tableTotal={(transactions && transactions._metaData && transactions._metaData.total) || 0}
									onPageChange={page => this.setPage(page)}
									onPageSizeChange={size => this.setPageSize(size)}
								/>
							</CardContent>
						</Paper>
					</Grid>
				</AuthManager>
				{/*
				<Grid item xs={12} md={6}>
					<InfoTextContainer page="Financials" section="Wallet" />
				</Grid> */}
				{/* /********** ORIGINALLY COMMMENTED ****************

				<Dialog open={monthlyStatementsModal} onBackdropClick={this.toggleShowMonthlyStatementsModal}>
					<DialogContent>
						<Typography variant="h2">Monthly statement</Typography>
						<br />
						<WalletTransactionReportForm
							initialValues={{
								date: moment()
							}}
							onSubmit={triggerWalletReportSubmit}
						/>
					</DialogContent>
				</Dialog>

				/********** ORIGINALLY COMMMENTED **************** */}

				<Dialog maxWidth="sm" fullWidth open={depositOpen} onBackdropClick={this.handleDepositShow} TransitionComponent={Transition}>
					{(settings?.currency?.currencyName || 'USD') === 'EUR' && (
						<>
							<DialogContent>
								<Grid container alignItems="center">
									<Grid item xs>
										<TextField
											fullWidth
											variant="filled"
											label={t('deposit_modal.amount')}
											value={amountTopUp}
											onChange={handleChangeTopUp}
											InputProps={{
												startAdornment: (
													<InputAdornment variant="filled" position="start">
														<CurrencyNumber amount="" />
													</InputAdornment>
												)
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										{/* CURRENCY EUR */}
										<Typography valiant="headline">Tess Bank Information</Typography>
										<br />
										<p>
											<strong>{t('bank.account_name')}:</strong> TessPay Finance Inc.
										</p>
										<p>
											<strong>{t('bank.benef_address')}:</strong> 9160 Forum Corporate Parkway, Suite 350, Fort Myers, FL 33905
										</p>
										<p>
											<strong>{t('bank.benef_acc_no')}:</strong> 3304018981
										</p>
										<p>
											<strong>{t('bank.bank_name')}:</strong> Standard Chartered Bank
										</p>
										<p>
											<strong>{t('bank.bank_address')}:</strong> Frankfurt, Germany
										</p>
										<p>
											<strong>{t('bank.bank_aba')}:</strong> N/A
										</p>
										<p>
											<strong>{t('bank.bank_swift')}:</strong> SCBLDEFX
										</p>

										<TextField fullWidth variant="filled" label={t('deposit_modal.ref')} value={paymentRef} disabled error />
										<input type="hidden" name="tesspay-ref" value={paymentRef} />

										<p style={{ textAlign: 'center' }}>
											<img src="https://s3.ca-central-1.amazonaws.com/tesspay-cdn/assets/PP_Acceptance_Marks_for_LogoCenter_76x48.png" />
										</p>
										<p>{t('deposit_modal.paypal')}</p>
									</Grid>

								</Grid>
							</DialogContent>
							{paymentRef && (
								<DialogActions>
									<RenderButton
										color="primary"
										onClick={() => {
											sendTopUp(amountTopUp, walletInfo);
											this.handleDepositShow();
										}}
									>
										{t('deposit_modal.btn_submit')}
									</RenderButton>
									<RenderButton color="danger" onClick={this.handleDepositShow}>
										{t('translations:close')}
									</RenderButton>
								</DialogActions>
							)}
						</>

					) || (
						<>
							<DialogContent>
								<Grid container alignItems="center">
									{/*<Grid item xs>
										<TextField
											fullWidth
											variant="filled"
											label={t('deposit_modal.amount')}
											value={amountTopUp}
											onChange={handleChangeTopUp}
											InputProps={{
												startAdornment: (
													<InputAdornment variant="filled" position="start">
														<CurrencyNumber amount="" />
													</InputAdornment>
												)
											}}
										/>
									</Grid>*/}
									<Grid item xs={12}>
										{/* CURRENCY USD */}
										<Typography valiant="headline">Tess Bank Information</Typography>
										<br />
										<p>
											<strong>
												Please reach out to us by email at <a href="mailto:accounting@tesspay.io">accounting@tesspay.io</a> in order to get the bank account details.
											</strong>
										</p>
										<p>
											<strong>{t('bank.account_name')}:</strong> TessPay Finance Inc.
										</p>
										<p>
											<strong>{t('bank.benef_address')}:</strong> 9160 Forum Corporate Parkway, Suite 350, Fort Myers, FL 33905
										</p>
										<p>
											<strong>{t('bank.benef_acc_no')}:</strong> 3304018981
										</p>
										<p>
											<strong>{t('bank.bank_name')}:</strong> Silicon Valley Bank
										</p>
										<p>
											<strong>{t('bank.bank_address')}:</strong> 3003 TASMAN DRIVE, SANTA CLARA, United States
										</p>
										<p>
											<strong>{t('bank.bank_aba')}:</strong> 121140399
										</p>
										<p>
											<strong>{t('bank.bank_swift')}:</strong> SVBKUS6SXXX
										</p>

										<TextField fullWidth variant="filled" label={t('deposit_modal.ref')} value={paymentRef} disabled error />
										<input type="hidden" name="tesspay-ref" value={paymentRef} />

										<p style={{ textAlign: 'center' }}>
											<img src="https://s3.ca-central-1.amazonaws.com/tesspay-cdn/assets/PP_Acceptance_Marks_for_LogoCenter_76x48.png" />
										</p>
										<p>{t('deposit_modal.paypal')}</p>
									</Grid>

								</Grid>
							</DialogContent>
							<DialogActions>
								<RenderButton
									color="primary"
									onClick={() => {
										sendTopUp(amountTopUp, walletInfo);
										this.handleDepositShow();
									}}
								>
									{t('deposit_modal.btn_submit')}
								</RenderButton>
								<RenderButton color="danger" onClick={this.handleDepositShow}>
									{t('translations:close')}
								</RenderButton>
							</DialogActions>
						</>

					)}

				</Dialog>
			</Grid>
		);
	}
}

function mapStateToProps(state) {
	return {
		settings: fromSettings.getPlatformSettings(state),
		transactionsLoading: fromWallet.getTransactionsLoading(state),
		transactions: fromWallet.getWalletTransactions(state)
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			getPlatformSettings,
			getWalletTransactions,
			csvDownloadWalletTransactions
		},
		dispatch
	);
}

const WalletTessPayTranslated = withTranslation(['financials/general', 'translations'])(WalletTessPay);

export default withStyles(walletStyle)(connect(mapStateToProps, mapDispatchToProps)(WalletTessPayTranslated));
