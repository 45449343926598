import { initialState, getSettlements } from "./selectors";
import {
  FINANCIAL,
  RESULT_FINANCIAL,
  FINANCIAL_FAILURE,
  SETTLEMENTS,
  RESULT_SETTLEMENTS,
  SETTLEMENTS_FAILURE,
  LENDER_INVOICES_APPROVAL
} from "./actions";

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FINANCIAL:
      return {
        ...state,
        [FINANCIAL]: {
          ...payload
        }
      };
    case RESULT_FINANCIAL:
      return {
        ...state,
        [RESULT_FINANCIAL]: {
          ...payload
        }
      };
    case FINANCIAL_FAILURE:
      return {
        ...state,
        [FINANCIAL_FAILURE]: {
          ...payload
        }
      };
    case SETTLEMENTS:
      return {
        ...state,
        [SETTLEMENTS]: {
          ...payload
        }
      };
    case RESULT_SETTLEMENTS:
      return {
        ...state,
        [RESULT_SETTLEMENTS]: {
          ...payload
        }
      };
    case SETTLEMENTS_FAILURE:
      return {
        ...state,
        [SETTLEMENTS_FAILURE]: {
          ...payload
        }
      };
    case LENDER_INVOICES_APPROVAL:
      return {
        ...state,
        [LENDER_INVOICES_APPROVAL]: payload
      };
    default:
      return state;
  }
};
