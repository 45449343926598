import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, reset } from "redux-form";

import { createValidator, required, email } from "services/validation";
import { RecoveryPasswordForm } from "components";
import {
  recoveryPasswordRequest,
  cleanDataRecoveryPassword
} from "store/actions";
import { fromRecoverypassword } from "store/selectors";
import PropTypes from "prop-types";

class RecoveryPasswordContainer extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(reset("recoveryPasswordForm"));
    dispatch(cleanDataRecoveryPassword());
  }

  render() {
    return (
      <RecoveryPasswordForm
        {...this.props}
        handleSubmit={this.props.handleSubmit}
      />
    );
  }
}

RecoveryPasswordContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  form: state.form,
  info: fromRecoverypassword.getRecoveryPasswordDataState(state)
});

const onSubmit = (data, dispatch) => {
  dispatch(recoveryPasswordRequest(data.email));
};

const validate = createValidator({
  email: [required, email]
});

const RecoveryPasswordContainerConnect = connect(
  mapStateToProps,
  null
)(RecoveryPasswordContainer);

export default reduxForm({
  form: "recoveryPasswordForm",
  destroyOnUnmount: true,
  onSubmit,
  validate
})(RecoveryPasswordContainerConnect);
