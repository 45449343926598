import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ListItem } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "./style";

const PrivateSubMenuItem = props => {
  const {
    classes,
    link,
    callbackFromParent,
    menuIcon,
    title,
    id,
    itemState,
    menuOpen,
    external
  } = props;

  return (
    <ListItem className={classes.listItem}>
      {!external ? (
        <Link
          className={`${classes.navLink} ${itemState === "active" &&
            "active"} ${!menuOpen && classes.navOpen}`}
          to={link}
          onClick={() => callbackFromParent(id)}
        >
          {menuIcon}

          <span className={classes.text}>{title}</span>
        </Link>
      ) : (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className={`${classes.navLink} ${itemState === "active" &&
            "active"} ${!menuOpen && classes.navOpen}`}
        >
          {menuIcon}
          <span className={classes.text}>{title}</span>
        </a>
      )}
    </ListItem>
  );
};

PrivateSubMenuItem.propTypes = {
  itemState: PropTypes.string,
  title: PropTypes.string.isRequired,
  menuIcon: PropTypes.node.isRequired,
  link: PropTypes.string,
  id: PropTypes.string.isRequired,
  callbackFromParent: PropTypes.func.isRequired,
  classes: PropTypes.shape().isRequired,
  external: PropTypes.bool
};

PrivateSubMenuItem.defaultProps = {
  itemState: "",
  link: "/Privateexample",
  external: false
};

export default withStyles(headerLinksStyle)(PrivateSubMenuItem);
