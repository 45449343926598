export const initialState = {};
export const initialParticipantState = {
  participant: {
    id: "",
    userId: "",
    businessTypeId: "",
    addressId: "",
    participantTypeId: "",
    bankAccountId: "",
    participantName: "",
    contactPerson: "",
    taxId: "",
    phoneNumber: "",
    faxNumber: "",
    hardwareId: "",
    phonePrefixId: "",
    status: "",
    walletAddress: "",
    createdAt: "",
    updatedAt: ""
  }
};

export const initialAddressState = {
  address: {
    addressId: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    stateId: "",
    countryId: "",
    zipPostalCode: "",
    createdAt: "",
    updatedAt: ""
  }
};

export const initialBankAccountState = {
  bankAccount: {
    bankAccountId: "",
    participantId: "",
    paymentMethodId: "",
    currencyId: "",
    bankName: "",
    bankAddress: "",
    accountName: "",
    accountAddress: "",
    accountNumber: "",
    abaAch: "",
    swift: "",
    createdAt: "",
    updatedAt: ""
  }
};

export const initialUserState = {
  user: {
    name: "",
    email: "",
    accountType: "",
    confirmed: false,
    active: false,
    uuid: "",
    timezone: "",
    createdAt: ""
  }
};

export const initialHardwareState = {
  hardware: {
    hardwareId: "",
    participantId: "",
    voipProtocolId: "",
    codexId: "",
    manufacturer: "",
    model: "",
    softwareVersion: "",
    primaryIpAddress: "",
    mediaIpAddress: "",
    dialStringFormat: "",
    createdAt: "",
    updatedAt: ""
  }
};

export const initialVoipProtocolState = {
  voipProtocol: {
    voipProtocolId: "",
    voipProtocolName: ""
  }
};

export const initialHardwareCodecsState = {
  voipProtocol: {
    voipProtocolId: "",
    voipProtocolName: ""
  }
};

const initialStates = [
  {
    stateId: "19",
    stateName: "Other"
  }
];

export const initialHardwareArrayState = [];

export const getMyAccountData = (state = initialState) =>
  state.MYACCOUNT_ACTION_GET_DATA_RESPONSE !== null &&
  state.MYACCOUNT_ACTION_GET_DATA_RESPONSE !== undefined &&
  state.MYACCOUNT_ACTION_GET_DATA_RESPONSE.payload !== null &&
  state.MYACCOUNT_ACTION_GET_DATA_RESPONSE.payload !== undefined
    ? state.MYACCOUNT_ACTION_GET_DATA_RESPONSE.payload
    : "";

export const getParticipantData = (state = initialState) =>
  getMyAccountData(state).participant !== undefined &&
  getMyAccountData(state).participant !== null &&
  Object.keys(getMyAccountData(state).participant).length > 0
    ? getMyAccountData(state).participant
    : initialParticipantState;

export const getAddressData = (state = initialState) =>
  getMyAccountData(state).address !== undefined &&
  getMyAccountData(state).address !== null &&
  Object.keys(getMyAccountData(state).address).length > 0
    ? getMyAccountData(state).address
    : initialAddressState;

export const getBankAccountData = (state = initialState) =>
  getMyAccountData(state).bankAccount !== undefined &&
  getMyAccountData(state).bankAccount !== null &&
  Object.keys(getMyAccountData(state).bankAccount).length > 0
    ? getMyAccountData(state).bankAccount
    : initialBankAccountState;

export const getBankAccountsDataArray = (state = initialState) =>
  getMyAccountData(state).bankAccounts !== undefined &&
  getMyAccountData(state).bankAccounts !== null &&
  Object.keys(getMyAccountData(state).bankAccounts).length > 0
    ? getMyAccountData(state).bankAccounts
    : [];

export const getUserData = (state = initialState) =>
  getMyAccountData(state).user !== undefined &&
  getMyAccountData(state).user !== null &&
  Object.keys(getMyAccountData(state).user).length > 0
    ? getMyAccountData(state).user
    : initialUserState;

export const getHardwareData = (state = initialState) =>
  getMyAccountData(state).hardware !== undefined &&
  getMyAccountData(state).hardware !== null &&
  Object.keys(getMyAccountData(state).hardware).length > 0
    ? getMyAccountData(state).hardware
    : initialHardwareState;

export const getHardwareDataArray = (state = initialState) => {
  const hardwareAndCodecs =
    getMyAccountData(state).hardwareProfiles !== undefined &&
    getMyAccountData(state).hardwareProfiles !== null &&
    Object.keys(getMyAccountData(state).hardwareProfiles).length > 0
      ? getMyAccountData(state).hardwareProfiles
      : [];

  return hardwareAndCodecs;
};

export const getVoipProtocolData = (state = initialState) =>
  getMyAccountData(state).voipProtocol !== undefined &&
  getMyAccountData(state).voipProtocol !== null &&
  Object.keys(getMyAccountData(state).voipProtocol).length > 0
    ? getMyAccountData(state).voipProtocol
    : initialVoipProtocolState;

export const lenderSettingsInitialState = {
  isLoading: false,
  info: false,
  error: false,
  generation: {
    active: false,
    isPDFInvoiceSyncActive: false,
    isCSVInvoiceSyncActive: false,
    isDebtorFileSyncActive: false,
    isCDRSyncActive: false
  },
  sftp: {
    active: false,
    credentials: {
      host: "",
      username: "",
      remotePath: "/",
      port: 22,
      pwd: ""
    }
  }
};

export const LENDER_SETTINGS_DATA = "LENDER_SETTINGS_DATA";
export const getLenderSettings = (state = initialState) =>
  state[LENDER_SETTINGS_DATA] || lenderSettingsInitialState;

export const getGeneralInformation = (state = {}) => {
  const participantData = getParticipantData(state);
  const userData = getUserData(state);
  const addressData = getAddressData(state);

  const id = participantData.id !== undefined ? participantData.id : "";

  const userId =
    participantData.userId !== undefined ? participantData.userId : "";

  const participantName =
    participantData.participantName !== undefined
      ? participantData.participantName
      : "";

  const businessTypeId =
    participantData.businessTypeId !== undefined
      ? participantData.businessTypeId
      : "";

  const contactPerson =
    participantData.contactPerson !== undefined
      ? participantData.contactPerson
      : "";

  const email = userData.email !== undefined ? userData.email : "";

  const taxId =
    participantData.taxId !== undefined ? participantData.taxId : "";

  const addressLine1 =
    addressData.addressLine1 !== undefined ? addressData.addressLine1 : "";

  const countryId =
    addressData.countryId !== undefined ? addressData.countryId : "";

  const stateId = addressData.stateId !== undefined ? addressData.stateId : "";

  const city = addressData.city !== undefined ? addressData.city : "";

  const zipPostalCode =
    addressData.zipPostalCode !== undefined ? addressData.zipPostalCode : "";

  const phonePrefixId =
    participantData.phonePrefixId !== undefined
      ? participantData.phonePrefixId
      : "";

  const phoneNumber =
    participantData.phoneNumber !== undefined
      ? participantData.phoneNumber
      : "";

  const faxNumber =
    participantData.faxNumber !== undefined ? participantData.faxNumber : "";

  const registrationNumber =
    participantData.registrationNumber !== undefined
      ? participantData.registrationNumber
      : "";

  const logo = participantData.logo !== undefined ? participantData.logo : "";

  const generalInformationData = {
    id,
    userId,
    participantName,
    businessTypeId,
    contactPerson,
    email,
    taxId,
    addressLine1,
    countryId,
    stateId,
    city,
    zipPostalCode,
    phonePrefixId,
    phoneNumber,
    faxNumber,
    registrationNumber,
    logo
  };

  return generalInformationData;
};

export const getCountriesDataArray = (state = initialState) =>
  state.COUNTRIES !== null &&
  state.COUNTRIES !== undefined &&
  state.COUNTRIES.payload !== null &&
  state.COUNTRIES.payload !== undefined &&
  state.COUNTRIES.payload.countries !== null &&
  state.COUNTRIES.payload.countries !== undefined
    ? state.COUNTRIES.payload.countries
    : [];

export const getCodecsDataArray = (state = initialState) =>
  state.HARDWARE_CODECS !== null &&
  state.HARDWARE_CODECS !== undefined &&
  state.HARDWARE_CODECS.payload !== null &&
  state.HARDWARE_CODECS.payload !== undefined &&
  state.HARDWARE_CODECS.payload.codecs !== null &&
  state.HARDWARE_CODECS.payload.codecs !== undefined
    ? state.HARDWARE_CODECS.payload.codecs
    : [];

export const getHardwareProfileOptionsDataArray = (state = initialState) =>
  state.HARDWARE_PROFILE_OPTIONS !== null &&
  state.HARDWARE_PROFILE_OPTIONS !== undefined &&
  state.HARDWARE_PROFILE_OPTIONS.payload !== null &&
  state.HARDWARE_PROFILE_OPTIONS.payload !== undefined
    ? state.HARDWARE_PROFILE_OPTIONS.payload
    : [];

export const getStatesDataArray = (state = initialState) =>
  state.STATES !== null &&
  state.STATES !== undefined &&
  state.STATES.payload !== null &&
  state.STATES.payload !== undefined &&
  state.STATES.payload.countryState !== null &&
  state.STATES.payload.countryState !== undefined
    ? state.STATES.payload.countryState
    : initialStates;

export const getBankStatesDataArray = (state = initialState) =>
  state.BANKSTATES !== null &&
  state.BANKSTATES !== undefined &&
  state.BANKSTATES.payload !== null &&
  state.BANKSTATES.payload !== undefined &&
  state.BANKSTATES.payload.countryState !== null &&
  state.BANKSTATES.payload.countryState !== undefined
    ? state.BANKSTATES.payload.countryState
    : initialStates;

export const getBeneficiaryStatesDataArray = (state = initialState) =>
  state.BENEFICIARYSTATES !== null &&
  state.BENEFICIARYSTATES !== undefined &&
  state.BENEFICIARYSTATES.payload !== null &&
  state.BENEFICIARYSTATES.payload !== undefined &&
  state.BENEFICIARYSTATES.payload.countryState !== null &&
  state.BENEFICIARYSTATES.payload.countryState !== undefined
    ? state.BENEFICIARYSTATES.payload.countryState
    : initialStates;

export const getCorrespondentStatesDataArray = (state = initialState) =>
  state.CORRESPONDENTSTATES !== null &&
  state.CORRESPONDENTSTATES !== undefined &&
  state.CORRESPONDENTSTATES.payload !== null &&
  state.CORRESPONDENTSTATES.payload !== undefined &&
  state.CORRESPONDENTSTATES.payload.countryState !== null &&
  state.CORRESPONDENTSTATES.payload.countryState !== undefined
    ? state.CORRESPONDENTSTATES.payload.countryState
    : initialStates;

export const getBusinessTypeDataArray = (state = initialState) =>
  state.BUSINESS_TYPE !== null &&
  state.BUSINESS_TYPE !== undefined &&
  state.BUSINESS_TYPE.payload !== null &&
  state.BUSINESS_TYPE.payload !== undefined &&
  state.BUSINESS_TYPE.payload.items !== null &&
  state.BUSINESS_TYPE.payload.items !== undefined
    ? state.BUSINESS_TYPE.payload.items
    : [];

export const getPostResponse = (state = initialState) =>
  state.POST_RESPONSE !== null &&
  state.POST_RESPONSE !== undefined &&
  state.POST_RESPONSE.payload !== null &&
  state.POST_RESPONSE.payload !== undefined
    ? state.POST_RESPONSE.payload
    : undefined;

export const getPhonePrefixDataArray = (state = initialState) =>
  state.PHONEPREFIX !== null &&
  state.PHONEPREFIX !== undefined &&
  state.PHONEPREFIX.payload !== null &&
  state.PHONEPREFIX.payload !== undefined &&
  state.PHONEPREFIX.payload !== null &&
  state.PHONEPREFIX.payload !== undefined
    ? state.PHONEPREFIX.payload
    : [];

export const getMfaQrCode = (state = initialState) =>
  state.MFAQR !== null &&
  state.MFAQR !== undefined &&
  state.MFAQR.payload !== null &&
  state.MFAQR.payload !== undefined &&
  state.MFAQR.payload !== null &&
  state.MFAQR.payload !== undefined
    ? state.MFAQR.payload
    : [];

export const EXTERNAL_CLIENT_DATA = "EXTERNAL_CLIENT_DATA";
export const externalClientData = {};
export const getExternalClientDataState = (state = externalClientData) =>
  state[EXTERNAL_CLIENT_DATA] || externalClientData;
