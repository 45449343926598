import React, { memo, useState, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import styles from './styles.module.scss';
import { TicketsSection } from './components/TicketsSection';
import { ThreadsSection } from './components/ThreadsSection';

export default memo(() => {
	const [selectedTicket, setSelectedTicket] = useState('');
	const handleSelectTicket = useCallback(value => setSelectedTicket(value), []);

	return (
		<div className={styles.container}>
			<Grid container spacing={3}>
				<Grid item xs={12} md={6}>
					<TicketsSection selectedTicket={selectedTicket} handleSelectTicket={handleSelectTicket} />
				</Grid>
				<Grid item xs={12} md={6} sx={{ maxHeight: 1000, overflow: 'scroll' }}>
					<ThreadsSection selectedTicket={selectedTicket} />
				</Grid>
			</Grid>
		</div>
	);
});
