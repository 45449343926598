import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export const useModal = ({ toggleModal }) => {
	const dispatch = useDispatch();
	const { escrowmanaging, notifications } = useSelector(state => state || {});

	//************ HANDLING MODAL STATE ****************************/

	const [openDrawer, setOpenDrawer] = useState(false);
	const [needFinancing, setNeedFinancing] = useState(true);
	const [selectedClaim, setSelectedClaim] = useState(null);

	useEffect(() => {
		setOpenDrawer(!!selectedClaim);
	}, [selectedClaim]);

	useEffect(() => {
		dispatch({
			type: 'escrowManaging/GET_AVAILABLE_CLAIMS'
		});
	}, []);

	// ************ FETCHING DATA AFTER CREATE ORDER SUBMISSION ******************************/

	const transactionResult = notifications.notifications;

	const { availableClaims, loading } = escrowmanaging || {};

	useEffect(() => {
		if (!loading.orders && transactionResult.length > 0 && transactionResult[0].message === 'Order created successfully') {
			toggleModal();
			setSelectedClaim(null);
			formik.resetForm();

			dispatch({
				type: 'escrowManaging/GET_AVAILABLE_CLAIMS'
			});
		}
	}, [loading.orders, transactionResult]);

	//************ HANDLING FORM  ******************************/

	const handleSetNeedFinancing = useCallback(
		value => {
			setNeedFinancing(value);
			formik.setFieldValue('needFinancing', value);
		},
		[needFinancing]
	);

	const { claimId, claimNumber, summary, lender, _id } = selectedClaim || {};
	const { quantity, value } = summary || {};
	const { lenderCoveragePercent } = lender || {};

	const formik = useFormik({
		initialValues: {
			needFinancing: needFinancing,
			coverage: 90
		},
		onSubmit: values => {
			try {
				dispatch({
					type: 'escrowManaging/CREATE_NEW_ORDER',
					payload: {
						coverage: values.needFinancing ? values.coverage : 0,
						financingStatus: needFinancing ? 'required' : 'not-required',
						claimId,
						claimNumber,
						orderQty: quantity,
						orderTotal: value,
						settlementId: _id
					}
				});
			} catch (e) {
				console.log(e);
			}
		},
		validationSchema: Yup.object().shape({
			coverage: Yup.number().when('needFinancing', {
				is: true,
				then: Yup.number()
					.required('Percentage is required')
					.min(1, 'Percentage must be at least 1%')
					.max(90, 'No more than 90% can be financed'),
				otherwise: Yup.number().notRequired()
			})
		})
	});

	return { formik, handleSetNeedFinancing, setSelectedClaim, selectedClaim, loading, availableClaims, openDrawer, needFinancing };
};
