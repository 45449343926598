import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Slide, Paper, CardContent, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, TextField, MenuItem } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { RenderBadge, RenderButton, RenderTable, SmartContractAllocateBuy, SmartContractAllocateSell, AuthManager } from 'components';
import moment from 'moment';
import { contractIdFormatter } from 'utils';
import { useTranslation } from 'react-i18next';
import styles from "./styles.module.scss"

const Transition = props => <Slide direction="up" {...props} />;

const TableSmartContracts = props => {
	const { t } = useTranslation(['smartcontracts/list', 'translations']);

	const fundedFormatter = isFunded => {
		if (isFunded) {
			return <RenderBadge color="primary">{t('translations:yes')}</RenderBadge>;
		}
		return t('translations:no');
	};

	const statusFormatter = row => {
		if (row.status === 'draft') {
			return <RenderBadge color="warning">{t('draft')}</RenderBadge>;
		}
		if (row.status === 'terminated') {
			return <RenderBadge color="danger">{t('terminated')}</RenderBadge>;
		}
		if (row.status === 'active' && row.isSignedContract && row.terminationRequested) {
			return (
				<RenderBadge color="danger">
					{t('requested_termination_on', {
						partner: (row.terminationRequestedByCurrentUser && 'You') || 'Partner',
						date: moment(row.terminationRequestedAt).format('DD MMM YYYY')
					})}
				</RenderBadge>
			);
		}
		if (row.status === 'active' && row.isSignedContract) {
			return <RenderBadge color="primary">{t('executed', { date: moment(row.updatedAt).fromNow() })}</RenderBadge>;
		}
		return <RenderBadge color="warning">{t('pending')}</RenderBadge>;
	};

	const actionFormatter = row => {
		const { terminateSmartContract } = props;

		if (row.status && row.status === 'pending') {
			return (
				<div>
					<AuthManager permissions={['smartContracts.view-contracts']}>
						<RenderButton small color="primary" component={Link} to={`/smart-contracts/edit/${row._id}`}>
							{t('view')}
						</RenderButton>
					</AuthManager>

					{((!row.terminationRequested || (row.terminationRequested && !row.terminationRequestedByCurrentUser)) && (
						<AuthManager permissions={['smartContracts.delete-contracts']}>
							<RenderButton small color="danger" onClick={() => terminateSmartContract(row._id)}>
								{(!row.terminationRequested && t('terminate')) || t('approve_termination')}
							</RenderButton>
						</AuthManager>
					)) ||
						''}
				</div>
			);
		}

		return (
			<div>
				<AuthManager permissions={['smartContracts.view-contracts']}>
					<RenderButton small color="primary" component={Link} to={`/smart-contracts/edit/${row._id}`}>
						{t('view')}
					</RenderButton>
				</AuthManager>
				{(row.status === 'active' && (!row.terminationRequested || (row.terminationRequested && !row.terminationRequestedByCurrentUser)) && (
					<AuthManager permissions={['smartContracts.delete-contracts']}>
						<RenderButton small color="danger" onClick={() => terminateSmartContract(row._id)}>
							{(!row.terminationRequested && t('terminate')) || t('approve_termination')}
						</RenderButton>
					</AuthManager>
				)) ||
					''}
				{row.status === 'draft' && (
					<AuthManager permissions={['smartContracts.delete-contracts']}>
						<RenderButton small color="danger" onClick={() => terminateSmartContract(row._id)}>
							{t('terminate')}
						</RenderButton>
					</AuthManager>
				) ||
					''}
			</div>
		);
	};
	const fundingActionsFormatter = row => {
		const { toggleRequestFundingModal, toggleAllocateFundingModal } = props;
		return (
			<div>
				{row.status === 'active' ? (
					<AuthManager permissions={['smartContracts.update-contracts']}>
						<RenderButton small color="primary" onClick={() => toggleAllocateFundingModal(row)}>
							{t('allocate_funds')}
						</RenderButton>
					</AuthManager>
				) : (
					''
				)}
				{row.fundingRequested && row.status === 'active' ? (
					<AuthManager permissions={['smartContracts.update-contracts']}>
						<RenderButton small color="primary" component={Link} to={`/smart-contracts/funding-offers/${row._id}`}>
							{t('funding_offers')}
						</RenderButton>
					</AuthManager>
				) : (
					''
				)}

				{!row.fundingRequested && row.isOriginContract && !row.isFundedContract && row.status === 'active' ? (
					<AuthManager permissions={['smartContracts.update-contracts']}>
						<RenderButton small color="secondary" onClick={() => toggleRequestFundingModal(row)}>
							{t('request_funding')}
						</RenderButton>
					</AuthManager>
				) : (
					''
				)}
			</div>
		);
	};

	const filterCaseInsensitive = (filter, row) => {
		const id = filter.pivotId || filter.id;
		const content = row[id];
		if (typeof content !== 'undefined') {
			// TODO filter by text in the table or if it's a object, filter by key
			if (typeof content === 'object' && content !== null && content.key) {
				return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
			}
			return String(content).toLowerCase().includes(filter.value.toLowerCase());
		}
		return true;
	};

	const { listSmartContracts, isFunder, modalAllocateSell, toggleAllocateSell, saveAllocateSell, modalAllocateBuy, toggleAllocateBuy, saveAllocateBuy } = props;
	const triggerUpdateRoute = useCallback(userId => {
		dispatch({
			type: 'myaccount/REMOVE_USER_SUBACOUNT',
			payload: { _id: userId }
		});
	}, []);
	const tableColumns = [
		{
			Header: t('Name'),
			id: row=> row._id,
			width: 240,
			accessor: row=> contractIdFormatter(row._id, row.name, row.partner),
			Filter: ({ filter, onChange }) => (
				<TextField
				value={filter ? filter.value : ''}
				onChange={filterEvent => onChange(filterEvent.target.value)}
				variant="standard" />
			)
		},
		{
			Header: '   ',
			height:10,
			width: 60,
			filterable: false,
			Cell: p => {
				const { toggleSmartContractUpdateModal} = props;
				return (

					<div className="table-actions">
							<IconButton  onClick={() => toggleSmartContractUpdateModal(p.original)} color="primary" >
							<Edit style={{height: '18px'}}/>
							</IconButton>
							</div>
				);
			}
		},
		{
			Header: t('partner'),
			accessor: 'partner',
			width: 140,
			Filter: ({ filter, onChange }) => (
				<TextField
				value={filter ? filter.value : ''}
				onChange={filterEvent => onChange(filterEvent.target.value)}
				variant="standard" />
			)
		},
		{
			Header: t('type'),
			accessor: 'type',
			show: !isFunder,
			width: 100,
			Filter: ({ filter, onChange }) => (
				<TextField
				value={filter ? filter.value : ''}
				style={{width: '80%'}}
				select
				onChange={filterEvent => onChange(filterEvent.target.value)}
				variant="standard">
					<MenuItem value="sell">Sell</MenuItem>
					<MenuItem value="buy">Buy</MenuItem>
					<MenuItem value="">All</MenuItem>
				</TextField>
			)
		},
		{
			Header: t('funded'),
			accessor: 'isFundedContract',
			Cell: p => fundedFormatter(p.value),
			width: 100,
			filterable: false
		},
		{
			Header: t('status'),
			accessor: 'status',
			width: 140,
			Cell: p => statusFormatter(p.original),
			Filter: ({ filter, onChange }) => (
				<TextField
				value={filter ? filter.value : ''}
				style={{width: '100%'}}
				select
				onChange={filterEvent => onChange(filterEvent.target.value)}
				variant="standard">
					<MenuItem value="terminated">Terminated</MenuItem>
					<MenuItem value="draft">Draft</MenuItem>
					<MenuItem value="pending">Pending</MenuItem>
					<MenuItem value="active">Active</MenuItem>
					<MenuItem value="">All</MenuItem>
				</TextField>
			)
		},
		{
			accessor: '',
			filterable: false,
			className: 'actions-column',
			Cell: p => fundingActionsFormatter(p.original, isFunder),
		},
		{
			Header: t('action'),
			accessor: '',
			filterable: false,
			width: 200,
			Cell: p => actionFormatter(p.original, isFunder),
			className: 'actions-column'
		},
	];

	let tableData = null;

	if (!isFunder) {
		tableData = listSmartContracts;
	} else {
		tableData = {
			pending: [],
			active: [],
			denied: []
		};

		listSmartContracts.map(row => {
			if (row.status && row.status.statusOrigin === 'funded') {
				tableData.active.push(row);
			} else if (row.status && row.status.statusOrigin === 'executed') {
				tableData.pending.push(row);
			} else {
				tableData.denied.push(row);
			}

			return tableData;
		});
	}

	return (
		<div className={styles.container}>
			<Paper>
				<CardContent>
					<RenderTable defaultFilterMethod={filterCaseInsensitive} filterable title="" data={listSmartContracts && Array.isArray(listSmartContracts) ? listSmartContracts : []} columns={tableColumns} />
				</CardContent>
			</Paper>

			<Dialog open={modalAllocateSell} onBackdropClick={toggleAllocateSell} TransitionComponent={Transition}>
				<DialogTitle>{t('contract_settings')}</DialogTitle>
				<DialogContent>
					<SmartContractAllocateSell {...props} />
				</DialogContent>
				<DialogActions>
					<RenderButton color="danger" onClick={toggleAllocateSell}>
						{t('translations:close')}
					</RenderButton>
					<RenderButton color="primary" onClick={saveAllocateSell}>
						{t('translations:save_changes')}
					</RenderButton>
				</DialogActions>
			</Dialog>

			<Dialog open={modalAllocateBuy} onBackdropClick={toggleAllocateBuy} TransitionComponent={Transition}>
				<DialogTitle>{t('contract_settings')}</DialogTitle>
				<DialogContent>
					<SmartContractAllocateBuy {...props} />
				</DialogContent>
				<DialogActions>
					<RenderButton color="danger" onClick={toggleAllocateBuy}>
						{t('translations:close')}
					</RenderButton>
					<RenderButton color="primary" onClick={saveAllocateBuy}>
						{t('translations:save_changes')}
					</RenderButton>
				</DialogActions>
			</Dialog>
		</div>
	);
};

TableSmartContracts.propTypes = {
	// listSmartContracts: PropTypes.arrayOf(
	//   PropTypes.shape({
	//     _id: PropTypes.string.isRequired,
	//     partner: PropTypes.string.isRequired,
	//     type: PropTypes.string.isRequired,
	//     status: PropTypes.string.isRequired,
	//     isFundedContract: PropTypes.bool.isRequired,
	//     fundingRequested: PropTypes.bool.isRequired,
	//     isOriginContract: PropTypes.bool.isRequired
	//   })
	// ).isRequired,
	isFunder: PropTypes.bool.isRequired,
	terminateSmartContract: PropTypes.func.isRequired,
	toggleRequestFundingModal: PropTypes.func.isRequired,
	toggleAllocateFundingModal: PropTypes.func.isRequired,
	toggleSmartContractUpdateModal: PropTypes.func.isRequired,
	handleFundingReview: PropTypes.func.isRequired,
	sendRequestFunding: PropTypes.func.isRequired,
	// allocateFundingModal: PropTypes.bool.isRequired,
	saveAllocateFunding: PropTypes.func.isRequired
};
export default TableSmartContracts;
