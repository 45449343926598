import React from 'react';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { DateRangePicker, DateRangeDelimiter, LocalizationProvider } from '@material-ui/pickers';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import MomentUtilsAdapter from '@material-ui/pickers/adapter/moment';

const RenderFormikDatePicker = ({
	formik: { errors, touched, setFieldValue, getFieldProps },
	formik,
	value,
	name,
	onChange,
	label,
	autoOk,
	dateFormat,
	emptyLabel,
	views,
	disablePast,
	disableFuture,
	clearable,
	range,
	maxDate,
	minDate,
	...rest
}) => {
	if (!name || !formik) {
		console.log('FORMIK RenderFormikDatePicker ERORR!: name or formik required', { name, formik });
		return '';
	}

	const { error: fieldError, touched: fieldTouched, value: fieldValue } = formik.getFieldMeta(name);
	const fieldProps = getFieldProps(name);

	const hasError = (!!fieldError && fieldTouched && true) || false;

	const customMoment = moment.tz.setDefault('UTC');

	if (range) {
		return (
			<FormControl error={hasError} fullWidth>
				<LocalizationProvider dateAdapter={MomentUtilsAdapter} dateLibInstance={customMoment}>
					<DateRangePicker
						{...fieldProps}
						name={name}
						value={(fieldValue && Array.isArray(fieldValue) && fieldValue) || [null, null]}
						maxDate={maxDate}
						onChange={value => {
							setFieldValue(name, value);
							if (onChange && typeof onChange === 'function') onChange(value);
						}}
						renderInput={(startProps, endProps) => (
							<React.Fragment>
								<TextField {...startProps} helperText="" variant="standard" style={{ flexGrow: 1 }} />
								<DateRangeDelimiter> - </DateRangeDelimiter>
								<TextField {...endProps} helperText="" variant="standard" style={{ flexGrow: 1 }} />
							</React.Fragment>
						)}
						{...rest}
					/>
				</LocalizationProvider>
				{hasError && <FormHelperText>{fieldError}</FormHelperText>}
			</FormControl>
		);
	}

	return (
		<FormControl error={hasError} fullWidth>
			<MuiPickersUtilsProvider utils={MomentUtils} moment={customMoment}>
				<DatePicker
					{...fieldProps}
					name={name}
					leftArrowIcon={<KeyboardArrowLeft color="primary" />}
					rightArrowIcon={<KeyboardArrowRight color="primary" />}
					label={label}
					format={dateFormat}
					autoOk={autoOk}
					disablePast={disablePast}
					disableFuture={disableFuture}
					value={fieldValue && moment(fieldValue).isValid() ? moment.utc(fieldValue) : null}
					onChange={value => {
						setFieldValue(name, value);
						if (onChange && typeof onChange === 'function') onChange(value);
					}}
					minDate={minDate}
					emptyLabel={emptyLabel}
					clearable={clearable}
					views={views}
					{...rest}
				/>
			</MuiPickersUtilsProvider>
			{hasError && <FormHelperText>{fieldError}</FormHelperText>}
		</FormControl>
	);
};

export default RenderFormikDatePicker;
