import React, { memo, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RenderFormikInput, RenderFormikSelect } from 'components';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const ExternalClientTab = memo(({ formik }) => {
	const dispatch = useDispatch();

	const countries = useSelector(state => (state.routes && state.routes.countries) || []);
	const states = useSelector(state => (state.routes && state.routes.states) || []);
	const { t } = useTranslation('routing/routing');

	useEffect(() => {
		dispatch({ type: 'routes/GET_COUNTRIES' });
	}, []);

	useEffect(() => {
		if (formik.values.externalClient.countryId) {
			dispatch({ type: 'routes/GET_STATES', payload: formik.values.externalClient.countryId });
		}
	}, [formik.values.externalClient.countryId]);

	useEffect(() => {
		if (formik.values.externalClient.stateId && states && Array.isArray(states)) {
			if (!states.find(s => s._id === formik.values.externalClient.stateId)) {
				formik.setFieldValue('externalClient.stateId', '');
			}
		}
	}, [states, formik]);
	return (
		<div className={styles.container}>
			<Grid container spacing={2}>
				<Grid item xs={4}>
					<RenderFormikInput label={t('company_name')} name="externalClient.companyName" formik={formik} />
				</Grid>
				<Grid item xs={3}>
					<RenderFormikInput label={t('tax_id')} name="externalClient.taxId" formik={formik} />
				</Grid>
				<Grid item xs={5} />

				<Grid item xs={4}>
					<RenderFormikSelect
						formik={formik}
						label={t('country')}
						name="externalClient.countryId"
						options={(countries && Array.isArray(countries) && countries.map(c => ({ value: c._id, title: c.name }))) || []}
					/>
				</Grid>
				<Grid item xs={4}>
					<RenderFormikSelect
						formik={formik}
						label={t('state_prov_region')}
						name="externalClient.stateId"
						options={(states && Array.isArray(states) && states.map(c => ({ value: c._id, title: c.name }))) || []}
					/>
				</Grid>
				<Grid item xs={3}>
					<RenderFormikInput label={t('city')} name="externalClient.city" formik={formik} />
				</Grid>
				<Grid item xs={6}>
					<RenderFormikInput label={t('registered_address')} name="externalClient.addressLine1" formik={formik} />
				</Grid>
				<Grid item xs={3}>
					<RenderFormikInput label={t('zip_postal_code')} name="externalClient.zipPostalCode" formik={formik} />
				</Grid>

			</Grid>
		</div>
	);
});

export default ExternalClientTab;
