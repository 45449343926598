import { initialState } from "./selectors";
import { INFO_TEXT_REQUEST, INFO_TEXT_RESPONSE } from "./actions";

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case INFO_TEXT_REQUEST:
      return {
        ...state,
        [INFO_TEXT_REQUEST]: {
          ...payload
        }
      };

    case INFO_TEXT_RESPONSE:
      return {
        ...state,
        [INFO_TEXT_RESPONSE]: {
          ...payload
        }
      };

    default:
      return state;
  }
};
