import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const InfoText = props => {
	const { infoText, section } = props;

	return (
		infoText instanceof Object &&
		Object.values(infoText).map(info => {
			if (info.section === section) {
				return (
					<div key={info._id}>
						<Typography variant="h1" gutterBottom>
							{info.subsection || info.section}
						</Typography>
						<Typography variant="body1" gutterBottom>
							{info.content}
						</Typography>
					</div>
				);
			}

			return null;
		})
	);
};

InfoText.propTypes = {
	infoText: PropTypes.shape().isRequired
};

export default InfoText;
