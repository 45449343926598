import React, { memo, useEffect } from 'react';
import { Field, useFormik } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import { CardContent, Grid, Paper } from '@material-ui/core';
import { RenderRadio, RenderButton, RenderFormikSelect, RenderFormikRadioGroup, Loader } from 'components';
import styles from "./styles.module.scss"
import { useTranslation } from 'react-i18next';

const RoutesFilters = memo(({ triggerOnSubmit }) => {
	const dispatch = useDispatch();
	const isLoading = useSelector(state => (state.routes && state.routes.loadingFilters) || false);
	const filters = useSelector(state => (state.routes && state.routes.filters) || false);
	const { t } = useTranslation('routing/routing');
	const formik = useFormik({
		initialValues: {
			contractType: 'selling',
			customer: '',
			supplier: '',
			country: '',
			destinationType: '',
			destinationCarrier: '',
			contract: ''
		},
		onSubmit: (values, { resetForm }) => {
			triggerOnSubmit(values);
		},
		enableReinitialize: true
	});

	useEffect(() => {
		const { contractType: filtertype, country, destinationType, destinationCarrier } = formik.values;
		dispatch({
			type: 'routes/GET_LIST_FILTERS',
			payload: {
				filterType: filtertype,
				filters: {
					country,
					destinationType,
					destinationCarrier
				}
			}
		});
	}, [formik.values.contractType, formik.values.country, formik.values.destinationType, formik.values.destinationCarrier]);

	return (
		<div className={styles.container}>
			<form>
				<Paper>
					{(isLoading && <Loader loading />) || ''}
					<CardContent>
						<RenderFormikRadioGroup
							formik={formik}
							inline
							name="contractType"
							onChange={() => {
								formik.setFieldValue('supplier', '');
								formik.setFieldValue('customer', '');
								formik.setFieldValue('country', '');
								formik.setFieldValue('contract', '');
								formik.setFieldValue('destinationType', '');
								formik.setFieldValue('destinationCarrier', '');
								formik.submitForm();
							}}
						>
							<RenderRadio value="selling" label={t('selling')} />
							<RenderRadio value="buying" label={t('buying')} />
						</RenderFormikRadioGroup>
						<Grid container spacing={2} justify="space-around">
							{formik.values.contractType === 'selling' && (
								<Grid item xs={12} md>
									<RenderFormikSelect formik={formik} label={t('customer')} name="customer" options={(filters && filters.customers) || []} />
								</Grid>
							)}

							{formik.values.contractType === 'buying' && (
								<Grid item xs={12} md>
									<RenderFormikSelect formik={formik} label={t('supplier')} name="supplier" options={(filters && filters.suppliers) || []} />
								</Grid>
							)}

							{formik.values.contractType === 'selling' && (
								<Grid item xs={12} md>
									<RenderFormikSelect
										formik={formik}
										label={t('country')}
										name="country"
										options={(filters && filters.countries) || []}
										onChange={() => {
											formik.setFieldValue('destinationType', '');
											formik.setFieldValue('destinationCarrier', '');
										}}
									/>
								</Grid>
							)}

							{formik.values.contractType === 'selling' && (
								<Grid item xs={12} md>
									<RenderFormikSelect
										formik={formik}
										label={t('destination_type')}
										name="destinationType"
										options={(filters && filters.destTypes) || []}
										onChange={() => {
											formik.setFieldValue('destinationCarrier', '');
										}}
									/>
								</Grid>
							)}

							{formik.values.contractType === 'selling' && (
								<Grid item xs={12} md>
									<RenderFormikSelect
										formik={formik}
										label={t('destination_carrier')}
										name="destinationCarrier"
										options={(filters && filters.destCarriers) || []}
									/>
								</Grid>
							)}

							<Grid item xs={12} md>
								<RenderFormikSelect formik={formik} label={t('contract')} name="contract" options={(filters && filters.contracts) || []} />
							</Grid>
						</Grid>
						<br />
						<Grid container spacing={8} justify="flex-end">
							<Grid item>
								<RenderButton color="primary" onClick={formik.handleSubmit} disabled={isLoading}>
								{t('search')}
								</RenderButton>
							</Grid>
						</Grid>
					</CardContent>
				</Paper>
			</form>
		</div>
	);
});

export default RoutesFilters;
