import React from 'react';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { DateRangePicker, LocalizationProvider, DateRangeDelimiter } from '@material-ui/pickers';
import moment from 'moment-timezone';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import { useTranslation } from 'react-i18next';

const RenderFormikDatePicker2 = ({ formik: { setFieldValue }, formik, value, name, onChange, label, disableAction, setDisplayExternalPicker, ...rest }) => {
	if (!name || !formik) {
		console.log('FORMIK RenderFormikDatePicker ERORR!: name or formik required', { name, formik });
		return '';
	}
	const { error: fieldError, touched: fieldTouched, value: fieldValue } = formik.getFieldMeta(name);
	const hasError = (!!fieldError && fieldTouched && true) || false;
	const { i18n } = useTranslation();

	const handleExternalPicker = () => {
		if (disableAction) {
			setDisplayExternalPicker(true)
		}
	}

	return (
		<FormControl error={hasError} fullWidth>
			<LocalizationProvider locale={i18n.language} dateLibInstance={moment} dateAdapter={MomentAdapter}>
				<DateRangePicker
					disableOpenPicker={disableAction ? true : false}
					{...rest}
					value={(fieldValue && Array.isArray(fieldValue) && fieldValue) || [null, null]}
					onChange={value => {
						setFieldValue(name, value);
						if (onChange && typeof onChange === 'function') onChange(value);
					}}
					renderInput={(startProps, endProps) => (
						<React.Fragment>
							<TextField onClick={() => handleExternalPicker()} {...startProps} variant="standard" fullWidth />
							<DateRangeDelimiter>-</DateRangeDelimiter>
							<TextField onClick={() => handleExternalPicker()} {...endProps} variant="standard" fullWidth />
						</React.Fragment>
					)}
				/>
			</LocalizationProvider>
			{hasError && <FormHelperText>{fieldError}</FormHelperText>}
		</FormControl>
	);
};

export default RenderFormikDatePicker2;
