import React from 'react';
import { FormControl, FormHelperText } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';

function RenderDatePicker(props) {
	const {
		input: { onChange, value, name },
		label,
		autoOk,
		dateFormat,
		emptyLabel,
		views,
		disablePast,
		disableFuture,
		clearable,
		meta: { touched, error }
	} = props;

	// const { value } = this.state;

	const hasError = typeof error !== typeof undefined && error.length > 0 && touched;

	const customMoment = moment.tz.setDefault('UTC');

	return (
		<FormControl error={hasError} fullWidth>
			<MuiPickersUtilsProvider utils={MomentUtils} moment={customMoment}>
				<DatePicker
					name={name}
					leftArrowIcon={<KeyboardArrowLeft color="primary" />}
					rightArrowIcon={<KeyboardArrowRight color="primary" />}
					label={label}
					format={dateFormat}
					autoOk={autoOk}
					disablePast={disablePast}
					disableFuture={disableFuture}
					value={value && moment(value).isValid() ? moment(value).utc() : null}
					onChange={onChange}
					emptyLabel={emptyLabel}
					clearable={clearable}
					views={views}
				/>
			</MuiPickersUtilsProvider>
			{hasError && <FormHelperText>{error}</FormHelperText>}
		</FormControl>
	);
}

export default RenderDatePicker;
