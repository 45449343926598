import React from "react";
import PropTypes from "prop-types";

const MyAccountSectionContent = props => {
  return <div>{props.children}</div>;
};

MyAccountSectionContent.propTypes = {
  children: PropTypes.node.isRequired
};
export default MyAccountSectionContent;
