const headerLinksStyle = theme => ({
  listItem: {
    width: "auto",
    padding: "0"
  },
  navLink: {
    color: "inherit",
    fontSize: "13px",
    padding: `0 ${theme.spacing(1)}px`,
    height: "48px",
    textDecoration: "none",
    margin: "0px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    willChange: "border",
    transition: "border 225ms linear",
    borderColor: theme.palette.secondary.main,
    position: "relative",
    "&:before": {
      content: "''",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      willChange: "width",
      width: 0,
      transition: "width 255ms linear",
      backgroundColor: "#3a755b",
      zIndex: "-1"
    },
    "&:hover, &:focus, &.active": {
      color: "inherit",
      borderLeft: `2px solid ${theme.palette.secondary.main}`,
      "&:before": {
        width: "100%"
      }
    }
  },
  text: {
    marginLeft: "5px",
    textTransform: "capitalize"
  },
  navOpen: {
    fontSize: theme.spacing(1) * 3,
    "& $text": {
      display: "none"
    }
  }
});

export default headerLinksStyle;
