import { FINANCING_REQUESTS_SMARTCONTRACTS_DATA } from "./actions";

const initialSmartContractData = {
  pendingContracts: [],
  activeContracts: [],
  declinedContracts: []
};

export const getSmartContracts = (state = {}) =>
  state[FINANCING_REQUESTS_SMARTCONTRACTS_DATA] || initialSmartContractData;
