import React, { Component } from 'react';
import { Field } from 'redux-form';
import { Grid, Typography } from '@material-ui/core';
import { RenderInput, RenderSelect } from 'components';
import { fromSmartcontracts } from 'store/selectors';
import { getSmartContractCatalogData } from 'store/actions';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class SmartContractsTechnicalTerms extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedSIP: ''
		};
	}

	componentWillMount() {
		const { getSmartContractCatalogData } = this.props;
		getSmartContractCatalogData({});
	}

	render() {
		const { newSmartContractData, showOptionsSell, showOptionBuy, smartContract, t } = this.props;

		return (
			<div>
				<Typography variant="h2" gutterBottom>
					{t('technical_terms')}
				</Typography>
				<Grid container justify={showOptionBuy === 'block' ? 'flex-end' : 'flex-start'}>
					<Grid item xs={12} md={6}>
						<Grid container>
							<Grid item xs={12} style={{ display: showOptionsSell }}>
								<Field
									disabled
									name="supplierName"
									id="supplierName"
									component={RenderInput}
									placeholder={smartContract.supplierName}
									label={t('supplier')}
									InputLabelProps={{ shrink: true }}
									margin="none"
								/>
							</Grid>
							<Grid item xs={12} style={{ display: showOptionBuy }}>
								<Field name="supplierId" id="supplierId" label={t('translations:select_field',{ field: t('supplier') })} component={RenderSelect} options={newSmartContractData.suppliers} />
							</Grid>
							<Grid item xs={12}>
								<Field
									name="trafficType"
									label={t('traffic_type')}
									component={RenderSelect}
									options={[
										{
											value: 'TDM',
											label: 'TDM'
										},
										{
											value: 'CLI',
											label: 'CLI'
										},
										{
											value: 'NON-CLI',
											label: 'NON-CLI'
										}
									]}
								/>
							</Grid>
							<Grid item xs={12}>
								<Grid item xs={12}>
									<Field
										name="isOriginContract"
										label={t('contract_type')}
										component={RenderSelect}
										options={[
											{
												value: true,
												label: t('contract_type.origination')
											},
											{
												value: false,
												label: t('contract_type.standard')
											}
										]}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} md={6}>
						<Grid container>
							<Grid item xs={12} style={{ display: showOptionsSell }}>
								<Field id="customerId" name="customerId" label={t('translations:select_field',{ field: t('customer') })} component={RenderSelect} options={newSmartContractData.customers} />
							</Grid>
							<Grid item xs={12} style={{ display: showOptionBuy }}>
								<Field
									id="customerName"
									disabled
									name="customerName"
									component={RenderInput}
									// placeholder={dataNewSmartContract.participant.participantName}
									placeholder={smartContract.customerName}
									label={t('customer')}
									InputLabelProps={{ shrink: true }}
									margin="none"
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		);
	}
}

SmartContractsTechnicalTerms.defaultProps = {
	selectedHardwareId: null
};

const mapDispatchToProps = dispatch => ({
	getSmartContractCatalogData() {
		dispatch(getSmartContractCatalogData());
	}
});

const mapStateToProps = state => ({
	voipProtocols: fromSmartcontracts.getVoipProtocols(state)
});

const SmartContractsTechnicalTermsTranslated = withTranslation(['smartcontracts/edit', 'translations'])(SmartContractsTechnicalTerms);

export default connect(mapStateToProps, mapDispatchToProps)(SmartContractsTechnicalTermsTranslated);
