import React, { memo, useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Chip, Dialog, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core';
import { FileDownload, DeleteSweep, AccountBalance, DateRange, RadioButtonUnchecked, RadioButtonChecked } from '@material-ui/icons';
import { RenderFormikSelect, RenderFormikDatePicker, RenderButton, WalletTransactionReportForm, RenderFormikCheckbox } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { contractIdFormatter, capitalizeWord } from 'utils';
import moment from 'moment';

export default memo(({ onChange: walletTransactionFilterOnChange, onDownloadCSV, onDownloadMonthlyStatements }) => {
	const dispatch = useDispatch();

	// const data = useSelector(state => (state.wallet.WALLET_DATA && state.wallet.WALLET_DATA.walletData) || {});
	const filters = useSelector(state => (state.wallet && state.wallet.transactionsFilters) || {});

	const [contractsSelectorModalOpen, setContractsSelectorModalOpen] = useState(false);
	const [availableContracts, setAvailableContracts] = useState((filters && filters.contracts) || []);
	const [filteredModalContracts, setFilteredModalContracts] = useState((filters && filters.contracts) || []);
	const [selectedModalContracts, setSelectedModalContracts] = useState([]);
	const [monthlyStatementsModal, setMonthlyStatementsModal] = useState(false);
	const [resetFilters, setResetFilters] = useState(false);

	const dummyFormik = useFormik({
		initialValues: {
			startDate: '',
			endDate: '',
			customer: '',
			supplier: ''
		},
		validateOnChange: false,
		validateOnBlur: false,
		validateOnMount: false
	});

	const formik = useFormik({
		initialValues: {
			date: [null, null],
			transactionType: '',
			contracts: [],
			excludeFees: false
		},
		onSubmit: (values, { resetForm }) => {
			if (walletTransactionFilterOnChange) {
				walletTransactionFilterOnChange({
					startDate: values?.date?.[0] || undefined,
					endDate: values?.date?.[1] || undefined,
					transactionType: values.transactionType,
					excludeFees: values.excludeFees,
					contracts: ((values.contracts || []).length > 0 && values.contracts) || undefined
				});
			}
		},
		validationSchema: Yup.object().shape({
			// participantName: Yup.string().required(t('please_input_bank_help', { label: t('bank_name') }))
		})
	});

	const triggerAddRemoveContractSelection = useCallback(
		contractId => {
			let selContracts = [...(selectedModalContracts || [])];
			if (selectedModalContracts.indexOf(contractId) > -1) {
				selContracts = selContracts.filter(c => c !== contractId);
			} else {
				selContracts.push(contractId);
			}
			setSelectedModalContracts(selContracts);
		},
		[selectedModalContracts, filteredModalContracts]
	);

	const triggerAddSmartContractFilters = useCallback(() => {
		setContractsSelectorModalOpen(false);
		if (Array.isArray(selectedModalContracts)) {
			formik.setFieldValue('contracts', selectedModalContracts);
		}
	}, [selectedModalContracts]);

	const triggerResetFilters = useCallback(() => {
		formik.resetForm();
		dummyFormik.resetForm();
		setSelectedModalContracts([]);
		setResetFilters(true);
	}, [formik, dummyFormik, selectedModalContracts]);

	const triggerDownloadCSV = useCallback(() => {
		if (onDownloadCSV) {
			const { date, transactionType, contracts } = formik.values;
			onDownloadCSV({
				startDate: (date && date[0]) || undefined,
				endDate: (date && date[1]) || undefined,
				transactionType: transactionType || undefined,
				contracts: (contracts.length > 0 && contracts) || undefined
			});
		}
	}, [formik.values]);

	const triggerWalletReportSubmit = useCallback(data => {
		if (onDownloadMonthlyStatements) {
			onDownloadMonthlyStatements(data);
		}
	}, []);

	const toggleShowMonthlyStatementsModal = useCallback(() => setMonthlyStatementsModal(state => !state), []);

	useEffect(() => {
		formik.resetForm();
	}, []);

	useEffect(() => {
		setResetFilters(false);
	}, [resetFilters]);

	useEffect(() => {
		dummyFormik.resetForm();
	}, [contractsSelectorModalOpen]);

	useEffect(() => {
		if (filters && filters.contracts) {
			setAvailableContracts(filters.contracts);
			setFilteredModalContracts(filters.contracts);
		}
	}, [filters]);

	useEffect(() => {
		let filteredContracts = availableContracts;

		if (dummyFormik.values.customer) {
			filteredContracts = availableContracts.filter(c => c.customerId === dummyFormik.values.customer);
		}

		if (dummyFormik.values.supplier) {
			filteredContracts = availableContracts.filter(c => c.supplierId === dummyFormik.values.supplier);
		}
		setFilteredModalContracts(filteredContracts);
	}, [availableContracts, dummyFormik.values.customer, dummyFormik.values.supplier]);

	const triggerApplyFilters = useCallback(() => {
		if (walletTransactionFilterOnChange) {
			formik.handleSubmit();

			// const { date, transactionType, contracts, excludeFees, project } = formik.values;
			// walletTransactionFilterOnChange({
			// 	startDate: (date && date[0]) || undefined,
			// 	endDate: (date && date[1]) || undefined,
			// 	transactionType: transactionType || undefined,
			// 	excludeFees: excludeFees || undefined,
			// 	contracts: (contracts.length > 0 && contracts) || undefined
			// });
		}
	}, [formik.values]);

	useEffect(() => {
		if (resetFilters) {
			if (walletTransactionFilterOnChange) {
				const { date, transactionType, contracts, excludeFees } = formik.values;
				walletTransactionFilterOnChange({
					startDate: (date && date[0]) || undefined,
					endDate: (date && date[1]) || undefined,
					transactionType: transactionType || undefined,
					excludeFees: excludeFees || undefined,
					contracts: (contracts.length > 0 && contracts) || undefined
				});
			}
		}
	}, [formik.values]);

	useEffect(() => {
		if (formik && formik.values && formik.values.transactionType !== 'settlement') {
			formik.setFieldValue('excludeFees', false);
		}
	}, [formik.values]);

	useEffect(() => {
		dispatch({ type: 'transactions/GET_TRANSACTIONS_FILTERS' });
	}, []);

	const formHasFilters =
		formik.values.transactionType || (formik.values.contracts && formik.values.contracts.length > 0) || (formik.values.date && formik.values.date[0] && formik.values.date[1]);

	const parseDateRange = ([startDate, endDate]) =>
		`${(startDate && startDate.isValid() && startDate.format('MM-DD-YYYY')) || ''} - ${(endDate && endDate.isValid() && endDate.format('MM-DD-YYYY')) || ''}`;

	return (
		<div>
			<Grid container spacing={1} style={{ marginBottom: '20px' }}>
				<Grid item xs={2}>
					<RenderFormikSelect
						name="transactionType"
						label="Transaction type"
						options={((filters && filters.transactionType) || []).map(option => ({ value: option.value, label: option.label }))}
						formik={formik}
					/>
				</Grid>
				<Grid item xs={2}>
					<RenderFormikSelect
						name="smartContracts"
						label="Smart Contracts"
						options={[]}
						formik={formik}
						disabled
						onClick={() => {
							setContractsSelectorModalOpen(true);
						}}
					/>
				</Grid>
				<Grid item xs={2}>
					<RenderFormikSelect
						name="projects"
						label="Projects"
						options={((filters && filters.projects) || []).map(option => ({ value: option.contracts, label: option.name }))}
						formik={formik}
						onChange={e => {
							formik.setFieldValue('contracts', e.target.value);
						}}
					/>
				</Grid>
				<Grid item xs={1} />
				<Grid item xs={4}>
					<RenderFormikDatePicker
						range
						disableFuture
						maxDate={new Date()}
						label="Transaction date"
						name="date"
						formik={formik}
						startText="Start date"
						endText="End date"
					/>
				</Grid>
				<Grid item xs={1} alignItems="flex-end">
					<RenderButton block color="primary" onClick={triggerApplyFilters}>
						Apply filters
					</RenderButton>
				</Grid>
			</Grid>
			<Grid container spacing={1} style={{ marginBottom: '20px' }}>
				<Grid item sm={12} md={8} xs={8}>
					{(formHasFilters && (
						<Grid container spacing={1}>
							{/* <Grid item>
								<Chip
									style={{ backgroundColor: '#FE2712', color: '#fff' }}
									label="Reset all filters"
									onClick={triggerResetFilters}
									avatar={
										<div style={{ color: '#fff' }}>
											<AccessAlarm />
										</div>
									}
								/>
							</Grid> */}
							{/* <Grid item>
								<Chip
									color="primary"
									label="Download CSV"
									onClick={triggerDownloadCSV}
									avatar={
										<div style={{ color: '#fff' }}>
											<FileDownload />
										</div>
									}
								/>
							</Grid> */}

							{/* <Grid item> </Grid> */}

							{formik.values.transactionType && (
								<Grid item>
									<Chip
										style={{ backgroundColor: 'orange', color: '#fff' }}
										label={`${capitalizeWord(formik.values.transactionType)}`}
										onDelete={() => {
											formik.setFieldValue('transactionType', null);
											setResetFilters(true);
										}}
										avatar={
											<div style={{ color: '#fff' }}>
												<AccountBalance />
											</div>
										}
									/>
								</Grid>
							)}
							{formik.values.date && formik.values.date[0] && formik.values.date[1] && (
								<Grid item>
									<Chip
										style={{ backgroundColor: '#0d553f', color: '#fff' }}
										label={`Date: ${parseDateRange(formik.values.date)}`}
										onDelete={() => {
											formik.setFieldValue('date', [null, null]);
											setResetFilters(true);
										}}
										avatar={
											<div style={{ color: '#fff' }}>
												<DateRange />
											</div>
										}
									/>
								</Grid>
							)}
							{(formik.values.contracts || []).map(contractId => {
								const curContr = availableContracts.find(c => c._id === contractId);
								if (curContr) {
									return (
										<Grid item>
											<Chip
												style={{ backgroundColor: 'green', color: '#fff' }}
												label={
													curContr.contractName
														? ` ${curContr.contractName} - ${contractIdFormatter(curContr._id)}`
														: `${curContr.partner} - ${contractIdFormatter(curContr._id)}`
												}
												onDelete={() => {
													const newContracts = formik.values.contracts.filter(c => c !== contractId);
													setSelectedModalContracts(newContracts);
													setResetFilters(true);
													formik.setFieldValue('contracts', newContracts);
												}}
											/>
										</Grid>
									);
								}
								return null;
							})}
						</Grid>
					)) ||
						null}
				</Grid>
				<Grid item sm={12} md={4} xs={4}>
					<Grid container spacing={1} justify="flex-end">
						{(formHasFilters && (
							<Grid item>
								<Chip
									style={{ backgroundColor: '#FE2712', color: '#fff' }}
									label="Reset all filters"
									onClick={triggerResetFilters}
									avatar={
										<div style={{ color: '#fff' }}>
											<DeleteSweep />
										</div>
									}
								/>
							</Grid>
						)) ||
							null}
						<Grid item>
							<Chip
								color="primary"
								label="Download CSV"
								onClick={triggerDownloadCSV}
								avatar={
									<div style={{ color: '#fff' }}>
										<FileDownload />
									</div>
								}
							/>
						</Grid>
						<Grid item>
							<Chip color="primary" label="Monthly Statements" onClick={() => toggleShowMonthlyStatementsModal()} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{(formik && formik.values && formik.values.transactionType === 'settlement' && (
				<Grid container style={{ marginBottom: '20px' }}>
					<RenderFormikCheckbox label="Exclude Tesspay Fees" name="excludeFees" formik={formik} />
				</Grid>
			)) ||
				''}

			<Dialog
				maxWidth="sm"
				fullWidth
				open={contractsSelectorModalOpen}
				onBackdropClick={() => {
					setSelectedModalContracts(formik.values.contracts || []);
					setContractsSelectorModalOpen(false);
				}}
			>
				<DialogContent>
					<Grid container spacing={1} style={{ marginBottom: '20px' }}>
						<Grid item xs={6}>
							<RenderFormikSelect
								allowClear
								name="customer"
								label="Customer"
								options={((filters && filters.customers) || []).map(option => ({ value: option._id, label: option.participantName }))}
								formik={dummyFormik}
								onChange={() => {
									dummyFormik.setFieldValue('supplier', null);
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<RenderFormikSelect
								allowClear
								name="supplier"
								label="Suppliers"
								options={((filters && filters.suppliers) || []).map(option => ({ value: option._id, label: option.participantName }))}
								formik={dummyFormik}
								onChange={() => {
									dummyFormik.setFieldValue('customer', null);
								}}
							/>
						</Grid>
					</Grid>

					<Grid container alignItems="center" style={{ paddingBottom: 40 }} spacing={1}>
						{(filteredModalContracts || []).map((contract, index) => (
							<Grid item key={index}>
								<Chip
									size="small"
									iconSmall
									label={
										contract.contractName
											? ` ${contract.contractName} - ${contractIdFormatter(contract._id)}`
											: `${contract.partner} - ${contractIdFormatter(contract._id)}`
									}
									onClick={() => triggerAddRemoveContractSelection(contract._id)}
									// onDelete={() => this.triggerAddRemoveSmartContracts(contract._id)}
									color={(selectedModalContracts.indexOf(contract._id) > -1 && 'primary') || undefined}
									icon={(selectedModalContracts.indexOf(contract._id) > -1 && <RadioButtonChecked />) || <RadioButtonUnchecked />}
								/>
							</Grid>
						))}
					</Grid>
				</DialogContent>
				<DialogActions>
					<RenderButton color="default" disabled onClick={triggerAddSmartContractFilters} style={{ color: '#000' }}>
						Adding {selectedModalContracts.length || 0} contracts
					</RenderButton>
					<RenderButton color="primary" onClick={triggerAddSmartContractFilters}>
						Add filters
					</RenderButton>
					<RenderButton
						color="danger"
						onClick={() => {
							setSelectedModalContracts(formik.values.contracts || []);
							setContractsSelectorModalOpen(false);
						}}
					>
						Close
					</RenderButton>
				</DialogActions>
			</Dialog>
			<Dialog open={monthlyStatementsModal} onBackdropClick={toggleShowMonthlyStatementsModal}>
				<DialogContent>
					<Typography variant="h2">Monthly statement</Typography>
					<br />
					<WalletTransactionReportForm
						initialValues={{
							date: moment()
						}}
						onSubmit={triggerWalletReportSubmit}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
});
