import React, { memo, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@material-ui/core';
import { RenderButton, RenderFormikInput } from 'components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

export default memo(({ open, openCloseHandler, initialValues, update = false }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation('reports/reports');
	const formik = useFormik({
		initialValues: {
			templateName: ''
		},
		onSubmit: (values, { resetForm }) => {
			dispatch({
				type: !!update && 'reports/UPDATE_REPORT_TEMPLATE' || 'reports/NEW_REPORT_TEMPLATE',
				payload: values
			});
			resetForm();
			openCloseHandler();
		},
		validationSchema: Yup.object().shape({
			templateName: Yup.string().required(t('AdvancedReportsTab.please_input_help', {label: t('AdvancedReportsTab.template_name')})),
		})
	});

	useEffect(() => {
		formik.resetForm();
	}, [open]);

	useEffect(() => {
		formik.setValues(initialValues);
	}, [initialValues]);

	console.log("initialValues",initialValues)

	return (
		<Dialog maxWidth="sm" open={open} className={styles.container}>
			<DialogTitle>
				{(!update)?t('AdvancedReportsTab.add_new_template') : t('AdvancedReportsTab.update_template')}
			</DialogTitle>
			<DialogContent>
				<form onSubmit={formik.handleSubmit}>
					<Grid container>
						<RenderFormikInput label='Template name' name="templateName" formik={formik} />
					</Grid>
				</form>
			</DialogContent>
			<DialogActions>
				<RenderButton onClick={openCloseHandler} color="transparent">
					{t('cancel')}
				</RenderButton>
				<RenderButton color="primary" onClick={formik.handleSubmit}>
					{t('save')}
				</RenderButton>
			</DialogActions>
		</Dialog>
	);
});
