import React, { memo, useCallback, useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { RenderFormikFileUpload, RatesTable } from 'components';
import { useDispatch, useSelector } from 'react-redux';
// import { RatesTable } from "./components"
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const ExternalRatesTab = memo(({ formik }) => {
	const dispatch = useDispatch();

	const isLoading = useSelector(state => (state.routes && state.routes.loadingHardwareProfileRateUpload) || false);
	const uploadData = useSelector(state => (state.routes && state.routes.hardwareProfileRateUploadData) || false);
	const hardwareProfileRateData = useSelector(state => (state.routes && state.routes.hardwareProfileRateData) || false);
	const { t } = useTranslation('routing/routing');

	const triggerFileUploadChange = useCallback(value => {
		dispatch({
			type: 'routes/UPLOAD_HARDWARE_PROFILE_RATE',
			payload: {
				tempId: formik.values.tempId,
				file: value[0],
				type: 'external'
			}
		});
	});

	const triggerGetExternalRatesData = useCallback((externalProfileId, options) => {
		dispatch({
			type: 'routes/GET_HARDWARE_PROFILE_RATES',
			payload: { externalProfileId, options, type: 'external' }
		});
	});

	useEffect(() => {
		dispatch({
			type: 'routes/SET_STATE',
			payload: {
				// hardwareProfileRateData: false,
				loadingHardwareProfileRateUpload: false,
				hardwareProfileRateUploadData: false
			}
		});
	}, []);

	return (
		<div className={styles.container}>
			<div className="rates-table-wrap">
				<RatesTable
					loadRates={triggerGetExternalRatesData}
					handleProposedRatesAction={() => {
						// noop
					}}
					smartContractId={formik.values._id}
					rates={hardwareProfileRateData.data || []}
					total={hardwareProfileRateData.total || 0}
					requiresActionTotal={0}
					loading={isLoading}
					startOffset={0}
					isSelectable={false}
					showLegend={false}
					hideCustomToolbar
				/>

			</div>
			{(!uploadData && (
				<div>
					<table className="sample-table">
						<tr>
							<th colSpan={7}>{t('sample_file_format')}</th>
						</tr>
						<tr>
							<td>{t('CC')}</td>
							<td>{t('COUNTRY')}</td>
							<td>{t('PEAK')}</td>
							<td>{t('EFF_DATE')}</td>
							<td>{t('END_DATE')}</td>
							<td>{t('INC_INI')}</td>
							<td>{t('INC_ADD')}</td>
						</tr>
					</table>
					<ul className="legend">
						<li>
							<strong>{t('CC')}</strong> - {t('country_code')}
						</li>
						<li>
							<strong>{t('COUNTRY')}</strong> -{t('COUNTRY_DES')}
						</li>
						<li>
							<strong>{t('PEAK')}</strong> - {t('price_rate')}
						</li>
						<li>
						<strong>{t('EFF_DATE')}</strong> - {t('effective_date ')}
						</li>
						<li>
						<strong>{t('END_DATE')}</strong> - {t('end_date')}{' '}
						</li>
						<li>
						<strong>{t('INC_INI')}</strong> - {t('initial_increment')} 
						</li>
						<li>
						<strong>{t('INC_ADD')}</strong> - {t('additional_increment')}
						</li>
					</ul>
				</div>
			)) ||
				''}

			<div className="text-align">
				{(!isLoading && !uploadData && (
					<RenderFormikFileUpload
						formik={formik}
						name="externalRate.file"
						allowedFileTypes={['.csv', '.xlsx', '.xls']}
						btnText={t('upload_rates')}
						onChange={triggerFileUploadChange}
					/>
				)) ||
					''}
				{(isLoading && !uploadData && (
					<div className="upload-loader">
						<CircularProgress color="secondary" size={20} />
						<span>
						{t('uploading')}{' '}
							{(formik.values.externalRate && formik.values.externalRate.file && formik.values.externalRate.file[0] && formik.values.externalRate.file[0].name) ||
								'file'}
						</span>
					</div>
				)) ||
					''}

				{(!isLoading && uploadData && uploadData.status === 'OK' && (
					<div className="upload-status">
						{t('succesfully_uploaded')}{' '}
						{(formik.values.externalRate && formik.values.externalRate.file && formik.values.externalRate.file[0] && formik.values.externalRate.file[0].name) || 'file'}
					</div>
				)) ||
					''}
			</div>
		</div>
	);
});

export default ExternalRatesTab;
