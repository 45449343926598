import React, { Component } from "react";
import { BiddingFunder, Util } from "components";
import PropTypes from "prop-types";

class TableFunders extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <table className="table table-bidding table-responsive table-hover">
        <thead className="thead-default thead-default-bidding">
          <tr>
            <th width="10%">%</th>
            <th width="20%">Period Amount</th>
            <th width="20%">Daily Amount</th>
            <th width="17%">Rating</th>
            <th width="12%">Payment</th>
          </tr>
        </thead>
        <tbody>
          {Util.isEmpty(this.props.funders) ? (
            <tr>
              <td colSpan="5" className="text-center">
                No data available
              </td>
            </tr>
          ) : (
            Object.keys(
              this.props.funders === undefined ? [] : this.props.funders
            ).map(i => (
              <BiddingFunder
                key={"kbf-" + i}
                funder={this.props.funders[i]}
                processAcceptOpenOrder={this.props.processAcceptOpenOrder}
              />
            ))
          )}
        </tbody>
      </table>
    );
  }
}
TableFunders.propTypes = {
  processAcceptOpenOrder: PropTypes.func.isRequired,
  suppliers: PropTypes.array
};
export default TableFunders;
