import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

export default memo(({ userRole = [], permissions, children, redirectTo }) => {
	const dispatch = useDispatch();
	const accountData = useSelector(state => (state.myaccount && state.myaccount.accountData) || false);
	const [operationPermitted, setOperationPermitted] = useState(false);
	const kycApproved = accountData?.kycApproved || false;
	const [loaded, setLoaded] = useState(false);
	useEffect(
		() => () => {
			setLoaded(false);
		},
		[]
	);

	useEffect(() => {
		if (!accountData) {
			dispatch({ type: 'myaccount/GET_ACCOUNT_DETAILS' });
		}
	}, []);

	useEffect(() => {
		let hasPermission = false;

		if (accountData && accountData.permissions) {
			permissions.map(permission => {
				accountData.permissions.map(upermission => {
					// console.log(upermission.toLowerCase(), " - ", permission.toLowerCase(), " ==== ", upermission.toLowerCase().indexOf(permission.toLowerCase()))
					if (upermission.toLowerCase().indexOf(permission.toLowerCase()) > -1) {
						hasPermission = true;
					}
				});
			});
		}

		if (accountData && accountData.primaryAccount && (permissions || []).indexOf('acp_program') === -1) {
			hasPermission = true;
		}

		if (accountData && (permissions || []).indexOf('acp_program') > -1) {
			if (accountData.acpProgram?.active) {
				hasPermission = true;
			}
		}

		if (userRole.length !== 0 && accountData.role && !userRole.includes(accountData.role.toLowerCase())) {
			hasPermission = false;
		}

		if((permissions || []).indexOf('myaccount') === -1){
			hasPermission = kycApproved
		}

		setOperationPermitted(hasPermission);

		setLoaded(true);
	}, [accountData]);












	if (loaded && operationPermitted && accountData && accountData.role) {
		return children;
	}

	if (redirectTo) {
		return <Redirect to={redirectTo} />;
	}

	return null;
});
