const RenderTablePaginationStyle = theme => ({
	selectRoot: {
		marginRight: theme.spacing(1) * 10,
		marginLeft: theme.spacing(1)
	},
	select: {
		width: "auto",
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1) * 2
	},
	selectIcon: {
		top: 1
	},
	input: {
		fontSize: 12
	},
	marginRight: {
		marginRight: theme.spacing(1) * 2
	}
});

export default RenderTablePaginationStyle;
