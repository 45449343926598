import React, { Component } from "react";
import { change } from "redux-form";
import {
  FormHelperText,
  FormControl,
  IconButton,
  InputAdornment
} from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  NavigateBefore,
  NavigateNext
} from "@material-ui/icons";
import moment from "moment-timezone";
import MomentUtils from "@date-io/moment";

class RenderDateTimePicker extends Component {
  incrementDays = e => {
    e.preventDefault();
    e.stopPropagation();
    const {
      input: { value }
    } = this.props;
    const {
      disableFuture,
      input: { name },
      meta: { form, dispatch }
    } = this.props;
    if (
      moment(value).isValid() &&
      (!disableFuture ||
        (disableFuture && moment(value).add("1", "day") <= moment()))
    ) {
      dispatch(change(form, name, moment(value).add("1", "day")));
    }
    return false;
  };

  decrementDays = e => {
    e.preventDefault();
    e.stopPropagation();
    const {
      input: { value }
    } = this.props;
    const {
      disablePast,
      input: { name },
      meta: { form, dispatch }
    } = this.props;
    if (
      moment(value).isValid() &&
      (!disablePast ||
        (disablePast &&
          moment(value)
            .utc()
            .subtract("1", "day") >= moment()))
    ) {
      dispatch(change(form, name, moment(value).subtract("1", "day")));
    }
  };

  render() {
    const {
      input: { onChange, name, value },
      label,
      dateFormat,
      emptyLabel,
      disablePast,
      disableFuture,
      clearable,
      ampm,
      autoOk,
      meta: { touched, error }
    } = this.props;

    const hasError =
      typeof error !== typeof undefined && error.length > 0 && touched;

    const customMoment = moment.tz.setDefault("UTC");
    return (
      <FormControl error={hasError} fullWidth>
        <MuiPickersUtilsProvider utils={MomentUtils} moment={customMoment}>
          <DateTimePicker
            name={name}
            leftArrowIcon={<KeyboardArrowLeft color="primary" />}
            rightArrowIcon={<KeyboardArrowRight color="primary" />}
            ampm={ampm || false}
            label={label}
            format={dateFormat}
            disablePast={disablePast}
            disableFuture={disableFuture}
            autoOk={autoOk || true}
            value={
              value && moment(value).isValid() ? moment(value).utc() : null
            }
            onChange={onChange}
            emptyLabel={emptyLabel}
            clearable={clearable}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" onClick={this.decrementDays}>
                  <IconButton>
                    <NavigateBefore />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" onClick={this.incrementDays}>
                  <IconButton>
                    <NavigateNext />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </MuiPickersUtilsProvider>
        {hasError && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
}

export default RenderDateTimePicker;
