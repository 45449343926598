import { useCallback, memo } from 'react';

import { useDispatch } from 'react-redux';

export default ({ setPage, setFilters }) => {
	const dispatch = useDispatch();

	const triggerTransactionFilterActions = useCallback(filters => {
		setPage(0);
		setFilters(filters);
		if (!filters.startDate && !filters.endDate && !filters.transactionType && !filters.contracts) {
			setFilters('');
		}
	});

	const triggerDownloadCSVActions = useCallback(filters => {
		dispatch({ type: 'transactions/CSV_DOWNLOAD_WALLET_TRANSACTIONS', payload: { filters: JSON.stringify(filters || {}) } });
	});

	const triggerWalletReportSubmit = useCallback(data => {
		dispatch({ type: 'transactions/DOWNLOAD_WALLET_REPORT', payload: data });
	});

	return { triggerTransactionFilterActions, triggerDownloadCSVActions, triggerWalletReportSubmit };
};
