import { initialState } from "./selectors";
import {
  MODAL_INTERCONNECTIONS,
  RESULT_INTERCONNECTION,
  INTERCONNECTIONS,
  MODAL_ACCEPT_INTERCONNECTIONS,
  INTERCONNECT_REQUEST_RESPONSE
} from "./actions";

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case MODAL_INTERCONNECTIONS:
      return {
        ...state,
        [MODAL_INTERCONNECTIONS]: {
          ...payload
        },
      };

    case MODAL_ACCEPT_INTERCONNECTIONS:
      return {
        ...state,
        [MODAL_ACCEPT_INTERCONNECTIONS]: {
          ...payload
        },
      };

    case RESULT_INTERCONNECTION:
      return {
        ...state,
        [RESULT_INTERCONNECTION]: {
          ...payload
        },
      };

    case INTERCONNECTIONS:
      return {
        ...state,
        [INTERCONNECTIONS]: {
          ...payload
        },
      };
    case INTERCONNECT_REQUEST_RESPONSE:
      return {
        ...state,
        [INTERCONNECT_REQUEST_RESPONSE]: {
          ...payload
        },
      };

    default:
      return state;
  }
};
