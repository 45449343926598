import React, { useContext, useState } from 'react';
import { Dialog, DialogContent, DialogActions, Slide, Typography, CircularProgress, Button, Badge, Tooltip } from '@material-ui/core';
import { FinancialContext } from 'screens/Financials';
import { MonthlyInvoice, RenderButton, Invoice, ExternalInvoice, Loader } from 'components';
import ReactToPrint from 'react-to-print';
import useGetModalInfo from './hooks/useGetModalInfo';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default () => {
	const { modalVisible, setModalVisible, toggleInvoiceModal, selectedInvoice, selectedInvoiceType, clientType, isOriginationInvoice, t } = useContext(FinancialContext);
	const { totalAvailable, isDownloading, amountDue, isMaster, paymentStatus, paymentInfo, handlePayInvoice, handleInvoiceDownload } = useGetModalInfo(
		selectedInvoice,
		setModalVisible
	);
	const [ref, setRef] = useState(null);

	const invoicesTypes = {
		monthly: (
			<MonthlyInvoice
				ref={el => {
					setRef(el);
				}}
				data={selectedInvoice}
			/>
		),
		daily: (
			<Invoice
				ref={el => {
					setRef(el);
				}}
				data={selectedInvoice}
			/>
		),
		external: (
			<ExternalInvoice
				ref={el => {
					setRef(el);
				}}
				data={selectedInvoice}
			/>
		)
	};

	return (
		<React.Fragment>
			{paymentInfo?.status === 'inProgress' && <Loader loading fullscreen overlay />}
			<Dialog sx={{ overflow: 'hidden' }} maxWidth="md" fullWidth open={modalVisible} onClose={() => setModalVisible(!modalVisible)} Transition={Transition}>
				<DialogContent>{invoicesTypes[selectedInvoiceType]}</DialogContent>
				<DialogActions>
					<RenderButton color="primary" onClick={toggleInvoiceModal}>
						{t('translations:close')}
					</RenderButton>

					<ReactToPrint trigger={() => <RenderButton color="primary">{t('translations:print')}</RenderButton>} content={() => ref} />

					{selectedInvoiceType === 'daily' && (
						<RenderButton color="primary" onClick={handleInvoiceDownload} disabled={isDownloading}>
							{isDownloading ? (
								<Typography variant="body1" align="center">
									<CircularProgress color="secondary" size={15} />
									<span>&nbsp;&nbsp;&nbsp;{t('translations:downloading')}</span>
								</Typography>
							) : (
								t('translations:download')
							)}
						</RenderButton>
					)}

					{isMaster && clientType === 'supplier' && isOriginationInvoice ? (
						paymentStatus === 'opened' ? (
						<Tooltip title="Invoice still opened">
							<Badge badgeContent={'!'} color="error" style={{ marginRight: 10 }}>
								<RenderButton disabled color="primary" onClick={handlePayInvoice}>
									{t('translations:pay_invoice')}
								</RenderButton>
							</Badge>
						</Tooltip> 
						) : paymentStatus === 'paid' ? (
							<RenderButton disabled color="primary" onClick={handlePayInvoice}>
								{t('translations:invoice_paid')}
							</RenderButton>
						) : totalAvailable < amountDue ? (
							<Tooltip title="Insufficient Funds">
								<Badge badgeContent={'!'} color="error" style={{ marginRight: 10 }}>
									<RenderButton disabled color="primary" onClick={handlePayInvoice}>
										{t('translations:pay_invoice')}
									</RenderButton>
								</Badge>
							</Tooltip>
						) : paymentStatus !== 'due' ? (
							<Tooltip title="Can't pay this invoice">
								<Badge badgeContent={'!'} color="error" style={{ marginRight: 10 }}>
									<RenderButton disabled color="primary" onClick={handlePayInvoice}>
										{t('translations:pay_invoice')}
									</RenderButton>
								</Badge>
							</Tooltip>
						) : ( <RenderButton color="primary" onClick={handlePayInvoice}>
								{t('translations:pay_invoice')}
							</RenderButton>
						)
					) : null}
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};
