import React from 'react';
import { FormControl, FormHelperText } from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import RenderRadio from 'components';
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import radioStyle from "./style";

const RenderFormikRadioGroup = ({ formik: { values, errors, touched, setFieldValue }, name, options, onChange, children, classes, inline, ...rest }) => {
	const hasError = (errors[name] && touched[name] && true) || false;
	let value = values[name] || '';
	if (name.indexOf('.') > -1) {
		value = name.split('.').reduce((o, i) => (o && o[i]) || '', values);
	}
	return (
		<FormControl fullWidth error={hasError}>
			<RadioGroup
				name={name}
				// onBlur={handleBlur}
				// fullWidth
				className={inline && classes.inline}
				value={value}
				onChange={e => {
					setFieldValue(name, e.target.value);
					if (onChange && typeof onChange === 'function') onChange(e);
				}}
			>
				{children}
			</RadioGroup>
			{hasError && <FormHelperText>{(!!errors[name] && errors[name]) || ''}</FormHelperText>}
		</FormControl>
	);
};

RenderFormikRadioGroup.defaultProps = {
	inline: true
};

RenderFormikRadioGroup.propTypes = {
	classes: PropTypes.shape().isRequired,
	inline: PropTypes.bool
};

export default withStyles(radioStyle)(RenderFormikRadioGroup);
