import PropTypes from 'prop-types';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import invoiceFooterStyle from './style';

const CustomRow = ({ firstCellContent, secondCellContent, thirdCellContent, classes }) => (
	<TableRow className={classes.tableRow}>
		<TableCell className={[classes.w200, classes.noBorderBottom].join(' ')}>{firstCellContent}</TableCell>
		<TableCell className={[classes.w300, classes.noBorderBottom].join(' ')}>{secondCellContent}</TableCell>
		<TableCell className={[classes.noBorderBottom, classes.w300].join(' ')}>{thirdCellContent}</TableCell>
	</TableRow>
);

CustomRow.defaultProps = {
	firstCellContent: '',
	secondCellContent: '',
	thirdCellContent: ''
};

CustomRow.propTypes = {
	classes: PropTypes.shape().isRequired,
	firstCellContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	secondCellContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	thirdCellContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

const BankDetailRow = ({ name, value, classes }) => (
	<CustomRow secondCellContent={<Typography variant="body1">{name}</Typography>} thirdCellContent={<Typography variant="body1">{value}</Typography>} classes={classes} />
);

BankDetailRow.defaultProps = {
	name: '',
	value: ''
};

BankDetailRow.propTypes = {
	classes: PropTypes.shape().isRequired,
	name: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	value: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

const InvoiceFooter = props => {
	const { bankData, classes, isStandard, customData } = props;
	const { t } = useTranslation('financials/general');

	const data = bankData || {
		name: 'TessPay Inc.',
		addressLine1: '9160 Forum Corporate Parkway, Suite 350',
		addressLine2: 'Fort Myers, FL 33905, USA',
		bank: {
			name: 'Northern Trust',
			contactName: '',
			addressLine1: '7960 Summerlin Lakes Drive',
			addressLine2: 'Fort Myers, FL 33908, United States',

			beneficiaryName: 'TessPay Inc.',
			beneficiaryAccountNumber: '2841135275',
			ffCreditTo: 'Default For Further Credit to',
			ABA: '066009650',
			accountNumber: '066009650',
			swiftCode: 'CNORUS44'
		},
		paypal: {
			email: 'paypal@tesspay.io'
		}
	};
	return (
		<React.Fragment>
			{isStandard ? (
				<Typography variant="body2">{t('invoices.settled_automatically')}</Typography>
			) : (
				<React.Fragment>
					{!!customData && !!customData.customInvoiceText && <Typography variant="body2">{customData.customInvoiceText}</Typography>}
					<Typography variant="body2">{t('invoices.payment_wire_instructions')}:</Typography>
					<Table className={classes.table} padding="dense">
						<TableBody>
							<TableRow className={classes.tableRow}>
								<TableCell className={[classes.w300, classes.noBorderBottom].join(' ')} />
								<TableCell className={[classes.w300, classes.noBorderBottom].join(' ')}>
									<Typography variant="body2">{t('invoices.wire_info')}:</Typography>
								</TableCell>
							</TableRow>

							<CustomRow secondCellContent={<Typography variant="body1">{data.bankDetails?.name}</Typography>} classes={classes} />
							<CustomRow secondCellContent={<Typography variant="body1">{data.bankDetails.addressLine1}</Typography>} classes={classes} />
							<CustomRow secondCellContent={<Typography variant="body1">{data.bankDetails.addressLine2}</Typography>} classes={classes} />

							<BankDetailRow name={t('bank.benef_name')} value={data.beneficiaryDetails.name} classes={classes} />
							<BankDetailRow name={t('bank.benef_address')} value={`${data.beneficiaryDetails.addressLine1}, ${data.beneficiaryDetails.addressLine2}`} classes={classes} />
							<BankDetailRow name={t('bank.benef_acc_no')} value={data.bank.beneficiaryAccountNumber} classes={classes} />

							<BankDetailRow name={t('bank.bank_aba')} value={data.bank.ABA} classes={classes} />
							<BankDetailRow name={t('bank.bank_ach')} value={data.bank.accountNumber} classes={classes} />
							<BankDetailRow name={t('bank.bank_swift')} value={data.bank.swiftCode} classes={classes} />
							<BankDetailRow name={t('bank.paypal')} value={data.paypal.email} classes={classes} />
						</TableBody>
					</Table>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

InvoiceFooter.defaultProps = {
	bankData: null,
	isStandard: false
};

InvoiceFooter.propTypes = {
	classes: PropTypes.shape().isRequired,
	bankData: PropTypes.shape(),
	isStandard: PropTypes.bool
};

export default withStyles(invoiceFooterStyle)(InvoiceFooter);
