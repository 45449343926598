import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PrivateMenu } from 'components';
import { fromPrivatemenu, fromUseraccount } from 'store/selectors';
import { actionCreatorSelectItem, actionCreatorGetMenuState, actionCreatorGetMenuSectionState } from 'store/actions';
import PropTypes from 'prop-types';

class PrivateMenuContainer extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			isOpen: this.props.menuState,
			sections: this.props.menuSections
		};
	}

	getItemValue = value => {
		const { actionCreatorSelectItem } = this.props;

		actionCreatorSelectItem(value);
	};

	getMState = () => {
		const { actionCreatorGetMenuState } = this.props;
		let { isOpen } = this.state;
		isOpen = !isOpen;
		this.setState({... this.state, isOpen });
		actionCreatorGetMenuState(isOpen);
	};

	getSection = section =>{
		const { actionCreatorGetMenuSectionState } = this.props;
		this.setState({ ... this.state, sections: { ...this.state.sections, ...section }  })
		actionCreatorGetMenuSectionState(section)
	}

	render() {
		return (
			<PrivateMenu
				getItemValue={this.getItemValue}
				selectedMenuItem={this.props.selectedMenuItem}
				handleDrawerToggle={this.getMState}
				menuOpen={this.state.isOpen}
				role={this.props.role}
				getSection={this.getSection}
				menuSections={this.props.menuSections}
			/>
		);
	}
}

function mapStateToProps(state) {
	return {
		selectedMenuItem: fromPrivatemenu.getSelectedMenuItem(state),
		menuSections: fromPrivatemenu.getMenuSectionsState(state),
		menuState: fromPrivatemenu.getMenuState(state),
		role: fromUseraccount.getUserRol(state)
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			actionCreatorSelectItem,
			actionCreatorGetMenuState,
			actionCreatorGetMenuSectionState
		},
		dispatch
	);
}

PrivateMenuContainer.propTypes = {
	actionCreatorSelectItem: PropTypes.func.isRequired,
	selectedMenuItem: PropTypes.string,
	menuState: PropTypes.bool,
	getSection: PropTypes.func
};

PrivateMenuContainer.defaultProps = {
	selectedMenuItem: '',
	menuState: true
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateMenuContainer);
