const styles = {
	orderButtons: {
		root: {
			border: 'none !important',
			'&after': {
				display: 'none !important'
			}
		}
	},
	fieldTitle: { color: '#666', fontSize: '12px', lineHeight: '18px', fontWeight: 'normal', margin: '0', padding: '0' }
};

export default styles;
