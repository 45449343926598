import React, { Component } from "react";
import { connect } from "react-redux";

import { SmartContractDestinationCode } from "components";

class SmartContractDestinationCodeContainer extends Component {
  componentWillMount() {
    console.log(
      "==========SmartContractDestinationCodeContainer====componentWillMount"
    );
    const { fields } = this.props;
    if (fields.length === 0) fields.push({});
  }

  componentWillReceiveProps(nextProps) {
    const { dialCodesSelected, codeRowIndexSelected, change } = this.props;

    if (dialCodesSelected !== nextProps.dialCodesSelected) {
      const valueCodes =
        nextProps.dialCodesSelected[codeRowIndexSelected] != undefined
          ? nextProps.dialCodesSelected[codeRowIndexSelected]
              .filter(codeDial => codeDial.selected !== false)
              .map(codeDial => codeDial.id)
              .toString()
          : "";
      change(
        `members[${codeRowIndexSelected}].smartContractOfferCodes`,
        valueCodes
      );
      console.log(
        ` change members[${codeRowIndexSelected}].smartContractOfferCodes=${valueCodes}`
      );
    }
  }

  render() {
    return <SmartContractDestinationCode {...this.props} />;
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmartContractDestinationCodeContainer);
