import {
  initialRecoveryPasswordData,
  getRecoveryPasswordDataState,
  RECOVERY_PASSWORD_DATA
} from "./selectors";
import {
  RECOVERY_PASSWORD_REQUEST,
  RECOVERY_PASSWORD_FAILURE,
  RECOVERY_PASSWORD_SUCCESS,
  CLEAN_DATA_RECOVERY_PASSWORD
} from "./actions";

export default (state = initialRecoveryPasswordData, { type, payload }) => {
  switch (type) {
    case RECOVERY_PASSWORD_REQUEST:
      return {
        ...state,
        [RECOVERY_PASSWORD_DATA]: {
          ...getRecoveryPasswordDataState(state),
          ...payload
        }
      };
    case RECOVERY_PASSWORD_FAILURE:
      return {
        ...state,
        [RECOVERY_PASSWORD_DATA]: {
          successMessage: null,
          errorMessage: payload
        }
      };
    case RECOVERY_PASSWORD_SUCCESS:
      return {
        ...state,
        [RECOVERY_PASSWORD_DATA]: {
          ...initialRecoveryPasswordData,
          successMessage: payload
        }
      };
    case CLEAN_DATA_RECOVERY_PASSWORD:
      return {
        ...state,
        [RECOVERY_PASSWORD_DATA]: {
          ...initialRecoveryPasswordData
        }
      };
    default:
      return state;
  }
};
