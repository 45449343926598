import {
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Typography
} from "@material-ui/core";
import { Invoice, RenderButton } from "components";
import React, { Component } from "react";

import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import ReactToPrint from "react-to-print";
import moment from "moment";

const Transition = props => <Slide direction="up" {...props} />;

class InvoiceListTable extends Component {
  actionFormatter(row) {
    const { toggleInvoiceModal } = this.props;
    return (
      <RenderButton color="primary" onClick={() => toggleInvoiceModal(row)}>
        View
      </RenderButton>
    );
  }

  render() {
    const {
      isInvoiceModalVisible,
      toggleInvoiceModal,
      selectedInvoiceData,
      invoices
    } = this.props;
    // const {
    //   modalAllocateSell,
    //   toggleAllocateSell,
    //   saveAllocateSell,
    //   modalAllocateBuy,
    //   toggleAllocateBuy,
    //   saveAllocateBuy,
    //   listSmartContracts,
    //   isFunder
    // } = this.props;

    const tableColumns = [
      {
        name: "No.",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "Invoice Date",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "Action",
        options: {
          filter: false,
          sort: false
        }
      }
    ];

    const tableData = [];

    invoices.forEach(row => {
      tableData.push([
        row.invoice.INV_NUMBER,
        moment(row.invoice.DATE_END).format("MMM DD YYYY"),
        this.actionFormatter(row)
      ]);
    });

    const tableOptions = {
      selectableRows: false,
      filter: false,
      print: false,
      download: false,
      viewColumns: false,
      responsive: "scroll"
    };

    const showNotification = data => {
      if (!data || !data.length) {
        return (
          <Typography variant="title" align="center">
            No data
          </Typography>
        );
      }
      return false;
    };

    return (
      <div>
        {showNotification(invoices) || (
          <div>
            <MUIDataTable
              title=""
              data={tableData}
              columns={tableColumns}
              options={tableOptions}
            />

            <Dialog
              maxWidth="md"
              fullWidth
              open={isInvoiceModalVisible}
              onBackdropClick={toggleInvoiceModal}
              TransitionComponent={Transition}
            >
              <DialogContent>
                <Invoice
                  ref={el => {
                    this.componentRef = el;
                  }}
                  data={selectedInvoiceData}
                />
              </DialogContent>
              <DialogActions>
                <RenderButton color="primary" onClick={toggleInvoiceModal}>
                  Close
                </RenderButton>
                <ReactToPrint
                  trigger={() => (
                    <RenderButton color="primary">Print</RenderButton>
                  )}
                  content={() => this.componentRef}
                />
              </DialogActions>
            </Dialog>
          </div>
        )}
      </div>
    );
  }
}

InvoiceListTable.defaultProps = {
  selectedInvoiceData: {}
};

InvoiceListTable.propTypes = {
  selectedInvoiceData: PropTypes.shape(),
  isInvoiceModalVisible: PropTypes.bool.isRequired,
  toggleInvoiceModal: PropTypes.func.isRequired,
  invoices: PropTypes.instanceOf(Array).isRequired
};

export default InvoiceListTable;
