const walletStyle = theme => ({
  toggleContainer: {
    display: "none",
    "&.active": {
      display: "block"
    }
  }
});

export default walletStyle;
