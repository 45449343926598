import React from "react";
import TextField from "@material-ui/core/TextField";

const FormikInput = ({
                       field,
                       label,
                       value,
                       defaultValue,
                       margin,
                       form: { touched, errors },
                       min,
                       max,
                       customError,
                       ...rest
                     }) => {
  const custom = {
    ...rest,
    inputProps: {
      min,
      max
    }
  };
  const hasError = (touched[field.name] && errors[field.name]) || false;
  return (
    <TextField
      {...field}
      fullWidth
      margin={margin}
      label={label}
      helperText={hasError ? error || customError : ""}
      error={hasError}
      InputLabelProps={field.value && { shrink: true } || {}}
      {...custom}
    />
  );
};

FormikInput.defaultProps = {
  margin: "none"
};

export default FormikInput;
