const actions = {
	GET_ACP_CUSTOMER_CLAIM: 'acp/GET_ACP_CUSTOMER_CLAIM',
	UPLOAD_NEW_CLAIM: 'acp/UPLOAD_NEW_CLAIM',
	DELETE_ACP_CLAIM: 'acp/DELETE_ACP_CLAIM',

	DOWNLOAD_ACP_CLAIM: 'acp/DOWNLOAD_ACP_CLAIM',
	DOWNLOAD_NLAD_SUBMISSION: 'acp/DOWNLOAD_NLAD_SUBMISSION',
	DOWNLOAD_CLAIMABLE_DEVICE_TEMPLATE: 'acp/DOWNLOAD_CLAIMABLE_DEVICE_TEMPLATE',
	DOWNLOAD_MRC_SALES_TEMPLATE: 'acp/DOWNLOAD_MRC_SALES_TEMPLATE',

	ARCHIVE_NLAD_SUBMISSION: 'acp/ARCHIVE_NLAD_SUBMISSION',
	UNARCHIVE_NLAD_SUBMISSION: 'acp/UNARCHIVE_NLAD_SUBMISSION',
	GET_ARCHIVE_SETTINGS: 'acp/GET_ARCHIVE_SETTINGS',
	UPDATE_ARCHIVE_SETTINGS: 'acp/UPDATE_ARCHIVE_SETTINGS',
	GET_NLAD_SUBMISSIONS: 'acp/GET_NLAD_SUBMISSIONS',
	GET_PRO_FORMA_CLAIMS: 'acp/GET_PRO_FORMA_CLAIMS',
	GET_PRO_FORMA_CLAIMS_PARTICIPANT_INFO: 'acp/GET_PRO_FORMA_CLAIMS_PARTICIPANT_INFO',

	GET_NEW_PRO_FORMA_CLAIMS: 'acp/GET_NEW_PRO_FORMA_CLAIMS',

	GET_PAYMENTS: 'acp/GET_PAYMENTS',
	GET_STATEMENTS: 'acp/GET_STATEMENTS',
	GET_ACP_INVOICES: 'acp/GET_ACP_INVOICES',
	GET_ACP_INVOICE: 'acp/GET_ACP_INVOICE',

	SET_STATE: 'acp/SET_STATE'
};

export default actions;

export const ACP_CLAIM_DOWNLOAD_COMPLETED = 'ACP_CLAIM_DOWNLOAD_COMPLETED';
export const acpClaimDownloadCompleted = () => ({
	type: ACP_CLAIM_DOWNLOAD_COMPLETED,
	payload: null
});

export const ACP_NLAD_SUBMISSION_DOWNLOAD_COMPLETED = 'ACP_NLAD_SUBMISSION_DOWNLOAD_COMPLETED';
export const acpNladSubmissionDownloadCompleted = () => ({
	type: ACP_NLAD_SUBMISSION_DOWNLOAD_COMPLETED,
	payload: null
});
