import colors from 'assets/variables/colors';

export const containerStyle = thread => {
	const style = { maxWidth: '90%', marginTop: 0, marginBottom: 10, display: 'flex' };

	if (thread.direction === 'in') {
		style.marginRight = 'auto';
	} else {
		style.marginLeft = 'auto';
	}

	return style;
};

export const bubbleStyle = ({ thread }) => {
	const style = { width: '100%', position: 'relative' };

	if (thread.direction === 'in') {
		style.right = '65px';
	} else {
		style.left = '65px';
		style.backgroundColor = colors.primary.main;
		style.color = 'white';
	}

	return style;
};

export const triangleStyle = (thread, position) => {
	const style = {};

	if (thread) {
		if ((thread.direction === 'in' && position === 'before') || (thread.direction === 'out' && position === 'after') || (!!!thread.direction && position === 'after')) {
			style.display = 'block';
		} else {
			style.display = 'none';
		}
	}

	return style;
};

export const dateStyle = thread => {
	return {
		margin: 0,
		...(thread && thread.direction === 'in' && { alignSelf: 'flex-start' }),
		...(thread && thread.direction === 'out' && { alignSelf: 'flex-end' })
	};
};
