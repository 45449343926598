import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import { PublicTemplate } from "components";
import { RegisterFormContainer } from "containers";

import {Grid} from "@material-ui/core";
import registerStyle from "./style";

const RegisterPage = ({...props}) => {
  const { classes } = props;
  return (
    <PublicTemplate>
      <Grid container justify="center" alignItems="center" className={classes.content}>
          <Grid item>
            <RegisterFormContainer />
          </Grid>
      </Grid>
  </PublicTemplate>
  );
};

export default withStyles(registerStyle)(RegisterPage);
