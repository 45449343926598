import React, { Component } from 'react';
import { reset } from 'redux-form';
import { BankAccountForm, RenderButton, CreateBankAccountForm, ConfirmDeleteDialog } from 'components';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Paper, CardContent, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class BankAccountsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: null,
			deleteConfirmationOpen: false,
			confirmDeleteOpen: false
		};
	}

	handleChange = panel => (event, expanded) => {
		this.setState({
			expanded: expanded ? panel : false
		});
		reset(`BankAccountForm-${panel}`);
	};

	deleteBankAccountConfirm = () => {
		const { deleteConfirmationOpen } = this.state;
		this.setState({ deleteConfirmationOpen: !deleteConfirmationOpen });
	};

	handleBankAccountDelete = data => {
		const { deleteBankAccountSubmit } = this.props;
		deleteBankAccountSubmit(data);
		this.deleteBankAccountConfirm();
	};

	deleteConfirmationHandler = () => {
		this.setState({ confirmDeleteOpen: !this.state.confirmDeleteOpen });
	};

	deleteBankAccountHandler = data => {
		const { deleteBankAccountSubmit } = this.props;
		deleteBankAccountSubmit(data);
		this.deleteConfirmationHandler();
	};

	render() {
		const {
			bankAccountData,
			updateBankAccountSubmit,
			deleteBankAccountSubmit,
			newBankAccountSubmit,
			bankAccountFormName,
			setNewBankAccountStateForm,
			newBankAccountStateForm,
			countriesData,
			bankStatesDataArray,
			beneficiaryStatesDataArray,
			correspondentStatesDataArray,
			getBankStatesData,
			getBeneficiaryStatesData,
			getCorrespondentStatesData,
			generalInformationData
		} = this.props;
		const { expanded, bankingModalOpen, confirmDeleteOpen } = this.state;

		const renderExplorerContentItems = Object.values(bankAccountData).map((item, i) => (
			<div key={i}>
				<Accordion expanded={expanded === item._id} onChange={this.handleChange(item._id)}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ maxWidth: '90%' }}>
						<span style={{ flexGrow: 1 }}>
							{i + 1} - {item.bankNickname ? item.bankNickname : item.bankName}
						</span>
					</AccordionSummary>
					<RenderButton onClick={() => this.deleteConfirmationHandler(item._id)} color="danger" style={{ position: 'absolute', top: 8, right: 0 }}>
						Delete
					</RenderButton>
					<AccordionDetails>
						<BankAccountForm
							initialValues={item}
							onSubmit={updateBankAccountSubmit}
							deleteBankAccountSubmit={deleteBankAccountSubmit}
							form={`BankAccountForm-${item._id}`}
							bankStatesDataArray={bankStatesDataArray}
							beneficiaryStatesDataArray={beneficiaryStatesDataArray}
							correspondentStatesDataArray={correspondentStatesDataArray}
							countriesData={countriesData}
							handleChange={this.handleChange}
							index={i}
							getBankStatesData={getBankStatesData}
							getBeneficiaryStatesData={getBeneficiaryStatesData}
							getCorrespondentStatesData={getCorrespondentStatesData}
							generalInformationData={generalInformationData}
						/>
					</AccordionDetails>
				</Accordion>

				<ConfirmDeleteDialog open={confirmDeleteOpen} initialValues={item} openCloseHandler={this.deleteConfirmationHandler} handleSubmit={this.deleteBankAccountHandler} />
			</div>
		));
		return (
			<div>
				<Paper>
					<CardContent style={{ display: 'flex' }}>
						<Typography variant="h1" style={{ marginRight: 16 }}>
							Banking
						</Typography>
						<RenderButton color="primary" onClick={setNewBankAccountStateForm}>
							New bank
						</RenderButton>
					</CardContent>
					{newBankAccountStateForm && (
						<CardContent>
							<CreateBankAccountForm
								onSubmit={newBankAccountSubmit}
								form={bankAccountFormName}
								setNewBankAccountStateForm={setNewBankAccountStateForm}
								bankStatesDataArray={bankStatesDataArray}
								beneficiaryStatesDataArray={beneficiaryStatesDataArray}
								correspondentStatesDataArray={correspondentStatesDataArray}
								countriesData={countriesData}
								getBankStatesData={getBankStatesData}
								getBeneficiaryStatesData={getBeneficiaryStatesData}
								getCorrespondentStatesData={getCorrespondentStatesData}
								generalInformationData={generalInformationData}
							/>
						</CardContent>
					)}
				</Paper>
				{bankAccountData !== undefined && bankAccountData !== null && Object.values(bankAccountData).length > 0 && renderExplorerContentItems}
			</div>
		);
	}
}

export default BankAccountsList;
