import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {Paper, Grid, CardContent} from '@material-ui/core';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { RenderTable } from 'components';
import { contractIdFormatter } from '../../../../../utils/index';

export default memo(() => {
	const dispatch = useDispatch();

	const loading = useSelector(state => (state && state.reports && state.reports.loadingPartitionActiveCalls) || false);

	const partitionStats = useSelector(state => (state && state.reports && state.reports.partitionStats) || { sessionsKpi: [], sessionsActive: [] });
	const sessionsKpi = partitionStats && partitionStats.sessionsKpi || [];
	const sessionsActive = partitionStats && partitionStats.sessionsActive || [];
	const partitionActiveCalls = useSelector(state => (state && state.reports && state.reports.partitionActiveCalls) || []);

	useEffect(() => {
		dispatch({ type: 'reports/GET_DASHBOARD_PARTITION_STATS' });
		dispatch({ type: 'reports/GET_DASHBOARD_PARTITION_ACTIVE_CALLS' });
	}, []);

	setTimeout(() => {
		dispatch({ type: 'reports/GET_DASHBOARD_PARTITION_STATS' });
	},5000)


	const columns = [
		{
			Header: 'Invite Time',
			accessor: 'inviteTime'
		},
		{
			Header: 'Answer Time',
			accessor: 'answerTime'
		},
		{
			Header: 'Contract Name',
			id: row=> row.contractId,
			accessor: row=>row.name? `${row.name} (${contractIdFormatter(row.contractId)})`: contractIdFormatter(row.contractId)
		},
		{
			Header: 'Partner',
			accessor: 'partner'
		},
		{
			Header: 'Calling Party',
			accessor: 'callingParty'
		},
		{
			Header: 'Called Party',
			accessor: 'calledParty'
		},
		{
			Header: 'Duration',
			accessor: 'duration'
		}
	];

	return (
		<div>
			<Grid container spacing={2}>
				<Grid
					item
					xs={6}
				>
					<Paper>
						<CardContent>
							<h6 style={{ marginBottom: '10px' }}>Sessions KPI</h6>
							<ResponsiveContainer minHeight={"300px"}>
								<LineChart width={500} height={300} data={sessionsKpi}>
									<Line name="In Attempts" type="monotone" dataKey="inAttempts" stroke="#ff2800" strokeWidth={2} dot={false} />
									<Line name="Successfull" type="monotone" dataKey="successful" stroke="#006F62" strokeWidth={2} dot={false} />
									<CartesianGrid stroke="#efefef" />
									<XAxis dataKey="time" />
									<YAxis />
									<Tooltip />
									<Legend />
								</LineChart>
							</ResponsiveContainer>
						</CardContent>
					</Paper>
				</Grid>

				<Grid
					item
					xs={6}
				>
					<Paper>
						<CardContent>
							<h6 style={{ marginBottom: '10px' }}>Active sessions</h6>
							<ResponsiveContainer minHeight="300px">
								<LineChart width={500} height={300} data={sessionsActive}>
									<Line name="Signaling" type="monotone" dataKey="signaling" stroke="#05a1e0" strokeWidth={2} dot={false}  />
									<Line name="In Progress" type="monotone" dataKey="inProgress" stroke="#2e3642" strokeWidth={2} dot={false}  />
									<Line name="Media" type="monotone" dataKey="media" stroke="#E21B4D" strokeWidth={2} dot={false}  />
									<Line name="Transcoded" type="monotone" dataKey="transcoded" stroke="#F48037" strokeWidth={2} dot={false}  />
									<CartesianGrid stroke="#efefef" />
									<XAxis dataKey="time" />
									<YAxis />
									<Tooltip />
									<Legend />
								</LineChart>
							</ResponsiveContainer>
						</CardContent>
					</Paper>
				</Grid>
				<Grid
					item
					xs={12}
				>
					<Paper>
						<CardContent>
							<h6 style={{ marginBottom: '10px' }}>Last 20 calls</h6>
							<RenderTable
								data={partitionActiveCalls || []}
								minRows={2}
								loading={loading}
								columns={columns}
							/>
						</CardContent>
					</Paper>
				</Grid>
			</Grid>

		</div>
	);
});
