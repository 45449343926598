import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Grid, Paper, Typography, Chip, Tooltip } from '@material-ui/core';
import _, { isEmpty } from 'lodash';
import moment from 'moment-timezone';

import RenderTable from 'components/atoms/RenderTable';
import Loader from 'components/atoms/Loader';
import CurrencyNumber from 'components/atoms/CurrencyNumber';

import actions from 'store/acp/actions';
import styles from './styles.module.scss';

export default memo(() => {
	const dispatch = useDispatch();

	const isLoading = useSelector(state => (state.acp && state.acp.loading) || false);
	const statements = useSelector(state => (state.acp && state.acp?.statements) || []);

	const [filteredStatements, setFilteredStatements] = useState([]);

	const DateFilter = ({ filter, onChange, type = 'date' }) => (
		<TextField
			type={type}
			value={filter ? filter.value : ''}
			onChange={e => onChange(e.target.value)}
			style={{ width: '80%' }}
			variant="standard"
			InputLabelProps={{
				shrink: true
			}}
		/>
	);

	const columns = [
		{
			Header: 'Date',
			accessor: 'date',
			Cell: cell => {
				return <div>{moment(cell.original?.date).format('LL') ?? ''}</div>;
			},
			width: 200,
			Filter: ({ filter, onChange }) => <DateFilter filter={filter} onChange={onChange} type="date" />
		},
		{
			Header: 'Claim Period',
			accessor: 'claimPeriod',
			Cell: cell => {
				return <div>{moment(cell.original?.claimPeriod).format('MMMM YYYY') ?? ''}</div>;
			},
			width: 150,
			Filter: ({ filter, onChange }) => <DateFilter filter={filter} onChange={onChange} type="month" />
		},
		{
			Header: 'Claim Numbers',
			accessor: 'claimNumber',
			Cell: cell => {
				return <span className={styles.claimNumers}>{cell.original?.claimNumber.join(', ')}</span>;
			},
			width: 250,
			Filter: ({ filter, onChange }) => <TextField value={filter ? filter.value : ''} onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard" />
		},
		{
			Header: <div className="number-column">Claim Amount</div>,
			accessor: 'proFormaClaimValue',
			Cell: cell => {
				return <div className="number-column">{cell.original?.type === 'proFormaClaim' && <CurrencyNumber amount={cell.original?.value} precision={0} />}</div>;
			},
			width: 200,
			Filter: ({ filter, onChange }) => <TextField value={filter ? filter.value : ''} onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard" />
		},
		{
			Header: <div className="number-column">Payment Amount</div>,
			accessor: 'paymentValue',
			Cell: cell => {
				return (
					<div className="number-column">
						{cell.original?.type === 'payment' && (
							<CurrencyNumber
								style={{
									color: cell.original?.runningBalance < 0 ? 'red' : 'green'
								}}
								amount={-cell.original?.value}
								precision={0}
							/>
						)}
					</div>
				);
			},
			width: 200,
			Filter: ({ filter, onChange }) => <TextField value={filter ? filter.value : ''} onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard" />
		},
		{
			Header: <div className="number-column">Running Balance</div>,
			accessor: 'runningBalance',
			Cell: cell => {
				return (
					<div
						className="number-column"
						style={{
							color: cell.original?.runningBalance < 0 ? 'red' : 'green'
						}}
					>
						<CurrencyNumber amount={cell.original?.runningBalance} precision={0} />
					</div>
				);
			},
			width: 200,
			Filter: ({ filter, onChange }) => <TextField value={filter ? filter.value : ''} onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard" />
		}
	];

	useEffect(() => {
		dispatch({ type: actions.GET_STATEMENTS });
	}, []);

	useEffect(() => {
		let newStatements = [];
		statements.forEach(statement => {
			if (statement.type === 'proFormaClaim') {
				newStatements.push({
					...statement,
					proFormaClaimValue: statement.value,
					paymentValue: 0
				});
			} else if (statement.type === 'payment') {
				newStatements.push({
					...statement,
					paymentValue: statement.value,
					proFormaClaimValue: 0
				});
			}
		});
		setFilteredStatements(newStatements);
	}, [statements]);

	return (
		<div className={styles.container}>
			<Paper>
				<Grid container className="grid-container">
					<Grid item xs={12}>
						<Typography variant="h1" gutterBottom>
							Statements
						</Typography>
					</Grid>
					<Grid item xs={12}>
						{(isLoading && <Loader isLoading />) || (
							<RenderTable
								data={filteredStatements && filteredStatements.length > 0 ? filteredStatements : []}
								loading={isLoading}
								minRows={2}
								columns={columns}
								sortable={true}
								filterable={!isLoading}
							/>
						)}
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
});
