import React from "react";
import PropTypes from "prop-types";
import ReactHighcharts from "react-highcharts";

const RenderChart = ({ data }) => <ReactHighcharts config={data} />;

RenderChart.propTypes = {
  data: PropTypes.shape().isRequired
};

export default RenderChart;
