import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { RenderButton } from 'components';

const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80%',
	bgcolor: 'background.paper',
	border: '1px solid gray',
	borderRadius: 5,
	p: 4,
	textAlign: 'center'
};

const RenderModal = ({ title, open, onModalClose, children, actions, className }) => {
	return (
		<Dialog open={open} onClose={onModalClose} className={className}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{children}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<RenderButton onClick={onModalClose}>Close</RenderButton>
				{actions}
			</DialogActions>
		</Dialog>
	);
};

export default RenderModal;
