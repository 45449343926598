export const MODAL_INTERCONNECTIONS = "MODAL_INTERCONNECTIONS";
export const ACCEPT_INTERCONNECTION = "ACCEPT_INTERCONNECTION";
export const MODAL_ACCEPT_INTERCONNECTIONS = "MODAL_ACCEPT_INTERCONNECTIONS";
export const SEND_INTERCONNECTION = "SEND_INTERCONNECTION";
export const RESULT_INTERCONNECTION = "RESULT_INTERCONNECTION";
export const INITIALIZE_INTERCONNECTIONS = "INITIALIZE_INTERCONNECTIONS";
export const INTERCONNECTIONS = "INTERCONNECTIONS";
export const PROCESS_ACCEPT_INTERCONNECTION = "PROCESS_ACCEPT_INTERCONNECTION";
export const INITIALIZE_SMARTCONTRACT_SIGN = "INITIALIZE_SMARTCONTRACT_SIGN";
export const INITIALIZE_SMARTCONTRACT_DOWNLOAD = "INITIALIZE_SMARTCONTRACT_DOWNLOAD";
export const INITIALIZE_SMARTCONTRACT_COUNTERSIGN = "INITIALIZE_SMARTCONTRACT_COUNTERSIGN";
export const INTERCONNECT_REQUEST_RESPONSE = "INTERCONNECT_REQUEST_RESPONSE";


export const actionInitializeSignContract = data => ({
  type: INITIALIZE_SMARTCONTRACT_SIGN,
  payload: data
})


export const actionInitializeDownloadContract = data => ({
  type: INITIALIZE_SMARTCONTRACT_DOWNLOAD,
  payload: data
})

export const  actionInitializeCounterSignContract = data => ({
  type: INITIALIZE_SMARTCONTRACT_COUNTERSIGN,
  payload: data
})

export const initializeInterconnections = userId => ({
  type: INITIALIZE_INTERCONNECTIONS,
  payload: userId
});

export const acceptInterconnection = connectGuid => ({
  type: ACCEPT_INTERCONNECTION,
  payload: connectGuid
});

export const processInterconnection = (toId, connectGuid) => ({
  type: PROCESS_ACCEPT_INTERCONNECTION,
  payload: {
    toId: toId,
    connectGuid: connectGuid
  },
});

export const setModalInterconnections = (value, email) => ({
  type: MODAL_INTERCONNECTIONS,
  payload: {
    dataModalInterconnections: {
      modalInterconnections: value,
      emailInterconnections: email
    },
  },
});

export const setModalAcceptInterconnections = (
  value,
  connectGuid,
  participantName
) => ({
  type: MODAL_ACCEPT_INTERCONNECTIONS,
  payload: {
    dataModalAcceptInterconnections: {
      modalAcceptInterconnections: value,
      connectGuid: connectGuid,
      participantName: participantName
    }
  }
});

export const sendInterconnection = data => ({
  type: SEND_INTERCONNECTION,
  payload: data
});

export const setResultInterconnection = result => ({
  type: RESULT_INTERCONNECTION,
  payload: {
    resultInterconnection: result
  },
});

export const setInterconnections = result => ({
  type: INTERCONNECTIONS,
  payload: {
    interconnections: result
  },
});
export const setRequestResponse = result => ({
  type: INTERCONNECT_REQUEST_RESPONSE,
  payload: result,
});
