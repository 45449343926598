import React, { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Paper, CardContent } from '@material-ui/core';
import actions from "store/contracts/actions"

import { RenderTable, RenderButton } from 'components';
import CreateProjectModal from './CreateProjectModal';
import UpdateProjectModal from './UpdateProjectModal';

export default memo(() => {
	const dispatch = useDispatch();
	const [isCreateProjectOpen, setCreateProjectOpen] = useState(false);
	const [isUpdateProjectOpen, setUpdateProjectOpen] = useState(false);

	const loading = useSelector(state => state.contracts?.loadingProjects);
	const projects = useSelector(state => state.contracts?.projects || []);

	const tableColumns = [
		{
			Header: 'Project Name',
			accessor: 'name',
			width: 200
		},
		{
			Header: 'Smart Contracts',
			accessor: 'contracts',
			Cell: cell => {
				return (
					<Grid container spacing={1} columns={24}>
						{cell.value.map((contractId, i) => {
							if(!contractId) return null
							return (
								<Grid item key={i}>
									{contractId}
								</Grid>
							);
						})}
					</Grid>
				);
			}
		},
		{
			Header: 'Actions',
			Cell: cell => {
				return (
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-end'
						}}
					>
						<RenderButton
							color="primary"
							small
							onClick={() => setUpdateProjectOpen(cell.original._id)}
							style={{
								marginRight: '10px'
							}}
						>
							Update
						</RenderButton>
						<RenderButton
							color="danger"
							small
							onClick={() => {
								dispatch({ type: 'smartcontracts/DELETE_PROJECT', payload: cell.original._id });
							}}
						>
							Delete
						</RenderButton>
					</div>
				);
			},
			width: 150
		}
	];

	useEffect(() => {
		dispatch({ type: actions.GET_PROJECTS });
	}, []);


	return (
		<Grid container spacing={1}>
			<Grid item xs={12} style={{ textAlign: 'right' }}>
				<RenderButton
					medium
					color="primary"
					style={{
						margin: '10px'
					}}
					onClick={() => setCreateProjectOpen(true)}
				>
					New Project
				</RenderButton>
			</Grid>
			<Grid item xs={12}>
				<Paper>
					<CardContent>
						<RenderTable
							style={{
								margin: '10px'
							}}
							loading={loading}
							data={projects || []}
							columns={tableColumns}
						/>
					</CardContent>
				</Paper>
			</Grid>
			{!!isCreateProjectOpen && (<CreateProjectModal isOpen openCloseHandler={() => setCreateProjectOpen(false)} />) || null}
			{!!isUpdateProjectOpen && <UpdateProjectModal isOpen openCloseHandler={() => setUpdateProjectOpen(false)} projectId={isUpdateProjectOpen} />}
		</Grid>
	);
});
