import React, { memo, useState } from 'react';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import { SmartContractsTab, ReportsTab, InvoicesTab, StatementsOfAccountTab 
	} from './components';

export default memo(() => {
	const [selectedTab, setSelectedTab] = useState('smart-contracts');

	return (
		<div>
			<AppBar position="static">
				<Tabs value={selectedTab} onChange={(_, value) => setSelectedTab(value)}>
					<Tab value="smart-contracts" label="Smart Contracts" />
					<Tab value="reports" label="Reports" />
					<Tab value="invoices" label="Invoices" />
					<Tab value="statements-of-accounts" label="Statements of account" />
				</Tabs>
			</AppBar>
			<div>
				{(selectedTab === 'smart-contracts' && <SmartContractsTab />) || ''}
				{(selectedTab === 'reports' && <ReportsTab />) || ''}
				{(selectedTab === 'invoices' && <InvoicesTab />) || ''}
				{(selectedTab === 'statements-of-accounts' && <StatementsOfAccountTab />) || ''}
			</div>
		</div>
	);
});
