import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, Paper, CardContent, MenuItem } from '@material-ui/core';
import {
	SmartContractTermsConditions,
	RenderButton,
	SmartContractNewTerms
} from 'components';
import { SmartContractDestinationCodeContainer, SmartContractRatesContainer } from 'containers';
import {useTranslation} from "react-i18next";

const SmartContractsNewForm = props => {
	const { t } = useTranslation('smartcontracts/create');

	const {
		handleSubmit,
		changeStatus,
		uploadRatePlan,
		ratePlanData,
		clearUploadError,
		newSmartContractData,
		dataNewSmartContract: { tempId },
		submitSucceeded
	} = props;
  const isAddOfferDisabled = true; // temporarily we disable add offers

  const isRatePlanUploadDisable =
    (document.querySelector("[name='members[0].countryId']") &&
      document.querySelector("[name='members[0].countryId']").value !== "") ||
    false;
  const isBtnDisabled = (ratePlanData && ratePlanData.loading) || submitSucceeded;

	return (
		<div>
			<form onSubmit={handleSubmit}>
				<SmartContractNewTerms {...newSmartContractData} />

				<Grid container>
					{!isRatePlanUploadDisable && (
						<Grid item xs={12}>
							<SmartContractRatesContainer
								ratePlans={ratePlanData.data}
								handleRateUpload={uploadRatePlan}
								ratePlanData={ratePlanData}
								clearUploadError={clearUploadError}
								isUploadDisabled={isRatePlanUploadDisable}
								isRateAddendum={false}
								tempId={tempId}
							/>
						</Grid>
					)}
					{!isAddOfferDisabled && (
						<Grid item xs={12}>
							<Paper>
								<CardContent>
									<FieldArray name="members" component={SmartContractDestinationCodeContainer} props={props} disabled={isAddOfferDisabled} />
								</CardContent>
							</Paper>
						</Grid>
					)}
					<Grid item xs={12}>
						<Paper>
							<CardContent style={{ textAlign: 'center' }}>
								<SmartContractTermsConditions />
								<Field name="status" component="input" type="hidden" />
								<RenderButton color="secondary" type="submit" disabled={isBtnDisabled}>
									{t('save_draft')}
								</RenderButton>
								<RenderButton component={Link} to="/smart-contracts">
									{t('discard_changes')}
								</RenderButton>
								<RenderButton color="primary" type="submit" onClick={() => changeStatus('pending')} disabled={isBtnDisabled}>
									{t('sign_contract')}
								</RenderButton>
							</CardContent>
						</Paper>
					</Grid>
				</Grid>
			</form>
		</div>
	);
};

SmartContractsNewForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	changeStatus: PropTypes.func.isRequired,
	pristine: PropTypes.bool.isRequired,
	showOptionsContractType: PropTypes.func.isRequired,
	// onHardwareIdChange: PropTypes.func.isRequired,
	ratePlanData: PropTypes.shape().isRequired,
	clearUploadError: PropTypes.func.isRequired
};

export default SmartContractsNewForm;
