import React from "react";
import {
  AppBar,
  CardContent,
  Paper,
  Tab,
  Tabs,
  Typography
} from "@material-ui/core";
import {
  RenderTable,
  RenderButton,
  SmartContractManageFundingOfferModal,
  CurrencyNumber
} from "components";
import moment from "moment";

class SmartContractFundingOffers extends React.Component {
  constructor(props) {
    super(props);
    const {
      fundingOffers: { activeOffers }
    } = this.props;
    this.state = {
      activeTab: activeOffers
        ? activeOffers.length > 0
          ? "activeOffers"
          : "pendingOffers"
        : "pendingOffers",
      manageOffer: null
    };

    this.handleTabChange = this.handleTabChange.bind(this);
    this.manageFundingOffer = this.manageFundingOffer.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.fundingOffers) {
      const { activeOffers } = newProps.fundingOffers;
      if (activeOffers && activeOffers.length > 0) {
        this.setState({ activeTab: "activeOffers" });
      }
    }
  }

  handleTabChange = (ev, activeTab) => {
    this.setState({ activeTab });
  };

  contractIdFormatter = contractId => {
    const padded = `${contractId}000000`.slice(0, -contractId.length);
    return parseInt(padded)
      .toString(16)
      .toUpperCase();
  };

  manageFundingOffer = data => {
    const { manageOffer } = this.state;
    const { manageFundingOfferSubmit } = this.props;
    this.setState({
      manageOffer:
        manageOffer === null ? (
          <SmartContractManageFundingOfferModal
            contractData={data}
            dialogOpen
            dialogOpenCloseHandler={this.manageFundingOffer}
            onSubmit={postData => {
              manageFundingOfferSubmit(data._id, postData);
              this.manageFundingOffer();
            }}
            initialValues={{
              _id: data._id || null,
              supplierStatus: data.supplierStatus || null
            }}
          />
        ) : null
    });
  };

  actionFormatter = row => {
    const {
      fundingOffers: { activeOffers }
    } = this.props;
    return (
      <div>
        {activeOffers.length < 1 && (
          <div>
            <RenderButton
              color="primary"
              onClick={() => {
                this.manageFundingOffer(row);
              }}
            >
              Manage Funding Offer
            </RenderButton>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { activeTab, manageOffer } = this.state;
    const {
      fundingOffers: { activeOffers, pendingOffers, declinedOffers }
    } = this.props;
    return (
      <div>
        <AppBar position="static">
          <Tabs value={activeTab} onChange={this.handleTabChange}>
            <Tab label="Received Funding Offers" value="pendingOffers" />
            <Tab label="Accepted Funding Offers" value="activeOffers" />
            <Tab label="Declined Funding Offers" value="declinedOffers" />
          </Tabs>
        </AppBar>
        {manageOffer}
        {activeTab === "pendingOffers" ? (
          <Paper>
            <CardContent>
              <Typography variant="h1" gutterBottom>
                Received Funding Offers
              </Typography>
              <RenderTable
                data={pendingOffers || []}
                columns={[
                  {
                    Header: "Lender",
                    accessor: "lender"
                  },
                  {
                    Header: "Daily Settlement Amount",
                    accessor: "dailyFundingAmount",
                    Cell: props => (
                      <CurrencyNumber
                        amount={parseFloat(props.value).toFixed(6)}
						precision={6}
                      />
                    )
                  },
                  {
                    Header: "Total Settlement Amount",
                    accessor: "totalFundingAmount",
                    Cell: props => (
                      <CurrencyNumber
                        amount={parseFloat(props.value).toFixed(6)}
						precision={6}
                      />
                    )
                  },
                  {
                    Header: "Financing Fee (%/invoice)",
                    accessor: "interestRate",
                    Cell: props =>
                      props.value !== "" && props.value !== undefined
                        ? `${props.value}%`
                        : ""
                  },
                  {
                    Header: "Coverage (%)",
                    accessor: "percent",
                    Cell: props =>
                      props.value !== "" && props.value !== undefined
                        ? `${props.value}%`
                        : "100%"
                  },
                  {
                    Header: "Date",
                    accessor: "updatedAt",
                    Cell: props => moment(props.value).format("DD MMM YYYY")
                  },
                  {
                    Header: "Action",
                    accessor: "",
                    filterable: false,
                    Cell: props => this.actionFormatter(props.original, true)
                  }
                ]}
              />
            </CardContent>
          </Paper>
        ) : (
          ""
        )}

        {activeTab === "activeOffers" ? (
          <Paper>
            <CardContent>
              <Typography variant="h1" gutterBottom>
                Accepted Funding Offers
              </Typography>
              <RenderTable
                data={activeOffers || []}
                columns={[
                  {
                    Header: "Lender",
                    accessor: "lender"
                  },
                  {
                    Header: "Daily Settlement Amount",
                    accessor: "dailyFundingAmount",
                    Cell: props => (
                      <CurrencyNumber
                        amount={parseFloat(props.value).toFixed(6)}
						precision={6}
                      />
                    )
                  },
                  {
                    Header: "Total Settlement Amount",
                    accessor: "totalFundingAmount",
                    Cell: props => (
                      <CurrencyNumber
                        amount={parseFloat(props.value).toFixed(6)}
						precision={6}
                      />
                    )
                  },
                  {
                    Header: "Financing Fee (%/invoice)",
                    accessor: "interestRate",
                    Cell: props =>
                      props.value !== "" && props.value !== undefined
                        ? `${props.value}%`
                        : ""
                  },
                  {
                    Header: "Date",
                    accessor: "updatedAt",
                    Cell: props => moment(props.value).format("DD MMM YYYY")
                  }
                ]}
              />
            </CardContent>
          </Paper>
        ) : (
          ""
        )}

        {activeTab === "declinedOffers" ? (
          <Paper>
            <CardContent>
              <Typography variant="h1" gutterBottom>
                Declined Funding Offers
              </Typography>
              <RenderTable
                data={declinedOffers || []}
                columns={[
                  {
                    Header: "Lender",
                    accessor: "lender"
                  },
                  {
                    Header: "Daily Settlement Amount",
                    accessor: "dailyFundingAmount",
                    Cell: props => (
                      <CurrencyNumber
                        amount={parseFloat(props.value).toFixed(6)}
						precision={6}
                      />
                    )
                  },
                  {
                    Header: "Total Settlement Amount",
                    accessor: "totalFundingAmount",
                    Cell: props => (
                      <CurrencyNumber
                        amount={parseFloat(props.value).toFixed(6)}
						precision={6}
                      />
                    )
                  },
                  {
                    Header: "Financing Fee (%/invoice)",
                    accessor: "interestRate",
                    Cell: props =>
                      props.value !== "" && props.value !== undefined
                        ? `${props.value}%`
                        : ""
                  },
                  {
                    Header: "Date",
                    accessor: "updatedAt",
                    Cell: props => moment(props.value).format("DD MMM YYYY")
                  },
                  {
                    Header: "Action",
                    accessor: "",
                    filterable: false,
                    Cell: props => this.actionFormatter(props.original, true)
                  }
                ]}
              />
            </CardContent>
          </Paper>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default SmartContractFundingOffers;
