import {
  IDLESTATUS_AWAY,
  IDLESTATUS_INACTIVE,
  IDLESTATUS_EXPIRED
} from "./constants";

export const idleStatusDelay = idleStatus => (dispatch, getState) => {
  if (idleStatus === IDLESTATUS_AWAY) return 60000;
  if (idleStatus === IDLESTATUS_INACTIVE) return 60 * 5 * 1000; // Call database to look up the users delay time
  if (idleStatus === IDLESTATUS_EXPIRED) return AUTH_TIMEOUT; // Log them out after another minute after they enter the inactive status
};

export const activeStatusAction = (dispatch, getState) =>
  console.log("welcome back!");

export const idleStatusAction = idleStatus => (dispatch, getState) => {
  if (idleStatus === IDLESTATUS_AWAY)
    console.log(`absent user...${new Date()}-----${new Date().getTime()}`);
  if (idleStatus === IDLESTATUS_INACTIVE)
    console.log(
      `you still there??...${new Date()}-----${new Date().getTime()}`
    );
  if (idleStatus === IDLESTATUS_EXPIRED) {
    dispatch({ type: "LOG_OUT" });
  }
};
