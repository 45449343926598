import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fromInfotext } from 'store/selectors';
import { actionGetInfoTextPostDataRequest } from 'store/actions';
import PropTypes from 'prop-types';
import { InfoText } from 'components';

class InfoTextContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		const { actionGetInfoTextPostDataRequest, page } = this.props;
		actionGetInfoTextPostDataRequest({ page });
	}

	render() {
		const { dataInfoText, section } = this.props;

		return <InfoText infoText={typeof dataInfoText === 'object' ? dataInfoText : {}} section={section} />;
	}
}

const mapStateToProps = state => ({
	dataInfoText: fromInfotext.infoTextResult(state)
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			actionGetInfoTextPostDataRequest
		},
		dispatch
	);

InfoTextContainer.propTypes = {
	actionGetInfoTextPostDataRequest: PropTypes.func.isRequired,
	page: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoTextContainer);
