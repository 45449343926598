export const FINANCING_REQUESTS_GET_SMARTCONTRACTS =
  "FINANCING_REQUESTS_GET_SMARTCONTRACTS";
export const FINANCING_REQUESTS_SMARTCONTRACTS_DATA =
  "FINANCING_REQUESTS_SMARTCONTRACTS_DATA";
export const FINANCING_REQUESTS_SEND_LENDER_OFFER =
  "FINANCING_REQUESTS_SEND_LENDER_OFFER";
export const FINANCING_REQUESTS_UPDATE_OFFER =
  "FINANCING_REQUESTS_UPDATE_OFFER";

export const getFinancialRequestsSmartContracts = () => ({
  type: FINANCING_REQUESTS_GET_SMARTCONTRACTS,
  payload: null
});

export const setFinancialRequestsSmartContracts = data => ({
  type: FINANCING_REQUESTS_SMARTCONTRACTS_DATA,
  payload: data
});

export const sendFinancialRequestsLenderOffer = data => ({
  type: FINANCING_REQUESTS_SEND_LENDER_OFFER,
  payload: data
});

export const updateFundingOffer = data => ({
  type: FINANCING_REQUESTS_UPDATE_OFFER,
  payload: data
});
