export const contractIdFormatter = (contractId, nickname = false, partnerName = false) => {
	const contractIdNumber = parseInt(contractId);
	if (isNaN(contractIdNumber)) {
		return contractId;
	}
	const padded = `${contractIdNumber}000000`.slice(0, -`${contractIdNumber}`.length);
	const contractHash = parseInt(padded)
		.toString(16)
		.toUpperCase();
	if (nickname && nickname.length > 0) {
		return `${nickname} - ${parseInt(padded)
			.toString(16)
			.toUpperCase()}`;
	}

	return `${(nickname && `${nickname} - `) || (partnerName && `${partnerName} - `) || ''}${contractHash}`;
};

export const capitalizeWord = string => string.charAt(0).toUpperCase() + string.slice(1);

export const thousandSeparator = value => {
	if (!value) return 0;
	if (typeof myVar === 'number') return value.toLocaleString('en-US', { maximumFractionDigits: 2 });
	return parseFloat(value).toLocaleString('en-US', { maximumFractionDigits: 2 });
};

export const handleCurrency = (number, currency, roundNumbers = true) => {
	if (typeof currency === 'string') {
		const currencies = {
			USD: {
				currencySymbol: '$',
				symbolPosition: 'prefix'
			},
			EUR: {
				currencySymbol: '€',
				symbolPosition: 'suffix'
			},
			CAD: {
				currencySymbol: '$',
				symbolPosition: 'prefix'
			}
		};

		currency = currencies[currency];
	}

	const stringNumber = roundNumbers ? parseFloat(number).toFixed(2) : number.toString();
	const indexOfDot = stringNumber.indexOf('.');

	const integer = stringNumber !== '0' ? stringNumber.substring(0, indexOfDot) : '0';
	const decimals = stringNumber.substring(indexOfDot + 1, stringNumber.length);

	const formattedInteger = integer.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

	let formatted = stringNumber === '0' ? `0.00` : `${formattedInteger}.${decimals}`;

	if (number < 0 && currency?.symbolPosition === 'prefix') {
		formatted = `(${currency?.currencySymbol}${formatted.replace('-', '')})`;
	}

	if (number < 0 && currency?.symbolPosition === 'suffix') {
		formatted = `(${formatted.replace('-', '')}${currency?.currencySymbol})`;
	}
	if (number >= 0) {
		if (currency?.symbolPosition === 'prefix') return `${currency?.currencySymbol}${formatted}`;
		if (currency?.symbolPosition === 'suffix') return `${formatted}${currency?.currencySymbol}`;
		if (!currency?.symbolPosition) return `${currency?.currencySymbol}${formatted}`;
	}
	if (number >= 0) {
		if (currency?.symbolPosition === 'prefix') return `${currency?.currencySymbol}${formatted}`;
		if (currency?.symbolPosition === 'suffix') return `${formatted}${currency?.currencySymbol}`;
	}

	return formatted;
};

export const colorStatusText = (status, property = 'color', style) => {
	const statusColor = {
		active: '#FF8C00',
		fulfilled: '#14AB2C',
		cancelled: '#F00707',
		rejected: '#F00707',
		pending: '#2A6CBA',
		approved: '#14AB2C',
		'waiting-for-partner-confirmation': '#2A6CBA',
		'under-review': '#2A6CBA'
	};
	return {
		...style,
		[property]: statusColor?.[status] || 'violet'
	};
};

export const base64ToBlob = (base64String, contentType = 'application/pdf') => {
	const byteCharacters = atob(base64String);
	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);
	return new Blob([byteArray], { type: contentType });
};

export const fileToBase64 = async file => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = () => {
			const base64String = reader.result;
			const base64Content = base64String.split(',')[1];

			const fileInfo = {
				name: file.name,
				type: file.type,
				content: base64Content
			};

			resolve(fileInfo);
		};

		reader.onerror = () => {
			reject(reader.error);
		};

		reader.readAsDataURL(file);
	});
};
