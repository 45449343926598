import React from 'react';
import { Field, reduxForm } from 'redux-form';
import withStyles from '@material-ui/core/styles/withStyles';
import { createValidator, required } from 'services/validation';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Slide, Typography } from '@material-ui/core';
import { RenderButton, RenderInput } from 'components';
import moment from 'moment';
import { contractIdFormatter } from 'utils';
import { withTranslation } from 'react-i18next';
import styles from './style';

const Transition = props => <Slide direction="up" {...props} />;

class SmartContractRequestFundingModal extends React.Component {
	constructor(props) {
		super(props);
		this.onChangeRequestedFundingInput = this.onChangeRequestedFundingInput.bind(this);
		this.onChangedailySettlementAmountInput = this.onChangedailySettlementAmountInput.bind(this);
	}

	onChangeRequestedFundingInput = ev => {
		const {
			contractData: {
				contractTerms: { billingCycle, paymentTerms }
			}
		} = this.props;
		const value = ev.target.value || 0;
		let dailySettlementAmount = 0;
		switch (billingCycle.toLowerCase()) {
			case 'weekly':
				dailySettlementAmount = value / (7 + paymentTerms);
				break;
			case 'semi-monthly':
			case 'bi-weekly':
				dailySettlementAmount = value / (15 + paymentTerms);
				break;
			case 'prepay':
			case 'daily':
				dailySettlementAmount = value / (1 + paymentTerms);
				break;
			case 'monthly':
				dailySettlementAmount = value / (30 + paymentTerms);
				break;
			case 'bi-monthly':
				dailySettlementAmount = value / (60 + paymentTerms);
				break;
			default:
				dailySettlementAmount = 0;
				break;
		}
		this.props.change('dailySettlementAmount', dailySettlementAmount);
	};

	onChangedailySettlementAmountInput = ev => {
		const {
			contractData: {
				contractTerms: { billingCycle, paymentTerms }
			}
		} = this.props;
		const value = ev.target.value || 0;
		let requestedFundingAmount = 0;
		switch (billingCycle.toLowerCase()) {
			case 'weekly':
				requestedFundingAmount = value * (7 + paymentTerms);
				break;
			case 'semi-monthly':
			case 'bi-weekly':
				requestedFundingAmount = value * (15 + paymentTerms);
				break;
			case 'prepay':
			case 'daily':
				requestedFundingAmount = value * (1 + paymentTerms);
				break;
			case 'monthly':
				requestedFundingAmount = value * (30 + paymentTerms);
				break;
			case 'bi-monthly':
				requestedFundingAmount = value * (60 + paymentTerms);
				break;
			default:
				requestedFundingAmount = 0;
				break;
		}
		this.props.change('fundingRequestedAmount', requestedFundingAmount);
	};

	render() {
		const {
			t,
			open,
			classes,
			openCloseHandler,
			handleSubmit,
			contractData: {
				contractTerms: { contractId, supplier, customer, billingCycle, paymentTerms, settlementPeriod, billingTimezone, contractEndDate }
			}
		} = this.props;

		return (
			<Dialog open={open} onBackdropClick={openCloseHandler} TransitionComponent={Transition} maxWidth="sm">
				<DialogTitle>{t('funding.title')}</DialogTitle>
				<form onSubmit={handleSubmit}>
					<DialogContent>
						<Grid container>
							<Grid item xs={3}>
								<Typography className={classes.label}>{t('funding.smartcontract_id')}</Typography>
								<Typography>{contractIdFormatter(contractId)}</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography className={classes.label}>{t('funding.customer')}</Typography>
								<Typography>{customer}</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography className={classes.label}>{t('funding.supplier')}</Typography>
								<Typography>{supplier}</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography className={classes.label}>{t('funding.contract_end_date')}</Typography>
								<Typography>
									{contractEndDate === undefined || contractEndDate === 0 || contractEndDate === null ? 'Open' : moment(contractEndDate).format('DD MMM YYYY')}
								</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography className={classes.label}>{t('funding.billing_cycle')}</Typography>
								<Typography>{billingCycle}</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography className={classes.label}>{t('funding.payment_terms')}</Typography>
								<Typography>
									{parseInt(paymentTerms) > 1 ? t('translations:x_days', { num: paymentTerms }) : t('translations:x_day', { num: paymentTerms })}
								</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography className={classes.label}>{t('funding.settlement_period')}</Typography>
								<Typography>{settlementPeriod}</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography className={classes.label}>{t('funding.billing_timezone')}</Typography>
								<Typography>{billingTimezone}</Typography>
							</Grid>
						</Grid>

						<Divider className={classes.divider} />

						<Grid container>
							<Grid item xs={12}>
								<Field
									component={RenderInput}
									name="dailyRequestedAmount"
									type="number"
									label={t('funding.daily_settlement_amount')}
									onChange={this.onChangedailySettlementAmountInput}
								/>
							</Grid>
							<Grid item xs={12}>
								<Field
									component={RenderInput}
									name="fundingRequestedAmount"
									type="number"
									label={t('funding.requested_amount')}
									onChange={this.onChangeRequestedFundingInput}
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<RenderButton color="primary" type="submit">
							{t('translations:continue')}
						</RenderButton>
						<RenderButton color="danger" onClick={openCloseHandler}>
							{t('translations:cancel')}
						</RenderButton>
					</DialogActions>
				</form>
			</Dialog>
		);
	}
}

SmartContractRequestFundingModal.defaultProps = {
	contractData: {
		contractTerms: {
			contractId: '',
			supplier: '',
			customer: '',
			paymentTerms: '',
			settlementPeriod: '',
			billingTimezone: '',
			contractEndDate: ''
		}
	}
};

const validate = createValidator({
	fundingRequestedAmount: [required]
});

const SmartContractRequestFundingModalWithStyles = withStyles(styles)(SmartContractRequestFundingModal);
const SmartContractRequestFundingModalWithTranslation = withTranslation()(SmartContractRequestFundingModalWithStyles);

export default reduxForm({
	form: 'SmartContractRequestFundingForm',
	enableReinitialize: true,
	validate
})(SmartContractRequestFundingModalWithTranslation);
