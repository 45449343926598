import React, { Component } from "react";
import { Util } from "components";

const BiddingOpenOrder = ({ contract, processCancelOpenOrder }) => {
  return (
    <tr id={"contract-" + contract.id} key={"key" + contract.id}>
      <td>
        {Util.isEmpty(contract.paymentTerms) ||
        Util.isEmpty(contract.paymentTermsNet)
          ? ""
          : contract.paymentTerms
            ? contract.paymentTerms
            : 30 + "net" + contract.paymentTermsNet
              ? contract.paymentTermsNet
              : 30}
      </td>
      <td>{contract.minimumCreditRating}</td>
      <td>
        <div className="row">
          <div id={"daily-settlement-amount-" + contract.id}>
            {Util.isEmpty(contract.dailyAllocatedAmount)
              ? "not set"
              : contract.dailyAllocatedAmount}
          </div>
          <i
            className="btn-edit-daily-settlement-amount fa fa-pencil text-info ml-2"
            style={{ cursor: "pointer" }}
            data-contract-id={contract.id}
          />
        </div>
      </td>
      <td className={"period-" + contract.id}>
        {contract.paymentTerms * contract.dailyAllocatedAmount}
      </td>
      <td>
        <div className="row">
          <div id={"interest-rate-" + contract.id}>
            {Util.isEmpty(contract.interestRate) ? "" : contract.interestRate}
          </div>
          <i
            className="btn-edit-interest fa fa-pencil text-info ml-2"
            style={{ cursor: "pointer" }}
            data-contract-id={contract.id}
          />
        </div>
      </td>
      <td>{Util.getHexaDecimal(contract.id)}</td>
      <td>
        <div className="btn-group">
          <button
            type="button"
            className="btn btn-sm btn-icon btn-danger btn-cancel"
            data-id={contract.id}
            data-toggle="tooltip"
            data-original-title="Cancel"
            onClick={() => processCancelOpenOrder(contract.id)}
          >
            <i className="fa fa-times" aria-hidden="true" />
          </button>
        </div>
      </td>
    </tr>
  );
};

export default BiddingOpenOrder;
