import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Interconnections, InterconnectionForm, RenderNotification } from 'components';
import { SendInterconnectionsContainer, AcceptInterconnectionsContainer } from 'containers';
import {
	setModalInterconnections,
	setResultInterconnection,
	initializeInterconnections,
	setModalAcceptInterconnections,
	actionInitializeSignContract,
	actionInitializeCounterSignContract,
	actionInitializeDownloadContract,
	setRequestResponse
} from 'store/actions';

import { fromInterconnections, fromBiddingportal, fromMyaccount } from 'store/selectors';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { reduxForm, reset } from 'redux-form';
import { createValidator, required, emailInterconnections } from 'services/validation';

class InterconnectionsContainer extends Component {
	constructor(props) {
		super(props);
		this.hideAcceptInterconnections = this.hideAcceptInterconnections.bind(this);
		this.acceptInterconnections = this.acceptInterconnections.bind(this);
		this.agreementSign = this.agreementSign.bind(this);
		this.agreementDownload = this.agreementDownload.bind(this);
		this.agreementCounterSign = this.agreementCounterSign.bind(this);

		this.toast = toast;
	}

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(reset('interconecctionForm'));
		this.props.initializeInterconnections('userId');
		this.props.setRequestResponse(false);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.resultInterconnection !== nextProps.resultInterconnection) {
			if (nextProps.resultInterconnection) {
				const { dispatch } = this.props;
				dispatch(reset('interconecctionForm'));
				this.fnToaStr(nextProps.resultInterconnection);
				this.props.setResultInterconnection(undefined);
			}
		}
	}

	fnToaStr(data) {
		if (data.status === 'success') {
			this.toast.success(data.message, {
				position: 'top-right',
				autoClose: 2000
			});
		} else {
			this.toast.error(data.message, {
				position: 'top-right',
				autoClose: 2000
			});
		}
	}

	hideAcceptInterconnections() {
		this.props.setModalAcceptInterconnections(false, undefined, undefined);
	}

	acceptInterconnections(guid, participantName) {
		this.props.setModalAcceptInterconnections(true, guid, participantName);
	}

	agreementSign(field) {
		this.props.actionInitializeSignContract(field);
	}

	agreementDownload(field) {
		this.props.actionInitializeDownloadContract(field);
	}

	agreementCounterSign(field) {
		this.props.actionInitializeCounterSignContract(field);
	}

	render() {
		const { requestResponse } = this.props;
		return (
			<div>
				<RenderNotification
					variant={requestResponse && requestResponse.status === 'BAD' ? 'danger' : requestResponse && requestResponse.status === 'INFO' ? 'warning' : 'success'}
					floating
					open={!!(requestResponse && requestResponse.status && requestResponse.message)}
					message={requestResponse && requestResponse.message ? requestResponse.message : 'Internal error. Please try again later'}
				/>
				<ToastContainer />
				<Interconnections
					interconnections={this.props.interconnections}
					user={this.props.userInfo}
					agreementSign={this.agreementSign}
					agreementDownload={this.agreementDownload}
					agreementCounterSign={this.agreementCounterSign}
					acceptInterconnections={this.acceptInterconnections}
					interconnectionForm={<InterconnectionForm {...this.props} handleSubmit={this.props.handleSubmit} />}
				/>
				{this.props.dataModalAcceptInterconnections.modalAcceptInterconnections ? (
					<AcceptInterconnectionsContainer hideAcceptInterconnections={this.hideAcceptInterconnections} />
				) : (
					<div />
				)}
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	userInfo: fromMyaccount.getParticipantData(state),
	user: { acountType: 2 },
	resultProcessOrder: fromBiddingportal.getResultProcessOrder(state),
	dataModalInterconnections: fromInterconnections.getDataModalInterconnections(state),
	dataModalAcceptInterconnections: fromInterconnections.getDataModalAcceptInterconnections(state),
	resultInterconnection: fromInterconnections.getResultInterconnection(state),
	interconnections: fromInterconnections.getInterconnections(state),
	requestResponse: fromInterconnections.getRequestResponse(state)
});

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			initializeInterconnections,
			setModalInterconnections,
			setResultInterconnection,
			setModalAcceptInterconnections,
			actionInitializeSignContract,
			actionInitializeDownloadContract,
			actionInitializeCounterSignContract,
			setRequestResponse
		},
		dispatch
	);
}

const onSubmit = (data, dispatch) => {
	dispatch(setModalInterconnections(true, data.interconnectionsEmail));
};

const validate = createValidator({
	interconnectionsEmail: [required, emailInterconnections]
});

InterconnectionsContainer.propTypes = {};

const reduxFormInterconnectionsContainer = reduxForm({
	form: 'interconecctionForm',
	destroyOnUnmount: true,
	onSubmit,
	validate
})(InterconnectionsContainer);

export default connect(mapStateToProps, mapDispatchToProps)(reduxFormInterconnectionsContainer);
