import React from "react";
import { PrivateTemplate, BreadCrumb } from "components";
import { BiddingPortalContainer } from "containers";
import { Paper, CardContent } from "@material-ui/core";

const BiddigPortalPage = ({ ...props }) => (
  <PrivateTemplate>
    <BreadCrumb
      name={"Bidding Portal"}
      firstLevel={"Home"}
      secondLevel={"Bidding Portal"}
    />
    <Paper>
      <CardContent>
        <BiddingPortalContainer {...props} />
      </CardContent>
    </Paper>
  </PrivateTemplate>
);
export default BiddigPortalPage;
