import { CurrencyNumber, RenderButton } from 'components';

/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import moment from 'moment';

const MonthlyTableHeader = ({ toggleInvoiceModal, clientDataKey }, t) => {
	return {
		columns: [
			{
				Header: t('invoices.table.inv_date'),
				accessor: 'invoiceDate',
				minWidth: 120,
				sortMethod: (a, b) => {
					if (moment(a, 'MMM DD YYYY').isSame(moment(b, 'MMM DD YYYY'))) {
						return 0;
					}
					return moment(a, 'MMM DD YYYY').isSameOrAfter(moment(b, 'MMM DD YYYY')) ? 1 : -1;
				}
			},
			{
				Header: t('invoices.table.client'),
				accessor: `${clientDataKey}.name`,
				minWidth: 160
			},
			{
				Header: t('invoices.table.amount'),
				accessor: 'TOT_AMOUNT',
				maxWidth: 120,
				Cell: props => {
					// eslint-disable-next-line react/prop-types
					const { value } = props;
					return (
						<div style={{ textAlign: 'right' }}>
							<CurrencyNumber amount={Math.round(value * 100) / 100} />
						</div>
					);
				}
			},
			{
				Header: () => <div style={{ textAlign: 'center' }}>{t('invoices.table.actions')}</div>,
				id: 'actions',
				Filter: () => null,
				Cell: d => (
					<div style={{ textAlign: 'center' }}>
						<RenderButton color="primary" onClick={() => toggleInvoiceModal(d.original, 'monthly')}>
							{t('invoices.table.view')}
						</RenderButton>
					</div>
				)
			}
		]
	};
};
export default MonthlyTableHeader;
