import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography, Paper, TextField, MenuItem } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import * as moment from 'moment';

import { RenderTable } from 'components';
import Loader from 'components/atoms/Loader';
import CurrencyNumber from 'components/atoms/CurrencyNumber';
import RenderButton from 'components/atoms/RenderButton';
import RenderAcpType from 'components/atoms/AcpType';

import actions from 'store/acp/actions';
import styles from './styles.module.scss';

export default memo(({ openedTable, setOpenedTable, isLoading, nladSubmissions, numberOfSubmissions }) => {
	const dispatch = useDispatch();

	const triggerDownloadNladSubmission = (e, _id, claimNumber, type) => {
		e.preventDefault();
		e.stopPropagation();
		dispatch({
			type: actions.DOWNLOAD_NLAD_SUBMISSION,
			payload: {
				nladSubmissionId: _id,
				claimNumber,
				type
			}
		});
	};

	const DateFilter = ({ filter, onChange, type = 'date' }) => (
		<TextField
			type={type}
			value={filter ? filter.value : ''}
			onChange={e => onChange(e.target.value)}
			style={{ width: '80%' }}
			variant="standard"
			InputLabelProps={{
				shrink: true
			}}
		/>
	);

	const columnsPending = [
		{
			Header: 'Upload Date',
			accessor: 'uploadDate',
			Cell: cell => {
				return <span>{moment(cell.original?.uploadDate).format('MMM Do YYYY')}</span>;
			},
			width: 200,
			Filter: ({ filter, onChange }) => <DateFilter filter={filter} onChange={onChange} type="date" />
		},
		{
			Header: 'Claim Number',
			accessor: 'claimNumber',
			Cell: cell => {
				return <span>{cell.original?.claimNumber}</span>;
			},
			width: 125,
			Filter: ({ filter, onChange }) => <TextField value={filter ? filter.value : ''} onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard" />
		},
		{
			Header: 'Claim Period',
			accessor: 'claimPeriod',
			Cell: cell => <span>{moment(cell.original?.claimPeriod).format('MMMM YYYY')}</span>,
			width: 200,
			Filter: ({ filter, onChange }) => <DateFilter filter={filter} onChange={onChange} type="month" />
		},
		{
			Header: 'Type',
			accessor: 'type',
			Cell: cell => {
				return <RenderAcpType size="small" claimType={cell.original?.claimType} />;
			},
			width: 125,
			Filter: ({ filter, onChange }) => (
				<TextField value={filter ? filter.value : ''} style={{ width: '80%' }} select onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard">
					<MenuItem value="">All</MenuItem>
					<MenuItem value="sales">Sales</MenuItem>
					<MenuItem value="mrc">MRC</MenuItem>
					<MenuItem value="claimable-devices">Claimable Devices</MenuItem>
				</TextField>
			)
		},
		{
			Header: <div className="number-column">Quantity</div>,
			accessor: 'quantity',
			Cell: cell => {
				return <div className="number-column">{cell.original?.quantity}</div>;
			},
			width: 125,
			Filter: ({ filter, onChange }) => <TextField value={filter ? filter.value : ''} onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard" />
		},
		{
			Header: <div className="number-column">Value</div>,
			accessor: 'value',
			Cell: cell => {
				return (
					<div className="number-column">
						<CurrencyNumber amount={cell.original?.value} precision={0} />
					</div>
				);
			},
			Filter: ({ filter, onChange }) => <TextField value={filter ? filter.value : ''} onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard" />
		},
		{
			Header: 'Notes',
			accessor: 'notes',
			filterable: false,
			Cell: cell => {
				return <span>{cell.original?.notes ?? ''}</span>;
			}
		},
		{
			Header: 'Actions',
			filterable: false,
			width: 250,
			Cell: ({ original: { _id, claimNumber } }) => {
				return (
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-start'
						}}
					>
						<RenderButton
							onClick={e => triggerDownloadNladSubmission(e, _id, claimNumber, 'pending')}
							color="primary"
							small
							style={{
								marginRight: '10px'
							}}
						>
							Download
						</RenderButton>
					</div>
				);
			}
		}
	];

	return (
		<div className={styles.container}>
			<Paper>
				<Grid container className="grid-container">
					<Grid
						container
						onClick={() => {
							if (openedTable === 'pending') {
								setOpenedTable('');
							} else setOpenedTable('pending');
						}}
					>
						<Grid item xs={11}>
							<Typography variant="h1" gutterBottom>
								Pending Submissions ({numberOfSubmissions.pending})
							</Typography>
						</Grid>

						<Grid item xs={1} align="right">
							<Typography variant="h1" gutterBottom style={{ marginRight: '20px' }}>
								{(openedTable === 'pending' && <Remove color="primary" fontSize="large" />) ||
									(openedTable !== 'pending' && <Add color="primary" fontSize="large" />)}
							</Typography>
						</Grid>
					</Grid>
					{openedTable === 'pending' && (
						<Grid item xs={12}>
							{(isLoading && <Loader isLoading />) || (
								<RenderTable
									data={nladSubmissions?.filter(submission => submission.nladVerificationStatus === 'pending') ?? []}
									loading={isLoading}
									minRows={2}
									columns={columnsPending}
									filterable={!isLoading}
									sortable={true}
									style={{
										marginRight: '20px'
									}}
								/>
							)}
						</Grid>
					)}
				</Grid>
			</Paper>
		</div>
	);
});
