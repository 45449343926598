const ratesTableCustomToolbarStyle = {
  legend: {
    listStyle: "none"
  },
  legendLi: {
    float: "left",
    marginRight: "10px",
    padding: "5px"
  },
  legendSpan: {
    border: "1px solid #ccc",
    float: "left",
    width: "12px",
    height: "12px",
    margin: "2px"
  },
  pending: { backgroundColor: "orange" },
  autoaccepted: { backgroundColor: "green" },
  declined: { backgroundColor: "gray" },
  selected: { backgroundColor: "lightgreen" },
  new: { backgroundColor: "lightblue" }
};

export default ratesTableCustomToolbarStyle;
