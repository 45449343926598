const smartContractRatesStyle = {
  loadingText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  marginBottom: {
    marginBottom: "10px"
  }
};
export default smartContractRatesStyle;
