// New Way
const actions = {
	SET_STATE: 'useraccount/SET_STATE',
	VERIFY_IMPERSONATE_CODE: 'useraccount/VERIFY_IMPERSONATE_CODE',
	IMPERSONATE_LOGIN: 'useraccount/IMPERSONATE_LOGIN',
};

export default actions;

export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';

export const userInfoSuccess = successData => ({
	type: USER_INFO_SUCCESS,
	payload: successData
});
