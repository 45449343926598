import React, { Component } from "react";
import { Field } from "redux-form";
import { RenderInput, RenderButton } from "components";
import { Grid } from "@material-ui/core";

class TechnicalInformationIpAddresses extends Component {
  componentWillMount() {
    const { fields } = this.props;
    if (fields.length === 0) fields.push({});
  }

  render() {
    const {
      fields,
      meta: { touched, error }
    } = this.props;

    // const normalizeIp = (value, prev, values, prevValues) => {
    //   if (!value) {
    //     return value;
    //   }

    //   const onlyNums = value.replace(/[^\d]/g, "");
    //   if (onlyNums.length <= 3) {
    //     return onlyNums;
    //   }
    //   if (onlyNums.length <= 6) {
    //     return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3)}`;
    //   }
    //   return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
    //     6,
    //     9
    //   )}`;
    // };

    return (
      <Grid container>
        <Grid item xs={12}>
          <RenderButton color="primary" onClick={() => fields.push({})}>
            Add New IP Address
          </RenderButton>
        </Grid>
        {fields.map((element, index) => (
          <Grid item xs={12} key={index}>
            <Grid container alignItems="flex-end">
              <Grid item xs={2}>
                <Field
                  component={RenderInput}
                  label="Ip Address"
                  name={`${element}.ip`}
                  autoComplete="off"
                  // normalize={normalizeIp}
                />
              </Grid>
              <Grid item xs={1}>
                <Field
                  component={RenderInput}
                  label="Netmask"
                  name={`${element}.netmask`}
                />
              </Grid>
              <Grid item xs={1}>
                <Field
                  component={RenderInput}
                  label="Port"
                  name={`${element}.port`}
                />
              </Grid>
              <Grid item xs={2}>
                <RenderButton
                  color="danger"
                  onClick={() => fields.remove(index)}
                  disabled={fields.length === 1}
                >
                  Delete
                </RenderButton>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  }
}

export default TechnicalInformationIpAddresses;
