import React, { memo, useCallback, useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Drawer, FormControlLabel, Switch, Typography } from '@material-ui/core';
import { Add, Check } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { set } from 'date-fns';
import moment from 'moment';
import { Loader, RenderButton, RenderFormikDatePicker, RenderFormikInput, RenderTable } from 'components';
import { handleCurrency } from 'utils';
import { useModal } from './hooks/useModal';
import { columns } from './columns';
import styles from './styles.module.scss';

export default memo(({ modalOpened, toggleModal }) => {
	const { formik, needFinancing, handleSetNeedFinancing, selectedClaim, setSelectedClaim, loading, availableClaims, openDrawer } = useModal({ toggleModal });
	const { claimNumber, summary, createdAt, lender, settled } = selectedClaim || {};
	const { quantity, value } = summary || {};
	const { lenderCoveragePercent } = lender || {};
	return (
		<>
			<Dialog maxWidth="lg" open={modalOpened} className={styles.modal} onClose={toggleModal}>
				{loading.orders && <Loader fullscreen />}
				<DialogTitle>Processed claims</DialogTitle>
				<DialogContent className="content">
					<RenderTable
						className="space-top"
						data={availableClaims || []}
						columns={columns({ setSelectedClaim, loading })}
						showPagination={false}
						sortable={false}
						minRows={2}
						noDataText={
							<div>
								<strong>There are no claim available to create an order for</strong>
							</div>
						}
					/>
				</DialogContent>
				<DialogActions>
					<RenderButton onClick={toggleModal}>Close</RenderButton>
				</DialogActions>
			</Dialog>
			<Drawer className={styles.drawer} anchor="right" open={openDrawer} onClose={() => setSelectedClaim(null)}>
				<div className="content">
					{loading.orders && <Loader fullscreen />}

					<Typography variant="h1" className="title">
						Create Order
					</Typography>

					<div className="claim-data-container">
						<div className="row">
							<Typography className="label">Claim ID</Typography>
							<Typography className="dark-text">{claimNumber}</Typography>
						</div>
						<div className="row">
							<Typography className="label">Approval Date</Typography>
							<Typography className="dark-text">{moment(createdAt).format('MMM Do YY')}</Typography>
						</div>
						<div className="row">
							<Typography className="label">Item Quantity</Typography>
							<Typography className="dark-text">{quantity}</Typography>
						</div>
						<div className="row">
							<Typography className="label">Escrow</Typography>
							<Typography className="dark-text">{handleCurrency(value, 'USD')}</Typography>
						</div>
					</div>

					<FormControlLabel
						control={<Switch checked={needFinancing} disabled onChange={e => handleSetNeedFinancing(e.target.checked)} name="needFinancing" color="primary" />}
						label="Need financing?"
					/>
					{needFinancing && (
						<div className="need-financing">
							<Typography variant="caption">Remember than no more than 90% will be financed</Typography>

							<RenderFormikInput type="number" formik={formik} name="coverage" label="Coverage" disabled />
							<div className="text-container">
								<Typography>If approved, you will be financed with</Typography>
								<Typography className="financed-amount">{handleCurrency((value * lenderCoveragePercent) / 100 || 0, 'USD')}</Typography>
							</div>
						</div>
					)}

					<Divider className="divider" />
					<div className="button-container">
						<RenderButton className="button" color="primary" startIcon={<Add />} onClick={() => formik.submitForm()} disabled={!settled}>
							Create Order
						</RenderButton>
						{!settled && <Typography variant="caption">The order can't be created until the claim is settled</Typography>}
					</div>
				</div>
			</Drawer>
		</>
	);
});
