import React from 'react';
import { fromSettings } from 'store/selectors';
import { bindActionCreators } from 'redux';
import { getPlatformSettings } from 'store/actions';
import { connect } from 'react-redux';

class CurrencyNumber extends React.Component {
	render() {
		let {
			currencySymbol,
			symbolPosition,
			settings: { currency },
			amount,
			precision = 2
		} = this.props;

		if (!currencySymbol) {
			// eslint-disable-next-line prefer-destructuring
			currencySymbol = currency?.currencySymbol;
		}
		if (!symbolPosition) {
			// eslint-disable-next-line prefer-destructuring
			symbolPosition = currency?.symbolPosition;
		}

		let displayAmount = ''

		if(typeof amount !== "undefined" && !isNaN(parseFloat(amount))) {
			displayAmount = Math.round(`${amount || ''}`.replace(",","") * Math.pow(10, precision)) / Math.pow(10, precision)
			displayAmount = Number(displayAmount).toLocaleString(undefined, { minimumFractionDigits: precision })
		}


		return (
			<span style={{textAlign: 'right'}} className="amount-with-currency">{`${symbolPosition === 'prefix' ? currencySymbol || '' : ''}${displayAmount}${
				symbolPosition === 'suffix' ? currencySymbol || '' : ''
			}`}</span>
		);
	}
}

function mapStateToProps(state) {
	return {
		settings: fromSettings.getPlatformSettings(state)
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			getPlatformSettings
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyNumber);
