import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import contractsApi from 'services/api/contractsApi';
import { triggerNotification } from '../notifications/actions';
import { actionCreatorPreloaderOff, actionCreatorPreloaderOn } from '../preloader/actions';
import { history }  from '../../index';
import { saveAs } from 'file-saver';

import actions from "./actions"

export function* GET_SMART_CONTRACTS({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: true
		}
	});
	const result = yield call(contractsApi.getContracts, payload);
	if (result) {
		yield put({
			type: actions.SET_STATE,
			payload: {
				smartContracts: result
			}
		});
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_NEW_SMART_CONTRACT_DATA() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: true
		}
	});
	const result = yield call(contractsApi.getNewSmartContractData);
	if (result) {
		yield put({
			type: actions.SET_STATE,
			payload: {
				newSmartContractData: result
			}
		});
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* DOWNLOAD_SAMPLE_RATEPLAN() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: true
		}
	});
	const result = yield call(contractsApi.getSampleRateplan);
	console.log("result",result)
	if (result) {
		saveAs(result, 'sample-rateplan.xlsx');
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_PROJECTS() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingProjects: true
		}
	});
	try {
		const result = yield call(contractsApi.getProjects);
		if(result) {
			yield put({
				type: actions.SET_STATE,
				payload: {
					projects: result || []
				}
			});
		}
	} catch (error) {
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
		console.log('GET_PROJECTS ERROR');
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingProjects: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_PROJECT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingProjects: true
		}
	});
	try {
		const result = yield call(contractsApi.getProject, payload);

		if(result) {
			yield put({
				type: actions.SET_STATE,
				payload: {
					data: result || []
				}
			});
		}
	} catch (error) {
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
		console.log('GET_PROJECT ERROR');
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingProjects: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* CREATE_PROJECT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingProjects: true
		}
	});
	try {
		yield call(contractsApi.createProject, payload);

		yield put({ type: actions.GET_PROJECTS });
	} catch (error) {
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
		console.log('CREATE_PROJECT ERR');
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingProjects: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* DELETE_PROJECT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingProjects: true
		}
	});
	try {
		yield call(contractsApi.deleteProject, payload);

		yield put({ type: actions.GET_PROJECTS });
	} catch (error) {
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
		console.log('DELETE_PROJECT ERR');
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingProjects: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* UPDATE_PROJECT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingProjects: true
		}
	});
	try {
		yield call(contractsApi.updateProject, payload);

		yield put({ type: actions.GET_PROJECTS });
	} catch (error) {
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
		console.log('UPDATE_PROJECT ERR');
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingProjects: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_SMART_CONTRACT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(contractsApi.getContract, payload);
		if(result) {
			yield put({
				type: actions.SET_STATE,
				payload: {
					smartContract: result || {}
				}
			});
		}
	} catch (error) {
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
		console.log('GET_PROJECT ERROR');
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* SAVE_SMART_CONTRACT_DRAFT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: true
		}
	});

	const result = yield call(contractsApi.saveSmartContractDraft, payload);
	if (result && result.status === 'OK') {
		yield put(triggerNotification('Smart contract draft successfully created.', 'success'));
		yield history.push(`/smart-contracts/update/${result.contract._id}`);
	} else {
		yield put(triggerNotification('Unable to save smart contract draft at this time.', 'error'));
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* SECOND_SIGNATURE({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: true
		}
	});
	const result = yield call(contractsApi.secondSignature, payload);
	if (result && result.status === 'OK') {
		yield put(triggerNotification('Smart contract successfully signed.', 'success'));
		yield put({
			type: actions.GET_SMART_CONTRACT,
			payload: payload
		})
	} else {
		yield put(triggerNotification('Unable to sign smart contract at this time.', 'error'));
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* CREATE_SMART_CONTRACT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: true
		}
	});
	const result = yield call(contractsApi.createContract, payload);
	if (result && result.status === 'OK') {
		yield put(triggerNotification('Smart contract successfully created.', 'success'));
		yield history.push(`/smart-contracts/update/${result.contract._id}`);
	} else {
		yield put(triggerNotification('Unable to save smart contract at this time.', 'error'));
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* UPDATE_SMART_CONTRACT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: true
		}
	});
	const result = yield call(contractsApi.updateSmartContract, payload);
	if (result && result.status === 'OK') {
		yield put(triggerNotification('Smart contract successfully updated.', 'success'));
		yield put({
			type: actions.GET_SMART_CONTRACT,
			payload: payload._id
		})
	} else {
		yield put(triggerNotification('Unable to update smart contract at this time.', 'error'));
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* UPLOAD_RATES_FILE({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: true
		}
	});
	const result = yield call(contractsApi.uploadRates, payload);
	if (result && result.status === 'OK') {
		yield put(triggerNotification('Rates successfuly uploaded.', 'success'));
		yield put({
			type: actions.GET_RATE_FILES_DATA,
			payload: payload.get('tempId')
		})
	} else {
		yield put(triggerNotification('Unable to upload rates at this time.', 'error'));
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_RATE_FILES_DATA({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(contractsApi.getRateFileData, payload);
		if(result) {
			yield put({
				type: actions.SET_STATE,
				payload: {
					uploadedRateFiles: result || []
				}
			});
		}
	} catch (error) {
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
		console.log('GET_PROJECT ERROR');
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* DOWNLOAD_RATE_FILE({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(contractsApi.downloadRateFile, payload);
		if(result && result.status === 'OK') {
			window.open(result.url, '_blank');
		}
	} catch (error) {
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
		console.log('GET_PROJECT ERROR');
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export default function* rootSaga() {
	yield all([
		takeLatest(actions.GET_SMART_CONTRACTS, GET_SMART_CONTRACTS),
		takeLatest(actions.GET_SMART_CONTRACT, GET_SMART_CONTRACT),
		takeLatest(actions.GET_NEW_SMART_CONTRACT_DATA, GET_NEW_SMART_CONTRACT_DATA),
		takeLatest(actions.DOWNLOAD_SAMPLE_RATEPLAN, DOWNLOAD_SAMPLE_RATEPLAN),
		takeLatest(actions.SAVE_SMART_CONTRACT_DRAFT, SAVE_SMART_CONTRACT_DRAFT),
		takeLatest(actions.CREATE_SMART_CONTRACT, CREATE_SMART_CONTRACT),
		takeLatest(actions.UPDATE_SMART_CONTRACT, UPDATE_SMART_CONTRACT),
		takeLatest(actions.SECOND_SIGNATURE, SECOND_SIGNATURE),
		takeLatest(actions.UPLOAD_RATES_FILE, UPLOAD_RATES_FILE),
		takeLatest(actions.GET_RATE_FILES_DATA, GET_RATE_FILES_DATA),
		takeLatest(actions.DOWNLOAD_RATE_FILE, DOWNLOAD_RATE_FILE),

		takeLatest(actions.GET_PROJECTS, GET_PROJECTS),
		takeLatest(actions.GET_PROJECT, GET_PROJECT),
		takeLatest(actions.CREATE_PROJECT, CREATE_PROJECT),
		takeLatest(actions.DELETE_PROJECT, DELETE_PROJECT),
		takeLatest(actions.UPDATE_PROJECT, UPDATE_PROJECT),
	]);
}
