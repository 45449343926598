import React from "react";
import { PreLoaderContainer } from "containers";
import PropTypes from "prop-types";

const PublicTemplate = ({ children }) => (
  <div>
    <PreLoaderContainer />
    {children}
  </div>
);

PublicTemplate.propTypes = {
  children: PropTypes.element.isRequired
};

export default PublicTemplate;
