import { API } from "aws-amplify";

const explorerApiService = {
  getSmartContractExplorerGetDataRequest(address) {
    return API.get(
      "BLOCK_CHAIN_API",
      `/supplierClientContract/${address}/transactions`,
      {}
    )
      .then(response => ({
        address,
        response
      }))
      .catch(err => err);
  },
  getSmartContractFundedExplorerGetDataRequest(data) {
    const { smartContractAddresss, funderContractAddress } = data;

    return API.get(
      "BLOCK_CHAIN_API",
      `/supplierClientContract/${funderContractAddress}/fundedContract/${funderContractAddress}`,
      {}
    )
      .then(response => ({
        smartContractAddresss,
        response
      }))
      .catch(err => err);
  },
  getData() {
    return API.get("BLOCK_CHAIN_API", "/blocks", {})
      .then(response => response)
      .catch(err => err);
  },
  getWalletExplorerGetDataRequest(data) {
    return API.get("BLOCK_CHAIN_API", `/wallet/${data}/transactions`, {})
      .then(response => response)
      .catch(err => err);
  }
};

export default explorerApiService;
