import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { useTranslation } from 'react-i18next';
import { ListItem } from '@material-ui/core';
import privateMenuAccordionStyle from './style';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useSelector } from 'react-redux';

const PrivateMenuAccordion = props => {

const { 
	children, 
	menuIcon, 
	classes, 
	itemState, 
	menuOpen = true, 
	title, 
	setSection, 
	id, 
	sectionOpened
} = props;

const accountData = useSelector(state => (state?.myaccount?.accountData) || false);
const kycApproved = accountData?.kycApproved || false;
let displayMenu = accountData?.[id] || false;

if(!displayMenu && accountData?.[id]) displayMenu = accountData?.[id]?.active || false

if(!displayMenu || !kycApproved) return null;

 return (
	<ListItem className={classes.listItem}>
		<div className={classes.titleContainer}>
			<div	
				className={`${classes.navLink} ${sectionOpened &&
					"active"} ${!menuOpen && classes.navOpen}`}
					onClick={()=>setSection({[id] : !sectionOpened})}
				>
				{menuIcon}
				<span className={classes.text}>{title}</span>
				<span className={classes.chevronContainer}>
					<KeyboardArrowDownIcon className={ `${classes.chevron} ${sectionOpened ? classes.chevronOpened : ''}`}/>
				</span>
			</div>
			<div 
				  className={classes.options + (sectionOpened ? ` ${classes.opened}` : '')}
				>
					{children}
			</div>
		</div>
	</ListItem>
	);
};

PrivateMenuAccordion.propTypes = {
	selectedMenuItem: PropTypes.string.isRequired,
	getItemValue: PropTypes.func.isRequired,
	classes: PropTypes.shape().isRequired,
	menuOpen: PropTypes.bool.isRequired,
	handleDrawerToggle: PropTypes.func.isRequired
};

export default withStyles(privateMenuAccordionStyle)(PrivateMenuAccordion);
