import { initialState, URL_REDIRECT } from "./selectors";
import { URL_REDIRECT_REQUEST,CLEAN_URL_REDIRECT } from "./actions";

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case URL_REDIRECT_REQUEST:
      return {
        ...state,
        [URL_REDIRECT]: {
          url: payload.url,
          userId: payload.userId
        }
      };
    case CLEAN_URL_REDIRECT:
      return {
        ...state,
        [URL_REDIRECT]: {
          url: null,
          userId: null
        }
      };
    default:
      return state;
  }
};
