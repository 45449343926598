import React, { memo, useCallback, useEffect, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Grid,
	AccordionSummary,
	AccordionDetails,
	Accordion,
	Checkbox,
	FormControlLabel,
	FormControl,
	FormHelperText
} from '@material-ui/core';
import { RenderButton, RenderFormikInput } from 'components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SnackbarProvider, useSnackbar } from 'notistack';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';


export default memo(({ open, openCloseHandler }) => {
	const dispatch = useDispatch();

	const permissions = useSelector(state => (state.myaccount && state.myaccount.permissions) || []);
	const loading = useSelector(state => state.myaccount && state.myaccount.loading);
	const createUserSuccess = useSelector(state => state.myaccount && state.myaccount.createUserSuccess);
	const [bulkCheckboxes, setBulkCheckboxes] = useState([])
	const [canRenderBulkCheckboxes, setCanRenderBulkCheckboxes] = useState(false)
	const { t } = useTranslation('myaccount/general_information');

	const formik = useFormik({
		initialValues: {
			name: '',
			email: '',
			password: '',
			confirmPassword: '',
			permissions: []
		},
		onSubmit: (values, { resetForm }) => {
			dispatch({
				type: 'myaccount/CREATE_NEW_SUBACOUNT',
				payload: values
			});
			resetForm();
			openCloseHandler();
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required(t('please_input_bank_help', { label: t('name') })),
			email: Yup.string()
				.required(t('please_input_bank_help', { label: t('email') }))
				.email(t('invalid_input', { label: t('email') })),
			password: Yup.string()
				.required(t('please_input_bank_help', { label: t('password') }))
				.matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, t('must_contain_characters_validation')),
			passwordConfirmation: Yup.string()
				.required(t('please_input_bank_help', { label: t('password_confirmation') }))
				.oneOf([Yup.ref('password'), null], t('passwords_not_match')),
			permissions: Yup.array()
				.of(Yup.string())
				.required(t('select_one_permission'))
		})
		// enableReinitialize: true
	});

	const triggerAddRemovePermission = useCallback(
		key => {
			let perms = formik.values.permissions;
			if (perms.indexOf(key) > -1) {
				perms = perms.filter(permission => permission !== key);
				removeBulkCheckboxes(key)
				return formik.setFieldValue('permissions', perms);
			}
			perms.push(key);
			formik.handleBlur('permissions');
			return formik.setFieldValue('permissions', perms);
		},
		[formik.values.permissions]
	);

	useEffect(() => {
		let bulkCheckboxes = []
		if (permissions && typeof permissions === 'object') {
			Object.keys(permissions).map((key, i) => {
				bulkCheckboxes.push({ name: key, checked: false, index: i })
			});
			setBulkCheckboxes(bulkCheckboxes)
			setTimeout(() => setCanRenderBulkCheckboxes(true), 400)
		}
	}, [permissions])

	const bulkAddPermission = useCallback(
		(mainKey, ev, parentIndex) => {
			Object.keys(ev).map((subkey) => {
				if (Array.isArray(ev[subkey])) {
					ev[subkey].map((val) => {
						triggerAddRemovePermission(`${mainKey}.${subkey}.${val.key}`)
					});
				} else {
					triggerAddRemovePermission(`${mainKey}.${ev[subkey].key}`)
				}
			})
			bulkCheckboxes[parentIndex].checked = true
		}
	)

	const removeBulkCheckboxes = (key) => {
		const index = bulkCheckboxes.findIndex((checkbox) => checkbox.name === key.split('.')[0])
		if (index > -1) {
			bulkCheckboxes[index].checked = false
		}
	}

	useEffect(() => {
		if (open) {
			dispatch({ type: 'myaccount/GET_PERMISSIONS' });
			dispatch({
				type: 'myaccount/SET_STATE',
				payload: {
					createUserSuccess: false
				}
			});

			formik.resetForm();
		}

		return () => {
			formik.resetForm();
		};
	}, [open]);

	useEffect(() => {
		if (createUserSuccess && open && formik.isSubmitting) {
			openCloseHandler(true);
		}
	}, [createUserSuccess, open]);

	return (
		<Dialog maxWidth="md" open={open} className={styles.container}>
			<DialogTitle>{t('add_new_account')}</DialogTitle>
			<DialogContent>
				<form onSubmit={formik.handleSubmit}>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6}>
							<RenderFormikInput label={t('name')} name="name" formik={formik} />
						</Grid>
						<Grid item xs={12} md={6}>
							<RenderFormikInput type="email" label={t('email')} name="email" formik={formik} />
						</Grid>
						<Grid item xs={12} md={6}>
							<RenderFormikInput type="password" label={t('password')} name="password" formik={formik} />
						</Grid>
						<Grid item xs={12} md={6}>
							<RenderFormikInput type="password" label={t('confirm_password')} name="passwordConfirmation" formik={formik} />
						</Grid>
					</Grid>

					<h4>{t('permissions')}</h4>
					<FormControl fullWidth error={(formik.errors.permissions && formik.touched.permissions) || false}>
						{(permissions &&
							typeof permissions === 'object' &&
							Object.keys(permissions).map((key, i) => {
								const sectionName = key.replace(/([A-Z])/g, ' $1');
								return (
									<div className="permission-set" key={i}>
										<Accordion>
											<AccordionSummary className="expanded-summary" expandIcon={<ExpandMoreIcon />}>
												{t(key) || sectionName}
											</AccordionSummary>
											<AccordionDetails>
												{(permissions[key] && typeof permissions[key] === 'object' && (
													<div className="permission-sub-section">
													{(bulkCheckboxes.length >= 1 && canRenderBulkCheckboxes) && (
														<React.Fragment>
															<FormControlLabel
															key={new Date().getTime() + '_' + i}
															control={
															<Checkbox
															name={bulkCheckboxes[i].name}
															onChange={() => bulkAddPermission(key, permissions[key], i)}
															checked={ bulkCheckboxes[i].checked } />}
															label={t('all')} />
															<br />
														</React.Fragment>
														)}
														{Object.keys(permissions[key]).map((subkey, k) => {
															if (permissions[key][subkey] && permissions[key][subkey].key) {
																return (
																	<FormControlLabel
																		key={k}
																		control={
																			<Checkbox
																				checked={formik.values.permissions.indexOf(`${key}.${permissions[key][subkey].key}`) > -1}
																				onChange={() => triggerAddRemovePermission(`${key}.${permissions[key][subkey].key}`)}
																			/>
																		}
																		label={t('permission_key', { key: t(permissions[key][subkey].key.split("-")?.[0]), permission: t(permissions[key][subkey].key.split("-")?.[1]) }) || permissions[key][subkey].description}
																	/>
																);
															}
															let subSectionName = subkey.replace(/([A-Z])/g, ' $1');
															subSectionName = subSectionName.charAt(0).toUpperCase() + subSectionName.slice(1);
															return (
																<div>
																	<h5>{t(subkey) || subSectionName}</h5>
																	{(permissions[key][subkey] &&
																		Array.isArray(permissions[key][subkey]) &&
																		permissions[key][subkey].map((perm, j) => (
																			<FormControlLabel
																				key={j}
																				control={
																					<Checkbox
																						checked={formik.values.permissions.indexOf(`${key}.${subkey}.${perm.key}`) > -1}
																						onChange={() => triggerAddRemovePermission(`${key}.${subkey}.${perm.key}`)}
																					/>
																				}
																				label={t('permission_key', { key: t(permissions[key][subkey][j].key.split("-")?.[0]), permission: t(permissions[key][subkey][j].key.split("-")?.[1]) }) || perm.description}
																			 />
																		))) ||
																		''}
																</div>
															);
														})}
													</div>
												)) ||
													''}
											</AccordionDetails>
										</Accordion>
									</div>
								);
							})) ||
							''}
						{(formik.errors.permissions && formik.touched.permissions && <FormHelperText>{formik.errors.permissions || ''}</FormHelperText>) || ''}
					</FormControl>
				</form>
			</DialogContent>
			<DialogActions>
				<RenderButton onClick={openCloseHandler} color="transparent">
					{t('cancel')}
				</RenderButton>
				<RenderButton color="primary" onClick={formik.handleSubmit} disabled={loading}>
					{t('create_account')}
				</RenderButton>
			</DialogActions>
		</Dialog>
	);
});
