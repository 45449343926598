import { API, Auth } from 'aws-amplify';
import axios from 'axios';

const EscrowManagingApi = {
	getAvailableClaims() {
		return API.get('ACP_SERVICE_API', '/escrow-orders/available-claims')
			.then(response => response)
			.catch(err => {
				throw err;
			});
	},
	createOrder(payload) {
		const request = {
			body: payload
		};
		return API.post('ACP_SERVICE_API', '/escrow-orders/create', request)
			.then(response => response)
			.catch(err => {
				throw err;
			});
	},
	getOrders() {
		return API.get('ACP_SERVICE_API', '/escrow-orders/managing')
			.then(response => response)
			.catch(err => {
				throw err;
			});
	},
	createSmartContract(payload) {
		const request = {
			body: payload
		};
		return API.post('ACP_SERVICE_API', '/contracts/create', request)
			.then(response => response)
			.catch(err => {
				throw err;
			});
	},
	createSelfFulfillmentSmartContract(payload) {
		const request = {
			body: payload
		};
		return API.post('ACP_SERVICE_API', '/contracts/create/self-fullfillment', request)
			.then(response => response)
			.catch(err => {
				throw err;
			});
	},
	uploadFulfillmentProof(formData) {
		return API.post('ACP_SERVICE_API', '/contracts/upload/fullfillment', { body: formData })
			.then(response => response)
			.catch(err => {
				throw err;
			});
	},
	getPofUrl(payload) {
		return API.post('ACP_SERVICE_API', `/contracts/pof/download`, { body: payload })
			.then(response => response)
			.catch(err => {
				throw err;
			});
	},
	getParticipatingPartners() {
		return API.get('ACP_SERVICE_API', '/escrow-orders/participating-partners')
			.then(response => response)
			.catch(err => {
				throw err;
			});
	},
	getContracts() {
		return API.get('ACP_SERVICE_API', '/contracts')
			.then(response => response)
			.catch(err => {
				throw err;
			});
	},
	updateContractStatus(payload) {
		return API.patch('ACP_SERVICE_API', `/contracts/updateStatus`, { body: payload })
			.then(response => response)
			.catch(err => {
				throw err;
			});
	},
	updateContractPof(payload) {
		return API.post('ACP_SERVICE_API', `/contracts/pof/updateStatus`, { body: payload })
			.then(response => response)
			.catch(err => {
				throw err;
			});
	},
	createSmartContractWithNewPartner(payload) {
		return API.post('ACP_SERVICE_API', '/contracts/invite/partner', { body: payload })
			.then(response => response)
			.catch(err => {
				throw err;
			});
	},
	updateOrderStatus(payload) {
		return API.patch('ACP_SERVICE_API', '/escrow-orders/update/order', { body: payload })
			.then(response => response)
			.catch(err => {
				throw err;
			});
	}
};

export default EscrowManagingApi;
