import React from 'react';
import { PrivateTemplate, BreadCrumb } from 'components';
import { InterconnectionsContainer } from 'containers';
import { useTranslation } from 'react-i18next';

const InterconnectionsPage = props => {
	const { t } = useTranslation('tradingpartners/interconnections');

	return (
		<PrivateTemplate>
			<BreadCrumb name={t('title')} firstLevel="Home" secondLevel={t('title')} />

			<InterconnectionsContainer {...props} />
		</PrivateTemplate>
	);
};
export default InterconnectionsPage;
