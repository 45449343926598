import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {Grid} from "@material-ui/core";
import { PublicTemplate } from "components";
import { RecoveryPasswordContainer } from "containers";
import resetStyle from "./style";

const PasswordResetPage = ({...props}) => {
  const { classes } = props;
  return (
      <PublicTemplate>
          <Grid container justify="center" alignItems="center" className={classes.content}>
              <Grid item>
                <RecoveryPasswordContainer />
              </Grid>
          </Grid>
      </PublicTemplate>
  );
};

export default withStyles(resetStyle)(PasswordResetPage);
