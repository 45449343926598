import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrivateTemplate, BreadCrumb } from 'components';
import ReportsScreen from 'screens/Reports';

const ReportPage = props => {
	const { t } = useTranslation('reports/general');

	return (
		<PrivateTemplate>
			<BreadCrumb name={t('title')} firstLevel="Home" secondLevel={t('title')} />
			<ReportsScreen {...props} />
		</PrivateTemplate>
	);
};

export default ReportPage;
