import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import CardContent from "@material-ui/core/CardContent";
import { PreLoaderContainer } from "containers";
import withStyles from "@material-ui/core/styles/withStyles";
import breadcrumbsStyle from "./style";

const BreadCrumb = props => {
	const { classes, secondLevel, children } = props;
	return (
		<Paper className={classes.breadcrumbs}>
			<CardContent>
				<ul className={classes.breadcrumbsList}>
					<li>{secondLevel}</li>
				</ul>
				{children && (
					<div className={classes.breadcrumbsChildren}>{children}</div>
				)}
			</CardContent>
			<div className={classes.preloader}>
				<PreLoaderContainer />
			</div>

		</Paper>
	);
};

BreadCrumb.propTypes = {
	classes: PropTypes.shape().isRequired,
	name: PropTypes.string.isRequired,
	secondLevel: PropTypes.string.isRequired,
	children: PropTypes.node
};

export default withStyles(breadcrumbsStyle)(BreadCrumb);
