import {
	actionCreatorPreloaderOff,
	actionCreatorPreloaderOn,
	createExternalInvoiceError,
	createExternalInvoiceSuccess,
	loadExternalInvoicePreviewDataError,
	loadExternalInvoicePreviewDataSuccess,
	loadExternalRatesError,
	loadExternalRatesSuccess,
	loadSmartContractSuccess,
	loadVendorRatesError,
	loadVendorRatesSuccess,
	setRoutesSmartContractsList,
	setRoutesSmartContractsListFilters,
	setSmartContractRouteData,
	uploadExternalRatePlanError,
	uploadExternalRatePlanSuccess,
	uploadVendorRatePlanError,
	uploadVendorRatePlanSuccess,
	triggerNotification
} from 'store/actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import routesApiService from 'services/api/routesApi';
import smartContractsApi from 'services/api/SmartContractsApi';
import myAccountApiService from 'services/api/MyAccountApi';

import actions from './actions';

export function* getRouteSmartContracts(action) {
	try {
		yield put(actionCreatorPreloaderOn());
		const result = yield call(routesApiService.getSmartContracts, action.payload);
		yield put(setRoutesSmartContractsList(result));
		yield put(actionCreatorPreloaderOff());
	} catch (err) {
		console.log(err);
	}
}

export function* getRouteSmartContractsFilters(action) {
	try {
		yield put(actionCreatorPreloaderOn());
		const result = yield call(routesApiService.getRouteSmartContractsFilters, action.payload);
		yield put(setRoutesSmartContractsListFilters(result));
		yield put(actionCreatorPreloaderOff());
	} catch (err) {
		console.log(err);
	}
}

export function* getSmartContractRouteData(action) {
	try {
		yield put(actionCreatorPreloaderOn());
		const result = yield call(routesApiService.getRoutesForSmartContract, action.payload);

		yield put(setSmartContractRouteData(result));

		yield put(actionCreatorPreloaderOff());
	} catch (err) {
		console.log(err);
	}
}

export function* postNewRoute(action) {
	try {
		yield put(actionCreatorPreloaderOn());
		yield call(routesApiService.createNewRoute, action.payload.smartContractId, action.payload.data);

		const routes = yield call(routesApiService.getRoutesForSmartContract, action.payload.smartContractId);

		yield put(setSmartContractRouteData(routes));

		yield put(actionCreatorPreloaderOff());
	} catch (err) {
		console.log(err);
	}
}

export function* postUpdateRoute(action) {
	try {
		yield put(actionCreatorPreloaderOn());
		yield call(routesApiService.updateRoute, action.payload.smartContractId, action.payload.data);

		const result = yield call(routesApiService.getRoutesForSmartContract, action.payload.data.smartContractId);

		yield put(setSmartContractRouteData(result));

		yield put(actionCreatorPreloaderOff());
	} catch (err) {
		console.log(err);
	}
}

export function* postDeleteRoute(action) {
	try {
		yield put(actionCreatorPreloaderOn());
		yield call(routesApiService.removeRoute, action.payload.smartContractId, action.payload.data);

		const result = yield call(routesApiService.getRoutesForSmartContract, action.payload.data.smartContractId);

		yield put(setSmartContractRouteData(result));

		yield put(actionCreatorPreloaderOff());
	} catch (err) {
		console.log(err);
	}
}

export function* updateSmartContract(action) {
	try {
		yield put(actionCreatorPreloaderOn());

		// TODO - Tidy this up - its a fast hack/fix
		const payload = action.payload.data;
		payload.ingressMaxSessions = payload.ingressMaxSessions ? payload.ingressMaxSessions.toString() : '';
		payload.ingressCps = payload.ingressCps ? payload.ingressCps.toString() : '';

		yield call(smartContractsApi.updateSmartContractRoutingDetails, action.payload.smartContractId, payload);

		yield call(routesApiService.updateSmartContractIngress, action.payload.smartContractId);

		const smartcontract = yield call(smartContractsApi.getSmartContract, action.payload.smartContractId);
		yield put(loadSmartContractSuccess(smartcontract));

		const routes = yield call(routesApiService.getRoutesForSmartContract, action.payload.smartContractId);

		yield put(setSmartContractRouteData(routes));

		yield put(actionCreatorPreloaderOff());
	} catch (err) {
		console.log(err);
	}
}

export function* uploadVendorRates(action) {
	try {
		const uploadRatesRes = yield call(routesApiService.uploadRates, action.payload.tempId, action.payload.ratePlanFile);

		yield put(uploadVendorRatePlanSuccess(action.payload.tempId, uploadRatesRes));
	} catch (err) {
		yield put(uploadVendorRatePlanError(action.payload.tempId, 'An error occured during the upload'));
	}
}

export function* uploadExternalRates(action) {
	try {
		const uploadRatesRes = yield call(routesApiService.uploadExternalRates, action.payload.tempId, action.payload.ratePlanFile);

		yield put(uploadExternalRatePlanSuccess(action.payload.tempId, uploadRatesRes));
	} catch (err) {
		yield put(uploadExternalRatePlanError(action.payload.tempId, 'An error occured during the upload'));
	}
}

export function* getVendorRates(action) {
	try {
		const ratesResult = yield call(routesApiService.getVendorRates, action.payload.externalProfileId, action.payload.options);
		yield put(loadVendorRatesSuccess(action.payload.externalProfileId, ratesResult));
	} catch (err) {
		const error = err.message || err;
		console.error(err);
		yield put(loadVendorRatesError(action.payload.externalProfileId, 'Something went wrong'));
	}
}

export function* getExternalRates(action) {
	try {
		const ratesResult = yield call(routesApiService.getExternalRates, action.payload.externalProfileId, action.payload.options);
		yield put(loadExternalRatesSuccess(action.payload.externalProfileId, ratesResult));
	} catch (err) {
		yield put(loadExternalRatesError(action.payload.externalProfileId, 'Something went wrong'));
	}
}

export function* getExternalInvoicePreview(action) {
	try {
		const externalInvoicePreviewData = yield call(routesApiService.getExternalInvoicePreviewData, action.payload.externalProfileId);
		yield put(loadExternalInvoicePreviewDataSuccess(action.payload.externalProfileId, externalInvoicePreviewData));
	} catch (err) {
		yield put(loadExternalInvoicePreviewDataError(action.payload.externalProfileId, 'Something went wrong'));
	}
}

export function* postCreateExternalInvoice(action) {
	try {
		const resp = yield call(routesApiService.postSaveExternalInvoiceData, action.payload);
		yield put(createExternalInvoiceSuccess(action.payload.tempId, resp));
	} catch (err) {
		yield put(createExternalInvoiceError(action.payload.tempId, 'Something went wrong'));
	}
}

// new way
export function* GET_COUNTRIES() {
	try {
		const result = yield call(myAccountApiService.getCountries);
		if (result) {
			yield put({
				type: 'routes/SET_STATE',
				payload: {
					countries: result
				}
			});
		}
		//
	} catch (error) {}
}
export function* GET_STATES({ payload }) {
	try {
		const result = yield call(myAccountApiService.getStates, payload);
		if (result) {
			yield put({
				type: 'routes/SET_STATE',
				payload: {
					states: result
				}
			});
		}
		//
	} catch (error) {}
}

export function* GET_LIST_FILTERS({ payload: { filterType, filters } }) {
	// yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingFilters: true
		}
	});

	const response = yield call(routesApiService.getListFilters, filterType, filters);
	if (response) {
		yield put({
			type: 'routes/SET_STATE',
			payload: {
				filters: response
			}
		});
	}

	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingFilters: false
		}
	});
	// yield put(actionCreatorPreloaderOff());
}

export function* GET_LIST({ payload: { filterType, filters } }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loading: true
		}
	});

	const response = yield call(routesApiService.getList, filterType, filters);
	if (response) {
		yield put({
			type: 'routes/SET_STATE',
			payload: {
				list: response
			}
		});
	}

	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_CONTRACT_DETAILS({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loading: true
		}
	});

	const response = yield call(routesApiService.getContractDetails, payload);
	if (response) {
		yield put({
			type: 'routes/SET_STATE',
			payload: {
				details: response
			}
		});
	}

	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* UPDATE_ROUTE_SETTINGS({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loading: true
		}
	});

	const response = yield call(routesApiService.updateRouteSettings, payload);
	if (response) {
		yield put({
			type: 'routes/GET_ROUTE_DETAILS',
			payload: payload.smartContractId
		});
	}

	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_ROUTE_CODE_MATCHES({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'routes/SET_STATE',
		payload: {
			routeCodeLoading: true
		}
	});

	const response = yield call(routesApiService.getRouteCodeMatches, payload);
	if (response) {
		yield put({
			type: 'routes/SET_STATE',
			payload: {
				routeCodeMatches: response
			}
		});
	}

	yield put({
		type: 'routes/SET_STATE',
		payload: {
			routeCodeLoading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_UPDATE_ROUTE_CODE_MATCHES({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'routes/SET_STATE',
		payload: {
			routeCodeLoading: true
		}
	});

	const response = yield call(routesApiService.getRouteCodeMatches, payload);
	if (response) {
		yield put({
			type: 'routes/SET_STATE',
			payload: {
				routeUpdateCodeMatches: response
			}
		});
	}

	yield put({
		type: 'routes/SET_STATE',
		payload: {
			routeCodeLoading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_ROUTES_LIST({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingRoutesList: true
		}
	});

	const response = yield call(routesApiService.getContractRoutes, payload);
	if (response && Array.isArray(response)) {
		yield put({
			type: 'routes/SET_STATE',
			payload: {
				routesList: response
			}
		});
	}

	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingRoutesList: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}
export function* GET_ROUTE_DETAILS({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingRouteUpdate: true
		}
	});

	const response = yield call(routesApiService.getRouteDetails, payload);
	if (response) {
		yield put({
			type: 'routes/SET_STATE',
			payload: {
				routeDetails: response
			}
		});
	}

	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingRouteUpdate: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}
export function* CREATE_NEW_ROUTE({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingCreate: true
		}
	});

	const response = yield call(routesApiService.createNewRoute, payload.smartContractId, payload);
	if (response && response.status === 'OK') {
		yield put(triggerNotification('Route saved succesfully', 'success'));
		yield put({ type: 'routes/GET_ROUTES_LIST', payload: payload.smartContractId });
	} else {
		yield put(triggerNotification((response.response && response.response.data && response.response.data.message) || 'Unable to create new route', 'error'));
	}

	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingCreate: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}
export function* UPDATE_ROUTE({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingRouteUpdate: true
		}
	});

	const response = yield call(routesApiService.updateRoute, payload);
	if (response && response.status === 'OK') {
		yield put(triggerNotification('Route saved succesfully', 'success'));
		yield put({ type: 'routes/GET_ROUTES_LIST', payload: payload.smartContractId });
	} else {
		yield put(triggerNotification('Unable to create new route', 'error'));
	}

	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingRouteUpdate: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}
export function* DELETE_ROUTE({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingRoutesList: true
		}
	});
	const response = yield call(routesApiService.deleteRoute, payload);
	if (response && response.status === 'OK') {
		yield put(triggerNotification('Route removed succesfully', 'success'));
		yield put({ type: 'routes/GET_ROUTES_LIST', payload: payload.smartContractId });
	} else {
		yield put(triggerNotification('Unable to remove route', 'error'));
	}

	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingRoutesList: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}
export function* GET_PARTITION_INFORMATION() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingPartitionInfo: true
		}
	});

	const response = yield call(routesApiService.getPartitionInfo);
	if (response) {
		yield put({
			type: 'routes/SET_STATE',
			payload: {
				partitionInfo: response
			}
		});
	}

	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingPartitionInfo: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}
export function* GET_HARDWARE_PROFILE_OPTIONS() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingHardwareProfile: true
		}
	});

	const response = yield call(routesApiService.getHardwareProfileOptions);
	if (response) {
		yield put({
			type: 'routes/SET_STATE',
			payload: {
				hardwareProfileOptions: response
			}
		});
	}

	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingHardwareProfile: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}
export function* GET_ORIGIN_HARDWARE_PROFILES() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingOriginationHardwareProfiles: true
		}
	});

	const response = yield call(routesApiService.getOriginHardwareProfiles);
	if (response) {
		yield put({
			type: 'routes/SET_STATE',
			payload: {
				originationHardwareProfiles: response
			}
		});
	}

	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingOriginationHardwareProfiles: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}
export function* GET_TERMINATION_HARDWARE_PROFILES() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingTerminationHardwareProfiles: true
		}
	});

	const response = yield call(routesApiService.getTerminationHardwareProfiles);
	if (response) {
		yield put({
			type: 'routes/SET_STATE',
			payload: {
				terminationHardwareProfiles: response
			}
		});
	}

	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingTerminationHardwareProfiles: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}
export function* UPLOAD_HARDWARE_PROFILE_RATE({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingHardwareProfileRateUpload: true
		}
	});
	let uploadService = routesApiService.uploadInternalRate;
	if (payload.type === 'external') {
		uploadService = routesApiService.uploadExternalRate;
	}
	const response = yield call(uploadService, payload.tempId, payload.file);

	if (response && response.status === 'OK') {
		yield put(triggerNotification('Rateplan added succesfully', 'success'));
		yield put({
			type: 'routes/SET_STATE',
			payload: {
				hardwareProfileRateUploadData: response
			}
		});
	} else {
		yield put(triggerNotification('Unable to add rateplan', 'error'));
	}

	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingHardwareProfileRateUpload: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}
export function* GET_HARDWARE_PROFILE({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingHardwareProfileUpdate: true
		}
	});
	const response = yield call(routesApiService.getHardwareProfile, payload);
	if (response && !response.status) {
		yield put({
			type: 'routes/SET_STATE',
			payload: {
				hardwareProfileData: response
			}
		});
	} else {
		yield put(triggerNotification('Unable to get connection profile', 'error'));
	}

	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingHardwareProfileUpdate: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}
export function* GET_HARDWARE_PROFILE_RATES({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingHardwareProfileUpdate: true
		}
	});
	const response = yield call(routesApiService.getHardwareProfileRates, payload, payload.type);
	if (response && !response.status) {
		yield put({
			type: 'routes/SET_STATE',
			payload: {
				hardwareProfileRateData: response
			}
		});
	} else {
		yield put(triggerNotification('Unable to get connection profile', 'error'));
	}

	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingHardwareProfileUpdate: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}
export function* GET_HARDWARE_PROFILE_VENDOR_RATES({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingHardwareProfileUpdate: true
		}
	});
	const response = yield call(routesApiService.getHardwareProfileExternalRates, payload);
	if (response && !response.status) {
		yield put({
			type: 'routes/SET_STATE',
			payload: {
				hardwareProfileRateData: response
			}
		});
	} else {
		yield put(triggerNotification('Unable to get connection profile', 'error'));
	}

	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingHardwareProfileUpdate: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}
export function* CREATE_HARDWARE_PROFILE({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingHardwareProfile: true
		}
	});
	const response = yield call(routesApiService.createHardwareProfile, payload);
	if (response && response.status === 'OK') {
		yield put(triggerNotification('Connection profile created succesfully', 'success'));

		if (payload.type === 'ingress') {
			yield put({ type: 'routes/GET_ORIGIN_HARDWARE_PROFILES' });
		} else {
			yield put({ type: 'routes/GET_TERMINATION_HARDWARE_PROFILES' });
		}
	} else {
		yield put(triggerNotification('Unable to create connection profile', 'error'));
	}

	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingHardwareProfile: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* UPDATE_HARDWARE_PROFILE({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingHardwareProfile: true
		}
	});
	const response = yield call(routesApiService.updateHardwareProfile, payload);
	if (response && response.status === 'OK') {
		yield put(triggerNotification('Connection profile updated succesfully', 'success'));

		if (payload.type === 'ingress') {
			yield put({ type: 'routes/GET_ORIGIN_HARDWARE_PROFILES' });
		} else {
			yield put({ type: 'routes/GET_TERMINATION_HARDWARE_PROFILES' });
		}
	} else {
		yield put(triggerNotification('Unable to update connection profile', 'error'));
	}

	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingHardwareProfileRateUpload: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}
export function* DELETE_HARDWARE_PROFILE({ payload: { hardwareProfileId, type }, payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingHardwareProfile: true
		}
	});
	const response = yield call(routesApiService.deleteHardwareProfile, hardwareProfileId);
	if (response && response.status === 'OK') {
		yield put(triggerNotification('Connection profile delete succesfully', 'success'));

		if (type === 'origination') {
			yield put({ type: 'routes/GET_ORIGIN_HARDWARE_PROFILES' });
		} else {
			yield put({ type: 'routes/GET_TERMINATION_HARDWARE_PROFILES' });
		}
	} else {
		yield put(triggerNotification('Unable to delete connection profile', 'error'));
	}

	yield put({
		type: 'routes/SET_STATE',
		payload: {
			loadingHardwareProfile: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export default function* rootSaga() {
	yield all([takeEvery(actions.GET_COUNTRIES, GET_COUNTRIES)]);
	yield all([takeEvery(actions.GET_STATES, GET_STATES)]);

	yield all([takeEvery(actions.GET_LIST_FILTERS, GET_LIST_FILTERS)]);
	yield all([takeEvery(actions.GET_LIST, GET_LIST)]);
	yield all([takeEvery(actions.GET_CONTRACT_DETAILS, GET_CONTRACT_DETAILS)]);
	yield all([takeEvery(actions.UPDATE_ROUTE_SETTINGS, UPDATE_ROUTE_SETTINGS)]);
	yield all([takeEvery(actions.GET_ROUTE_CODE_MATCHES, GET_ROUTE_CODE_MATCHES)]);
	yield all([takeEvery(actions.GET_UPDATE_ROUTE_CODE_MATCHES, GET_UPDATE_ROUTE_CODE_MATCHES)]);
	yield all([takeEvery(actions.GET_ROUTES_LIST, GET_ROUTES_LIST)]);
	yield all([takeEvery(actions.GET_ROUTE_DETAILS, GET_ROUTE_DETAILS)]);
	yield all([takeEvery(actions.CREATE_NEW_ROUTE, CREATE_NEW_ROUTE)]);
	yield all([takeEvery(actions.UPDATE_ROUTE, UPDATE_ROUTE)]);
	yield all([takeEvery(actions.DELETE_ROUTE, DELETE_ROUTE)]);

	yield all([takeEvery(actions.GET_HARDWARE_PROFILE_OPTIONS, GET_HARDWARE_PROFILE_OPTIONS)]);
	yield all([takeEvery(actions.GET_PARTITION_INFORMATION, GET_PARTITION_INFORMATION)]);
	yield all([takeEvery(actions.GET_ORIGIN_HARDWARE_PROFILES, GET_ORIGIN_HARDWARE_PROFILES)]);
	yield all([takeEvery(actions.GET_TERMINATION_HARDWARE_PROFILES, GET_TERMINATION_HARDWARE_PROFILES)]);
	yield all([takeEvery(actions.UPLOAD_HARDWARE_PROFILE_RATE, UPLOAD_HARDWARE_PROFILE_RATE)]);
	yield all([takeEvery(actions.GET_HARDWARE_PROFILE, GET_HARDWARE_PROFILE)]);
	yield all([takeEvery(actions.GET_HARDWARE_PROFILE_RATES, GET_HARDWARE_PROFILE_RATES)]);

	yield all([takeEvery(actions.CREATE_HARDWARE_PROFILE, CREATE_HARDWARE_PROFILE)]);
	yield all([takeEvery(actions.UPDATE_HARDWARE_PROFILE, UPDATE_HARDWARE_PROFILE)]);
	yield all([takeEvery(actions.DELETE_HARDWARE_PROFILE, DELETE_HARDWARE_PROFILE)]);
}
