import React from "react";

const RenderField = ({
  input,
  label,
  type,
  className,
  name,
  meta: { touched, error },
  ...custom
}) => {
  if (type === "checkbox") {
    return (
      <input
        {...input}
        {...custom}
        type={type}
        className={className + (touched && error ? " is-invalid" : "")}
      />
    );
  }
  return (
    <div>
      <input
        {...input}
        {...custom}
        type={type}
        className={className + (touched && error ? " is-invalid" : "")}
      />
      {touched && error && <span className="label label-danger">{error}</span>}
    </div>
  );
};

export default RenderField;
