const actions = {
	GET_AVAILABLE_CLAIMS: 'escrowManaging/GET_AVAILABLE_CLAIMS',
	GET_PARTICIPATING_PARTNERS: 'escrowManaging/GET_PARTICIPATING_PARTNERS',
	GET_ORDERS: 'escrowManaging/GET_ORDERS',
	CREATE_NEW_ORDER: 'escrowManaging/CREATE_NEW_ORDER',
	CREATE_NEW_SMART_CONTRACT: 'escrowManaging/CREATE_NEW_SMART_CONTRACT',
	CREATE_SMART_CONTRACT_WITH_NEW_PARTNER: 'escrowManaging/CREATE_SMART_CONTRACT_WITH_NEW_PARTNER',
	UPLOAD_FULFILLMENT_PROOF: 'escrowManaging/UPLOAD_FULFILLMENT_PROOF',
	GET_POF_URL: 'escrowManaging/GET_POF_URL',
	UPDATE_CONTRACT_POF: 'escrowManaging/UPDATE_CONTRACT_POF',
	UPDATE_ORDER_STATUS: 'escrowManaging/UPDATE_ORDER_STATUS',
	GET_CONTRACTS: 'escrowManaging/GET_CONTRACTS',
	UPDATE_CONTRACT_STATUS: 'escrowManaging/UPDATE_CONTRACT_STATUS',
	SET_LOADING: 'escrowManaging/SET_LOADING',
	SET_STATE: 'escrowManaging/SET_STATE',
	CREATE_SELF_FULFILLMENT_SMART_CONTRACT: 'escrowManaging/CREATE_SELF_FULFILLMENT_SMART_CONTRACT'
};

export default actions;
