import React, { memo, useState } from 'react';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import { AuthManager } from 'components';
import { GeneralInformationTab, UserAdministrationTab, SecurityTab, LegalAgreementsTab, LenderSettingsTab, ApiKeysTab } from './components';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

export default memo(() => {
	const [selectedTab, setSelectedTab] = useState('general-information');
	const { t } = useTranslation();
	return (
		<div className={styles.container}>
			<div className="head-tabs">
				<AppBar position="static">
					<Tabs value={selectedTab} onChange={(e, value) => setSelectedTab(value)}>
						<Tab value="general-information" label={t('general_information')} />
						<AuthManager permissions={['myaccount.userAdministration']} value="user-administration">
							<Tab value="user-administration" label={t('user_administration')} onChange={(e, value) => setSelectedTab(value)} />
						</AuthManager>
						<Tab value="security" label={t('security')} />
						{/* <Tab value="contacts" label="Contacts" /> */}
						<AuthManager permissions={['myaccount.agreements']} value="agreements">
							<Tab value="agreements" label={t('agreements')} onChange={(e, value) => setSelectedTab(value)} />
						</AuthManager>
						<AuthManager permissions={['myaccount.lender']} userRole={['funder']} value="lenderSettings">
							<Tab value="lenderSettings" label={t('lender')} onChange={(e, value) => setSelectedTab(value)} />
						</AuthManager>
						<AuthManager permissions={['myaccount.userAdministration']} value="user-administration">
							<Tab value="apiKeys" label="API Keys" onChange={(e, value) => setSelectedTab(value)} />
						</AuthManager>
					</Tabs>
				</AppBar>
			</div>
			<div>
				{(selectedTab === 'general-information' && <GeneralInformationTab />) || ''}
				{(selectedTab === 'user-administration' && <UserAdministrationTab />) || ''}
				{(selectedTab === 'security' && <SecurityTab />) || ''}
				{(selectedTab === 'agreements' && <LegalAgreementsTab />) || ''}
				{(selectedTab === 'lenderSettings' && <LenderSettingsTab />) || ''}
				{(selectedTab === 'apiKeys' && <ApiKeysTab />) || ''}
			</div>
		</div>
	);
});
