import React, { Component } from "react";
import { Field } from "redux-form";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide
} from "@material-ui/core";
import { RenderButton } from "components";

const Transition = props => {
  return <Slide direction="up" {...props} />;
};

class AcceptInterconnectionsForm extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const {
      handleSubmit,
      hideAcceptInterconnections,
      initialValues
    } = this.props;
    return (
      <Dialog
        open
        onBackdropClick={hideAcceptInterconnections}
        TransitionComponent={Transition}
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle>Accept request</DialogTitle>
          <DialogContent>
            <p>The following company would like to interconnect with you:</p>
            Company name:{" "}
            <span id="company-name">{initialValues.participantName}</span>
            <Field
              component="input"
              readOnly
              type="hidden"
              className="form-control"
              name="connectGuid"
              placeholder=""
            />
          </DialogContent>
          <DialogActions>
            <RenderButton color="primary" type="submit">
              Accept
            </RenderButton>
            <RenderButton
              color="danger"
              onClick={() => hideAcceptInterconnections}
            >
              Cancel
            </RenderButton>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}
AcceptInterconnectionsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  hideAcceptInterconnections: PropTypes.func.isRequired
};
export default AcceptInterconnectionsForm;
