import React from 'react';
import { PrivateTemplate, BreadCrumb } from 'components';
import { FinancialContainer } from 'containers';
import { useTranslation } from 'react-i18next';

const FinancialPage = () => {
	const { t } = useTranslation('financials/general');

	return (
		<PrivateTemplate>
			<BreadCrumb name={t('title')} firstLevel="Home" secondLevel={t('title')} />
			<FinancialContainer />
		</PrivateTemplate>
	);
};

export default FinancialPage;
