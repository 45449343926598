import React from "react";
import { Field, reduxForm } from "redux-form";
import { RenderCheckbox } from "components";

class FundingOfferActiveForm extends React.Component {
  render() {
    const { handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Field
          component={RenderCheckbox}
          name="dailyTesspayFee"
          label="Subtract TessPay Fees Daily"
        />
      </form>
    );
  }
}

export default reduxForm({
  form: "FundingOfferActiveForm",
  destroyOnUnmount: true,
  enableReinitialize: true,
  onChange: (values, dispatch, props) => {
    props.submit(values);
  },
})(FundingOfferActiveForm);
