import { CardContent, Grid, Paper } from '@material-ui/core';
import {
	ConfirmFileUpload,
	RatesTable,
	RenderBadge,
	RenderButton,
	SmartContractTermsConditions,
	SmartContractsCommercialTerms,
	SmartContractsCommercialTermsReadOnly,
	SmartContractsTechnicalTerms,
	SmartContractsTechnicalTermsReadOnly
} from 'components';
import { Field, FieldArray } from 'redux-form';
import { SmartContractDestinationCodeContainer, SmartContractRatesContainer } from 'containers';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';

const SmartContractsEditForm = props => {
	const { t } = useTranslation(['smartcontracts/edit', 'translations']);

	const {
		status,
		warning,
		smartContract: { contract = {}, signedAt, lockContract, signatures, ratePlans, userIsCustomer, participant, userSignedContract },
		dataNewSmartContract: { tempId },
		handleSubmit,
		changeStatus,
		ratesData,
		ratePlanData,
		getRatePlanRates,
		uploadRatePlan,
		uploadImmediateRatePlan,
		clearUploadError,
		members,
		toggleRequestFundingModal,
		requestFundingModal,
		sendRequestFunding,
		sendProposedRatesAction,
		setRateRefreshOptions,
		rateRefreshOptions,
		submitSucceeded,
		pristine,
		toggleImmediateRateChangeDialog,
		showImmediateRateChangeDialog
	} = props;
	/* eslint-disable */
  const ratePlansToDisplay =
    ratePlanData && ratePlanData.data
      ? ratePlans.concat(ratePlanData.data)
      : ratePlans;
  const useTempId = (contract && contract.tempId) || tempId;
  const isAddOfferDisabled = true; // temporarily we disable offers
  // (ratePlans && !!ratePlans.length) || (ratePlanData && ratePlanData.loading);
  const isRatePlanUploadDisable =
    typeof members !== "undefined" && !!members.length;
  const isBtnDisabled =
    (ratePlanData && ratePlanData.loading) ||
    lockContract === true ||
    submitSucceeded ||
    pristine;
  const isRateAddendum = signatures && signatures.length > 1;
  // const allowRateActions =
  //   participant._id === contract.customerId && isRateAddendum;
  const allowRateActions = userIsCustomer && isRateAddendum;

  /* eslint-enable */
	const showNotification = data => {
		if (data) {
			if (data.loading) {
				return false;
			}
			if (data.info && data.info.error) {
				return (
					<Paper>
						<CardContent>Something went wrong</CardContent>
					</Paper>
				);
			}
			if (!data.data || !data.data.length) {
				return (
					<Paper>
						<CardContent>No rate sheets uploaded yet</CardContent>
					</Paper>
				);
			}
		}
		return false;
	};
	const allowedFileTypes = ['.csv', '.xlsx', '.xls'];

	return (
		<form onSubmit={handleSubmit}>
			<ConfirmFileUpload
				dialogTitle={t('immediate_rate_change_modal.title')}
				dialogMessage={t('immediate_rate_change_modal.text')}
				open={showImmediateRateChangeDialog}
				openCloseHandler={toggleImmediateRateChangeDialog}
				allowedFileTypes={allowedFileTypes}
				handleUpload={(smartContractId, ratesFile) => {
					toggleImmediateRateChangeDialog();
					return uploadImmediateRatePlan(smartContractId, ratesFile);
				}}
				uploadId={props && props.initialValues && props.initialValues.smartContractId}
			/>
			<Field component="input" type="hidden" name="tempId" />
			<Field name="smartContractId" component="input" type="hidden" />
			<Field name="contractType" component="input" type="hidden" />
			<Grid container>
				<Grid item xs={12}>
					<Paper>
						<CardContent>
							<Grid container>
								<Grid item xs={10}>
									{status && <RenderBadge color="primary">{status}</RenderBadge>}
									{warning && <RenderBadge color="warning">{warning}</RenderBadge>}
									<RenderBadge color={contract.status === 'terminated' ? 'danger' : 'primary'}>{contract.status}</RenderBadge>
									{contract.funder_id ? (
										<RenderBadge color="warning">{t('status.funded')}</RenderBadge>
									) : (
										<RenderBadge color="warning">{t('status.standard')}</RenderBadge>
									)}
									<RenderBadge color="primary">{t('status.created_at', { date: moment(contract.createdAt).fromNow() })}</RenderBadge>
									{contract.endDate !== 0 && moment(contract.endDate).isValid() && (
										<RenderBadge color="warning">{t('status.ends_at', { date: moment(contract.endDate).format('DD MMM YYYY') })}</RenderBadge>
									)}
									{lockContract && (
										<RenderBadge color="danger">
											<Trans
												ns="smartcontracts/edit"
												i18nKey="low_balance"
												components={{
													FinancialsLink: <Link to="/financials" />
												}}
											/>
										</RenderBadge>
									)}
									<br />
									<br />
								</Grid>
								<Grid item xs={2} style={{ textAlign: 'right' }}>
									{props.smartContract.userIsSupplier &&
									props.smartContract.contract.status === 'active' &&
									!contract.fundingRequested &&
									contract.isOriginContract ? (
										<RenderButton size="small" color="secondary" onClick={() => toggleRequestFundingModal()}>
											{t('request_funding')}
										</RenderButton>
									) : (
										''
									)}
								</Grid>
								<Grid item xs={12}>
									<Grid container>
										<Grid item xs={12} md={8}>
											{(contract.status !== 'draft' && <SmartContractsTechnicalTermsReadOnly {...props} />) || <SmartContractsTechnicalTerms {...props} />}
										</Grid>
										<Grid item xs={12} md={4}>
											{(contract.status !== 'draft' && <SmartContractsCommercialTermsReadOnly {...props} />) || <SmartContractsCommercialTerms {...props} />}
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</CardContent>
					</Paper>
				</Grid>
				{/* {isAddOfferDisabled && ( */}
				<Grid item xs={12}>
					{/* {showNotification(ratesData) || ( */}
					<RatesTable
						loadRates={(smartContractId, options) => {
							setRateRefreshOptions(smartContractId, options);
							getRatePlanRates(smartContractId, options);
						}}
						handleProposedRatesAction={(smartContractId, options) => {
							sendProposedRatesAction(smartContractId, options);
						}}
						smartContractId={contract.id}
						rates={ratesData.data}
						total={ratesData.total}
						requiresActionTotal={ratesData.requiresActionTotal}
						loading={ratesData.loading}
						startOffset={ratesData.startOffset}
						isSelectable={allowRateActions}
					/>
					{/* )} */}
				</Grid>
				{/* )} */}
				{participant._id === contract.supplierId && contract.status !== 'terminated' && isAddOfferDisabled && (
					<Grid item xs={12}>
						<SmartContractRatesContainer
							ratePlans={ratePlansToDisplay}
							handleRateUpload={(tempSmartContractId, ratesFile, effDate) => uploadRatePlan(tempSmartContractId, ratesFile, effDate, rateRefreshOptions)}
							ratePlanData={ratePlanData}
							clearUploadError={clearUploadError}
							isUploadDisabled={isRatePlanUploadDisable}
							tempId={useTempId}
							isRateAddendum={isRateAddendum}
							minimumNoticeDays={contract.minimumNoticeDays}
							toggleImmediateRateChangeDialog={toggleImmediateRateChangeDialog}
						/>
					</Grid>
				)}
				{!isAddOfferDisabled && (
					<Grid item xs={12}>
						<Paper>
							<CardContent>
								<FieldArray name="members" component={SmartContractDestinationCodeContainer} props={props} disabled={isAddOfferDisabled} />
							</CardContent>
						</Paper>
					</Grid>
				)}
				{contract.status !== 'terminated' && (
					<Grid item xs={12}>
						<Paper>
							<CardContent style={{ textAlign: 'center' }}>
								{contract.status !== 'active' && !userSignedContract && <SmartContractTermsConditions />}
								<Field name="status" component="input" type="hidden" />
								{contract.status === 'pending' && contract.terminatedBy && contract.terminatedBy !== participant._id && (
									<RenderButton color="danger" value="terminate">
										{t('agree_with_termination')}
									</RenderButton>
								)}
								{contract.status === 'pending' &&
									contract.terminatedBy &&
									contract.terminatedBy === contract.customerId &&
									contract.terminatedBy === participant._id && (
										<RenderButton color="warning" disabled value="terminate">
											{t('termination_initiated')}
										</RenderButton>
									)}

								{contract.status === 'draft' && contract.userId === participant._id && (
									<RenderButton color="secondary" disabled={isBtnDisabled} type="submit" onClick={() => changeStatus('draft')}>
										{t('save_draft')}
									</RenderButton>
								)}
								{contract.status === 'draft' && contract.userId === participant._id && (
									<RenderButton component={Link} to="/smart-contracts">
										{t('discard_changes')}
									</RenderButton>
								)}
								{contract.status === 'draft' && contract.userId === participant._id && (
									<RenderButton color="primary" disabled={isBtnDisabled} type="submit" onClick={() => changeStatus('pending')}>
										{t('sign_contract')}
									</RenderButton>
								)}
								{contract.status === 'pending' && signatures && signatures.length === 1 && !props.smartContract.userSignedContract && (
									<RenderButton color="primary" disabled={isBtnDisabled} type="submit" onClick={() => changeStatus('pending')}>
										{t('sign_contract')}
									</RenderButton>
								)}
								{contract.status === 'pending' && !contract.terminatedAt && contract.userId === participant._id && signedAt && (
									<RenderButton type="button" color="warning" disabled>
										{t('signed_x_time_ago',{ time: moment(signedAt).fromNow() })}
									</RenderButton>
								)}
								{signatures && signatures.length === 2 && (contract.customerId === participant._id || contract.supplierId === participant._id) && (
									<RenderButton type="button" className="warning" disabled>
										{t('signed_x_time_ago',{ time: moment(signedAt).fromNow() })}
									</RenderButton>
								)}
							</CardContent>
						</Paper>
					</Grid>
				)}
			</Grid>
		</form>
	);
};

SmartContractsEditForm.propTypes = {
	changeStatus: PropTypes.func.isRequired,
	contract: PropTypes.shape(),
	dataNewSmartContract: PropTypes.shape().isRequired,
	getRatePlanRates: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	ratesData: PropTypes.shape().isRequired,
	showImmediateRateChangeDialog: PropTypes.bool.isRequired,
	signContract: PropTypes.func.isRequired,
	status: PropTypes.string,
	smartContract: PropTypes.shape().isRequired,
	uploadImmediateRatePlan: PropTypes.func.isRequired,
	uploadRatePlan: PropTypes.func.isRequired,
	warning: PropTypes.string
};

SmartContractsEditForm.defaultProps = {
	contract: undefined,
	status: undefined,
	warning: undefined
};

export default SmartContractsEditForm;
