import React, { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { RoutesSmartContractInfo } from "../../index";

const RoutesEdit = memo(({ contractDetails }) => {

    return (
      <div>
        <RoutesSmartContractInfo
          data={contractDetails}
        />

      </div>
    );
  }
);

export default RoutesEdit;
