import React from "react";
import classNames from "classnames";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import {
  FormHelperText,
  FormControl,
  MenuItem,
  Chip,
  Typography,
  TextField,
  Paper
} from "@material-ui/core";
import { Cancel, KeyboardArrowDown } from "@material-ui/icons";
import { emphasize } from "@material-ui/core/styles/colorManipulator";

const styles = theme => ({
  input: {
    display: "flex",
    padding: "2px 0 3px"
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden"
  },
  chip: {
    margin: `0 ${theme.spacing.unit / 4}px`,
    height: "auto"
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16
    // whiteSpace: "nowrap",
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // maxWidth: "95%"
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16
  },
  paper: {
    position: "absolute",
    zIndex: 999,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing.unit * 2
  }
});

const NoOptionsMessage = ({ selectProps, innerProps, children }) => (
  <Typography
    color="textSecondary"
    className={selectProps.classes.noOptionsMessage}
    {...innerProps}
  >
    {children}
  </Typography>
);

const inputComponent = ({ inputRef, ...props }) => (
  <div ref={inputRef} {...props} />
);

const Control = ({
  selectProps,
  innerRef,
  children,
  innerProps,
  isDisabled,
  isFocused,
  hasValue,
  ...rest
}) => (
  <TextField
    fullWidth
    disabled={isDisabled}
    InputProps={{
      inputComponent,
      inputProps: {
        className: selectProps.classes.input,
        ref: innerRef,
        children,
        ...innerProps
      }
    }}
    {...selectProps.textFieldProps}
    InputLabelProps={{
      shrink: hasValue || isFocused
    }}
  />
);

const Option = ({ innerRef, isFocused, innerProps, children, isDisabled }) => (
  <MenuItem
    buttonRef={innerRef}
    selected={isFocused}
    disabled={isDisabled}
    component="div"
    style={{
      fontWeight: 400
    }}
    {...innerProps}
  >
    {children}
  </MenuItem>
);

const Placeholder = ({ selectProps, innerProps, children }) => (
  <Typography
    color="textSecondary"
    className={selectProps.classes.placeholder}
    {...innerProps}
  >
    {children}
  </Typography>
);

const SingleValue = ({ selectProps, innerProps, children }) => (
  <span className={selectProps.classes.singleValue} {...innerProps}>
    {children}
  </span>
);

const ValueContainer = ({ selectProps, children }) => (
  <div className={selectProps.classes.valueContainer}>{children}</div>
);

const MultiValue = ({ children, selectProps, removeProps }) => (
  <span className={classNames(selectProps.classes.chip)}>{children};</span>
);

const Menu = ({ selectProps, innerProps, children }) => (
  <Paper className={selectProps.classes.paper} {...innerProps}>
    {children}
  </Paper>
);

const DropdownIndicator = () => <KeyboardArrowDown color="action" />;

const IndicatorSeparator = () => null;

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  MultiValue,
  ValueContainer,
  DropdownIndicator,
  IndicatorSeparator
};

class FormikSelect extends React.Component {
  constructor(props) {
    super(props);
    const { multiple, field } = this.props;
  }
  //
  // handleChange = option => {
  //   const { callback, field } = this.props;
  //
  //   field.onChange(option.value || option);
  //
  //   if (callback) {
  //     callback(option.value || option);
  //   }
  // };

  render() {
    const {
      classes,
      inline,
      callback,
      children,
      form: { touched, errors },
      margin,
      disabled,
      theme,
      label,
      multiple,
      fullWidth,
      options,
      field,
      form,
      ...props
    } = this.props;

    // console.log("this.props", this.props);

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        "& input": {
          font: "inherit"
        }
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
      }
    };

    const hasError = (touched[field.name] && errors[field.name]) || false;

    const isMultiple = typeof multiple !== typeof undefined && multiple;

    return (
      <FormControl
        error={hasError}
        fullWidth={fullWidth}
        margin={margin}
        disabled={disabled}
      >
        <Select
          {...field}
          {...props}
          options={options}
          name={field.name}
          value={options ? (options.find(option => option.value === field.value) || "") : ""}
          onChange={(option) => {
            form.setFieldValue(field.name, option.value);
            if (callback) {
              callback(option.value);
            }
          }}
          onBlur={field.onBlur}
          classes={classes}
          styles={selectStyles}
          textFieldProps={{
            label
          }}
          components={components}
          placeholder=""
          isMulti={isMultiple}
          isDisabled={disabled}
        />
        {hasError && <FormHelperText>{errors[field.name]}</FormHelperText>}
      </FormControl>
    );
  }
}

FormikSelect.defaultProps = {
  callback: undefined,
  fullWidth: true,
  margin: "none",
  disabled: false
};
export default withStyles(styles, { withTheme: true })(FormikSelect);
