import RenderButton from 'components/atoms/RenderButton';
import moment from 'moment';
import React from 'react';
import { capitalizeWord, colorStatusText, handleCurrency } from 'utils';

export const columns = ({ scId, setSelectedPof }) => [
	{
		Header: 'Invoice Number',
		Cell: ({ original: { invoiceNumber } }) => <p>{invoiceNumber}</p>
	},
	{
		Header: 'Fulfillment Date',
		Cell: ({ original: { fulfillmentDate } }) => <p>{moment(fulfillmentDate).format('MMM Do YY')}</p>
	},
	{
		Header: 'Cost',
		Cell: ({ original: { itemPrice } }) => <p>{handleCurrency(itemPrice, 'USD')}</p>
	},
	{
		Header: () => <div className="centered-cell">Quantity</div>,
		Cell: ({ original: { deliveredQty } }) => <div className="centered-cell">{deliveredQty}</div>
	},
	{
		Header: () => <div className="centered-cell">Total</div>,
		Cell: ({ original: { deliveredQty, itemPrice } }) => <div className="centered-cell">{handleCurrency(deliveredQty * itemPrice, 'USD')}</div>
	},
	{
		Header: () => <div className="centered-cell">Status</div>,

		Cell: ({ original: { status } }) => (
			<div className="centered-cell" style={colorStatusText(status, undefined, { fontWeight: '700' })}>
				{capitalizeWord(status).replace(/-/g, ' ')}
			</div>
		)
	},
	{
		Header: () => <div className="centered-cell"> Actions</div>,
		Cell: ({ original }) => (
			<div className="centered-cell">
				<RenderButton color="primary" onClick={() => setSelectedPof({ ...original, scId })}>
					View
				</RenderButton>
			</div>
		)
	}
];
