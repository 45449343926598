import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Paper, CardContent, Input } from '@material-ui/core';
import { RatesTableCustomFilterForm, RatesTableCustomToolbar, RatesTableCustomToolbarSelect, RenderTable } from 'components';
import { fromUseraccount } from 'store/selectors';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class RatesTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filterValue: {
				currentRateOnly: false,
				showEnded: true,
				showFuture: true
			},
			page: 0,
			limit: 10,
			pages: -1,
			rateFilterGroup: 'all',
			selection: [],
			selectAll: false,
			tableTotal: undefined
		};
		this.filterTimer = null;
		this.ratesTable = React.createRef();
		this.setFilterValue = this.setFilterValue.bind(this);
	}

	handleAction = action => {
		const { rateFilterGroup, selection, page, limit } = this.state;
		const { handleProposedRatesAction, smartContractId } = this.props;

		handleProposedRatesAction(smartContractId, {
			action,
			rateFilterGroup,
			selectedRates: selection,
			page,
			limit
		});

		this.setState({
			selection: [],
			selectAll: false
		});
	};

	handleRateFilterGroupChange = value => {
		this.setState({ page: 0, rateFilterGroup: value }, () => this.fetchData());
	};

	toggleSelection = (key, shift, row) => {
		const keyIdx = key && key.indexOf('select-');
		key = keyIdx === 0 ? key.substr(7) : key;
		// start off with the existing state
		let { selection } = this.state;
		const keyIndex = selection.indexOf(key);
		// check to see if the key exists
		if (keyIndex >= 0) {
			// it does exist so we will remove it using destructing
			selection = [...selection.slice(0, keyIndex), ...selection.slice(keyIndex + 1)];
		} else {
			// it does not exist so add it
			selection.push(key);
		}
		// update the state
		this.setState({ selection });
	};

	toggleAll = () => {
		const { selectAll } = this.state;
		const newSelectAllVal = !selectAll;
		const selection = [];
		console.log("this.ratesTable",this.ratesTable)
		if (newSelectAllVal) {
			const {
				props: { data }
			} = this.ratesTable;

			if (data && Array.isArray(data)) {
				data.forEach(item => {
					const { _id } = item;
					if (typeof _id !== 'undefined') {
						selection.push(_id);
					}
				});
			}
		}
		this.setState({ selectAll: newSelectAllVal, selection });
	};

	isSelected = key => {
		const { selection } = this.state;
		return typeof key !== 'undefined' && selection.includes(key);
	};

	getRowColorByStatus = (status, isNewRate) => {
		if (isNewRate && ['pending', 'autoaccepted'].indexOf(status) > -1) {
			return 'lightblue';
		}
		switch (status) {
			case 'autoaccepted':
				return 'green';
			case 'pending':
				return 'orange';
			case 'declined':
				return 'gray';
			case 'accepted':
			default:
				return 'inherit';
		}
	};

	fetchData() {
		const { loadRates, smartContractId } = this.props;
		const { page, limit, rateFilterGroup, filterValue } = this.state;
		loadRates(smartContractId, {
			page,
			limit,
			rateFilterGroup,
			filters: filterValue
		});
	}

	setFilterValue(obj) {
		clearTimeout(this.filterTimer);
		const { filterValue } = this.state;
		this.setState(
			{
				filterValue: {
					...filterValue,
					...obj
				}
			},
			() => {
				this.filterTimer = setTimeout(() => this.fetchData(), 300);
			}
		);
	}


	render() {
		if (this.focusElement) {
			this.focusElement.focus();
		}
		const { role, rates, total, requiresActionTotal, loading, isSelectable, hideCustomToolbar, t } = this.props;

		const { rateFilterGroup, selection, selectAll, pages, page, tableTotal, limit, filterValue, autofocus } = this.state;

		const isFunder = role.indexOf(ROLES.FUNDER) !== -1;

		const ratesTotal = loading ? tableTotal || total : total;
		const tablePages = loading ? pages : Math.ceil(ratesTotal / limit);

		const defaultTextFilter = (options = {}) => ({ column }) => {
			const fVal = filterValue[column.id] || '';
			const inputOptions = {
				style: { width: '100%' },
				...options,
				value: fVal,
				onChange: e => {
					clearTimeout(this.filterTimer);
					this.focusElement = e.target;
					this.setState(
						{
							autofocus: column.id,
							filterValue: {
								...filterValue,
								[column.id]: e.target.value || undefined
							}
						},
						() => {
							this.filterTimer = setTimeout(() => this.fetchData(), 300);
						}
					);
				}
			};
			if (autofocus === column.id) {
				return <Input autoFocus {...inputOptions} />;
			}
			return <Input {...inputOptions} />;
		};

		const tableColumns = [
			{
				Header: t('rates.country_name'),
				accessor: 'country',
				Filter: defaultTextFilter({})
			},
			{
				Header: t('rates.country_code'),
				accessor: 'countryCode',
				Filter: defaultTextFilter({})
			},
			{
				Header: t('rates.eff_date'),
				accessor: 'effDate',
				Cell: d => moment(d.value).format('MMM DD YYYY'),
				Filter: defaultTextFilter({ placeholder: 'MM/DD/YYYY' })
			},
			{
				Header: t('rates.end_date'),
				accessor: 'endDate',
				Cell: d => moment(d.value, 'MM/DD/YYYY').format('MMM DD YYYY'),
				Filter: defaultTextFilter({ placeholder: 'MM/DD/YYYY' })
			},
			{
				Header: t('rates.rate'),
				accessor: 'peak',
				show: !isFunder,
				Cell: d => parseFloat(d.value).toFixed(6),
				Filter: defaultTextFilter({ type: 'number', step: '.000001' })
			},
			{
				Header: t('rates.old_rate'),
				accessor: 'oldRate',
				Cell: d => parseFloat(d.value).toFixed(6),
				Filter: defaultTextFilter({ type: 'number', step: '.000001' }),
				show: !isFunder
			},
			{
				Header: t('rates.ini_increment'),
				accessor: 'incIni',
				Filter: defaultTextFilter({ type: 'number', step: '.000001' })
			},
			{
				Header: t('rates.add_increment'),
				accessor: 'incAdd',
				Filter: defaultTextFilter({ type: 'number', step: '.000001' })
			}
		];
		const checkBoxProps = {
			selection,
			selectAll,
			selectType: 'checkbox',
			toggleSelection: this.toggleSelection,
			toggleAll: this.toggleAll,
			isSelected: this.isSelected,
			getTrProps: (s, r) => {
				if (isFunder) return {};
				if (r && r.original) {
					const {
						original: { _id, status, isNewRate }
					} = r;
					const selected = this.isSelected(_id);
					const defaultColor = this.getRowColorByStatus(status, isNewRate);
					return {
						style: {
							backgroundColor: selected ? 'lightgreen' : defaultColor
						}
					};
				}
				return {
					style: {
						backgroundColor: 'inherit'
					}
				};
			}
		};

		const tableData = rates.map(r => {
			const rateRec = { ...r };
			if (['accepted', 'declined'].indexOf(r.status) === -1) {
				rateRec._id = r.id;
			}
			return rateRec;
		});

		return (
			<div>
				<Paper>
					<CardContent>
						{isSelectable && (selection.length > 0 || selectAll) ? (
							<RatesTableCustomToolbarSelect
								count={selection.length}
								total={requiresActionTotal}
								criteriaText="Matching the selected criteria"
								handleAction={this.handleAction}
							/>
						) : (
							''
						)}
						{!isFunder && !hideCustomToolbar ? <RatesTableCustomToolbar handleTabSelect={this.handleRateFilterGroupChange} value={rateFilterGroup} /> : ''}
						<RatesTableCustomFilterForm
							handleChange={this.setFilterValue}
							initialValues={{
								currentRateOnly: false,
								showEnded: true,
								showFuture: true
							}}
						/>
						<RenderTable
							ref={ref => {
								this.ratesTable = ref;
							}}
							tableTotal={ratesTotal}
							isSelectTable={isSelectable}
							data={tableData}
							defaultPageSize={10}
							page={page}
							pages={tablePages}
							loading={loading}
							forcePagination
							manual
							onPageChange={pageIndex => {
								this.setState({ page: pageIndex });
							}}
							onPageSizeChange={(pageSize, pageIndex) => {
								this.setState({
									page: pageIndex,
									limit: pageSize
								});
							}}
							onFetchData={tableState => {
								this.setState(
									{
										pages: Math.ceil(total / tableState.pageSize),
										tableTotal: total,
										selection: [],
										selectAll: false
									},
									() => this.fetchData()
								);
							}}
							columns={tableColumns}
							sortable={false}
							filterable
							{...checkBoxProps}
						/>
					</CardContent>
				</Paper>
			</div>
		);
	}
}

RatesTable.defaultProps = {
	total: 0,
	loading: false,
	startOffset: 0,
	requiresActionTotal: 0,
	isSelectable: false,
	handleProposedRatesAction: () => {
		// noop
	}
};

RatesTable.propTypes = {
	loadRates: PropTypes.func.isRequired,
	rates: PropTypes.arrayOf(Array).isRequired,
	startOffset: PropTypes.number,
	total: PropTypes.number,
	loading: PropTypes.bool,
	smartContractId: PropTypes.string.isRequired,
	requiresActionTotal: PropTypes.number,
	isSelectable: PropTypes.bool,
	handleProposedRatesAction: PropTypes.func
};

const RatesTableTranslated = withTranslation(['smartcontracts/edit', 'translations'])(RatesTable);

function mapStateToProps(state) {
	return {
		role: fromUseraccount.getUserRol(state)
	};
}

export default connect(mapStateToProps)(RatesTableTranslated);
