import {
  initialState,
  initialChangePasswordState,
  getChangePasswordState
} from "./selectors";
import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  SET_COGNITO_INFO
} from "./actions";

export default (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        ["CHANGE_PASSWORD"]: {
          ...getChangePasswordState(state),
          oldPassword: payload.oldPassword,
          newPassword: payload.newPassword,
          email: payload.email
        }
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        ["CHANGE_PASSWORD"]: {
          ...initialChangePasswordState
        }
      };
    case CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        ["CHANGE_PASSWORD"]: {
          ...payload
        }
      };
    case SET_COGNITO_INFO:
      return {
        ...state,
        ["CHANGE_PASSWORD"]: {
          ...getChangePasswordState(state),
          ...payload
        }
      };
    default:
      return state;
  }
};
