import { CurrencyNumber, RenderButton } from 'components';

/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import moment from 'moment';

const MainTableHeader = ({ toggleInvoiceModal }, t) => {
	return {
		columns: [
			{
				Header: t('invoices.table.inv_date'),
				accessor: 'invoiceDate',
				maxWidth: 120,
				sortMethod: (a, b) => {
					if (moment(a, 'MMM DD YYYY').isSame(moment(b, 'MMM DD YYYY'))) {
						return 0;
					}
					return moment(a, 'MMM DD YYYY').isSameOrAfter(moment(b, 'MMM DD YYYY')) ? 1 : -1;
				}
			},
			{
				Header: t('invoices.table.inv_number'),
				accessor: 'displayId',
				maxWidth: 160
			},
			{
				Header: t('invoices.table.client'),
				accessor: 'customerData.name',
				maxWidth: 160
			},
			{
				Header: t('invoices.table.amount'),
				accessor: 'usage.total.amount',
				maxWidth: 120,
				Cell: props => {
					// eslint-disable-next-line react/prop-types
					const { value } = props;
					return <CurrencyNumber amount={Math.round(value * 100) / 100} />;
				}
			},
			{
				Header: t('invoices.table.actions'),
				id: 'actions',
				Filter: () => null,
				Cell: d => (
					<React.Fragment>
						<RenderButton color="primary" onClick={() => toggleInvoiceModal(d.original)}>
							{t('invoices.table.view')}
						</RenderButton>
					</React.Fragment>
				)
			}
		]
	};
};
export default MainTableHeader;
