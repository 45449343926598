import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Grid, Typography, Paper, TextField, MenuItem, Box } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import * as moment from 'moment';

import { RenderTable, RenderFormikCheckbox, RenderFormikSwitch, RenderFormikSelect, RenderFormikInput } from 'components';
import Loader from 'components/atoms/Loader';
import CurrencyNumber from 'components/atoms/CurrencyNumber';
import RenderButton from 'components/atoms/RenderButton';
import RenderAcpType from 'components/atoms/AcpType';

import actions from 'store/acp/actions';
import styles from './styles.module.scss';

export default memo(() => {
	const dispatch = useDispatch();
	const archiveSettings = useSelector(state => (state.acp && state.acp.archiveSettings) || {});
	const loading = useSelector(state => state.acp && state.acp.loading);

	const formik = useFormik({
		initialValues: {
			olderThanMonths: null,
			autoArchive: null,
			withStatusRejected: null,
			withStatusProcessed: null
		},
		onSubmit: values => {
			if (values.olderThanMonths === 0 || values.olderThanMonths === null) {
				values.autoArchive = false;
			}
			const withStatus = [];
			if (values.withStatusProcessed && values.autoArchive) {
				withStatus.push('processed');
			}
			if (values.withStatusRejected && values.autoArchive) {
				withStatus.push('rejected');
			}

			if (values.autoArchive) {
				values.olderThanMonths = parseInt(values.olderThanMonths);
			} else {
				values.olderThanMonths = 0;
			}
			const olderThanMonths = values.olderThanMonths;

			dispatch({
				type: actions.UPDATE_ARCHIVE_SETTINGS,
				payload: {
					withStatus,
					olderThanMonths
				}
			});
		}
	});

	useEffect(() => {
		dispatch({ type: actions.GET_ARCHIVE_SETTINGS });

		const isStatusRejected = archiveSettings && archiveSettings.withStatus && archiveSettings.withStatus.includes('rejected');
		const isStatusProcessed = archiveSettings && archiveSettings.withStatus && archiveSettings.withStatus.includes('processed');

		formik.setValues({
			olderThanMonths: archiveSettings.olderThanMonths,
			autoArchive: archiveSettings.olderThanMonths > 0,
			withStatusRejected: isStatusRejected,
			withStatusProcessed: isStatusProcessed
		});
	}, []);

	useEffect(() => {
		const isStatusRejected = archiveSettings && archiveSettings.withStatus && archiveSettings.withStatus.includes('rejected');
		const isStatusProcessed = archiveSettings && archiveSettings.withStatus && archiveSettings.withStatus.includes('processed');

		formik.setValues({
			olderThanMonths: archiveSettings.olderThanMonths,
			autoArchive: archiveSettings.olderThanMonths > 0,
			withStatusRejected: isStatusRejected,
			withStatusProcessed: isStatusProcessed
		});
	}, [archiveSettings]);

	return (
		<div className={styles.container}>
			<Paper>
				{(loading && <Loader loading />) || (
					<Grid container className="grid-container" spacing={1}>
						<Grid item xs={11}>
							<Box style={{ display: 'flex', justifyContent: 'space-between' }}>
								<Typography variant="h2" gutterBottom>
									Auto archiving settings
								</Typography>
								<RenderButton
									type="submit"
									color="primary"
									onClick={e => {
										e.preventDefault();
										formik.handleSubmit();
									}}
								>
									Save Settings
								</RenderButton>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<RenderFormikCheckbox formik={formik} label="Auto archive" name="autoArchive" />
						</Grid>

						{formik.values['autoArchive'] && (
							<>
								<Grid item xs={4}>
									<p>Types of submissions to be archived:</p>
									<RenderFormikCheckbox formik={formik} label="Processed submissions" name="withStatusProcessed" />
									<RenderFormikCheckbox formik={formik} label="Rejected submissions" name="withStatusRejected" />
								</Grid>

								<Grid item xs={4}>
									<p>Number of months after a submission will be archived:</p>
									<Grid item sm={4} md={3} lg={2}>
										<RenderFormikInput
											formik={formik}
											name="olderThanMonths"
											placeholder="number of months"
											type="number"
											disabled={formik.values['autoArchive'] !== true}
										/>
									</Grid>
									{formik.values['olderThanMonths'] > 0 && (
										<span className="disclaimer">
											&#x2022; submissions with a claim period of{' '}
											<b>
												{moment()
													.subtract(formik.values['olderThanMonths'], 'months')
													.format('MMMM YYYY')}
											</b>{' '}
											or older will be automatically archived at the beginning of each month.
										</span>
									)}
								</Grid>
							</>
						)}
					</Grid>
				)}
			</Paper>
		</div>
	);
});
