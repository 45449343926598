const privateMenuAccordionStyle = theme => ({
  text: {
    marginLeft: "5px",
    textTransform: "capitalize"
  },
  navLink: {
    color: "inherit",
    fontSize: "13px",
    padding: `0 ${theme.spacing(1)}px`,
    height: "48px",
    textDecoration: "none",
    margin: "1px 0px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    willChange: "border",
    transition: "border 225ms linear",
    borderColor: theme.palette.secondary.main,
    position: "relative",
    "&:before": {
      content: "''",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      willChange: "width",
      width: 0,
      transition: "width 255ms linear",
      backgroundColor: "#3a755b",
      zIndex: "-1"
    },
    "&:hover, &:focus, &.active": {
      color: "inherit",
      // borderLeft: `2px solid ${theme.palette.secondary.main}`,
      "&:before": {
        width: "100%"
      }
    }
  },
  navOpen: {
    fontSize: theme.spacing(1) * 3,
    "& $text": {
      display: "none"
    }
  },
  listItem: {
    width: "auto",
    padding: "0",
  },
  titleContainer:{
    width: '100%'
  },
  chevronContainer:{
    marginLeft: 'auto',
    textAlign: 'center'
  },
  chevron:{
    transitionDuration:'0.4s',
    transitionProperty: 'transform',
    transform: '' 
  },
  chevronOpened:{
    transform: 'rotate(180deg)'
  },
  options: {
    willChange: "max-height", 
    transition: "max-height 500ms cubic-bezier(0.1, -0.7, 0.4, 2)", 
    overflow: 'hidden',
    maxHeight: 0
  },
  opened: {
    maxHeight: '300px',  
  }
});

export default privateMenuAccordionStyle;
