import React from "react";
import { SmartContractContentItem } from "components";
import { Card, CardBody } from "reactstrap";
import { ExplorerSmartContractsContainer } from "containers";

const ExplorerContent = props => {
  const { data, children, type, getSmartContractsExplorer } = props;

  const renderChildren = (value, address) => {
    if (value === "smartContracts") {
      return (
        <ExplorerSmartContractsContainer
          key={Math.random()}
          address={address}
        />
      );
    }
  };

  if (data !== undefined && data !== null && Object.keys(data).length > 0) {
    return (
      <Card>
        <CardBody>
          {Object.values(data).map(
            (item, index) =>
              item !== undefined ? (
                <SmartContractContentItem
                  key={index}
                  getSmartContractsExplorer={getSmartContractsExplorer}
                  item={item}
                >
                  {renderChildren(type, item.smartContractAddresss)}
                </SmartContractContentItem>
              ) : (
                ""
              )
          )}
        </CardBody>
      </Card>
    );
  }
  return "No Transactions";
};

export default ExplorerContent;
