import React, { Component } from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  MenuItem,
  FormControl,
  FormLabel
} from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import {
  RenderButton,
  RenderSelect,
  RenderInput,
  RenderRadioGroup,
  RenderRadio,
  RenderCheckbox
} from "components";

const Transition = props => <Slide direction="up" {...props} />;

const destinationRow = ({ fields }) => {
  if (fields.length === 0) fields.push({});
  return (
    <div>
      {fields.map((dest, index) => (
        <Grid container>
          <Grid item xs={5}>
            <Field
              component={RenderSelect}
              id="destination"
              name="destination"
              label="Destination"
            >
              <MenuItem value="1">To MiddleNo Virtual Switch/06.4812T</MenuItem>
              <MenuItem value="2">To MiddleNo Virtual Switch/07.3812T</MenuItem>
              <MenuItem value="3">To MiddleNo Virtual Switch/08.2812T</MenuItem>
            </Field>
          </Grid>
          <Grid item xs={2}>
            <Field
              component={RenderInput}
              name="percentage"
              id="percentage"
              label="Percentage"
            />
          </Grid>
          <Grid item xs={2}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Active</FormLabel>
              <Field
                component={RenderCheckbox}
                name="active"
                id="active"
                checked
                value="1"
              />
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            {index === 0 ? (
              <RenderButton color="primary" onClick={() => fields.push({})}>
                Add
              </RenderButton>
            ) : (
              <RenderButton color="danger" onClick={() => fields.remove(index)}>
                Delete
              </RenderButton>
            )}
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

class CreateRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  handleOpenModal = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  render() {
    const { isOpen } = this.state;

    return (
      <div>
        <RenderButton color="primary" onClick={this.handleOpenModal}>
          Add route
        </RenderButton>

        <Dialog
          maxWidth="md"
          fullWidth
          open={isOpen}
          onBackdropClick={this.handleOpenModal}
          TransitionComponent={Transition}
        >
          <form>
            <DialogTitle>Add routes</DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={6}>
                  <Field
                    component={RenderSelect}
                    id="source"
                    name="source"
                    label="Source"
                  >
                    <MenuItem value="1">
                      From MiddleNo Virtual Switch/06.4812T
                    </MenuItem>
                    <MenuItem value="2">
                      From MiddleNo Virtual Switch/07.3812T
                    </MenuItem>
                    <MenuItem value="3">
                      From MiddleNo Virtual Switch/08.2812T
                    </MenuItem>
                  </Field>
                </Grid>
                <Grid item xs={2}>
                  <Field
                    component={RenderInput}
                    id="digits"
                    name="digits"
                    label="Digits"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={RenderInput}
                    id="description"
                    name="description"
                    label="Description"
                  />
                </Grid>
                <Grid item xs={7}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Route type</FormLabel>
                    <Field name="routeType" component={RenderRadioGroup} inline>
                      <RenderRadio value="1" label="Static" />
                      <RenderRadio value="2" label="Percentage" />
                      <RenderRadio
                        value="3"
                        label="LCR"
                      />
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Ignore rate class</FormLabel>
                    <Field
                      component={RenderCheckbox}
                      name="ignoreRateClass"
                      id="ignoreRateClass"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FieldArray name="destinations" component={destinationRow} />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <RenderButton color="primary" onClick={this.handleOpenModal}>
                Save
              </RenderButton>
              <RenderButton color="danger" onClick={this.handleOpenModal}>
                Close
              </RenderButton>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}

export default reduxForm({
  destroyOnUnmount: true,
  enableReinitialize: true
})(CreateRoute);
