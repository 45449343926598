import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Field } from "redux-form";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { RenderInput, RenderButton, RenderNotification } from "components";

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid
} from "@material-ui/core";

import registerStyle from "./style";

class RecoveryPasswordForm extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { handleSubmit, pristine, classes } = this.props;
    return (
      <form onSubmit={handleSubmit} className={classes.form}>
        <RenderNotification
          variant="success"
          floating={true}
          open={this.props.info.successMessage}
          message={this.props.info.successMessage}
        />
        <Card className={classes.root}>
          <CardContent>
            <img src="/images/Portal_Logo.png" alt="Logo TessPay" />
            <br />
            <br />
            <Field
              name="email"
              label="E-Mail Address"
              type="email"
              component={RenderInput}
            />
            <RenderNotification
              variant="danger"
              floating={false}
              open={this.props.info.errorMessage}
              message={this.props.info.errorMessage}
            />
          </CardContent>
          <CardActions>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <RenderButton color="primary" type="submit">
                  Send Password Reset Link
                </RenderButton>
              </Grid>
              <Grid item>
                <RenderButton component={Link} to="/login" underlined>
                  Back to login
                </RenderButton>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </form>
    );
  }
}

RecoveryPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  info: PropTypes.object.isRequired,
  pristine: PropTypes.bool.isRequired
};

export default withStyles(registerStyle)(RecoveryPasswordForm);
