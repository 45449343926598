import React, { Component } from "react";
import { connect } from "react-redux";
import { CancelOrderForm } from "components";
import { cancelOrder } from "store/actions";
import { bindActionCreators } from "redux";

class CancelOrderFormContainer extends Component {
  constructor(props) {
    super(props);
    this.executeCancelOpenOrder = this.executeCancelOpenOrder.bind(this);
  }

  componentDidMount() {}

  executeCancelOpenOrder(data) {
    this.props.cancelOpenOrder();
    this.props.cancelOrder(data);
  }

  render() {
    return (
      <CancelOrderForm
        {...this.props}
        handleSubmit={this.executeCancelOpenOrder}
      />
    );
  }
}
const mapStateToProps = state => ({});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      cancelOrder
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelOrderFormContainer);
