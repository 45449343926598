import React from "react";
import PropTypes from "prop-types";
import { RenderButton } from "components";

const RenderFileUpload = ({
  input,
  className,
  allowedFileTypes,
  btnText,
  handleUploadFn,
  disabled,
  ...custom
}) => {
  let fileInput;
  const selectedFile = input && input.value && input.value[0];
  return (
    <div>
      <input
        {...input}
        ref={ref => {
          fileInput = ref;
        }}
        style={{ display: "none" }}
        type="file"
        accept={allowedFileTypes.join(",")}
        {...custom}
        value={undefined}
        onChange={evt => {
          evt.persist();
          return handleUploadFn(evt);
        }}
      />
      {selectedFile ? (
        <p>
          <b>{selectedFile.name}</b> was selected
        </p>
      ) : (
        ""
      )}
      <RenderButton
        color="primary"
        className={className}
        onClick={() => fileInput.click()}
        disabled={disabled}
      >
        {btnText}
      </RenderButton>
    </div>
  );
};

RenderFileUpload.defaultProps = {
  className: "",
  handleUploadFn: () => {
    // noop
  }
};

RenderFileUpload.propTypes = {
  input: PropTypes.shape().isRequired,
  className: PropTypes.string,
  btnText: PropTypes.string.isRequired,
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleUploadFn: PropTypes.func
};

export default RenderFileUpload;
