import { API } from "aws-amplify";

const FinancingRequestsApi = {
  getSmartContracts() {
    return API.get("NODE_SERVICE_API", `/funding-requests`)
      .then(response => response)
      .catch(err => err);
  },
  sendLenderOffer(data) {
    const request = {
      body: data
    };
    return API.post("NODE_SERVICE_API", `/funding-requests/manage`, request)
      .then(response => response)
      .catch(err => err);
  },
  updateLendingOffer(data) {
    const request = {
      body: data
    };
    return API.post("NODE_SERVICE_API", `/funding-requests/update`, request)
      .then(response => response)
      .catch(err => err);
  }
};

export default FinancingRequestsApi;
