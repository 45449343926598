export const initialStateSmartContracts = {};

export const initialSmartContractsData = {
  smartContract: undefined,
  smartContracts: undefined,
  smartContractsLink: [],
  listSmartContracts: undefined,
  catalogsSmartContract: undefined,
  dataSmartContract: undefined,
  errorMessage: undefined,
  messageStatusError: undefined,
  destinations: undefined,
  destinationsCarrier: undefined,
  dialCodes: undefined,
  hardwareProfiles: [],
  funderList: [],
  biddingPortal: {
    dailyAllocatedAmount: undefined,
    interestRate: undefined,
    repaymentTerm: undefined
  },
  messageErrorCreate: undefined,
  smartContractsPaymentTerms: undefined,

  supplierHardwareProfile: undefined,
  customerHardwareProfile: undefined,
  type: undefined
};

export const initialRatesData = {
  data: [],
  total: 0,
  requiresActionTotal: 0,
  startOffset: 0,
  loading: false,
  info: undefined
};

export const initialSmartContractCatalogData = {
  codecs: [],
  countries: [],
  paymentTerms: [],
  paymentTermsNet: [],
  voipProtocols: []
};

export const initialRatePlanData = {
  data: [],
  file: undefined,
  loading: false
};

export const initialProposedRatesData = {
  info: undefined,
  error: undefined
};

export const initialSmartContractFundingOffers = {
  activeOffers: [],
  pendingOffers: [],
  declinedOffers: []
};

export const initialNewSmartContract = {};

export const SMART_CONTRACTS_DATA = "SMART_CONTRACTS";
export const SMART_CONTRACTS_CATALOG_DATA = "SMART_CONTRACTS_CATALOG_DATA";
export const SMART_CONTRACT_RATES_DATA = "SMART_CONTRACT_RATES_DATA";
export const SMART_CONTRACT_RATE_PLAN_DATA = "SMART_CONTRACT_RATE_PLAN_DATA";
export const SMART_CONTRACT_PROPOSED_RATES_DATA =
  "SMART_CONTRACT_PROPOSED_RATES_DATA";
export const SMART_CONTRACT_FUNDING_OFFERS = "SMART_CONTRACT_FUNDING_OFFERS";
export const SMART_CONTRACT_NEW_SMART_CONTRACT_DATA =
  "SMART_CONTRACT_NEW_SMART_CONTRACT_DATA";
export const SMART_CONTRACT_WALLET_DETAILS_DATA =
  "SMART_CONTRACT_WALLET_DETAILS_DATA";

export const getProposedRatesData = (state = initialProposedRatesData) =>
  state[SMART_CONTRACT_PROPOSED_RATES_DATA] || initialProposedRatesData;

export const getSmartContractRatePlanState = (state = initialRatePlanData) =>
  state[SMART_CONTRACT_RATE_PLAN_DATA] || initialRatePlanData;

export const getSmartContractsDataState = (
  state = initialStateSmartContracts
) => state[SMART_CONTRACTS_DATA] || initialSmartContractsData;

export const getSmartContractsCatalogDataState = (
  state = initialSmartContractCatalogData
) => state[SMART_CONTRACTS_CATALOG_DATA] || initialSmartContractCatalogData;

export const getListSmartContractData = (state = initialStateSmartContracts) =>
  getSmartContractsDataState(state).listSmartContracts;

export const getHardwareAndCodecs = (state = initialSmartContractsData) =>
  getSmartContractsDataState(state).hardwareAndCodecs;

export const getRatesDataState = (state = initialRatesData) =>
  state[SMART_CONTRACT_RATES_DATA] || initialRatesData;

export const getHardwareId = (state = initialSmartContractsData) =>
  getSmartContractsDataState(state).hardwareId;

export const getCatalogNewSmartContract = (
  state = initialStateSmartContracts
) => getSmartContractsDataState(state).catalogsSmartContract;

export const getSmartContractData = (state = initialStateSmartContracts) =>
  getSmartContractsDataState(state).smartContract;

export const getDataNewSmartContract = (state = initialStateSmartContracts) =>
  getSmartContractsDataState(state).dataSmartContract;

export const getDestinationsType = (state = initialStateSmartContracts) =>
  getSmartContractsDataState(state).destinations;

export const getDestinationsCarrier = (state = initialStateSmartContracts) =>
  getSmartContractsDataState(state).destinationsCarrier;

export const getDialCodes = (state = initialStateSmartContracts) =>
  getSmartContractsDataState(state).dialCodes;

export const getIpParticipant = (state = initialStateSmartContracts) =>
  getSmartContractsDataState(state).ipAddressParticipant;

export const getIpSupplier = (state = initialStateSmartContracts) =>
  getSmartContractsDataState(state).ipSupplier;
export const getIpCustomer = (state = initialStateSmartContracts) =>
  getSmartContractsDataState(state).ipCustomer;
export const getError = (state = initialStateSmartContracts) =>
  getSmartContractsDataState(state).errorMessage;
export const getErrorCreate = (state = initialStateSmartContracts) =>
  getSmartContractsDataState(state).messageErrorCreate;

export const getHardwareProfiles = (state = initialSmartContractsData) =>
  getDataNewSmartContract(state) !== undefined &&
  getDataNewSmartContract(state).hardwareProfiles !== undefined
    ? getDataNewSmartContract(state).hardwareProfiles
    : [];

export const getCodecs = (state = initialStateSmartContracts) =>
  getSmartContractsCatalogDataState(state).codecs;
export const getCountries = (state = initialStateSmartContracts) =>
  getSmartContractsCatalogDataState(state).countries;
export const getPaymentTerms = (state = initialStateSmartContracts) =>
  getSmartContractsCatalogDataState(state).paymentTerms;
export const getPaymentTermsNet = (state = initialStateSmartContracts) =>
  getSmartContractsCatalogDataState(state).paymentTermsNet;
export const getVoipProtocols = (state = initialStateSmartContracts) =>
  getSmartContractsCatalogDataState(state).voipProtocols;

export const getRatePlans = (state = initialStateSmartContracts) =>
  getSmartContractData(state) !== undefined &&
  getSmartContractData(state).ratePlans !== undefined
    ? getSmartContractData(state).ratePlans
    : [];

export const getCustomerHardwareProfile = (
  state = initialStateSmartContracts
) =>
  getSmartContractData(state) !== undefined &&
  getSmartContractData(state).customerHardwareProfile !== undefined
    ? getSmartContractData(state).customerHardwareProfile
    : undefined;

export const getSupplierHardwareProfile = (
  state = initialStateSmartContracts
) =>
  getSmartContractData(state) !== undefined &&
  getSmartContractData(state).supplierHardwareProfile !== undefined
    ? getSmartContractData(state).supplierHardwareProfile
    : undefined;

export const getDefaultSettlementId = (state = initialStateSmartContracts) =>
  getDataNewSmartContract(state) !== undefined &&
  getDataNewSmartContract(state).defaultSettlementPeriod !== undefined &&
  getDataNewSmartContract(state).defaultSettlementPeriod.settlementId !==
    undefined
    ? getDataNewSmartContract(state).defaultSettlementPeriod.settlementId
    : undefined;

export const getSmartContractCatalogCodecs = (
  state = initialStateSmartContracts
) =>
  getCatalogNewSmartContract(state) !== undefined &&
  getCatalogNewSmartContract(state).codecs !== undefined
    ? getCatalogNewSmartContract(state).codecs
    : [];

export const getContractData = (state = initialStateSmartContracts) =>
  getSmartContractData(state) !== undefined &&
  getSmartContractData(state).contract !== undefined
    ? getSmartContractData(state).contract
    : [];

export const getContractType = (state = initialStateSmartContracts) =>
  getSmartContractData(state) !== undefined &&
  getSmartContractData(state).type !== undefined
    ? getSmartContractData(state).type
    : null;

export const getSmartContractCountData = (state = {}) =>
  state.SMART_CONTRACT_COUNT_DATA !== undefined
    ? state.SMART_CONTRACT_COUNT_DATA
    : state;

export const getSmartContractAddressData = (state = {}) =>
  state.SMART_CONTRACT_ADDRESS_DATA !== undefined
    ? state.SMART_CONTRACT_ADDRESS_DATA
    : state;

export const getSmartContractTempId = (state = initialStateSmartContracts) =>
  !!getContractData(state) && getContractData(state).tempId !== undefined
    ? getContractData(state).tempId
    : undefined;

export const getSmartContractFundingOffers = (
  state = initialSmartContractFundingOffers
) => state[SMART_CONTRACT_FUNDING_OFFERS] || initialSmartContractFundingOffers;

export const getNewSmartContractData = (state = initialNewSmartContract) =>
  state[SMART_CONTRACT_NEW_SMART_CONTRACT_DATA] || initialNewSmartContract;

export const getSmartContractWalletData = (state = {}) =>
  state[SMART_CONTRACT_WALLET_DETAILS_DATA] || [];
