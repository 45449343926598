import React, { Component } from "react";
import { RoutesEdit } from "components";
import {
  getSmartContractRouteData,
  loadSmartContract,
  initialSmartContractNew,
  postNewRouteRequest,
  postUpdateRouteRequest,
  postDeleteRouteRequest,
  postUpdateRouteSmartContractRequest
} from "store/actions";
import { fromRoutes, fromSmartcontracts } from "store/selectors";
import { connect } from "react-redux";
import { reset } from "redux-form";

class RoutesEditContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openRouteDetails: false
    };

    this.handleNewRouteSubmit = this.handleNewRouteSubmit.bind(this);
    this.handleUpdateRouteSubmit = this.handleUpdateRouteSubmit.bind(this);
    this.handleDeleteRouteSubmit = this.handleDeleteRouteSubmit.bind(this);
    this.handleUpdateSmartContractConnectionProfile = this.handleUpdateSmartContractConnectionProfile.bind(this);
  }

  componentWillMount() {
    const {
      loadRoutesData,
      match: { params }
    } = this.props;
    if (params.smartContractId) {
      loadRoutesData(params.smartContractId);
    }
  }

  handleNewRouteSubmit(data) {
    const {
      dispatch,
      saveRoute,
      match: {
        params: { smartContractId }
      }
    } = this.props;
    saveRoute(smartContractId, data);
    dispatch(reset("RoutesCreateForm"));
  }

  handleUpdateRouteSubmit(data) {
    const { updateRoute } = this.props;
    updateRoute(data);
  }

  handleDeleteRouteSubmit(id) {
    const {
      deleteRoute,
      routesData: { routes }
    } = this.props;
    const data = routes.find(obj => obj._id === id);
    deleteRoute(data);
  }

  handleUpdateSmartContractConnectionProfile(data) {
    const { updateSmartContract } = this.props;
    updateSmartContract(data.id, data);
  }

  render() {
    const {
      catalog,
      routesData,
      hardwareProfiles,
      customerHardwareProfile,
      supplierHardwareProfile,
      newSmartContractData,
      smartContract
    } = this.props;
    return (
      <RoutesEdit
        catalog={catalog}
        routesData={routesData}
        smartContract={smartContract}
        hardwareProfiles={hardwareProfiles}
        newSmartContractData={newSmartContractData}
        customerHardwareProfile={customerHardwareProfile}
        supplierHardwareProfile={supplierHardwareProfile}
        handleNewRouteSubmit={this.handleNewRouteSubmit}
        handleUpdateRouteSubmit={this.handleUpdateRouteSubmit}
        handleDeleteRouteSubmit={this.handleDeleteRouteSubmit}
        handleUpdateIngress={this.handleUpdateSmartContractConnectionProfile}
      />
    );
  }
}

const mapStateToProps = state => ({
  routesData: fromRoutes.getSmartContractsRouteData(state),
  catalog: fromSmartcontracts.getCatalogNewSmartContract(state),
  newSmartContractData: fromSmartcontracts.getDataNewSmartContract(state),
  smartContract: fromSmartcontracts.getSmartContractsDataState(state)
    .smartContract,
  customerHardwareProfile: fromSmartcontracts.getCustomerHardwareProfile(state),
  supplierHardwareProfile: fromSmartcontracts.getSupplierHardwareProfile(state),
  hardwareProfiles: fromSmartcontracts.getHardwareProfiles(state)
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  loadRoutesData(smartContractId) {
    dispatch(initialSmartContractNew());
    dispatch(loadSmartContract(smartContractId));
    dispatch(getSmartContractRouteData(smartContractId));
  },
  saveRoute(smartContractId, data) {
    dispatch(postNewRouteRequest(smartContractId, data));
  },
  updateRoute(data) {
    dispatch(postUpdateRouteRequest(data));
  },
  deleteRoute(data) {
    dispatch(postDeleteRouteRequest(data));
  },
  updateSmartContract(smartContractId, data) {
    dispatch(postUpdateRouteSmartContractRequest(smartContractId, data));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoutesEditContainer);
