import React from "react";

import { PublicTemplate } from "components";
import { ChangePasswordContainer } from "containers";

const ChangePasswordPage = ({ ...props }) => (
  <PublicTemplate>
    <ChangePasswordContainer />
  </PublicTemplate>
);

export default ChangePasswordPage;
