import React, { useState, useEffect, memo } from 'react';

export default memo(({ children, blinkForSeconds, secondsPerBlink }) => {
	const [blink, setBlink] = useState(false);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setBlink(prevBlink => !prevBlink);
		}, parseFloat(blinkForSeconds) * 1000);

		return () => clearInterval(intervalId);
	}, []);

	return (
		<>
			<span
				style={{
					animation: blink ? `blinking ${parseFloat(secondsPerBlink)}s infinite` : 'none'
				}}
			>
				{children}
			</span>
			<style>{`
                @keyframes blinking {
                0% { opacity: 1; }
                50% { opacity: 0.3; }
                100% { opacity: 1; }
                }
            `}</style>
		</>
	);
});
