const invoiceFooterStyle = theme => ({
  table: {
    minWidth: 700
  },
  w400: {
    minWidth: 400
  },
  w300: {
    minWidth: 300
  },
  w200: {
    minWidth: 200
  },
  w100: {
    minWidth: 100
  },
  noBorderBottom: {
    borderBottom: "none"
  },
  tableRow: {
    height: "auto"
  }
});

export default invoiceFooterStyle;
