import React, { Component } from "react";
import { SmartContractDestinationCodeRow, RenderButton } from "components";
import { Paper, CardContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  noWrap: {
    whiteSpace: "nowrap",
    minWidth: "150px"
  }
});

class SmartContractDestinationCode extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { fields } = this.props;
    return (
      <table id="new-items" style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td>
              <RenderButton color="primary" onClick={() => fields.push({})}>
                Add New Rate
              </RenderButton>
            </td>
          </tr>
          {fields.map((member, index) => (
            <SmartContractDestinationCodeRow
              {...this.props}
              codeRowIndex={index}
              nameBase={member}
              key={index}
            />
          ))}
        </tbody>
      </table>
    );
  }
}

export default withStyles(styles)(SmartContractDestinationCode);
