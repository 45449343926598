import React from "react";
import { CardContent, Grid, Paper } from "@material-ui/core";
import { ReportFilters, ReportMargin, ReportLending } from "components";
import {
  getReportsData,
  setReportsData,
  getReportsCDRData
} from "store/actions";
import { fromReports } from "store/selectors";
import moment from "moment";
import { getFormValues } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class FinancialReports extends React.Component {
  componentWillMount() {
    const { reportType } = this.props;
    this.props.setReportsData([]); // reset data on tab change
    this.props.getReportsData({}, reportType);
  }

  generateReport = data => {
    const { reportType } = this.props;
    this.props.getReportsData(data, reportType);
  };

  render() {
    const { reportType, reportData } = this.props;
    return (
      <Grid container>
        <Grid item xs={12}>
          <Paper>
            <CardContent>
              <ReportFilters
                reportType={reportType}
                onSubmit={this.generateReport}
                initialValues={{
                  reportType,
                  startDate: moment()
                    .utc()
                    .startOf("day")
                    .format("YYYY-MM-DD HH:mm"),
                  endDate: moment()
                    .utc()
                    .endOf("day")
                    .format("YYYY-MM-DD HH:mm"),
                  startHour: "00",
                  endHour: "23"
                }}
              />
            </CardContent>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <CardContent>
              <div>
                {reportType === "margin" ? (
                  <ReportMargin
                    tableData={Array.isArray(reportData) ? reportData : []}
                  />
                ) : (
                  ""
                )}
                {reportType === "lending" ? (
                  <ReportLending
                    tableData={Array.isArray(reportData) ? reportData : []}
                  />
                ) : (
                  ""
                )}
              </div>
            </CardContent>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  reportData: fromReports.getReportsData(state),
  filterOptions: getFormValues("ReportFiltersForm")(state)
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getReportsCDRData,
      getReportsData,
      setReportsData
    },
    dispatch
  );
}

FinancialReports.propTypes = {
  // filterData: PropTypes.shape().isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinancialReports);
