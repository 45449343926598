import React, { memo, useCallback, useEffect, useState, useMemo } from 'react';
import { Paper, CardContent, IconButton } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AuthManager, ConfirmationDialog, Loader, RenderTable, RenderButton } from 'components';
import { CreateHardwareProfile, UpdateHardwareProfile } from './components';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

const OriginationHardwareProfiles = memo(() => {
	const dispatch = useDispatch();
	const hardwareProfiles = useSelector(state => (state.routes && state.routes.originationHardwareProfiles) || []);
	const isLoading = useSelector(state => (state.routes && state.routes.loadingOriginationHardwareProfiles) || false);

	const [createModalOpen, setCreateModalOpen] = useState(false);
	const [updateModalOpen, setUpdateModalOpen] = useState(false);
	const { t } = useTranslation('routing/routing');
	const triggerDeleteConnectionProfile = useCallback(hardwareProfileId => {
		dispatch({
			type: 'routes/DELETE_HARDWARE_PROFILE',
			payload: {
				hardwareProfileId,
				type: 'origination'
			}
		});
	}, []);

	useEffect(() => {
		dispatch({ type: 'routes/GET_ORIGIN_HARDWARE_PROFILES' });
	}, []);

	const columns = useMemo(
		() => [
			{
				Header: t('name_up'),
				accessor: 'name'
			},
			{
				Header: t('primary_ip_address'),
				accessor: 'primaryIPAddress'
			},
			{
				Header: '',
				accessor: 'actions',
				width: 150,
				Cell: cell => {
					const { original } = cell;

					return (
						<div className="table-actions">
							<AuthManager permissions={['routing.update-routes']}>
								<IconButton onClick={() => setUpdateModalOpen(original._id)} color="primary" size="small">
									<Edit />
								</IconButton>
							</AuthManager>
							<AuthManager permissions={['routing.delete-routes']}>
								<ConfirmationDialog
									buttonProps={{
										size: 'small',
										color: 'danger'
									}}
									title={t('confirm_delete_con_prof')}
									onConfirm={() => triggerDeleteConnectionProfile(original._id)}
									okText={t('okText_delete_con_prof')}
									cancelText="No"
								>
									<Delete />
								</ConfirmationDialog>
							</AuthManager>
						</div>
					);
				}
			}
		],
		[]
	);

	return (
		<div className={styles.container}>
			<Paper>
				{(isLoading && <Loader loading />) || ''}
				<CardContent>
					<div className="card-header">
						<h4>{t('origination_conn_profiles')}</h4>
						<RenderButton small color="primary" onClick={() => setCreateModalOpen(state => !state)}>
						{t('new_ori_conn')}
						</RenderButton>
					</div>
					<RenderTable data={(hardwareProfiles && Array.isArray(hardwareProfiles) && hardwareProfiles) || []} columns={columns} filterable={false} />
				</CardContent>
			</Paper>

			<CreateHardwareProfile open={createModalOpen} triggerOpenClose={() => setCreateModalOpen(state => !state)} />
			{(!!updateModalOpen && <UpdateHardwareProfile open={!!updateModalOpen} triggerOpenClose={() => setUpdateModalOpen(false)} hardwareProfileId={updateModalOpen} />) || ''}
		</div>
	);
});

export default OriginationHardwareProfiles;
