import React, { memo, useCallback, useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, RenderButton, RenderTable } from 'components';
import style from './style.module.scss';
import { useTranslation } from 'react-i18next';

const Transition = props => <Slide direction="up" {...props} />;

const CreateNewRoute = memo(({ open, triggerOpenClose, formik }) => {
	const dispatch = useDispatch();
	const codesLoading = useSelector(state => (state.routes && state.routes.routeCodeLoading) || false);
	const { matchCount, matches } = useSelector(state => state.routes && state.routes.routeCodeMatches);

	const [matchesPerPage, setMatchesPerPage] = useState(20);
	const [matchPage, setMatchPage] = useState(1);
	const { t } = useTranslation('routing/routing');
	const triggerLoadCodes = useCallback(() => {
		dispatch({
			type: 'routes/GET_ROUTE_CODE_MATCHES',
			payload: {
				country: formik.values.countryMatch,
				type: formik.values.countryMatchType,
				perPage: matchesPerPage,
				page: matchPage
			}
		});
	}, [formik.values.countryMatch, formik.values.countryMatchType, matchesPerPage, matchPage]);

	const matchTablePagination = useCallback(pageNum => {
		setMatchPage(pageNum);
		triggerLoadCodes();
	}, []);

	const matchTablePageSize = useCallback((pageSize, pageIndex) => {
		setMatchesPerPage(pageSize);
		setMatchPage(pageIndex);
		triggerLoadCodes();
	}, []);

	useEffect(() => {
		if (open) {
			triggerLoadCodes();
		}
	}, [formik.values.countryMatch, formik.values.countryMatchType, open]);

	const tableColumns = [
		{
			Header: t('code'),
			accessor: 'code'
		},
		{
			Header: t('country'),
			accessor: 'country'
		},
		{
			Header: t('type'),
			accessor: 'type'
		},
		{
			Header: t('carrier'),
			accessor: 'carrier'
		}
	];

	return (
		<Dialog maxWidth="md" fullWidth open={open} onBackdropClick={triggerOpenClose} TransitionComponent={Transition} className={style.container}>
			<DialogTitle>{t('code_matches')}</DialogTitle>
			<DialogContent>
				{(codesLoading && <Loader loading />) || ''}
				<RenderTable
					data={matches}
					columns={tableColumns}
					loading={codesLoading}
					manual
					showPagination
					tableTotal={matchCount}
					pages={Math.ceil(matchCount / matchesPerPage)}
					onPageChange={matchTablePagination}
					defaultPageSize={matchesPerPage}
					onPageSizeChange={matchTablePageSize}
					sortable={false}
					filterable={false}
				/>
			</DialogContent>
			<DialogActions>
				<RenderButton color="danger" onClick={triggerOpenClose}>
					{t('close')}
				</RenderButton>
			</DialogActions>
		</Dialog>
	);
});

export default CreateNewRoute;
