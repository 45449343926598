import React, { memo, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography, Paper, TextField } from '@material-ui/core';
import * as moment from 'moment';

import ClaimDetail from './ClaimDetail';
import Loader from 'components/atoms/Loader';
import CurrencyNumber from 'components/atoms/CurrencyNumber';
import { RenderTable } from 'components';
import actions from 'store/acp/actions';

import styles from './styles.module.scss';

export default memo(() => {
	const dispatch = useDispatch();

	const [expanded, setExpanded] = useState({});

	const isLoading = useSelector(state => (state.acp && state.acp.loading) || false);
	const proFormaClaims = useSelector(state => (state.acp && state.acp.newProFormaClaims) || []);

	useEffect(() => {
		dispatch({ type: actions.GET_NEW_PRO_FORMA_CLAIMS });
	}, []);

	const DateFilter = ({ filter, onChange, type = 'date' }) => (
		<TextField
			type={type}
			value={filter ? filter.value : ''}
			onChange={e => onChange(e.target.value)}
			style={{ width: '80%' }}
			variant="standard"
			InputLabelProps={{
				shrink: true
			}}
		/>
	);

	const columns = [
		{
			Header: 'Claim Period',
			accessor: 'claimPeriod',
			Cell: cell => {
				return <span>{moment(cell.original?.claimPeriod).format('MMMM YYYY')}</span>;
			},
			width: 200,
			Filter: ({ filter, onChange }) => <DateFilter filter={filter} onChange={onChange} type="month" />
		},
		{
			Header: 'No of Claims',
			accessor: 'noOfClaims',
			Cell: cell => {
				return <span>{cell.original?.claimNumbers?.length ?? 0}</span>;
			},
			width: 125,
			Filter: ({ filter, onChange }) => <TextField value={filter ? filter.value : ''} onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard" />
		},
		{
			Header: 'Total quantity',
			accessor: 'quantity',
			Cell: cell => {
				return <span>{cell.original?.quantity}</span>;
			},
			width: 125,
			Filter: ({ filter, onChange }) => <TextField value={filter ? filter.value : ''} onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard" />
		},
		{
			Header: <div className="number-column">Value</div>,
			accessor: 'value',
			Cell: cell => {
				return (
					<div className="number-column">
						<CurrencyNumber amount={cell.original?.value} precision={0} />
					</div>
				);
			},
			width: 125,
			Filter: ({ filter, onChange }) => <TextField value={filter ? filter.value : ''} onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard" />
		},
		{
			Header: <div className="number-column">Financed</div>,
			accessor: 'financed',
			Cell: cell => {
				return (
					<div className="number-column">
						<CurrencyNumber amount={cell.original?.financed} precision={0} />
					</div>
				);
			},
			width: 125,
			Filter: ({ filter, onChange }) => <TextField value={filter ? filter.value : ''} onChange={filterEvent => onChange(filterEvent.target.value)} variant="standard" />
		},
		{
			Header: 'Last Update Date',
			accessor: 'updatedAt',
			Cell: cell => {
				return <span>{moment(cell.original?.updatedAt).format('MMMM DD YYYY') ?? ''}</span>;
			},
			width: 170,
			Filter: ({ filter, onChange }) => <DateFilter filter={filter} onChange={onChange} type="date" />
		}
	];

	const getTdProps = useCallback((state, rowInfo) => ({
		onClick: (e, handleOriginal) => {
			const { original } = rowInfo;
			const path = rowInfo.nestingPath[0];
			const diff = { [path]: !expanded[path] };
			setExpanded(diff);
			// const localClaimList =
			// original &&
			// original.submissions &&
			// original.submissions.map(claim => {
			// 	let quantity;
			// 	if (claim.total >= 0) {
			// 		quantity = claim?.total / 30;
			// 	} else {
			// 		quantity = 0;
			// 	}
			// 	return {
			// 		claimPeriod: moment(claim.claimPeriod).format('MMMM YY'),
			// 		nladVerifiedDate: moment(claim.createdAt).format('MMMM DD YYYY HH:mm'),
			// 		type: claim.type,
			// 		quantity: quantity
			// 	};
			// });
			// setClaimList(localClaimList);
			if (handleOriginal) {
				handleOriginal();
			}
		}
	}));

	return (
		<div className={styles.container}>
			<Paper>
				<Grid container className="grid-container">
					<Grid item xs={12}>
						<Typography variant="h1" gutterBottom>
							Pro Forma Claims
						</Typography>
					</Grid>
					<Grid item xs={12}>
						{(isLoading && <Loader isLoading />) || (
							<RenderTable
								forceSingleExpand
								isExpandable
								defaultSorted={[
									{
										id: 'claimPeriod',
										desc: true
									}
								]}
								data={proFormaClaims ?? []}
								loading={isLoading}
								expanded={expanded}
								onPageChange={() => setExpanded({})}
								columns={columns}
								SubComponent={row => <ClaimDetail row={row} />}
								getTdProps={getTdProps}
								filterable={!isLoading}
								sortable={true}
								minRows={2}
							/>
						)}
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
});
