import React, { memo, useEffect } from 'react';
import { RenderTable, RenderButton } from 'components';
import {Paper, Grid, Accordion, AccordionSummary, AccordionDetails, Badge, CardContent} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { contractIdFormatter } from 'utils';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const BuyingRoutesList = memo(({ data }) => {
	const { t } = useTranslation('routing/routing');

	const columns = [
		{
			Header: t('smart_contract'),
			Cell: ({ original: { _id, contractName, partnerName } }) => contractIdFormatter(_id, contractName, partnerName),
		},
		{
			Header: t('prefix'),
			Cell: ({ original: { _id } }) => `${parseInt((parseInt(_id) + 1000), 32)}#`
		},
		{
			Header: t('connection_profile'),
			Cell: ({ original: { hardwareProfile } }) => hardwareProfile && hardwareProfile.name || "Not set"
		},
		{
			Header: t('connection_profile_ips'),
			Cell: ({ original: { hardwareProfile } }) => hardwareProfile && hardwareProfile.ips && hardwareProfile.ips.join(", ") || "Not set"
		},
		{
			Header: t('actions'),
			Cell: ({ original: { _id } }) => {
				return (
					<RenderButton
						color="primary"
						small
						component={Link}
						to={`/routing/${_id}`}
					>
						{t('update')}
					</RenderButton>
				);
			},
			width: 150
		}
		// {
		// 	Header: 'Route Type',
		// 	accessor: 'routeType',
		// 	Cell: d => (d.value ? (d.value === 'S' ? t('static') : d.value === 'P' ? t('percentage') : 'LCR') : '-')
		// },
		// {
		// 	Header: 'Destination',
		// 	Cell: ({ original: { destinations, routeType } }) => {
		// 		return (
		// 			<div>
		// 				{destinations.map((destination) => (
		// 					<div>
		// 						{routeType === "P" && `${destination.percentage}% - `}
		// 						{destination.contract && contractIdFormatter(destination.contract._id, destination.contract.contractName, destination.contract.partnerName) || destination.hardwareProfile && destination.hardwareProfile.name || destination.id}
		// 					</div>
		// 				))}
		// 			</div>
		// 		)
		// 	}
		// },
	];

	return (
		<div className="routes-list">
			{(data || []).length === 0 && (
				<Paper>
					<CardContent className="text-center">
						{t('no_actives_buying')}
					</CardContent>
				</Paper>
			) || null}
			{data.map((item, index) => (
				<Paper key={index} className="route-item">
					<Accordion elevation={0} >
						<AccordionSummary expandIcon={<ExpandMore />} className={'route-item-header'}>
							<Grid container spacing={2}>
								<Grid item>
									<div className="heading-title">{item.supplierName}</div>
									<div className="heading-subtitle">{(item.contracts || []).length} {t('active_contracts')}</div>
								</Grid>
							</Grid>
						</AccordionSummary>
						<AccordionDetails>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<RenderTable
										data={item && item.contracts || []}
										columns={columns}
										showPagination={false}
										sortable={false}
										minRows={2}
										noDataText={(
											<div>
												<strong>{t('no_origination_found')}</strong><br />
											</div>
										)}
									/>
								</Grid>
							</Grid>
						</AccordionDetails>
					</Accordion>
				</Paper>
			))}
		</div>
	);
});

export default BuyingRoutesList;
