import React, { Component } from 'react';
import moment from 'moment';
import { Field, reduxForm } from 'redux-form';
import { RenderDatePicker, RenderButton } from 'components';
import {ButtonGroup, Grid, Input} from '@material-ui/core';
import {FileDownload} from '@material-ui/icons';

class WalletTransactionReportForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			format: 'csv',
			date: moment()
		};
		this.handleSubmitTrigger = this.handleSubmitTrigger.bind(this);
		this.triggerDateChange = this.triggerDateChange.bind(this);
	}

	handleSubmitTrigger = format => {
		const { onSubmit } = this.props;
		const { date } = this.state;
		this.setState({ format });
		onSubmit({
			format,
			date: (date && moment(date).format('YYYY/MM/01')) || null
		});
	};

	triggerDateChange(date) {
		this.setState({ date });
	}

	render() {
		const { handleSubmit } = this.props;
		const { format, date } = this.state;
		return (
			<form onSubmit={handleSubmit}>
				<Field component={Input} type="hidden" name="format" value={format} style={{ display: 'none' }} />
				<Grid container>
					<Grid item xs={12} md={6}>
						<Field
							component={RenderDatePicker}
							name="date"
							value={date}
							onChange={this.triggerDateChange}
							label="MM / YYYY"
							dateFormat="MM/YYYY"
							views={['month', 'year']}
							autoOk
						/>
					</Grid>
					<Grid item xs={12} md={6} style={{ paddingTop: 10, paddingLeft: 10 }}>
						<ButtonGroup>
							<RenderButton
								color="primary"
								onClick={() => {
									this.handleSubmitTrigger('pdf');
								}}
							>
								<FileDownload /> PDF
							</RenderButton>

							<RenderButton
								color="primary"
								onClick={() => {
									this.handleSubmitTrigger('csv');
								}}
							>
								<FileDownload /> CSV
							</RenderButton>
						</ButtonGroup>
					</Grid>
				</Grid>
			</form>
		);
	}
}

export default reduxForm({
	form: 'WalletTransactionReportForm',
	destroyOnUnmount: false,
	enableReinitialize: false
})(WalletTransactionReportForm);
