import React from "react";
import PropTypes from "prop-types";

const PlusButton = props => (
  <div className="text-center">
    <a
      className="btn btn-sm btn-success m-r-10 m-b-30"
      onClick={() => props.callbackFromParent()}
    >
      {props.title}
    </a>
  </div>
);

PlusButton.propTypes = {
  title: PropTypes.string.isRequired,
  callbackFromParent: PropTypes.func.isRequired
};

export default PlusButton;
