const breadcrumbsStyle = theme => ({
	breadcrumbs: {
		margin: `${-theme.spacing(1)}px
      ${-theme.spacing(1)}px
      ${theme.spacing(1)}px
    `,
		position: 'relative',
		display: 'flex',
		padding: theme.spacing(1),
		alignItems: 'center',
		height: theme.spacing(1) * 6,
		borderRadius: 0
	},
	breadcrumbsList: {
		listStyle: 'none',
		display: 'flex',
		alignItems: 'center',
		margin: '0',
		padding: '0',
		fontSize: '16px',
		textTransform: 'capitalize'
	},
	breadcrumbsChildren: {
		flexGrow: 1
	},
	preloader: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		width: '100%'
	}
});

export default breadcrumbsStyle;
