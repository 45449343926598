import React, { memo } from "react";
import { Form, Formik } from "formik";

import RoutesFilterFields from "./RouteFilterFields";

const RoutesFilters = memo(({ triggerOnSubmit }) => {
  return (
    <Formik
      initialValues={{
        contractType: "selling",
        customer: "",
        supplier: "",
        country: "",
        destinationType: "",
        destinationCarrier: "",
        contract: ""
      }}
      enableReinitialize
      onSubmit={triggerOnSubmit}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <RoutesFilterFields />
        </Form>
      )}
    </Formik>
  );
});

export default RoutesFilters;
