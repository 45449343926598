const actions = {
	SET_STATE: 'routes/SET_STATE',

	GET_COUNTRIES: 'routes/GET_COUNTRIES',
	GET_STATES: 'routes/GET_STATES',

	// Routes page
	GET_LIST_FILTERS: 'routes/GET_LIST_FILTERS',
	GET_LIST: 'routes/GET_LIST',
	GET_CONTRACT_DETAILS: 'routes/GET_CONTRACT_DETAILS',
	UPDATE_ROUTE_SETTINGS: 'routes/UPDATE_ROUTE_SETTINGS',
	GET_ROUTE_CODE_MATCHES: 'routes/GET_ROUTE_CODE_MATCHES',
	GET_UPDATE_ROUTE_CODE_MATCHES: 'routes/GET_UPDATE_ROUTE_CODE_MATCHES',
	GET_ROUTES_LIST: 'routes/GET_ROUTES_LIST',
	GET_ROUTE_DETAILS: 'routes/GET_ROUTE_DETAILS',
	CREATE_NEW_ROUTE: 'routes/CREATE_NEW_ROUTE',
	UPDATE_ROUTE: 'routes/UPDATE_ROUTE',
	DELETE_ROUTE: 'routes/DELETE_ROUTE',

	// Hardware Profiles Page
	GET_HARDWARE_PROFILE_OPTIONS: 'routes/GET_HARDWARE_PROFILE_OPTIONS',
	GET_PARTITION_INFORMATION: 'routes/GET_PARTITION_INFORMATION',
	GET_ORIGIN_HARDWARE_PROFILES: 'routes/GET_ORIGIN_HARDWARE_PROFILES',
	GET_TERMINATION_HARDWARE_PROFILES: 'routes/GET_TERMINATION_HARDWARE_PROFILES',

	UPLOAD_HARDWARE_PROFILE_RATE: 'routes/UPLOAD_HARDWARE_PROFILE_RATE',
	GET_HARDWARE_PROFILE: 'routes/GET_HARDWARE_PROFILE',
	GET_HARDWARE_PROFILE_RATES: 'routes/GET_HARDWARE_PROFILE_RATES',

	CREATE_HARDWARE_PROFILE: 'routes/CREATE_HARDWARE_PROFILE',
	UPDATE_HARDWARE_PROFILE: 'routes/UPDATE_HARDWARE_PROFILE',
	DELETE_HARDWARE_PROFILE: 'routes/DELETE_HARDWARE_PROFILE'

};

export const REQUEST_ROUTES_SMARTCONTRACTS_LIST = 'REQUEST_ROUTES_SMARTCONTRACTS_LIST';
export const REQUEST_ROUTES_SMARTCONTRACTS_LIST_FILTERS = 'REQUEST_ROUTES_SMARTCONTRACTS_LIST_FILTERS';
export const ROUTES_SMARTCONTRACTS_LIST_FILTERS = 'ROUTES_SMARTCONTRACTS_LIST_FILTERS';
export const REQUEST_SMARTCONTRACT_ROUTE_DATA = 'REQUEST_SMARTCONTRACT_ROUTE_DATA';
export const REQUEST_ROUTES_NEW_ROUTE = 'REQUEST_ROUTES_NEW_ROUTE';
export const REQUEST_ROUTES_UPDATE_ROUTE = 'REQUEST_ROUTES_UPDATE_ROUTE';
export const REQUEST_ROUTES_DELETE_ROUTE = 'REQUEST_ROUTES_DELETE_ROUTE';
export const REQUEST_ROUTES_UPDATE_SMARTCONTRACT = 'REQUEST_ROUTES_UPDATE_SMARTCONTRACT';

export const SMARTCONTRACTS_LIST = 'SMARTCONTRACTS_LIST';
export const SMARTCONTRACTS_ROUTE_DATA = 'SMARTCONTRACTS_ROUTE_DATA';
export const ROUTE_CODES = 'ROUTE_CODES';

export const UPLOAD_VENDOR_RATE_PLAN = 'UPLOAD_VENDOR_RATE_PLAN';
export const UPLOAD_VENDOR_RATE_PLAN_SUCCESS = 'UPLOAD_VENDOR_RATE_PLAN_SUCCESS';
export const UPLOAD_VENDOR_RATE_PLAN_ERROR = 'UPLOAD_VENDOR_RATE_PLAN_ERROR';
export const VENDOR_RATES = 'VENDOR_RATES';
export const VENDOR_RATES_SUCCESS = 'VENDOR_RATES_SUCCESS';
export const VENDOR_RATES_ERROR = 'VENDOR_RATES_ERROR';

export const getRoutesSmartContractsList = data => ({
	type: REQUEST_ROUTES_SMARTCONTRACTS_LIST,
	payload: data
});

export const setRoutesSmartContractsList = data => ({
	type: SMARTCONTRACTS_LIST,
	payload: data
});

export const getRoutesSmartContractsListFilters = data => ({
	type: REQUEST_ROUTES_SMARTCONTRACTS_LIST_FILTERS,
	payload: data
});

export const setRoutesSmartContractsListFilters = data => ({
	type: ROUTES_SMARTCONTRACTS_LIST_FILTERS,
	payload: data
});

export const getSmartContractRouteData = smartContractId => ({
	type: REQUEST_SMARTCONTRACT_ROUTE_DATA,
	payload: smartContractId
});

export const setSmartContractRouteData = data => ({
	type: SMARTCONTRACTS_ROUTE_DATA,
	payload: data
});

export const postNewRouteRequest = (smartContractId, data) => ({
	type: REQUEST_ROUTES_NEW_ROUTE,
	payload: { smartContractId, data }
});

export const postUpdateRouteRequest = data => ({
	type: REQUEST_ROUTES_UPDATE_ROUTE,
	payload: {
		smartContractId: data.smartContractId,
		data
	}
});

export const postDeleteRouteRequest = data => ({
	type: REQUEST_ROUTES_DELETE_ROUTE,
	payload: {
		smartContractId: data.smartContractId,
		data
	}
});

export const postUpdateRouteSmartContractRequest = (smartContractId, data) => ({
	type: REQUEST_ROUTES_UPDATE_SMARTCONTRACT,
	payload: {
		smartContractId,
		data
	}
});

export const uploadVendorRateplan = (tempId, ratePlanFile) => ({
	type: UPLOAD_VENDOR_RATE_PLAN,
	payload: {
		tempId,
		ratePlanFile
	}
});

export const uploadVendorPlanSuccess = (tempId, data) => ({
	type: UPLOAD_VENDOR_RATE_PLAN_SUCCESS,
	payload: {
		tempId,
		info: data
	}
});

export const uploadVendorRatePlanError = (tempId, data) => ({
	type: UPLOAD_VENDOR_RATE_PLAN_ERROR,
	payload: {
		tempId,
		error: data
	}
});

export const loadVendorRates = (externalProfileId, options) => ({
	type: VENDOR_RATES,
	payload: {
		externalProfileId,
		options
	}
});

export const loadVendorRatesSuccess = (externalProfileId, data) => ({
	type: VENDOR_RATES_SUCCESS,
	payload: {
		...data,
		externalProfileId
	}
});

export const loadVendorRatesError = (externalProfileId, data) => ({
	type: VENDOR_RATES_ERROR,
	payload: {
		data,
		externalProfileId
	}
});

export const EXTERNAL_RATES = 'EXTERNAL_RATES';
export const EXTERNAL_RATES_SUCCESS = 'EXTERNAL_RATES_SUCCESS';
export const EXTERNAL_RATES_ERROR = 'EXTERNAL_RATES_ERROR';

export const loadExternalRates = (externalProfileId, options) => ({
	type: EXTERNAL_RATES,
	payload: {
		externalProfileId,
		options
	}
});

export const loadExternalRatesSuccess = (externalProfileId, data) => ({
	type: EXTERNAL_RATES_SUCCESS,
	payload: {
		...data,
		externalProfileId
	}
});

export const loadExternalRatesError = (externalProfileId, data) => ({
	type: EXTERNAL_RATES_ERROR,
	payload: {
		data,
		externalProfileId
	}
});

export const EXTERNAL_INVOICE = 'EXTERNAL_INVOICE';
export const EXTERNAL_INVOICE_SUCCESS = 'EXTERNAL_INVOICE_SUCCESS';
export const EXTERNAL_INVOICE_ERROR = 'EXTERNAL_INVOICE_ERROR';

export const loadExternalInvoicePreviewData = externalProfileId => ({
	type: EXTERNAL_INVOICE,
	payload: {
		externalProfileId
	}
});

export const loadExternalInvoicePreviewDataSuccess = (externalProfileId, data) => ({
	type: EXTERNAL_INVOICE_SUCCESS,
	payload: {
		...data,
		externalProfileId
	}
});

export const loadExternalInvoicePreviewDataError = (externalProfileId, data) => ({
	type: EXTERNAL_INVOICE_ERROR,
	payload: {
		data,
		externalProfileId
	}
});

export const UPLOAD_EXTERNAL_RATE_PLAN = 'UPLOAD_EXTERNAL_RATE_PLAN';
export const UPLOAD_EXTERNAL_RATE_PLAN_ERROR = 'UPLOAD_EXTERNAL_RATE_PLAN_ERROR';
export const UPLOAD_EXTERNAL_RATE_PLAN_SUCCESS = 'UPLOAD_EXTERNAL_RATE_PLAN_SUCCESS';
export const uploadExternalRateplan = (tempId, ratePlanFile) => ({
	type: UPLOAD_EXTERNAL_RATE_PLAN,
	payload: {
		tempId,
		ratePlanFile
	}
});

export const uploadExternalPlanSuccess = (tempId, data) => ({
	type: UPLOAD_EXTERNAL_RATE_PLAN_SUCCESS,
	payload: {
		tempId,
		info: data
	}
});

export const uploadExternalRatePlanError = (tempId, data) => ({
	type: UPLOAD_EXTERNAL_RATE_PLAN_ERROR,
	payload: {
		tempId,
		error: data
	}
});

export const CREATE_EXTERNAL_INVOICE = 'CREATE_EXTERNAL_INVOICE';
export const CREATE_EXTERNAL_INVOICE_SUCCESS = 'CREATE_EXTERNAL_INVOICE_SUCCESS';
export const CREATE_EXTERNAL_INVOICE_ERROR = 'CREATE_EXTERNAL_INVOICE_ERROR';

export const createExternalInvoice = data => ({
	type: CREATE_EXTERNAL_INVOICE,
	payload: data
});

export const createExternalInvoiceSuccess = (tempId, data) => ({
	type: CREATE_EXTERNAL_INVOICE_SUCCESS,
	payload: {
		tempId,
		info: data
	}
});

export const createExternalInvoiceError = (tempId, data) => ({
	type: CREATE_EXTERNAL_INVOICE_ERROR,
	payload: {
		tempId,
		error: data
	}
});

export default actions;
