import React, { Component } from 'react';
import { InfoTextContainer } from 'containers';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { MyAccountOptions, GeneralInformationForm, LenderSettingsForm, BankAccountsList, TableUsers, SecuritySettingsForm, RenderButton, TableAgreements } from 'components';
import { Grid, Typography, Paper, CardContent, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import PropTypes from 'prop-types';

class MyAccount extends Component {
	constructor(props) {
		super(props);
		const { lenderSettingsData } = this.props;
		const active = lenderSettingsData && lenderSettingsData.sftp && lenderSettingsData.sftp.active;
		this.state = {
			newPhonePrefixId: undefined,
			forceGeneration: active
		};
	}

	getPhonePrefix = e => {
		const { getStatesData } = this.props;
		if (e.target.selectedOptions !== undefined && Object.keys(e.target.selectedOptions).length > 0 && e.target.selectedOptions[0].id !== undefined) {
			this.setState({
				newPhonePrefixId: e.target.selectedOptions[0].id
			});
		}

		if (e.target.value !== undefined) {
			const country = {
				countryId: e.target.value
			};

			getStatesData(country);
		}
	};

	onSFTPConfigToggle = isSFTPActive => {
		this.setState({
			forceGeneration: isSFTPActive
		});
	};

	render() {
		const {
			getMyAccountSectionItemValue,
			selectedMenuItem,
			rol,
			lenderSettingsData,
			generalInformationSubmit,
			generalInformationData,
			newBankAccountStateForm,
			newBankAccountSubmit,
			updateBankAccountSubmit,
			deleteBankAccountSubmit,
			bankAccountData,
			setNewBankAccountStateForm,
			bankAccountFormName,
			hardwareData,
			countriesData,
			userInfo,
			statesData,
			businessTypeData,
			getCreateNewWalletPostDataRequest,
			profileComplete,
			getStatesData,
			getBankStatesData,
			getBeneficiaryStatesData,
			getCorrespondentStatesData,
			bankStatesDataArray,
			beneficiaryStatesDataArray,
			correspondentStatesDataArray,
			phonePrefixData,
			handleLogoUpload,
			handleSign,
			handleSignFinancing,
			handlePlatformAgreementDownload,
			handleFinancingAgreementDownload,
			accountData,
			changePasswordSubmit,
			postLenderSettings,
			clearLenderSettingsMessages,
			onClickInitiateDownload,
			handleNewUser,
			enableMfa
		} = this.props;

		const { newPhonePrefixId, forceGeneration } = this.state;

		const connections = {
			ingress: [],
			egress: []
		};

		hardwareData.map(row => {
			if (row.type === 'ingress') {
				connections.ingress.push(row);
			} else {
				connections.egress.push(row);
			}

			return connections;
		});

		return (
			<div>
				<MyAccountOptions callbackFromParent={getMyAccountSectionItemValue} selectedMenuItem={selectedMenuItem} currentRol={rol} profileComplete={profileComplete} />
				<br />
				{selectedMenuItem === 'gi' && (
					<div>
						<Grid container spacing={1}>
							<Grid item xs={12} md={8}>
								<Grid container>
									<Grid item xs={12}>
										<GeneralInformationForm
											onSubmit={generalInformationSubmit}
											initialValues={generalInformationData}
											countriesData={countriesData}
											newPhonePrefixId={newPhonePrefixId}
											getPhonePrefix={this.getPhonePrefix}
											userInfo={userInfo}
											statesData={statesData}
											businessTypeData={businessTypeData}
											getCreateNewWalletPostDataRequest={getCreateNewWalletPostDataRequest}
											getStatesData={getStatesData}
											phonePrefixData={phonePrefixData}
											handleLogoUpload={handleLogoUpload}
											accountData={accountData}
											handleSign={handleSign}
											handleSignFinancing={handleSignFinancing}
											handlePlatformAgreementDownload={handlePlatformAgreementDownload}
											handleFinancingAgreementDownload={handleFinancingAgreementDownload}
										/>
									</Grid>
									<Grid item xs={12}>
										<BankAccountsList
											bankAccountData={bankAccountData}
											updateBankAccountSubmit={updateBankAccountSubmit}
											deleteBankAccountSubmit={deleteBankAccountSubmit}
											newBankAccountSubmit={newBankAccountSubmit}
											bankAccountFormName={bankAccountFormName}
											newBankAccountStateForm={newBankAccountStateForm}
											setNewBankAccountStateForm={setNewBankAccountStateForm}
											countriesData={countriesData}
											bankStatesDataArray={bankStatesDataArray}
											beneficiaryStatesDataArray={beneficiaryStatesDataArray}
											correspondentStatesDataArray={correspondentStatesDataArray}
											getBankStatesData={getBankStatesData}
											getBeneficiaryStatesData={getBeneficiaryStatesData}
											getCorrespondentStatesData={getCorrespondentStatesData}
											generalInformationData={generalInformationData}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} md={4}>
								<InfoTextContainer page="My Account" section="General Information" />
							</Grid>
						</Grid>
					</div>
				)}
				{selectedMenuItem === 'ua' && (
					<Grid container spacing={1}>
						<Grid item xs={12} md={8}>
							<div>
								<TableUsers users={accountData.users} admins={accountData.admins} account={accountData} handleNewUser={handleNewUser} />
							</div>
						</Grid>
						<Grid item xs={12} md={4}>
							<InfoTextContainer page="My Account" section="User Administration" />
						</Grid>
					</Grid>
				)}
				{selectedMenuItem === 'sec' && (
					<Grid container spacing={1}>
						<Grid item xs={12} md={6}>
							<SecuritySettingsForm
								enableMfa={enableMfa}
								onSubmit={changePasswordSubmit}
								initialValues={{ email: userInfo.email ? userInfo.email : '' }}
								mfaResponse={accountData.mfaResponse}
								mfaStatus={accountData.mfa}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<InfoTextContainer page="My Account" section="Security" />
						</Grid>
					</Grid>
				)}
				{selectedMenuItem === 'contacts' && (
					<Grid container spacing={1} style={{ opacity: '.6' }}>
						<Grid item xs={12} md={6}>
							<Grid container>
								<Grid item xs={12} md={12}>
									<Paper>
										<CardContent>
											<Typography variant="h1" gutterBottom>
												Billing
											</Typography>
										</CardContent>
									</Paper>
								</Grid>
								<Grid item xs={12} md={12}>
									<Paper>
										<CardContent>
											<Typography variant="h1" gutterBottom>
												Technical
											</Typography>
										</CardContent>
									</Paper>
								</Grid>
								<Grid item xs={12} md={12}>
									<Paper>
										<CardContent>
											<Typography variant="h1" gutterBottom>
												Admin
											</Typography>
										</CardContent>
									</Paper>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} md={6}>
							<InfoTextContainer page="My Account" section="Contacts" />
						</Grid>
					</Grid>
				)}
				{selectedMenuItem === 'agreements' && (
					<Grid container spacing={1}>
						<Grid item xs={12} md={8}>
							{accountData.agreement !== undefined && accountData.agreement.status === 'ACCOUNT_NOT_COMPLETE' ? (
								<Paper>
									<CardContent>
										<Typography variant="h2">
											Account is not complete ! Please complete your account information before signing the agreements
										</Typography>
									</CardContent>
								</Paper>
							) : (
								<div>
									<Grid container>
										<Grid item xs={12} md={12}>
											<Paper>
												<CardContent>
													<Typography variant="h1" gutterBottom>
														Tesspay Platform Services Agreement
													</Typography>
													<List>
														<ListItem button component="a" href="#">
															<ListItemIcon>
																<DownloadIcon />
															</ListItemIcon>
															<ListItemText>
																Tesspay Platform Services Agreement &nbsp;
																{accountData.agreement !== undefined && accountData.agreement.status !== 'ACCOUNT_NOT_COMPLETE' && (
																	<span>
																		{accountData.agreement !== undefined && accountData.agreement.status === 'COMPLETED' && (
																			<RenderButton color="success" onClick={() => handlePlatformAgreementDownload(accountData)}>
																				Download Agreement
																			</RenderButton>
																		)}

																		{accountData.agreement !== undefined &&
																			(accountData.agreement.status === undefined ||
																				accountData.agreement.status === 'PENDING_USER_SIGNATURE') && (
																				<RenderButton color="danger" onClick={() => handleSign()}>
																					Review / Sign Agrement
																				</RenderButton>
																			)}
																	</span>
																)}
															</ListItemText>
														</ListItem>
													</List>
												</CardContent>
											</Paper>
										</Grid>
									</Grid>
									<Grid container>
										<Grid item xs={12} md={12}>
											<Paper>
												<CardContent>
													<Typography variant="h1" gutterBottom>
														Tesspay Platform Lending Agreements
													</Typography>
													<List>
														<ListItem button component="a" href="#">
															<ListItemIcon>
																<DownloadIcon />
															</ListItemIcon>
															<ListItemText>
																Tesspay Platform Lending Agreements &nbsp;
																{accountData.agreement !== undefined && accountData.agreement.status !== 'ACCOUNT_NOT_COMPLETE' && (
																	<span>
																		{accountData.financingAgreementUrl === undefined && (
																			<RenderButton color="danger" onClick={() => handleSignFinancing()}>
																				Review / Sign Agreement
																			</RenderButton>
																		)}
																		{accountData.financingAgreementUrl !== undefined && accountData.financingAgreementUrl.length > 0 && (
																			<RenderButton color="success" onClick={() => handleFinancingAgreementDownload(accountData)}>
																				Download Agreement
																			</RenderButton>
																		)}
																	</span>
																)}
															</ListItemText>
														</ListItem>
													</List>
												</CardContent>
											</Paper>
										</Grid>
									</Grid>
									<Grid container>
										<Grid item xs={12} md={12}>
											<Paper>
												<CardContent>
													<Typography variant="h1" gutterBottom>
														TessPay Smart Contract Carrier Services Agreement
													</Typography>

													<TableAgreements
														agreements={accountData.carrierAgreements}
														onClickInitiateDownload={onClickInitiateDownload}
														account={accountData}
													/>
												</CardContent>
											</Paper>
										</Grid>
									</Grid>
								</div>
							)}
						</Grid>
						<Grid item xs={12} md={4}>
							<InfoTextContainer page="My Account" section="Legal Agreements" />
						</Grid>
					</Grid>
				)}
				{selectedMenuItem === 'lenderSettings' && (
					<Grid container spacing={1}>
						<Grid item xs={12} md={8}>
							<Grid container>
								<Grid item xs={12}>
									<LenderSettingsForm
										onSubmit={postLenderSettings}
										clearLenderSettingsMessages={clearLenderSettingsMessages}
										lenderSettingsData={lenderSettingsData}
										initialValues={
											forceGeneration
												? {
														...lenderSettingsData,
														generation: {
															...lenderSettingsData.generation,
															active: true
														},
														accountData
												  }
												: {
														...lenderSettingsData,
														accountData
												  }
										}
										onSFTPConfigToggle={this.onSFTPConfigToggle}
										forceGeneration={forceGeneration}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} md={4}>
							<InfoTextContainer page="My Account" section="Lender Settings" />
						</Grid>
					</Grid>
				)}
			</div>
		);
	}
}

MyAccount.propTypes = {
	getMyAccountSectionItemValue: PropTypes.func.isRequired,
	selectedMenuItem: PropTypes.string.isRequired,
	rol: PropTypes.arrayOf(PropTypes.string).isRequired,
	generalInformationSubmit: PropTypes.func.isRequired,
	newBankAccountStateForm: PropTypes.bool,
	newBankAccountSubmit: PropTypes.func.isRequired,
	lenderSettingsData: PropTypes.shape()
};

MyAccount.defaultProps = {
	newBankAccountStateForm: false,
	lenderSettingsData: {}
};

export default MyAccount;
