import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

export default () => {
	const [page, setPage] = useState(0);
	const [perPage, setPerPage] = useState(20);
	const [filters, setFilters] = useState('');

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch({
			type: 'transactions/GET_WALLET_TRANSACTIONS',
			payload: {
				page: page + 1,
				perPage,
				filters: JSON.stringify(filters || {})
			}
		});
	}, [page, perPage, filters]);

	return { page, setPage, perPage, setPerPage, filters, setFilters };
};
