import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { RenderButton, RenderConfirmDialog } from 'components';
import { withTranslation } from 'react-i18next';

// eslint-disable-next-line react/prefer-stateless-function
class RatesTableCustomToolbarSelect extends React.Component {
	state = {
		isVisible: {
			confirmAcceptAll: false,
			confirmDeclineAll: false,
			confirmAccpetSelected: false,
			confirmDeclineSelected: false
		}
	};

	toggleConfirmationDialog(dialogName, visibile) {
		const { isVisible } = this.state;
		this.setState({
			isVisible: {
				...isVisible,
				[dialogName]: visibile
			}
		});
	}

	showConfirmationDialog(dialogName) {
		this.toggleConfirmationDialog(dialogName, true);
	}

	hideConfirmationDialog(dialogName) {
		this.toggleConfirmationDialog(dialogName, false);
	}

	render() {
		const { handleAction, count, total, t } = this.props;
		const { isVisible } = this.state;
		return (
			<React.Fragment>
				<RenderConfirmDialog
					title={t('rates.accept_proposed.title', { count: total })}
					contentText={t('rates.accept_proposed.text', { count: total })}
					open={!!isVisible && !!isVisible.confirmAcceptAll}
					openCloseHandler={() => this.hideConfirmationDialog('confirmAcceptAll')}
					handleConfirm={() => {
						handleAction('acceptAll');
					}}
				/>
				<RenderConfirmDialog
					title={t('rates.decline_proposed.title', { count: total })}
					contentText={t('rates.decline_proposed.text', { count: total })}
					open={!!isVisible && !!isVisible.confirmDeclineAll}
					openCloseHandler={() => this.hideConfirmationDialog('confirmDeclineAll')}
					handleConfirm={() => {
						handleAction('declineAll');
					}}
				/>
				<RenderConfirmDialog
					title={t('rates.accept_selected.title', { count })}
					contentText={t('rates.accept_selected.text', { count })}
					open={!!isVisible && !!isVisible.confirmAcceptSelected}
					openCloseHandler={() => this.hideConfirmationDialog('confirmAcceptSelected')}
					handleConfirm={() => {
						handleAction('acceptSelected');
					}}
				/>
				<RenderConfirmDialog
					title={t('rates.decline_selected.title', { count })}
					contentText={t('rates.decline_selected.text', { count })}
					open={!!isVisible && !!isVisible.confirmDeclineSelected}
					openCloseHandler={() => this.hideConfirmationDialog('confirmDeclineSelected')}
					handleConfirm={() => {
						handleAction('declineSelected');
					}}
				/>
				<Typography variant="caption" align="left">
					{t('rates.x_rows_selected', { count })}
				</Typography>
				<div style={{ textAlign: 'right' }}>
					<RenderButton color="secondary" onClick={() => this.showConfirmationDialog('confirmAcceptAll')}>
						<Tooltip title={`${t('rates.btn_accept_all')} ${total}`}>
							<span>{t('rates.btn_accept_all')}</span>
						</Tooltip>
					</RenderButton>
					<RenderButton color="secondary" onClick={() => this.showConfirmationDialog('confirmAcceptSelected')}>
						{t('rates.btn_accept_selected')}
					</RenderButton>
					<RenderButton color="secondary" onClick={() => this.showConfirmationDialog('confirmDeclineAll')}>
						<Tooltip title={`${t('rates.btn_decline_all')} ${total}`}>
							<span>{t('rates.btn_decline_all')}</span>
						</Tooltip>
					</RenderButton>
					<RenderButton color="secondary" onClick={() => this.showConfirmationDialog('confirmDeclineSelected')}>
						{t('rates.btn_decline_selected')}
					</RenderButton>
				</div>
			</React.Fragment>
		);
	}
}

RatesTableCustomToolbarSelect.propTypes = {
	handleAction: PropTypes.func.isRequired,
	count: PropTypes.number.isRequired,
	total: PropTypes.number.isRequired
};

const RatesTableCustomToolbarSelectTranslated = withTranslation(['smartcontracts/edit', 'translations'])(RatesTableCustomToolbarSelect);

export default RatesTableCustomToolbarSelectTranslated;
