import React, { memo } from "react";
import { CardContent, Grid, Paper, Typography } from "@material-ui/core";
import moment from "moment";
import { Field } from "redux-form";
import { RenderButton, RenderCheckbox, RenderInput } from "components";
import style from "./style.module.scss";

const RoutesSmartContractInfo = memo(({ data }) => {
  console.log("data", data);
  return (
    <div className={style.container}>
      <Paper>
        {(data && (
          <CardContent>
            <h3>Smart Contract Details</h3>
            <Grid container spacing={8} className="top-row">
              <Grid item xs={2}>
                <h4>Smart Contract ID</h4>
                {data._id || ""}
              </Grid>
              <Grid item xs={2}>
                <h4>Contract Start Date</h4>
                {data.startDate && moment(data.startDate).isValid() && moment(data.startDate).format("MMM DD YYYY") || ""}
              </Grid>
              <Grid item xs={2}>
                <h4>Contract End Date</h4>
                {data.endDate && moment(data.endDate).isValid() && moment(data.endDate).format("MMM DD YYYY") || "Open"}
              </Grid>
              <Grid item xs={2} className="disabled">
                <h4>Last Rate Update</h4>
                Sep 10 2018
              </Grid>
              <Grid item xs={2} className="disabled">
                <h4>Active Rates</h4>
                1253 of 1546
              </Grid>
              <Grid item xs={2} className="disabled">
                <h4>Last Traffic Date</h4>
                Nov 19 2018
              </Grid>
            </Grid>
            <Grid container spacing={8}>
              <Grid item xs={2}>
                <h4>Contract Type</h4>
                {data && data.isOriginContract ? "Origination" : "Standard"}
              </Grid>
              <Grid item xs={2}>
                <h4>Traffic Type</h4>
                {data.trafficType || ""}
              </Grid>
              <Grid item xs={2}>
                <h4>Billing Cycle</h4>
                {data.billingCycle || ""}
              </Grid>
              <Grid item xs={2}>
                <h4>Payment Terms</h4>
                {data.paymentTerms && `${data.paymentTerms} Days` || ""}
              </Grid>
              <Grid item xs={2} className="disabled">
                <h4>Credit Status</h4>
                Open
              </Grid>
            </Grid>
          </CardContent>
        )) ||
        ""}
        {(!data && <CardContent>Loading</CardContent>) || ""}
      </Paper>
    </div>
  );
});

export default RoutesSmartContractInfo;
