import React, { useEffect, useState } from 'react';
import { CardContent, Grid, Paper, Typography } from '@material-ui/core';
import { Field } from 'redux-form';
import { RenderSelect, RenderInput, RenderDatePicker } from 'components';
import { useTranslation } from 'react-i18next';

const SmartContractNewTerms = props => {
	const { t } = useTranslation(['smartcontracts/create', 'translations']);

	const { suppliers, customers, participant, invoicePeriods, defaultSettlementPeriod } = props;

	const [contractType, setContractType] = useState('sell');
	const [billingCycle, setBillingCycle] = useState(null);

	const handleContractTypeChange = event => {
		setContractType(event.value);
	};

	const handleBillingCycleChange = event => {
		setBillingCycle(event.value);
	};

	const billingCycleSorterFn = (a, b) => {
		const order = ['5', '1', '2', '3', '6'];

		const x = order.indexOf(a._id);
		const y = order.indexOf(b._id);

		return x < y ? -1 : x > y ? 1 : 0;
	};

	useEffect(() => {
		if (props.initialValues) {
			const { invoicePeriodId } = props.initialValues;
			setBillingCycle(invoicePeriodId);
		}
	}, [props]);

	invoicePeriods.sort(billingCycleSorterFn);

	return (
		<Grid container>
			<Grid item xs={12}>
				<Field component="input" type="hidden" name="tempId" />
				<Paper>
					<CardContent>
						<Grid container>
							<Grid item xs={4}>
								<Field
									name="contractType"
									component={RenderSelect}
									callback={handleContractTypeChange}
									inline
									label={t('contract_type')}
									options={[
										{
											value: 'sell',
											label: t('contract_type.sell')
										},
										{
											value: 'buy',
											label: t('contract_type.buy')
										}
									]}
								/>
							</Grid>
							<Grid item xs={12}>
								<br />
								<Grid container>
									<Grid item xs={8}>
										<Typography variant="h2" gutterBottom>
											{t('technical_terms')}
										</Typography>
										<Grid container>
											<Grid item xs={6}>
												<Grid container>
													<Grid item xs={12}>
														{contractType === 'sell' ? (
															<Field
																disabled
																name="supplierName"
																component={RenderInput}
																placeholder={participant.participantName}
																label={t('supplier')}
																InputLabelProps={{ shrink: true }}
															/>
														) : (
															<Field
																name="supplierId"
																label={t('translations:select_field', { field: t('supplier') })}
																component={RenderSelect}
																options={suppliers}
															/>
														)}
													</Grid>
													<Grid item xs={12}>
														<Field
															name="trafficType"
															label={t('traffic_type')}
															component={RenderSelect}
															options={[
																{
																	value: 'TDM',
																	label: 'TDM'
																},
																{
																	value: 'CLI',
																	label: 'CLI'
																},
																{
																	value: 'NON-CLI',
																	label: 'NON-CLI'
																}
															]}
														/>
													</Grid>
													<Grid item xs={12}>
														<Field
															name="isOriginContract"
															label={t('contract_type2')}
															component={RenderSelect}
															options={[
																{
																	value: true,
																	label: t('contract_type2.origination')
																},
																{
																	value: false,
																	label: t('contract_type2.standard')
																}
															]}
														/>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={6}>
												<Grid container>
													<Grid item xs={12}>
														{contractType === 'sell' ? (
															<Field
																name="customerId"
																label={t('translations:select_field', { field: t('customer') })}
																component={RenderSelect}
																options={customers}
															/>
														) : (
															<Field
																id="customerName"
																disabled
																name="customerName"
																component={RenderInput}
																placeholder={participant.participantName}
																// placeholder={"Customer Name Here"}
																label={t('customer')}
																InputLabelProps={{ shrink: true }}
																margin="none"
															/>
														)}
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={4}>
										<Typography variant="h2" gutterBottom>
											{t('commercial_terms')}
										</Typography>
										<Grid container>
											<Grid item xs={12}>
												<Field component={RenderInput} type="number" step="1" min="1" name="minimumNoticeDays" label={t('min_notice_period')} />
											</Grid>
											<Grid item xs={12}>
												<Field
													name="invoicePeriodId"
													id="invoicePeriodId"
													label={t('billing_cycle')}
													component={RenderSelect}
													callback={handleBillingCycleChange}
													options={invoicePeriods.map(option => ({
														value: option._id,
														label: option.invoicePeriodType
													}))}
												/>
											</Grid>
											{parseInt(billingCycle) !== 5 ? (
												<Grid item xs={12}>
													<Field
														name="paymentTerms"
														id="paymentTerms"
														label={t('payment_terms')}
														component={RenderSelect}
														options={[
															{
																value: 1,
																label: t('translations:x_day', { num: 1 })
															},
															{
																value: 3,
																label: t('translations:x_days', { num: 3 })
															},
															{
																value: 5,
																label: t('translations:x_days', { num: 5 })
															},
															{
																value: 7,
																label: t('translations:x_days', { num: 7 })
															},
															{
																value: 14,
																label: t('translations:x_days', { num: 14 })
															},
															{
																value: 15,
																label: t('translations:x_days', { num: 15 })
															},
															{
																value: 30,
																label: t('translations:x_days', { num: 30 })
															},
															{
																value: 60,
																label: t('translations:x_days', { num: 60 })
															},
															{
																value: 90,
																label: t('translations:x_days', { num: 90 })
															},
															{
																value: 120,
																label: t('translations:x_days', { num: 120 })
															}
														]}
													/>
												</Grid>
											) : (
												''
											)}
											<Grid item xs={12}>
												<Field
													component={RenderDatePicker}
													name="endDate"
													label={t('contract_end_date')}
													dateFormat="MMM DD YYYY"
													clearable
													disablePast
													emptyLabel="Open"
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<strong>{t('settlement_period')}: </strong>
												{defaultSettlementPeriod.settlementPeriod}
												<Field name="settlementPeriodId" component="input" type="hidden" />
											</Grid>
											<Grid item xs={12} md={6}>
												<strong>{t('billing_timezone')}: </strong> GMT
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</CardContent>
				</Paper>
			</Grid>
		</Grid>
	);
};

SmartContractNewTerms.defaultProps = {
	participant: {
		participantName: ''
	},
	defaultSettlementPeriod: {
		settlementPeriod: '',
		_id: ''
	},
	invoicePeriods: [],
	loading: false
};

export default SmartContractNewTerms;
