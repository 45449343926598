import { call, put } from 'redux-saga/effects';
import FinancialApi from '../../services/api/FinancialApi';
import { setFinancial, setSettlements, setLenderInvoices } from './actions';

export function* setFinancials(action) {
	try {
		const dataResult = yield call(FinancialApi.initializeFinancial);
		yield put(setFinancial(dataResult));
	} catch (err) {
		const error = err.message || err;
		console.error(error);
	}
}

export function* setSettlementsData() {
	try {
		const dataResult = yield call(FinancialApi.loadSettlements);
		yield put(setSettlements(dataResult));
	} catch (err) {
		const error = err.message || err;
		console.error(error);
	}
}

export function* setLenderInvoicesApproval() {
	try {
		const dataResult = yield call(FinancialApi.getLenderInvoicesApproval);
		yield put(setLenderInvoices(dataResult && dataResult.length ? dataResult : []));
	} catch (err) {
		const error = err.message || err;
		console.error(error);
	}
}

export function* setLenderInvoicesApprovalAgree(action) {
	try {
		yield call(FinancialApi.setLenderInvoicesApproval, action.payload);
		const dataResult = yield call(FinancialApi.getLenderInvoicesApproval);
		yield put(setLenderInvoices(dataResult && dataResult.length ? dataResult : []));
	} catch (err) {
		const error = err.message || err;
		console.error(error);
	}
}
