import React from "react";
import { PrivateTemplate, BreadCrumb } from "components";
import SmartContractNewScreen from "screens/SmartContracts/create";

const SmartContractNewPage2 = () => (
  <PrivateTemplate>
    <BreadCrumb
      name="New Smart Contract"
      firstLevel="Home"
      secondLevel="New smart contract"
    />
    <SmartContractNewScreen />
  </PrivateTemplate>
);
export default SmartContractNewPage2;
