import React from 'react';
import MUIDataTable from 'mui-datatables';
import { EditUserForm, AddUserForm, RenderButton } from 'components';

const TableAgreements = props => {
	const { onClickInitiateDownload } = props;

	const { loggedInUser } = props.account;

	const downloadButton = row => (
		<RenderButton onClick={onClickInitiateDownload.bind(this, row)} color="success" small>
			View Agreement
		</RenderButton>
	);

	const data = {
		result: {
			items: props.agreements
		}
	};
	const tableColumns = [
		{
			name: 'Name',
			options: {
				filter: true,
				sort: true
			}
		},
		{
			name: 'Signature Date',
			options: {
				filter: true,
				sort: true
			}
		},
		{
			name: 'Agreement',
			options: {
				filter: true,
				sort: true
			}
		},
		// {
		//   name: "Updated at",
		//   options: {
		//     filter: true,
		//     sort: true,
		//     customBodyRender: (value, tableMeta, updateValue) =>
		//       moment(value).format("MMM DD YYYY")
		//   }
		// },
		{
			name: '',
			options: {
				filter: false,
				sort: false,
				customBodyRender: () => {
					if (loggedInUser.isAdmin === true) {
						return <EditUserForm />;
					}
				}
			}
		}
	];

	const tableData = [];

	if (data.result)
		data.result.items.map(row => {
			tableData.push([row.participantName, row.signedAt, downloadButton(row)]);
			return tableData;
		});

	const tableOptions = {
		selectableRows: false,
		filter: false,
		print: false,
		download: false,
		viewColumns: false,
		responsive: 'scroll',
		rowsPerPage: 8
	};

	return (
		tableData && (
			<div>
				<MUIDataTable title=" " data={tableData} columns={tableColumns} options={tableOptions} />
			</div>
		)
	);
};

export default TableAgreements;
