import React, { Component } from 'react';
import { WalletTessPay } from 'components';
import { connect } from 'react-redux';
import {
	getInitialWallet,
	getWalletTransactions,
	getVoipApiData,
	sendTopUp,
	sendWithDraw,
	actionCreatorPreloaderOn,
	actionCreatorPreloaderOff,
	setResultWallet,
	redirectAccount,
	walletDataSuccess,
	triggerDownloadWalletReport
} from 'store/actions';
import { IntlProvider } from 'react-intl';
import { fromUseraccount, fromWallet, fromMyaccount } from 'store/selectors';
import { reset } from 'redux-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

class WalletTessPayContainer extends Component {
	constructor(props) {
		super(props);
		this.sendTopUp = this.sendTopUp.bind(this);
		this.withDrawSubmit = this.withDrawSubmit.bind(this);
		this.handleChangeTopUp = this.handleChangeTopUp.bind(this);
		this.walletTransactions = this.walletTransactions.bind(this);
		this.paymentRef = this.paymentRef.bind(this);
		this.dataBank = this.dataBank.bind(this);
		this.printButton = this.printButton.bind(this);
		this.fnToaStr = this.fnToaStr.bind(this);
		this.redirectAccount = this.redirectAccount.bind(this);
		this.handleChangeWithDraw = this.handleChangeWithDraw.bind(this);
		this.getLockedOnSmartContracts = this.getLockedOnSmartContracts.bind(this);
		this.triggerWalletReportSubmit = this.triggerWalletReportSubmit.bind(this);
		this.toast = toast;
		this.state = {
			amountTopUp: '',
			amountWithDraw: ''
		};
	}

	componentWillMount() {
		const { loadInitialWallet, setWalletData, userInfo, participantData, loadVoipApiData } = this.props;
		setWalletData({});
		loadInitialWallet(userInfo.userId);

		// if (participantData.carrierId) {
		// 	loadVoipApiData(
		// 		participantData.carrierId,
		// 		moment()
		// 			.startOf('day')
		// 			.format('YYYY-MM-DD 01:00:00')
		// 	);
		// }
	}

	componentWillReceiveProps(nextProps) {
		const { resultWallet, resetForm, setResultWallet } = this.props;
		if (resultWallet !== nextProps.resultWallet) {
			if (nextProps.resultWallet) {
				resetForm('withdrawForm');
				this.fnToaStr(nextProps.resultWallet);
				setResultWallet(undefined);
				this.setState({ amountTopUp: '', amountWithDraw: '' });
			}
		}
	}

	getLockedOnSmartContracts() {
		const { walletInfo } = this.props;
		return walletInfo !== undefined && walletInfo.lockedOnSmartContracts !== undefined ? walletInfo.lockedOnSmartContracts : '';
	}

	walletTransactions() {
		const { walletInfo } = this.props;
		const data = walletInfo === undefined || walletInfo.wallet === undefined ? [] : walletInfo.wallet;
		return data;
	}

	sendTopUp(amounttopup, datosWallet) {
		const { amountTopUp } = this.state;
		const { updateTopUp } = this.props;
		updateTopUp(amountTopUp, this.props.walletInfo.paymentRef);
	}

	withDrawSubmit(data) {
		const { updateWithDraw, resetForm } = this.props;
		updateWithDraw(data);
		resetForm('withdrawForm');
	}

	handleChangeTopUp(event) {
		const reg = /^[0-9]+[.]?([0-9]+)?$/;

		if (event.target.value === '' || reg.test(event.target.value)) {
			this.setState({ amountTopUp: event.target.value });
		}
	}

	handleChangeWithDraw(event) {
		const { totalAmount } = this.props;
		// const reg = /^[0-9]+[.]?([0-9]+)?$/;

		const roundedAmount = parseFloat((Math.round(totalAmount.toFixed(2) * 100) / 100).toFixed(2));

		if (event.target.value !== '') {
			const value = Number.parseFloat(event.target.value);
			if (roundedAmount >= value || isNaN(value)) {
				this.setState({ amountWithDraw: event.target.value });
			}
		}
	}

	fnToaStr(data) {
		if (data.status === 'success') {
			this.toast.success(data.message, {
				position: 'top-right',
				autoClose: 2000
			});
		} else {
			this.toast.error(data.message, {
				position: 'top-right',
				autoClose: 2000
			});
		}
	}

	paymentRef() {
		const { walletInfo } = this.props;
		const data = walletInfo === undefined || walletInfo.paymentRef === undefined ? '' : walletInfo.paymentRef;
		return data;
	}

	dataBank() {
		const { walletInfo } = this.props;
		if (walletInfo != null && walletInfo.bankAccount !== undefined && walletInfo.bankAccount.length > 0) {
			return walletInfo.bankAccount;
		}
		return null;
	}

	printButton() {
		const { walletInfo } = this.props;

		let buttonToShow = true;
		if (walletInfo != null) {
			if (walletInfo.bankAccount != null && walletInfo.bankAccount !== undefined && walletInfo.bankAccount.length > 0) {
				const bankAccount = walletInfo.bankAccount[0];
				if (bankAccount.bankName === undefined || bankAccount.bankName === '') {
					buttonToShow = true;
				} else {
					buttonToShow = false;
				}
			}
		} else {
			buttonToShow = true;
		}
		return buttonToShow;
	}

	redirectAccount() {
		const { redirectAccount } = this.props;
		redirectAccount('ai');
	}

	triggerWalletReportSubmit(data) {
		this.props.triggerDownloadWalletReport(data);
	}

	render() {
		const { i18n } = this.props;

		return (
			<div>
				<ToastContainer />
				<IntlProvider locale={i18n.language}>
					<WalletTessPay
						{...this.props}
						sendTopUp={this.sendTopUp}
						handleChangeTopUp={this.handleChangeTopUp}
						withDrawSubmit={this.withDrawSubmit}
						handleChangeWithDraw={this.handleChangeWithDraw}
						paymentRef={this.paymentRef()}
						dataBank={this.dataBank()}
						printButton={this.printButton()}
						redirectAccount={this.redirectAccount}
						walletTransactions={this.walletTransactions()}
						lockedOnSmartContracts={this.getLockedOnSmartContracts()}
						triggerWalletReportSubmit={this.triggerWalletReportSubmit}
						setPage={this.setPage}
						setPerPage={this.setPerPage}
					/>
				</IntlProvider>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	userInfo: fromUseraccount.getUserInfoDataState(state),
	participantData: fromMyaccount.getParticipantData(state),
	voipData: fromWallet.getVoipApiData(state),
	walletLoading: fromWallet.getWalletLoading(state),
	walletInfo: fromWallet.getWalletData(state),
	resultWallet: fromWallet.getResultWallet(state)
	// transactions: fromWallet.getWalletTransactions(state)
});

const mapDispatchToProps = dispatch => ({
	loadInitialWallet(value) {
		dispatch(getInitialWallet(value));
	},
	setWalletData(value) {
		dispatch(walletDataSuccess(value));
	},
	loadVoipApiData(carrierId, startDate) {
		dispatch(getVoipApiData(carrierId, startDate));
	},
	updateTopUp(amounttopup, paymentRef) {
		dispatch(sendTopUp(amounttopup, paymentRef));
	},
	updateWithDraw(dataWithDraw) {
		dispatch(sendWithDraw(dataWithDraw));
	},
	setResultWallet(data) {
		dispatch(setResultWallet(data));
	},
	resetForm(formName) {
		dispatch(reset(formName));
	},
	redirectAccount(data) {
		dispatch(redirectAccount(data));
	},
	triggerDownloadWalletReport(data) {
		dispatch(triggerDownloadWalletReport(data));
	},
	actionCreatorPreloaderOn,
	actionCreatorPreloaderOff
});

const WalletTessPayContainerTranslated = withTranslation(['financials/general', 'translations'])(WalletTessPayContainer);

export default connect(mapStateToProps, mapDispatchToProps)(WalletTessPayContainerTranslated);
