import { Typography } from '@material-ui/core';
import RenderButton from 'components/atoms/RenderButton';
import React from 'react';
import moment from 'moment';
import { capitalizeWord, colorStatusText, handleCurrency } from 'utils';

export const columns = ({ handleToggleModal, setOpenedSmartContract, setOpenedOrder, order }) => [
	{
		Header: 'Smart Contract',
		width: 100,
		Cell: ({ original: { smartContractRef } }) => <p>{smartContractRef}</p>
	},
	{
		Header: 'Partner',
		Cell: ({ original: { partner, status } }) => (status === 'self-fulfilled' ? <p>Self-fulfilled</p> : <p>{partner?.participantName}</p>)
	},
	{
		Header: () => <div className="centered-cell">PO Date</div>,
		width: 100,
		Cell: ({ original: { dueDate } }) => <p className="centered-cell">{moment(dueDate).format('MMM Do YY')}</p>
	},
	{
		Header: () => <div className="centered-cell">Cost</div>,
		width: 90,
		Cell: ({ original: { cost, status } }) => <div className="centered-cell">{status === 'self-fulfilled' ? '-' : handleCurrency(cost, 'USD')}</div>
	},
	{
		Header: () => <div className="centered-cell">Quantity</div>,
		width: 80,
		Cell: ({ original: { scQty } }) => <div className="centered-cell">{scQty}</div>
	},
	{
		Header: () => <div className="centered-cell">Escrow</div>,
		width: 110,
		Cell: ({ original: { scQty, scPofTotalFulfilled, scTotalSelfFullfilled } }) => (
			<div className="centered-cell">{handleCurrency((order.itemPrice * (scQty - scPofTotalFulfilled) * order.finance.coverage) / 100, 'USD')}</div>
		)
	},
	{
		Header: () => <div className="centered-cell">Status</div>,
		width: 100,
		Cell: ({ original: { status } }) => (
			<div className="centered-cell" style={colorStatusText(status)}>
				{capitalizeWord(status).replace(/-/g, ' ')}
			</div>
		)
	},

	{
		Header: () => <div className="centered-cell">Fulfillment</div>,
		width: 200,
		Cell: ({ original: { scPofTotalFulfilled, scTotalSelfFullfilled, scQty, status } }) => {
			const scTotalFulfilled = scPofTotalFulfilled + scTotalSelfFullfilled;

			const fulfillmentPercentage = Math.round((scTotalFulfilled / scQty) * 100);
			const remaining = scQty - scTotalFulfilled;

			const isSelfFulfilled = status === 'self-fulfilled';

			return (
				<div className="fulfillment-card-container">
					<div className="fulfillment-card">
						{/* hardcoding 100% for self-fulfillment sc */}
						<Typography className="large-text percentage-text">{`${isSelfFulfilled ? 100 : fulfillmentPercentage}%`}</Typography>{' '}
						<span className="divider horizontal-space" />
						<div className="qty">
							<Typography className="delivered" variant="caption">
								{isSelfFulfilled ? scQty : scTotalFulfilled}
							</Typography>
							{remaining !== 0 && !isSelfFulfilled && (
								<Typography className="remaining" variant="caption">
									{remaining}
								</Typography>
							)}
						</div>
					</div>
					<div className="qty">
						<Typography className="delivered" variant="caption">
							{'Delivered'}
						</Typography>
						{remaining !== 0 && !isSelfFulfilled && (
							<Typography className="remaining" variant="caption">
								{'Remaining'}
							</Typography>
						)}
					</div>
				</div>
			);
		}
	},
	{
		Header: 'Actions',
		width: 100,
		Cell: ({ original }) => {
			const { status } = original;
			return (
				status !== 'self-fulfilled' && (
					<div>
						<RenderButton
							color="primary"
							onClick={() => {
								handleToggleModal();
								setOpenedSmartContract(original);
								setOpenedOrder(order);
							}}
						>
							View
						</RenderButton>
					</div>
				)
			);
		}
	}
];
