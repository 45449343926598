import React from "react";
import { RenderBadge, RenderTable, CurrencyNumber } from "components";
import { Paper, Typography, CardContent, Grid } from "@material-ui/core";
import moment from "moment";

class ContractWalletDetails extends React.Component {
  transactionAmount(amount) {
    if (parseFloat(amount) < 0) {
      return (
        <RenderBadge color="danger">
          (<CurrencyNumber amount={amount.toFixed(2)} />)
        </RenderBadge>
      );
    }

    return (
      <RenderBadge color="success">
        (<CurrencyNumber amount={amount.toFixed(2)} />)
      </RenderBadge>
    );
  }

  render() {
    const { data = [] } = this.props;

    const transactionsHeader = [
      {
        Header: "Date",
        accessor: "date",
        Cell: props => moment(props.value).format("MMM DD YYYY"),
        sort: (a, b) => (a && b ? (moment(a).isSameOrAfter(b) ? 1 : -1) : 0)
      },
      {
        Header: "Details",
        accessor: "details"
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: props => this.transactionAmount(props.value),
        style: {
          textAlign: "right"
        },
        headerStyle: {
          textAlign: "right"
        }
      }
    ];

    let walletFunds = 0;
    if (data) {
      data.map(obj => {
        walletFunds += obj.amount;
      });
    }

    return (
      <Paper>
        <CardContent>
          {data ? (
            <div>
              <Grid container>
                <Grid
                  item
                  md={12}
                  style={{
                    padding: "30px 0"
                  }}
                >
                  <strong>Balance:</strong>
                  <CurrencyNumber amount={walletFunds.toFixed(2)} />
                </Grid>
              </Grid>
              <RenderTable
                data={data}
                filterable={false}
                columns={transactionsHeader}
                defaultSorted={[
                  {
                    id: "date",
                    desc: true
                  }
                ]}
              />
            </div>
          ) : (
            <Typography style={{ textAlign: "center", marginTop: 40 }}>
              Loading Wallet data...
            </Typography>
          )}
        </CardContent>
      </Paper>
    );
  }
}

export default ContractWalletDetails;
