import { takeEvery, all } from 'redux-saga/effects';
import * as InfoTextSagas from './infotext/sagas';
import * as biddingPortal from './biddingportal/sagas';
import * as changePasswordSagas from './changepassword/sagas';
import * as explorerSagas from './explorer/sagas';
import * as financial from './financial/sagas';
import * as financialRequestsSagas from './financingrequests/sagas';
import * as interconnectionsSagas from './interconnections/sagas';
import * as loginSagas from './loginaccount/sagas';
import * as myAccountSagas from './myaccount/sagas';
import * as notificationsSagas from './notifications/sagas';
import * as platformSettingsSagas from './settings/sagas';
import * as recoveryPasswordSagas from './recoverypassword/sagas';
import * as registerSagas from './registeraccount/sagas';
import * as reportSagas from './reports/sagas';
import * as routeSagas from './routes/sagas';
import * as smartcontractsSagas from './smartcontracts/sagas';
import * as urlRedirectSagas from './urlredirect/sagas';
import * as verificationCodeSagas from './verificationcode/sagas';
import * as walletSagas from './wallet/sagas';

import account from './myaccount/sagas';
import useraccount from './useraccount/sagas';
import routes from './routes/sagas';
import reports from './reports/sagas';
import smartContracts from './smartcontracts/sagas';
import wallet from './wallet/sagas';
import contracts from './contracts/sagas';
import acp from './acp/sagas';
import support from './support/sagas';
import passwordchallenge from './passwordchallenge/sagas';
import escrowmanaging from './escrowmanaging/sagas';

import {
	ACCEPT_INTERCONNECTION,
	ACCEPT_ORDER,
	ACTION_HANDLE_NEW_USER,
	BANK_ACCOUNT_ACTION_POST_DATA_REQUEST,
	BANK_ACCOUNT_ACTION_REMOVE_POST_DATA_REQUEST,
	BIDDING_SMART_CONTRACT,
	CANCEL_ORDER,
	CATALOGS_SMART_CONTRACT,
	CHANGE_PASSWORD_ACTION_POST_DATA_REQUEST,
	CHANGE_PASSWORD_REQUEST,
	CREATE_EXTERNAL_INVOICE,
	CREATE_NEW_WALLET_ACTION_POST_DATA_REQUEST,
	CREATE_ORDER,
	CREATE_SMART_CONTRACT,
	CUSTOMER_PAY_INVOICE,
	DOWNLOAD_INVOICE,
	DOWNLOAD_SAMPLE_RATE_PLAN,
	DOWNLOAD_SMART_CONTRACT_RATE,
	DOWNLOAD_WALLET_REPORT,
	EXPLORER_INIT_BLOCKS_POLLING,
	EXPLORER_SMART_CONTRACTS_ACTION_GET_DATA_REQUEST,
	EXPLORER_WALLET_ACTION_GET_DATA_REQUEST,
	EXTERNAL_CLIENT_DETAILS_ACTION_POST_DATA_REQUEST,
	EXTERNAL_INVOICE,
	EXTERNAL_RATES,
	FINANCIAL,
	FINANCING_REQUESTS_GET_SMARTCONTRACTS,
	FINANCING_REQUESTS_SEND_LENDER_OFFER,
	FINANCING_REQUESTS_UPDATE_OFFER,
	FUNDERS_SMART_CONTRACT,
	GET_BANKSTATES_ACTION_POST_DATA_REQUEST,
	GET_BENEFICIARYSTATES_ACTION_POST_DATA_REQUEST,
	GET_BUSINESS_TYPE_ACTION_POST_DATA_REQUEST,
	GET_CODECS_ACTION_POST_DATA_REQUEST,
	GET_CORRESPONDENTSTATES_ACTION_POST_DATA_REQUEST,
	GET_COUNTRIES_ACTION_POST_DATA_REQUEST,
	GET_EXTERNAL_CLIENT_DATA,
	GET_HARDWARE_PROFILE_OPTIONS_ACTION_POST_DATA_REQUEST,
	GET_NOTIFICATIONS,
	GET_PHONEPREFIX_ACTION_POST_DATA_REQUEST,
	GET_PLATFORM_SETTINGS,
	GET_STATES_ACTION_POST_DATA_REQUEST,
	INFO_TEXT_REQUEST,
	INITIALIZE_BIDDING_PORTAL,
	INITIALIZE_INTERCONNECTIONS,
	INITIALIZE_SMARTCONTRACT_COUNTERSIGN,
	INITIALIZE_SMARTCONTRACT_DOWNLOAD,
	INITIALIZE_SMARTCONTRACT_SIGN,
	INITIAL_NEW_SMART_CONTRACT,
	INITIAL_SMART_CONTRACTS,
	LENDER_INVOICES_APPROVAL_AGREE,
	LENDER_SETTINGS_GET_DATA_REQUEST,
	LENDER_SETTINGS_POST_DATA_REQUEST,
	LOAD_DESTINATIONS_CARRIER,
	LOAD_DESTINATIONS_TYPE_CARRIER,
	LOAD_DIAL_CODES_TYPE_CARRIER_COUNTRY,
	LOAD_EXTERNAL_INVOICES,
	LOAD_INITIAL_WALLET,
	LOAD_INITIAL_WELCOME_WALLET,
	LOAD_INVOICES,
	LOAD_IP_ADDRESS_SMART_CONTRACTS,
	LOAD_LENDER_INVOICES_APPROVAL,
	LOAD_MONTHLY_INVOICES,
	LOAD_SMART_CONTRACT,
	LOAD_SMART_CONTRACTS,
	LOAD_VOIP_API_DATA,
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	MFA_REQUEST,
	MYACCOUNT_ACTION_DOWNLOAD_FINANCING_AGREEMENT,
	MYACCOUNT_ACTION_DOWNLOAD_PORTAL_AGREEMENT,
	MYACCOUNT_ACTION_GET_DATA_REQUEST,
	MYACCOUNT_ACTION_POST_DATA_REQUEST,
	MYACCOUNT_ACTION_SIGN_FINANCING_AGREEMENT,
	MYACCOUNT_ACTION_SIGN_PORTAL_AGREEMENT,
	MYACCOUNT_ACTION_UPLOAD_LOGO,
	MYACCOUNT_MFA_QR_CODE,
	NEW_HARDWARE_ACTION_POST_DATA_REQUEST,
	ORDER,
	PROCESS_ACCEPT_INTERCONNECTION,
	RATES_SMART_CONTRACT,
	RECOVERY_PASSWORD_REQUEST,
	REDIRECT_ACCOUNT,
	REGISTER_REQUEST,
	REMOVE_HARDWARE_PROFILE,
	RESULT_INTERCONNECTION,
	SAVE_ALLOCATE_SMART_CONTRACT,
	SAVE_BIDDINGPORTAL_SMART_CONTRACT,
	SAVE_FUNDER_SMART_CONTRACT,
	SAVE_FUNDING_REQUEST,
	SAVE_FUNDING_REVIEW,
	SELECT_MENU_ITEM,
	SEND_INTERCONNECTION,
	SETTLEMENTS,
	SIGN_SMART_CONTRACT,
	SMART_CONTRACTS_CATALOG_DATA,
	SMART_CONTRACT_ADDRESS_ACTION_GET_DATA_REQUEST,
	SMART_CONTRACT_ALLOCATE_FUNDS_REQUEST,
	SMART_CONTRACT_COUNT_ACTION_GET_DATA_REQUEST,
	SMART_CONTRACT_CREATE_UPDATE_SUCCESS,
	SMART_CONTRACT_FUNDING_OFFERS_MANAGE_REQUEST,
	SMART_CONTRACT_GET_FUNDING_OFFERS,
	SMART_CONTRACT_LINK,
	SMART_CONTRACT_PROPOSED_RATES_ACTION,
	SMART_CONTRACT_REQUEST_NEW_SMART_CONTRACT_DATA,
	SMART_CONTRACT_WALLET_DETAILS_REQUEST,
	SUPPLIER_PAY_INVOICE,
	TECHNICAL_DETAILS_ACTION_POST_DATA_REQUEST,
	TERMINATE_SMART_CONTRACT,
	UPDATE_HARDWARE_PROFILE,
	UPDATE_SMART_CONTRACT,
	UPLOAD_EXTERNAL_RATE_PLAN,
	UPLOAD_SMART_CONTRACT_IMMEDIATE_RATE_PLAN,
	UPLOAD_SMART_CONTRACT_RATE_PLAN,
	UPLOAD_VENDOR_RATE_PLAN,
	URL_REDIRECT_POSTLOGIN,
	VENDOR_RATES,
	VERIFICATION_CODE_REQUEST,
	WALLET_TOPUP,
	WALLET_WITHDRAW,
	GET_WALLET_TRANSACTIONS,
	CSV_DOWNLOAD_WALLET_TRANSACTIONS,
	SMART_CONTRACT_REQUEST_SAVE_UPDATE_CONTRACT_NAME_DATA
} from './actions';

export default function* rootSaga() {
	yield all([
		takeEvery(LOGIN_REQUEST, loginSagas.loginUser),
		takeEvery(MFA_REQUEST, loginSagas.mfaRequest),
		takeEvery('LOG_OUT', loginSagas.logoutUser),
		takeEvery(REGISTER_REQUEST, registerSagas.registerUser),
		takeEvery('START_IDLE_MONITOR', loginSagas.startIdleMonitor),
		takeEvery(CREATE_ORDER, biddingPortal.createOrder),
		takeEvery(CANCEL_ORDER, biddingPortal.cancelOrder),
		takeEvery(ACCEPT_ORDER, biddingPortal.acceptOrder),
		takeEvery(ORDER, biddingPortal.order),
		takeEvery(SELECT_MENU_ITEM, notificationsSagas.getNotifications),
		takeEvery(RESULT_INTERCONNECTION, notificationsSagas.getNotifications),
		takeEvery(SMART_CONTRACT_CREATE_UPDATE_SUCCESS, notificationsSagas.getNotifications),
		takeEvery(RECOVERY_PASSWORD_REQUEST, recoveryPasswordSagas.recoveryPassword),
		takeEvery(VERIFICATION_CODE_REQUEST, verificationCodeSagas.verificationCode),
		takeEvery(FINANCIAL, financial.setFinancials),
		takeEvery(SETTLEMENTS, financial.setSettlementsData),
		takeEvery(LOAD_LENDER_INVOICES_APPROVAL, financial.setLenderInvoicesApproval),
		takeEvery(LENDER_INVOICES_APPROVAL_AGREE, financial.setLenderInvoicesApprovalAgree),
		takeEvery(INITIALIZE_BIDDING_PORTAL, biddingPortal.initializeBidding),
		takeEvery(SEND_INTERCONNECTION, interconnectionsSagas.sendInterconnection),
		takeEvery(INITIALIZE_INTERCONNECTIONS, interconnectionsSagas.initializeInterconnections),
		takeEvery(ACCEPT_INTERCONNECTION, interconnectionsSagas.acceptInterconnection),
		takeEvery(MYACCOUNT_ACTION_POST_DATA_REQUEST, myAccountSagas.sagaMyAccountPostRequest),
		takeEvery(LOGIN_SUCCESS, myAccountSagas.sagaMyAccountGetRequest),
		takeEvery(MYACCOUNT_ACTION_GET_DATA_REQUEST, myAccountSagas.sagaMyAccountGetRequest),
		takeEvery(LOAD_INITIAL_WALLET, walletSagas.getWallets),
		takeEvery(LOAD_VOIP_API_DATA, walletSagas.getVoipApiData),
		takeEvery(WALLET_TOPUP, walletSagas.sendTopUp),
		takeEvery(WALLET_WITHDRAW, walletSagas.sendWithDraw),
		takeEvery(GET_NOTIFICATIONS, notificationsSagas.getNotifications),
		takeEvery(PROCESS_ACCEPT_INTERCONNECTION, interconnectionsSagas.processAcceptInterconnection),
		takeEvery(EXPLORER_SMART_CONTRACTS_ACTION_GET_DATA_REQUEST, explorerSagas.sagaSmartContractExplorerGetDataRequest),
		takeEvery(REDIRECT_ACCOUNT, walletSagas.redirectAccount),
		takeEvery(DOWNLOAD_WALLET_REPORT, walletSagas.downloadWalletReport),
		takeEvery(URL_REDIRECT_POSTLOGIN, urlRedirectSagas.redirectPostLogin),
		takeEvery(BANK_ACCOUNT_ACTION_POST_DATA_REQUEST, myAccountSagas.sagaBankAccountPostRequest),
		takeEvery(BANK_ACCOUNT_ACTION_REMOVE_POST_DATA_REQUEST, myAccountSagas.sagaBankAccountRemovePostRequest),
		takeEvery(NEW_HARDWARE_ACTION_POST_DATA_REQUEST, myAccountSagas.sagaNewHardwarePostRequest),
		takeEvery(TECHNICAL_DETAILS_ACTION_POST_DATA_REQUEST, myAccountSagas.sagaTechnicalDetailsPostRequest),
		takeEvery(UPDATE_HARDWARE_PROFILE, myAccountSagas.sagaUpdateHardwareProfile),
		takeEvery(REMOVE_HARDWARE_PROFILE, myAccountSagas.sagaRemoveHardwareProfile),
		takeEvery(GET_COUNTRIES_ACTION_POST_DATA_REQUEST, myAccountSagas.sagaCountriesGetRequest),
		takeEvery(GET_BUSINESS_TYPE_ACTION_POST_DATA_REQUEST, myAccountSagas.sagaBusinessTypeGetRequest),
		takeEvery(CREATE_SMART_CONTRACT, smartcontractsSagas.createNewSmartContract),
		takeEvery(UPDATE_SMART_CONTRACT, smartcontractsSagas.updateNewSmartContract),
		takeEvery(SMART_CONTRACTS_CATALOG_DATA, smartcontractsSagas.getSmartContractCatalogs),
		takeEvery(LOAD_IP_ADDRESS_SMART_CONTRACTS, smartcontractsSagas.getIpAddressParticipant),
		takeEvery(INITIAL_SMART_CONTRACTS, smartcontractsSagas.initialSmartContracts),
		takeEvery(SMART_CONTRACT_REQUEST_NEW_SMART_CONTRACT_DATA, smartcontractsSagas.getNewSmartContractData),
		takeEvery(LOAD_SMART_CONTRACTS, smartcontractsSagas.getSmartContracts),
		takeEvery(INITIAL_NEW_SMART_CONTRACT, smartcontractsSagas.getInitialNewSmartContract),
		takeEvery(LOAD_DESTINATIONS_TYPE_CARRIER, smartcontractsSagas.getDestinationTypesCarrier),
		takeEvery(LOAD_DESTINATIONS_CARRIER, smartcontractsSagas.getDestinationsCarrier),
		takeEvery(DOWNLOAD_SMART_CONTRACT_RATE, smartcontractsSagas.downloadSmartContractRatePlan),
		takeEvery(DOWNLOAD_SAMPLE_RATE_PLAN, smartcontractsSagas.downloadSampleRatePlan),
		takeEvery(RATES_SMART_CONTRACT, smartcontractsSagas.getRates),
		takeEvery(CATALOGS_SMART_CONTRACT, smartcontractsSagas.getSmartContractCatalogs),
		takeEvery(FUNDERS_SMART_CONTRACT, smartcontractsSagas.getFunders),
		takeEvery(BIDDING_SMART_CONTRACT, smartcontractsSagas.getBiddingPortal),
		takeEvery(LOAD_DIAL_CODES_TYPE_CARRIER_COUNTRY, smartcontractsSagas.getDialCodesCountryTypesCarrier),
		takeEvery(SMART_CONTRACT_LINK, smartcontractsSagas.getBuyContractsExcept),
		takeEvery(TERMINATE_SMART_CONTRACT, smartcontractsSagas.terminate),
		takeEvery(SIGN_SMART_CONTRACT, smartcontractsSagas.sign),
		takeEvery(SAVE_FUNDER_SMART_CONTRACT, smartcontractsSagas.saveFunder),
		takeEvery(SAVE_BIDDINGPORTAL_SMART_CONTRACT, smartcontractsSagas.saveBiddingPortal),
		takeEvery(SAVE_ALLOCATE_SMART_CONTRACT, smartcontractsSagas.saveAllocate),
		takeEvery(LOAD_SMART_CONTRACT, smartcontractsSagas.getSmartContract),
		takeEvery(SMART_CONTRACT_REQUEST_SAVE_UPDATE_CONTRACT_NAME_DATA, smartcontractsSagas.saveUpdateContractName),

		/** Lender Flow Sagas */
		takeEvery(SAVE_FUNDING_REQUEST, smartcontractsSagas.saveFundingRequest),
		takeEvery(SAVE_FUNDING_REVIEW, smartcontractsSagas.saveAllocateFunding),
		/** End Lender Flow Sagas */

		takeEvery(GET_HARDWARE_PROFILE_OPTIONS_ACTION_POST_DATA_REQUEST, myAccountSagas.sagaHardwareProfileOptionsGetRequest),
		takeEvery(GET_CODECS_ACTION_POST_DATA_REQUEST, myAccountSagas.sagaCodecsGetRequest),
		takeEvery(GET_STATES_ACTION_POST_DATA_REQUEST, myAccountSagas.sagaStatesGetRequest),
		takeEvery(GET_BANKSTATES_ACTION_POST_DATA_REQUEST, myAccountSagas.sagaBankStatesGetRequest),
		takeEvery(GET_BENEFICIARYSTATES_ACTION_POST_DATA_REQUEST, myAccountSagas.sagaBeneficiaryStatesGetRequest),
		takeEvery(GET_CORRESPONDENTSTATES_ACTION_POST_DATA_REQUEST, myAccountSagas.sagaCorrespondentStatesGetRequest),
		takeEvery(CREATE_NEW_WALLET_ACTION_POST_DATA_REQUEST, myAccountSagas.sagaCreateNewWalletGetRequest),
		takeEvery(EXPLORER_INIT_BLOCKS_POLLING, explorerSagas.sagaInitBlocksPolling),
		takeEvery(EXPLORER_WALLET_ACTION_GET_DATA_REQUEST, explorerSagas.sagaWalletExplorerGetDataRequest),
		takeEvery(LOAD_INITIAL_WELCOME_WALLET, walletSagas.getWelcomeWallets),
		takeEvery(GET_WALLET_TRANSACTIONS, walletSagas.getWalletTransactions),
		takeEvery(CSV_DOWNLOAD_WALLET_TRANSACTIONS, walletSagas.exportWalletTransactions),
		takeEvery(CHANGE_PASSWORD_REQUEST, changePasswordSagas.changePassword),
		takeEvery(SMART_CONTRACT_COUNT_ACTION_GET_DATA_REQUEST, smartcontractsSagas.sagaSmartContractCountGetDataRequest),
		takeEvery(SMART_CONTRACT_ADDRESS_ACTION_GET_DATA_REQUEST, smartcontractsSagas.sagaSmartContractAddressGetDataRequest),
		takeEvery(LOAD_INVOICES, reportSagas.getInvoices),
		takeEvery(LOAD_MONTHLY_INVOICES, reportSagas.getMonthlyInvoices),
		takeEvery(DOWNLOAD_INVOICE, reportSagas.downloadInvoice),
		takeEvery(CUSTOMER_PAY_INVOICE, reportSagas.payInvoice),
		takeEvery(SUPPLIER_PAY_INVOICE, reportSagas.supplierPayInvoice),
		takeEvery(UPLOAD_SMART_CONTRACT_RATE_PLAN, smartcontractsSagas.uploadSmartContractRates),

		takeEvery(SMART_CONTRACT_PROPOSED_RATES_ACTION, smartcontractsSagas.sendProposedRatesAction),

		takeEvery(GET_PHONEPREFIX_ACTION_POST_DATA_REQUEST, myAccountSagas.sagaPhonePrefixGetRequest),
		takeEvery(MYACCOUNT_ACTION_UPLOAD_LOGO, myAccountSagas.sagaUploadLogo),
		takeEvery(MYACCOUNT_ACTION_SIGN_PORTAL_AGREEMENT, myAccountSagas.sagaSignPortalAgreement),
		takeEvery(MYACCOUNT_MFA_QR_CODE, myAccountSagas.sagaGetQrCode),

		takeEvery(ACTION_HANDLE_NEW_USER, myAccountSagas.sagaHandleNewUser),

		takeEvery(MYACCOUNT_ACTION_SIGN_FINANCING_AGREEMENT, myAccountSagas.sagaSignFinancingAgreement),
		takeEvery(MYACCOUNT_ACTION_DOWNLOAD_PORTAL_AGREEMENT, myAccountSagas.sagaDownloadPortalAgreement),
		takeEvery(MYACCOUNT_ACTION_DOWNLOAD_FINANCING_AGREEMENT, myAccountSagas.sagaDownloadFinancingAgreement),
		takeEvery(EXTERNAL_CLIENT_DETAILS_ACTION_POST_DATA_REQUEST, myAccountSagas.sagaUpdateExternalClientDetails),
		takeEvery(GET_EXTERNAL_CLIENT_DATA, myAccountSagas.sagaGetExternalClientData),

		/** Smart Contract Sign Actions */
		takeEvery(INITIALIZE_SMARTCONTRACT_SIGN, interconnectionsSagas.initializeSmartContractSign),
		takeEvery(INITIALIZE_SMARTCONTRACT_DOWNLOAD, interconnectionsSagas.initializeSmartContractDownload),
		takeEvery(INITIALIZE_SMARTCONTRACT_COUNTERSIGN, interconnectionsSagas.initializeSmartContractCounterSign),

		takeEvery(INFO_TEXT_REQUEST, InfoTextSagas.sagaInfoTextPostRequest),
		takeEvery(CHANGE_PASSWORD_ACTION_POST_DATA_REQUEST, myAccountSagas.sagaChangePasswordPostRequest),
		takeEvery(FINANCING_REQUESTS_GET_SMARTCONTRACTS, financialRequestsSagas.getSmartContracts),
		takeEvery(FINANCING_REQUESTS_SEND_LENDER_OFFER, financialRequestsSagas.sendLenderOffer),
		takeEvery(SMART_CONTRACT_GET_FUNDING_OFFERS, smartcontractsSagas.getSmartContractFundingOffers),
		takeEvery(SMART_CONTRACT_FUNDING_OFFERS_MANAGE_REQUEST, smartcontractsSagas.sagaSmartContractManageFundingOffer),
		takeEvery(SMART_CONTRACT_ALLOCATE_FUNDS_REQUEST, smartcontractsSagas.sagaSmartContractAllocateFunds),
		takeEvery(UPLOAD_VENDOR_RATE_PLAN, routeSagas.uploadVendorRates),
		takeEvery(VENDOR_RATES, routeSagas.getVendorRates),
		takeEvery(LENDER_SETTINGS_GET_DATA_REQUEST, myAccountSagas.sagaLenderSettingsGetDataRequest),
		takeEvery(LENDER_SETTINGS_POST_DATA_REQUEST, myAccountSagas.sagaLenderSettingsPostDataRequest),
		takeEvery(SMART_CONTRACT_WALLET_DETAILS_REQUEST, smartcontractsSagas.getContractWalletDetails),
		takeEvery(FINANCING_REQUESTS_UPDATE_OFFER, financialRequestsSagas.updateOfferOffer),
		takeEvery(GET_PLATFORM_SETTINGS, platformSettingsSagas.getPlatformSettings),
		takeEvery(UPLOAD_EXTERNAL_RATE_PLAN, routeSagas.uploadExternalRates),
		takeEvery(UPLOAD_SMART_CONTRACT_IMMEDIATE_RATE_PLAN, smartcontractsSagas.uploadSmartContractImmediateRates),
		takeEvery(CREATE_EXTERNAL_INVOICE, routeSagas.postCreateExternalInvoice),
		takeEvery(LOAD_EXTERNAL_INVOICES, reportSagas.getExternalInvoices),
		takeEvery(EXTERNAL_RATES, routeSagas.getExternalRates),
		takeEvery(EXTERNAL_INVOICE, routeSagas.getExternalInvoicePreview),
		// New way
		account(),
		useraccount(),
		routes(),
		reports(),
		smartContracts(),
		contracts(),
		wallet(),
		acp(),
		support(),
		passwordchallenge(),
		escrowmanaging()
	]);
}
