import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionDetails,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField
} from '@material-ui/core';
import {
  RenderRadio,
  RenderNotification
} from "components";

const styles = {
  root: {
    '&:before': {
      display: "none",
    },
  },
};

class SmartContractAllocateBuy extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      allocatedAmount: undefined,
      smartContractId: ""

    }
  }

  handleChange(key, value) {
    this.setState({
      [key]: value
    });
  }

  render() {
    const {
      buySmartcontracts,
      classSelectAllocate,
      classSelectSmartContractLink,
      onFieldChangeAllocateBuy,
      errorsAllocateBuy,
      isFunder,
      changeAllocateBuySelect,
      classes
    } = this.props;

    return (
      <div>
        <RenderNotification
          variant="danger"
          floating={false}
          open={errorsAllocateBuy && errorsAllocateBuy.length > 0}
          message={errorsAllocateBuy.map((error, i) => {
            return <p key={i}>{error}</p>
          })}
        />
        {/* PTI-137 temporary hide */}
        {/* <RenderRadio label="Allocate Amount" name="radio" onChange={() => changeAllocateBuySelect("allocateAmount")} checked={classSelectAllocate && !classSelectSmartContractLink} />

        {!isFunder && (
          <RenderRadio label="Link to a smart contract" name="radio" onChange={() => changeAllocateBuySelect("linkSmartContract")} checked={classSelectSmartContractLink} />
         )} */}

        {/* <Accordion expanded={classSelectAllocate} classes={{root: classes.root}}>
          <AccordionDetails> */}
            <TextField
              label="Daily allocated amount"
              value={this.state.allocatedAmount}
              onChange={event => {
                  this.handleChange("allocatedAmount", event.target.value);
                  onFieldChangeAllocateBuy("allocatedAmount", event.target.value)
                }
              }
              type="number"
              style={{minWidth: "100%"}}
            />
          {/* </AccordionDetails>
        </Accordion> */}

        {/* <Accordion expanded={classSelectSmartContractLink} classes={{root: classes.root}}>
          <AccordionDetails>
            <FormControl style={{minWidth: "200px"}}>
              <InputLabel htmlFor="fundersList">Select a funder</InputLabel>
              <Select
                value={this.state.smartContractId}
                onChange={event => {
                    this.handleChange("smartContractId", event.target.value);
                    onFieldChangeAllocateBuy("smartContractId", event.target.value)
                  }
                }
                inputProps={{
                  name: "fundersList",
                  id: "fundersList"
                }}
              >
                <MenuItem value="">
                  None
                </MenuItem>
                {buySmartcontracts.map(smartcontract => (
                  <MenuItem key={smartcontract.id} value={smartcontract.id}>
                    {`ID:${smartcontract.id} - ${smartcontract.supplierName}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </AccordionDetails>
        </Accordion> */}
      </div>
    );
  }

};

SmartContractAllocateBuy.propTypes = {
  buySmartcontracts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      supplierName: PropTypes.string.isRequired
    })
  ),
  isFunder: PropTypes.bool,
  classSelectAllocate: PropTypes.bool,
  classSelectSmartContractLink: PropTypes.bool,
  changeAllocateBuySelect: PropTypes.func.isRequired,
  onFieldChangeAllocateBuy: PropTypes.func.isRequired,
  errorsAllocateBuy: PropTypes.arrayOf(PropTypes.string)
};
SmartContractAllocateBuy.defaultProps = {
  classSelectAllocate: false,
  classSelectSmartContractLink: false,
  isFunder: true,
  buySmartcontracts: [],
  errorsAllocateBuy: []
};
export default withStyles(styles)(SmartContractAllocateBuy);
