import React, { Component } from "react";
import MUIDataTable from "mui-datatables";

class ReportSmartContracts extends Component {
  render() {
    const { tableData, tableOptions } = this.props;
    const formattedData = tableData ? tableData : [];
    const tables = formattedData.map((obj, i) => (
      <MUIDataTable
        key={i}
        title={obj.title}
        data={obj.data}
        columns={["Destination", "Minutes", "Rate", "Amount","Cost/Min","Profit","Margin (%)"]}
        options={tableOptions}
      />
    ));

    return <div>{tables}</div>;
  }
}

export default ReportSmartContracts;
