import { Auth } from 'aws-amplify';

const awsAmplifyService = {
	login(userName, password) {
		window.localStorage.removeItem('IMPERSONATE_CODE');
		return Auth.signIn(userName, password)
			.then(user => user)
			.catch(err => err);
	},
	logout() {
		window.localStorage.removeItem('IMPERSONATE_CODE');
		return Auth.signOut()
			.then(data => data)
			.catch(err => err);
	},
	registerUser(contactName, companyName, email, password, accountType) {
		return Auth.signUp({
			username: email,
			password,
			attributes: {
				email,
				name: contactName,
				'custom:company_name': companyName,
				zoneinfo: accountType
			}
		})
			.then(data => data)
			.catch(err => err);
	},
	recoveryPassword(email) {
		return Auth.forgotPassword(email)
			.then(data => data)
			.catch(err => err);
	},
	recoveryPasswordSubmit(email, code, newPassword) {
		return Auth.forgotPasswordSubmit(email, code, newPassword)
			.then(data => ({ ...data, status: 'ok' }))
			.catch(err => ({ ...err, status: 'bad', message: err.message }))
	},
	changePassword(oldPassword, newPassword, username) {
		return Auth.signIn(username, oldPassword)
			.then(() =>
				Auth.currentAuthenticatedUser()
					.then(user => Auth.changePassword(user, oldPassword, newPassword))
					// .then(() => Auth.signOut())
					.then(result => ({ status: result === 'SUCCESS' ? 'OK' : 'BAD' }))
					.catch(err => err)
			)
			.catch(err => err);
	},
	impersonateSignIn(username, code) {
		Auth.configure({
			authenticationFlowType: 'CUSTOM_AUTH',
			clientMetadata: {
				foo: code
			}
		});

		return Auth.signIn(username)
			.then(user => {
				if (user.challengeName === 'CUSTOM_CHALLENGE') {
					// to send the answer of the custom challenge

					window.localStorage.setItem('IMPERSONATE_CODE', code);

					return Auth.sendCustomChallengeAnswer(user, code)
						.then(u => u)
						.catch(err => err);
				}
				return false;
			})
			.catch(err => err);
	}
};

export default awsAmplifyService;
