import React from "react";
import isEmail from "validator/lib/isEmail";
import isFloat from "validator/lib/isFloat";
import isInt from "validator/lib/isInt";
import isIn from "validator/lib/isIn";
import isURL from "validator/lib/isURL";
import isIP from "validator/lib/isIP";
import { CurrencyNumber } from "components";

const isEmptyString = value =>
  value === undefined ||
  value === null ||
  value.length < 1 ||
  (typeof value.trim === "function" && value.trim() === "");

const isEmpty = value => value === undefined || value === null || value === "";

const join = rules => (value, data) =>
  rules.map(rule => rule(value, data)).filter(error => !!error)[0];

export const commaSeparatedDigits = value =>
  !isEmpty(value) &&
  (!/^\d+(?:\,\d+)*$/.test(value) && "Only digits separated by commas");

export const email = value =>
  !isEmpty(value) && !isEmail(value) && "E-mail address invalid";

export const emailInterconnections = value =>
  !isEmpty(value) &&
  !isEmail(value) &&
  "Sorry, that email is invalid. Try another?";

export const url = value => !isEmpty(value) && !isURL(value) && "Invalid URL";

export const required = value => isEmpty(value) && "Required field";

export const requiredString = value =>
  isEmptyString(value) && "Required field string";

export const requiredInterconnectionType = value =>
  isEmpty(value) && "Please select an option";

export const minLengthChecked = min => value =>
  !isEmpty(value) && value.length < min && `Must be at least ${min} checked`;

export const minLength = min => value =>
  !isEmpty(value) && value.length < min && `Must be at least ${min} characters`;

export const maxLength = max => value =>
  !isEmpty(value) &&
  value.length > max &&
  `Must be no more than ${max} characters`;

export const decimal = value =>
  !isFloat((value = "0"), { min: 1 }) && "It must be a decimal number";

export const integer = value =>
  !isInt((value = "0"), { min: 1 }) && "It must be a number";

export const isNumber = value => {
  const pattern = /^\d+$/;
  return !pattern.test(value) && "It must be a number";
};

export const isOptionalNumber = value =>
  isEmpty(value) ? undefined : isNumber(value);

export const isDecimal = value => {
  const pattern = /^[0-9]+[.]?([0-9]+)?$/;
  return !pattern.test(value) && "It must be a number";
};

export const oneOf = values => value =>
  !isIn(value, values) && `Must be one of: ${values.join(", ")}`;

export const match = (field, label) => (value, data) =>
  data && value !== data[field] && `It must match ${label} `;

export const validaRegExp = (pattern, msg) => value =>
  !matchRegExpr(pattern, value) && `${msg}`;

const matchRegExpr = (pattern, value) => {
  if (isEmpty(value)) return true;
  const patt = new RegExp(pattern);
  return patt.test(value);
};

export const isIPValid = (value = "0.0.0.0") =>
  value !== null &&
  value !== "" &&
  !isIP(value, 4) &&
  "It must be a IP adress valid ";

export const isChecked = value =>
  value !== null &&
  value !== undefined &&
  value !== "" &&
  !value &&
  "It must be marked";

export const validateRequiredDepentValue = (field, valueCondition) => (
  value,
  data
) =>
  data &&
  valueCondition &&
  data[field] &&
  valueCondition === data[field] &&
  required(value);

export const validateDepentValue = (field, valueCondition, validateDepend) => (
  value,
  data
) =>
  data &&
  valueCondition &&
  data[field] &&
  valueCondition === data[field] &&
  validateDepend &&
  validateDepend(value);

export const validateSmartContractMembersArray = data => {
  if (data === null || data === undefined) return undefined;
  const membersArrayErrors = [];
  data.forEach((dataItem, index) => {
    const memberErrors = {};
    if (!dataItem.countryId) {
      memberErrors.countryId = "Required";
    }
    if (!dataItem.destinationTypeId) {
      memberErrors.destinationTypeId = "Required";
    }
    if (!dataItem.offerTypeId) {
      memberErrors.offerTypeId = "Required";
    }
    if (!dataItem.billingIncrementId) {
      memberErrors.billingIncrementId = "Required";
    }
    if (!dataItem.offerPrice) {
      memberErrors.offerPrice = "Required";
    } else if (!(dataItem.offerPrice > 0)) {
      memberErrors.offerPrice = "Must be greater than 0";
    }
    membersArrayErrors[index] = memberErrors;
  });

  return membersArrayErrors.length > 0 ? membersArrayErrors : undefined;
};

export const validateTechnicalInformationIpAddresses = data => {
  if (data === null || data === undefined) return undefined;
  const IpaddressesArrayErrors = [];
  data.forEach((dataItem, index) => {
    const IpaddressesErrors = {};
    if (!dataItem.ip) {
      IpaddressesErrors.ip = "Required";
    }
    if (isIPValid(dataItem.ip)) {
      IpaddressesErrors.ip = "Invalid IP Address";
    }
    if (!dataItem.netmask) {
      IpaddressesErrors.netmask = "Required";
    }
    if (!dataItem.port) {
      IpaddressesErrors.port = "Required";
    }
    IpaddressesArrayErrors[index] = IpaddressesErrors;
  });

  return IpaddressesArrayErrors.length > 0 ? IpaddressesArrayErrors : undefined;
};

export const validateRouteDestinations = data => {
  if (data === null || data === undefined) return undefined;
  const destinationArrayErrors = [];
  data.forEach((dataItem, index) => {
    const destinationErrors = {};
    if (!dataItem.type) {
      destinationErrors.type = "Required";
    }
    if (!dataItem.id) {
      destinationErrors.id = "Required";
    }
    if (!dataItem.detail) {
      destinationErrors.detail = "Required";
    }
    if (!dataItem.cps) {
      destinationErrors.cps = "Required";
    }
    if (!dataItem.sessions) {
      destinationErrors.sessions = "Required";
    }
    destinationArrayErrors[index] = destinationErrors;
  });

  return destinationArrayErrors.length > 0 ? destinationArrayErrors : undefined;
};

export const validatePassword = (value, data) => {
  if (
    typeof minLength(8)(value) === "string" ||
    typeof maxLength(20)(value) === "string" ||
    !matchRegExpr("^(?=.*\\d)", value) ||
    !matchRegExpr("(?=.*[!@#$%^.&*])", value) ||
    !matchRegExpr("(?=.*[a-z])", value) ||
    !matchRegExpr("(?=.*[A-Z])", value)
  ) {
    return (
      <div>
        <div>{minLength(8)(value)}</div>
        <div>{maxLength(20)(value)}</div>
        <div>
          {validaRegExp("^(?=.*\\d)", "Must contain at least one digit")(value)}
        </div>
        <div>
          {validaRegExp(
            "(?=.*[!@#$%^.&*])",
            "Must contain at least one special character"
          )(value)}
        </div>
        <div>
          {validaRegExp(
            "(?=.*[a-z])",
            "Must contain at least one lowercase letter"
          )(value)}
        </div>
        <div>
          {validaRegExp(
            "(?=.*[A-Z])",
            "Must contain at least one uppercase letter"
          )(value)}
        </div>
      </div>
    );
  }
};

export const agreement = value =>
  ((value !== null && value !== undefined && value !== "" && !value) ||
    isEmpty(value)) &&
  "You must be in agreement with the terms of service";

export const createValidator = rules => (data = {}) => {
  const errors = {};
  Object.keys(rules).forEach(key => {
    const rule = join([].concat(rules[key]));
    const error = rule(data[key], data);
    if (error) {
      errors[key] = error;
    }
  });
  return errors;
};

export const validateMaxValueParam = (value, data) => {
  if (isEmpty(value)) return "Please insert Amount";
  if (data && data.max && value > data.max) {
    return `Amount can not exceed ${data.max}`;
  }
};

export const validateDailyAlocatedMaxValueParam = (value, data) => {
  if (isEmpty(value)) return "Please insert daily settlement amount";
  if (
    data &&
    data.walletFunds !== undefined &&
    parseFloat(value) > parseFloat(data.walletFunds)
  ) {
    return `There are insufficient funds in your wallet. Please top up !`;
  }
  if (
    data &&
    data.maxDailyFundingAmount !== undefined &&
    parseFloat(value) > parseFloat(data.maxDailyFundingAmount)
  ) {
    return `Daily settlement amount cannot exceed ${data.maxDailyFundingAmount}`;
  }
};

export const validateTotalFundingMaxValueParam = (value, data) => {
  if (isEmpty(value)) return "Please insert requested funding amount";
  if (
    data &&
    data.maxTotalFundingAmount !== undefined &&
    parseFloat(value) > parseFloat(data.maxTotalFundingAmount)
  ) {
    return `Requested funding amount cannot exceed ${data.maxTotalFundingAmount}`;
  }
};

export const validateReportsSmartContract = (value, data) => {
  if (data.reportType && data.reportType === "callSearch") {
    if (isEmpty(value)) return "Please select smart contract";
  }
};

export const validateWithdrawAmount = (value, data) => {
  if (isEmpty(value)) return "Please insert requested withdraw amount";
  if (
    data &&
    data.amountAvailable !== undefined &&
    parseFloat(value) > parseFloat(data.amountAvailable)
  ) {
    return data.amountAvailable > 0 ? <span>Amount cannot exceed <CurrencyNumber amount={data.amountAvailable} /></span> : "You do not have any money in your account. Please Top Up!";
  }
};

export const validatePercent = value => {
  if (isEmpty(value)) return "Please insert percentage";
  if (parseInt(value) > 100) {
    return `Percentage can not exceed 100%`;
  }
  if (parseInt(value) < 0) {
    return `Percentage can not be below 0%`;
  }
};
