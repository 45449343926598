import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Paper, Grid, Typography, CircularProgress } from "@material-ui/core";
import AttachMoney from "@material-ui/icons/AttachMoney";
import { RenderChart } from "components";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
import moment from "moment";
import welcomeChartsStyles from "./style";

const aSRChartData = {
  xAxis: {
    type: "datetime"
  },
  yAxis: {
    title: {
      text: ""
    }
  },
  chart: {
    zoomType: "x",
    events: {
      load: function() {
        this.series.forEach(function(s) {
          s.update({
            showInLegend: s.points.length
          });
        });
      }
    }
  },
  credits: {
    enabled: false
  },
  title: {
    text: ""
  },
  legend: {
    layout: "vertical",
    floating: true,
    align: "left",
    verticalAlign: "top",
    x: 30,
    y: 0,
    symbolWidth: 5
  },
  boost: {
    useGPUTranslations: true
  },
  series: [
    {
      name: "Buyer",
      data: []
    },
    {
      name: "Seller",
      data: []
    }
  ]
};

const rDAvgChartData = {
  xAxis: {
    type: "datetime"
  },
  yAxis: {
    title: {
      text: ""
    }
  },
  chart: {
    zoomType: "x",
    events: {
      load: function() {
        this.series.forEach(function(s) {
          s.update({
            showInLegend: s.points.length
          });
        });
      }
    }
  },
  credits: {
    enabled: false
  },
  title: {
    text: ""
  },
  legend: {
    layout: "vertical",
    floating: true,
    align: "left",
    verticalAlign: "top",
    x: 30,
    y: 0,
    symbolWidth: 5
  },
  boost: {
    useGPUTranslations: true
  },
  series: [
    {
      name: "Buyer",
      data: []
    },
    {
      name: "Seller",
      data: []
    }
  ]
};

const aLOCChartData = {
  xAxis: {
    type: "datetime"
  },
  yAxis: {
    title: {
      text: ""
    }
  },
  chart: {
    zoomType: "x",
    events: {
      load: function() {
        this.series.forEach(function(s) {
          s.update({
            showInLegend: s.points.length
          });
        });
      }
    }
  },
  credits: {
    enabled: false
  },
  title: {
    text: ""
  },
  legend: {
    layout: "vertical",
    floating: true,
    align: "left",
    verticalAlign: "top",
    x: 30,
    y: 0,
    symbolWidth: 5
  },
  boost: {
    useGPUTranslations: true
  },
  series: [
    {
      name: "Buyer",
      data: []
    },
    {
      name: "Seller",
      data: []
    }
  ]
};

const nERChartData = {
  xAxis: {
    type: "datetime"
  },
  yAxis: {
    title: {
      text: ""
    }
  },
  chart: {
    zoomType: "x",
    events: {
      load: function() {
        this.series.forEach(function(s) {
          s.update({
            showInLegend: s.points.length
          });
        });
      }
    }
  },
  credits: {
    enabled: false
  },
  title: {
    text: ""
  },
  legend: {
    layout: "vertical",
    floating: true,
    align: "left",
    verticalAlign: "top",
    x: 30,
    y: 0,
    symbolWidth: 5
  },
  boost: {
    useGPUTranslations: true
  },
  series: [
    {
      name: "Buyer",
      data: []
    },
    {
      name: "Seller",
      data: []
    }
  ]
};

const tableColumns = [
  {
    name: "Id"
  },
  {
    name: "Timestamp"
  },
  {
    name: "Zone"
  },
  {
    name: "Party"
  },
  {
    name: "Operator"
  }
];

class WelcomeCharts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aSRChartData,
      rDAvgChartData,
      aLOCChartData,
      nERChartData,
      trend: {
        all: "3",
        aSR: "3",
        rDAvg: "3",
        aLOC: "3",
        nER: "3"
      },
      smartContract: ["111"],
      scIndex: ["0"],
      activeCalls: undefined,
      activeCallsZone: "iZoneId",
      average: [
        {
          aSR: "0",
          rDAvg: "0",
          aLOC: "0",
          nER: "0"
        },
        {
          aSR: "0",
          rDAvg: "0",
          aLOC: "0",
          nER: "0"
        }
      ],
      filterOpen: {
        aSR: false,
        rDAvg: false,
        aLOC: false,
        nER: false
      }
    };
  }

  componentDidMount() {
    const { scIndex, smartContract } = this.state;
    this.getAllChartData(scIndex[0], smartContract[0]);
    this.getActiveCalls(smartContract[0]);
  }

  getAllChartData = (index, contract) => {
    const { trend } = this.state;

    this.getChartData(trend.aSR, contract, index, "aSR");
    this.getChartData(trend.rDAvg, contract, index, "rDAvg");
    this.getChartData(trend.aLOC, contract, index, "aLOC");
    this.getChartData(trend.nER, contract, index, "nER");

    this.getAverage(trend.aSR, contract, index, "aSR");
    this.getAverage(trend.rDAvg, contract, index, "rDAvg");
    this.getAverage(trend.aLOC, contract, index, "aLOC");
    this.getAverage(trend.nER, contract, index, "nER");
  };

  getChartData = async (trendType, zoneId, index, statName) => {
    axios
      .get(`${await API.endpoint('NODE_SERVICE_API')}/analytics_report`, {
        params: {
          zone_id: zoneId,
          trendType,
          statName
        }
      })
      .then(response => {
        const series = response.data.values.map(Number);
        const data = [];

        response.data.dates.forEach((date, i) => {
          data.push([Number(moment(date).format("x")), series[i]]);
        });

        eval(`${statName}ChartData`).series[index].data = data;
        this.setState({
          [`${statName}ChartData`]: eval(`${statName}ChartData`)
        });
      })
      .catch(error => {
        console.log(`error ${error}`);
      });
  };

  getActiveCalls = async zoneId => {
    this.setState({ activeCalls: undefined });
    axios
      .get(`${await API.endpoint('NODE_SERVICE_API')}/live_calls`, {
        params: {
          iZoneId: zoneId
        }
      })
      .then(response => {
        this.setState({ activeCalls: response.data });
      })
      .catch(error => {
        console.log(`error ${error}`);
      });
  };

  getAverage = async (trendType, zoneId, index, statName) => {
    axios
      .get(`${await API.endpoint('NODE_SERVICE_API')}/avg_analytics_report`, {
        params: {
          zone_id: zoneId,
          trendType,
          statName
        }
      })
      .then(response => {
        const { average } = this.state;
        average[index][statName] = response.data.avg;

        this.setState({ average });
      })
      .catch(error => {
        console.log(`error ${error}`);
      });
  };

  selectTrend = (statName, value) => {
    if (!statName) {
      this.setState(
        {
          trend: {
            all: value,
            aSR: value,
            rDAvg: value,
            aLOC: value,
            nER: value
          }
        },
        () => {
          const { scIndex, smartContract } = this.state;
          scIndex.forEach((index, i) => {
            this.getAllChartData(i, smartContract[i]);
          });
        }
      );
    } else {
      const { trend } = this.state;
      trend[statName] = value;

      this.setState({ trend }, () => {
        const { scIndex, smartContract, trend, filterOpen } = this.state;
        filterOpen[statName] = !filterOpen[statName];
        this.setState({ filterOpen });
        scIndex.forEach((index, i) => {
          this.getChartData(trend[statName], smartContract[i], i, statName);
          this.getAverage(trend[statName], smartContract[i], i, statName);
        });
      });
    }
  };

  toggleFilters = filterName => {
    const { filterOpen } = this.state;
    filterOpen[filterName] = !filterOpen[filterName];

    this.setState({ filterOpen });
  };

  selectSmartContract = (contract, index, event) => {
    const {
      smartContract,
      aSRChartData,
      rDAvgChartData,
      aLOCChartData,
      nERChartData,
      scIndex,
      average
    } = this.state;

    if (event.currentTarget.classList.contains("active")) {
      event.currentTarget.classList.remove("active");

      smartContract.splice(smartContract.indexOf(contract), 1);

      scIndex.splice(scIndex.indexOf(index), 1);

      aSRChartData.series[index].data = [];
      rDAvgChartData.series[index].data = [];
      aLOCChartData.series[index].data = [];
      nERChartData.series[index].data = [];

      average[index].aSR = "0";
      average[index].rDAvg = "0";
      average[index].aLOC = "0";
      average[index].nER = "0";

      this.setState({
        smartContract,
        aSRChartData,
        rDAvgChartData,
        aLOCChartData,
        nERChartData,
        scIndex,
        average
      });
    } else {
      event.currentTarget.classList.add("active");
      smartContract.push(contract);
      scIndex.push(index);

      this.setState({ smartContract, scIndex }, () => {
        this.getAllChartData(index, contract);
        // this.getActiveCalls(contract);
      });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      aSRChartData,
      rDAvgChartData,
      aLOCChartData,
      nERChartData,
      trend,
      activeCalls,
      average,
      smartContract,
      activeCallsZone,
      filterOpen
    } = this.state;

    const tableData = [];

    if (activeCalls) {
      activeCalls.forEach(row => {
        tableData.push([
          row.trxId,
          row.sessionId,
          row.invitingTs,
          row.answerTs,
          row.iZoneName,
          row.eZoneName,
          row.callingParty,
          row.calledParty,
          row.operatorId
        ]);

        return tableData;
      });
    }

    let aSR = 0;
    let rDAvg = 0;
    let aLOC = 0;
    let nER = 0;

    average.forEach(avg => {
      aSR += parseFloat(avg.aSR);
    });

    average.forEach(avg => {
      rDAvg += parseFloat(avg.rDAvg);
    });

    average.forEach(avg => {
      aLOC += parseFloat(avg.aLOC);
    });

    average.forEach(avg => {
      nER += parseFloat(avg.nER);
    });

    return (
      <Grid container>
        <Grid item xs={12} md={9} lg={10}>
          <Grid container>
            <Grid item xs={12} md={6} lg={6}>
              <Grid container justify="center">
                <Grid item xs={12}>
                  <div className={classes.filterContainer}>
                    <div
                      className={`${classes.filterItem} active`}
                      onClick={() => this.activeCallsZone("iZoneId")}
                    >
                      Ingress
                    </div>
                    <div
                      className={classes.filterItem}
                      onClick={() => this.activeCallsZone("eZoneId")}
                    >
                      Egress
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {activeCalls === undefined && (
                    <Paper
                      className={`${classes.root} ${
                        classes.fullHeightContainer
                      }`}
                    >
                      <Typography variant="h2" align="center">
                        <CircularProgress color="secondary" />
                      </Typography>
                    </Paper>
                  )}
                  {activeCalls && (
                    <Grid
                      container

                      className={classes.fullHeightContainer}
                    >
                      <Grid item xs={12}>
                        <Scrollbars autoHide>
                          <Paper
                            className={`${classes.root} ${
                              classes.activeCallsContainer
                            }`}
                          >
                            {tableColumns.map(column => (
                              <div
                                key={column.name}
                                className={`${
                                  classes.activeCallsCell
                                } ${column.name === "Party" &&
                                  classes.activeCallsCellLarge}`}
                              >
                                <Typography
                                  variant="caption"
                                  className={classes.textBold}
                                >
                                  {column.name}
                                </Typography>
                              </div>
                            ))}
                          </Paper>

                          {tableData.map(row => (
                            <Paper
                              className={`${classes.root} ${
                                classes.activeCallsContainer
                              }`}
                            >
                              <div
                                className={`${classes.activeCallsCell} ${
                                  classes.activeCallsCellData
                                }`}
                              >
                                <div>
                                  <span className={classes.textBold}>Trx:</span>{" "}
                                  {row[0]}
                                </div>
                                <div>
                                  <span className={classes.textBold}>
                                    Session:
                                  </span>{" "}
                                  {row[1]}
                                </div>
                              </div>
                              <div
                                className={`${classes.activeCallsCell} ${
                                  classes.activeCallsCellData
                                }`}
                              >
                                <div>
                                  <span className={classes.textBold}>
                                    Inviting:
                                  </span>{" "}
                                  {row[2]}
                                </div>
                                <div>
                                  <span className={classes.textBold}>
                                    Answer:
                                  </span>{" "}
                                  {row[3]}
                                </div>
                              </div>
                              <div
                                className={`${classes.activeCallsCell} ${
                                  classes.activeCallsCellData
                                }`}
                              >
                                <div>
                                  <span className={classes.textBold}>
                                    Ingress:
                                  </span>{" "}
                                  {row[4]}
                                </div>
                                <div>
                                  <span className={classes.textBold}>
                                    Egress:
                                  </span>{" "}
                                  {row[5]}
                                </div>
                              </div>
                              <div
                                className={`${classes.activeCallsCell} ${
                                  classes.activeCallsCellData
                                } ${classes.activeCallsCellLarge}`}
                              >
                                <div>
                                  <span className={classes.textBold}>
                                    Calling:
                                  </span>{" "}
                                  {row[6]}
                                </div>
                                <div>
                                  <span className={classes.textBold}>
                                    Called:
                                  </span>{" "}
                                  {row[7]}
                                </div>
                              </div>
                              <div
                                className={`${classes.activeCallsCell} ${
                                  classes.activeCallsCellData
                                }`}
                              >
                                {row[8]}
                              </div>
                            </Paper>
                          ))}
                        </Scrollbars>
                      </Grid>
                    </Grid>
                  )}
                  {activeCalls === "" && (
                    <Paper
                      className={`${classes.root} ${
                        classes.fullHeightContainer
                      }`}
                    >
                      <Typography variant="h2" align="center">
                        No active calls at this moment
                      </Typography>
                    </Paper>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Grid container>
                <Grid item xs={12}>
                  <div className={classes.filterContainer}>
                    <div
                      className={`${classes.filterItem} ${trend.all === "3" &&
                        "active"}`}
                      onClick={() => this.selectTrend(null, "3")}
                    >
                      Current interval
                    </div>
                    <div
                      className={`${classes.filterItem} ${trend.all === "2" &&
                        "active"}`}
                      onClick={() => this.selectTrend(null, "2")}
                    >
                      Hourly
                    </div>
                    <div
                      className={`${classes.filterItem} ${trend.all === "1" &&
                        "active"}`}
                      onClick={() => this.selectTrend(null, "1")}
                    >
                      Daily
                    </div>
                    <div
                      className={`${classes.filterItem} ${trend.all === "4" &&
                        "active"}`}
                      onClick={() => this.selectTrend(null, "4")}
                    >
                      Weekly
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container

                    className={classes.fullHeightContainer}
                  >
                    <Grid item xs={12}>
                      <Scrollbars autoHide>


                            <Paper className={`${classes.root} ${classes.chartContainer}`}>
                              <Grid container>
                                <Grid item xs={6}>
                                  <Typography
                                    variant="caption"
                                    className={classes.textBold}
                                  >
                                    Answer to Seizure Ratio (ASR)
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    className={`${classes.textBold} ${
                                      classes.textPrimary
                                    }`}
                                  >
                                    Avg{" "}
                                    {(aSR / smartContract.length).toFixed(2)}%
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <div
                                    className={classes.filterContainer}
                                    onClick={() => this.toggleFilters("aSR")}
                                  >
                                    <div
                                      className={`${classes.filterItem} active`}
                                    >
                                      {trend.aSR === "3" && "Current interval"}
                                      {trend.aSR === "2" && "Hourly"}
                                      {trend.aSR === "1" && "Daily"}
                                      {trend.aSR === "4" && "Weekly"}
                                    </div>
                                  </div>
                                  <div
                                    className={`${classes.filterContainer} ${
                                      classes.filterContainerHidden
                                    } ${!filterOpen.aSR && "closed"}`}
                                  >
                                    <div
                                      className={`${
                                        classes.filterItem
                                      } ${trend.aSR === "3" && "active"}`}
                                      onClick={() =>
                                        this.selectTrend("aSR", "3")
                                      }
                                    >
                                      Current interval
                                    </div>

                                    <div
                                      className={`${
                                        classes.filterItem
                                      } ${trend.aSR === "2" && "active"}`}
                                      onClick={() =>
                                        this.selectTrend("aSR", "2")
                                      }
                                    >
                                      Hourly
                                    </div>

                                    <div
                                      className={`${
                                        classes.filterItem
                                      } ${trend.aSR === "1" && "active"}`}
                                      onClick={() =>
                                        this.selectTrend("aSR", "1")
                                      }
                                    >
                                      Daily
                                    </div>

                                    <div
                                      className={`${
                                        classes.filterItem
                                      } ${trend.aSR === "4" && "active"}`}
                                      onClick={() =>
                                        this.selectTrend("aSR", "4")
                                      }
                                    >
                                      Weekly
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item xs={12}>
                                  <RenderChart data={aSRChartData} />
                                </Grid>
                              </Grid>
                            </Paper>


                            <Paper className={`${classes.root} ${classes.chartContainer}`}>
                              <Grid container>
                                <Grid item xs={6}>
                                  <Typography
                                    variant="caption"
                                    className={classes.textBold}
                                  >
                                    Post Dial Delay (PDD)
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    className={`${classes.textBold} ${
                                      classes.textPrimary
                                    }`}
                                  >
                                    Avg{" "}
                                    {(
                                      (rDAvg / smartContract.length) *
                                      1000
                                    ).toFixed(2)}
                                    s
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <div
                                    className={classes.filterContainer}
                                    onClick={() => this.toggleFilters("rDAvg")}
                                  >
                                    <div
                                      className={`${classes.filterItem} active`}
                                    >
                                      {trend.rDAvg === "3" &&
                                        "Current interval"}
                                      {trend.rDAvg === "2" && "Hourly"}
                                      {trend.rDAvg === "1" && "Daily"}
                                      {trend.rDAvg === "4" && "Weekly"}
                                    </div>
                                  </div>
                                  <div
                                    className={`${classes.filterContainer} ${
                                      classes.filterContainerHidden
                                    } ${!filterOpen.rDAvg && "closed"}`}
                                  >
                                    <div
                                      className={`${
                                        classes.filterItem
                                      } ${trend.rDAvg === "3" && "active"}`}
                                      onClick={() =>
                                        this.selectTrend("rDAvg", "3")
                                      }
                                    >
                                      Current interval
                                    </div>

                                    <div
                                      className={`${
                                        classes.filterItem
                                      } ${trend.rDAvg === "2" && "active"}`}
                                      onClick={() =>
                                        this.selectTrend("rDAvg", "2")
                                      }
                                    >
                                      Hourly
                                    </div>

                                    <div
                                      className={`${
                                        classes.filterItem
                                      } ${trend.rDAvg === "1" && "active"}`}
                                      onClick={() =>
                                        this.selectTrend("rDAvg", "1")
                                      }
                                    >
                                      Daily
                                    </div>

                                    <div
                                      className={`${
                                        classes.filterItem
                                      } ${trend.rDAvg === "4" && "active"}`}
                                      onClick={() =>
                                        this.selectTrend("rDAvg", "4")
                                      }
                                    >
                                      Weekly
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item xs={12}>
                                  <RenderChart data={rDAvgChartData} />
                                </Grid>
                              </Grid>
                            </Paper>


                            <Paper className={`${classes.root} ${classes.chartContainer}`}>
                              <Grid container>
                                <Grid item xs={6}>
                                  <Typography
                                    variant="caption"
                                    className={classes.textBold}
                                  >
                                    Average Length of Call (ALOC)
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    className={`${classes.textBold} ${
                                      classes.textPrimary
                                    }`}
                                  >
                                    Avg{" "}
                                    {(aLOC / smartContract.length).toFixed(2)}s
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <div
                                    className={classes.filterContainer}
                                    onClick={() => this.toggleFilters("aLOC")}
                                  >
                                    <div
                                      className={`${classes.filterItem} active`}
                                    >
                                      {trend.aLOC === "3" && "Current interval"}
                                      {trend.aLOC === "2" && "Hourly"}
                                      {trend.aLOC === "1" && "Daily"}
                                      {trend.aLOC === "4" && "Weekly"}
                                    </div>
                                  </div>
                                  <div
                                    className={`${classes.filterContainer} ${
                                      classes.filterContainerHidden
                                    } ${!filterOpen.aLOC && "closed"}`}
                                  >
                                    <div
                                      className={`${
                                        classes.filterItem
                                      } ${trend.aLOC === "3" && "active"}`}
                                      onClick={() =>
                                        this.selectTrend("aLOC", "3")
                                      }
                                    >
                                      Current interval
                                    </div>

                                    <div
                                      className={`${
                                        classes.filterItem
                                      } ${trend.aLOC === "2" && "active"}`}
                                      onClick={() =>
                                        this.selectTrend("aLOC", "2")
                                      }
                                    >
                                      Hourly
                                    </div>

                                    <div
                                      className={`${
                                        classes.filterItem
                                      } ${trend.aLOC === "1" && "active"}`}
                                      onClick={() =>
                                        this.selectTrend("aLOC", "1")
                                      }
                                    >
                                      Daily
                                    </div>

                                    <div
                                      className={`${
                                        classes.filterItem
                                      } ${trend.aLOC === "4" && "active"}`}
                                      onClick={() =>
                                        this.selectTrend("aLOC", "4")
                                      }
                                    >
                                      Weekly
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item xs={12}>
                                  <RenderChart data={aLOCChartData} />
                                </Grid>
                              </Grid>
                            </Paper>


                            <Paper className={`${classes.root} ${classes.chartContainer}`}>
                              <Grid container>
                                <Grid item xs={6}>
                                  <Typography
                                    variant="caption"
                                    className={classes.textBold}
                                  >
                                    Network Efficiency Ratio (NER)
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    className={`${classes.textBold} ${
                                      classes.textPrimary
                                    }`}
                                  >
                                    Avg{" "}
                                    {(nER / smartContract.length).toFixed(2)}%
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <div
                                    className={classes.filterContainer}
                                    onClick={() => this.toggleFilters("nER")}
                                  >
                                    <div
                                      className={`${classes.filterItem} active`}
                                    >
                                      {trend.nER === "3" && "Current interval"}
                                      {trend.nER === "2" && "Hourly"}
                                      {trend.nER === "1" && "Daily"}
                                      {trend.nER === "4" && "Weekly"}
                                    </div>
                                  </div>
                                  <div
                                    className={`${classes.filterContainer} ${
                                      classes.filterContainerHidden
                                    } ${!filterOpen.nER && "closed"}`}
                                  >
                                    <div
                                      className={`${
                                        classes.filterItem
                                      } ${trend.nER === "3" && "active"}`}
                                      onClick={() =>
                                        this.selectTrend("nER", "3")
                                      }
                                    >
                                      Current interval
                                    </div>

                                    <div
                                      className={`${
                                        classes.filterItem
                                      } ${trend.nER === "2" && "active"}`}
                                      onClick={() =>
                                        this.selectTrend("nER", "2")
                                      }
                                    >
                                      Hourly
                                    </div>

                                    <div
                                      className={`${
                                        classes.filterItem
                                      } ${trend.nER === "1" && "active"}`}
                                      onClick={() =>
                                        this.selectTrend("nER", "1")
                                      }
                                    >
                                      Daily
                                    </div>

                                    <div
                                      className={`${
                                        classes.filterItem
                                      } ${trend.nER === "4" && "active"}`}
                                      onClick={() =>
                                        this.selectTrend("nER", "4")
                                      }
                                    >
                                      Weekly
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item xs={12}>
                                  <RenderChart data={nERChartData} />
                                </Grid>
                              </Grid>
                            </Paper>


                      </Scrollbars>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <Grid container direction="column">
            <Grid item xs={12}>
              <div className={classes.filterContainer}>
                <input
                  className={`${classes.filterItem} ${classes.filterItemInput}`}
                  placeholder="Search smart contract"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container

                direction="column"
                className={classes.fullHeightContainer}
              >
                <Grid item xs={12}>
                  <Scrollbars autoHide>
                    <Paper className={classes.root}>
                      <div className={classes.smartContractsContainer}>
                        <div
                          className={`${classes.smartContract} active`}
                          onClick={event =>
                            this.selectSmartContract("111", "0", event)
                          }
                        >
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 46500,&nbsp;
                            </span>
                            <i>Buyer C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $89.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 hours ago</i>
                          </div>
                        </div>
                        <div
                          className={classes.smartContract}
                          onClick={event =>
                            this.selectSmartContract("113", "1", event)
                          }
                        >
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 68600,&nbsp;
                            </span>
                            <i>Seller C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $120.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 days ago</i>
                          </div>
                        </div>

                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 46500,&nbsp;
                            </span>
                            <i>Buyer C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $89.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 hours ago</i>
                          </div>
                        </div>
                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 68600,&nbsp;
                            </span>
                            <i>Seller C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $120.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 days ago</i>
                          </div>
                        </div>
                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 46500,&nbsp;
                            </span>
                            <i>Buyer C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $89.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 hours ago</i>
                          </div>
                        </div>
                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 68600,&nbsp;
                            </span>
                            <i>Seller C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $120.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 days ago</i>
                          </div>
                        </div>
                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 46500,&nbsp;
                            </span>
                            <i>Buyer C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $89.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 hours ago</i>
                          </div>
                        </div>
                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 68600,&nbsp;
                            </span>
                            <i>Seller C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $120.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 days ago</i>
                          </div>
                        </div>
                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 46500,&nbsp;
                            </span>
                            <i>Buyer C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $89.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 hours ago</i>
                          </div>
                        </div>
                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 68600,&nbsp;
                            </span>
                            <i>Seller C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $120.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 days ago</i>
                          </div>
                        </div>
                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 46500,&nbsp;
                            </span>
                            <i>Buyer C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $89.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 hours ago</i>
                          </div>
                        </div>
                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 68600,&nbsp;
                            </span>
                            <i>Seller C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $120.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 days ago</i>
                          </div>
                        </div>
                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 46500,&nbsp;
                            </span>
                            <i>Buyer C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $89.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 hours ago</i>
                          </div>
                        </div>
                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 68600,&nbsp;
                            </span>
                            <i>Seller C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $120.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 days ago</i>
                          </div>
                        </div>
                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 46500,&nbsp;
                            </span>
                            <i>Buyer C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $89.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 hours ago</i>
                          </div>
                        </div>
                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 68600,&nbsp;
                            </span>
                            <i>Seller C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $120.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 days ago</i>
                          </div>
                        </div>
                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 46500,&nbsp;
                            </span>
                            <i>Buyer C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $89.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 hours ago</i>
                          </div>
                        </div>
                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 68600,&nbsp;
                            </span>
                            <i>Seller C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $120.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 days ago</i>
                          </div>
                        </div>
                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 46500,&nbsp;
                            </span>
                            <i>Buyer C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $89.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 hours ago</i>
                          </div>
                        </div>
                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 68600,&nbsp;
                            </span>
                            <i>Seller C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $120.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 days ago</i>
                          </div>
                        </div>
                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 46500,&nbsp;
                            </span>
                            <i>Buyer C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $89.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 hours ago</i>
                          </div>
                        </div>
                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 68600,&nbsp;
                            </span>
                            <i>Seller C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $120.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 days ago</i>
                          </div>
                        </div>
                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 46500,&nbsp;
                            </span>
                            <i>Buyer C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $89.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 hours ago</i>
                          </div>
                        </div>
                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 68600,&nbsp;
                            </span>
                            <i>Seller C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $120.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 days ago</i>
                          </div>
                        </div>
                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 46500,&nbsp;
                            </span>
                            <i>Buyer C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $89.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 hours ago</i>
                          </div>
                        </div>
                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 68600,&nbsp;
                            </span>
                            <i>Seller C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $120.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 days ago</i>
                          </div>
                        </div>
                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 46500,&nbsp;
                            </span>
                            <i>Buyer C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $89.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 hours ago</i>
                          </div>
                        </div>
                        <div className={classes.smartContract}>
                          <AttachMoney
                            className={classes.smartContractBullet}
                          />
                          <span className={classes.flex}>
                            <span
                              className={`${classes.textPrimary} ${
                                classes.textBold
                              }`}
                            >
                              Ref.: 68600,&nbsp;
                            </span>
                            <i>Seller C </i>
                            <span className={classes.dailyEarnings}>
                              Daily earnings: $120.50
                            </span>
                          </span>
                          <div className={classes.lastUpdate}>
                            Last action: <i>3 days ago</i>
                          </div>
                        </div>
                      </div>
                    </Paper>
                  </Scrollbars>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

// export default withStyles(welcomeChartsStyles)(WelcomeCharts);
export default withStyles(welcomeChartsStyles, { withTheme: true })(
  WelcomeCharts
);
