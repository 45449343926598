import React from "react";
import { Accordion, AccordionItem } from "react-sanfona";

const SmartContractContentItem = props => {
  const { children, getSmartContractsExplorer, item } = props;

  if (item !== undefined) {
    const text = `${item.smartContractId} - ${item.smartContractAddresss}`;

    const getSmartContracts = id => e => {
      getSmartContractsExplorer(id, e);
    };

    return (
      <Accordion className="explorer-accordion">
        <AccordionItem
          key={item.smartContractId}
          titleTag="h5"
          bodyTag="div"
          title={text}
          titleClassName="card-header"
          style={{ cursor: "pointer" }}
          className="explorer-accordion-title"
          onExpand={getSmartContracts(item)}
        >
          <div className="card-body">{children}</div>
        </AccordionItem>
      </Accordion>
    );
  }

  return false;
};

export default SmartContractContentItem;
