const colors = {
	primary: {
		light: '#7ccbc8',
		main: '#0d553f',
		dark: '#0b3a2b'
	},
	secondary: {
		light: '#c7edec',
		main: '#05ce7b',
		dark: '#016562'
	},
	warning: {
		light: '#f7ead4',
		main: '#FC600A',
		dark: '#986b1d'
	},
	danger: {
		light: '#fcd9d6',
		main: '#FE2712',
		dark: '#aa2e25'
	},
	success: {
		light: '#deeddf',
		main: '#5daa62',
		dark: '#417644'
	},
	light: '#FFFFFF',
	dark: '#000000',
	grey: {
		light: '#666',
		main: '#999999',
		dark: '#adadad'
	},
	lightGrey: {
		light: '#f3f3f3',
		main: '#f0f0f0',
		dark: '#a8a8a8'
	},
	bodyBackground: '#f0f0f0'
};

export default colors;
