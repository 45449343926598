import { call, put } from "redux-saga/effects";
import FinancingRequestsApi from "services/api/FinancingRequestsApi";
import { setFinancialRequestsSmartContracts } from "store/actions";
import {
  actionCreatorPreloaderOff,
  actionCreatorPreloaderOn
} from "../preloader/actions";

export function* getSmartContracts() {
  try {
    yield put(actionCreatorPreloaderOn());
    const response = yield call(FinancingRequestsApi.getSmartContracts);
    yield put(setFinancialRequestsSmartContracts(response));
    yield put(actionCreatorPreloaderOff());
  } catch (err) {
    yield put(actionCreatorPreloaderOff());
    const error = err.message || err;
    console.log(`message error ::${error}`);
  }
}

export function* sendLenderOffer(action) {
  try {
    yield put(actionCreatorPreloaderOn());
    yield call(FinancingRequestsApi.sendLenderOffer, action.payload);

    const response = yield call(FinancingRequestsApi.getSmartContracts);
    yield put(setFinancialRequestsSmartContracts(response));
    yield put(actionCreatorPreloaderOff());
  } catch (err) {
    yield put(actionCreatorPreloaderOff());
    const error = err.message || err;
    console.log(`message error ::${error}`);
  }
}

export function* updateOfferOffer(action) {
  try {
    yield put(actionCreatorPreloaderOn());
    yield call(FinancingRequestsApi.updateLendingOffer, action.payload);

    const response = yield call(FinancingRequestsApi.getSmartContracts);
    yield put(setFinancialRequestsSmartContracts(response));

    yield put(actionCreatorPreloaderOff());
  } catch (err) {
    yield put(actionCreatorPreloaderOff());
    const error = err.message || err;
    console.log(`message error ::${error}`);
  }
}
