const req = require.context(
  ".",
  false,
  /^((?!index|__snapshots__|__mocks__|\.test).)*\.js$/
);

req.keys().forEach(key => {
  const containerName = key.replace(/^\.\/([^.]+)\.js$/, "$1");
  module.exports[containerName] = req(key).default;
});
