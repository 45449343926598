import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { IconButton, Badge, MenuItem, Menu } from '@material-ui/core';

import Mail from '@material-ui/icons/Mail';

class NotificationsBox extends Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.redirect = this.redirect.bind(this);

		this.state = {
			dropdownOpen: false,
			anchorEl: null
		};
	}

	toggle(event) {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen,
			anchorEl: event.currentTarget
		});
	}

	redirect(data) {
		if (data === '/account') {
			this.props.actionCreatorSelectItem('myaccount');
		} else if (data.includes('/smart-contracts/edit')) {
			this.props.actionCreatorSelectItem('smartContracts');
		} else if (data.includes('/trading-partners')) {
			this.props.actionCreatorSelectItem('trading-partners');
		}
	}

	render() {
		const data = this.props.notifications === undefined ? [] : this.props.notifications;
		return (
			<span>
				<IconButton color="inherit" onClick={this.toggle}>
					{data.length > 0 ? (
						<Badge badgeContent={data.length} color="error">
							<Mail />
						</Badge>
					) : (
						<Mail />
					)}
				</IconButton>

				<Menu
					anchorEl={this.state.anchorEl}
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					transformOrigin={{ vertical: 'top', horizontal: 'right' }}
					open={this.state.dropdownOpen}
					onClose={this.toggle}
				>
					{data.length > 0 ? (
						Object.keys(data).map(i => (
							<MenuItem
								key={i}
								onClick={e => {
									this.toggle(e);
									this.redirect(data[i].link);
									if (data[i].link) {
										window.location.href = data[i].link
									}
								}}
							>
								{data[i].title}
							</MenuItem>
						))
					) : (
						<MenuItem onClick={this.toggle}>{this.props.defaultMsg}</MenuItem>
					)}
				</Menu>
			</span>
		);
	}
}

NotificationsBox.propTypes = {
	defaultMsg: PropTypes.string
};

NotificationsBox.defaultProps = {
	defaultMsg: 'You have no new messages.'
};

export default NotificationsBox;
