import { call, put } from 'redux-saga/effects';

import NotificationsApi from 'services/api/NotificationsApi';
import awsAmplifyService from 'services/api/awsamplify';
import myAccountApiService from 'services/api/MyAccountApi';

import { push } from 'connected-react-router';
import { loginSuccess, registerFailure, registerSuccess } from '../actions';

// eslint-disable-next-line import/prefer-default-export
export function* registerUser({ payload }) {
	try {
		console.log({ payload });
		const userIp = yield call(myAccountApiService.getUserIp);
		const updatedPayload = {
			...userIp,
			...payload,
			browserInfo: window.navigator.userAgent
		};
		const result = yield call(myAccountApiService.registerUser, updatedPayload);

		console.log({ result });
		if (result && !result.userSub) {
			yield put(
				registerFailure((result.response && result.response.data && result.response.data.message) || result.message || 'Unable to process your registration at this time')
			);
		} else {
			yield put(registerSuccess());
			yield put(loginSuccess('Account created with success!'));

			if (payload.accessType === 'acp') {
				console.log(`'Update this smart contract ${payload.contract} with the new users participant Id`);
			}

			yield put(push('/login'));
		}
	} catch (err) {
		const error = err.message || err;
		yield put(registerFailure(error));
	}
}
