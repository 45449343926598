import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { Tabs, Tab } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import RatesTableCustomToolbarStyle from './style';
import {useTranslation} from "react-i18next";

const RatesTableCustomToolbar = props => {
	const { handleTabSelect, value, classes } = props;
	const { t } = useTranslation(['smartcontracts/edit', 'translations']);


	return (
		<React.Fragment>
			<fieldset>
				<legend>Legend</legend>
				<ul className={classes.legend}>
					<li className={classes.legendLi}>
						<span className={[classes.legendSpan, classes.pending].join(' ')} />
						{t('rates.pending_rates')}
					</li>
					<li className={classes.legendLi}>
						<span className={[classes.legendSpan, classes.declined].join(' ')} />
						{t('rates.declined_rates')}
					</li>
					<li className={classes.legendLi}>
						<span className={[classes.legendSpan, classes.autoaccepted].join(' ')} />
						{t('rates.auto_accepted_rates')}
					</li>
					<li className={classes.legendLi}>
						<span className={[classes.legendSpan, classes.new].join(' ')} />
						{t('rates.new_rates')}
					</li>
					<li className={classes.legendLi}>
						<span className={[classes.legendSpan, classes.selected].join(' ')} />
						{t('rates.current_selected_rates')}
					</li>
				</ul>
			</fieldset>
			<Tabs value={value} onChange={(event, val) => handleTabSelect(val)}>
				<Tab
					label={
						<Tooltip title="Display All Rates">
							<span>{t('rates.all')}</span>
						</Tooltip>
					}
					value="all"
				/>
				<Tab
					label={
						<Tooltip title="Filter Proposed Rates">
							<span>{t('rates.all_changes')}</span>
						</Tooltip>
					}
					value="changedDate"
				/>
				<Tab
					label={
						<Tooltip title="Filter Proposed Rates">
							<span>{t('rates.new_rates')}</span>
						</Tooltip>
					}
					value="newRate"
				/>
				<Tab
					label={
						<Tooltip title="Filter Increased Proposed Rates">
							<span>{t('rates.increased')}</span>
						</Tooltip>
					}
					value="increased"
				/>

				<Tab
					label={
						<Tooltip title="Filter Decreased Proposed Rates">
							<span>{t('rates.decreased')}</span>
						</Tooltip>
					}
					value="decreased"
				/>
				<Tab
					label={
						<Tooltip title="Accepted Rates">
							<span>{t('rates.no_change')}</span>
						</Tooltip>
					}
					value="noChange"
				/>
			</Tabs>
		</React.Fragment>
	);
};

RatesTableCustomToolbar.defaultProps = {
	value: 'all'
};

RatesTableCustomToolbar.propTypes = {
	handleTabSelect: PropTypes.func.isRequired,
	value: PropTypes.oneOf(['all', 'changedDate', 'decreased', 'increased', 'noChange'])
};

export default withStyles(RatesTableCustomToolbarStyle)(RatesTableCustomToolbar);
