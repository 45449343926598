import actions from './actions';

const initialState = {
	smartContracts: [],

	projects: [],
	project: {},
	newSmartContractData: {
		customers: [],
		suppliers: [],
		trafficTypes: [],
		invoicePeriods: [],
		paymentTerms: [],
	},
	smartContract: {},
	data: {},
	loading: false,
	loadingProjects: false,

};

export default (state = initialState, { type, payload, meta }) => {
	switch (type) {
		case actions.SET_STATE:
			return { ...state, ...payload };
		default:
			return state;
	}
};
