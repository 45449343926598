import React, { memo, useCallback, useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@material-ui/core';
import { RenderButton } from 'components';
import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';

export default memo(({ open, openCloseHandler, bankAccountId }) => {
	const dispatch = useDispatch();

	const triggerConfirmDelete = useCallback(() => {
		dispatch({
			type: 'myaccount/DELETE_BANK_ACCOUNT',
			payload: bankAccountId
		});
		openCloseHandler(false);
	}, [bankAccountId]);

	return (
		<Dialog open={open} className={styles.container}>
			<DialogTitle>Are you sure?</DialogTitle>
			<DialogContent>Are you sure you want to delete this bank account ? This process cannot be undone.</DialogContent>
			<DialogActions>
				<RenderButton onClick={openCloseHandler} color="transparent">
					No
				</RenderButton>
				<RenderButton color="primary" onClick={triggerConfirmDelete}>
					Yes, delete bank account
				</RenderButton>
			</DialogActions>
		</Dialog>
	);
});
