import { createTheme } from '@material-ui/core/styles';
import colors from '../variables/colors';
import typography from '../variables/typography';

const customTheme = createTheme({
	spacing: 8,
	palette: {
		primary: {
			light: colors.primary.light,
			main: colors.primary.main,
			dark: colors.primary.dark
		},
		secondary: {
			light: colors.secondary.light,
			main: colors.secondary.main,
			dark: colors.secondary.dark
		},
		error: {
			light: colors.danger.light,
			main: colors.danger.main,
			dark: colors.danger.dark
		},
		danger: {
			light: colors.danger.light,
			main: colors.danger.main,
			dark: colors.danger.dark
		},
		warning: {
			light: colors.warning.light,
			main: colors.warning.main,
			dark: colors.warning.dark
		},
		success: {
			light: colors.success.light,
			main: colors.success.main,
			dark: colors.success.dark
		},
		light: colors.light,
		dark: colors.dark,
		grey: {
			light: colors.grey.light,
			main: colors.grey.main,
			dark: colors.grey.dark
		},
		lightGrey: {
			light: colors.lightGrey.light,
			main: colors.lightGrey.main,
			dark: colors.lightGrey.dark
		},
		border: {
			light: colors.grey.light,
			main: colors.grey.light,
			dark: colors.grey.dark
		},
		divider: colors.grey.light,
		background: { default: colors.bodyBackground }
	},
	typography: {
		// fontFamily: "'Ubuntu Mono', monospace",
		fontWeightLight: typography.weight.light,
		fontWeightRegular: typography.weight.regular,
		fontWeightMedium: typography.weight.semiBold,
		fontWeightBold: typography.weight.bold,
		fontSize: 14,
		h1: {
			fontSize: 24,
			lineHeight: '28px',
			fontWeight: 500,
			margin: '10px 0 10px 0'
		},
		h2: {
			fontSize: 18,
			lineHeight: '24px',
			fontWeight: 500,
			margin: '10px 0 10px 0'
		},
		h3: {
			fontSize: 18,
			lineHeight: '24px',
			fontWeight: 500,
			margin: '10px 0 10px 0'
		},
		h4: {
			fontSize: 18,
			lineHeight: '24px',
			fontWeight: 500,
			margin: '0'
		},
		h5: {
			fontSize: 18,
			lineHeight: '24px',
			fontWeight: 500,
			margin: '0'
		}
	},
	overrides: {
		MuiDropzonePreviewList: {
			root: {
				width: '100%',
				margin:0,
				padding: '10px 0',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}
		},
		MuiDropzoneArea: {
			root: {
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				borderWidth: '1px',
				borderColor: colors.lightGrey.dark
			},
			textContainer: {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center'
			},
			text: {
				fontSize: '16px',
				padding: '10px 0 0 0',
				marginTop: 0,
				marginBottom: 0,
				order: 2,
				'p': {
					marginTop: 0,
					marginBottom: '10px'
				}
			},
			icon: {
				color: colors.primary.main,
				order: 1
			}
		},
		MuiMenuItem: {
			root: {
				'a': {
					color: 'red'
				}
			}
		},
		MuiFormControl: {
			root: {
				margin: '0 0 5px 0',
				'&:last-child': {
					margin: '0'
				}
			}
		},
		MuiFormControlLabel: {
			root: {
				margin: 0
			}
		},
		MuiPaper: {
			root: {
				padding:0,
				background: 'red'
			},
			elevation1: {
				padding: '0 !important',
			}
		},
		MuiCardHeader: {
			root: {
				padding: 16,
				backgroundColor: colors.primary.main,
				color: colors.light,
				borderTopLeftRadius: '4px',
				borderTopRightRadius: '4px'
			},
			subheader: {
				fontSize: '12px',
				lineHeight: '16px',
				color: colors.light,
			}
		},
		MuiCardContent: {
			root: {
				padding: 16,
				'&:last-child': {
					paddingBottom: 16
				}
			}
		},
		MuiTab: {
			root: {
				textTransform: 'capitalize',
				fontSize: '14px',
				'&$selected': {
					background: '#3a755b',
					'&.inactive-tab': {
						background: '#3a755b',
						color: 'inherit'
					}
				}
			}
		},
		MuiTableRow: {
			head: {
				height: 'auto'
			},
			root: {
				height: 'auto'
			}
		},
		MuiTableCell: {
			root: {
				padding: '4px 8px',
				'&:last-child': {
					paddingRight: 8
				}
			}
		},
		MUIDataTable: {
			responsiveScroll: {
				padding: '0 16px'
			}
		},
		MUIDataTableHeadCell: {
			// toolButton: {
			// 	position: 'relative',
			// 	height: 'auto'
			// },
			// sortAction: {
			// 	position: 'absolute',
			// 	transform: 'translateY(-50%)',
			// 	top: '50%',
			// 	right: '-20px',
			// 	height: 'auto'
			// }
		},
		MuiDialog: {
			root: {
				overflow: 'visible'
			},
			paper: {
				minWidth: 300,
				minHeight: 150
			}
		},
		MuiButton: {
			root: {
				fontWeight: 400,
				letterSpacing: '0.05em',
				textTransform: 'capitalize',
				textAlign: 'center',
				// padding: '4px 16px',
				// marginRight: '8px',
				minHeight: 0,
				borderRadius: 3,
				willChange: 'background',
				transition: 'background .2s linear',
				fontSize: '16px',
				lineHeight: '20px',
				borderWidth: '1px',
				borderColor: colors.grey.main,
				borderStyle: 'solid'
			},
			text: {
				padding: '8px 15px'
			},
			// sizeSmall: {
			// 	padding: '5px 10px'
			// },
			textSizeSmall: {
				padding: '5px 8px',
				fontSize: '13px'
			}
		},
		MuiDialogContent: {
			root: {
				overflowY: 'visible'
			}
		},
		// MuiDialogActions: {
		// 	action: {
		// 		margin: '0 8px 0 0'
		// 	}
		// },
		MuiSelect: {
			selectMenu: {
				minHeight: 0
			},
			select: {
				'&:focus': {
					backgroundColor: 'transparent'
				}
			}
		},
		MuiInputLabel: {
			root: {
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				overflow: 'hidden',
				maxWidth: '100%',
				fontSize: 15
			}
		},
		MuiPickersToolbar: {
			toolbar: {
				fontSize: '1.5rem'
			}
		},
		MuiInput: {
			root: {
				width: '100%'
			}
		},
		MuiGrid: {
			item: {
				// padding: '8px'
			},
			container: {
				// margin: '-8px !important'
			}
		},
		MuiAccordionSummary: {
			content: {
				alignItems: 'center'
			}
		}
	}
});

export default customTheme;
