import React, { useContext, useState, memo } from 'react';
import { useSelector } from 'react-redux';
import { FinancialContext } from 'screens/Financials';
import { Grid, Paper, CardContent, Table, TableHead, TableBody, TableRow, TableCell, Typography } from '@material-ui/core';
import { FormattedDate, FormattedTime } from 'react-intl';
import { RenderTransactionAmount, Loader } from 'components';
import { useTranslation } from 'react-i18next';

export default memo(() => {
	const { t } = useContext(FinancialContext);

	const { wallet } = useSelector(state => {
		return state;
	});

	const { WALLET_DATA } = wallet;
	const pendingFunds = WALLET_DATA?.walletData?.pendingFunds;

	return (
		<Grid item xs={12}>
			{(pendingFunds || []).length > 0 && (
				<Paper>
					<CardContent style={{ position: 'relative' }}>
						<Typography variant="h1" gutterBottom>
							{t('pending_transactions.title')}
						</Typography>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>{t('pending_transactions.date')}</TableCell>
									<TableCell>{t('pending_transactions.details')}</TableCell>
									<TableCell>{t('pending_transactions.amount')}</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{pendingFunds.map(({ fiatAmount, createdAt }, i) => {
									const isWithdraw = fiatAmount.indexOf('-') > -1;
									return (
										<TableRow key={i}>
											<TableCell>
												<FormattedDate value={createdAt} day="numeric" month="long" year="numeric" /> <FormattedTime value={createdAt} />
											</TableCell>
											<TableCell>{isWithdraw ? <span>Withdraw request</span> : <span>Deposit request</span>}</TableCell>
											<TableCell>
												<RenderTransactionAmount amount={parseFloat(fiatAmount || 0)} />
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</CardContent>
				</Paper>
			)}
		</Grid>
	);
});
