import React, { memo, useEffect, useState, useCallback } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { RenderFormikSelect, RenderFormikDatePicker2, RenderDateRangePicker, RenderDateRangePickerDialog, RenderButton, CurrencyNumber, RenderTable, RenderFormikInput } from 'components';
import { RadioButtonUnchecked, RadioButtonChecked, SettingsRemote } from '@material-ui/icons';
import { CardContent, Grid, Paper, Chip, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { CreateUpdateTemplate, ConfirmDeleteTemplate } from './components';
import styles from './styles.module.scss';
import { contractIdFormatter, thousandSeparator } from 'utils';

const defaultVisibleColumns = ['connected_calls', 'attempts', 'raw_duration', 'pdd', 'acd', 'asr', 'amount'];

export default memo(({}) => {
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation('reports/reports');

	let defaultAvailableColumns = [
		{ value: 'raw_duration', label: t('AdvancedReportsTab.minutes') },
		{ value: 'attempts', label: t('AdvancedReportsTab.attempts') },
		{ value: 'connected_calls', label: t('AdvancedReportsTab.connected_calls') },
		{ value: 'asr', label: 'ASR' },
		{ value: 'acd', label: 'ACD' },
		{ value: 'pdd', label: 'PDD' },
		{ value: 'amount', label: t('AdvancedReportsTab.amount') }
	];

	const defaultGroupByOptions = [
		{ value: 'contract', label: t('AdvancedReportsTab.smart_contract') },
		// { value: 'customer_name', label: 'Customer' },
		// { value: 'vendor_name', label: 'Supplier' },
		{ value: 'dest_country', label: t('AdvancedReportsTab.dest_country') },
		{ value: 'dest_type', label: t('AdvancedReportsTab.dest_type') },
		{ value: 'dest_carrier', label: t('AdvancedReportsTab.dest_carrier') },
		{ value: 'customer_rate', label: t('AdvancedReportsTab.rate') },
		{ value: 'date', label: t('AdvancedReportsTab.date') },
		{ value: 'hour', label: t('AdvancedReportsTab.hour') }
	];

	const loading = useSelector(state => state.reports && state.reports.loadingFilters);
	const loadingReport = useSelector(state => state.reports && state.reports.loadingAdvancedReports);
	const formFilterData = useSelector(state => (state.reports && state.reports.reportsFilters) || {});
	const advancedReport = useSelector(state => state.reports && state.reports.advancedReport);
	const totalRecords = (advancedReport && advancedReport.metaData && advancedReport.metaData.total) || 0;

	const loadingReportTemplates = useSelector(state => state.reports && state.reports.loadingReportTemplates);
	const reportTemplates = useSelector(state => (state.reports && state.reports.reportTemplates) || []);
	const loadedTemplate = useSelector(state => (state.reports && state.reports.reportTemplate) || {});

	const [createUpdateModalOpen, setCreateUpdateModalOpen] = useState(false);
	const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
	const triggerOpenCloseCreateModal = useCallback(() => setCreateUpdateModalOpen(state => !state));

	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(100);
	const [columns, setColumns] = useState([]);
	const [columnOptions, setColumnOptions] = useState(defaultAvailableColumns);
	const [visibleColumns, setVisibleColumns] = useState(defaultVisibleColumns);
	const [displayExternalPicker, setDisplayExternalPicker] = useState(false)

	const [showPennies, setShowPennies] = useState(false);

	const triggerShowPennies = useCallback(() => {
		setShowPennies(!showPennies);
	});

	const triggerConfirmDeleteTemplate = useCallback(() => {
		setConfirmationModalOpen(true);
	}, []);

	useEffect(() => {
		i18n.on('CHANGE_LANG_EVENT', () => {
			setColumnOptions([
				{ value: 'connected_calls', label: t('AdvancedReportsTab.connected_calls') },
				{ value: 'attempts', label: t('AdvancedReportsTab.attempts') },
				{ value: 'raw_duration', label: t('AdvancedReportsTab.minutes') },
				{ value: 'pdd', label: 'PDD' },
				{ value: 'acd', label: 'ACD' },
				{ value: 'asr', label: 'ASR' },
				{ value: 'amount', label: t('AdvancedReportsTab.amount') }
			]);
			if (advancedReport && advancedReport.data && advancedReport.data[0]) {
				generateColumns([...new Set([...(formik.values.groupBy || []), 'connected_calls', 'attempts', 'raw_duration', 'pdd', 'acd', 'asr', 'amount'])]);
			}
		});
	}, [columnOptions, advancedReport]);

	const formik = useFormik({
		initialValues: {
			date: [
				moment()
					.subtract(2, 'day')
					.toDate(),
				moment().toDate()
			]
		},
		onSubmit: (values, { resetForm }) => {
			setColumns([]);
			setPage(1);
			fetchData(values);
		},
		validationSchema: Yup.object().shape({
			date: Yup.mixed().required(t('AdvancedReportsTab.please_select_date'))
		})
	});

	const fetchData = useCallback(
		values => {
			dispatch({
				type: 'reports/GET_ADVANCED_REPORT',
				payload: {
					filters: {
						...values,
						date: undefined,
						startDate: moment(values.date && values.date[0]).format('YYYY-MM-DD'),
						endDate: moment(values.date && values.date[1]).format('YYYY-MM-DD')
					},
					params: {
						page,
						perPage
					}
				}
			});
		},
		[page, perPage]
	);

	const triggerAddRemoveGrouping = useCallback(
		value => {
			let selected = [...(formik.values.groupBy || [])];
			if (formik && formik.values && formik.values.groupBy && formik.values.groupBy.indexOf(value) > -1) {
				selected = selected.filter(s => s !== value);
			} else {
				selected.push(value);
			}
			formik.setFieldValue('groupBy', selected);
		},
		[formik, JSON.stringify(formik.values)]
	);

	const triggerAddRemoveColumns = useCallback(
		value => {
			let showColumns = [...(visibleColumns || [])];
			if (visibleColumns.indexOf(value) > -1) {
				showColumns = showColumns.filter(s => s !== value);
			} else {
				showColumns.push(value);
			}
			setVisibleColumns(showColumns);
		},
		[visibleColumns, columns]
	);

	const triggerResetFilters = useCallback(() => {
		formik.resetForm();
		setColumnOptions(defaultAvailableColumns);
		setVisibleColumns(defaultVisibleColumns);
	}, []);

	useEffect(() => {
		dispatch({
			type: 'reports/GET_FILTER_DATA',
			payload: formik.values
		});
	}, [formik.values.country, formik.values.destinationType, formik.values.destinationCarrier]);

	useEffect(() => {
		if (formik.values.templateId && formik.values.templateId.length > 0) {
			dispatch({ type: 'reports/GET_REPORT_TEMPLATE', payload: formik.values.templateId });
		} else {
			formik.resetForm();
			dispatch({
				type: 'reports/SET_STATE',
				payload: {
					advancedReportIndexes: []
				}
			});
		}
	}, [formik.values.templateId]);

	useEffect(() => {
		if (loadedTemplate && Object.keys(loadedTemplate).length) {
			formik.setValues(loadedTemplate);
		}
	}, [loadedTemplate]);

	useEffect(() => {
		formik.setFieldValue('customer', null);
		formik.setFieldValue('supplier', null);
	}, [formik.values.smartContract]);

	useEffect(() => {
		formik.setFieldValue('supplier', null);
		formik.setFieldValue('smartContract', null);
	}, [formik.values.customer]);

	useEffect(() => {
		formik.setFieldValue('customer', null);
		formik.setFieldValue('smartContract', null);
	}, [formik.values.supplier]);

	// reset destination type and destination carrier when country changes
	useEffect(() => {
		if (!formik.values.templateId) {
			formik.setFieldValue('destinationType', null);
			formik.setFieldValue('destinationCarrier', null);
		}
	}, [formik.values.country]);

	useEffect(() => {
		dispatch({ type: 'reports/GET_FILTER_DATA' });
		dispatch({ type: 'reports/GET_REPORT_TEMPLATES' });
		dispatch({
			type: 'reports/SET_STATE',
			payload: {
				advancedReport: {
					data: [],
					metaData: {
						total: 0,
						page: 1,
						perPage: 1
					}
				}
			}
		});
		return () => {
			formik.resetForm();
		};
	}, []);

	const generateColumns = useCallback(columns => {
		setColumns(
			columns.map(receivedColumn => {
				switch (receivedColumn) {
					case 'contract':
						return {
							key: receivedColumn,
							Header: <div className="rt-text-right">{t('AdvancedReportsTab.smart_contract')}</div>,
							className: 'rt-text-right',
							accessor: receivedColumn,
							Cell: ({ original }) => {
								return (
									<strong>
										{original.partner} - {contractIdFormatter(original.contract, original.contractName)}
									</strong>
								);
							}
						};
					case 'orig_number':
						return {
							key: receivedColumn,
							Header: <div className="rt-text-right">{t('AdvancedReportsTab.dialing_number')}</div>,
							className: 'rt-text-right',
							accessor: receivedColumn
						};
					case 'translated_number':
						return {
							key: receivedColumn,
							Header: <div className="rt-text-right">{t('AdvancedReportsTab.dialed_number')}</div>,
							className: 'rt-text-right',
							accessor: receivedColumn
						};
					case 'raw_duration':
						return {
							key: receivedColumn,
							Header: <div className="rt-text-right">{t('AdvancedReportsTab.minutes')}</div>,
							className: 'rt-text-right',
							accessor: receivedColumn,
							Cell: ({ value }) => {
								return thousandSeparator(value && parseFloat(value / 60).toFixed(0));
							},
							Footer: ({ data }) => {
								const total = parseFloat(
									(data || []).reduce(
										(res, cur) =>
											parseFloat(res) +
											(isNaN(parseFloat(cur[receivedColumn] ? cur[receivedColumn] : 0)) ? 0 : parseFloat(cur[receivedColumn] ? cur[receivedColumn] : 0)),
										0
									) / 60
								).toFixed(0);
								return thousandSeparator(total);
							}
						};
					case 'attempts':
						return {
							key: receivedColumn,
							Header: <div className="rt-text-right">{t('attempts')}</div>,
							className: 'rt-text-right',
							accessor: receivedColumn,
							Cell: ({ value }) => {
								return thousandSeparator(value);
							},
							Footer: ({ data }) => {
								const total = (data || []).reduce(
									(res, cur) =>
										parseFloat(res) +
										(isNaN(parseFloat(cur[receivedColumn] ? cur[receivedColumn] : 0)) ? 0 : parseFloat(cur[receivedColumn] ? cur[receivedColumn] : 0)),
									0
								);
								return thousandSeparator(total);
							}
						};
					case 'connected_calls':
						return {
							key: receivedColumn,
							Header: <div className="rt-text-right">{t('AdvancedReportsTab.connected_calls')}</div>,
							className: 'rt-text-right',
							accessor: receivedColumn,
							Cell: ({ value }) => {
								return thousandSeparator(value);
							},
							Footer: ({ data }) => {
								const total = (data || []).reduce(
									(res, cur) =>
										parseFloat(res) +
										(isNaN(parseFloat(cur[receivedColumn] ? cur[receivedColumn] : 0)) ? 0 : parseFloat(cur[receivedColumn] ? cur[receivedColumn] : 0)),
									0
								);
								return thousandSeparator(total);
							}
						};
					case 'customer_rate':
						return {
							key: receivedColumn,
							Header: <div className="rt-text-right">{t('AdvancedReportsTab.customer_rate')}</div>,
							className: 'rt-text-right',
							accessor: receivedColumn
						};
					case 'amount':
						return {
							key: receivedColumn,
							Header: <div className="rt-text-right">{t('AdvancedReportsTab.amount')}</div>,
							className: 'rt-text-right',
							accessor: receivedColumn,
							Cell: ({ value }) => {
								return <CurrencyNumber amount={(value && parseFloat(value || 0)) || 0} precision={showPennies ? 2 : 0} />;
							},
							Footer: ({ data }) => {
								const total = (data || []).reduce((res, cur) => res + parseFloat(cur[receivedColumn] || 0), 0);
								return <CurrencyNumber amount={total} precision={showPennies ? 2 : 0} />;
							}
						};
					case 'pdd':
						return {
							key: receivedColumn,
							Header: <div className="rt-text-right">{'PDD'}</div>,
							className: 'rt-text-right',
							accessor: receivedColumn,
							Cell: ({ value }) => {
								return (value && parseFloat(value || 0).toFixed(2)) || 0;
							}
						};
					case 'acd':
						return {
							key: receivedColumn,
							Header: <div className="rt-text-right">{'ACD'}</div>,
							className: 'rt-text-right',
							accessor: receivedColumn,
							Cell: ({ value }) => {
								return (value && parseFloat(value || 0).toFixed(2)) || 0;
							}
						};
					case 'asr':
						return {
							key: receivedColumn,
							Header: <div className="rt-text-right">{'ASR'}</div>,
							className: 'rt-text-right',
							accessor: receivedColumn,
							Cell: ({ value }) => {
								return (value && parseFloat(value || 0).toFixed(2)) || 0;
							}
						};
					default:
						return {
							key: receivedColumn,
							Header: receivedColumn.replace('_', ' '),
							accessor: receivedColumn
						};
				}
			})
		);
	});

	useEffect(() => {
		// TODO we did this change to be able to detect language changes and fix that bug.
		if (advancedReport && advancedReport.data && advancedReport.data[0]) {
			generateColumns([...new Set([...(formik.values.groupBy || []), 'connected_calls', 'attempts', 'raw_duration', 'pdd', 'acd', 'asr', 'amount'])]);
		}
	}, [advancedReport, showPennies]);

	useEffect(() => {
		const currentColumns = columns.map(column => {
			const show = visibleColumns.findIndex(col => col === column.key) > -1;
			return {
				...column,
				show
			};
		});
		console.log(currentColumns);
		setColumns(currentColumns);
	}, [visibleColumns]);

	useEffect(() => {
		const extra = (formik.values.groupBy || []).reduce((res, group) => {
			const groupOption = defaultGroupByOptions.find(el => el.value === group);
			if (groupOption) {
				res.push(groupOption);
			}
			return res;
		}, []);

		setColumnOptions([...extra, ...defaultAvailableColumns]);

		const extraColumnNames = extra.reduce((res, cur) => [...res, cur.value], []);

		setVisibleColumns(state => [...extraColumnNames, ...state]);
	}, [formik.values.groupBy]);

	useEffect(() => {
		fetchData(formik.values);
	}, [page, perPage]);

	return (
		<div className={styles.container}>
			<form onSubmit={formik.handleSubmit}>
				<Grid container spacing={2} justifyContent="flex-end" style={{ marginBottom: '20px' }}>
					<Grid item style={{ paddingTop: '18px' }}>
						{(formik.values.templateId && (
							<RenderButton
								color="danger"
								onClick={() => {
									triggerConfirmDeleteTemplate();
								}}
							>
								{t('AdvancedReportsTab.delete_template')}
							</RenderButton>
						)) ||
							null}
					</Grid>
					<Grid item style={{ paddingTop: '18px' }}>
						<RenderButton color="primary" onClick={triggerOpenCloseCreateModal}>
							{(!!formik.values.templateId && formik.values.templateId.length && t('AdvancedReportsTab.update_template')) || t('AdvancedReportsTab.save_template')}
						</RenderButton>
					</Grid>
					<Grid item xs={2}>
						<RenderFormikSelect
							disabled={loadingReportTemplates || loading}
							label={t('AdvancedReportsTab.saved_templates')}
							name="templateId"
							formik={formik}
							options={[
								{ value: '', label: t('AdvancedReportsTab.new_template') },
								...((reportTemplates && Array.isArray(reportTemplates) && reportTemplates) || []).map(template => ({ value: template._id, label: template.name }))
							]}
						/>
					</Grid>
				</Grid>

				<Paper>
					<CardContent>
						<Grid container spacing={1} style={{ marginBottom: '20px' }}>
							<fieldset>
								<legend>Date picker</legend>
								<Grid container spacing={1} justifyContent="flex-start">
									<Grid item>
										<RenderFormikDatePicker2
											disableAction={true}
											disableFuture
											showTodayButton
											setDisplayExternalPicker={setDisplayExternalPicker}
											startText={t('AdvancedReportsTab.start_date')}
											endText={t('AdvancedReportsTab.end_date')}
											name="date"
											formik={formik}
										/>
									</Grid>
									<Grid item>
										<RenderDateRangePickerDialog
										 open={displayExternalPicker}
										 setDisplayExternalPicker={setDisplayExternalPicker}
										 dateRangePicker={
										 <RenderDateRangePicker 
											value={formik.values.date} 
											setDisplayExternalPicker={setDisplayExternalPicker}
											name="date" 
											formik={formik} />
											}
										 />											
									</Grid>
								</Grid>
							</fieldset>
						</Grid>
						<Grid container spacing={1} style={{ marginBottom: '20px' }}>
							<fieldset>
								<legend>{t('AdvancedReportsTab.group_by')}</legend>
								<Grid container spacing={1}>
									{(defaultGroupByOptions || []).map((group, index) => {
										return (
											<Grid item key={index}>
												<Chip
													size="medium"
													label={group.label}
													onClick={() => triggerAddRemoveGrouping(group.value)}
													// onDelete={() => this.triggerAddRemoveSmartContracts(contract._id)}
													color={((formik.values.groupBy || []).indexOf(group.value) > -1 && 'primary') || undefined}
													icon={((formik.values.groupBy || []).indexOf(group.value) > -1 && <RadioButtonChecked />) || <RadioButtonUnchecked />}
												/>
											</Grid>
										);
									})}
								</Grid>
							</fieldset>
						</Grid>
						<Grid container spacing={1} style={{ marginBottom: '20px' }}>
							<fieldset>
								<legend>{t('AdvancedReportsTab.columns')}</legend>
								<Grid container spacing={1}>
									{(columnOptions || []).map((column, index) => (
										<Grid item key={index}>
											<Chip
												size="medium"
												label={column.label}
												onClick={() => triggerAddRemoveColumns(column.value)}
												color={(visibleColumns.indexOf(column.value) > -1 && 'primary') || undefined}
												icon={(visibleColumns.indexOf(column.value) > -1 && <RadioButtonChecked />) || <RadioButtonUnchecked />}
											/>
										</Grid>
									))}
								</Grid>
							</fieldset>
						</Grid>
						<Grid container spacing={1} style={{ marginBottom: '20px', display: 'block' }}>
							<fieldset>
								<legend>Filters</legend>
								<Grid container spacing={1}>
									<Grid item xs={12} md={4}>
										<RenderFormikSelect
											label={t('customer')}
											name="customer"
											disabled={loading}
											formik={formik}
											options={(formFilterData && formFilterData.customers) || []}
										/>
									</Grid>
									<Grid item xs={12} md={4}>
										<RenderFormikSelect
											label={t('supplier')}
											name="supplier"
											disabled={loading}
											formik={formik}
											options={(formFilterData && formFilterData.suppliers) || []}
										/>
									</Grid>

									<Grid item xs={12} md={4}>
										<RenderFormikSelect
											label={t('smart_contract')}
											name="smartContract"
											disabled={loading}
											formik={formik}
											options={(formFilterData && formFilterData.contracts) || []}
										/>
									</Grid>
								</Grid>
								<Grid container spacing={1}>
									<Grid item xs={12} md={4}>
										<RenderFormikSelect
											label={t('country')}
											name="country"
											disabled={loading}
											formik={formik}
											options={(formFilterData && formFilterData.countries) || []}
										/>
									</Grid>
									<Grid item xs={6} md={4}>
										<RenderFormikSelect
											label={t('destination_type')}
											name="destinationType"
											formik={formik}
											disabled={loading || !formik.values.country}
											options={((formFilterData && formFilterData.destTypes) || []).map(el => ({
												value: el,
												title: el
											}))}
										/>
									</Grid>
									<Grid item xs={12} md={4}>
										<RenderFormikSelect
											label={t('destination_carrier')}
											name="destinationCarrier"
											formik={formik}
											disabled={loading || !formik.values.country || !formik.values.destinationType}
											options={((formFilterData && formFilterData.destCarriers) || []).map(el => ({
												value: el,
												title: el
											}))}
										/>
									</Grid>
								</Grid>
								<Grid container spacing={1} style={{ marginBottom: '40px' }}>
									<Grid item xs={12} md={4}>
										<RenderFormikInput label={t('AdvancedReportsTab.dialing_number')} name="dialingNumber" disabled={loading} formik={formik} />
									</Grid>
									<Grid item xs={12} md={4}>
										<RenderFormikInput label={t('AdvancedReportsTab.dialed_number')} name="dialedNumber" disabled={loading} formik={formik} />
									</Grid>
								</Grid>
							</fieldset>
						</Grid>

						<Grid container spacing={2} justifyContent="flex-end" alignItems="center">
							{/*<Grid item>*/}
							{/*	<RenderFormikCheckbox block formik={formik} name="groupSubtotal" label="Show subtotals" labelPlacement="Show subtotals" />*/}
							{/*</Grid>*/}
							<Grid item>
								<Chip
									size="large"
									label="Pennies"
									onClick={triggerShowPennies}
									color={(showPennies && 'primary') || undefined}
									icon={(showPennies && <RadioButtonChecked />) || <RadioButtonUnchecked />}
								/>
							</Grid>
							<Grid item>
								<RenderButton block color="default" onClick={triggerResetFilters}>
									{t('AdvancedReportsTab.reset_filters')}
								</RenderButton>
							</Grid>
							<Grid item>
								<RenderButton block color="primary" onClick={formik.handleSubmit}>
									{t('run_report')}
								</RenderButton>
							</Grid>
						</Grid>
					</CardContent>
				</Paper>
			</form>

			<Paper style={{ marginTop: '20px' }}>
				<CardContent>
					<RenderTable
						resizable
						data={(advancedReport && advancedReport.data && Array.isArray(advancedReport.data) && advancedReport.data) || []}
						style={{ width: '100%' }}
						columns={columns}
						loading={loadingReport}
						minRows={2}
						manual
						showPagination
						tableTotal={totalRecords}
						pages={Math.ceil(totalRecords / perPage)}
						onPageChange={page => setPage(page + 1)}
						defaultPageSize={perPage}
						onPageSizeChange={pageSize => setPerPage(pageSize)}
						sortable={false}
						filterable={false}
					/>
				</CardContent>
			</Paper>

			{(createUpdateModalOpen && (
				<CreateUpdateTemplate open openCloseHandler={triggerOpenCloseCreateModal} initialValues={formik.values} update={!!formik.values.templateId} />
			)) ||
				null}

			{(confirmationModalOpen && (
				<ConfirmDeleteTemplate open={confirmationModalOpen} openCloseHandler={() => setConfirmationModalOpen(false)} templateId={formik.values.templateId} />
			)) ||
				null}
		</div>
	);
});
