import React from 'react';
import { PrivateTemplate, BreadCrumb } from 'components';
import SmsScreen from 'screens/Sms/list'
import { useTranslation } from 'react-i18next';

const SmsPage = props => {
	const { t } = useTranslation();
	return (
		<PrivateTemplate>
			<BreadCrumb name="Sms" firstLevel="Home" secondLevel="Sms" />
			<SmsScreen {...props} />
		</PrivateTemplate>
	);
};

export default SmsPage;
