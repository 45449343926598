export const getSmartContracts = (state = {}) =>
  state.SMARTCONTRACTS_LIST || [];

const initialSmartContractRouteData = {
  connectionSettings: {
    customerName: "",
    supplierName: "",
    switchIp: "",
    prefix: ""
  },
  hardwareProfiles: [],
  routes: [],
  smartContracts: []
};

const initialSmartContractRouteDataFilters = {
  customers: [],
  suppliers: [],
  countries: [],
  destTypes: [],
  destCarriers: [],
  contracts: []
};

export const getSmartContractsRouteData = (state = {}) =>
  state.SMARTCONTRACTS_ROUTE_DATA || initialSmartContractRouteData;

export const getSmartContractsRouteDataFilters = (state = {}) =>
  state.ROUTES_SMARTCONTRACTS_LIST_FILTERS ||
  initialSmartContractRouteDataFilters;

export const VENDOR_RATE_PLAN_UPLOAD_DATA = "VENDOR_RATE_PLAN_UPLOAD_DATA";
export const VENDOR_RATES_DATA = "VENDOR_RATES_DATA";

export const vendorRatePlanUploadData = {};
export const initialVendorRatePlanData = {
  data: [],
  file: undefined,
  loading: false
};

export const getVendorRatePlanUploadState = (
  state = vendorRatePlanUploadData
) => state[VENDOR_RATE_PLAN_UPLOAD_DATA] || vendorRatePlanUploadData;

export const getVendorRatesDataState = (state = initialVendorRatePlanData) =>
  state[VENDOR_RATES_DATA] || initialVendorRatePlanData;

export const EXTERNAL_RATE_PLAN_UPLOAD_DATA = "EXTERNAL_RATE_PLAN_UPLOAD_DATA";
export const EXTERNAL_RATES_DATA = "EXTERNAL_RATES_DATA";
export const externalRatePlanUploadData = {};
export const initialExternalRatePlanUploadData = {
  data: [],
  file: undefined,
  loading: false
};

export const getExternalRatePlanUploadState = (
  state = externalRatePlanUploadData
) => state[EXTERNAL_RATE_PLAN_UPLOAD_DATA] || externalRatePlanUploadData;

export const getExternalRatesDataState = (
  state = initialExternalRatePlanUploadData
) => state[EXTERNAL_RATES_DATA] || initialExternalRatePlanUploadData;

const initialExternalInvoiceData = {};
export const EXTERNAL_INVOICE_DATA = "EXTERNAL_INVOICE_DATA";
export const getExternalInvoiceDataState = (
  state = initialExternalInvoiceData
) => state[EXTERNAL_INVOICE_DATA] || initialExternalInvoiceData;
