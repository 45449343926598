import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/contracts/actions';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Chip, CardContent, Typography, Divider, Box } from '@material-ui/core';
import { ExpandMore, Send, ThumbDown, ThumbUp } from '@material-ui/icons';
import { capitalizeWord, colorStatusText, contractIdFormatter, handleCurrency, thousandSeparator } from 'utils';
import { RenderButton, RenderTable, RenderBadge } from 'components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styles from './style.module.scss';
import { dummyOrders } from 'screens/AcpSmartContracts/dummyData';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Add from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import { InvoiceModal } from './components/InvoiceModal';
import Loader from 'components/atoms/Loader';
import { set } from 'date-fns';
import { columns } from './columns';
import { useAccordion } from './hooks/useAccordion';

export default memo(() => {
	const { t } = useTranslation('routing/routing');

	const { loading } = useSelector(state => state.escrowmanaging || {});
	const { modalOpened, selectedPof, setModalOpened, selectedSmartContract, setSelectedSmartContract, smartContracts, handleStatusChange, setSelectedPof } = useAccordion();

	return (
		<>
			<InvoiceModal
				modalOpened={modalOpened}
				handleToggleModal={() => setModalOpened(!modalOpened)}
				selectedSmartContract={selectedSmartContract}
				selectedPof={selectedPof}
			/>
			{loading.contracts ? (
				<Loader />
			) : (
				<div className={styles.container}>
					{smartContracts.length > 0 ? (
						smartContracts.map(sc => {
							const { scQty, pof: pofs, cost } = sc || {};

							const itemsCalculations = pofs?.reduce(
								(acc, pof) => {
									if (pof.status === 'under-review') {
										acc.underReviewItems += parseInt(pof.deliveredQty);
										acc.underReviewTotalValue += parseInt(pof.deliveredQty) * parseInt(pof.itemPrice);
									}

									if (pof.status === 'approved') {
										acc.deliveredItems += parseInt(pof.deliveredQty);
									}

									if (pof.status !== 'rejected') {
										acc.blockedItems += parseInt(pof.deliveredQty);
									}

									return acc;
								},
								{ underReviewItems: 0, underReviewTotalValue: 0, deliveredItems: 0, blockedItems: 0 }
							);

							const remainingItems = scQty - itemsCalculations.deliveredItems;

							return (
								<Accordion key={sc._id} className="accordion">
									<AccordionSummary className="order-summary" expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
										<div className="status" style={colorStatusText(sc.status, 'backgroundColor')}>
											{sc?.status.toUpperCase()}
										</div>
										<div className="order-id">
											<Typography variant="caption" className="label">
												Smart Contract ID
											</Typography>
											<Typography>{sc.smartContractRef}</Typography>
										</div>
										<Grid container spacing={2}>
											<Grid item xs={4}>
												<Typography variant="caption" className="label">
													Partner
												</Typography>
												<Typography>{sc.ownerParticipant.participantName}</Typography>
											</Grid>
											<Grid className="centered" item xs={1}>
												<Typography variant="caption" className="label">
													Quantity
												</Typography>
												<Typography>{sc.scQty}</Typography>
											</Grid>
											<Grid className="divider-container" item xs={2}>
												<span className="divider" />
												<div className="centered">
													<Typography variant="caption" className="label">
														Escrow balance
													</Typography>
													<Typography variant="h6">{handleCurrency((scQty - itemsCalculations.deliveredItems) * cost, 'USD')}</Typography>
												</div>
												<span className="divider" />
											</Grid>
											<Grid className="centered" item xs={2}>
												<Typography variant="caption" className="label">
													Delivered Items
												</Typography>
												<Typography className="large-text">{itemsCalculations.deliveredItems}</Typography>
												<Typography variant="caption" className="label">
													{`${remainingItems} Remaining`}
												</Typography>
											</Grid>
											<Grid className="divider-container" item xs={2}>
												<span className="divider" />
												<div className="centered">
													<Typography variant="caption" className="label">
														Items Under Review
													</Typography>
													<Typography className="large-text">{itemsCalculations.underReviewItems}</Typography>
													<Typography variant="caption" className="label">
														{handleCurrency(itemsCalculations.underReviewTotalValue, 'USD')}
													</Typography>
												</div>
												<span className="divider" />
											</Grid>
										</Grid>
									</AccordionSummary>
									<AccordionDetails className="order-details">
										{sc.status === 'pending' || sc.status === 'rejected' ? (
											<div className="acceptance-card">
												<Typography variant="h6" className="label">
													Do you want to accept this proposal from
												</Typography>
												<Typography>{sc.ownerParticipant.participantName}</Typography>

												<div className="proposal-info-container">
													{loading.orders && <Loader loading={true} fullscreen />}
													<div className="row">
														<Typography className="label">Creation Date</Typography>
														<Typography className="right-aligned-cell dark-text">{moment(sc.createdAt).format('MMM Do YY')}</Typography>
													</div>
													<div className="row">
														<Typography className="label">Due Date</Typography>
														<Typography className="right-aligned-cell dark-text">{moment(sc.dueDate).format('MMM Do YY')}</Typography>
													</div>
													<div className="row">
														<Typography className="label">Unit cost</Typography>
														<Typography className="right-aligned-cell dark-text">{sc.cost}</Typography>
													</div>
													<div className="row">
														<Typography className="label">Required quantity</Typography>
														<Typography className="right-aligned-cell dark-text">{sc.scQty}</Typography>
													</div>
													<div className="row">
														<Typography className="label">Total value</Typography>
														<Typography className="right-aligned-cell dark-text">{handleCurrency(sc.cost * sc.scQty, 'USD')}</Typography>
													</div>
												</div>
												{sc.status !== 'rejected' && (
													<div className="button-container">
														<RenderButton
															className="button"
															color="danger"
															startIcon={<ThumbDown />}
															onClick={() => handleStatusChange(sc._id, 'rejected')}
														>
															Reject
														</RenderButton>
														<RenderButton
															className="button"
															color="primary"
															startIcon={<ThumbUp />}
															onClick={() => handleStatusChange(sc._id, 'active')}
														>
															Accept
														</RenderButton>
													</div>
												)}
											</div>
										) : (
											<>
												<div className="accordion-details-buttons">
													{itemsCalculations.blockedItems !== scQty && (
														<RenderButton
															className="button"
															color="primary"
															startIcon={<Send />}
															onClick={() => {
																setModalOpened(true);
																setSelectedSmartContract(sc);
															}}
														>
															Submit invoice
														</RenderButton>
													)}
												</div>
												<RenderTable
													className="space-top"
													data={sc.pof || []}
													columns={columns({ scId: sc._id, setSelectedPof })}
													showPagination={false}
													sortable={false}
													minRows={2}
													noDataText={
														<div>
															<strong>There are no smart contracts associated with this partner</strong>
														</div>
													}
												/>
											</>
										)}
									</AccordionDetails>
								</Accordion>
							);
						})
					) : (
						<>
							<Box className="no-sc-container">
								<Typography variant="h6">You haven't been invited as a partner to a smart contract yet.</Typography>
							</Box>
						</>
					)}
				</div>
			)}
		</>
	);
});
