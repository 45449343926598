import React from "react";
import { Field, reduxForm, reset } from "redux-form";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createValidator,
  required,
  validateMaxValueParam
} from "services/validation";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Slide,
  Typography,
  FormHelperText
} from "@material-ui/core";
import { RenderButton, RenderInput } from "components";
import styles from "./style";

const Transition = props => <Slide direction="up" {...props} />;

class CustomerPayInvoiceModal extends React.Component {
  render() {
    const {
      open,
      classes,
      openCloseHandler,
      handleSubmit,
      availableWalletAmount,
      initialValues: { amount }
    } = this.props;

    return (
      <Dialog
        open={open}
        onBackdropClick={openCloseHandler}
        TransitionComponent={Transition}
        maxWidth="sm"
      >
        <DialogTitle>Pay Invoice</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container>
              {parseInt(availableWalletAmount) > 0 ? (
                <Grid item xs={12}>
                  <p>
                    ${amount ? amount.toFixed(2) : 0} will be removed from you
                    wallet to pay this invoice.
                  </p>
                  <p style={{ textAlign: "right" }}>
                    <small>
                      Wallet Balance: $
                      {availableWalletAmount
                        ? availableWalletAmount.toFixed(2)
                        : 0}
                    </small>
                  </p>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Typography align="center">
                    You are not able to pay this invoice, because you don't have
                    enough funds in your wallet
                  </Typography>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            {parseInt(availableWalletAmount) > 0 ? (
              <RenderButton color="primary" type="submit">
                Pay Invoice
              </RenderButton>
            ) : (
              ""
            )}
            <RenderButton color="danger" onClick={openCloseHandler}>
              Cancel
            </RenderButton>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

CustomerPayInvoiceModal.defaultProps = {
  contractData: {
    contractTerms: {
      contractId: "",
      supplier: "",
      customer: "",
      paymentTerms: "",
      settlementPeriod: "",
      billingTimezone: "",
      contractEndDate: ""
    }
  }
};

const validate = createValidator({
  amount: [required, validateMaxValueParam]
});

CustomerPayInvoiceModal = withStyles(styles)(CustomerPayInvoiceModal);

export default reduxForm({
  form: "CustomerPayInvoiceModalForm",
  enableReinitialize: true,
  touchOnBlur: true,
  validate
})(CustomerPayInvoiceModal);
