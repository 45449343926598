import React from 'react';
import { Redirect } from 'react-router-dom';

import { Card, CardHeader, CardContent, CardActions, Grid, InputAdornment, IconButton, Typography, TextField } from '@material-ui/core';
import { Email } from '@material-ui/icons';

import { RenderButton, Loader, RenderNotification, RenderFormikInput } from 'components';
import useFormValues from './hooks/useFormValues';
import { styles } from './styles';

export default () => {
	const { formik, email, errorMessage, successMessage, loading } = useFormValues();

	return (
		<form style={styles.form} onSubmit={formik.handleSubmit}>
			{loading && <Loader fullscreen />}
			<RenderNotification variant="danger" floating open={errorMessage !== null} message={errorMessage} />
			<RenderNotification variant="success" floating open={successMessage !== null} message={successMessage} />
			{email === null && <Redirect to="/login" />}
			<Card style={styles.root}>
				<CardHeader subheader={<img src="/images/logo-light-text.png" alt="Logo TessPay" />} style={styles.cardHeader} />
				<CardContent>
					<Typography variant="body2" align="center" style={{ marginBottom: 20 }}>
						As this is the first time you login, you must change your temporary password.
					</Typography>
					<TextField
						label="Email"
						disabled
						style={styles.field}
						value={email || ''}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton disabled>
										<Email />
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
					<RenderFormikInput peek type="password" formik={formik} name="password" label={'New password'} />
					<RenderFormikInput peek type="password" formik={formik} name="passwordConfirmation" label={'New confirm Password'} />
				</CardContent>
				<CardActions>
					<Grid container justify="center" alignItems="center">
						<Grid item>
							<RenderButton type="submit" color="primary">
								Update
							</RenderButton>
						</Grid>
					</Grid>
				</CardActions>
			</Card>
		</form>
	);
};
