import React, { Component } from "react";
import { Util } from "components";

const BiddingSupplier = ({ supplier, processAcceptOpenOrder }) => {
  return (
    <tr
      className="order-row"
      data-id={supplier.id}
      key={"key" + supplier.id}
      onClick={() => processAcceptOpenOrder(supplier.id)}
    >
      <td>
        {!Util.isEmpty(supplier.paymentTerms) &&
        String(supplier.paymentTerms).length > 0
          ? supplier.paymentTerms + "net" + supplier.paymentTermsNet
          : supplier.maximumPaymentTerms}
      </td>
      <td>5</td>
      <td>{supplier.dailyAllocatedAmount}</td>
      <td>
        {Util.isEmpty(supplier.paymentTerms)
          ? Util.getMaximumPaymentTerms(supplier)
          : supplier.paymentTerms * supplier.dailyAllocatedAmount}
      </td>
      <td>{supplier.interestRate}</td>
    </tr>
  );
};

export default BiddingSupplier;
