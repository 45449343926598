import React from 'react';
import { MuiPickersUtilsProvider, DateTimePicker } from 'material-ui-pickers';
import {KeyboardArrowLeft, KeyboardArrowRight, NavigateBefore, NavigateNext} from '@material-ui/icons';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import {
	FormHelperText,
	FormControl, InputAdornment, IconButton,
} from "@material-ui/core";
import {change} from "redux-form";

const RenderFormikDateTimePicker = ({
										formik,
										name,
										onChange,
										label,
										autoOk,
										dateFormat,
										emptyLabel,
										views,
										disablePast,
										disableFuture,
										clearable,
										ampm,
										...rest
									}) => {
	if (!name || !formik) {
		console.log('FORMIK RenderFormikDateTimePicker ERORR!: name or formik required', { name, formik });
		return '';
	}

	let { error: fieldError, touched: fieldTouched, value: fieldValue } = formik.getFieldMeta(name);
	let fieldProps = formik.getFieldProps(name);

	const hasError = (!!fieldError && fieldTouched && true) || false;

	const customMoment = moment.tz.setDefault('UTC');

	const incrementDays = e => {
		e.preventDefault();
		e.stopPropagation();

		if (
			moment(fieldValue).isValid() &&
			(!disableFuture ||
				(disableFuture && moment(fieldValue).add("1", "day") <= moment()))
		) {
			formik.setFieldValue(name, moment(fieldValue).add("1", "day"));
			if (onChange && typeof onChange === 'function') onChange(moment(fieldValue).add("1", "day"));
		}
		return false;
	};

	const decrementDays = e => {
		e.preventDefault();
		e.stopPropagation();

		if (
			moment(fieldValue).isValid() &&
			(!disablePast ||
				(disablePast &&
					moment(value)
						.utc()
						.subtract("1", "day") >= moment()))
		) {
			formik.setFieldValue(name, moment(fieldValue).subtract("1", "day"));
			if (onChange && typeof onChange === 'function') onChange(moment(fieldValue).subtract("1", "day"));
		}
	};

	return (
		<FormControl error={hasError} fullWidth>
			<MuiPickersUtilsProvider utils={MomentUtils} moment={customMoment}>
				<DateTimePicker
					name={name}
					leftArrowIcon={<KeyboardArrowLeft color="primary" />}
					rightArrowIcon={<KeyboardArrowRight color="primary" />}
					ampm={ampm || false}
					label={label}
					format={dateFormat}
					disablePast={disablePast}
					disableFuture={disableFuture}
					autoOk={autoOk || true}
					emptyLabel={emptyLabel}
					clearable={clearable}
					views={views}
					{...fieldProps}
					{...rest}
					value={fieldValue && moment(fieldValue).isValid() ? moment(fieldValue).utc() : null}
					onChange={e => {
						formik.setFieldValue(name, moment(e).utc());
						if (onChange && typeof onChange === 'function') onChange(e);
					}}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start" onClick={decrementDays}>
								<IconButton>
									<NavigateBefore />
								</IconButton>
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position="end" onClick={incrementDays}>
								<IconButton>
									<NavigateNext />
								</IconButton>
							</InputAdornment>
						)
					}}
				/>
			</MuiPickersUtilsProvider>
			{hasError && <FormHelperText>{fieldError}</FormHelperText>}
		</FormControl>
	);
};

export default RenderFormikDateTimePicker;
