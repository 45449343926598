import React, { useContext } from 'react';
import { FinancialContext } from 'screens/Financials';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { Checkbox, Chip, Box } from '@material-ui/core';
import moment from 'moment';
import { CurrencyNumber } from 'components';
import colors from 'assets/variables/colors';
import { contractIdFormatter } from '../../../../../../../utils';

export default (clientType) => {
	const { t } = useContext(FinancialContext);

	const originationColumns = [
		{
			columns: [
				{
					expander: true,
					width: 65,
					Expander: ({ isExpanded }) => <div>{isExpanded ? <KeyboardArrowDown color="primary" /> : <KeyboardArrowRight color="primary" />}</div>,
					style: {
						cursor: 'pointer',
						fontSize: 25,
						padding: '0',
						textAlign: 'center',
						userSelect: 'none'
					}
				}
			]
		},
		{
			Header: t('invoices.table.start_date'),
			accessor: 'startDate',
			Cell: props => moment(props.value, 'MM-DD-YYYY').format('MMM DD YYYY'),
			maxWidth: 100,
			sortMethod: (a, b) => {
				if (moment(a, 'MM-DD-YYYY').isSame(moment(b, 'MM-DD-YYYY'))) {
					return 0;
				}
				return moment(a, 'MM-DD-YYYY').isSameOrAfter(moment(b, 'MM-DD-YYYY')) ? 1 : -1;
			}
		},
		{
			Header: t('invoices.table.end_date'),
			accessor: 'endDate',
			Cell: props => (props.value === '' ? 'Open' : moment(props.value, 'MM-DD-YYYY').format('MMM DD YYYY')),
			maxWidth: 100,
			sortMethod: (a, b) => {
				if (a === 'Open' || b === 'Open') {
					return 0;
				}
				if (moment(a, 'MM-DD-YYYY').isSame(moment(b, 'MM-DD-YYYY'))) {
					return 0;
				}
				return moment(a, 'MM-DD-YYYY').isSameOrAfter(moment(b, 'MM-DD-YYYY')) ? 1 : -1;
			}
		},

		...(clientType === 'customer'
			? [
					{
						Header: t('invoices.table.customer'),
						accessor: 'masterInvoice.NAME',
						Cell: props => props.value || 'None',
						minWidth: 100,
						filterMethod: (filter, row) => {
							const filterValue = filter.value.trim().toLowerCase();
							const rowValue = row[filter.id].toLowerCase();
							return rowValue.includes(filterValue);
						}
					}
			  ]
			: []),
		...(clientType === 'supplier'
			? [
					{
						Header: t('invoices.table.supplier'),
						accessor: 'masterInvoice.supplier.name',
						Cell: props => props.value || 'None',
						minWidth: 100,
						filterMethod: (filter, row) => {
							const filterValue = filter.value.trim().toLowerCase();
							const rowValue = row[filter.id].toLowerCase();
							return rowValue.includes(filterValue);
						}
					}
			  ]
			: []),

		{
			Header: t('invoices.table.contract_id'),
			accessor: 'contractId',
			maxWidth: 215,
			Cell: ({ original }) => {
				if (clientType === 'customer') {
					if (original.masterInvoice.customer && original.masterInvoice.customer.customerNumber) {
						return original.contractName
							? `${original.contractName} - ${original.masterInvoice.customer.customerNumber}`
							: `${original.masterInvoice.customer.customerNumber}`;
					}
				}
				return contractIdFormatter(original.contractId, original.contractName);
			},
			filterMethod: (filter, row) => {
				const filterValue = filter.value.trim().toLowerCase();
				const rowValue = row[filter.id].toLowerCase();
				if (row._original.contractName) {
					return (
						row._original.contractName
							.trim()
							.toLowerCase()
							.includes(filterValue) || rowValue.includes(filterValue)
					);
				}
				return rowValue.includes(filterValue);
			}
		},
		{
			Header: t('invoices.table.billing_cycle'),
			accessor: 'billingCycle',
			maxWidth: 90
		},
		{
			Header: t('invoices.table.due_date'),
			accessor: 'dueDate',
			Cell: props => moment(props.value, 'MM-DD-YYYY').format('MMM DD YYYY'),
			maxWidth: 100,
			sortMethod: (a, b) => {
				if (moment(a, 'MM-DD-YYYY').isSame(moment(b, 'MM-DD-YYYY'))) {
					return 0;
				}
				return moment(a, 'MM-DD-YYYY').isSameOrAfter(moment(b, 'MM-DD-YYYY')) ? 1 : -1;
			}
		},
		{
			Header: t('invoices.table.inv_date'),
			accessor: 'invoiceDate',
			Cell: props => moment(props.value, 'MM-DD-YYYY').format('MMM DD YYYY'),
			maxWidth: 100,
			sortMethod: (a, b) => {
				if (moment(a, 'MM-DD-YYYY').isSame(moment(b, 'MM-DD-YYYY'))) {
					return 0;
				}
				return moment(a, 'MM-DD-YYYY').isSameOrAfter(moment(b, 'MM-DD-YYYY')) ? 1 : -1;
			}
		},
		{
			Header: t('invoices.table.amount'),
			accessor: 'amount',
			Cell: props => (
				<div style={{ textAlign: 'right' }}>
					<CurrencyNumber amount={props.value} />
				</div>
			),
			maxWidth: 100
		},
		{
			Header: t('invoices.table.taxes'),
			accessor: 'taxes',
			Cell: props => <div style={{ textAlign: 'right' }}>{props.value ? <CurrencyNumber amount={props.value} /> : ''}</div>,
			maxWidth: 80
		},
		{
			Header: t('invoices.table.total'),
			accessor: 'total',
			Cell: props => (
				<div style={{ textAlign: 'right' }}>
					<CurrencyNumber amount={props.value} />
				</div>
			),
			maxWidth: 100
		},
		...(clientType === 'supplier' ? [
			{
				Header: t('invoices.table.payment_status'),
				accessor: 'status',
				Cell: props => {

					const renderChip = {
						paid: <Chip style={{ backgroundColor: colors.success.main, color: colors.light }} label={t('invoices.table.paid')}  />,
						due: <Chip style={{ backgroundColor: colors.warning.light }} label={t('invoices.table.due')} />,
						settled: <Chip style={{ backgroundColor: colors.success.main, color: colors.light }} label={t('invoices.table.settled')} />,
						opened: <Chip style={{ backgroundColor: colors.secondary.light }} label={t('invoices.table.opened')} />,
						non_settled: <Chip style={{ backgroundColor: colors.danger.light }} label={t('invoices.table.not_settled')} />,
						inconsistent: <Chip style={{ backgroundColor: colors.danger.light }} label={t('invoices.table.inconsistent_payment')} />,
						unknown: null,
					}

					return (
						<Box display="flex" justifyContent="center" alignItems="center">
							{renderChip[props?.original?.paymentStatus]}				
						</Box>
					);
				}
			}] : [])
	];

	const standardColumns = [
		{
			columns: [
				{
					expander: true,
					width: 65,
					Expander: ({ isExpanded }) => <div>{isExpanded ? <KeyboardArrowDown color="primary" /> : <KeyboardArrowRight color="primary" />}</div>,
					style: {
						cursor: 'pointer',
						fontSize: 25,
						padding: '0',
						textAlign: 'center',
						userSelect: 'none'
					}
				}
			]
		},
		{
			Header: t('invoices.table.start_date'),
			accessor: 'startDate',
			Cell: props => moment(props.value, 'MM-DD-YYYY').format('MMM DD YYYY'),
			maxWidth: 100,
			sortMethod: (a, b) => {
				if (moment(a, 'MM-DD-YYYY').isSame(moment(b, 'MM-DD-YYYY'))) {
					return 0;
				}
				return moment(a, 'MM-DD-YYYY').isSameOrAfter(moment(b, 'MM-DD-YYYY')) ? 1 : -1;
			}
		},
		{
			Header: t('invoices.table.end_date'),
			accessor: 'endDate',
			Cell: props => (props.value === '' ? 'Open' : moment(props.value, 'MM-DD-YYYY').format('MMM DD YYYY')),
			maxWidth: 100,
			sortMethod: (a, b) => {
				if (moment(a, 'MM-DD-YYYY').isSame(moment(b, 'MM-DD-YYYY'))) {
					return 0;
				}
				return moment(a, 'MM-DD-YYYY').isSameOrAfter(moment(b, 'MM-DD-YYYY')) ? 1 : -1;
			}
		},
		...(clientType === 'customer'
			? [
					{
						Header: t('invoices.table.customer'),
						accessor: 'masterInvoice.customer.name',
						Cell: props => props.value || 'None',
						maxWidth: 120,
						filterMethod: (filter, row) => {
							const filterValue = filter.value.trim().toLowerCase();
							const rowValue = row[filter.id].toLowerCase();
							return rowValue.includes(filterValue);
						}
					}
			  ]
			: []),
		...(clientType === 'supplier'
			? [
					{
						Header: t('invoices.table.supplier'),
						accessor: 'masterInvoice.supplier.name',
						Cell: props => props.value || 'None',
						maxWidth: 200,
						filterMethod: (filter, row) => {
							const filterValue = filter.value.trim().toLowerCase();
							const rowValue = row[filter.id].toLowerCase();
							return rowValue.includes(filterValue);
						}
					}
			  ]
			: []),
		{
			Header: t('invoices.table.contract_id'),
			accessor: 'contractId',
			minWidth: 200,
			filterMethod: (filter, row) => {
				const filterValue = filter.value.trim().toLowerCase();
				const rowValue = row[filter.id].toLowerCase();
				return rowValue.includes(filterValue);
			}
		},
		{
			Header: t('invoices.table.billing_cycle'),
			accessor: 'billingCycle',
			maxWidth: 90
		},
		{
			Header: t('invoices.table.amount'),
			accessor: 'amount',
			Cell: props => (
				<div style={{ textAlign: 'right' }}>
					<CurrencyNumber amount={props.value} />
				</div>
			),
			maxWidth: 100
		},
		{
			Header: t('invoices.table.taxes'),
			accessor: 'taxes',
			Cell: props => <div style={{ textAlign: 'right' }}>{(props.value && <CurrencyNumber amount={props.value} />) || ''} </div>,
			maxWidth: 80
		},
		{
			Header: t('invoices.table.total'),
			accessor: 'total',
			Cell: props => (
				<div style={{ textAlign: 'right' }}>
					<CurrencyNumber amount={props.value} />
				</div>
			),
			maxWidth: 100
		}
	];

	const dailyInvoicesColumns = [
		{
			Header: t('invoices.table.inv_date'),
			accessor: 'endDate',
			Cell: props =>
				moment(props.value, 'MM-DD-YYYY')
					.add(1, 'day')
					.format('MMM DD YYYY'),

			sortMethod: (a, b) => {
				if (moment(a, 'MM-DD-YYYY').isSame(moment(b, 'MM-DD-YYYY'))) {
					return 0;
				}
				return moment(a, 'MM-DD-YYYY').isSameOrAfter(moment(b, 'MM-DD-YYYY')) ? 1 : -1;
			}
		},
		{
			Header: t('invoices.table.traffic_date'),
			accessor: 'endDate',
			Cell: props => moment(props.value, 'MM-DD-YYYY').format('MMM DD YYYY'),
			sortMethod: (a, b) => {
				if (moment(a, 'MM-DD-YYYY').isSame(moment(b, 'MM-DD-YYYY'))) {
					return 0;
				}
				return moment(a, 'MM-DD-YYYY').isSameOrAfter(moment(b, 'MM-DD-YYYY')) ? 1 : -1;
			}
		},
		...(clientType !== 'supplier'
			? [
					{
						Header: t('invoices.table.due_date'),
						accessor: 'dueDate',
						Cell: props => moment(props.value, 'MM-DD-YYYY').format('MMM DD YYYY'),
						sortMethod: (a, b) => {
							if (moment(a, 'MM-DD-YYYY').isSame(moment(b, 'MM-DD-YYYY'))) {
								return 0;
							}
							return moment(a, 'MM-DD-YYYY').isSameOrAfter(moment(b, 'MM-DD-YYYY')) ? 1 : -1;
						}
					}
			  ]
			: []),

		{
			Header: t('invoices.table.amount'),
			accessor: 'amount',
			Cell: props => (
				<div style={{ textAlign: 'right' }}>
					<CurrencyNumber amount={props.value} />
				</div>
			)
		},
		{
			Header: t('invoices.table.taxes'),
			accessor: 'taxes',
			Cell: props =>
				(props.value && (
					<div style={{ textAlign: 'right' }}>
						<CurrencyNumber amount={props.value} />
					</div>
				)) ||
				''
		},
		{
			Header: t('invoices.table.total'),
			accessor: 'total',
			Cell: props => (
				<div style={{ textAlign: 'right' }}>
					<CurrencyNumber amount={props.value} />
				</div>
			)
		},
	];

	return { originationColumns, standardColumns, dailyInvoicesColumns };
};
