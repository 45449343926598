import React from "react";
import { Accordion, AccordionItem } from 'react-sanfona';


const InfoTessPay = () => (
  <div className="row">
    <div className="col-md-12">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">Tesspay Info Center</h4>
          
          <Accordion>
              <AccordionItem 
              titleTag ="h5"
              bodyTag  = "div"
              title="Q1. What is TESS?"
              expanded={true}  
              titleClassName="card-header"
              style={{ cursor: "pointer" }}>
              <div className="card-body"> 
                  Anim pariatur cliche reprehenderit, enim eiusmod high life
                  accusamus terry richardson ad squid. 3 wolf moon officia aute,
                  non cupidatat skateboard dolor brunch. Food truck quinoa
                  nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                  aliqua put a bird on it squid single-origin coffee nulla
                  assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft
                  beer labore wes anderson cred nesciunt sapiente ea proident.
                  Ad vegan excepteur butcher vice lomo. Leggings occaecat craft
                  beer farm-to-table, raw denim aesthetic synth nesciunt you
                  probably haven´t heard of them accusamus labore sustainable
                  VHS.
              </div>
          </AccordionItem>

           <AccordionItem 
            titleTag ="h5"
            bodyTag  = "div"
            title="Q2. How do Tess Smart Contracts work ??"
            titleClassName="card-header"
            style={{ cursor: "pointer" }}
            >
               <div className="card-body">
                  Anim pariatur cliche reprehenderit, enim eiusmod high life
                  accusamus terry richardson ad squid. 3 wolf moon officia aute,
                  non cupidatat skateboard dolor brunch. Food truck quinoa
                  nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                  aliqua put a bird on it squid single-origin coffee nulla
                  assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft
                  beer labore wes anderson cred nesciunt sapiente ea proident.
                  Ad vegan excepteur butcher vice lomo. Leggings occaecat craft
                  beer farm-to-table, raw denim aesthetic synth nesciunt you
                  probably haven´t heard of them accusamus labore sustainable
                  VHS.
              </div>
          </AccordionItem>

           <AccordionItem
            titleTag ="h5"
            bodyTag  = "div"
            title="Q3. How to buy Tesscoin ?"
            titleClassName="card-header"
            style={{ cursor: "pointer" }}>
             <div className="card-body">
                  Anim pariatur cliche reprehenderit, enim eiusmod high life
                  accusamus terry richardson ad squid. 3 wolf moon officia aute,
                  non cupidatat skateboard dolor brunch. Food truck quinoa
                  nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                  aliqua put a bird on it squid single-origin coffee nulla
                  assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft
                  beer labore wes anderson cred nesciunt sapiente ea proident.
                  Ad vegan excepteur butcher vice lomo. Leggings occaecat craft
                  beer farm-to-table, raw denim aesthetic synth nesciunt you
                  probably haven´t heard of them accusamus labore sustainable
                  VHS.
              </div>
          </AccordionItem>

           <AccordionItem 
            titleTag ="h5"
            bodyTag  = "div"
            title="Q4. Who is this for ?" 
            titleClassName="card-header"
            style={{ cursor: "pointer" }} >
              <div className="card-body">
                  Anim pariatur cliche reprehenderit, enim eiusmod high life
                  accusamus terry richardson ad squid. 3 wolf moon officia aute,
                  non cupidatat skateboard dolor brunch. Food truck quinoa
                  nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                  aliqua put a bird on it squid single-origin coffee nulla
                  assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft
                  beer labore wes anderson cred nesciunt sapiente ea proident.
                  Ad vegan excepteur butcher vice lomo. Leggings occaecat craft
                  beer farm-to-table, raw denim aesthetic synth nesciunt you
                  probably haven´t heard of them accusamus labore sustainable
                  VHS.
              </div>
          </AccordionItem>
          
          </Accordion>
        </div>
      </div>
    </div>
  </div>
);

export default InfoTessPay;
