import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { PublicTemplate } from 'components';
import { Grid } from '@material-ui/core';
import passwordChallengeStyle from './style';
import { PasswordChallenge } from 'screens';

const PasswordChallengePage = ({ ...props }) => {
	const { classes } = props;
	return (
		<PublicTemplate>
			<Grid container justify="center" alignItems="center" className={classes.content}>
				<Grid item>
					<PasswordChallenge {...props} />
				</Grid>
			</Grid>
		</PublicTemplate>
	);
};

export default withStyles(passwordChallengeStyle)(PasswordChallengePage);
