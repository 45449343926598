import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import moment from "moment"

const defaultInstance = i18n.createInstance();

defaultInstance.use(LanguageDetector)
	.use(initReactI18next)
	.use(Backend)
	.init({
		fallbackLng: ['en', 'es'],
		debug: false,
		load: 'languageOnly',
		ns: ['translations'],
		returnObjects: true,
		react: {
			useSuspense: false
		},
		defaultNS: 'translations',
		interpolation: {
			escapeValue: false
		}
	});

defaultInstance.on('languageChanged', lng => {
	moment.locale(lng || 'en');
});

export default i18n;
