import { Chip, CircularProgress, Dialog, DialogActions, DialogContent, AccordionActions, Grid, Slide, Typography } from '@material-ui/core';
import {
	ConfirmDeleteDialog,
	ExternalClientFields,
	ExternalInvoice,
	RatesTable,
	RenderButton,
	RenderFileUpload,
	RenderInput,
	RenderMaterialSwitch,
	RenderSelect,
	TechnicalInformationIpAddresses
} from 'components';
import { Field, FieldArray, reduxForm } from 'redux-form';
import React, { Component } from 'react';
import { commaSeparatedDigits, createValidator, minLengthChecked, required, requiredString, validateTechnicalInformationIpAddresses } from 'services/validation';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import techProfileStyles from './style';

const ignoreEarlyMediaOptions = [
	{
		value: 'none',
		label: 'NONE'
	}
];
const dtmfOptions = [
	{
		value: 'sip_info',
		label: 'SIP INFO'
	},
	{
		value: 'rfc_2833',
		label: 'RFC 2833'
	},
	{
		value: 'inband',
		label: 'INBAND'
	}
];
// PTI-89 - User Dipping hidden for now
// const userDippingFromOptions = [
//   {
//     value: "lrn_server",
//     label: "LRN Server"
//   }
// ];
const Transition = props => <Slide direction="up" {...props} />;

class TechnicalInformationUpdateForm extends Component {
	constructor(props) {
		super(props);
		this.deleteConfirmationOpenCloseHandler = this.deleteConfirmationOpenCloseHandler.bind(this);
		this.externalInvoicePreviewDialogToggle = this.externalInvoicePreviewDialogToggle.bind(this);
		this.deleteHandler = this.deleteHandler.bind(this);

		this.state = {
			isExternalInvoicePreviewDialogOpen: false,
			deleteConfirmationDialogOpen: false,
			customError: false
		};
	}

	validateUniqueName = event => {
		const { hardwareProfiles } = this.props;
		const obj = hardwareProfiles.find(o => o.name === event.target.value);

		if (obj) {
			this.setState({ customError: 'The name needs to be unique' });
		} else {
			this.setState({ customError: false });
		}
	};

	showLoader = (data, classes) => {
		if (data && data.loading) {
			const fname = data.file && data.file.name;
			return (
				<div>
					<Typography variant="body1" align="center" className={classes.loadingText}>
						<CircularProgress color="secondary" size={20} />
						<span>&nbsp;&nbsp;&nbsp;Uploading {fname} ...</span>
					</Typography>
				</div>
			);
		}
		return false;
	};

	externalInvoiceCreate() {
		const {
			createExternalInvoice,
			externalInvoiceData,
			initialValues: { tempId }
		} = this.props;
		if (!externalInvoiceData || !externalInvoiceData[tempId]) return '';

		const { data } = externalInvoiceData[tempId];
		createExternalInvoice({
			tempId,
			...data
		});
		this.externalInvoicePreviewDialogToggle();
	}

	externalInvoicePreviewDialogToggle() {
		const { isExternalInvoicePreviewDialogOpen } = this.state;
		const {
			loadExternalInvoicePreviewData,
			initialValues: { tempId }
		} = this.props;
		if (!isExternalInvoicePreviewDialogOpen) {
			loadExternalInvoicePreviewData(tempId);
		}
		this.setState({
			isExternalInvoicePreviewDialogOpen: !isExternalInvoicePreviewDialogOpen
		});
	}

	deleteConfirmationOpenCloseHandler() {
		this.setState({
			deleteConfirmationDialogOpen: !this.state.deleteConfirmationDialogOpen
		});
	}

	deleteHandler() {
		this.props.onDelete(this.props.hardwareProfileId);
		this.deleteConfirmationOpenCloseHandler();
	}

	renderVendorRatesSection() {
		const showLoader = (data, classes) => {
			if (data && data.loading) {
				const fname = data.file && data.file.name;
				return (
					<div>
						<Typography variant="body1" align="center" className={classes.loadingText}>
							<CircularProgress color="secondary" size={20} />
							<span>&nbsp;&nbsp;&nbsp;Uploading {fname} ...</span>
						</Typography>
					</div>
				);
			}
			return false;
		};
		const allowedFileTypes = ['.csv', '.xlsx', '.xls'];
		const {
			technicalFormType,
			uploadRates,
			initialValues: { tempId },
			hardwareProfileId,
			hardwareProfiles,
			ratePlanUploadData,
			classes
		} = this.props;
		const currentObj = hardwareProfiles.find(hp => hp._id === hardwareProfileId);

		if (currentObj && currentObj.type === 'egress') {
			return (
				showLoader(ratePlanUploadData, classes) || (
					<Grid item xs={12}>
						<Grid item xs={12}>
							{this.renderRatesTable()}
						</Grid>
						<Grid container style={{ paddingTop: 15 }}>
							<Field
								type="file"
								component={RenderFileUpload}
								allowedFileTypes={allowedFileTypes}
								btnText="Upload rates"
								className="form-control"
								name="ratesFile"
								handleUploadFn={evt => {
									const fd = evt && evt.target && evt.target.files && evt.target.files[0];
									// handle Vendor Rate Upload
									uploadRates(tempId, fd);
								}}
							/>
						</Grid>
					</Grid>
				)
			);
		}
		return false;
	}

	renderExternalClientSection() {
		const { hardwareProfileId, hardwareProfiles, initialValues, countries, statesDataArray, getStatesData } = this.props;
		const currentObj = hardwareProfiles.find(hp => hp._id === hardwareProfileId);

		if (currentObj && currentObj.type === 'ingress') {
			return (
				<React.Fragment>
					<ExternalClientFields
						tempId={initialValues.tempId}
						countries={countries}
						statesDataArray={statesDataArray}
						getStatesData={getStatesData}
						initialValues={initialValues}
					/>
					{this.renderExternalRatesSection()}
				</React.Fragment>
			);
		}
		return false;
	}

	renderRatesTable() {
		const { hardwareProfileId, loadVendorRates, vendorRatesData } = this.props;
		const vendorRatesObj = vendorRatesData[hardwareProfileId] || {
			data: [],
			total: 0,
			loading: false,
			startOffset: 0
		};
		const { data, total, loading, startOffset } = vendorRatesObj;
		return (
			<RatesTable
				loadRates={(id, options) => {
					if (typeof loadVendorRates === 'function') {
						loadVendorRates(id, options);
					}
				}}
				handleProposedRatesAction={() => {
					// noop
				}}
				smartContractId={hardwareProfileId}
				rates={data}
				total={total}
				requiresActionTotal={0}
				loading={loading}
				startOffset={startOffset}
				isSelectable={false}
				showLegend={false}
				hideCustomToolbar
			/>
		);
	}

	renderExternalInvoiceSection() {
		const { hardwareProfiles, hardwareProfileId } = this.props;
		const currentObj = hardwareProfiles.find(hp => hp._id === hardwareProfileId);

		if (currentObj && currentObj.type === 'ingress') {
			return (
				<Grid item xs={12}>
					<Grid container style={{ paddingTop: 15 }}>
						<RenderButton color="primary" onClick={() => this.externalInvoicePreviewDialogToggle()}>
							External Invoice Preview
						</RenderButton>
					</Grid>
				</Grid>
			);
		}
		return '';
	}

	renderExternalInvoicePreview() {
		const {
			externalInvoiceData,
			initialValues: { tempId }
		} = this.props;
		if (!externalInvoiceData || !externalInvoiceData[tempId]) return '';

		const { loading, data } = externalInvoiceData[tempId];
		return loading ? 'Loading data...' : <ExternalInvoice data={data} />;
	}

	renderExternalRatesSection() {
		const allowedFileTypes = ['.csv', '.xlsx', '.xls'];
		const {
			uploadExternalRates,
			initialValues: { tempId },
			externalRatePlanUploadData,
			classes
		} = this.props;

		return (
			this.showLoader(externalRatePlanUploadData, classes) || (
				<Grid item xs={12}>
					<Grid item xs={12}>
						<Typography variant="h2" gutterBottom>
							<br />
							External client rates
						</Typography>
					</Grid>
					<Grid item xs={12}>
						{this.renderExternalRatesTable()}
					</Grid>
					<Grid container style={{ paddingTop: 15 }}>
						<Field
							type="file"
							component={RenderFileUpload}
							allowedFileTypes={allowedFileTypes}
							btnText="Upload rates"
							className="form-control"
							name="ratesFile"
							handleUploadFn={evt => {
								const fd = evt && evt.target && evt.target.files && evt.target.files[0];
								// handle external Rate Upload
								uploadExternalRates(tempId, fd);
							}}
						/>
					</Grid>
				</Grid>
			)
		);
	}

	renderExternalRatesTable() {
		const { hardwareProfileId, loadExternalRates, externalRatesData } = this.props;
		const externalRatesObj = externalRatesData[hardwareProfileId] || {
			data: [],
			total: 0,
			loading: false,
			startOffset: 0
		};
		const { data, total, loading, startOffset } = externalRatesObj;
		return (
			<RatesTable
				loadRates={(id, options) => {
					if (typeof loadExternalRates === 'function') {
						loadExternalRates(id, options);
					}
				}}
				handleProposedRatesAction={() => {
					// noop
				}}
				smartContractId={hardwareProfileId}
				rates={data}
				total={total}
				requiresActionTotal={0}
				loading={loading}
				startOffset={startOffset}
				isSelectable={false}
				showLegend={false}
				hideCustomToolbar
			/>
		);
	}

	render() {
		const {
			classes,
			cancel,
			handleSubmit,
			hardwareProfile,
			hardwareProfileOptionsData: { voipProtocols, codecs },
			externalInvoiceData,
			initialValues: { smartContracts, tempId },
			assignedToSmartContract,
			submitSucceeded,
			pristine
		} = this.props;

		const { deleteConfirmationDialogOpen, customError, isExternalInvoicePreviewDialogOpen } = this.state;

		const smartContractList = assignedToSmartContract
			? Object.values(smartContracts).map((smartContract, i) => (
					<Chip
						label={`${smartContract.id}`}
						// onClick={() => ()}
						className={classes.chip}
						key={i}
						color="primary"
						clickable
						href={`/smart-contracts/edit/${smartContract.id}`}
					/>
			  ))
			: '';

		return (
			<div>
				<form onSubmit={handleSubmit} autoComplete="off">
					<Field component="input" type="hidden" name="_id" />
					<Field component="input" type="hidden" name="type" />
					<Field component="input" type="hidden" name="tempId" />
					<Grid container>
						<Grid item xs={12} md={6}>
							<Field component={RenderInput} label="Profile Name *" name="name" onChange={this.validateUniqueName} customError={customError} />
						</Grid>
						{hardwareProfile.type === 'egress' && (
							<Grid item xs={12} md={3}>
								<Field component={RenderInput} label="Tech Prefix" name="techPrefix" />
							</Grid>
						)}
						<Grid item xs={12}>
							<br />
							<FieldArray component={TechnicalInformationIpAddresses} name="ipAddresses" />
						</Grid>
						<Grid item xs={12}>
							<br />
							<Typography variant="h2" gutterBottom>
								Basic
							</Typography>
						</Grid>
						<Grid item xs={12} md={1}>
							<Field
								name="voipProtocolId"
								label="VoIP Protocol"
								component={RenderSelect}
								disabled
								options={voipProtocols.map(option => ({
									value: option.voipProtocolId,
									label: option.voipProtocolName
								}))}
							/>
						</Grid>
						<Grid item xs={12} md={8}>
							<Field
								name="codecs"
								label="Codecs"
								component={RenderSelect}
								multiple
								disabled
								options={codecs.map(option => ({
									value: option.codexId,
									label: option.codexName
								}))}
							/>
						</Grid>
						<Grid item xs={12} md={1}>
							<Field
								name="dtmf"
								label="DTMF"
								component={RenderSelect}
								disabled
								options={dtmfOptions.map(option => ({
									value: option.value,
									label: option.label
								}))}
							/>
						</Grid>
						<Grid item xs={12} md={1}>
							<Field component={RenderInput} label="Dial String Format" name="dialStringFormat" disabled />
						</Grid>
						{hardwareProfile.type === 'egress' && (
							<Grid item xs={12}>
								<br />
								<Typography variant="h2" gutterBottom>
									Advanced Options
								</Typography>

								<Grid container>
									<Grid item xs={12} md={3}>
										<Field
											name="ignoreEarlyMedia"
											label="Ignore Early Media"
											component={RenderSelect}
											disabled={assignedToSmartContract}
											options={ignoreEarlyMediaOptions.map(option => ({
												value: option.value,
												label: option.label
											}))}
										/>
									</Grid>
									<Grid item xs={12} md={1}>
										<Field component={RenderInput} label="CPS Limit" name="cpsLimit" disabled={assignedToSmartContract} />
									</Grid>
									<Grid item xs={12} md={2}>
										<Field component={RenderInput} label="PDD Timeout (s)" name="pddTimeout" disabled={assignedToSmartContract} />
									</Grid>
									<Grid item xs={12} md={2}>
										<Field component={RenderInput} label="Max Duration (s)" name="maxDuration" disabled={assignedToSmartContract} />
									</Grid>
									<Grid item xs={12} md={1}>
										<Field component={RenderInput} label="Ring Timer (s)" name="ringTimer" disabled={assignedToSmartContract} />
									</Grid>
									<Grid item xs={12}>
										<Grid container>
											<Grid item>
												<Field component={RenderMaterialSwitch} label="Active" name="active" disabled={assignedToSmartContract} />
											</Grid>
											<Grid item>
												<Field component={RenderMaterialSwitch} label="T.38" name="t38" disabled={assignedToSmartContract} />
											</Grid>
											<Grid item>
												<Field component={RenderMaterialSwitch} label="Ignore Early NOSDP" name="ignoreEarlyNOSDP" disabled={assignedToSmartContract} />
											</Grid>
											<Grid item>
												<Field component={RenderMaterialSwitch} label="Proxy Media" name="proxyMedia" disabled={assignedToSmartContract} />
											</Grid>
										</Grid>
									</Grid>
									{this.renderVendorRatesSection()}
								</Grid>
								{/* // PTI-89 - User Dipping hidden for now
                    <div className="form-group">

                      <Field
                        name="userDippingFrom"
                        label="User Dipping From"
                        component={RenderMaterialSelect}
                      >
                        {userDippingFromOptions.map((item, i) => (
                          <MenuItem value={item.value} label={item.label} key={i}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </div> */}
								{/* // PTI-89 - User Dipping hidden for now
                    <div className="form-group">
                      <Field
                        component={RenderInput}
                        label="Min Duration (s)"
                        name="minDuration"
                      />
                  </div> */}
							</Grid>
						)}
						<Grid item xs={12}>
							{smartContractList ? (
								<div className={classes.chipList}>
									<Typography variant="h2" gutterBottom>
										Assigned To Smart Contract
									</Typography>
									{smartContractList}
								</div>
							) : (
								''
							)}
						</Grid>
						{this.renderExternalClientSection()}
						{this.renderExternalInvoiceSection()}
					</Grid>

					<AccordionActions>
						{typeof cancel !== 'undefined' ? (
							<RenderButton onClick={() => cancel()} color="warning">
								Cancel
							</RenderButton>
						) : null}
						<RenderButton onClick={() => this.deleteConfirmationOpenCloseHandler()} color="danger">
							Delete
						</RenderButton>
						<RenderButton color="primary" type="submit" disabled={customError !== false || submitSucceeded}>
							Update
						</RenderButton>
					</AccordionActions>
				</form>
				<ConfirmDeleteDialog open={deleteConfirmationDialogOpen} openCloseHandler={this.deleteConfirmationOpenCloseHandler} handleSubmit={this.deleteHandler} />
				<Dialog
					maxWidth="md"
					fullWidth
					open={isExternalInvoicePreviewDialogOpen}
					onBackdropClick={() => this.externalInvoicePreviewDialogToggle()}
					TransitionComponent={Transition}
				>
					<DialogContent>{this.renderExternalInvoicePreview()}</DialogContent>
					<DialogActions>
						<RenderButton
							color="primary"
							onClick={() => this.externalInvoiceCreate()}
							disabled={!externalInvoiceData || !externalInvoiceData[tempId] || externalInvoiceData[tempId].loading}
						>
							Save External Invoice
						</RenderButton>
						<RenderButton color="primary" onClick={() => this.externalInvoicePreviewDialogToggle()}>
							Close
						</RenderButton>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

TechnicalInformationUpdateForm.defaultProps = {
	cancel: undefined,
	hardwareProfileOptionsData: [{}],
	loadExternalInvoicePreviewData: () => {},
	createExternalInvoice: () => {}
};

TechnicalInformationUpdateForm.propTypes = {
	classes: PropTypes.shape().isRequired,
	hardwareProfileId: PropTypes.string.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	cancel: PropTypes.func,
	loadExternalInvoicePreviewData: PropTypes.func,
	createExternalInvoice: PropTypes.func
};

const validate = createValidator({
	techPrefix: [commaSeparatedDigits],
	name: [requiredString],
	voipProtocolId: [required],
	codecs: [required, minLengthChecked(1)],
	dtmf: [required],
	dialStringFormat: [requiredString],
	ipAddresses: [validateTechnicalInformationIpAddresses]
});

TechnicalInformationUpdateForm = withStyles(techProfileStyles)(TechnicalInformationUpdateForm);

export default reduxForm({
	destroyOnUnmount: true,
	enableReinitialize: true,
	touchOnBlur: false,
	validate,
	form: 'TechnicalInformationForm'
})(TechnicalInformationUpdateForm);
