import React from 'react';
import { PrivateMenuItem, PrivateMenuAccordion } from 'components';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';

import Description from '@material-ui/icons/Description';

import People from '@material-ui/icons/People';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Class from '@material-ui/icons/Class';
import BarChart from '@material-ui/icons/Poll';
import AccountBox from '@material-ui/icons/AccountBox';
import Help from '@material-ui/icons/Help';
import Menu from '@material-ui/icons/Menu';
import SettingsInputComponent from '@material-ui/icons/SettingsInputComponent';
import Devices from '@material-ui/icons/Devices';
import withStyles from '@material-ui/core/styles/withStyles';
import headerStyle from './style';
import { AuthManager } from '../../index';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PrivateSubMenuItem from 'components/molecules/PrivateSubMenuItem';
import SmsIcon from '@material-ui/icons/Sms';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';

const PrivateMenu = props => {
	const { classes, getItemValue, selectedMenuItem, menuOpen, handleDrawerToggle, role, getSection, menuSections } = props;
	const { t } = useTranslation(['translations']);

	const accountData = useSelector(state => (state.myaccount && state.myaccount.accountData) || false);
	const isLoading = useSelector(state => (state.myaccount && state.myaccount.loading) || false);

	const voipActive = accountData?.voipProgram && !isLoading;
	const acpActive = accountData?.acpProgramActive && !isLoading;
	const smsActive = accountData?.smsProgram && !isLoading;

	// const isFunder = role.indexOf(ROLES.FUNDER) !== -1;


	return (
		<div className={`${classes.navMenu}  ${menuOpen === true ? classes.navMenuOpen : ''}`}>
			<div className={`${classes.navMenu} ${classes.navFixed}  ${menuOpen === true ? classes.navMenuOpen : ''}`}>
				<div className={classes.hamburger}>
					<Menu color="inherit" onClick={handleDrawerToggle} />
				</div>
				<List className={classes.list}>
					<AuthManager permissions={['myaccount']}>
						<PrivateMenuItem
							key="myaccount"
							id="myaccount"
							title={t('menu.account')}
							menuIcon={<AccountBox fontSize="inherit" />}
							callbackFromParent={getItemValue}
							itemState={selectedMenuItem === 'myaccount' ? 'active' : ''}
							link="/account"
							menuOpen={menuOpen}
						/>
					</AuthManager>
					<AuthManager userRole={['carrier', 'acp-device-provider']} permissions={['tradingPartners']}>
						<PrivateMenuItem
							key="trading-partners"
							id="trading-partners"
							title={t('menu.trading_partners')}
							menuIcon={<People fontSize="inherit" />}
							callbackFromParent={getItemValue}
							link="/trading-partners"
							itemState={selectedMenuItem === 'trading-partners' ? 'active' : ''}
							menuOpen={menuOpen}
						/>
					</AuthManager>
					<AuthManager permissions={['financials']}>
						<PrivateMenuItem
							id="financials"
							title={t('menu.financials')}
							menuIcon={<AttachMoney fontSize="inherit" />}
							callbackFromParent={getItemValue}
							itemState={selectedMenuItem === 'financials' ? 'active' : ''}
							link="/financials"
							menuOpen={menuOpen}
						/>
					</AuthManager>
					{voipActive && (
						<PrivateMenuAccordion
							id='voipProgram'
							menuIcon={<PhoneIcon fontSize="inherit" />}
							menuOpen={menuOpen}
							title={'VoIP'}
							setSection={getSection}
							sectionOpened={menuSections?.['voipProgram']}
						>
							<>
								<AuthManager userRole={['carrier']} permissions={['smartContracts']}>
									<PrivateSubMenuItem
										key="smartContracts"
										id="smartContracts"
										title={t('menu.smartcontracts')}
										menuIcon={<Description fontSize="inherit" />}
										callbackFromParent={getItemValue}
										itemState={selectedMenuItem === 'smartContracts' ? 'active' : ''}
										link="/smart-contracts"
										menuOpen={menuOpen}
									/>
								</AuthManager>
								<AuthManager userRole={['funder']} permissions={['reports']}>
									<PrivateSubMenuItem
										id="reports"
										title={t('menu.tech_reports')}
										menuIcon={<BarChart fontSize="inherit" />}
										callbackFromParent={getItemValue}
										itemState={selectedMenuItem === 'reports' ? 'active' : ''}
										link="/reports"
										menuOpen={menuOpen}
									/>
								</AuthManager>
								<AuthManager userRole={['carrier']} permissions={['routes']}>
									<PrivateSubMenuItem
										id="routing"
										title={t('menu.routing')}
										menuIcon={<SettingsInputComponent fontSize="inherit" />}
										callbackFromParent={getItemValue}
										itemState={selectedMenuItem === 'routing' ? 'active' : ''}
										link="/routing"
										menuOpen={menuOpen}
									/>
								</AuthManager>
							</>
						</PrivateMenuAccordion>
					) || null}
					{smsActive && (
						<AuthManager permissions={['smsProgram']}>
							<PrivateMenuItem
								id='smsProgram'
								title="SMS"
								menuIcon={<SmsIcon fontSize="inherit" />}
								callbackFromParent={getItemValue}
								itemState={selectedMenuItem === 'smsProgram' ? 'active' : ''}
								link="/sms"
								menuOpen={menuOpen}
							/>
						</AuthManager>
					) || null}
					{acpActive && (
						<PrivateMenuAccordion
							id='acpProgram'
							menuIcon={<PhoneIphoneIcon fontSize="inherit" />}
							menuOpen={menuOpen}
							title={'acp'}
							setSection={getSection}
							sectionOpened={menuSections?.['acpProgram']}
						>
							<>
								<AuthManager userRole={['carrier']} permissions={['acp_program']}>
									<PrivateSubMenuItem
										key="acp_program"
										id="acp_program"
										title="ACP Claims"
										menuIcon={<Devices fontSize="inherit" />}
										callbackFromParent={getItemValue}
										itemState={selectedMenuItem === 'acp_program' ? 'active' : ''}
										link="/acp-program/provider-submissions"
										menuOpen={menuOpen}
									/>
								</AuthManager>

								{window.config.showAcpDevices && (
									<AuthManager permissions={['acp_program']}>
										<PrivateSubMenuItem
											userRole="acp-device-provider"
											id="acp-smart-contracts"
											title={t('menu.acp_device_orders')}
											menuIcon={<Class fontSize="inherit" />}
											callbackFromParent={getItemValue}
											itemState={selectedMenuItem === 'acp-smart-contracts' ? 'active' : ''}
											link="/acp-smart-contracts"
											menuOpen={menuOpen}
										/>
									</AuthManager>
								)}
							</>
						</PrivateMenuAccordion>
					) || null}




					{/* <AuthManager userRole={['carrier', 'acp-device-provider']} permissions={['tradingPartners']}>
						<PrivateMenuItem
							key="trading-partners"
							id="trading-partners"
							title={t('menu.trading_partners')}
							menuIcon={<People fontSize="inherit" />}
							callbackFromParent={getItemValue}
							link="/trading-partners"
							itemState={selectedMenuItem === 'trading-partners' ? 'active' : ''}
							menuOpen={menuOpen}
						/>
					</AuthManager>
					<AuthManager userRole={['carrier']} permissions={['smartContracts']} >
						<PrivateMenuItem
							key="smartContracts"
							id="smartContracts"
							title={t('menu.smartcontracts')}
							menuIcon={<Description fontSize="inherit" />}
							callbackFromParent={getItemValue}
							itemState={selectedMenuItem === 'smartContracts' ? 'active' : ''}
							link="/smart-contracts"
							menuOpen={menuOpen}
						/>
					</AuthManager>

					<AuthManager userRole={['funder']} permissions={['financials']} >
						<PrivateMenuItem
							key="financingRequests"
							id="financingRequests"
							title={t('menu.financing_requests')}
							menuIcon={<Description fontSize="inherit" />}
							callbackFromParent={getItemValue}
							itemState={selectedMenuItem === 'financingRequests' ? 'active' : ''}
							link="/financing-requests"
							menuOpen={menuOpen}
						/>
					</AuthManager>

					<AuthManager permissions={['financials']}>
						<PrivateMenuItem
							id="financials"
							title={t('menu.financials')}
							menuIcon={<AttachMoney fontSize="inherit" />}
							callbackFromParent={getItemValue}
							itemState={selectedMenuItem === 'financials' ? 'active' : ''}
							link="/financials"
							menuOpen={menuOpen}
						/>
					</AuthManager>

					<AuthManager userRole={['carrier']} permissions={['reports']}>
						<PrivateMenuItem
							id="reports"
							title={t('menu.reports')}
							menuIcon={<BarChart fontSize="inherit" />}
							callbackFromParent={getItemValue}
							itemState={selectedMenuItem === 'reports' ? 'active' : ''}
							link="/reports"
							menuOpen={menuOpen}
						/>
					</AuthManager>

					<AuthManager userRole={['funder']} permissions={['reports']}>
						<PrivateMenuItem
							id="reports"
							title={t('menu.tech_reports')}
							menuIcon={<BarChart fontSize="inherit" />}
							callbackFromParent={getItemValue}
							itemState={selectedMenuItem === 'reports' ? 'active' : ''}
							link="/reports"
							menuOpen={menuOpen}
						/>
					</AuthManager>

					<AuthManager userRole={['carrier']} permissions={['routes']}>
						<PrivateMenuItem
							id="routing"
							title={t('menu.routing')}
							menuIcon={<SettingsInputComponent fontSize="inherit" />}
							callbackFromParent={getItemValue}
							itemState={selectedMenuItem === 'routing' ? 'active' : ''}
							link="/routing"
							menuOpen={menuOpen}
						/>
					</AuthManager>

					<AuthManager userRole={['carrier']} permissions={['acp_program']}>
						<PrivateMenuItem
							key="acp_program"
							id="acp_program"
							title="ACP Claims"
							menuIcon={<Devices fontSize="inherit" />}
							callbackFromParent={getItemValue}
							itemState={selectedMenuItem === 'acp_program' ? 'active' : ''}
							link="/acp-program/provider-submissions"
							menuOpen={menuOpen}
						/>
					</AuthManager>

					{window.config.showAcpDevices && (
						<AuthManager permissions={['acp_program']}>
							<PrivateMenuItem
								userRole="acp-device-provider"
								id="acp-smart-contracts"
								title={t('menu.acp_device_orders')}
								menuIcon={<Class fontSize="inherit" />}
								callbackFromParent={getItemValue}
								itemState={selectedMenuItem === 'acp-smart-contracts' ? 'active' : ''}
								link="/acp-smart-contracts"
								menuOpen={menuOpen}
							/>
						</AuthManager>
					)} */}

					{/* <PrivateMenuItem
						id="support"
						title={t('menu.support')}
						menuIcon={<Help fontSize="inherit" />}
						callbackFromParent={getItemValue}
						itemState={selectedMenuItem === 'support' ? 'active' : ''}
						link="http://support.tpio.us/support/"
						external
						menuOpen={menuOpen}
					/> */}
					<PrivateMenuItem
						id="support2"
						title={t('menu.support')}
						menuIcon={<Help fontSize="inherit" />}
						callbackFromParent={getItemValue}
						itemState={selectedMenuItem === 'support' ? 'active' : ''}
						link="/support"
						menuOpen={menuOpen}
					/>
				</List>
			</div>
		</div>
	);
};

PrivateMenu.propTypes = {
	selectedMenuItem: PropTypes.string.isRequired,
	getItemValue: PropTypes.func.isRequired,
	classes: PropTypes.shape().isRequired,
	menuOpen: PropTypes.bool.isRequired,
	handleDrawerToggle: PropTypes.func.isRequired
};

export default withStyles(headerStyle)(PrivateMenu);
