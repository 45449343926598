import { API } from 'aws-amplify';

const apiZoho = {
	getTicketsByUser(data) {
		const { email, from, limit } = data;
		return API.post('ACCOUNT_SERVICE_API', `/zoho/tickets/get/byEmail/`, { body: { from, limit, email } })
			.then(response => response)
			.catch(err => err);
	},
	createTicket(data) {
		return API.post('ACCOUNT_SERVICE_API', '/zoho/tickets/create', {
			body: data
		})
			.then(response => response)
			.catch(err => err);
	},

	getTicketsThreads(ticketId) {
		return API.post('ACCOUNT_SERVICE_API', `/zoho/tickets/get/threads/byTicket`, { body: { ticketId } })
			.then(response => response)
			.catch(err => err);
	}
};

export default apiZoho;
