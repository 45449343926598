import React, { Component } from "react";
import { BiddingSupplier, Util } from "components";
import PropTypes from "prop-types";

class TableSuppliers extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <table className="table table-bidding table-responsive table-hover">
        <thead className="thead-default thead-default-bidding">
          <tr>
            <th width="15%">Payment</th>
            <th width="15%">Rating</th>
            <th width="20%">Daily Amount</th>
            <th width="20%">Period Amount</th>
            <th width="10%">%</th>
          </tr>
        </thead>
        <tbody>
          {Util.isEmpty(this.props.suppliers) ? (
            <tr>
              <td colSpan="5" className="text-center">
                No data available
              </td>
            </tr>
          ) : (
            Object.keys(
              this.props.suppliers === undefined ? [] : this.props.suppliers
            ).map(i => (
              <BiddingSupplier
                key={"kbs-" + i}
                supplier={this.props.suppliers[i]}
                processAcceptOpenOrder={this.props.processAcceptOpenOrder}
              />
            ))
          )}
        </tbody>
      </table>
    );
  }
}
TableSuppliers.propTypes = {
  processAcceptOpenOrder: PropTypes.func.isRequired,
  suppliers: PropTypes.array
};
export default TableSuppliers;
