export const PRELOADER = "PRELOADER";
export const PRELOADER_ON = "PRELOADER_ON";
export const PRELOADER_OFF = "PRELOADER_OFF";

export const actionCreatorPreloaderOn = data => ({
  type: PRELOADER_ON,
  payload: data
});

export const actionCreatorPreloaderOff = data => ({
  type: PRELOADER_OFF,
  payload: data
});
