import React from "react";

export const FinancialMenu = () => (
  <div>
    <div className="stickyside">
      <div className="list-group" id="top-menu">
        <a href="#1" className="list-group-item active">
          Balance
        </a>
        <a href="#3" className="list-group-item">
          Customer Invoices
        </a>
        <a href="#4" className="list-group-item">
          Supplier Invoices
        </a>
        {/* <a href="#5" className="list-group-item">
          Disputes
        </a> */}
      </div>
    </div>
  </div>
);

export default FinancialMenu;
