import React, {memo, useEffect} from 'react';
import { RenderButton, RenderFormikInput } from 'components';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { contractIdFormatter } from 'utils/index';
import actions from "store/contracts/actions"

import { Grid, Dialog, DialogContent, DialogActions, Checkbox, FormControlLabel } from '@material-ui/core';

export default memo(({ isOpen, openCloseHandler }) => {
	const dispatch = useDispatch();

	const smartContracts = useSelector(state => state.contracts?.smartContracts || []);
	const loading = useSelector(state => state.contracts?.loading);


	const formik = useFormik({
		initialValues: {
			name: '',
			smartContracts: []
		},
		onSubmit: (values, { resetForm }) => {
			dispatch({
				type: actions.CREATE_PROJECT,
				payload: values
			});
			resetForm();
			openCloseHandler();
		}
	});

	useEffect(() => {
		dispatch({ type: actions.GET_SMART_CONTRACTS, payload: { ungroup: 1 } });
	},[isOpen])

	return (
		<Dialog maxWidth="md" open={isOpen} onClose={openCloseHandler}>
			<DialogContent>
				<form onSubmit={formik.handleSubmit}>
					<Grid container xs={12} spacing={1}>
						<Grid item xs={12}>
							<RenderFormikInput formik={formik} name="name" label="Project name" />
						</Grid>
						<Grid item xs={12}>
							Smart contracts
							<br />
							<Grid container xs={12}>
								{!loading && (smartContracts || []).map((smartContract, i) => {
									return (
										<Grid item xs={4}>
											<FormControlLabel
												control={<Checkbox />}
												key={i}
												value={smartContract._id}
												label={contractIdFormatter(smartContract._id, smartContract.contractName, smartContract.partner?.participantName)}
												name="smartContracts"
												onChange={e => {
													let smartContracts = [];
													if (e.target.checked) {
														smartContracts = [...new Set([...formik.values.smartContracts, smartContract._id])];
													} else {
														smartContracts = formik.values.smartContracts.filter(sc => {
															return sc !== e.target.value;
														});
													}

													formik.setFieldValue('smartContracts', smartContracts);
												}}
											/>
											<br />
										</Grid>
									);
								})}
							</Grid>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
			<DialogActions>
				<RenderButton color="primary" onClick={formik.handleSubmit} disabled={loading}>
					Create Project
				</RenderButton>
				<RenderButton onClick={openCloseHandler} color="danger">
					Close
				</RenderButton>
			</DialogActions>
		</Dialog>
	);
});
