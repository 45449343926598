import { API, Auth } from 'aws-amplify';
import axios from 'axios';

const axiosAuthHeader = () =>
	Auth.currentAuthenticatedUser()
		.then(user => {
			const headers = {
				AuthorizationInfo: user.signInUserSession.idToken.jwtToken
			};

			const impersonateCode = window.localStorage.getItem('IMPERSONATE_CODE');
			if (impersonateCode) {
				headers.impersonateCode = impersonateCode;
			}

			return headers;
		})
		.catch(err => console.log(err));

const routesApiService = {
	getSmartContracts(data) {
		return API.post('NODE_SERVICE_API', '/routes/getRoutesData', { body: data })
			.then(response => response)
			.catch(err => err);
	},
	getRouteSmartContractsFilters(data) {
		return API.post('NODE_SERVICE_API', '/routes/getRoutesDataFilters', {
			body: data
		})
			.then(response => response)
			.catch(err => err);
	},
	getRoutesForSmartContract(smartContractId) {
		return API.get('NODE_SERVICE_API', `/routes/${smartContractId}`)
			.then(response => response)
			.catch(err => err);
	},

	removeRoute(smartContractId, data) {
		const routeData = {
			body: data
		};
		return API.post('NODE_SERVICE_API', `/routes/${smartContractId}/delete/${data._id}`, routeData)
			.then(response => response)
			.catch(err => err);
	},
	createInitialZones(smartContractId) {
		return API.get('NODE_SERVICE_API', `/routes/${smartContractId}/initializeRouteSetup`)
			.then(response => response)
			.catch(err => err);
	},
	updateSmartContractIngress(smartContractId) {
		return API.get('NODE_SERVICE_API', `/routes/${smartContractId}/updateSmartContractIngress`)
			.then(response => response)
			.catch(err => err);
	},

	async uploadRates(tempId, ratePlanFile) {
		const data = new FormData();
		data.append('ratesFile', ratePlanFile, ratePlanFile.name);
		data.append('tempId', tempId);

		return axios
			.post(`${await API.endpoint('NODE_SERVICE_API')}/ratePlan`, data, { headers: await axiosAuthHeader() })
			.then(() => 'Rates uploaded')
			.catch(err => err);
	},
	async uploadExternalRates(tempId, ratePlanFile) {
		const data = new FormData();
		data.append('ratesFile', ratePlanFile, ratePlanFile.name);
		data.append('tempId', tempId);

		return axios
			.post(`${await API.endpoint('NODE_SERVICE_API')}/external/ratePlan`, data, { headers: await axiosAuthHeader() })
			.then(() => 'Rates uploaded')
			.catch(err => err);
	},
	getVendorRates(externalProfileId, options) {
		return API.get('NODE_SERVICE_API', `/routes/vendorRates`, {
			queryStringParameters: {
				externalProfileId,
				options
			}
		});
	},
	getExternalRates(externalProfileId, options) {
		return API.get('NODE_SERVICE_API', `/routes/externalRates`, {
			queryStringParameters: {
				externalProfileId,
				options
			}
		});
	},
	getExternalInvoicePreviewData(externalProfileId) {
		return API.get('NODE_SERVICE_API', `/routes/externalInvoice`, {
			queryStringParameters: {
				externalProfileId
			}
		});
	},
	postSaveExternalInvoiceData(data) {
		const externalInvoiceData = {
			body: data
		};
		return API.post('NODE_SERVICE_API', `/routes/externalInvoice/create`, externalInvoiceData)
			.then(response => response)
			.catch(err => err);
	},

	// / routing service split
	getListFilters(type, filters) {
		return API.get('ROUTING_SERVICE_API', `/filters/${type}`, {
			queryStringParameters: filters
		})
			.then(r => r)
			.catch(() => []);
	},
	async getList(type, filters) {
		return API.get('ROUTING_SERVICE_API', `/${type}`, {
			queryStringParameters: filters
		})
			.then(r => r)
			.catch(() => []);
	},
	getContractDetails(smartContractId) {
		return API.get('ROUTING_SERVICE_API', `/contract/${smartContractId}`, {})
			.then(r => r)
			.catch(() => []);
	},
	updateRouteSettings(data) {
		return API.patch('ROUTING_SERVICE_API', `/contract/${data.smartContractId || ''}/settings`, { body: data })
			.then(r => r)
			.catch(() => []);
	},
	getRouteCodeMatches(data) {
		return API.get('ROUTING_SERVICE_API', '/routeCodes', { queryStringParameters: data })
			.then(r => r)
			.catch(() => []);
	},
	getContractRoutes(smartContractId) {
		return API.get('ROUTING_SERVICE_API', `/${smartContractId}`, {})
			.then(response => response)
			.catch(err => err);
	},
	getRouteDetails(routeId) {
		return API.get('ROUTING_SERVICE_API', `/update/${routeId}`, {})
			.then(response => response)
			.catch(err => err);
	},
	createNewRoute(smartContractId, data) {
		return API.post('ROUTING_SERVICE_API', `/${smartContractId}/newRoute`, { body: data, timeout: (1000 * 60) * 10 })
			.then(response => response)
			.catch(err => err);
	},
	updateRoute(data) {
		return API.post('ROUTING_SERVICE_API', `/${data.smartContractId}/update/${data._id}`, { body: data, timeout: (1000 * 60) * 10 })
			.then(response => response)
			.catch(err => err);
	},
	deleteRoute(data) {
		return API.del('ROUTING_SERVICE_API', `/${data.smartContractId}/delete/${data.routeId}`, {})
			.then(response => response)
			.catch(err => err);
	},
	getPartitionInfo() {
		return API.get('ROUTING_SERVICE_API', `/partition-info`, {})
			.then(response => response)
			.catch(err => err);
	},
	getHardwareProfileOptions() {
		return API.get('ROUTING_SERVICE_API', `/hardware-profiles/options`, {})
			.then(response => response)
			.catch(err => err);
	},
	getOriginHardwareProfiles() {
		return API.get('ROUTING_SERVICE_API', '/hardware-profiles/ingress', {})
			.then(response => response)
			.catch(err => err);
	},
	getTerminationHardwareProfiles() {
		return API.get('ROUTING_SERVICE_API', '/hardware-profiles/egress', {})
			.then(response => response)
			.catch(err => err);
	},
	async uploadExternalRate(tempId, ratePlanFile) {
		const data = new FormData();
		data.append('ratesFile', ratePlanFile, ratePlanFile.name);
		data.append('tempId', tempId);

		return axios
			.post(`${await API.endpoint('ROUTING_SERVICE_API')}/hardware-profiles/rate-plans/external`, data, { headers: await axiosAuthHeader() })
			.then(response => response.data)
			.catch(err => err);
	},
	async uploadInternalRate(tempId, ratePlanFile) {
		const data = new FormData();
		data.append('ratesFile', ratePlanFile, ratePlanFile.name);
		data.append('tempId', tempId);

		return axios
			.post(`${await API.endpoint('ROUTING_SERVICE_API')}/hardware-profiles/rate-plans/vendor`, data, { headers: await axiosAuthHeader() })
			.then(response => response.data)
			.catch(err => err);
	},
	getHardwareProfile(hardwareProfileId) {
		return API.get('ROUTING_SERVICE_API', `/hardware-profiles/${hardwareProfileId}`, {})
			.then(response => response)
			.catch(err => err);
	},
	getHardwareProfileRates(data, type) {
		return API.get('ROUTING_SERVICE_API', `/hardware-profiles/rate-plans/${type}`, { queryStringParameters: data })
			.then(response => response)
			.catch(err => err);
	},
	createHardwareProfile(data) {
		return API.post('ROUTING_SERVICE_API', '/hardware-profiles', { body: data })
			.then(response => response)
			.catch(err => err);
	},
	updateHardwareProfile(data) {
		return API.patch('ROUTING_SERVICE_API', `/hardware-profiles/${data._id}`, { body: data })
			.then(response => response)
			.catch(err => err);
	},
	deleteHardwareProfile(hardwareProfileId) {
		return API.del('ROUTING_SERVICE_API', `/hardware-profiles/${hardwareProfileId}`, {})
			.then(response => response)
			.catch(err => err);
	}
};

export default routesApiService;
