import React from "react";
import TextField from "@material-ui/core/TextField";

const RenderInput = ({
  input,
  label,
  value,
  defaultValue,
  margin,
  meta: { touched, error },
  min,
  max,
  customError,
  ...rest
}) => {
  const custom = {
    ...rest,
    inputProps: {
      min,
      max
    }
  };
  const hasError =
    (typeof error !== typeof undefined && touched) ||
    (customError && customError !== false);
  return (
    <TextField
      {...input}
      fullWidth
      margin={margin}
      label={label}
      helperText={hasError ? error || customError : ""}
      error={hasError}
      {...custom}
    />
  );
};

RenderInput.defaultProps = {
  margin: "none"
};

export default RenderInput;
