import {
  initialVerificationCodeData,
  getVerificationCodeDataState,
  VERIFICATION_CODE_DATA
} from "./selectors";
import {
  VERIFICATION_CODE_REQUEST,
  VERIFICATION_CODE_FAILURE,
  VERIFICATION_CODE_SUCCESS,
  CLEAN_DATA_VERIFICATION_CODE
} from "./actions";

export default (state = initialVerificationCodeData, { type, payload }) => {
  switch (type) {
    case VERIFICATION_CODE_REQUEST:
      return {
        ...state,
        [VERIFICATION_CODE_DATA]: {
          ...getVerificationCodeDataState(state),
          ...payload
        }
      };
    case VERIFICATION_CODE_FAILURE:
      return {
        ...state,
        [VERIFICATION_CODE_DATA]: {
          errorMessage: payload,
          successMessage: null
        }
      };
    case VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        [VERIFICATION_CODE_DATA]: {
          ...getVerificationCodeDataState(state),
          successMessage: payload
        }
      };
    case CLEAN_DATA_VERIFICATION_CODE:
      return {
        ...state,
        [VERIFICATION_CODE_DATA]: {
          ...initialVerificationCodeData
        }
      };
    default:
      return state;
  }
};
