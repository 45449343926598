export const URL_REDIRECT_REQUEST = "URL_REDIRECT_REQUEST";
export const URL_REDIRECT_POSTLOGIN = "URL_REDIRECT_POSTLOGIN";
export const CLEAN_URL_REDIRECT = "CLEAN_URL_REDIRECT";

export const urlRedirectRequest = (url, userId) => ({
  type: URL_REDIRECT_REQUEST,
  payload: { url, userId }
});

export const urlRedirectPostLogin = () => ({
  type: URL_REDIRECT_POSTLOGIN
});

export const cleanUrlRedirect = () => ({
  type: CLEAN_URL_REDIRECT
});
