import React, { Component } from "react";
import { BiddingOpenOrder } from "components";
import PropTypes from "prop-types";

class TableOpenOrders extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="table-responsive">
        <table className="table table-bidding table-responsive table-hover">
          <thead className="thead-default thead-default-bidding">
            <tr>
              <th width="15%">Payment Terms</th>
              <th width="15%">Credit Rating</th>
              <th width="24%">Estimated Daily Settlement Amount</th>
              <th width="13%">Period Amount</th>
              <th width="13%">Interest rate</th>
              <th width="20%">Smart Contract</th>
              <th width="30%">Action</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(
              this.props.contracts === undefined ? [] : this.props.contracts
            ).map(i => (
              <BiddingOpenOrder
                key={"kbo-" + i}
                contract={this.props.contracts[i]}
                processCancelOpenOrder={this.props.processCancelOpenOrder}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
TableOpenOrders.propTypes = {
  processCancelOpenOrder: PropTypes.func.isRequired,
  contracts: PropTypes.array
};
export default TableOpenOrders;
