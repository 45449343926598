export const RECOVERY_PASSWORD_REQUEST = "RECOVERY_PASSWORD_REQUEST";
export const RECOVERY_PASSWORD_FAILURE = "RECOVERY_PASSWORD_FAILURE";
export const RECOVERY_PASSWORD_SUCCESS = "RECOVERY_PASSWORD_SUCCESS";
export const CLEAN_DATA_RECOVERY_PASSWORD = "CLEAN_DATA_RECOVERY_PASSWORD";

export const recoveryPasswordRequest = email => ({
  type: RECOVERY_PASSWORD_REQUEST,
  payload: { email }
});
export const recoveryPasswordFailure = errorMessage => ({
  type: RECOVERY_PASSWORD_FAILURE,
  payload: errorMessage
});
export const recoveryPasswordSuccess = successMessage => ({
  type: RECOVERY_PASSWORD_SUCCESS,
  payload: successMessage
});

export const cleanDataRecoveryPassword = () => ({
  type: CLEAN_DATA_RECOVERY_PASSWORD
});
