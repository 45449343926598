import React from "react";
import { Field, reduxForm, reset } from "redux-form";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createValidator,
  required,
  validateMaxValueParam
} from "services/validation";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Slide,
  Typography,
  FormHelperText
} from "@material-ui/core";
import { RenderButton, RenderInput } from "components";
import styles from "./style";

const Transition = props => <Slide direction="up" {...props} />;

class LenderApproveInvoiceModal extends React.Component {
  render() {
    const {
      open,
      classes,
      openCloseHandler,
      handleSubmit,
      availableWalletAmount = 0,
      initialValues: { amount = 0 }
    } = this.props;

    return (
      <Dialog
        open={open}
        onBackdropClick={openCloseHandler}
        TransitionComponent={Transition}
        maxWidth="sm"
      >
        <DialogTitle>Approve Invoice</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container>
              {parseFloat(availableWalletAmount) > 0 &&
              parseFloat(availableWalletAmount) >= parseFloat(amount) ? (
                <Grid item xs={12}>
                  <p>
                    By approving this invoice, ${amount ? amount.toFixed(2) : 0}{" "}
                    removed from you wallet at the settlement process. Are you
                    sure you want to approve this invoice ?
                  </p>
                  <p style={{ textAlign: "right" }}>
                    <small>
                      Wallet Balance: $
                      {availableWalletAmount
                        ? availableWalletAmount.toFixed(2)
                        : 0}
                    </small>
                  </p>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Typography align="center">
                    You are not able to approve this invoice, because you don't
                    have enough funds in your wallet
                  </Typography>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            {parseInt(availableWalletAmount) > 0 ? (
              <RenderButton color="primary" type="submit">
                Approve Invoice
              </RenderButton>
            ) : (
              ""
            )}
            <RenderButton color="danger" onClick={openCloseHandler}>
              Cancel
            </RenderButton>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

LenderApproveInvoiceModal.defaultProps = {
  contractData: {
    contractTerms: {
      contractId: "",
      supplier: "",
      customer: "",
      paymentTerms: "",
      settlementPeriod: "",
      billingTimezone: "",
      contractEndDate: ""
    }
  }
};

const validate = createValidator({
  amount: [required, validateMaxValueParam]
});

LenderApproveInvoiceModal = withStyles(styles)(LenderApproveInvoiceModal);

export default reduxForm({
  form: "LenderApproveInvoiceModalForm",
  enableReinitialize: true,
  touchOnBlur: true,
  validate
})(LenderApproveInvoiceModal);
