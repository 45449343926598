// New Way
const actions = {
	// Misc
	SET_STATE: 'myaccount/SET_STATE',
	GET_COUNTRIES: 'myaccount/GET_COUNTRIES',
	GET_STATES: 'myaccount/GET_STATES',
	GET_ALL_STATES: 'myaccount/GET_ALL_STATES',
	GET_CURRENCIES: 'myaccount/GET_CURRENCIES',
	GET_BUSINESS_TYPES: 'myaccount/GET_BUSINESS_TYPES',

	// Account details
	GET_ACCOUNT_DETAILS: 'myaccount/GET_ACCOUNT_DETAILS',
	UPDATE_ACCOUNT_DETAILS: 'myaccount/UPDATE_ACCOUNT_DETAILS',
	UPDATE_ACCOUNT_PASSWORD: 'myaccount/UPDATE_ACCOUNT_PASSWORD',

	GET_BANK_ACCOUNTS: 'myaccount/GET_BANK_ACCOUNTS',
	CREATE_NEW_BANK_ACCOUNT: 'myaccount/CREATE_NEW_BANK_ACCOUNT',
	UPDATE_BANK_ACCOUNT: 'myaccount/UPDATE_BANK_ACCOUNT',
	DELETE_BANK_ACCOUNT: 'myaccount/DELETE_BANK_ACCOUNT',
	GET_PERMISSIONS: 'myaccount/GET_PERMISSIONS',

	// User administration
	CREATE_NEW_SUBACOUNT: 'myaccount/CREATE_NEW_SUBACOUNT',
	GET_USER_ACCOUNTS: 'myaccount/GET_USER_ACCOUNTS',
	GET_USER_ACCOUNT: 'myaccount/GET_USER_ACCOUNT',
	UPDATE_USER_SUBACOUNT: 'myaccount/UPDATE_USER_SUBACOUNT',
	REMOVE_USER_SUBACOUNT: 'myaccount/REMOVE_USER_SUBACOUNT',

	// Security
	GET_ACCOUNT_MFA_STATUS: 'myaccount/GET_ACCOUNT_MFA_STATUS',
	GET_ACCOUNT_MFA_QR_CODE: 'myaccount/GET_ACCOUNT_MFA_QR_CODE',
	UPDATE_ACCOUNT_MFA_ENABLE: 'myaccount/UPDATE_ACCOUNT_MFA_ENABLE',
	UPDATE_ACCOUNT_MFA_DISABLE: 'myaccount/UPDATE_ACCOUNT_MFA_DISABLE',

	// Legal agreements
	GET_PLATFORM_AGREEMENT_STATUS: 'myaccount/GET_PLATFORM_AGREEMENT_STATUS',
	GET_PLATFORM_AGREEMENT_INITIATE: 'myaccount/GET_PLATFORM_AGREEMENT_INITIATE',
	GET_FINANCING_AGREEMENT_STATUS: 'myaccount/GET_FINANCING_AGREEMENT_STATUS',
	GET_FINANCING_AGREEMENT_INITIATE: 'myaccount/GET_FINANCING_AGREEMENT_INITIATE',
	GET_CONTRACT_AGREEMENTS: 'myaccount/GET_CONTRACT_AGREEMENTS',
	GET_CONTRACT_AGREEMENT_BY_GUID: 'myaccount/GET_CONTRACT_AGREEMENT_BY_GUID',
	GET_ACP_PLATFORM_AGREEMENT_STATUS: 'myaccount/GET_ACP_PLATFORM_AGREEMENT_STATUS',
	GET_ACP_PLATFORM_AGREEMENT_INITIATE: 'myaccount/GET_ACP_PLATFORM_AGREEMENT_INITIATE',
	GET_ACP_FINANCING_AGREEMENT_STATUS: 'myaccount/GET_ACP_FINANCING_AGREEMENT_STATUS',
	GET_ACP_FINANCING_AGREEMENT_INITIATE: 'myaccount/GET_ACP_FINANCING_AGREEMENT_INITIATE',

	// Legal Documents
	GET_LEGAL_DOCUMENTS: 'myaccount/GET_LEGAL_DOCUMENTS',
	DOWNLOAD_LEGAL_DOCUMENT: 'myaccount/DOWNLOAD_LEGAL_DOCUMENT',

	// API Keys
	GET_API_KEYS: 'acp/GET_API_KEYS',

	// Lender settings
	GET_LENDER_SETTINGS_DATA: 'myaccount/GET_LENDER_SETTINGS_DATA',
	SET_LENDER_SETTINGS_DATA: 'myaccount/SET_LENDER_SETTINGS_DATA'
};

export default actions;

// Old Way

export const SET_STATE = 'account/SET_STATE';

export const MYACCOUNT_ACTION_POST_DATA_REQUEST = 'MYACCOUNT_ACTION_POST_DATA_REQUEST';

export const MYACCOUNT_ACTION_GET_DATA_REQUEST = 'MYACCOUNT_ACTION_GET_DATA_REQUEST';

export const MYACCOUNT_ACTION_GET_DATA_RESPONSE = 'MYACCOUNT_ACTION_GET_DATA_RESPONSE';

export const MYACCOUNT_ACTION_POST_DATA_RESPONSE = 'MYACCOUNT_ACTION_POST_DATA_RESPONSE';

export const BANK_ACCOUNT_ACTION_POST_DATA_REQUEST = 'BANK_ACCOUNT_ACTION_POST_DATA_REQUEST';

export const BANK_ACCOUNT_ACTION_REMOVE_POST_DATA_REQUEST = 'BANK_ACCOUNT_ACTION_REMOVE_POST_DATA_REQUEST';

export const BANK_ACCOUNT_ACTION_POST_DATA_RESPONSE = 'BANK_ACCOUNT_ACTION_POST_DATA_RESPONSE';

export const NEW_HARDWARE_ACTION_POST_DATA_REQUEST = 'NEW_HARDWARE_ACTION_POST_DATA_REQUEST';

export const NEW_HARDWARE_ACTION_POST_DATA_RESPONSE = 'NEW_HARDWARE_ACTION_POST_DATA_RESPONSE';

export const TECHNICAL_DETAILS_ACTION_POST_DATA_REQUEST = 'TECHNICAL_DETAILS_ACTION_POST_DATA_REQUEST';

export const TECHNICAL_DETAILS_ACTION_POST_DATA_RESPONSE = 'TECHNICAL_DETAILS_ACTION_POST_DATA_RESPONSE';

export const GET_COUNTRIES_ACTION_POST_DATA_REQUEST = 'GET_COUNTRIES_ACTION_POST_DATA_REQUEST';

export const GET_COUNTRIES_ACTION_POST_DATA_RESPONSE = 'GET_COUNTRIES_ACTION_POST_DATA_RESPONSE';

export const COUNTRIES = 'COUNTRIES';

export const MYACCOUNT_ACTION_UPDATE = 'MYACCOUNT_ACTION_UPDATE';

export const GET_CODECS_ACTION_POST_DATA_REQUEST = 'GET_CODECS_ACTION_POST_DATA_REQUEST';

export const GET_CODECS_ACTION_POST_DATA_RESPONSE = 'GET_CODECS_ACTION_POST_DATA_RESPONSE';

export const HARDWARE_CODECS = 'HARDWARE_CODECS';

export const GET_HARDWARE_PROFILE_OPTIONS_ACTION_POST_DATA_REQUEST = 'GET_HARDWARE_PROFILE_OPTIONS_ACTION_POST_DATA_REQUEST';

export const GET_HARDWARE_PROFILE_OPTIONS_ACTION_POST_DATA_RESPONSE = 'GET_HARDWARE_PROFILE_OPTIONS_ACTION_POST_DATA_RESPONSE';

export const HARDWARE_PROFILE_OPTIONS = 'HARDWARE_PROFILE_OPTIONS';

export const UPDATE_HARDWARE_PROFILE = 'UPDATE_HARDWARE_PROFILE';
export const REMOVE_HARDWARE_PROFILE = 'REMOVE_HARDWARE_PROFILE';

export const GET_STATES_ACTION_POST_DATA_REQUEST = 'GET_STATES_ACTION_POST_DATA_REQUEST';

export const GET_STATES_ACTION_POST_DATA_RESPONSE = 'GET_STATES_ACTION_POST_DATA_RESPONSE';

export const STATES = 'STATES';

export const GET_BANKSTATES_ACTION_POST_DATA_REQUEST = 'GET_BANKSTATES_ACTION_POST_DATA_REQUEST';

export const GET_BANKSTATES_ACTION_POST_DATA_RESPONSE = 'GET_BANKSTATES_ACTION_POST_DATA_RESPONSE';

export const BANKSTATES = 'BANKSTATES';

export const GET_BENEFICIARYSTATES_ACTION_POST_DATA_REQUEST = 'GET_BENEFICIARYSTATES_ACTION_POST_DATA_REQUEST';

export const GET_BENEFICIARYSTATES_ACTION_POST_DATA_RESPONSE = 'GET_BENEFICIARYSTATES_ACTION_POST_DATA_RESPONSE';

export const BENEFICIARYSTATES = 'BENEFICIARYSTATES';

export const GET_CORRESPONDENTSTATES_ACTION_POST_DATA_REQUEST = 'GET_CORRESPONDENTSTATES_ACTION_POST_DATA_REQUEST';

export const GET_CORRESPONDENTSTATES_ACTION_POST_DATA_RESPONSE = 'GET_CORRESPONDENTSTATES_ACTION_POST_DATA_RESPONSE';

export const CORRESPONDENTSTATES = 'CORRESPONDENTSTATES';

export const CREATE_NEW_WALLET_ACTION_POST_DATA_REQUEST = 'CREATE_NEW_WALLET_ACTION_POST_DATA_REQUEST';

export const CREATE_NEW_WALLET_ACTION_POST_DATA_RESPONSE = 'CREATE_NEW_WALLET_ACTION_POST_DATA_RESPONSE';

export const GET_BUSINESS_TYPE_ACTION_POST_DATA_REQUEST = 'GET_BUSINESS_TYPE_ACTION_POST_DATA_REQUEST';

export const GET_BUSINESS_TYPE_ACTION_POST_DATA_RESPONSE = 'GET_BUSINESS_TYPE_ACTION_POST_DATA_RESPONSE';

export const BUSINESS_TYPE = 'BUSINESS_TYPE';
export const POST_RESPONSE = 'POST_RESPONSE';

export const GET_PHONEPREFIX_ACTION_POST_DATA_RESPONSE = 'GET_PHONEPREFIX_ACTION_POST_DATA_RESPONSE';

export const GET_PHONEPREFIX_ACTION_POST_DATA_REQUEST = 'GET_PHONEPREFIX_ACTION_POST_DATA_REQUEST';

export const PHONEPREFIX = 'PHONEPREFIX';

export const MYACCOUNT_ACTION_UPLOAD_LOGO = 'MYACCOUNT_ACTION_UPLOAD_LOGO';
export const MYACCOUNT_ACTION_SIGN_PORTAL_AGREEMENT = 'MYACCOUNT_ACTION_SIGN_PORTAL_AGREEMENT';
export const MYACCOUNT_ACTION_SIGN_FINANCING_AGREEMENT = 'MYACCOUNT_ACTION_SIGN_FINANCING_AGREEMENT';
export const MYACCOUNT_ACTION_DOWNLOAD_PORTAL_AGREEMENT = 'MYACCOUNT_ACTION_DOWNLOAD_PORTAL_AGREEMENT';
export const MYACCOUNT_ACTION_DOWNLOAD_FINANCING_AGREEMENT = 'MYACCOUNT_ACTION_DOWNLOAD_FINANCING_AGREEMENT';

export const MYACCOUNT_MFA_QR_CODE = 'MYACCOUNT_MFA_QR_CODE';
export const MYACCOUNT_MFA_QR_CODE_GET_DATA_PNG = 'MYACCOUNT_MFA_QR_CODE_GET_DATA_PNG';

export const CHANGE_PASSWORD_ACTION_POST_DATA_REQUEST = 'CHANGE_PASSWORD_ACTION_POST_DATA_REQUEST';

export const CHANGE_PASSWORD_ACTION_POST_DATA_RESPONSE = 'CHANGE_PASSWORD_ACTION_POST_DATA_RESPONSE';

export const LENDER_SETTINGS_GET_DATA_REQUEST = 'LENDER_SETTINGS_GET_DATA_REQUEST';

export const ACTION_HANDLE_NEW_USER = 'ACTION_HANDLE_NEW_USER';

export const LENDER_SETTINGS_ERROR = 'LENDER_SETTINGS_ERROR';
export const LENDER_SETTINGS_SUCCESS = 'LENDER_SETTINGS_SUCCESS';
export const LENDER_SETTINGS_POST_DATA_REQUEST = 'LENDER_SETTINGS_POST_DATA_REQUEST';
export const CLEAR_LENDER_SETTINGS_MESSAGES = 'CLEAR_LENDER_SETTINGS_MESSAGES';

export const GET_EXTERNAL_CLIENT_DATA = 'GET_EXTERNAL_CLIENT_DATA';
export const EXTERNAL_CLIENT_DETAILS_ACTION_POST_DATA_REQUEST = 'EXTERNAL_CLIENT_DETAILS_ACTION_POST_DATA_REQUEST';
export const SET_EXTERNAL_CLIENTS = 'SET_EXTERNAL_CLIENTS';
export const MFAQR = 'MFAQR';

export const clearLenderSettingsMessages = () => ({
	type: CLEAR_LENDER_SETTINGS_MESSAGES,
	payload: null
});

export const actionPostLenderSettings = data => ({
	type: LENDER_SETTINGS_POST_DATA_REQUEST,
	payload: data
});

export const actionGetExternalClientData = data => ({
	type: GET_EXTERNAL_CLIENT_DATA,
	payload: data
});

export const setExternalClients = data => ({
	type: SET_EXTERNAL_CLIENTS,
	payload: data
});

export const actionGetLenderSettings = data => ({
	type: LENDER_SETTINGS_GET_DATA_REQUEST,
	payload: data
});

export const lenderSettingsFail = data => ({
	type: LENDER_SETTINGS_ERROR,
	payload: data
});

export const lenderSettingsSuccess = data => ({
	type: LENDER_SETTINGS_SUCCESS,
	payload: data
});

export const actionMyAccountPostDataRequest = data => ({
	type: MYACCOUNT_ACTION_POST_DATA_REQUEST,
	payload: data
});

export const actionMyAccountGetDataRequest = data => ({
	type: MYACCOUNT_ACTION_GET_DATA_REQUEST,
	payload: data
});

export const actionMyAccountGetDataResponse = data => ({
	type: MYACCOUNT_ACTION_GET_DATA_RESPONSE,
	payload: data
});

export const actionBankAccountPostDataRequest = data => ({
	type: BANK_ACCOUNT_ACTION_POST_DATA_REQUEST,
	payload: data
});

export const actionBankAccountPostDataResponse = data => ({
	type: BANK_ACCOUNT_ACTION_POST_DATA_RESPONSE,
	payload: data
});

export const actionRemoveBankAccountPostDataRequest = id => ({
	type: BANK_ACCOUNT_ACTION_REMOVE_POST_DATA_REQUEST,
	payload: id
});

export const actionNewHardwarePostDataRequest = data => ({
	type: NEW_HARDWARE_ACTION_POST_DATA_REQUEST,
	payload: data
});

export const actionNewHardwarePostDataResponse = data => ({
	type: NEW_HARDWARE_ACTION_POST_DATA_RESPONSE,
	payload: data
});

export const actionExternalClientDetailsPostDataRequest = data => ({
	type: EXTERNAL_CLIENT_DETAILS_ACTION_POST_DATA_REQUEST,
	payload: data
});

export const actionTechnicalDetailsPostDataRequest = data => ({
	type: TECHNICAL_DETAILS_ACTION_POST_DATA_REQUEST,
	payload: data
});

export const actionTechnicalDetailsPostDataResponse = data => ({
	type: TECHNICAL_DETAILS_ACTION_POST_DATA_RESPONSE,
	payload: data
});

export const actionGetCountriesPostDataRequest = data => ({
	type: GET_COUNTRIES_ACTION_POST_DATA_REQUEST,
	payload: data
});

export const actionGetCountriesPostDataResponse = data => ({
	type: GET_COUNTRIES_ACTION_POST_DATA_RESPONSE,
	payload: data
});

export const actionMyAccountDataUpdate = data => ({
	type: MYACCOUNT_ACTION_UPDATE,
	payload: data
});

export const actionGetCodecsPostDataRequest = data => ({
	type: GET_CODECS_ACTION_POST_DATA_REQUEST,
	payload: data
});

export const actionGetCodecsPostDataResponse = data => ({
	type: GET_CODECS_ACTION_POST_DATA_RESPONSE,
	payload: data
});

export const actionGetHardwareProfileOptionsPostDataRequest = data => ({
	type: GET_HARDWARE_PROFILE_OPTIONS_ACTION_POST_DATA_REQUEST,
	payload: data
});

export const actionGetHardwareProfileOptionsPostDataResponse = data => ({
	type: GET_HARDWARE_PROFILE_OPTIONS_ACTION_POST_DATA_RESPONSE,
	payload: data
});

export const actionRemoveHardwareProfile = hardwareProfileId => ({
	type: REMOVE_HARDWARE_PROFILE,
	payload: hardwareProfileId
});

export const actionUpdateHardwareProfile = data => ({
	type: UPDATE_HARDWARE_PROFILE,
	payload: data
});

export const actionGetStatesPostDataRequest = data => ({
	type: GET_STATES_ACTION_POST_DATA_REQUEST,
	payload: data
});

export const actionGetStatesPostDataResponse = data => ({
	type: GET_STATES_ACTION_POST_DATA_RESPONSE,
	payload: data
});

export const actionGetBankStatesPostDataRequest = data => ({
	type: GET_BANKSTATES_ACTION_POST_DATA_REQUEST,
	payload: data
});

export const actionGetBankStatesPostDataResponse = data => ({
	type: GET_BANKSTATES_ACTION_POST_DATA_RESPONSE,
	payload: data
});

export const actionGetBeneficiaryStatesPostDataRequest = data => ({
	type: GET_BENEFICIARYSTATES_ACTION_POST_DATA_REQUEST,
	payload: data
});

export const actionGetBeneficiaryStatesPostDataResponse = data => ({
	type: GET_BENEFICIARYSTATES_ACTION_POST_DATA_RESPONSE,
	payload: data
});

export const actionGetCorrespondentStatesPostDataRequest = data => ({
	type: GET_CORRESPONDENTSTATES_ACTION_POST_DATA_REQUEST,
	payload: data
});

export const actionGetCorrespondentStatesPostDataResponse = data => ({
	type: GET_CORRESPONDENTSTATES_ACTION_POST_DATA_RESPONSE,
	payload: data
});

export const actionCreateNewWalletPostDataRequest = data => ({
	type: CREATE_NEW_WALLET_ACTION_POST_DATA_REQUEST,
	payload: data
});

export const actionCreateNewWalletPostDataResponse = data => ({
	type: CREATE_NEW_WALLET_ACTION_POST_DATA_RESPONSE,
	payload: data
});

export const actionGetBusinessTypePostDataRequest = data => ({
	type: GET_BUSINESS_TYPE_ACTION_POST_DATA_REQUEST,
	payload: data
});

export const actionGetBusinessTypePostDataResponse = data => ({
	type: GET_BUSINESS_TYPE_ACTION_POST_DATA_RESPONSE,
	payload: data
});

export const setPostResponse = data => ({
	type: POST_RESPONSE,
	payload: data
});

export const actionGetPhonePrefixPostDataRequest = data => ({
	type: GET_PHONEPREFIX_ACTION_POST_DATA_REQUEST,
	payload: data
});

export const actionGetPhonePrefixPostDataResponse = data => ({
	type: GET_PHONEPREFIX_ACTION_POST_DATA_RESPONSE,
	payload: data
});

export const actionUploadLogo = data => ({
	type: MYACCOUNT_ACTION_UPLOAD_LOGO,
	payload: data
});

export const actionSignPortalAgreement = data => ({
	type: MYACCOUNT_ACTION_SIGN_PORTAL_AGREEMENT,
	payload: data
});

export const actionSignFinancingAgreement = data => ({
	type: MYACCOUNT_ACTION_SIGN_FINANCING_AGREEMENT,
	payload: data
});

export const actionDownloadPortalAgreement = data => ({
	type: MYACCOUNT_ACTION_DOWNLOAD_PORTAL_AGREEMENT,
	payload: data
});

export const handleFinancingAgreementDownload = data => ({
	type: MYACCOUNT_ACTION_DOWNLOAD_FINANCING_AGREEMENT,
	payload: data
});

export const actionGetQRCode = data => ({
	type: MYACCOUNT_MFA_QR_CODE,
	payload: data
});

export const actionHandleNewUser = data => ({
	type: ACTION_HANDLE_NEW_USER,
	payload: data
});

export const actionGetQrCodeResponse = data => ({
	type: MYACCOUNT_MFA_QR_CODE_GET_DATA_PNG,
	payload: data
});

export const actionChangePasswordPostDataRequest = data => ({
	type: CHANGE_PASSWORD_ACTION_POST_DATA_REQUEST,
	payload: data
});
