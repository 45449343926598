import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  MenuItem
} from "@material-ui/core";
import { RenderButton, RenderSelect, RenderInput } from "components";

const Transition = props => <Slide direction="up" {...props} />;

class EditUserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  handleOpenModal = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  render() {
    const { isOpen } = this.state;

    return (
      <div>
        {/* <RenderButton color="primary" onClick={this.handleOpenModal}>
          Edit
        </RenderButton> */}
        <Dialog
          maxWidth="sm"
          fullWidth
          open={isOpen}
          onBackdropClick={this.handleOpenModal}
          TransitionComponent={Transition}
        >
          <form>
            <DialogTitle>Edit user</DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  <Field
                    component={RenderInput}
                    id="name"
                    name="name"
                    label="Name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={RenderInput}
                    id="email"
                    name="email"
                    label="Email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    id="role"
                    name="role"
                    label="Role"
                    component={RenderSelect}
                    options={[
                      {
                        value: "Financial",
                        label: "Financial"
                      },
                      {
                        value: "Technical",
                        label: "Tech Admin"
                      },
                      {
                        value: "NOC",
                        label: "NOC"
                      },
                      {
                        value: "Reporting",
                        label: "Reporting"
                      },
                      {
                        value: "Sales",
                        label: "Sales"
                      }
                    ]}
                  />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <RenderButton color="danger" onClick={this.handleOpenModal}>
                Cancel
              </RenderButton>
              <RenderButton color="primary" onClick={this.handleOpenModal}>
                Save
              </RenderButton>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}

export default reduxForm({
  form: "EditUser",
  destroyOnUnmount: true,
  enableReinitialize: true
})(EditUserForm);
