import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';

import { CurrencyNumber } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import usageSummaryStyle from './style';
import {useTranslation} from "react-i18next";

const CutomTableHeadCell = withStyles(theme => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white
	}
}))(TableCell);

const CustomTableRow = withStyles({
	root: {
		height: 'auto'
	}
})(TableRow);

const CustomRow = ({ firstCellContent, secondCellContent, thirdCellContent, forthCellContent, fifthCellContent, sixthCellContent, seventhCellContent }) => (
	<CustomTableRow>
		<TableCell>{firstCellContent}</TableCell>
		<TableCell>{secondCellContent}</TableCell>
		<TableCell>{thirdCellContent}</TableCell>
		<TableCell numeric>{forthCellContent}</TableCell>
		<TableCell numeric>{fifthCellContent}</TableCell>
		<TableCell numeric>{sixthCellContent}</TableCell>
		<TableCell numeric>{seventhCellContent}</TableCell>
	</CustomTableRow>
);

CustomRow.defaultProps = {
	firstCellContent: '',
	secondCellContent: '',
	thirdCellContent: '',
	forthCellContent: '',
	fifthCellContent: '',
	sixthCellContent: '',
	seventhCellContent: ''
};

CustomRow.propTypes = {
	firstCellContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	secondCellContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	thirdCellContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	forthCellContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	fifthCellContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	sixthCellContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	seventhCellContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

const AddThousandSeparator = (amount) => amount !== undefined ? Number(amount).toLocaleString() : 0;

const UsageSummary = props => {
	const { data, classes } = props;
	const { t } = useTranslation('financials/general');

	return (
		<div>
			<Typography variant="headline" align="center" className={classes.headline}>
				{t('invoices.inv_summary')}
			</Typography>

			<Table className={classes.table}>
				<TableHead>
					<CustomTableRow>
						<CutomTableHeadCell>{t('invoices.description')}</CutomTableHeadCell>
						<CutomTableHeadCell>{t('invoices.eff_date')}</CutomTableHeadCell>
						<CutomTableHeadCell>{t('invoices.rate_period')}</CutomTableHeadCell>
						<CutomTableHeadCell numeric>{t('invoices.calls')}</CutomTableHeadCell>
						<CutomTableHeadCell numeric>{t('invoices.minutes')}</CutomTableHeadCell>
						<CutomTableHeadCell numeric>{t('invoices.amount')}</CutomTableHeadCell>
						<CutomTableHeadCell numeric>{t('invoices.rate_per_min')}</CutomTableHeadCell>
					</CustomTableRow>
				</TableHead>
				<TableBody>
					{data.usageCollection.map(usage => (
						<CustomRow
							key={`usage${usage.DESC}`}
							firstCellContent={usage.DESC}
							secondCellContent={moment(usage.EFF_DATE).format('MMM DD YYYY')}
							thirdCellContent={usage.RATE_PER}
							forthCellContent={AddThousandSeparator(usage.CALLS)}
							fifthCellContent={AddThousandSeparator(usage.MINUTES)}
							sixthCellContent={<CurrencyNumber amount={(usage.AMOUNT)} />}
							seventhCellContent={<CurrencyNumber amount={usage.RPM} precision={6} />}
						/>
					))}
					<CustomTableRow>
						<TableCell>
							<Typography variant="body2">Totals</Typography>
						</TableCell>
						<TableCell />
						<TableCell />
						<TableCell numeric>{AddThousandSeparator(data.total.calls)}</TableCell>
						<TableCell numeric>{AddThousandSeparator(data.total.minutes)}</TableCell>
						<TableCell numeric>
							<CurrencyNumber amount={data.total.amount} />
						</TableCell>
						<TableCell numeric />
					</CustomTableRow>
				</TableBody>
			</Table>
		</div>
	);
};

UsageSummary.propTypes = {
	classes: PropTypes.shape().isRequired,
	data: PropTypes.shape().isRequired
};

export default withStyles(usageSummaryStyle)(UsageSummary);
