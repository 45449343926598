import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography, Paper } from '@material-ui/core';
import { Add, Remove, ContentCopy } from '@material-ui/icons';

import Loader from 'components/atoms/Loader';
import actions from 'store/myaccount/actions';

import styles from './styles.module.scss';

export default memo(() => {
	const dispatch = useDispatch();

	const isLoading = useSelector(state => (state.acp && state.acp.loading) || false);
	const apiKeys = useSelector(state => (state.myaccount && state.myaccount.apiKeys) || []);
	const [notification, setNotification] = useState('');

	useEffect(() => {
		dispatch({ type: actions.GET_API_KEYS });
	}, []);

	const handleCopyClick = (token) => {
		navigator.clipboard.writeText(token);
		setNotification('Token copied to clipboard!');
		setTimeout(() => {
		  setNotification('');
		}, 2000);
	  };

	return (
		<div className={styles.container}>
		  <Paper>
		<Grid container className="grid-container">
		  <Grid item xs={12}>
			<Typography variant="h1" gutterBottom>
			  API Keys
			</Typography>
		  </Grid>
		  <Grid item xs={12}>
			{(isLoading && <Loader isLoading />) || (
			  <div>
				{apiKeys.map((apiKey, index) => (
				  <div key={index} style={{marginBottom: "10px"}}>
						<Grid container style={{ display: 'flex', alignItems: 'baseline' }}>
							<Grid item xs={4} style={{ marginRight: '5px', fontWeight: 'bold', fontSize: '15px' }}>
								{apiKey.token}
							</Grid>
							<Grid item xs={1}>
								<button
									type="button"
									onClick={() => handleCopyClick(apiKey.token)}
									style={{ marginLeft: '5px' }}
								>
									<ContentCopy />
								</button>
							</Grid>
						</Grid>
				  </div>
				))}
			  </div>
			)}
		  </Grid>
		</Grid>
	  </Paper>
	  {notification && (
        <div className={styles.notification}>
          <span>{notification}</span>
        </div>
      )}
	</div>
  );
  
});
