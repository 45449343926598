import React, { Component } from 'react';
import { reduxForm, change, Field } from 'redux-form';
import { RenderInput, RenderButton, RenderMaterialSwitch, RenderNotification, RenderCheckbox } from 'components';
import { createValidator, requiredString, maxLength, isNumber, isOptionalNumber } from 'services/validation';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Typography, CardContent, Paper, FormControl, InputAdornment, IconButton, CircularProgress } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

class LenderSettingsForm extends Component {
	constructor(props) {
		super(props);
		const {
			// eslint-disable-next-line react/prop-types
			initialValues: {
				sftp: { active }
			}
		} = this.props;
		this.state = {
			showPassword: false,
			isSFTPConfigDisabled: !active
		};
	}

	handleClickShowPassword = () => {
		this.setState(state => ({ showPassword: !state.showPassword }));
	};

	setGenerationActiveRef = ref => {
		this.generationActiveRef = ref;
	};

	setSFTPSyncActiveRef = ref => {
		this.SFTPActiveRef = ref;
	};

	generationActiveRef;

	SFTPActiveRef;

	render() {
		const {
			handleSubmit,
			onSFTPConfigToggle,
			forceGeneration,
			submitSucceeded,
			pristine,
			clearLenderSettingsMessages,
			lenderSettingsData: { isLoading, error, info }
		} = this.props;

		const showLoader = showLoading => {
			if (showLoading) {
				return (
					<div>
						<Typography variant="body1" align="center">
							<CircularProgress color="secondary" size={20} />
							<span>&nbsp;&nbsp;&nbsp;Validating configuration...</span>
						</Typography>
					</div>
				);
			}
			return false;
		};

		const { showPassword, isSFTPConfigDisabled } = this.state;
		return (
			<form onSubmit={handleSubmit}>
				<RenderNotification variant="danger" floating open={!!error} message={error} onClose={() => clearLenderSettingsMessages()} />
				<RenderNotification variant="success" floating open={!!info} message={info} onClose={() => clearLenderSettingsMessages()} />
				<Grid container>
					<Grid item xs={12}>
						<Paper>
							<CardContent>
								<Typography variant="h1" gutterBottom>
									Invoice and CDR Synchronization
								</Typography>
								<Grid container alignItems="flex-end">
									<Grid item xs={4}>
										<FormControl component="fieldset">
											<Field type="hidden" name="accountData.participant.id" component="input" />
											<Field
												component={RenderMaterialSwitch}
												label="Activate generation"
												name="generation.active"
												ref={this.setGenerationActiveRef}
												readOnly={forceGeneration}
												forceCheck={forceGeneration}
											/>
										</FormControl>
									</Grid>
								</Grid>
								<Grid container alignItems="flex-end">
									<Grid item xs={12}>
										<Typography variant="body2" gutterBottom>
											Select synchronization data
										</Typography>
									</Grid>
									<Grid item xs={4}>
										<FormControl component="fieldset">
											<Field component={RenderCheckbox} label="Invoice in PDF format" name="generation.isPDFInvoiceSyncActive" />
										</FormControl>
									</Grid>
									<Grid item xs={4}>
										<FormControl component="fieldset">
											<Field component={RenderCheckbox} label="Invoice in CSV format " name="generation.isCSVInvoiceSyncActive" />
										</FormControl>
									</Grid>
									<Grid item xs={4}>
										<FormControl component="fieldset">
											<Field component={RenderCheckbox} label="Debtor file" name="generation.isDebtorFileSyncActive" />
										</FormControl>
									</Grid>
									<Grid item xs={4}>
										<FormControl component="fieldset">
											<Field component={RenderCheckbox} label="CDRs" name="generation.isCDRSyncActive" />
										</FormControl>
									</Grid>
								</Grid>
								<Grid container alignItems="flex-end">
									<Grid item xs={4}>
										<Typography variant="h1" gutterBottom>
											SFTP Configuration
										</Typography>
									</Grid>
									<FormControl component="fieldset">
										<Field
											component={RenderMaterialSwitch}
											label="Enable Synchronization"
											name="sftp.active"
											ref={this.setSFTPSyncActiveRef}
											callback={() => {
												const { value } = this.SFTPActiveRef;
												// if value is false => avtive
												// if value is true => inactive
												this.setState({ isSFTPConfigDisabled: value }, () => {
													onSFTPConfigToggle(!value);
												});
											}}
										/>
									</FormControl>
								</Grid>
								<Grid container alignItems="flex-end">
									<Grid item xs={4}>
										<Field component={RenderInput} name="sftp.credentials.host" id="host" label="Hostname" disabled={isSFTPConfigDisabled} />
									</Grid>
									<Grid item xs={4}>
										<Field component={RenderInput} name="sftp.credentials.port" id="port" label="Port" disabled={isSFTPConfigDisabled} />
									</Grid>
									<Grid item xs={4}>
										<Field component={RenderInput} name="sftp.credentials.remotePath" id="remotePath" label="Remote Path" disabled={isSFTPConfigDisabled} />
									</Grid>
									<Grid item xs={4}>
										<Field component={RenderInput} name="sftp.credentials.username" id="username" label="Username" disabled={isSFTPConfigDisabled} />
									</Grid>
									<Grid item xs={4}>
										<Field
											component={RenderInput}
											name="sftp.credentials.pwd"
											id="pwd"
											type={showPassword ? 'text' : 'password'}
											label="Password"
											InputLabelProps={{
												shrink: true
											}}
											InputProps={{
												endAdornment: isSFTPConfigDisabled ? (
													''
												) : (
													<InputAdornment position="end">
														<IconButton
															aria-label="Toggle password visibility"
															onClick={this.handleClickShowPassword}
															onMouseDown={this.handleMouseDownPassword}
														>
															{showPassword ? <VisibilityOff /> : <Visibility />}
														</IconButton>
													</InputAdornment>
												)
											}}
											disabled={isSFTPConfigDisabled}
										/>
									</Grid>
								</Grid>
								<Grid container justify="center">
									<Grid item>
										<br />
										{showLoader(isLoading) || (
											<RenderButton type="submit" color="primary" disabled={submitSucceeded || pristine}>
												Validate & Save
											</RenderButton>
										)}
									</Grid>
								</Grid>
							</CardContent>
						</Paper>
					</Grid>
				</Grid>
			</form>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			change
		},
		dispatch
	);
}

const validate = createValidator({
	participantName: [requiredString, maxLength(40)],
	businessTypeId: [requiredString],
	countryId: [requiredString],
	stateId: [requiredString],
	city: [requiredString],
	faxNumber: [isOptionalNumber],
	phoneNumber: [isNumber]
});

LenderSettingsForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	onSFTPConfigToggle: PropTypes.func.isRequired,
	forceGeneration: PropTypes.bool.isRequired,
	clearLenderSettingsMessages: PropTypes.func.isRequired,
	lenderSettingsData: PropTypes.shape().isRequired
};

const LenderSettingsFormReduxForm = reduxForm({
	form: 'LenderSettingsForm',
	validate,
	enableReinitialize: true
})(LenderSettingsForm);

export default connect(null, mapDispatchToProps)(LenderSettingsFormReduxForm);
