import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormControl,
  FormGroup
} from '@material-ui/core';
import checkboxStyle from "./style";

const RenderCheckbox = ({ input, label, value, classes, meta: { touched, error }, ...rest }) => {
  const hasError = typeof error !== typeof undefined && touched;
  return (
    <FormControl error={hasError}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              {...input}
              {...rest}
              value={value}
              checked={input.value ? true : false}
              onChange={input.onChange}
            />
          }
          label={label}
        />
        {hasError && <span className={classes.errorLabel}><FormHelperText>{error}</FormHelperText></span>}
      </FormGroup>
    </FormControl>
  );
};

export default withStyles(checkboxStyle)(RenderCheckbox);

