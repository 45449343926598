import React from "react";
import { Accordion, AccordionItem } from "react-sanfona";

const ExplorerContentItem = props => {
  const { id, value, children } = props;
  const text = `${id} - ${value}`;

  return (
    <Accordion className="explorer-accordion">
      <AccordionItem
        titleTag="h5"
        bodyTag="div"
        title={text}
        titleClassName="card-header"
        style={{ cursor: "pointer" }}
        className="explorer-accordion-title"
      >
        <div className="card-body">{children}</div>
      </AccordionItem>
    </Accordion>
  );
};

export default ExplorerContentItem;
