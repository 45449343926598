const welcomeChartsStyles = theme => ({
  root: {
    padding: theme.spacing(1),
    position: "relative",
    overflow: "hidden"
  },
  fullHeightContainer: {
    height: `calc(100vh - ${theme.spacing(1) * 24}px)`,
    overflow: "auto"
  },
  smartContractsContainer: {
    // paddingLeft: theme.spacing(1) * 2,
    // paddingRight: theme.spacing(1) * 2,
    position: "relative"
    // "&:before": {
    //   content: "''",
    //   border: `1px solid ${theme.palette.grey.main}`,
    //   display: "block",
    //   width: "1px",
    //   position: "absolute",
    //   top: 0,
    //   bottom: 0,
    //   left: theme.spacing(1) / 2
    // }
  },
  smartContract: {
    cursor: "pointer",
    marginBottom: theme.spacing(1) * 2,
    fontSize: "11px",
    position: "relative",
    "&:hover $smartContractBullet, &.active $smartContractBullet": {
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main
    }
  },
  smartContractBullet: {
    border: `1px solid ${theme.palette.grey.main}`,
    color: theme.palette.grey.main,
    fontSize: "16px",
    borderRadius: "50%",
    padding: "1px",
    position: "absolute",
    transform: "translateY(-50%)",
    top: "50%",
    left: "-19px",
    backgroundColor: theme.palette.light
  },
  smartCtitle: {
    marginBottom: theme.spacing(1)
  },
  radioGroup: {
    alignItems: "center",
    flexGrow: "1"
  },
  radioLabel: {
    marginBottom: "0"
  },
  filterContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end"
  },
  filterContainerHidden: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: theme.palette.light,
    transition: "transform .3s ease",
    willChange: "transform",
    "&.closed": {
      transform: "translateX(105%)"
    }
  },
  filterItem: {
    textAlign: "center",
    padding: `${theme.spacing(1) / 4}px ${theme.spacing(1) / 2}px`,
    border: `1px solid ${theme.palette.grey.main}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.grey.main,
    cursor: "pointer",
    marginLeft: theme.spacing(1),
    fontSize: ".8rem",
    "&:hover, &.active": {
      borderColor: theme.palette.secondary.main,
      color: theme.palette.secondary.main
    }
  },
  textRight: {
    textAlign: "right"
  },
  textPrimary: {
    color: theme.palette.primary.main
  },
  textBold: {
    fontWeight: theme.typography.fontWeightBold
  },
  flex: {
    display: "flex"
  },
  dailyEarnings: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "right",
    flexGrow: "1"
  },
  filterItemInput: {
    width: "100%",
    margin: 0,
    backgroundColor: "transparent",
    outline: 0,
    textAlign: "left"
  },
  activeCallsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1) / 2
  },
  activeCallsCell: {
    width: "20%"
  },
  activeCallsCellLarge: {
    width: "35%"
  },
  activeCallsCellData: {
    marginLeft: theme.spacing(1) / 2,
    fontSize: "11px",
    wordBreak: "break-word",
    "&:not(:last-child)": {
      borderRight: `1px solid ${theme.palette.grey.light}`,
      marginRight: theme.spacing(1) / 2
    }
  },
  chartContainer: {
    marginBottom: theme.spacing(1)
  }
});

export default welcomeChartsStyles;
