import React, { Component } from "react";
import { Field } from "redux-form";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PropTypes from "prop-types";

class AcceptOrderForm extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {}

	componentWillUnmount() {}

	render() {
		return (
			<Modal isOpen>
				{this.props.initialValues ? (
					<form onSubmit={this.props.handleSubmit}>
						<ModalHeader>Order Form</ModalHeader>
						<ModalBody>
							<div className="form-group">
								<label htmlFor="interest-rate">Interest Rate</label>
								<Field
									component="input"
									type="text"
									className="form-control"
									name="interestRate"
									placeholder=""
									readOnly
								/>
								<Field
									component="input"
									readOnly
									type="hidden"
									className="form-control"
									name="id"
									placeholder=""
								/>
							</div>
							<div className="form-group">
								<label htmlFor="interest-rate">Daily Settlement Amount</label>
								<Field
									component="input"
									type="text"
									readOnly
									className="form-control"
									name="dailyAllocatedAmount"
									placeholder=""
								/>
							</div>
							<div className="form-group">
								<label htmlFor="interest-rate">Credit Rating</label>
								<Field
									component="input"
									type="number"
									readOnly
									step="1"
									value="{{ rand(2,8) }}"
									className="form-control"
									name="minimumCreditRating"
									placeholder=""
								/>
							</div>
							<div className="form-group">
								<label htmlFor="interest-rate">Payment Terms</label>
								<Field
									component="input"
									type="text"
									readOnly
									className="form-control"
									placeholder="30net 30"
									name="maximumPaymentTerms"
								/>
							</div>
						</ModalBody>
						<ModalFooter>
							<button type="submit" className="btn btn-danger btn-accept-order"
									disabled={this.props.typeAccept}>
								Accept Order
							</button>
							<button
								type="button"
								className="btn btn-default"
								data-dismiss="modal"
								onClick={() => this.props.hideAcceptOpenOrder()}
							>
								Cancel
							</button>
						</ModalFooter>
					</form>
				) : (
					<div>
						<ModalBody>
							<div className="form-group">
								<label htmlFor="interest-rate">There was an error</label>
							</div>
						</ModalBody>
						<ModalFooter>
							<button
								type="button"
								className="btn btn-default"
								data-dismiss="modal"
								onClick={() => this.props.hideAcceptOpenOrder()}
							>
								Cancel
							</button>
						</ModalFooter>
					</div>
				)}
			</Modal>
		);
	}
}
AcceptOrderForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	hideAcceptOpenOrder: PropTypes.func.isRequired,
};
export default AcceptOrderForm;
