export const actions = {
	SET_STATE: 'smartcontracts/SET_STATE',

	GET_PROJECTS: 'smartcontracts/GET_PROJECTS',
	GET_PROJECT: 'smartcontracts/GET_PROJECT',
	CREATE_PROJECT: 'smartcontracts/CREATE_PROJECT',
	UPDATE_PROJECT: 'smartcontracts/UPDATE_PROJECT',
	DELETE_PROJECT: 'smartcontracts/DELETE_PROJECT',

	ADD_RATES_PLAN: 'smartcontracts/ADD_RATES_PLAN',
	DELETE_RATE_PLAN: 'smartcontracts/DELETE_RATE_PLAN',
	DELETE_RATE_PLANS_BY_TEMPID: 'smartcontracts/DELETE_RATE_PLANS_BY_TEMPID',
	CREATE_CONTRACT: 'smartcontracts/CREATE_CONTRACT',
	GET_SMARTCONTRACTS: 'smartcontracts/GET_SMARTCONTRACTS',
	GET_SMARTCONTRACT_BY_ID: 'smartcontracts/GET_SMARTCONTRACT_BY_ID',
	GET_CREATE_CONTRACT: 'smartcontracts/GET_CREATE_CONTRACT',
	GET_NEW_SMART_CONTRACT_DATA: 'smartcontracts/GET_NEW_SMART_CONTRACT_DATA',
	UPDATE_SMARTCONTRACT: 'smartcontracts/UPDATE_SMARTCONTRACT',
	SAVE_SMART_CONTRACT_DRAFT: 'smartcontracts/SAVE_SMART_CONTRACT_DRAFT',
	GET_SMART_CONTRACT: 'smartcontracts/GET_SMART_CONTRACT',
	UPDATE_SMART_CONTRACT: 'smartcontracts/UPDATE_SMART_CONTRACT',

	DOWNLOAD_SAMPLE_RATEPLAN: 'smartcontracts/DOWNLOAD_SAMPLE_RATEPLAN',
	UPLOAD_RATES_FILE: 'smartcontracts/UPLOAD_RATES_FILE'
};
export const LOAD_SMART_CONTRACTS = 'LOAD_SMART_CONTRACTS';
export const INITIAL_SMART_CONTRACTS = 'INITIAL_SMART_CONTRACTS';
export const SMART_CONTRACT_NEW_SMART_CONTRACT_DATA = 'SMART_CONTRACT_NEW_SMART_CONTRACT_DATA';
export const SMART_CONTRACT_REQUEST_NEW_SMART_CONTRACT_DATA = 'SMART_CONTRACT_REQUEST_NEW_SMART_CONTRACT_DATA';
export const SUCCESS_LIST_SMART_CONTRACT = 'SUCCESS_LIST_SMART_CONTRACT';
export const INITIAL_NEW_SMART_CONTRACT = 'INITIAL_NEW_SMART_CONTRACT';
export const FAILURE_DATA_SMART_CONTRACT = 'FAILURE_DATA_SMART_CONTRACT';
export const SMART_CONTRACT_PAYMENT_TERMS = 'SMART_CONTRACT_PAYMENT_TERMS';
export const SUCCESS_INITIAL_NEW_SMART_CONTRACT = 'SUCCESS_INITIAL_NEW_SMART_CONTRACT';
export const LOAD_DESTINATIONS_TYPE_CARRIER = 'LOAD_DESTINATIONS_TYPE_CARRIER';
export const SUCCESS_DESTINATIONS_TYPE_CARRIER = 'SUCCESS_DESTINATIONS_TYPE_CARRIER';
export const LOAD_DESTINATIONS_CARRIER = 'LOAD_DESTINATIONS_CARRIER';
export const SUCCESS_DESTINATIONS_CARRIER = 'SUCCESS_DESTINATIONS_CARRIER';
export const LOAD_DIAL_CODES_TYPE_CARRIER_COUNTRY = 'LOAD_DIAL_CODES_TYPE_CARRIER_COUNTRY';
export const CREATE_SMART_CONTRACT = 'CREATE_SMART_CONTRACT';
export const CREATE_SMART_CONTRACT_FAILURE = 'CREATE_SMART_CONTRACT_FAILURE';
export const UPDATE_SMART_CONTRACT = 'UPDATE_SMART_CONTRACT';
export const UPDATE_SMART_CONTRACT_FAILURE = 'UPDATE_SMART_CONTRACT_FAILURE';
export const LOAD_IP_ADDRESS_SMART_CONTRACTS = 'LOAD_IP_ADDRESS_SMART_CONTRACTS';
export const LOAD_IP_ADDRESS_SUCCESS = 'LOAD_IP_ADDRESS_SUCCESS';

export const OPERATION_SUCCESS = 'OPERATION_SUCCESS';
export const OPERATION_FAILED = 'OPERATION_FAILED';

export const CATALOGS_SMART_CONTRACT = 'CATALOGS_SMART_CONTRACT';
export const CATALOGS_SMART_CONTRACT_SUCCESS = 'CATALOGS_SMART_CONTRACT_SUCCESS';

export const FUNDERS_SMART_CONTRACT = 'FUNDERS_SMART_CONTRACT';
export const FUNDERS_SMART_CONTRACT_SUCCESS = 'FUNDERS_SMART_CONTRACT_SUCCESS';

export const BIDDING_SMART_CONTRACT = 'BIDDING_SMART_CONTRACT';
export const BIDDING_SMART_CONTRACT_SUCCESS = 'BIDDING_SMART_CONTRACT_SUCCESS';

export const SMART_CONTRACT_LINK = 'SMART_CONTRACT_LINK';
export const SMART_CONTRACT_LINK_SUCCESS = 'SMART_CONTRACT_LINK_SUCCESS';

export const TERMINATE_SMART_CONTRACT = 'TERMINATE_SMART_CONTRACT';
export const SAVE_FUNDER_SMART_CONTRACT = 'SAVE_FUNDER_SMART_CONTRACT';
export const SAVE_BIDDINGPORTAL_SMART_CONTRACT = 'SAVE_BIDDINGPORTAL_SMART_CONTRACT';
export const SAVE_ALLOCATE_SMART_CONTRACT = 'SAVE_ALLOCATE_SMART_CONTRACT';

/** Lender Flow Actions */
export const SAVE_FUNDING_REQUEST = 'SAVE_FUNDING_REQUEST';
export const SAVE_FUNDING_REVIEW = 'SAVE_FUNDING_REVIEW';
export const SAVE_FUNDING_REVIEW_REQUEST = 'SAVE_FUNDING_REVIEW_REQUEST';
export const LOAD_FUNDING_REVIEW_REQUEST = 'LOAD_FUNDING_REVIEW_REQUEST';
/** End Lender Flow Action */

export const SIGN_SMART_CONTRACT = 'SIGN_SMART_CONTRACT';

export const LOAD_SMART_CONTRACT = 'LOAD_SMART_CONTRACT';
export const LOAD_SMART_CONTRACT_SUCCESS = 'LOAD_SMART_CONTRACT_SUCCESS';
export const LOAD_SMART_CONTRACT_FAILED = 'LOAD_SMART_CONTRACT_FAILED';

export const SMART_CONTRACT_COUNT_ACTION_GET_DATA_REQUEST = 'SMART_CONTRACT_COUNT_ACTION_GET_DATA_REQUEST';

export const SMART_CONTRACT_COUNT_ACTION_GET_DATA_RESPONSE = 'SMART_CONTRACT_COUNT_ACTION_GET_DATA_RESPONSE';

export const SMART_CONTRACT_COUNT_DATA = 'SMART_CONTRACT_COUNT_DATA';

export const SMART_CONTRACT_ADDRESS_ACTION_GET_DATA_REQUEST = 'SMART_CONTRACT_ADDRESS_ACTION_GET_DATA_REQUEST';

export const SMART_CONTRACT_ADDRESS_ACTION_GET_DATA_RESPONSE = 'SMART_CONTRACT_ADDRESS_ACTION_GET_DATA_RESPONSE';

export const SMART_CONTRACT_ADDRESS_DATA = 'SMART_CONTRACT_ADDRESS_DATA';

export const RATES_SMART_CONTRACT = 'RATES_SMART_CONTRACT';

export const RATES_SMART_CONTRACT_SUCCESS = 'RATES_SMART_CONTRACT_SUCCESS';
export const RATES_SMART_CONTRACT_FAILED = 'RATES_SMART_CONTRACT_FAILED';

export const SMART_CONTRACT_PROPOSED_RATES_ACTION = 'SMART_CONTRACT_PROPOSED_RATES_ACTION';

export const SMART_CONTRACT_PROPOSED_RATES_CLEAR = 'SMART_CONTRACT_PROPOSED_RATES_CLEAR';

export const SMART_CONTRACT_PROPOSED_RATES_SUCCESS = 'SMART_CONTRACT_PROPOSED_RATES_SUCCESS';

export const SMART_CONTRACT_PROPOSED_RATES_FAILED = 'SMART_CONTRACT_PROPOSED_RATES_FAILED';

export const DOWNLOAD_SMART_CONTRACT_RATE = 'DOWNLOAD_SMART_CONTRACT_RATE';

export const DOWNLOAD_SAMPLE_RATE_PLAN = 'DOWNLOAD_SAMPLE_RATE_PLAN';

export const SMART_CONTRACTS_CATALOG_DATA = 'SMART_CONTRACTS_CATALOG_DATA';

export const INITIALIZE_SMART_CONTRACT_RATE_PLAN = 'INITIALIZE_SMART_CONTRACT_RATE_PLAN';

export const SMART_CONTRACT_CREATE_UPDATE_SUCCESS = 'SMART_CONTRACT_CREATE_UPDATE_SUCCESS';

export const UPLOAD_SMART_CONTRACT_RATE_PLAN = 'UPLOAD_SMART_CONTRACT_RATE_PLAN';

export const SMART_CONTRACT_RATE_PLAN_SUCCESS = 'SMART_CONTRACT_RATE_PLAN_SUCCESS';

export const SMART_CONTRACT_RATE_PLAN_ERROR = 'SMART_CONTRACT_RATE_PLAN_ERROR';

export const CLEAR_SMART_CONTRACT_RATE_PLAN = 'CLEAR_SMART_CONTRACT_RATE_PLAN';

export const SMART_CONTRACT_GET_FUNDING_OFFERS = 'SMART_CONTRACT_GET_FUNDING_OFFERS';

export const SMART_CONTRACT_FUNDING_OFFERS = 'SMART_CONTRACT_FUNDING_OFFERS';
export const SMART_CONTRACT_FUNDING_OFFERS_MANAGE_REQUEST = 'SMART_CONTRACT_FUNDING_OFFERS_MANAGE_REQUEST';

export const SMART_CONTRACT_ALLOCATE_FUNDS_REQUEST = 'SMART_CONTRACT_ALLOCATE_FUNDS_REQUEST';

export const SMART_CONTRACT_WALLET_DETAILS_REQUEST = 'SMART_CONTRACT_WALLET_DETAILS_REQUEST';

export const SMART_CONTRACT_WALLET_DETAILS_DATA = 'SMART_CONTRACT_WALLET_DETAILS_DATA';

export const UPLOAD_SMART_CONTRACT_IMMEDIATE_RATE_PLAN = 'UPLOAD_SMART_CONTRACT_IMMEDIATE_RATE_PLAN';

export const SMART_CONTRACT_REQUEST_SAVE_UPDATE_CONTRACT_NAME_DATA =
  "SMART_CONTRACT_REQUEST_SAVE_UPDATE_CONTRACT_NAME_DATA";

export const uploadSmartContractImmediateRatePlan = (
  smartContractId,
  ratePlanFile
) => ({
  type: UPLOAD_SMART_CONTRACT_IMMEDIATE_RATE_PLAN,
  payload: {
    smartContractId,
    ratePlanFile
  }
});

export const initializeRatePlanUpload = () => ({
	type: INITIALIZE_SMART_CONTRACT_RATE_PLAN,
	payload: null
});

export const uploadSmartContractRatePlan = (smartContractId, ratePlanFile, effDate = null, options = null) => ({
	type: UPLOAD_SMART_CONTRACT_RATE_PLAN,
	payload: {
		smartContractId,
		ratePlanFile,
		effDate,
		options
	}
});

export const getProjects = () => ({
	type: GET_PROJECTS
});

export const clearRatePlan = () => ({
	type: CLEAR_SMART_CONTRACT_RATE_PLAN,
	payload: null
});

export const smartContractRatePlanFail = data => ({
	type: SMART_CONTRACT_RATE_PLAN_ERROR,
	payload: {
		info: { error: data }
	}
});

export const smartContractRatePlanSuccess = data => ({
	type: SMART_CONTRACT_RATE_PLAN_SUCCESS,
	payload: data
});

export const initialSmartContracts = () => ({
	type: INITIAL_SMART_CONTRACTS
});

export const getlistSmartContracts = () => ({
	type: LOAD_SMART_CONTRACTS
});

export const smartContractListSucces = dataSmartContract => ({
	type: SUCCESS_LIST_SMART_CONTRACT,
	payload: { ...dataSmartContract }
});

export const initialSmartContractNew = () => ({
	type: INITIAL_NEW_SMART_CONTRACT
});

export const smartContractFailure = messageError => ({
	type: FAILURE_DATA_SMART_CONTRACT,
	payload: messageError
});

export const smartContractInitialSuccess = dataNewSmartContract => ({
	type: SUCCESS_INITIAL_NEW_SMART_CONTRACT,
	payload: dataNewSmartContract
});

export const loadDestinationTypeCarrier = dataCountry => ({
	type: LOAD_DESTINATIONS_TYPE_CARRIER,
	payload: dataCountry
});

export const destinationsTypeCarrierSuccess = dataDestinations => ({
	type: SUCCESS_DESTINATIONS_TYPE_CARRIER,
	payload: dataDestinations
});

export const loadDestinationsCarrier = (country, destinationType) => ({
	type: LOAD_DESTINATIONS_CARRIER,
	payload: { country, destinationType }
});

export const destinationsCarrierSuccess = dataDesCarriers => ({
	type: SUCCESS_DESTINATIONS_CARRIER,
	payload: dataDesCarriers
});

export const loadDialCodes = (destinationCarries, country, destinationType) => ({
	type: LOAD_DIAL_CODES_TYPE_CARRIER_COUNTRY,
	payload: {
		destinationCarries,
		country,
		destinationType
	}
});

export const createSmartContract = dataSmartContract => ({
	type: CREATE_SMART_CONTRACT,
	payload: dataSmartContract
});

export const createSmartContractFailure = messageErrorCreate => ({
	type: CREATE_SMART_CONTRACT_FAILURE,
	payload: messageErrorCreate
});

export const createSmartContractSuccess = data => ({
	type: SMART_CONTRACT_CREATE_UPDATE_SUCCESS,
	payload: data
});

export const updateSmartContract = (smartContractId, dataSmartContract) => ({
	type: UPDATE_SMART_CONTRACT,
	payload: { smartContractId, dataSmartContract }
});

export const updateSmartContractFailure = messageErrorCreate => ({
	type: UPDATE_SMART_CONTRACT_FAILURE,
	payload: messageErrorCreate
});

export const getIpParticipant = (idParticipant, type) => ({
	type: LOAD_IP_ADDRESS_SMART_CONTRACTS,
	payload: { idParticipant, type }
});

export const ipParticipantSuccess = ipParticipant => ({
	type: LOAD_IP_ADDRESS_SUCCESS,
	payload: ipParticipant
});

export const operationFailed = messageError => ({
	type: OPERATION_FAILED,
	payload: { messageStatusError: messageError }
});

export const operationSuccess = message => ({
	type: OPERATION_SUCCESS,
	payload: { messageStatus: message }
});

export const downloadRatePlan = (smartContractId, ratePlanId, originalFileName) => ({
	type: DOWNLOAD_SMART_CONTRACT_RATE,
	payload: {
		smartContractId,
		ratePlanId,
		originalFileName
	}
});

export const downloadSampleRatePlan = () => ({
	type: DOWNLOAD_SAMPLE_RATE_PLAN,
	payload: null
});

export const clearProposedRatesData = () => ({
	type: SMART_CONTRACT_PROPOSED_RATES_CLEAR,
	payload: null
});

export const triggerProposedRatesAction = (smartContractId, options) => ({
	type: SMART_CONTRACT_PROPOSED_RATES_ACTION,
	payload: {
		smartContractId,
		options
	}
});

export const triggerProposedRatesActionSuccess = data => ({
	type: SMART_CONTRACT_PROPOSED_RATES_SUCCESS,
	payload: data
});

export const triggerProposedRatesActionFailed = data => ({
	type: SMART_CONTRACT_PROPOSED_RATES_FAILED,
	payload: data
});

export const loadRatePlanRates = (smartContractId, options) => ({
	type: RATES_SMART_CONTRACT,
	payload: {
		smartContractId,
		options
	}
});

export const loadRatePlanRatesSuccess = data => ({
	type: RATES_SMART_CONTRACT_SUCCESS,
	payload: data
});

export const loadRatePlanRatesFailed = data => ({
	type: RATES_SMART_CONTRACT_FAILED,
	payload: data
});

export const loadCatalogsSmartContract = () => ({
	type: CATALOGS_SMART_CONTRACT
});
export const loadCatalogsSmartContractSuccess = data => ({
	type: CATALOGS_SMART_CONTRACT_SUCCESS,
	payload: data
});

export const loadFundersSmartContract = () => ({
	type: FUNDERS_SMART_CONTRACT
});
export const loadFundersSmartContractSuccess = data => ({
	type: FUNDERS_SMART_CONTRACT_SUCCESS,
	payload: data
});

export const loadBiddingSmartContract = smartContractId => ({
	type: BIDDING_SMART_CONTRACT,
	payload: smartContractId
});
export const loadBiddingSmartContractSuccess = data => ({
	type: BIDDING_SMART_CONTRACT_SUCCESS,
	payload: data
});

export const loadSmartContractsLink = smartContractId => ({
	type: SMART_CONTRACT_LINK,
	payload: smartContractId
});
export const loadSmartContractLinkSuccess = data => ({
	type: SMART_CONTRACT_LINK_SUCCESS,
	payload: data
});

export const terminateSmartContract = smartContractId => ({
	type: TERMINATE_SMART_CONTRACT,
	payload: smartContractId
});

export const signSmartContract = smartContractId => ({
	type: SIGN_SMART_CONTRACT,
	payload: smartContractId
});

export const saveFunderSmartContract = data => ({
	type: SAVE_FUNDER_SMART_CONTRACT,
	payload: data
});

export const saveBiddingPortalSmartContract = data => ({
	type: SAVE_BIDDINGPORTAL_SMART_CONTRACT,
	payload: data
});

export const saveAllocateSmartContract = data => ({
	type: SAVE_ALLOCATE_SMART_CONTRACT,
	payload: data
});

export const saveAllocateFunding = data => ({
	type: SAVE_FUNDING_REVIEW,
	payload: data
});

export const saveFundingRequest = data => ({
	type: SAVE_FUNDING_REQUEST,
	payload: data
});

export const loadSmartContract = smartContractId => ({
	type: LOAD_SMART_CONTRACT,
	payload: smartContractId
});
export const loadSmartContractSuccess = data => ({
	type: LOAD_SMART_CONTRACT_SUCCESS,
	payload: data
});
export const loadSmartContractFailed = errorMessage => ({
	type: LOAD_SMART_CONTRACT_FAILED,
	payload: errorMessage
});

export const actionSmartContractCountGetDataRequest = () => ({
	type: SMART_CONTRACT_COUNT_ACTION_GET_DATA_REQUEST
});

export const actionSmartContractCountGetDataResponse = data => ({
	type: SMART_CONTRACT_COUNT_ACTION_GET_DATA_RESPONSE,
	payload: data
});

export const actionSmartContractAddressGetDataRequest = () => ({
	type: SMART_CONTRACT_ADDRESS_ACTION_GET_DATA_REQUEST
});

export const actionSmartContractAddressGetDataResponse = data => ({
	type: SMART_CONTRACT_ADDRESS_ACTION_GET_DATA_RESPONSE,
	payload: data
});

export const getSmartContractCatalogData = data => ({
	type: SMART_CONTRACTS_CATALOG_DATA,
	payload: data
});

export const getSmartContractFundingOffers = data => ({
	type: SMART_CONTRACT_GET_FUNDING_OFFERS,
	payload: data
});

export const setSmartContractFundingOffers = data => ({
	type: SMART_CONTRACT_FUNDING_OFFERS,
	payload: data
});

export const getNewSmartContractData = contractId => ({
	type: SMART_CONTRACT_REQUEST_NEW_SMART_CONTRACT_DATA,
	payload: { contractId }
});

export const setNewSmartContractData = data => ({
	type: SMART_CONTRACT_NEW_SMART_CONTRACT_DATA,
	payload: data
});

export const smartContractManageFundingRequest = (contractId, offerId, data) => ({
	type: SMART_CONTRACT_FUNDING_OFFERS_MANAGE_REQUEST,
	payload: { contractId, offerId, data }
});

export const allocateSmartContractFunds = data => ({
	type: SMART_CONTRACT_ALLOCATE_FUNDS_REQUEST,
	payload: data
});

export const getContractWalletData = contractId => ({
	type: SMART_CONTRACT_WALLET_DETAILS_REQUEST,
	payload: contractId
});
export const setContractWalletData = data => ({
	type: SMART_CONTRACT_WALLET_DETAILS_DATA,
	payload: data
});

export const saveUpdateContractName = (id, data) => ({
  type: SMART_CONTRACT_REQUEST_SAVE_UPDATE_CONTRACT_NAME_DATA,
  payload: {
	  id,
	  data
  }
});

export default actions
