import React, { memo } from 'react';
import { PrivateTemplate, BreadCrumb } from 'components';
import { RoutesScreen } from 'screens';

const RoutesPage = memo(props => (
	<PrivateTemplate>
		<BreadCrumb name="Routes" firstLevel="Home" secondLevel="Routes" />
		<RoutesScreen {...props} />
	</PrivateTemplate>
));
export default RoutesPage;
