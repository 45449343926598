export const LOAD_INITIAL_WALLET = 'LOAD_INITIAL_WALLET';
export const WALLET_DATA_SUCCESS = 'WALLET_DATA_SUCCESS';
export const WALLET_DATA_FAILURE = 'WALLET_DATA_FAILURE';
export const WALLET_TOPUP = 'WALLET_TOPUP';
export const WALLET_WITHDRAW = 'WALLET_WITHDRAW';
export const RESULT_WALLET = 'RESULT_WALLET';
export const REDIRECT_ACCOUNT = 'REDIRECT_ACCOUNT';
export const LOAD_INITIAL_WELCOME_WALLET = 'LOAD_INITIAL_WELCOME_WALLET';
export const LOAD_VOIP_API_DATA = 'LOAD_VOIP_API_DATA';
export const VOIP_API_DATA = 'VOIP_API_DATA';
export const LOAD_VOIP_API_DATA_SUCCESS = 'LOAD_VOIP_API_DATA_SUCCESS';
export const LOAD_VOIP_API_DATA_FAILURE = 'LOAD_VOIP_API_DATA_FAILURE';
export const DOWNLOAD_WALLET_REPORT = 'DOWNLOAD_WALLET_REPORT';
export const WALLET_LOADING = 'WALLET_LOADING';
export const TRANSACTIONS_LOADING = 'TRANSACTIONS_LOADING';
export const GET_WALLET_TRANSACTIONS = 'GET_WALLET_TRANSACTIONS';
export const CSV_DOWNLOAD_WALLET_TRANSACTIONS = 'CSV_DOWNLOAD_WALLET_TRANSACTIONS';
export const WALLET_TRANSACTIONS = 'WALLET_TRANSACTIONS';
export const GET_WALLET = 'GET_WALLET';

const actions = {
	// Misc
	SET_STATE: 'transactions/SET_STATE',
	GET_TRANSACTIONS_FILTERS: 'transactions/GET_TRANSACTIONS_FILTERS',
	GET_WALLET_TRANSACTIONS: 'transactions/GET_WALLET_TRANSACTIONS',
	GET_WALLET: 'transactions/GET_WALLET',
	WALLET_TOPUP: 'transactions/WALLET_TOPUP',
	WALLET_WITHDRAW: 'transactions/WALLET_WITHDRAW',
	DOWNLOAD_WALLET_REPORT: 'transactions/DOWNLOAD_WALLET_REPORT',
	CSV_DOWNLOAD_WALLET_TRANSACTIONS: 'transactions/CSV_DOWNLOAD_WALLET_TRANSACTIONS'
};

export default actions;

export const redirectAccount = tag => ({
	type: REDIRECT_ACCOUNT,
	payload: tag
});
export const getVoipApiData = (carrierId, startDate) => ({
	type: LOAD_VOIP_API_DATA,
	payload: {
		P_CUSTKEY: carrierId,
		P_SD: startDate
	}
});

export const loadVoipApiDataSuccess = voipApiData => ({
	type: LOAD_VOIP_API_DATA_SUCCESS,
	payload: voipApiData
});

export const loadVoipApiDataFailure = voipApiData => ({
	type: LOAD_VOIP_API_DATA_FAILURE,
	payload: voipApiData
});

export const getInitialWallet = userid => ({
	type: LOAD_INITIAL_WALLET,
	payload: userid
});

export const getWalletTransactions = payload => ({
	type: GET_WALLET_TRANSACTIONS,
	payload
});

export const csvDownloadWalletTransactions = payload => ({
	type: CSV_DOWNLOAD_WALLET_TRANSACTIONS,
	payload
});

export const setWalletTransactions = payload => ({
	type: WALLET_TRANSACTIONS,
	payload
});

export const walletDataSuccess = walletData => ({
	type: WALLET_DATA_SUCCESS,
	payload: walletData
});

export const walletDataFailure = messageError => ({
	type: WALLET_DATA_FAILURE,
	payload: 'error'
});

export const sendTopUp = (amountTopUp, paymentRef) => ({
	type: WALLET_TOPUP,
	payload: { amountTopUp, paymentRef }
});

export const sendWithDraw = dataWithDraw => ({
	type: WALLET_WITHDRAW,
	payload: dataWithDraw
});

export const setResultWallet = result => ({
	type: RESULT_WALLET,
	payload: {
		resultWallet: result
	}
});

export const getInitialWelcomeWallet = userid => ({
	type: LOAD_INITIAL_WELCOME_WALLET,
	payload: userid
});

export const triggerDownloadWalletReport = data => ({
	type: DOWNLOAD_WALLET_REPORT,
	payload: data
});

export const setWalletLoading = state => ({
	type: WALLET_LOADING,
	payload: state
});

export const setTransactionsLoading = state => ({
	type: TRANSACTIONS_LOADING,
	payload: state
});
