import React from "react";
import PropTypes from "prop-types";

const dateStyle = {
  color: "#99abb4"
};

export const FinancialResultDetail = props => (
  <tr key={props.index}>
    <td style={dateStyle}>
      <svg
        className="svg-inline--fa fa-clock fa-w-16"
        aria-hidden="true"
        data-prefix="fas"
        data-icon="clock"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        data-fa-i2svg=""
      >
        <path
          fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm57.1 350.1L224.9 294c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h48c6.6 0 12 5.4 12 12v137.7l63.5 46.2c5.4 3.9 6.5 11.4 2.6 16.8l-28.2 38.8c-3.9 5.3-11.4 6.5-16.8 2.6z"
        />
      </svg>
{" "}
      {props.created_at}
    </td>
    <td>
{props.type}
</td>
    <td>
{props.payment_ref}
</td>
    <td>
{props.item_in}
</td>
    <td>
{props.item_out}
</td>
    <td>
{props.item_balance}
</td>
  </tr>
);
FinancialResultDetail.propTypes = {
  index: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  payment_ref: PropTypes.string.isRequired,
  item_in: PropTypes.string.isRequired,
  item_out: PropTypes.string.isRequired,
  item_balance: PropTypes.string.isRequired
};
export default FinancialResultDetail;
