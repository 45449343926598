import React from 'react';
import { RenderBadge, CurrencyNumber } from 'components';

export default ({ amount }) => {
	return (
		<RenderBadge color={(amount < 0 && 'danger') || 'success'}>
			{(amount < 0 && '(') || null}
			<CurrencyNumber amount={(amount < 0 && amount * -1) || amount} />
			{(amount < 0 && ')') || null}
		</RenderBadge>
	);
};
