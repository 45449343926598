import React, {memo, useCallback, useEffect, useState} from 'react';
import {CardContent, Paper, Typography} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment-timezone";
import { RenderTable, CurrencyNumber, Loader } from "components";
import ReportFilters from "../ReportFilters";
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

export default memo(() => {
	const dispatch = useDispatch();
	const { t } = useTranslation('reports/reports');
	const loading = useSelector(state => state.reports && state.reports.loading || false);
	const tableData = useSelector(state => state.reports && state.reports.saleAndCostReport || [])

	const [filters, setFilters] = useState({
		startDate: moment().utc().hour(0).minute(0).seconds(0).millisecond(0),
		endDate: moment().utc().hour(23).minute(0).seconds(0).millisecond(0),
	})

	const triggerSaleAndCostReport = useCallback(() => {
		dispatch({ type: 'reports/GET_SALE_AND_COST_REPORT', payload: filters })
	},[filters])

	const triggerDownloadReport = useCallback(() => {
		dispatch({ type: 'reports/DOWNLOAD_SALE_AND_COST_REPORT', payload: filters })
	},[filters])

	const columns = (totalGrossProfit, totalCost, totalSale) => [
			{
				Header: t('SalesCostReportTab.country'),
				accessor: "country",
				Cell: data => data.value.replace(/['"]+/g, "")
			},
			{
				Header: t('SalesCostReportTab.destination_type'),
				accessor: "destinationType"
			},
			{
				Header: t('SalesCostReportTab.destination_carrier'),
				accessor: "destinationCarrier"
			},
			{
				Header: t('SalesCostReportTab.minutes'),
				className: "rt-text-right",
				accessor: "minutes",
				Cell: data => parseFloat(data.value).toFixed(2),
				Footer: table => {
					let total = 0;
					let itsTopMostFooter = false;
					table.data.map(obj => {
						total += parseFloat(obj.minutes);
						if (obj._original.lastPosition) {
							itsTopMostFooter = true
						}
					});
					return (
						<div>
							{itsTopMostFooter && (
								<div>
									<div className='space-15' />
									<br />
								</div>
							)}
							<strong>{total.toFixed(2)}</strong>
						</div>
					);
				}
			},
			{
				Header: t('SalesCostReportTab.sale_rate'),
				className: "rt-text-right",
				accessor: "saleRate",
				Cell: props => (
					<CurrencyNumber
						amount={parseFloat(props.value).toFixed(6)}
						precision={6}
					/>
				)
			},
			{
				Header: t('SalesCostReportTab.buy_rate'),
				className: "rt-text-right",
				accessor: "buyRate",
				Cell: props => (
					<CurrencyNumber
						amount={parseFloat(props.value).toFixed(6)}
						precision={6}
					/>
				)
			},
			{
				Header: t('SalesCostReportTab.margin_net'),
				className: "rt-text-right",
				accessor: "margin",
				Cell: data => (
					<strong>{parseFloat(data.value).toFixed(2)}%</strong>
				)
			},
			{
				Header: t('SalesCostReportTab.billing_inc'),
				className: "rt-text-right",
				accessor: "billingIncrement"
			},
			{
				Header: t('SalesCostReportTab.asr'),
				className: "rt-text-right",
				accessor: "asr",
				Cell: data => `${parseFloat(data.value).toFixed(0)}%`
			},
			{
				Header: t('SalesCostReportTab.acd'),
				className: "rt-text-right",
				accessor: "acd"
			},
			{
				Header: t('SalesCostReportTab.connected_calls'),
				className: "rt-text-right",
				accessor: "connected_calls",
				Footer: table => {
					let itsTopMostFooter = false
					table.data.map(obj => {
						if (obj._original.lastPosition) {
							itsTopMostFooter = true
						}
					})
					return (
						<React.Fragment>
						{itsTopMostFooter && (
							<div>
								<div className='space-15' />
								<br />
								<strong>
									<span>Totals: </span>
								</strong>
							</div>
						)}
						</React.Fragment>
					);
				}
			},
			{
				Header: t('SalesCostReportTab.sale'),
				className: "rt-text-right",
				accessor: "sale",
				width: 150,
				Cell: props => (
					<CurrencyNumber
						amount={parseFloat(props.value).toFixed(6)}
						precision={6}
					/>
				),
				Footer: table => {
					let total = 0;
					let itsTopMostFooter = false
					table.data.map(obj => {
						total += parseFloat(obj.sale);
						if (obj._original.lastPosition) {
							itsTopMostFooter = true
						}
					});
					return (
						<React.Fragment>
						<strong>
							<CurrencyNumber amount={total.toFixed(6)} precision={6} />
						</strong>
						{itsTopMostFooter && (
							<div>
								<hr className='hr-full' />
								<div className='space-15' />
								<strong>
									<CurrencyNumber amount={totalSale.toFixed(6)} precision={6} />
								</strong>
							</div>
						)}
						</React.Fragment>
					);
				}
			},
			{
				Header: t('SalesCostReportTab.cost'),
				className: "rt-text-right",
				accessor: "cost",
				width: 150,
				Cell: props => (
					<CurrencyNumber
						amount={parseFloat(props.value).toFixed(6)}
						precision={6}
					/>
				),
				Footer: table => {
					let total = 0;
					let itsTopMostFooter = false
					table.data.map(obj => {
						total += parseFloat(obj.cost);
						if (obj._original.lastPosition) {
							itsTopMostFooter = true
						}
					});
					return (
						<React.Fragment>
							<strong>
								<CurrencyNumber amount={total.toFixed(6)} precision={6}/>
							</strong>
							{itsTopMostFooter && (
								<div>
									<div className='space-15' />
									<strong>
										<CurrencyNumber amount={totalCost.toFixed(6)} precision={6} />
									</strong>
								</div>
							)}
						</React.Fragment>
					);
				}
			},
			{
				Header: t('SalesCostReportTab.gross_profit'),
				className: "rt-text-right",
				accessor: "grossProfit",
				width: 125,
				Cell: props => (
					<CurrencyNumber
						amount={parseFloat(props.value).toFixed(6)}
						precision={6}
					/>
				),
				Footer: table => {
					let total = 0;
					let itsTopMostFooter = false
					table.data.map(obj => {
						total += parseFloat(obj.grossProfit);
						if (obj._original.lastPosition) {
							itsTopMostFooter = true
						}
					});
					return (
							<React.Fragment>
								<strong>
									<CurrencyNumber amount={total.toFixed(6)} precision={6}/>
								</strong>
								{itsTopMostFooter && (
									<div className='top-most-footer'>
										<div className='space-15' />
										<strong>
											<CurrencyNumber amount={totalGrossProfit.toFixed(6)} precision={6} />
										</strong>
									</div>
								)}
							</React.Fragment>
					);
				}
			}
	]

	useEffect(() => {
		dispatch({
			type: 'reports/SET_STATE',
			payload: {
				saleAndCostReport: []
			}
		})
		triggerSaleAndCostReport()
	},[])


	return (
		<div className={styles.container}>
			<ReportFilters
				reportType="sale-and-cost"
				onChange={setFilters}
				onRunReport={triggerSaleAndCostReport}
				onDownload={triggerDownloadReport}
			/>
			{loading && <Loader loading /> || (
				<div className="report-data">
					{tableData.map((carrier) => (
						<Paper>
							<CardContent>
								<h3>{carrier.customer}</h3>
								{carrier.data.map((data, i) => (
									<div key={i}>
										<h4><strong>{data.vendor}</strong></h4>
										<RenderTable
											resizable={false}
											sortable={false}
											data={data.data}
											columns={columns(carrier.groupedTotalGrossProfit, carrier.groupedTotalCost, carrier.groupedTotalSale)}
											filterable={false}										
										/>
										{i <= (carrier.data.length -2) && <hr /> || null}
									</div>
								)) || null}
							</CardContent>
						</Paper>
					))}
				</div>
			)}
		</div>
	);
});
