import { v4 as uuidv4 } from 'uuid';
import { Util } from 'components';
import { saveAs } from 'file-saver';
import { push } from 'connected-react-router';
import { call, put, select, all, takeEvery } from 'redux-saga/effects';
import BlockchainApi from 'services/api/BlockchainApi';
import smartContractsApi from 'services/api/SmartContractsApi';
import routesApi from 'services/api/routesApi';
import { fromNotifications } from 'store/selectors';
import { actionCreatorPreloaderOff, actionCreatorPreloaderOn } from '../preloader/actions';
import { actionCreatorSelectItem } from '../privatemenu/actions';
import {
	actionSmartContractAddressGetDataResponse,
	actionSmartContractCountGetDataResponse,
	createSmartContractFailure,
	createSmartContractSuccess,
	destinationsCarrierSuccess,
	destinationsTypeCarrierSuccess,
	getlistSmartContracts,
	ipParticipantSuccess,
	loadBiddingSmartContractSuccess,
	loadCatalogsSmartContractSuccess,
	loadFundersSmartContractSuccess,
	loadRatePlanRatesFailed,
	loadRatePlanRatesSuccess,
	loadSmartContractFailed,
	loadSmartContractLinkSuccess,
	loadSmartContractSuccess,
	operationFailed,
	operationSuccess,
	smartContractFailure,
	smartContractInitialSuccess,
	smartContractListSucces,
	smartContractRatePlanFail,
	smartContractRatePlanSuccess,
	triggerProposedRatesActionSuccess,
	triggerProposedRatesActionFailed,
	loadRatePlanRates,
	setSmartContractFundingOffers,
	setNewSmartContractData,
	setContractWalletData,
	actions
} from './actions';
import {triggerNotification} from "../notifications/actions";

export function* initialSmartContracts(action) {
	try {
		yield put(actionCreatorPreloaderOn());
		const dataParticipantInitial = yield select(fromNotifications.getNotifications);
		if (dataParticipantInitial.participant == null || Util.isEmpty(dataParticipantInitial.participant) || dataParticipantInitial.participant === undefined) {
			yield put(actionCreatorSelectItem('myaccount'));
			yield put(push('/account/index'));
		} else {
			yield put(getlistSmartContracts());
		}
		yield put(actionCreatorPreloaderOff());
	} catch (err) {
		yield put(actionCreatorPreloaderOff());
		const error = err.message || err;
		console.log(`message error ::${error}`);
		yield put(smartContractFailure(error));
	}
}

export function* getNewSmartContractData(action) {
	try {
		yield put(actionCreatorPreloaderOn());
		const result = yield call(smartContractsApi.getNewSmartContractData, action.payload);
		yield put(setNewSmartContractData(result));
		yield put(actionCreatorPreloaderOff());
	} catch (err) {
		yield put(actionCreatorPreloaderOff());
		const error = err.message || err;
		console.log(`message error ::${error}`);
		yield put(smartContractFailure(error));
	}
}

export function* getSmartContracts(action) {
	try {
		console.log(`Get smartContracts:::${action}---${new Date().getTime()}`);
		yield put(actionCreatorPreloaderOn());
		const resultSmartContracts = yield call(smartContractsApi.getSmartContracts);
		yield put(smartContractListSucces({ listSmartContracts: resultSmartContracts }));
		yield put(actionCreatorPreloaderOff());
	} catch (err) {
		yield put(actionCreatorPreloaderOff());
		const error = err.message || err.details || err;
		console.log(`message error ::${error}`);
		// yield put(smartContractDataFailure(error));
	}
}

export function* getInitialNewSmartContract(action) {
	try {
		yield put(actionCreatorPreloaderOn());

		const resultCatalogsSmartContract = yield call(smartContractsApi.getCatalogsSmartContract);
		const resultDataNewSmartContract = yield call(smartContractsApi.getDataNewSmartContract);

		yield put(
			smartContractInitialSuccess({
				catalogsSmartContract: resultCatalogsSmartContract,
				dataSmartContract: {
					...resultDataNewSmartContract,
					tempId: uuidv4()
				}
			})
		);

		yield put(actionCreatorPreloaderOff());
	} catch (err) {
		yield put(actionCreatorPreloaderOff());
		const error = err.message || err.details || err;
		console.log(`message error::${error}`);
		yield put(smartContractFailure(error));
	}
}

export function* getSmartContractCatalogs() {
	try {
		const result = yield call(smartContractsApi.getCatalogsSmartContract);
		yield put(loadCatalogsSmartContractSuccess(result));
	} catch (err) {
		yield put(actionCreatorPreloaderOff());
		const error = err.message || err;
		console.log(`message error::${error}`);
		yield put(smartContractFailure(error));
	}
}

export function* getDestinationTypesCarrier(action) {
	try {
		const resultCountryDestinationsType = yield call(smartContractsApi.getDestinationsType, action.payload);
		const resultCountryDestinationsCarrier = yield call(smartContractsApi.getDestinationCarrierCountry, action.payload);
		const resultDialCodes = yield call(smartContractsApi.getDialCodes, action.payload);
		yield put(
			destinationsTypeCarrierSuccess({
				destinations: resultCountryDestinationsType,
				destinationsCarrier: resultCountryDestinationsCarrier,
				dialCodes: resultDialCodes
			})
		);
	} catch (err) {
		const error = err.message || err;
		console.log(`message error::${error}`);
		yield put(smartContractFailure(error));
	}
}

export function* getDestinationsCarrier(action) {
	try {
		const resultDestionationCarrier = yield call(smartContractsApi.getDestinationCarrierType, action.payload.country, action.payload.destinationType);
		const resultDialCodes = yield call(smartContractsApi.getDialCodes, action.payload.country, action.payload.destinationType);
		yield put(
			destinationsCarrierSuccess({
				destinationsCarrier: resultDestionationCarrier,
				dialCodes: resultDialCodes
			})
		);
	} catch (err) {
		const error = err.message || err;
		console.log(`message error::${error}`);
		yield put(smartContractFailure(error));
	}
}

export function* getDialCodesCountryTypesCarrier(action) {
	try {
		const resultDialCodes = yield call(smartContractsApi.getDialCodes, action.payload.country, action.payload.destinationType, action.payload.destinationCarries);
		yield put(destinationsCarrierSuccess({ dialCodes: resultDialCodes }));
	} catch (err) {
		const error = err.message || err;
		console.log(`message error::${error}`);
		yield put(smartContractFailure(error));
	}
}

export function* getIpAddressParticipant(action) {
	try {
		let ipResp;
		const resultIp = yield call(smartContractsApi.getIpAddressParticipant, action.payload.idParticipant);
		console.log(`resultIp:::${resultIp}`);
		if (resultIp.status === 'BAD') {
			ipResp = '0.0.0.0';
		} else {
			const { ip } = resultIp;
			ipResp = ip;
		}
		const dataResult = {};
		dataResult[action.payload.type] = ipResp;
		yield put(ipParticipantSuccess(dataResult));
	} catch (err) {
		const error = err.message || err;
		console.log(`message error::${error}`);
		yield put(smartContractFailure(error));
	}
}

export function* uploadSmartContractImmediateRates(action) {
	try {
		console.log('action', action);

		const uploadRatesRes = yield call(smartContractsApi.uploadSmartContractImmediateRates, action.payload.smartContractId, action.payload.ratePlanFile);

		yield put(smartContractRatePlanSuccess(uploadRatesRes));
		yield put(loadRatePlanRates(action.payload.smartContractId, {}));
	} catch (err) {
		yield put(smartContractRatePlanFail('An error occured during the upload'));
	}
}

export function* uploadSmartContractRates(action) {
	try {
		console.log('action', action);
		const uploadRatesRes = yield call(smartContractsApi.uploadSmartContractRates, action.payload.smartContractId, action.payload.ratePlanFile, action.payload.effDate);

		yield put(smartContractRatePlanSuccess(uploadRatesRes));

		if (action.payload.effDate && action.payload.options) {
			const { smartContractId, options } = action.payload.options;
			yield put(loadRatePlanRates(smartContractId, options));
		}
	} catch (err) {
		yield put(smartContractRatePlanFail('An error occured during the upload'));
	}
}

export function* createNewSmartContract(action) {
	try {
		yield put(actionCreatorPreloaderOn());
		const resultcreateSmartContract = yield call(smartContractsApi.createNewSmartContract, action.payload);

		try {
			yield call(smartContractsApi.assignRatePlanToSmartContract, resultcreateSmartContract.smartContractId);
		} catch (err) {
			yield put(smartContractRatePlanFail('An error occured when assigning rate plan to the smart contract'));
		}

		if (resultcreateSmartContract.status === 'OK') {
			yield put(push(`/smart-contracts/${resultcreateSmartContract.smartContractId}`));
		} else {
			yield put(createSmartContractFailure(resultcreateSmartContract.details));
		}
		yield put(actionCreatorPreloaderOn());
	} catch (err) {
		const error = err.message || err;
		console.log(`message error here ::${error}`);
		yield put(createSmartContractFailure(error));
	}
	yield put(actionCreatorPreloaderOff());
}

export function* downloadSmartContractRatePlan(action) {
	try {
		yield put(actionCreatorPreloaderOn());
		const blobData = yield call(smartContractsApi.downloadSmartContractRatePlan, action.payload.smartContractId, action.payload.ratePlanId);
		const blob = new Blob([blobData]);
		saveAs(blob, action.payload.originalFileName);
	} catch (err) {
		const error = err.message || err;
		console.log(`message error::${error}`);
	}
	yield put(actionCreatorPreloaderOff());
}

export function* downloadSampleRatePlan() {
	try {
		yield put(actionCreatorPreloaderOn());
		const blobData = yield call(smartContractsApi.downloadSampleRatePlan);
		const blob = new Blob([blobData]);
		saveAs(blob, 'sample.xlsx');
	} catch (err) {
		const error = err.message || err;
		// eslint-disable-next-line no-console
		console.log(`message error::${error}`);
	}
	yield put(actionCreatorPreloaderOff());
}

export function* updateNewSmartContract(action) {
	try {
		yield put(actionCreatorPreloaderOn());
		const resultUpdateSmartContract = yield call(smartContractsApi.updateNewSmartContract, action.payload.smartContractId, action.payload.dataSmartContract);

		if (resultUpdateSmartContract.smartContract && resultUpdateSmartContract.smartContract.status === 'active') {
			yield call(routesApi.createInitialZones, action.payload.smartContractId);
		}

		// make blockchain request
		yield call(BlockchainApi.postContractWallet, action.payload.dataSmartContract);
		try {
			yield call(smartContractsApi.assignRatePlanToSmartContract, action.payload.smartContractId);
		} catch (err) {
			yield put(smartContractRatePlanFail('An error occured when assigning rate plan to the smart contract'));
		}

		console.log(`::resultUpdateSmartContract::${JSON.stringify(resultUpdateSmartContract, null, ' ')}`);

		if (resultUpdateSmartContract.status === 'OK') {
			yield put(createSmartContractSuccess(resultUpdateSmartContract));
			yield put(push('/'));
			yield put(push(`/smart-contracts/${resultUpdateSmartContract.smartContractId}`));
		} else {
			yield put(createSmartContractFailure(resultUpdateSmartContract.details));
		}
	} catch (err) {
		const error = err.message || err;
		console.log(`message error::${error}`);
		yield put(createSmartContractFailure(error));
	}
	yield put(actionCreatorPreloaderOff());
}

export function* getRates(action) {
	try {
		const ratesResult = yield call(smartContractsApi.getSmartContractRates, action.payload.smartContractId, action.payload.options);
		yield put(loadRatePlanRatesSuccess(ratesResult));
	} catch (err) {
		const error = err.message || err;
		yield put(loadRatePlanRatesFailed(error));
	}
}

export function* sendProposedRatesAction(action) {
	yield put(actionCreatorPreloaderOn());
	try {
		const { smartContractId } = action.payload;
		const proposedRatesActionResult = yield call(smartContractsApi.handleProposedRates, smartContractId, action.payload.options);
		yield put(triggerProposedRatesActionSuccess(proposedRatesActionResult));
		yield put(loadRatePlanRates(smartContractId, action.payload.options));
	} catch (err) {
		const error = err.message || err;
		yield put(triggerProposedRatesActionFailed(error));
	}
	yield put(actionCreatorPreloaderOff());
}

export function* getCatalog(action) {
	try {
		const resultCatalogs = yield call(smartContractsApi.getCatalogs);
		yield put(loadCatalogsSmartContractSuccess({ catalogs: resultCatalogs }));
	} catch (err) {
		const error = err.message || err;
		console.log(`message error::${error}`);
		yield put(operationFailed(error));
	}
}

export function* getFunders(action) {
	try {
		const resultFunders = yield call(smartContractsApi.getFunders);
		yield put(loadFundersSmartContractSuccess({ funderList: resultFunders }));
	} catch (err) {
		const error = err.message || err;
		console.log(`message error::${error}`);
		yield put(operationFailed(error));
	}
}

export function* getBiddingPortal(action) {
	try {
		const resultBidding = yield call(smartContractsApi.getBiddingPortal, action.payload);
		if (resultBidding === null) {
			console.log('resultBidding is null');
			yield put(
				loadBiddingSmartContractSuccess({
					biddingPortal: {
						dailyAllocatedAmount: 0,
						interestRate: 0,
						repaymentTerm: undefined
					}
				})
			);
		} else {
			yield put(loadBiddingSmartContractSuccess({ biddingPortal: resultBidding }));
		}
	} catch (err) {
		const error = err.message || err;
		console.log(`message error::${error}`);
		yield put(operationFailed(error));
	}
}

export function* getBuyContractsExcept(action) {
	try {
		const resultSmartContractsLink = yield call(smartContractsApi.getBuyContractsExcept, action.payload);
		yield put(
			loadSmartContractLinkSuccess({
				smartContractsLink: resultSmartContractsLink
			})
		);
	} catch (err) {
		const error = err.message || err;
		console.log(`message error::${error}`);
		yield put(operationFailed(error));
	}
}

export function* saveFunder(action) {
	try {
		const result = yield call(smartContractsApi.saveFunder, action.payload);
		if (result && result.response && result.response.status !== 200) {
			yield put(operationFailed(result.response.data.details));
		} else if (result && result.status === 'OK') {
			console.log('successful operation');
			yield put(operationSuccess('successful operation'));
			yield put(push('/'));
			yield put(push('/smart-contracts/index'));
		} else if (result && result.status === 'BAD') {
			yield put(operationFailed(result.details));
		} else {
			console.log('an internal error occurred');
			yield put(operationFailed('an internal error occurred'));
		}
	} catch (err) {
		const error = err.message || err;
		console.log(`message error::${error}`);
		yield put(operationFailed(error));
	}
}

export function* saveBiddingPortal(action) {
	try {
		const result = yield call(smartContractsApi.saveBiddingPortal, action.payload);
		if (result && result.response && result.response.status !== 200) {
			yield put(operationFailed(result.response.data.details));
		} else if (result && result.status === 'OK') {
			console.log('successful operation');
			yield put(operationSuccess('successful operation'));
			yield put(push('/'));
			yield put(push('/smart-contracts/index'));
		} else if (result && result.status === 'BAD') {
			yield put(operationFailed(result.details));
		} else {
			console.log('an internal error occurred');
			yield put(operationFailed('an internal error occurred'));
		}
	} catch (err) {
		const error = err.message || err;
		console.log(`message error::${error}`);
		yield put(operationFailed(error));
	}
}

export function* saveAllocateFunding(action) {
	try {
		const result = yield call(BlockchainApi.postSendFundingReview, action.payload);
		if (result && result.success) {
			yield put(operationSuccess('successful operation'));
			const resultSmartContracts = yield call(smartContractsApi.getSmartContracts);
			yield put(smartContractListSucces({ listSmartContracts: resultSmartContracts }));
		} else {
			yield put(operationFailed('an internal error occurred.'));
		}
	} catch (err) {
		const error = err.message || err;
		console.log(`message error::${error}`);
		yield put(operationFailed(error));
	}
}

export function* saveFundingRequest(action) {
	try {
		yield put(actionCreatorPreloaderOn());
		const result = yield call(BlockchainApi.postSendFundingRequest, action.payload);
		if (result.success) {
			yield put(operationSuccess('Funding request sent'));
			const resultSmartContracts = yield call(smartContractsApi.getSmartContracts);
			yield put(smartContractListSucces({ listSmartContracts: resultSmartContracts }));
		} else {
			yield put(operationFailed('Could not send funding request'));
		}
		yield put(actionCreatorPreloaderOff());
	} catch (err) {
		yield put(actionCreatorPreloaderOn());
		const error = err.message || err;
		console.log(`message error::${error}`);
		yield put(operationFailed(error));
	}
}

export function* saveAllocate(action) {
	try {
		const result = yield call(smartContractsApi.saveAllocate, action.payload);
		console.log(`result=${result}`);
		if (result && result.response && result.response.status !== 200) {
			yield put(operationFailed(result.response.data.details));
		} else if (result && result.status === 'OK') {
			console.log('successful operation');
			yield put(operationSuccess('successful operation'));
			yield put(push('/'));
			yield put(push('/smart-contracts/index'));
		} else if (result && result.status === 'BAD') {
			yield put(operationFailed(result.details));
		} else {
			console.log('an internal error occurred');
			yield put(operationFailed('an internal error occurred'));
		}
	} catch (err) {
		const error = err.message || err;
		console.log(`message error::${error}`);
		yield put(operationFailed(error));
	}
}

export function* terminate(action) {
	try {
		const result = yield call(smartContractsApi.terminate, action.payload);
		if (result && result.response && result.response.status !== 200) {
			yield put(operationFailed(result.response.data.details));
		} else if (result && result.status === 'OK') {
			console.log('successful operation');
			yield put(operationSuccess('successful operation'));
			yield put(push('/'));
			yield put(push('/smart-contracts/index'));
		} else if (result && result.status === 'BAD') {
			yield put(operationFailed(result.details));
		} else {
			console.log('an internal error occurred');
			yield put(operationFailed('an internal error occurred'));
		}
	} catch (err) {
		const error = err.message || err;
		console.log(`message error::${error}`);
		yield put(operationFailed(error));
	}
}

export function* sign(action) {
	try {
		const result = yield call(smartContractsApi.sign, action.payload);
		yield call(smartContractsApi.sendSmartContractRates, action.payload);

		if (result && result.response && result.response.status !== 200) {
			console.log(result.response.data.details);
			yield put(operationFailed(result.response.data.details));
		} else if (result && result.status === 'OK') {
			console.log('successful operation');
			yield put(operationSuccess('successful operation'));
			yield put(push('/'));
			yield put(push(`/smart-contracts/${action.payload}`));
		} else if (result && result.status === 'BAD') {
			yield put(operationFailed(result.details));
		} else {
			console.log('an internal error occurred');
			yield put(operationFailed('an internal error occurred'));
		}
	} catch (err) {
		const error = err.message || err;
		console.log(`message error::${error}`);
		yield put(operationFailed(error));
	}
}

export function* getSmartContract(action) {
	try {
		console.log('action',action)
		const result = yield call(smartContractsApi.getSmartContract, action.payload);

		// if (result.contract && result.contract.id) {
		//   yield put(loadRatePlanRates(result.contract.id, { page: 1, limit: 10 }));
		// }

		if (result && result.response && result.response.status !== 200) {
			yield put(loadSmartContractFailed(result.response.data.details));
		} else if (result && result.contract == null) {
			yield put(loadSmartContractFailed('an internal error occurred'));
		} else {
			yield put(loadSmartContractSuccess(result));
		}
	} catch (err) {
		const error = err.message || err.details || err;
		console.log(`message error::${error}`);
		yield put(loadSmartContractFailed(error));
	}
}

export function* sagaSmartContractCountGetDataRequest() {
	try {
		yield put(actionCreatorPreloaderOn());

		const result = yield call(smartContractsApi.getSmartContractCountDataRequest);

		yield put(actionSmartContractCountGetDataResponse(result));
		yield put(actionCreatorPreloaderOff());
	} catch (error) {
		yield put(actionCreatorPreloaderOff());
	}
}

export function* sagaSmartContractAddressGetDataRequest() {
	try {
		yield put(actionCreatorPreloaderOn());

		const result = yield call(smartContractsApi.getSmartContractAddressDataRequest);

		yield put(actionSmartContractAddressGetDataResponse(result));
		yield put(actionCreatorPreloaderOff());
	} catch (error) {
		yield put(actionCreatorPreloaderOff());
	}
}

export function* getSmartContractFundingOffers(action) {
	yield put(actionCreatorPreloaderOn());
	try {
		const result = yield call(smartContractsApi.getSmartContractFundingOffers, action.payload);
		yield put(setSmartContractFundingOffers(result));
	} catch (err) {
		const error = err.message || err;
		yield put(triggerProposedRatesActionFailed(error));
	}
	yield put(actionCreatorPreloaderOff());
}

export function* sagaSmartContractManageFundingOffer(action) {
	yield put(actionCreatorPreloaderOn());
	try {
		yield call(smartContractsApi.smartContractManageFundingOffers, action.payload.offerId, action.payload.data);
		const result = yield call(smartContractsApi.getSmartContractFundingOffers, action.payload.contractId);
		yield put(setSmartContractFundingOffers(result));
	} catch (err) {}
	yield put(actionCreatorPreloaderOff());
}

export function* sagaSmartContractAllocateFunds(action) {
	yield put(actionCreatorPreloaderOn());
	try {
		yield call(smartContractsApi.smartContractAllocateFunds, action.payload);
	} catch (err) {}
	yield put(actionCreatorPreloaderOff());
}

export function* getContractWalletDetails(action) {
	yield put(actionCreatorPreloaderOn());
	try {
		const result = yield call(smartContractsApi.smartContractGetWalletDetails, action.payload);
		yield put(setContractWalletData(result));
	} catch (err) {}
	yield put(actionCreatorPreloaderOff());
}

export function* saveUpdateContractName(action) {
	try {
		yield put(actionCreatorPreloaderOn());
		const result = yield call(smartContractsApi.saveUpdateContractName, action.payload.id, action.payload.data);
		if (result) {
			const resultSmartContracts = yield call(smartContractsApi.getSmartContracts);
			yield put(smartContractListSucces({ listSmartContracts: resultSmartContracts }));
		}
		yield put(actionCreatorPreloaderOff());
	} catch (err) {
		yield put(actionCreatorPreloaderOff());
		const error = err.message || err;
		console.log(`message error ::${error}`);
		yield put(smartContractFailure(error));
	}
}

export function* GET_PROJECTS() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loadingProjects: true
		}
	});
	try {
		const result = yield call(smartContractsApi.getProjects);
		if(result) {
			yield put({
				type: 'smartcontracts/SET_STATE',
				payload: {
					projects: result || []
				}
			});
		}
	} catch (error) {
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
		console.log('GET_PROJECTS ERROR');
	}
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loadingProjects: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_PROJECT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loadingProjects: true
		}
	});
	try {
		const result = yield call(smartContractsApi.getProject, payload);

		if(result) {
			yield put({
				type: 'smartcontracts/SET_STATE',
				payload: {
					project: result || []
				}
			});
		}
	} catch (error) {
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
		console.log('GET_PROJECT ERROR');
	}
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loadingProjects: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* CREATE_PROJECT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loadingProjects: true
		}
	});
	try {
		yield call(smartContractsApi.createProject, payload);

		yield put({ type: 'smartcontracts/GET_PROJECTS' });
	} catch (error) {
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
		console.log('CREATE_PROJECT ERR');
	}
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loadingProjects: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* DELETE_PROJECT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loadingProjects: true
		}
	});
	try {
		yield call(smartContractsApi.deleteProject, payload);

		yield put({ type: 'smartcontracts/GET_PROJECTS' });
	} catch (error) {
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
		console.log('DELETE_PROJECT ERR');
	}
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loadingProjects: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* UPDATE_PROJECT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loadingProjects: true
		}
	});
	try {
		yield call(smartContractsApi.updateProject, payload);

		yield put({ type: 'smartcontracts/GET_PROJECTS' });
	} catch (error) {
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
		console.log('UPDATE_PROJECT ERR');
	}
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loadingProjects: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_SMARTCONTRACTS({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loading: true
		}
	});
	const result = yield call(smartContractsApi.getSmartContracts, payload);
	if (result && Array.isArray(result)) {
		yield put({
			type: 'smartcontracts/SET_STATE',
			payload: {
				smartContracts: result
			}
		});
	}
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_CREATE_CONTRACT() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loading: true
		}
	});
	const result = yield call(smartContractsApi.getCreateSmartContracts);
	if (result) {
		yield put({
			type: 'smartcontracts/SET_STATE',
			payload: {
				newSmartContractData: result
			}
		});
	}
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* DOWNLOAD_SAMPLE_RATEPLAN() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loading: true
		}
	});
	const result = yield call(smartContractsApi.getSampleRateplan);
	if (result) {
		saveAs(result, 'sample-rateplan.xlsx');
	}
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_NEW_SMART_CONTRACT_DATA() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loading: true
		}
	});
	const result = yield call(smartContractsApi.getNewSmartContractDataNew);
	if (result) {
		yield put({
			type: 'smartcontracts/SET_STATE',
			payload: {
				newSmartContractData: result
			}
		});
	}
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_SMART_CONTRACT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loadingProjects: true
		}
	});
	try {
		const result = yield call(smartContractsApi.getContract, payload);
		if(result) {
			yield put({
				type: 'smartcontracts/SET_STATE',
				payload: {
					smartContract: result || {}
				}
			});
		}
	} catch (error) {
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
		console.log('GET_PROJECT ERROR');
	}
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loadingProjects: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* UPLOAD_RATES_FILE({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loading: true
		}
	});
	const result = yield call(smartContractsApi.uploadRatesFile, payload);
	if (result) {
		yield put({
			type: 'smartcontracts/ADD_RATES_PLAN',
			payload: result
		});
	}
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* DELETE_RATE_PLAN({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loading: true
		}
	});
	const result = yield call(smartContractsApi.deleteRatePlan, payload);
	if (result.status !== 'OK') {
		yield put(triggerNotification(result.response.data.message || 'Unable to delete rate plan.', 'error'));
	} else {
		yield put(triggerNotification('Successfully deleted rate plan', 'success'));
	}
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* DELETE_RATE_PLANS_BY_TEMPID({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loading: true
		}
	});
	const result = yield call(smartContractsApi.deleteRatePlansByTempId, payload);
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* SAVE_SMART_CONTRACT_DRAFT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loading: true
		}
	});

	const result = yield call(smartContractsApi.saveSmartContractDraft, payload);
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* CREATE_CONTRACT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loading: true
		}
	});
	const result = yield call(smartContractsApi.createContract, payload);

	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* UPDATE_SMART_CONTRACT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loading: true
		}
	});
	const result = yield call(smartContractsApi.updateSmartContract, payload);

	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_SMARTCONTRACT_BY_ID({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loading: true
		}
	});
	const result = yield call(smartContractsApi.getSmartContractData, payload.id);
	if (result) {
		yield put({
			type: 'smartcontracts/SET_STATE',
			payload: {
				smartContractEditData: result
			}
		});
	}
	if (result.tempId) {
		const ratePlans = yield call(smartContractsApi.getRatePlansByTempId, result.tempId);
		yield put({
			type: 'smartcontracts/SET_STATE',
			payload: {
				ratePlans: ratePlans
			}
		});
	}
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* UPDATE_SMARTCONTRACT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loading: true
		}
	});
	const result = yield call(smartContractsApi.updateSmartContract, payload.id, payload.data);
	if (result.status !== 'OK') {
		yield put(triggerNotification(result.response.data.message || 'Unable to delete rate plan.', 'error'));
	} else {
		yield put(triggerNotification('Successfully deleted rate plan', 'success'));
	}

	yield put({
		type: 'smartcontracts/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}


export default function* rootSaga() {
	yield all([takeEvery(actions.GET_PROJECTS, GET_PROJECTS)]);
	yield all([takeEvery(actions.GET_PROJECT, GET_PROJECT)]);
	yield all([takeEvery(actions.CREATE_PROJECT, CREATE_PROJECT)]);
	yield all([takeEvery(actions.DELETE_PROJECT, DELETE_PROJECT)]);
	yield all([takeEvery(actions.UPDATE_PROJECT, UPDATE_PROJECT)]);

	yield all([takeEvery(actions.GET_SMARTCONTRACTS, GET_SMARTCONTRACTS)]);
	yield all([takeEvery(actions.GET_CREATE_CONTRACT, GET_CREATE_CONTRACT)]);
	yield all([takeEvery(actions.GET_SMART_CONTRACT, GET_SMART_CONTRACT)]);
	yield all([takeEvery(actions.DOWNLOAD_SAMPLE_RATEPLAN, DOWNLOAD_SAMPLE_RATEPLAN)]);
	yield all([takeEvery(actions.CREATE_CONTRACT, CREATE_CONTRACT)]);
	yield all([takeEvery(actions.UPDATE_SMART_CONTRACT, UPDATE_SMART_CONTRACT)]);
	yield all([takeEvery(actions.GET_NEW_SMART_CONTRACT_DATA, GET_NEW_SMART_CONTRACT_DATA)]);
	yield all([takeEvery(actions.UPLOAD_RATES_FILE, UPLOAD_RATES_FILE)]);
	yield all([takeEvery(actions.DELETE_RATE_PLAN, DELETE_RATE_PLAN)]);
	yield all([takeEvery(actions.DELETE_RATE_PLANS_BY_TEMPID, DELETE_RATE_PLANS_BY_TEMPID)]);
	yield all([takeEvery(actions.SAVE_SMART_CONTRACT_DRAFT, SAVE_SMART_CONTRACT_DRAFT)]);
	yield all([takeEvery(actions.GET_SMARTCONTRACT_BY_ID, GET_SMARTCONTRACT_BY_ID)]);
	yield all([takeEvery(actions.UPDATE_SMARTCONTRACT, UPDATE_SMARTCONTRACT)]);
}
