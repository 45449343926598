import React from 'react';
import { PrivateTemplate, BreadCrumb } from 'components';
import { useTranslation } from 'react-i18next';
import { Financials } from 'screens';

const FinancialPage2 = () => {
	const { t } = useTranslation('financials/general');

	return (
		<PrivateTemplate>
			<BreadCrumb name={t('title')} firstLevel="Home" secondLevel={t('title')} />
			<Financials />
		</PrivateTemplate>
	);
};

export default FinancialPage2;
