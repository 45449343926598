import { call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import awsAmplifyService from 'services/api/awsamplify';
import myAccountApiService from 'services/api/MyAccountApi';
import { actions as idleActions } from '../idle/configure';
import { loginFailure, loginSuccess, userInfoSuccess, setPostResponse, forcedChangePasswordInfo, setPasswordChallengeInfo } from '../actions';

export function* mfaRequest(action) {
	try {
		const codeVerification = yield call(myAccountApiService.verifyMfaCode, action.payload);
		if (codeVerification.success === true) {
			yield put(loginSuccess());
			yield put(push('/account/index'));
		} else {
			yield put(loginFailure(codeVerification.message));
			yield put(push('/mfa'));
		}
	} catch (err) {
		const error = err.message || err;
		console.log(`mfa::${error}`);
		yield put(loginFailure(error));
	}
}

export function* loginUser(action) {
	yield put({ type: 'SET_LOGIN_LOADING', payload: true });
	try {
		const result = yield call(awsAmplifyService.login, action.payload.userName, action.payload.password);

		if (result.challengeName) {
			const data = {
				email: action.payload.userName,
				session: result.Session,
				userId: result.username,
				challengeName: result.challengeName
			};

			yield put({ type: 'passwordChallenge/SET_PASSWORD_CHALLENGE_INFO', payload: data });

			yield put(loginFailure('New password required'));
		} else if (result.username != null) {
			const userData = {
				userId: result.signInUserSession.idToken.payload['cognito:username'],
				email: result.signInUserSession.idToken.payload.email,
				name: result.signInUserSession.idToken.payload.name,
				roles: result.signInUserSession.idToken.payload['cognito:groups']
			};

			const accountData = yield call(myAccountApiService.getIndex);

			if (!accountData) {
				yield put(loginFailure('Authentication failure'));
				return;
			}

			if (accountData && !accountData.loggedInUser && accountData.mfa === true) {
				yield put(userInfoSuccess(userData));
				// yield put(loginSuccess());
				yield put(push('/mfa'));
			} else if (accountData && accountData.loggedInUser && accountData.loggedInUser.mfa === true) {
				yield put(userInfoSuccess(userData));
				// yield put(loginSuccess());
				yield put(push('/mfa'));
			} else {
				yield put(userInfoSuccess(userData));
				yield put(loginSuccess());
				yield put(push('/account'));
			}
		} else if (result.status === 'CHANGE_PASSWORD') {
			yield put(loginFailure('It is necessary to change your password'));
		} else {
			yield put(loginFailure(result.message));
		}
		console.log('Finish Saga');
	} catch (err) {
		const error = err.message || err;
		console.log(`eror---${error}`);
		yield put(loginFailure(error));
	} finally {
		yield put({ type: 'SET_LOGIN_LOADING', payload: false });
	}
}

export function* logoutUser() {
	try {
		console.log('..........antes de invocar logout:');
		yield call(awsAmplifyService.logout);

		yield put(push('/login'));
		yield put(idleActions.stop());
		yield put({ type: 'LOG_OUT_FINISH' });
	} catch (err) {
		const error = err.message || err;
		console.log(`eror---${error}`);
	}
}

export function* startIdleMonitor() {
	try {
		yield put(idleActions.start());
	} catch (err) {
		console.log('err', err);
	}
}
