import { GET_REPORT_TEMPLATES } from './sagas';

const actions = {
	// Misc
	SET_STATE: 'reports/SET_STATE',

	// Reports
	GET_FILTER_DATA: 'reports/GET_FILTER_DATA',
	GET_CUSTOMER_REPORT: 'reports/GET_CUSTOMER_REPORT',
	GET_SUPPLIER_REPORT: 'reports/GET_SUPPLIER_REPORT',
	GET_SALE_AND_COST_REPORT: 'reports/GET_SALE_AND_COST_REPORT',
	GET_CDR_REPORT: 'reports/GET_CDR_REPORT',
	REQUEST_CDR_REPORT: 'reports/REQUEST_CDR_REPORT',

	GET_REPORT_TEMPLATES: 'reports/GET_REPORT_TEMPLATES',
	GET_REPORT_TEMPLATE: 'reports/GET_REPORT_TEMPLATE',
	NEW_REPORT_TEMPLATE: 'reports/NEW_REPORT_TEMPLATE',
	UPDATE_REPORT_TEMPLATE: 'reports/UPDATE_REPORT_TEMPLATE',
	DELETE_REPORT_TEMPLATE: 'reports/DELETE_REPORT_TEMPLATE',

	GET_DASHBOARD_PARTITION_STATS: 'reports/GET_DASHBOARD_PARTITION_STATS',
	GET_DASHBOARD_PARTITION_ACTIVE_CALLS: 'reports/GET_DASHBOARD_PARTITION_ACTIVE_CALLS',

	DOWNLOAD_CUSTOMER_REPORT: 'reports/DOWNLOAD_CUSTOMER_REPORT',
	DOWNLOAD_SUPPLIER_REPORT: 'reports/DOWNLOAD_SUPPLIER_REPORT',
	DOWNLOAD_SALE_AND_COST_REPORT: 'reports/DOWNLOAD_SALE_AND_COST_REPORT',
	DOWNLOAD_CDR_REPORT: 'reports/DOWNLOAD_CDR_REPORT',

	GET_ADVANCED_REPORT: 'reports/GET_ADVANCED_REPORT',
	GET_EXTERNAL_INVOICES: 'reports/GET_EXTERNAL_INVOICES',
	GET_MONTHLY_INVOICES: 'reports/GET_MONTHLY_INVOICES',
	SET_MONTHLY_INVOICES_STATE: 'reports/SET_MONTHLY_INVOICES_STATE',
	GET_INVOICES: 'reports/GET_INVOICES',
	SET_INVOICES_STATE_BY_TYPE: 'reports/SET_INVOICES_STATE_BY_TYPE',
	SET_INVOICES_GENERAL_STATE: 'reports/SET_INVOICES_GENERAL_STATE',
	DOWNLOAD_INVOICE: 'reports/DOWNLOAD_INVOICE',
	PAY_INVOICE: 'reports/PAY_INVOICE'
};

export default actions;

export const INITIALIZE_REPORT = 'INITIALIZE_REPORT';
export const SET_REPORT_FILTER_DATA = 'SET_REPORT_FILTER_DATA';
export const NEW_REPORT = 'NEW_REPORT';
export const SET_CREATE_REPORT = 'SET_CREATE_REPORT';
export const LOAD_INVOICES = 'LOAD_INVOICES';
export const LOAD_MONTHLY_INVOICES = 'LOAD_MONTHLY_INVOICES';
export const SET_MONTHLY_INVOICE_REPORT_ERROR = 'SET_MONTHLY_INVOICE_REPORT_ERROR';
export const DOWNLOAD_INVOICE = 'DOWNLOAD_INVOICE';
export const SET_INVOICE_REPORT_ERROR = 'SET_INVOICE_REPORT_ERROR';
export const SET_SUPPLIER_INVOICE_DATA = 'SET_SUPPLIER_INVOICE_DATA';
export const SET_CUSTOMER_INVOICE_DATA = 'SET_CUSTOMER_INVOICE_DATA';
export const SET_MONTHLY_SUPPLIER_INVOICE_DATA = 'SET_MONTHLY_SUPPLIER_INVOICE_DATA';
export const SET_MONTHLY_CUSTOMER_INVOICE_DATA = 'SET_MONTHLY_CUSTOMER_INVOICE_DATA';
export const INVOICE_DATA = 'INVOICE_DATA';
export const LOAD_INVOICES_FAILURE = 'LOAD_INVOICES_FAILURE';
export const SET_PAYMENT_STATUS = 'SET_PAYMENT_STATUS';

export const GET_REPORTS_FILTERS = 'GET_REPORTS_FILTERS';
export const REPORTS_FILTERS = 'REPORTS_FILTERS';
export const GET_REPORT_DATA = 'GET_REPORT_DATA';
export const REPORT_DATA = 'REPORT_DATA';

export const GET_REPORT_CDR_DATA = 'GET_REPORT_CDR_DATA';
export const REPORT_CDR_DATA = 'REPORT_CDR_DATA';
export const REPORT_LOADING = 'REPORT_LOADING';
export const INVOICE_DOWNLOAD_COMPLETED = 'INVOICE_DOWNLOAD_COMPLETED';

export const initializeReport = userId => ({
	type: INITIALIZE_REPORT,
	payload: userId
});

export const setInitializeReport = result => ({
	type: SET_REPORT_FILTER_DATA,
	payload: result
});

export const newReport = result => ({
	type: NEW_REPORT,
	payload: result
});

export const setCreateReport = result => ({
	type: SET_CREATE_REPORT,
	payload: { result }
});

export const invoiceDownloadCompleted = () => ({
	type: INVOICE_DOWNLOAD_COMPLETED,
	payload: null
});

export const downloadInvoice = (invoiceId, invoiceNumber, type = 'customer', isOrigination) => ({
	type: DOWNLOAD_INVOICE,
	payload: {
		invoiceId,
		invoiceNumber,
		type,
		isOrigination
	}
});

export const loadInvoices = (invoiceType = 'customer', isOrigination) => ({
	type: LOAD_INVOICES,
	payload: {
		type: invoiceType,
		isOrigination
	}
});

export const LOAD_EXTERNAL_INVOICES = 'LOAD_EXTERNAL_INVOICES';
export const SET_EXTERNAL_INVOICES_DATA = 'SET_EXTERNAL_INVOICES_DATA';
export const SET_EXTERNAL_INVOICES_DATA_ERROR = 'SET_EXTERNAL_INVOICES_DATA_ERROR';
export const loadExternalInvoices = () => ({
	type: LOAD_EXTERNAL_INVOICES,
	payload: null
});
export const loadExternalInvoicesSuccess = data => ({
	type: SET_EXTERNAL_INVOICES_DATA,
	payload: data
});
export const loadExternalInvoicesError = error => ({
	type: SET_EXTERNAL_INVOICES_DATA_ERROR,
	payload: {
		info: {
			error
		}
	}
});

export const loadMonthlyInvoices = (invoiceType = 'customer') => ({
	type: LOAD_MONTHLY_INVOICES,
	payload: {
		type: invoiceType
	}
});

export const loadMonthlyInvoiceData = (data, type) => ({
	type,
	payload: {
		data,
		loading: false
	}
});

export const loadCustomerMonthlyInvoicesSuccess = data => loadMonthlyInvoiceData(data, SET_MONTHLY_CUSTOMER_INVOICE_DATA);

export const loadSupplierMonthlyInvoicesSuccess = data => loadMonthlyInvoiceData(data, SET_MONTHLY_SUPPLIER_INVOICE_DATA);

export const loadMonthlyInvoiceError = (error, type) => ({
	type: SET_MONTHLY_INVOICE_REPORT_ERROR,
	payload: {
		type,
		info: {
			error
		}
	}
});

export const loadInvoiceData = (data, isOrigination, type) => ({
	type,
	payload: {
		type: isOrigination ? 'origination' : 'standard',
		data,
		loading: false,
		info: undefined
	}
});

export const loadCustomerInvoicesSuccess = (data, isOrigination) => loadInvoiceData(data, isOrigination, SET_CUSTOMER_INVOICE_DATA);

export const loadSupplierInvoicesSuccess = (data, isOrigination) => loadInvoiceData(data, isOrigination, SET_SUPPLIER_INVOICE_DATA);

export const loadInvoicesError = (error, type, isOrigination) => ({
	type: SET_INVOICE_REPORT_ERROR,
	payload: {
		type,
		isOrigination,
		info: {
			error
		}
	}
});

export const getReportsFilters = data => ({
	type: GET_REPORTS_FILTERS,
	payload: data
});

export const setReportsFilters = data => ({
	type: REPORTS_FILTERS,
	payload: data
});

export const getReportsData = (data, reportType) => ({
	type: GET_REPORT_DATA,
	payload: {
		data,
		reportType
	}
});

export const setReportsData = data => ({
	type: REPORT_DATA,
	payload: data
});

export const getReportsCDRData = () => ({
	type: GET_REPORT_CDR_DATA
});

export const setReportsCDRData = data => ({
	type: REPORT_CDR_DATA,
	payload: data
});

export const setReportLoading = state => ({
	type: REPORT_LOADING,
	payload: state
});

export const setPaymentStatus = payload => ({
	type: SET_PAYMENT_STATUS,
	payload
});
