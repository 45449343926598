import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// eslint-disable-next-line react/prefer-stateless-function
class ConfirmDeleteDialog extends React.Component {
  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onBackdropClick={() => this.props.openCloseHandler()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to delete this item?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this item ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.props.openCloseHandler();
              }}
              color="secondary"
            >
              No
            </Button>
            <Button
              onClick={() => {
                this.props.handleSubmit !== undefined
                  ? this.props.handleSubmit(this.props.initialValues)
                  : this.props.openCloseHandler();
              }}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ConfirmDeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  // handleDelete: PropTypes.func,
  openCloseHandler: PropTypes.func.isRequired
};

export default ConfirmDeleteDialog;
