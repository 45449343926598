import React from 'react';
import { CardContent, Typography, Grid } from '@material-ui/core';
import * as moment from 'moment';

import CurrencyNumber from 'components/atoms/CurrencyNumber';
import RenderAcpType from 'components/atoms/AcpType';
import { RenderTable } from 'components';

import styles from './styles.module.scss';

export default ({ row }) => {
	const devicesStats = {
		...row?.original?.stats?.devices,
		type: 'claimable-devices'
	};
	const salesStats = {
		...row?.original?.stats?.sales,
		type: 'sales'
	};
	const mrcStats = {
		...row?.original?.stats?.mrc,
		type: 'mrc'
	};
	const data = [salesStats, mrcStats, devicesStats];

	const columns = [
		{
			Header: 'Type',
			accessor: 'type',
			Cell: cell => {
				return <RenderAcpType size="small" claimType={cell.original?.type} />;
			},
			width: 125
		},
		{
			Header: 'Claim Numbers',
			accessor: 'claimNumbers',
			Cell: ({ value }) => {
				const localClaimNumbers = value.join(', ');
				return <div className={styles.claimNumers}>{localClaimNumbers ?? ''}</div>;
			},
			width: 350
		},
		{
			Header: 'Regular Subscription',
			accessor: 'regularSubscriptions',
			Cell: cell => {
				return (
					<div>
						{cell.original.type === 'sales' && cell.original?.claimNumbers.length > 0 && cell?.original?.quantityRegularSubscription > 0 && (
							<Grid container>
								<Grid item xs={8}>
									<div>Units:</div>
									<div>Total price:</div>
								</Grid>
								<Grid item xs={2}>
									<Grid container direction="column" justifyContent="center" alignItems="flex-end">
										<div className="number-column">{cell?.original?.quantityRegularSubscription || 0}</div>
										<div className="number-column">
											<CurrencyNumber amount={cell?.original?.totalRegularSubscription || 0} precision={0} />
										</div>
									</Grid>
								</Grid>
							</Grid>
						)}
						{cell.original.type === 'mrc' && cell.original?.claimNumbers.length > 0 && cell?.original?.quantityRegularSubscription > 0 && (
							<Grid container>
								<Grid item xs={8}>
									<div>Units:</div>
									<div>Total price:</div>
								</Grid>
								<Grid item xs={2}>
									<Grid container direction="column" justifyContent="center" alignItems="flex-end">
										<div className="number-column">{cell?.original?.quantityRegularSubscription || 0}</div>
										<div className="number-column">
											<CurrencyNumber amount={cell?.original?.totalRegularSubscription || 0} precision={0} />
										</div>
									</Grid>
								</Grid>
							</Grid>
						)}
					</div>
				);
			},
			width: 200
		},
		{
			Header: 'Tribal Subscription',
			accessor: 'tribalSubscription',
			Cell: cell => {
				return (
					<div>
						{cell.original.type === 'sales' && cell.original?.claimNumbers.length > 0 && cell?.original?.quantityTribalSubscription > 0 && (
							<Grid container>
								<Grid item xs={8}>
									<div>Units:</div>
									<div>Total price:</div>
								</Grid>
								<Grid item xs={2}>
									<Grid container direction="column" justifyContent="center" alignItems="flex-end">
										<div className="number-column">{cell?.original?.quantityTribalSubscription || 0}</div>
										<div className="number-column">
											<CurrencyNumber amount={cell?.original?.totalTribalSubscription || 0} precision={0} />
										</div>
									</Grid>
								</Grid>
							</Grid>
						)}
						{cell.original.type === 'mrc' && cell.original?.claimNumbers.length > 0 && cell?.original?.quantityTribalSubscription > 0 && (
							<Grid container>
								<Grid item xs={8}>
									<div>Units:</div>
									<div>Total price:</div>
								</Grid>
								<Grid item xs={2}>
									<Grid container direction="column" justifyContent="center" alignItems="flex-end">
										<div className="number-column">{cell?.original?.quantityTribalSubscription || 0}</div>
										<div className="number-column">
											<CurrencyNumber amount={cell?.original?.totalTribalSubscription || 0} precision={0} />
										</div>
									</Grid>
								</Grid>
							</Grid>
						)}
					</div>
				);
			},
			width: 200
		},
		{
			Header: 'Claimable Devices',
			accessor: 'summary',
			Cell: cell => {
				return (
					<div>
						{cell.original.type === 'claimable-devices' && cell.original?.claimNumbers.length > 0 && cell?.original?.quantity > 0 && (
							<Grid container>
								<Grid item xs={8}>
									<div>Units:</div>
									<div>Total price:</div>
								</Grid>
								<Grid item xs={2}>
									<Grid container direction="column" justifyContent="center" alignItems="flex-end">
										<div className="number-column">{cell?.original?.quantity || 0}</div>
										<div className="number-column">
											<CurrencyNumber amount={cell?.original?.total || 0} precision={0} />
										</div>
									</Grid>
								</Grid>
							</Grid>
						)}
					</div>
				);
			},
			width: 200
		}
	];

	return (
		<CardContent>
			<div className={styles.titleContainer}>
				<Typography variant="h1" align="left">
					{moment(row?.original?.claimPeriod).format('MMMM YYYY')}
				</Typography>
			</div>

			<RenderTable data={data ?? []} columns={columns} />
		</CardContent>
	);
};
