import React, { memo, useCallback, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import styles from './styles.module.scss';

export default memo(props => {
	const { title, description, onConfirm, children, okText = 'Yes', cancelText = 'No', buttonProps = {} } = props;
	const [confirmOpen, setConfirmOpen] = useState(false);
	return (
		<div className={styles.container}>
			<Button className="trigger" onClick={() => setConfirmOpen(true)} {...buttonProps}>
				{children}
			</Button>
			<Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
				<DialogContent>{(description && <DialogContentText id="alert-dialog-description">{description}</DialogContentText>) || ''}</DialogContent>
				<DialogActions>
					<Button onClick={() => setConfirmOpen(false)} color="primary">
						{cancelText}
					</Button>
					<Button
						onClick={() => {
							setConfirmOpen(false);
							return onConfirm();
						}}
						color="primary"
					>
						{okText}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
});
