import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Slide, Typography } from '@material-ui/core';
import { RenderButton, RenderInput, RenderRadioGroup, RenderRadio, RenderCheckbox, CurrencyNumber } from 'components';
import { createValidator, required, validateDepentValue, validateDailyAlocatedMaxValueParam, validateTotalFundingMaxValueParam, validatePercent } from 'services/validation';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './style';

const Transition = props => <Slide direction="up" {...props} />;
class FundingOfferRequestModal extends React.Component {
	constructor(props) {
		super(props);
		const {
			initialValues: { lenderStatus }
		} = this.props;
		this.state = {
			offerStatus: lenderStatus && lenderStatus !== '' ? lenderStatus : null
		};
		this.handleOfferStatusChange = this.handleOfferStatusChange.bind(this);
	}

	componentWillReceiveProps(newProps) {
		const { offerStatus } = this.state;
		if (newProps.initialValues) {
			const { lenderStatus: initialLenderStatus } = newProps.initialValues;
			const { lenderStatus: oldLenderStatus } = this.props;

			if (oldLenderStatus === '' || (initialLenderStatus !== 'pending' && initialLenderStatus === oldLenderStatus && offerStatus !== initialLenderStatus)) {
				this.setState({ offerStatus: initialLenderStatus });
			}
		}
	}

	contractIdFormatter = contractId => {
		const padded = `${contractId}000000`.slice(0, -contractId.length);
		return parseInt(padded)
			.toString(16)
			.toUpperCase();
	};

	handleOfferStatusChange(event) {
		this.setState({ offerStatus: event.target.value });
	}

	onChangeAmount = (ev, input) => {
		const {
			contractData: {
				contractTerms: { billingCycle, paymentTerms }
			}
		} = this.props;
		const value = ev.target.value || 0;
		let modifier = 0;
		switch (billingCycle.toLowerCase()) {
			case 'weekly':
				modifier = 7 + paymentTerms;
				break;
			case 'semi-monthly':
			case 'bi-weekly':
				modifier = 15 + paymentTerms;
				break;
			case 'prepay':
			case 'daily':
				modifier = 1 + paymentTerms;
				break;
			case 'monthly':
				modifier = 30 + paymentTerms;
				break;
			case 'bi-monthly':
				modifier = 60 + paymentTerms;
				break;
			default:
				modifier = 0;
				break;
		}
		const amount = input === 'dailyFundingAmount' ? value * modifier : value / modifier;
		this.props.change(input === 'dailyFundingAmount' ? 'totalFundingAmount' : 'dailyFundingAmount', amount.toFixed(2));
	};

	render() {
		const {
			classes,
			dialogOpen,
			dialogOpenCloseHandler,
			handleSubmit,
			contractData: {
				contractTerms: { contractId, supplier, customer, customerCurrency, billingCycle, paymentTerms, settlementPeriod, billingTimezone, contractEndDate },
				fundingRequestedAmount,
				dailyRequestedAmount,
				fundingRequestedAt,
				supplierStatus,
				lenderStatus,
				messages,
				updatedAt,
				walletFunds
			}
		} = this.props;
		const { offerStatus } = this.state;

		const supplierMessages = messages ? messages.filter(obj => obj.from === 'supplier') : '';
		const declineReason = supplierMessages && supplierMessages.length > 0 ? supplierMessages[supplierMessages.length - 1].message : '';

		const lenderMessages = messages ? messages.filter(obj => obj.from === 'lender') : '';
		const lenderDeclineReason = lenderMessages && lenderMessages.length > 0 ? lenderMessages[lenderMessages.length - 1].message : '';

		return (
			<Dialog open={dialogOpen} onBackdropClick={dialogOpenCloseHandler} TransitionComponent={Transition} maxWidth="md">
				<form onSubmit={handleSubmit}>
					<DialogTitle>Manage Funding Request</DialogTitle>
					<DialogContent>
						<Grid container>
							<Grid item xs={3}>
								<Typography className={classes.label}>Smart Contract ID</Typography>
								<Typography>{this.contractIdFormatter(contractId)}</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography className={classes.label}>Customer</Typography>
								<Typography>{customer}</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography className={classes.label}>Supplier</Typography>
								<Typography>{supplier}</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography className={classes.label}>Contract end date</Typography>
								<Typography>
									{contractEndDate === undefined || contractEndDate === 0 || contractEndDate === null ? 'Open' : moment(contractEndDate).format('DD MMM YYYY')}
								</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography className={classes.label}>Billing Cycle</Typography>
								<Typography>{billingCycle}</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography className={classes.label}>Payment Terms</Typography>
								<Typography>{parseInt(paymentTerms) > 1 ? `${paymentTerms} Days` : `${paymentTerms} Day`}</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography className={classes.label}>Settlement Period</Typography>
								<Typography>{settlementPeriod}</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography className={classes.label}>Billing Timezone</Typography>
								<Typography>{billingTimezone}</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography className={classes.label}>Wallet Funds</Typography>
								<Typography>
									<CurrencyNumber amount={walletFunds.toFixed(2) || ''} {...customerCurrency} />
								</Typography>
							</Grid>
						</Grid>
						<Divider className={classes.divider} />
						<Grid container>
							<Grid item xs={12}>
								<Typography className={classes.lendingMessage}>
									<span>{supplier}</span> requested funding for contract <span>{this.contractIdFormatter(contractId)}</span> of{' '}
									<span>
										<CurrencyNumber amount={fundingRequestedAmount || ''} {...customerCurrency} />
									</span>{' '}
									on <span>{moment(fundingRequestedAt).format('DD MMM YYYY')}</span>.
								</Typography>
								<Field name="lenderStatus" component={RenderRadioGroup} callback={this.handleOfferStatusChange} inline>
									<RenderRadio value="accepted" label="Make Offer" disabled={supplierStatus === 'accepted'} />
									<RenderRadio value="declined" label="Decline Request" disabled={supplierStatus === 'accepted'} />
								</Field>
							</Grid>
							<Grid item xs={12}>
								{offerStatus === 'accepted' ? (
									<Grid container>
										<Grid item xs={12}>
											<Field
												component={RenderInput}
												name="dailyFundingAmount"
												type="number"
												label="Daily settlement amount"
												disabled={supplierStatus === 'accepted'}
												maxamount={dailyRequestedAmount || null}
												walletfunds={walletFunds}
												// onChange={ev =>
												//   this.onChangeAmount(ev, "dailyFundingAmount")
												// }
											/>
										</Grid>
										<Grid item xs={12}>
											<Field
												component={RenderInput}
												name="totalFundingAmount"
												type="number"
												label="Requested funding amount"
												disabled={supplierStatus === 'accepted'}
												maxamount={fundingRequestedAmount || null}
												// onChange={ev =>
												//   this.onChangeAmount(ev, "totalFundingAmount")
												// }
											/>
										</Grid>
										<Grid item xs={12}>
											<Field
												component={RenderInput}
												name="interestRate"
												label="Financing Fee (%/invoice)"
												disabled={supplierStatus === 'accepted'}
												type="number"
											/>
										</Grid>
										<Grid item xs={12}>
											<Field component={RenderInput} name="percent" label="Coverage Percent %" disabled={supplierStatus === 'accepted'} type="number" />
										</Grid>
										<Grid item xs={12}>
											<Field component={RenderCheckbox} name="dailyTesspayFee" label="Subtract TessPay Fees Daily" disabled={supplierStatus === 'accepted'} />
										</Grid>
										<Grid item xs={12}>
											<small>
												<strong>Note:</strong> Please note that the Daily Settlement amount will set a daily limit, and on a daily basis, traffic will not
												go past that amount. If a daily amount is left blank supplier will have access to the Requested funding amount.
											</small>
										</Grid>
									</Grid>
								) : (
									''
								)}
								{offerStatus === 'declined' ? <Field component={RenderInput} name="declineReason" label="Message for Suplier" /> : ''}

								{supplierStatus === 'declined' ? (
									<Typography>
										<br />
										Offer declined on {moment(updatedAt).format('DD MMM YYYY')} by the supplier with the following message: <br />
										{declineReason}
									</Typography>
								) : (
									''
								)}

								{offerStatus === 'declined' && lenderStatus === 'declined' ? (
									<Typography>
										<br />
										Offer declined on {moment(updatedAt).format('DD MMM YYYY')} by the lender with the following message: <br />
										{lenderDeclineReason}
									</Typography>
								) : (
									''
								)}
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						{supplierStatus === 'declined' ? <Typography>Offer declined on {moment(updatedAt).format('DD MMM YYYY')} by the supplier.</Typography> : ''}
						{supplierStatus !== 'accepted' ? (
							<RenderButton color="primary" type="Submit">
								Submit
							</RenderButton>
						) : (
							''
						)}
						<RenderButton color="danger" onClick={dialogOpenCloseHandler}>
							Cancel
						</RenderButton>
					</DialogActions>
				</form>
			</Dialog>
		);
	}
}

FundingOfferRequestModal = withStyles(styles)(FundingOfferRequestModal);

const validate = createValidator({
	// dailyFundingAmount: [
	//   validateDepentValue("lenderStatus", "accepted", required),
	//   validateDailyAlocatedMaxValueParam
	// ],
	totalFundingAmount: [validateDepentValue('lenderStatus', 'accepted', required), validateTotalFundingMaxValueParam],
	interestRate: [validateDepentValue('lenderStatus', 'accepted', required)],
	percent: [validateDepentValue('lenderStatus', 'accepted', validatePercent)],
	declineReason: [validateDepentValue('lenderStatus', 'declined', required)],
	lenderStatus: [required]
});

export default reduxForm({
	form: 'FundingOfferRequestForm',
	destroyOnUnmount: true,
	enableReinitialize: true,
	validate
})(FundingOfferRequestModal);
