import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { createValidator, required, validatePassword, match } from 'services/validation';
import { Grid, Paper, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Slide, InputAdornment, IconButton, Typography } from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';
import { RenderButton, RenderInput, RenderCheckbox } from 'components';

const Transition = props => <Slide direction="up" {...props} />;

class SecuritySettingsForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			showPassword: false,
			mfaEnabled: false
		};
	}

	handleOpenModal = () => {
		const { isOpen } = this.state;
		this.setState({ isOpen: !isOpen });

		if (!isOpen) {
			this.props.change('oldPassword', '');
			this.props.change('newPassword', '');
			this.props.change('confirmNewPassword', '');
		}
	};

	handleMouseDownPassword = event => {
		event.preventDefault();
	};

	handleClickShowPassword = () => {
		this.setState(state => ({ showPassword: !state.showPassword }));
	};

	handleMfaEnable = () => {
		this.props.enableMfa();
	};

	handleMfaDisable = () => {
		this.props.enableMfa({ disable: true });
	};

	handleQrDisplay = () => {
		if (this.props.mfaResponse && this.props.mfaResponse.data) {
			return <img src={this.props.mfaResponse.data} />;
		}
	};

	render() {
		const { handleSubmit, mfaStatus } = this.props;
		const { isOpen, showPassword } = this.state;

		return (
			<Grid container>
				<Grid item xs={12}>
					<Paper>
						<CardContent>
							<Typography variant="h1" gutterBottom>
								Two-Step Verification
							</Typography>
							<Grid container alignItems="center">
								<Grid item xs={12}>
									<p>
										Use an Authenticator app like Google Authenticator
										<br />
										Two-step verification helps to prevent unauthorized access to your Tesspay Account by requiring a one-time password for every login attempt.
										Enable this to further secure your account.
									</p>
									{mfaStatus !== true && (
										<p>
											<RenderButton onClick={this.handleMfaEnable} color="warning">
												Enable 2FA
											</RenderButton>
										</p>
									)}
									<p>{this.handleQrDisplay()}</p>
									{mfaStatus && mfaStatus === true && (
										<p>
											Status: <RenderButton color="secondary">2FA Enabled</RenderButton>
											<RenderButton onClick={this.handleMfaDisable} color="danger">
												Disable 2FA
											</RenderButton>
										</p>
									)}
									<br />
									<p>
										<a target="_blank" href="https://www.youtube.com/watch?v=mVIxzH4EWmA">
											How to use Google Authenticator
										</a>
									</p>
								</Grid>
							</Grid>
						</CardContent>
						<hr />
						<CardContent>
							<Typography variant="h1" gutterBottom>
								Change Password
							</Typography>
							<Grid container alignItems="center">
								<Grid item xs={12}>
									<RenderButton onClick={this.handleOpenModal} color="primary">
										Change Password
									</RenderButton>
								</Grid>
							</Grid>
							<Dialog maxWidth="sm" fullWidth open={isOpen} onBackdropClick={this.handleOpenModal} TransitionComponent={Transition}>
								<form onSubmit={handleSubmit} autoComplete={false}>
									<DialogTitle>Edit user</DialogTitle>
									<DialogContent>
										<Grid container>
											<Grid item xs={12}>
												<Field
													name="oldPassword"
													label="Old Password"
													type={showPassword ? 'text' : 'password'}
													component={RenderInput}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton
																	aria-label="Toggle password visibility"
																	onClick={this.handleClickShowPassword}
																	onMouseDown={this.handleMouseDownPassword}
																>
																	{showPassword ? <VisibilityOff /> : <Visibility />}
																</IconButton>
															</InputAdornment>
														)
													}}
												/>
											</Grid>
											<Grid item xs={12}>
												<Field
													name="newPassword"
													label="New Password"
													type={showPassword ? 'text' : 'password'}
													component={RenderInput}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton
																	aria-label="Toggle password visibility"
																	onClick={this.handleClickShowPassword}
																	onMouseDown={this.handleMouseDownPassword}
																>
																	{showPassword ? <VisibilityOff /> : <Visibility />}
																</IconButton>
															</InputAdornment>
														)
													}}
												/>
											</Grid>
											<Grid item xs={12}>
												<Field
													name="confirmNewPassword"
													label="Confirm New Password"
													type={showPassword ? 'text' : 'password'}
													component={RenderInput}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton
																	aria-label="Toggle password visibility"
																	onClick={this.handleClickShowPassword}
																	onMouseDown={this.handleMouseDownPassword}
																>
																	{showPassword ? <VisibilityOff /> : <Visibility />}
																</IconButton>
															</InputAdornment>
														)
													}}
												/>
											</Grid>
										</Grid>
									</DialogContent>
									<DialogActions>
										<RenderButton color="danger" onClick={this.handleOpenModal}>
											Cancel
										</RenderButton>
										<RenderButton
											color="primary"
											type="submit"
											// disabled={submitSucceeded || pristine}
											onClick={this.handleOpenModal}
										>
											Save
										</RenderButton>
									</DialogActions>
								</form>
							</Dialog>
						</CardContent>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const validate = createValidator({
	oldPassword: [required],
	newPassword: [required, validatePassword],
	confirmNewPassword: [required, match('newPassword', 'New Password')]
});

export default reduxForm({
	form: 'SecuritySettingsForm',
	destroyOnUnmount: true,
	enableReinitialize: true,
	validate
})(SecuritySettingsForm);
