import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { PrivateHeaderContainer } from 'containers';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography, Button } from '@material-ui/core';
import headerStyle from './style';

const PrivateHeader = props => {
	const { classes } = props;
	const dispatch = useDispatch();
	const impersonated = !!window.localStorage.getItem('IMPERSONATE_CODE');

	const handleBackToTpAdmin = useCallback(() => {
		// dispatch({ type: 'LOG_OUT' });
		window.location.href = `${window.config.adminDashboardUrl}/customers`;
	});

	return (
		<div>
			{(impersonated && window.config.adminDashboardUrl && (
				<div className={classes.impersonatedBanner}>
					<Button variant="outlined" onClick={handleBackToTpAdmin} className={classes.impersonatedBannerButton}>
						Back to Administration
					</Button>
				</div>
			)) ||
				''}
			<header className={`${classes.header} mui-fixed ${(impersonated && 'impersonated') || ''}`}>
				<div>
					<Link to="/dashboard" className={classes.logo}>
						<img src="/images/TP_White.png" alt="tess-pay-logo" className={classes.logoImage} />
					</Link>
				</div>
				<PrivateHeaderContainer />
			</header>
		</div>
	);
};
PrivateHeader.propTypes = {
	classes: PropTypes.shape().isRequired
};

export default withStyles(headerStyle)(PrivateHeader);
