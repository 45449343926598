import React, { Component } from "react";
import { connect } from "react-redux";
import { SmartContractRates } from "components";
import { downloadRatePlan, downloadSampleRatePlan } from "store/actions";

class SmartContractRatesContainer extends Component {
  componentWillMount() {
    console.log("==========SmartContractRatesContainer====componentWillMount");
  }

  render() {
    return <SmartContractRates {...this.props} />;
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  handleDownloadRatePlan(smartContractId, rateplanId, originalFileName) {
    dispatch(downloadRatePlan(smartContractId, rateplanId, originalFileName));
  },
  handleDownloadSample() {
    dispatch(downloadSampleRatePlan());
  }
});

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmartContractRatesContainer);
