import React, { memo, useCallback, useRef, useState } from 'react';
import { IconButton, MenuItem, Menu, Divider } from '@material-ui/core';
import Language from '@material-ui/icons/Language';

import AccountCircle from '@material-ui/icons/AccountCircle';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

export default memo(() => {
	const dispatch = useDispatch();

	const anchor = useRef();
	const switcherAnchor = useRef();

	const [dropdownOpen, setdropdownOpen] = useState(false);
	const [languageDropdownSwitcher, setLanguageDropdownSwitcher] = useState(false);

	const accountData = useSelector(state => (state.myaccount && state.myaccount.accountData) || {});

	const triggerLogout = useCallback(() => {
		dispatch({ type: 'LOG_OUT' });
	}, []);

	const { t, i18n } = useTranslation();

	const changeLanguage = lang => {
		i18n.changeLanguage(lang);
		setLanguageDropdownSwitcher(false);
		i18n.emit('CHANGE_LANG_EVENT')
	};

	return (
		<span>
			<span className="welcome-header-test">
				{/* eslint-disable-next-line react/prop-types */}
				{t('welcome')}&nbsp;{accountData.name || ''}
				{/* eslint-disable-next-line react/prop-types */}
				{(accountData.participantName && ` | Company: ${accountData.participantName}`) || ''}
			</span>
			<IconButton color="inherit" onClick={() => setdropdownOpen(state => !state)} ref={anchor}>
				<AccountCircle />
			</IconButton>
			<IconButton color="inherit" onClick={() => setLanguageDropdownSwitcher(state => !state)} ref={switcherAnchor}>
				<Language />
			</IconButton>
			<Menu
				anchorEl={anchor.current}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={dropdownOpen}
				onClose={() => setdropdownOpen(state => !state)}
				style={{
					top: '30px',
					minWidth: '200px'
				}}
			>
				<MenuItem onClick={() => setdropdownOpen(state => !state)}>{accountData.contactPerson || ''}</MenuItem>
				<Divider />
				<MenuItem onClick={triggerLogout}>{t('logout')}</MenuItem>
			</Menu>
			<Menu
				anchorEl={switcherAnchor.current}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={languageDropdownSwitcher}
				onClose={() => setLanguageDropdownSwitcher(state => !state)}
				style={{
					top: '30px',
					minWidth: '200px'
				}}
			>
				{i18n.languages.map((language, i) => (
					<MenuItem onClick={() => changeLanguage(language)} key={i}>{t(language)}</MenuItem>
				))}
			</Menu>
		</span>
	);
});
