import React from "react";
import PropTypes from "prop-types";

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions
} from "@material-ui/core";

import { RenderButton } from "components";

const RenderConfirmDialog = props => {
  const {
    contentText,
    closeText,
    confirmText,
    title,
    open,
    openCloseHandler,
    handleConfirm,
    removeButtons
  } = props;

  const handleConfirmFn = () => {
    handleConfirm();
    openCloseHandler();
  };

  return (
      <Dialog
        open={open}
        onBackdropClick={() => openCloseHandler()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {contentText}
          </DialogContentText>
        </DialogContent>
        {removeButtons !== true && (
          <DialogActions>
            <RenderButton color="danger" onClick={openCloseHandler}>
              {closeText}
            </RenderButton>
            <RenderButton color="primary" onClick={handleConfirmFn} autoFocus>
              {confirmText}
            </RenderButton>
          </DialogActions>
        )}
      </Dialog>
  );
};

RenderConfirmDialog.defaultProps = {
  confirmText: "Yes",
  closeText: "No",
  title: "",
  contentText: "",
  removeButtons: false
};

RenderConfirmDialog.propTypes = {
  confirmText: PropTypes.string,
  closeText: PropTypes.string,
  title: PropTypes.string,
  contentText: PropTypes.string,
  open: PropTypes.bool.isRequired,
  openCloseHandler: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func
};

export default RenderConfirmDialog;
