import React from "react";
import { Field, reduxForm, change } from "redux-form";
import { Link } from "react-router-dom";
import {
  RenderInput,
  RenderSelect,
  RenderButton,
  RenderCheckbox
} from "components";
import { Grid, MenuItem } from "@material-ui/core";
import {
  createValidator,
  requiredString,
  minLength
} from "services/validation";

const currencyTypeIds = [
  {
    id: "1",
    value: "CAD"
  },
  {
    id: "2",
    value: "EUR"
  },
  {
    id: "3",
    value: "USD"
  }
];

class BankAccountForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CB: props.initialValues.correspondentBank
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.initialValues.bankCountryId !== prevState.bankCountryId) {
      const country = {
        countryId: nextProps.initialValues.bankCountryId
      };

      nextProps.getBankStatesData(country);

      return {
        bankCountryId: nextProps.initialValues.bankCountryId
      };
    }

    if (
      nextProps.initialValues.beneficiaryCountryId !==
      prevState.beneficiaryCountryId
    ) {
      const country = {
        countryId: nextProps.initialValues.beneficiaryCountryId
      };

      nextProps.getBeneficiaryStatesData(country);

      return {
        beneficiaryCountryId: nextProps.initialValues.beneficiaryCountryId
      };
    }

    if (
      nextProps.initialValues.correspondentBankCountryId !==
      prevState.correspondentBankCountryId
    ) {
      const country = {
        countryId: nextProps.initialValues.correspondentBankCountryId
      };

      nextProps.getCorrespondentStatesData(country);

      return {
        correspondentBankCountryId: nextProps.initialValues.correspondentBankCountryId
      };
    }

    return null;
  }

  showCorrespondentBank = () => {
    const { CB } = this.state;
    this.setState({ CB: !CB });
  };

  changeBankCountry = e => {
    const { getBankStatesData } = this.props;
    getBankStatesData({ countryId: e.value });
  };

  changeBeneficiaryCountry = e => {
    const { getBeneficiaryStatesData } = this.props;
    getBeneficiaryStatesData({ countryId: e.value });
  };

  changeCorrespondentCountry = e => {
    const { getCorrespondentStatesData } = this.props;
    getCorrespondentStatesData({ countryId: e.value });
  };

  copyAddress = () => {
    const { generalInformationData, dispatch, form } = this.props;
    dispatch(
      change(form, "beneficiaryCountryId", generalInformationData.countryId)
    );
    this.changeBeneficiaryCountry({ value: generalInformationData.countryId });
    dispatch(
      change(form, "accountAddress", generalInformationData.addressLine1)
    );
    dispatch(change(form, "beneficiaryCity", generalInformationData.city));
    dispatch(
      change(form, "beneficiaryStateId", generalInformationData.stateId)
    );
    dispatch(
      change(
        form,
        "beneficiaryZipPostalCode",
        generalInformationData.zipPostalCode
      )
    );
  };

  render() {
    const {
      handleSubmit,
      initialValues,
      countriesData,
      handleChange,
      reset,
      bankStatesDataArray,
      beneficiaryStatesDataArray,
      correspondentStatesDataArray,
      submitSucceeded,
      pristine
    } = this.props;

    const sc = countriesData.sort((a, b) => {
      return a.countryName
        .toLowerCase()
        .localeCompare(b.countryName.toLowerCase());
    });

    const sortedCountries = sc.map(option => ({
      value: parseInt(option.countryId),
      label: option.countryName
    }));

    return (
      <form onSubmit={handleSubmit} autoComplete="off">
        <Grid container>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={4}>
                <Field
                  id="bankName"
                  name="bankName"
                  component={RenderInput}
                  type="text"
                  label="Bank Name"
                />
              </Grid>

              <Grid item xs={4}>
                <Field
                  id="bankNickname"
                  name="bankNickname"
                  component={RenderInput}
                  type="text"
                  label="Bank Nickname"
                />
              </Grid>

              <Grid item xs={4}>
                <Field
                  id="bankCountryId"
                  name="bankCountryId"
                  label="Bank Country"
                  component={RenderSelect}
                  callback={this.changeBankCountry}
                  options={sortedCountries}
                />
              </Grid>

              <Grid item xs={8}>
                <Field
                  id="bankAddress"
                  name="bankAddress"
                  component={RenderInput}
                  type="text"
                  label="Bank Address"
                />
              </Grid>

              <Grid item xs={4}>
                <Field
                  id="bankCity"
                  name="bankCity"
                  component={RenderInput}
                  type="text"
                  label="Bank City"
                  margin="none"
                />
              </Grid>

              <Grid item xs={5}>
                <Field
                  id="bankStateId"
                  name="bankStateId"
                  label="Bank State/Province"
                  component={RenderSelect}
                  options={bankStatesDataArray.map(option => ({
                    value: option.stateId,
                    label: option.stateName
                  }))}
                />
              </Grid>

              <Grid item xs={3}>
                <Field
                  id="bankZipPostalCode"
                  name="bankZipPostalCode"
                  component={RenderInput}
                  type="text"
                  label="Bank Zip/Postal"
                  margin="none"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={6}>
                <Field
                  id="accountName"
                  name="accountName"
                  component={RenderInput}
                  type="text"
                  label="Beneficiary Name"
                />
              </Grid>

              <Grid item xs={6}>
                <Field
                  id="beneficiaryCountryId"
                  name="beneficiaryCountryId"
                  label="Beneficiary Country"
                  component={RenderSelect}
                  callback={this.changeBeneficiaryCountry}
                  options={sortedCountries}
                />
              </Grid>

              <Grid item xs={8}>
                <Field
                  id="accountAddress"
                  name="accountAddress"
                  component={RenderInput}
                  type="text"
                  label="Beneficiary Address"
                />
              </Grid>

              <Grid item xs={4}>
                <Field
                  id="beneficiaryCity"
                  name="beneficiaryCity"
                  component={RenderInput}
                  type="text"
                  label="Beneficiary City"
                  margin="none"
                />
              </Grid>

              <Grid item xs={5}>
                <Field
                  id="beneficiaryStateId"
                  name="beneficiaryStateId"
                  label="Beneficiary State/Province"
                  component={RenderSelect}
                  options={beneficiaryStatesDataArray.map(option => ({
                    value: option.stateId,
                    label: option.stateName
                  }))}
                />
              </Grid>

              <Grid item xs={3}>
                <Field
                  id="beneficiaryZipPostalCode"
                  name="beneficiaryZipPostalCode"
                  component={RenderInput}
                  type="text"
                  label="Beneficiary Zip/Postal"
                  margin="none"
                />
              </Grid>
              <Grid item xs={4}>
                <RenderButton
                  onClick={this.copyAddress}
                  component={Link}
                  color="primary"
                  to="#"
                  underlined
                >
                  Copy from business address
                </RenderButton>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Field
              id="accountNumber"
              name="accountNumber"
              component={RenderInput}
              type="text"
              label="Account Number Or IBAN"
            />
          </Grid>
          <Grid item xs={2}>
            <Field
              id="currencyId"
              name="currencyId"
              label="Currency"
              component={RenderSelect}
              options={currencyTypeIds.map(option => ({
                value: parseInt(option.id),
                label: option.value
              }))}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={3}>
            <Field
              id="aba"
              name="aba"
              component={RenderInput}
              type="text"
              label="ABA"
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              id="sortCode"
              name="sortCode"
              component={RenderInput}
              type="text"
              label="Sort Code"
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              id="swift"
              name="swift"
              component={RenderInput}
              type="text"
              label="SWIFT"
            />
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={3}>
            <Field
              id="ach"
              name="ach"
              component={RenderInput}
              type="text"
              label="ACH"
            />
          </Grid>

          <Grid item xs={3}>
            <Field
              id="achAccountType"
              name="achAccountType"
              label="Account Type"
              component={RenderSelect}
              options={[
                {
                  value: "checking",
                  label: "Checking"
                },
                {
                  value: "savings",
                  label: "Savings"
                }
              ]}
            />
          </Grid>

          <Grid item xs={12}>
            <Field
              name="correspondentBank"
              label="Correspondent Bank"
              component={RenderCheckbox}
              onClick={this.showCorrespondentBank}
            />
          </Grid>

          {this.state.CB && (
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={4}>
                  <Field
                    id="correspondentBankName"
                    name="correspondentBankName"
                    component={RenderInput}
                    type="text"
                    label="Correspondent Name"
                  />
                </Grid>

                <Grid item xs={4}>
                  <Field
                    id="correspondentBankCountryId"
                    name="correspondentBankCountryId"
                    label="Correspondent Country"
                    component={RenderSelect}
                    callback={this.changeCorrespondentCountry}
                    options={sortedCountries}
                  />
                </Grid>

                <Grid item xs={8}>
                  <Field
                    id="correspondentBankAddress"
                    name="correspondentBankAddress"
                    component={RenderInput}
                    type="text"
                    label="Correspondent Address"
                  />
                </Grid>

                <Grid item xs={4}>
                  <Field
                    id="correspondentBankCity"
                    name="correspondentBankCity"
                    component={RenderInput}
                    type="text"
                    label="Correspondent City"
                    margin="none"
                  />
                </Grid>

                <Grid item xs={5}>
                  <Field
                    id="correspondentBankStateId"
                    name="correspondentBankStateId"
                    label="Correspondent State/Province"
                    component={RenderSelect}
                    options={correspondentStatesDataArray.map(option => ({
                      value: `"${option.stateId}"`,
                      label: option.stateName
                    }))}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Field
                    id="correspondentBankZipPostalCode"
                    name="correspondentBankZipPostalCode"
                    component={RenderInput}
                    type="text"
                    label="Correspondent Zip/Postal"
                    margin="none"
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          {this.state.CB && (
            <Grid container>
              <Grid item xs={4}>
                <Field
                  id="correspondentAccountNumber"
                  name="correspondentAccountNumber"
                  component={RenderInput}
                  type="text"
                  label="Correspondent Account Number Or IBAN"
                />
              </Grid>
              <Grid item xs={2}>
                <Field
                  id="correspondentCurrencyId"
                  name="correspondentCurrencyId"
                  label="Correspondent Currency"
                  component={RenderSelect}
                  options={currencyTypeIds.map(option => ({
                    value: parseInt(option.id),
                    label: option.value
                  }))}
                />
              </Grid>
              <Grid item xs={6} />
              {/* <Grid item xs={3}>
                <Field
                  id="correspondentAba"
                  name="correspondentAba"
                  component={RenderInput}
                  type="text"
                  label="Correspondent ABA"
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  id="correspondentAch"
                  name="correspondentAch"
                  component={RenderInput}
                  type="text"
                  label="Correspondent ACH"
                />
              </Grid> */}
              <Grid item xs={3}>
                <Field
                  id="correspondentSortCode"
                  name="correspondentSortCode"
                  component={RenderInput}
                  type="text"
                  label="Correspondent Sort Code"
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  id="correspondentSwift"
                  name="correspondentSwift"
                  component={RenderInput}
                  type="text"
                  label="Correspondent SWIFT"
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container justify="center">
          <Grid item>
            <br />
            <RenderButton
              type="submit"
              color="primary"
              disabled={submitSucceeded || pristine}
            >
              Update
            </RenderButton>
            <RenderButton
              onClick={reset}
              color="warning"
              disabled={submitSucceeded || pristine}
            >
              Reset
            </RenderButton>

            <RenderButton
              onClick={handleChange(initialValues._id)}
              color="danger"
            >
              Close
            </RenderButton>
          </Grid>
        </Grid>
      </form>
    );
  }
}

const validate = createValidator({
  bankName: [requiredString, minLength(2)],
  bankNickname: [requiredString, minLength(2)],
  bankCountryId: [requiredString, minLength(1)],
  bankAddress: [requiredString, minLength(3)],
  bankCity: [requiredString, minLength(2)],
  bankStateId: [requiredString, minLength(2)],
  bankZipPostalCode: [requiredString, minLength(2)],
  accountNumber: [requiredString, minLength(2)],
  currencyId: [requiredString, minLength(1)],
  // sortCode: [requiredString, minLength(2)],
  swift: [requiredString, minLength(2)],
  // correspondentBankName: [requiredString, minLength(2)],
  // correspondentBankCountryId: [requiredString, minLength(1)],
  // correspondentBankAddress: [requiredString, minLength(3)],
  // correspondentBankCity: [requiredString, minLength(2)],
  // correspondentBankStateId: [requiredString, minLength(2)],
  // correspondentBankZipPostalCode: [requiredString, minLength(2)],
  // correspondentAccountNumber: [requiredString, minLength(2)],
  // correspondentCurrencyId: [requiredString, minLength(1)],
  // correspondentSortCode: [requiredString, minLength(2)],
  // correspondentSwift: [requiredString, minLength(2)],
  accountName: [requiredString, minLength(2)],
  beneficiaryCountryId: [requiredString, minLength(1)],
  accountAddress: [requiredString, minLength(3)],
  beneficiaryCity: [requiredString, minLength(2)],
  beneficiaryStateId: [requiredString, minLength(1)],
  beneficiaryZipPostalCode: [requiredString, minLength(2)]
});

export default reduxForm({
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate,
  touchOnBlur: false
})(BankAccountForm);
