import React, { memo, useEffect } from 'react';
import { RenderTable, RenderButton } from 'components';
import {Paper, Grid, Accordion, AccordionSummary, AccordionDetails, Badge, CardContent} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { contractIdFormatter } from 'utils';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const SellingRoutesList = memo(({ data }) => {
	const { t } = useTranslation('routing/routing');

	const columns = [
		{
			Header: t('match'),
			Cell: ({ original: { matchType, digitMatch, countryMatch } }) => matchType && matchType === 'D' && digitMatch || matchType && matchType === 'N' && countryMatch || '-',
		},
		{
			Header: t('match_type'),
			accessor: 'matchType',
			Cell: d => (d.value ? (d.value === 'D' ? t('digit') : t('country')) : '-')
		},
		{
			Header: t('route_type'),
			accessor: 'routeType',
			Cell: d => (d.value ? (d.value === 'S' ? t('static') : d.value === 'P' ? t('percentage') : 'LCR') : '-')
		},
		{
			Header: t('destination'),
			Cell: ({ original: { destinations, routeType } }) => {
				return (
					<div>
						{destinations.map((destination) => (
							<div>
								{routeType === "P" && `${destination.percentage}% - `}
								{destination.contract && contractIdFormatter(destination.contract._id, destination.contract.contractName, destination.contract.partnerName) || destination.hardwareProfile && destination.hardwareProfile.name || destination.id}
							</div>
						))}
					</div>
				)
			}
		},
	];

	const forceSortData = (data) => {
		return data.sort((a, b) => {
			const aValue = a.matchType && a.matchType === 'D' && a.digitMatch || a.matchType && a.matchType === 'N' && a.countryMatch || "0"
			const bValue = b.matchType && b.matchType === 'D' && b.digitMatch || b.matchType && b.matchType === 'N' && b.countryMatch || "0"
			if(aValue === "*") return -1
			if(bValue === "*") return 1

			return parseInt(aValue) - parseInt(bValue)
		})
	}

	return (
		<div className="routes-list">
			{(data || []).length === 0 && (
				<Paper>
					<CardContent className="text-center">
						{t('no_actives_selling')}
					</CardContent>
				</Paper>
			) || null}
			{data.map((item, index) => (
				<Paper key={index} className="route-item">
					<Accordion elevation={0} >
						<AccordionSummary expandIcon={<ExpandMore />} className={'route-item-header'}>
							<Grid container spacing={2}>
								<Grid item>
									<div className="heading-title">{item.customerName}</div>
									<div className="heading-subtitle">{(item.contracts || []).length} {t('active_contracts')}</div>
								</Grid>
							</Grid>
						</AccordionSummary>
						<AccordionDetails>
							<Grid container spacing={2}>
								{(item.contracts || []).map(({ _id, contractName, partnerName, routes }, index) => (
									<Grid item xs={12} key={index}>
										<Grid container spacing={2}>
											<Grid item xs={10}>
												<div className="heading-title">{contractIdFormatter(_id, contractName, partnerName)}</div>
											</Grid>
											<Grid item xs={2} alignItems="center" justifyContent="flex-end" className="display-flex">
												<RenderButton color="primary" component={Link} to={`/routing/${_id}`} size="small">
													{t('update_routing')}
												</RenderButton>
											</Grid>
										</Grid>
										<br />
										<RenderTable
											data={forceSortData(routes || [])}
											columns={columns}
											showPagination={false}
											sortable={false}
											minRows={2}
											noDataText={(
												<div>
													<strong>{t('no_routing_set')}</strong><br />
												</div>
											)}
										/>
										{item.contracts.length > 0 && (index+1 < item.contracts.length) && <hr /> || null}
									</Grid>
								))}
							</Grid>
						</AccordionDetails>
					</Accordion>
				</Paper>
			))}
		</div>
	);
});

export default SellingRoutesList;
