export const initialState = {};

export const getExplorerBlocksSuccessData = (state = initialState) =>
  state.SUCCESS_BLOCKS_REQUEST !== undefined
    ? state.SUCCESS_BLOCKS_REQUEST
    : "";

export const getExplorerLatestBlocksData = (state = initialState) =>
  getExplorerBlocksSuccessData(state).latestBlocks !== undefined
    ? getExplorerBlocksSuccessData(state).latestBlocks
    : [];

export const getNumberOfBlocksPerDayData = (state = initialState) =>
  getExplorerBlocksSuccessData(state).numberOfBlocksPerDay !== undefined
    ? getExplorerBlocksSuccessData(state).numberOfBlocksPerDay
    : 0;

export const getSmartContractExplorerGetDataRequest = (state = initialState) =>
  state.EXPLORER_SMART_CONTRACTS !== undefined &&
  state.EXPLORER_SMART_CONTRACTS !== null &&
  state.EXPLORER_SMART_CONTRACTS.DATA !== undefined &&
  state.EXPLORER_SMART_CONTRACTS.DATA !== null
    ? state.EXPLORER_SMART_CONTRACTS.DATA
    : {};

export const getSmartContractExplorerDataStatus = (state = initialState) =>
  state.EXPLORER_SMART_CONTRACTS !== undefined &&
  state.EXPLORER_SMART_CONTRACTS !== null &&
  state.EXPLORER_SMART_CONTRACTS.status !== undefined &&
  state.EXPLORER_SMART_CONTRACTS.status !== null
    ? state.EXPLORER_SMART_CONTRACTS.status
    : "finish";

export const getWalletExplorerGetDataResponse = (state = initialState) =>
  state.EXPLORER_WALLET_ACTION_GET_DATA_RESPONSE !== undefined &&
  state.EXPLORER_WALLET_ACTION_GET_DATA_RESPONSE.payload !== undefined
    ? state.EXPLORER_WALLET_ACTION_GET_DATA_RESPONSE.payload
    : {};

export const getStatusBlocksPolling = (state = initialState) =>
  state.STATUS_EXPLORER_INIT_BLOCKS_POLLING !== undefined &&
  state.STATUS_EXPLORER_INIT_BLOCKS_POLLING !== null
    ? state.STATUS_EXPLORER_INIT_BLOCKS_POLLING.payload
    : null;
