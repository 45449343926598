export const INFO_TEXT_REQUEST = "INFO_TEXT_REQUEST";
export const INFO_TEXT_RESPONSE = "INFO_TEXT_RESPONSE";

export const actionGetInfoTextPostDataRequest = data => ({
  type: INFO_TEXT_REQUEST,
  payload: data
});

export const actionGetInfoTextPostDataResponse = data => ({
  type: INFO_TEXT_RESPONSE,
  payload: data
});
