import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { order } from 'store/biddingportal/sagas';
import * as Yup from 'yup';

export const useForm = (order, modalOpened) => {
	const dispatch = useDispatch();

	const { loading } = useSelector(state => state.escrowmanaging || {});
	const [selectedTab, setSelectedTab] = useState('existingPartners');
	const { participatingPartners = [] } = useSelector(state => state.escrowmanaging || {});

	useEffect(() => {
		if (modalOpened) return;
		formik.resetForm();
	}, [modalOpened]);

	useEffect(() => {
		dispatch({
			type: 'escrowManaging/GET_PARTICIPATING_PARTNERS'
		});
	}, []);

	useEffect(() => {
		formik.setValues({ partnerId: '' });
	}, [selectedTab]);

	const options = useMemo(() => {
		return participatingPartners?.map(partner => ({ value: partner._id, label: partner.participantName }));
	}, [participatingPartners]);

	const { _id, orderQty, totalAssignedItems, availableEscrow, itemPrice, contracts, finance, availableItems } = order || {};

	const maxItemCost = (itemPrice * finance?.coverage) / 100;

	//******** EXTERNAL VALIDATIONS  ************/
	const isSelfFulfill = selectedTab === 'selfFulfill';
	const partnerIdValidation = isSelfFulfill ? Yup.string().notRequired() : Yup.string().required('Participant is required');
	const dueDateValidation = isSelfFulfill ? Yup.string().notRequired() : Yup.string().required('Due date is required');
	const costValidation = isSelfFulfill
		? Yup.number().notRequired()
		: Yup.number()
				.required('Cost is required')
				.min(1, 'Cost must be greater than 0')
				.max(maxItemCost, `Cost must be less than ${maxItemCost}`);

	const formik = useFormik({
		initialValues: {
			partnerId: '',
			dueDate: '',
			scQty: '',
			cost: 0,
			orderId: ''
		},
		onSubmit: values => {
			try {
				if (selectedTab === 'existingPartners') {
					dispatch({
						type: 'escrowManaging/CREATE_NEW_SMART_CONTRACT',
						payload: { ...values, orderId: _id }
					});
				}

				if (selectedTab === 'selfFulfill') {
					console.log('this runs');
					dispatch({
						type: 'escrowManaging/CREATE_SELF_FULFILLMENT_SMART_CONTRACT',
						payload: { ...values, orderId: _id }
					});
				}
			} catch (e) {
				console.log(e);
			}
		},
		validationSchema: Yup.object().shape({
			partnerId: partnerIdValidation,
			dueDate: dueDateValidation,
			scQty: Yup.number()
				.required('Items qty is required')
				.min(1, 'You must select at least 1 item to assign')
				.max(availableItems, `Only ${availableItems} items left to assign`),
			cost: costValidation
		})
	});

	useEffect(() => {
		formik.setFieldValue('scQty', availableItems);
	}, [availableItems, selectedTab]);

	return { formik, selectedTab, setSelectedTab, loading, options, availableItems };
};
