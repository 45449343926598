import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Field } from "redux-form";
import { Link } from "react-router-dom";

import { RenderInput, RenderButton, RenderNotification } from "components";

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  InputAdornment,
  IconButton
} from "@material-ui/core";

import {
  Email,
  Visibility,
  VisibilityOff,
  AddBox,
  LockOpen,
  Help
} from "@material-ui/icons";
import loginStyle from "./style";

class MfaForm extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    showPassword: false
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  render() {
    const {
      handleSubmit,
      classes,
      info: { errorMessage, successMessage }
    } = this.props;

    return (
      <form onSubmit={handleSubmit} className={classes.form}>
        <RenderNotification
          variant="danger"
          floating
          open={errorMessage !== null}
          message={errorMessage}
        />
        <RenderNotification
          variant="success"
          floating
          open={successMessage !== null && successMessage !== undefined}
          message={successMessage}
        />
        <Card className={classes.root}>
          <CardContent>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12}>
                <img src="/images/Portal_Logo.png" alt="Logo TessPay" />
                <br />
                <br />
                <br />
                <p>
                  Provide your One Time Password (OTP) in the field below to complete Sign-In.
                      </p>
                <br />
                <br />
                <Field
                  name="code"
                  label="Code"
                  type="code"
                  component={RenderInput}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <br />
                <br />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <RenderButton color="primary" type="submit">
                  Login
                </RenderButton>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </form>
    );
  }
}

export default withStyles(loginStyle)(MfaForm);
