import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button, FormControl, FormLabel, Grid, Typography } from '@material-ui/core';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import { Field } from 'redux-form';
import { RenderCheckbox, RenderInput, RoutesDestinationSwitch, RenderButton } from 'components';
import styles from './style';

const RoutesDestinationRow = props => {
	const {
		classes,
		fields,
		routeType,
		destinationOptions: { smartContracts, hardwareProfiles, onlyExternalRoutes }
	} = props;
	if (fields.length === 0) fields.push({});
	return (
		<div>
			<Grid container>
				<Grid item xs={1}>
					<Typography variant="h1">Route</Typography>
				</Grid>
				<Grid item xs={10}>
					<RenderButton color="primary" onClick={() => fields.push({})}>
						Add New Route
					</RenderButton>
				</Grid>
			</Grid>
			{fields.map((name, index) => (
				<Grid container key={index}>
					<Grid item xs={routeType === 'P' ? 4 : 6}>
						<RoutesDestinationSwitch
							name={name}
							fieldProps={fields.get(index)}
							smartContracts={smartContracts}
							onlyExternalRoutes={onlyExternalRoutes}
							hardwareProfiles={hardwareProfiles.filter(obj => obj.type === 'egress')}
						/>
					</Grid>
					{routeType === 'P' && (
						<Grid item xs={2}>
							<Field component={RenderInput} name={`${name}.percentage`} label="Percentage" />
						</Grid>
					)}
					<Grid item xs={2}>
						<Field component={RenderInput} name={`${name}.sessions`} label="Ports" />
					</Grid>
					<Grid item xs={routeType === 'S' ? 1 : 2}>
						<Field component={RenderInput} name={`${name}.cps`} label="CPS" />
					</Grid>
					{routeType === 'S' ? (
						<Grid item xs={1}>
							{index > 0 ? (
								<Button onClick={() => fields.move(index, index - 1)}>
									<ArrowUpward />
								</Button>
							) : (
								''
							)}
							{index + 1 < fields.length ? (
								<Button onClick={() => fields.move(index, index + 1)}>
									<ArrowDownward />
								</Button>
							) : (
								''
							)}
						</Grid>
					) : (
						''
					)}
					<Grid item xs={1}>
						<FormControl component="fieldset">
							<FormLabel component="legend">Active</FormLabel>
							<Field component={RenderCheckbox} name={`${name}.active`} />
						</FormControl>
					</Grid>
					<Grid item xs={1}>
						<RenderButton color="danger" onClick={() => fields.remove(index)}>
							Delete
						</RenderButton>
					</Grid>
				</Grid>
			))}
		</div>
	);
};

RoutesDestinationRow.defaultProps = {
	destinationOptions: {
		smartContracts: [],
		hardwareProfiles: []
	}
};

export default withStyles(styles)(RoutesDestinationRow);
