import React, { Component } from "react";
import {
  Grid,
  MenuItem,
  InputLabel,
  Select,
  TextField,
  FormControl,
  Switch,
  FormControlLabel
} from "@material-ui/core";
// import MomentUtils from "material-ui-pickers/utils/moment-utils";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { RenderButton } from "components";

class ReportFiltersTraffic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      filterOptions: {
        P_CC: "",
        P_SH: "00",
        P_EH: "23",
        P_SH_SUB: "Y",
        P_COMPL: "Y",
        P_SUM_CYCLE: "N",
        selectedCarrier: ""
      },
      selectedContractId: ""
    };
  }

  handleSmartContract = evt => {
    this.setState({ selectedContractId: evt.target.value });
  };

  handleStartDate = date => {
    const { endDate } = this.state;

    this.setState({ startDate: date });
    this.handleInterval(date, endDate);
  };

  handleEndDate = date => {
    const { startDate } = this.state;

    this.setState({ endDate: date });
    this.handleInterval(startDate, date);
  };

  handleInterval = (dateInterval1, dateInterval2) => {
    if (dateInterval1 === null || dateInterval2 === null) {
      return;
    }
    this.setState({
      interval: `${dateInterval1.format("MM/DD/YYYY")}-${dateInterval2.format(
        "MM/DD/YYYY"
      )}`
    });
  };

  handleInput = evt => {
    let val = evt.target.value;
    if (evt.target.type === "checkbox") {
      val = evt.target.checked ? "Y" : "N";
    }
    const newState = {
      filterOptions: {
        ...this.state.filterOptions,
        [evt.target.name]: val
      }
    };

    this.setState(newState);
  };

  handleSwitch = (stateName, checked) => {
    const newState = {
      filterOptions: {
        ...this.state.filterOptions,
        [stateName]: checked ? "Y" : "N"
      }
    };
    this.setState(newState);
  };

  handleSelect = evt => {
    const newState = {
      filterOptions: {
        ...this.state.filterOptions,
        [evt.target.name]: evt.target.value
      }
    };

    this.setState(newState);
  };

  runReport = () => {
    const { loadReportHandler } = this.props;
    const { interval, filterOptions, selectedContractId } = this.state;

    loadReportHandler(interval, filterOptions, selectedContractId);
  };

  render() {
    const { carrierList, reportType, contracts } = this.props;
    const {
      filterOptions,
      startDate,
      endDate,
      selectedContractId
    } = this.state;

    const carriers = carrierList || [];
    const carrierLabel =
      reportType === "customerTraffic" ? "Customer" : "Supplier";

    return (
      <div>
        {reportType === "margin" ? (
          <Grid container>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <InputLabel>Supplier</InputLabel>
                <Select
                  id="selectedCarrier"
                  name="selectedCarrier"
                  value={filterOptions.selectedCarrier}
                  onChange={this.handleSelect}
                >
                  <MenuItem value="" label="All">
                    All
                  </MenuItem>
                  {carriers.map((option, i) => (
                    <MenuItem value={option} label={option} key={i}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel>Select smart contract</InputLabel>
                <Select
                  name="smartContracts"
                  value={selectedContractId}
                  onChange={this.handleSmartContract}
                >
                  <MenuItem value="">All</MenuItem>
                  {contracts
                    ? contracts.map(el => (
                        <MenuItem value={el.id} key={el.id}>
                          {el.id} {el.referenceNumber}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    leftArrowIcon={<KeyboardArrowLeft color="primary" />}
                    rightArrowIcon={<KeyboardArrowRight color="primary" />}
                    label="Select start date"
                    format="DD/MM/YYYY"
                    value={startDate}
                    onChange={this.handleStartDate}
                    disableFuture
                    autoOk
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    leftArrowIcon={<KeyboardArrowLeft color="primary" />}
                    rightArrowIcon={<KeyboardArrowRight color="primary" />}
                    label="Select end date"
                    format="DD/MM/YYYY"
                    value={endDate}
                    onChange={this.handleEndDate}
                    minDate={startDate}
                    disableFuture
                    autoOk
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    leftArrowIcon={<KeyboardArrowLeft color="primary" />}
                    rightArrowIcon={<KeyboardArrowRight color="primary" />}
                    label="Select start date"
                    format="DD/MM/YYYY"
                    value={startDate}
                    onChange={this.handleStartDate}
                    disableFuture
                    autoOk
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    leftArrowIcon={<KeyboardArrowLeft color="primary" />}
                    rightArrowIcon={<KeyboardArrowRight color="primary" />}
                    label="Select end date"
                    format="DD/MM/YYYY"
                    value={endDate}
                    onChange={this.handleEndDate}
                    minDate={startDate}
                    disableFuture
                    autoOk
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <InputLabel>
                  {reportType === "saleAndCost" ? "Customer" : carrierLabel}
                </InputLabel>
                <Select
                  id="selectedCarrier"
                  name="selectedCarrier"
                  value={filterOptions.selectedCarrier}
                  onChange={this.handleSelect}
                >
                  <MenuItem value="" label="All">
                    All
                  </MenuItem>
                  {carriers.map((option, i) => (
                    <MenuItem value={option} label={option} key={i}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <TextField
                  name="P_CC"
                  id="P_CC"
                  value={filterOptions.P_CC}
                  onChange={this.handleInput}
                  fullWidth
                  label="Country/City Code"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <InputLabel>Destination Type</InputLabel>
                <Select id="destinationType" name="destinationType">
                  <MenuItem value="" label="All">
                    All
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <InputLabel>Destination Carrier</InputLabel>
                <Select id="destinationCarrier" name="destinationCarrier">
                  <MenuItem value="" label="All">
                    All
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}

        <Grid container justify="flex-end">
          <Grid item>
            <RenderButton color="primary" onClick={this.runReport}>
              Run report
            </RenderButton>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default ReportFiltersTraffic;
