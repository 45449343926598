import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Grid, Typography, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText } from '@material-ui/core';
import {useTranslation} from "react-i18next";
import { contractIdFormatter } from 'utils';
import { RenderButton, RenderFormikInput } from 'components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import {
	saveUpdateContractName
} from 'store/actions';


const SmartContractsCommercialTermsReadOnly = props => {

	const { t } = useTranslation(['smartcontracts/edit', 'translations']);

	const {
		smartContract,
		dataNewSmartContract,
		newSmartContractData,
		catalogsNewSmartContract: { paymentTerms: catalogPaymentTerms },
		initialValues: { paymentTerms, invoicePeriodId, endDate, minimumNoticeDays }
	} = props;

	let billingCycleName = invoicePeriodId ? newSmartContractData.invoicePeriods.find(obj => obj._id === invoicePeriodId) : false;
	billingCycleName = billingCycleName ? billingCycleName.invoicePeriodType : '';
	const endDateName =
		endDate === undefined || endDate === 0 || endDate === null || endDate === new Date('1970-01-01T00:00:00.000Z') ? t('open') : moment(endDate).format('MMM DD YYYY');

	const [confirmationUpdateModalOpen, setUpdateModalOpen] = useState(false);

	const showConfirmation = useCallback(
		val => {
			setUpdateModalOpen(state => !state);
		},
		[confirmationUpdateModalOpen]
	);

	const dispatch = useDispatch();
	const formik = useFormik({
		initialValues: {
			name: ""
		},
		onSubmit: (values, { resetForm }) => {
			if(!smartContract._id) return
			dispatch(saveUpdateContractName(smartContract._id, values));
			setUpdateModalOpen(state => !state);
		},
		validationSchema: Yup.object().shape({
			// idContract: Yup.string().required(t('please_input_help', {label: t('name')})),
			// nickname: Yup.string().required(t('please_input_help', {label: t('name')})),
		})
	});

	useEffect(() => {
		if(smartContract._id) {
			formik.setValues({
				name: smartContract.name
			})
		}
	},[JSON.stringify(smartContract)])

	return (
		<div>
			<Typography variant="h2">{t('commercial_terms')}</Typography>
			<form onSubmit={formik.handleSubmit}>
				<Grid container>
					<Grid item xs={4}>
						<strong>{t('name')}:</strong>
					</Grid>
					<Grid item xs={8}>
						<Grid container spacing={1}>
							<Grid item>
								<RenderFormikInput name="name" placeholder={contractIdFormatter(smartContract._id)} formik={formik} />
							</Grid>
							{/*{!!formik.values.nickname && formik.values.nickname && (*/}
								<Grid item>
									<RenderButton size="xs" color="primary" onClick={()=> showConfirmation('test')}>
										{t('Update')}
									</RenderButton>
								</Grid>
							{/*)}*/}
						</Grid>


					</Grid>
					<Grid item xs={4}>
						<strong>{t('min_notice_period')}:</strong>
					</Grid>
					<Grid item xs={8}>
						{minimumNoticeDays}
					</Grid>
					<Grid item xs={4}>
						<strong>{t('billing_cycle')}:</strong>
					</Grid>
					<Grid item xs={8}>
						{billingCycleName}
					</Grid>
					<Grid item xs={4}>
						<strong>{t('payment_terms')}:</strong>
					</Grid>
					<Grid item xs={8}>
						{parseInt(paymentTerms) > 1 ? t('translations:x_days', { num: paymentTerms }) : t('translations:x_day', { num: paymentTerms })}
					</Grid>
					<Grid item xs={4}>
						<strong>{t('settlement_period')}:</strong>
					</Grid>
					<Grid item xs={8}>
						{newSmartContractData.defaultSettlementPeriod.settlementPeriod}
					</Grid>
					<Grid item xs={4}>
						<strong>{t('billing_timezone')}:</strong>
					</Grid>
					<Grid item xs={8}>
						GMT
					</Grid>
					<Grid item xs={4}>
						<strong>{t('contract_end_date')}:</strong>
					</Grid>
					<Grid item xs={8}>
						{endDateName}
					</Grid>
				</Grid>
				<Dialog
					maxWidth="sm"
					fullWidth
					open={confirmationUpdateModalOpen}
				>
					<DialogTitle id="alert-dialog-title">{t('dialog_title')}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">{t('dialog_content')}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<RenderButton onClick={() => setUpdateModalOpen(false)} >
							{t('no')}
						</RenderButton>
						<RenderButton color="primary" onClick={formik.handleSubmit}>{t('yes')}</RenderButton>
					</DialogActions>
				</Dialog>
			</form>
		</div>
	);
};
SmartContractsCommercialTermsReadOnly.propTypes = {
	newSmartContractData: PropTypes.shape({
		// participant: PropTypes.shape({
		// 	participantName: PropTypes.string.isRequired
		// }).isRequired,
		defaultSettlementPeriod: PropTypes.shape({
			// settlementId: PropTypes.string.isRequired,
			settlementPeriod: PropTypes.string.isRequired
		}).isRequired,
		// suppliersList: PropTypes.shape.isRequired,
		// customersList: PropTypes.shape.isRequired
	}).isRequired,
	// catalogsNewSmartContract: PropTypes.shape({
	// 	voipProtocols: PropTypes.array.isRequired,
	// 	codecs: PropTypes.array.isRequired,
	// 	paymentTerms: PropTypes.array.isRequired,
	// 	paymentTermsNet: PropTypes.array.isRequired
	// }).isRequired,
	initialValues: PropTypes.shape().isRequired
};

export default SmartContractsCommercialTermsReadOnly;
