import React from 'react';
import ReactTable from 'react-table';
import selectTableHOC from 'react-table/lib/hoc/selectTable';
import { PropTypes } from 'prop-types';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import './style.css';
import { RenderTablePagination } from 'components';

const SelectTable = selectTableHOC(ReactTable);
// const SelectTable = ReactTable;

const RenderTable = (props, ref) => {
	const { isSelectTable, columns, data, defaultPageSize, filterable, isExpandable, defaultExpanded, showExpander, forcePagination, forceSingleExpand, ...rest } = props;

	/* eslint-disable */
	if (isExpandable) {
		columns.push({
			Header: '',
			expander: true,
			Expander: ({ isExpanded }) =>
				showExpander ? <div className="rt-expander">{isExpanded ? <ExpandLess fontSize="inherit" /> : <ExpandMore fontSize="inherit" />}</div> : null
		});
	}
	/* eslint-enable */
	const RTable = isSelectTable ? SelectTable : ReactTable;

	const showPagination = (data && data.length > defaultPageSize) || forcePagination;
	return (
		<RTable
			ref={ref}
			filterable={filterable}
			data={(data && Array.isArray(data) && data) || []}
			columns={columns}
			defaultExpanded={defaultExpanded}
			defaultPageSize={defaultPageSize}
			PaginationComponent={RenderTablePagination}
			minRows={0}
			showPagination={showPagination}
			getTrProps={(state, rowInfo, col, instance) => {
				if (!isExpandable || rowInfo === undefined) {
					return false;
				}

				const { expanded } = state;
				const path = rowInfo.nestingPath[0];
				const diff = { [path]: !expanded[path] };

				return {
					onClick: (e, handleOriginal) => {
						if (forceSingleExpand) {
							if (typeof path !== 'undefined') {
								instance.setState({
									expanded: {
										...diff
									}
								});
							}
						} else {
							instance.setState({
								expanded: {
									...expanded,
									...diff
								}
							});
						}

						if (handleOriginal) {
							handleOriginal();
						}
					},
					style: {
						cursor: isExpandable && showExpander && 'pointer',
						backgroundColor: diff[rowInfo.index] ? '' : 'rgba(0, 0, 0, 0.05)'
					}
				};
			}}
			{...rest}
		/>
	);
};

RenderTable.defaultProps = {
	data: [],
	defaultPageSize: 10,
	filterable: true,
	isExpandable: false,
	isSelectTable: false,
	defaultExpanded: false,
	showExpander: true,
	forcePagination: false
};

RenderTable.propTypes = {
	columns: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	defaultPageSize: PropTypes.number,
	filterable: PropTypes.bool,
	isExpandable: PropTypes.bool,
	isSelectTable: PropTypes.bool,
	defaultExpanded: PropTypes.bool,
	showExpander: PropTypes.bool,
	forcePagination: PropTypes.bool
};

export default React.forwardRef(RenderTable);
