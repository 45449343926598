import { call, put, takeEvery, all, takeLatest } from 'redux-saga/effects';
import ReportApi from 'services/api/ReportApi';
import { saveAs } from 'file-saver';
import { actionCreatorPreloaderOn, actionCreatorPreloaderOff } from '../preloader/actions';
import { triggerNotification } from '../notifications/actions';
import actions, {
	invoiceDownloadCompleted,
	loadCustomerInvoicesSuccess,
	loadCustomerMonthlyInvoicesSuccess,
	loadExternalInvoicesError,
	loadExternalInvoicesSuccess,
	loadInvoicesError,
	loadMonthlyInvoiceError,
	loadSupplierInvoicesSuccess,
	loadSupplierMonthlyInvoicesSuccess,
	loadInvoices,
	setPaymentStatus
} from './actions';
import { Util } from 'components';

export function* getMonthlyInvoices(action) {
	try {
		let dataResult;
		switch (action.payload.type) {
			case 'supplier':
				dataResult = yield call(ReportApi.getSupplierMonthlyInvoices);
				yield put(loadSupplierMonthlyInvoicesSuccess(dataResult));
				break;
			default:
				dataResult = yield call(ReportApi.getCustomerMonthlyInvoices);
				yield put(loadCustomerMonthlyInvoicesSuccess(dataResult));
		}
	} catch (err) {
		yield put(loadMonthlyInvoiceError(err, action.payload.type));
	}
}

export function* getExternalInvoices() {
	try {
		const dataResult = yield call(ReportApi.getExternalInvoices);
		yield put(loadExternalInvoicesSuccess(dataResult));
	} catch (err) {
		yield put(loadExternalInvoicesError(err));
	}
}

export function* getInvoices(action) {
	try {
		let dataResult;

		switch (action.payload.type) {
			case 'supplier':
				dataResult = yield call(ReportApi.getSupplierInvoices, action.payload.isOrigination);

				if (dataResult?.response && Util.isErrorResponse(dataResult?.response)) {
					break;
				} else {
					yield put(loadSupplierInvoicesSuccess(dataResult, action.payload.isOrigination));
					break;
				}

			default:
				dataResult = yield call(ReportApi.getCustomerInvoices, action.payload.isOrigination);

				if (dataResult?.response && Util.isErrorResponse(dataResult?.response)) {
					break;
				} else {
					yield put(loadCustomerInvoicesSuccess(dataResult, action.payload.isOrigination));
					break;
				}
		}
	} catch (err) {
		yield put(loadInvoicesError(err, action.payload.type, action.payload.isOrigination));
	}
}

export function* downloadInvoice(action) {
	try {
		const { invoiceId, invoiceNumber, type, isOrigination } = action.payload;
		const blobData = yield call(ReportApi.downloadInvoice, {
			invoiceId,
			invoiceNumber,
			type,
			isOriginContract: isOrigination
		});
		const blob = new Blob([blobData]);
		saveAs(blob, `${invoiceNumber}.pdf`);
		yield put(invoiceDownloadCompleted());
	} catch (err) {
		yield put(invoiceDownloadCompleted());
	}
}

export function* payInvoice(action) {
	try {
		yield call(ReportApi.payInvoice, action.payload);
		const dataResult = yield call(ReportApi.getSupplierInvoices, true);
		yield put(loadSupplierInvoicesSuccess(dataResult, true));
	} catch (err) {
		console.log(err);
	}
}

export function* supplierPayInvoice(action) {
	try {
		yield put(actionCreatorPreloaderOn());
		yield put(setPaymentStatus({ status: 'inProgress', message: 'Your payment is being processed' }));
		const result = yield call(ReportApi.supplierPayInvoice, action.payload);

		const JSONResponse = JSON.stringify(result);

		console.log(result);
		// console.log(JSONResponse);

		const response = result?.response ?? '';

		let responseObject;
		if (result?.response && Util.isErrorResponse(result?.response)) {
			responseObject = {
				status: 'error',
				message: response?.details === undefined ? (response?.data === undefined ? 'There was an error!' : response?.data?.message) : response?.details
			};

			yield put(setPaymentStatus(responseObject));
			yield put(actionCreatorPreloaderOff());
		} else {
			responseObject = {
				status: 'success',
				message: response?.status || 'Invoice payed successfully'
			};

			const dataResult = yield call(ReportApi.getSupplierInvoices, true);
			yield put(loadSupplierInvoicesSuccess(dataResult, true));
			yield put(setPaymentStatus(responseObject));
			yield put(setPaymentStatus({ status: '', message: '' }));
			yield put(actionCreatorPreloaderOff());
		}
	} catch (error) {
		console.log('SUPPLIER_PAY_INVOICE_ERROR', error);
	}
}

export function* GET_FILTER_DATA({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loadingFilters: true
		}
	});
	try {
		const result = yield call(ReportApi.getFilterData, payload);

		if (result) {
			yield put({
				type: 'reports/SET_STATE',
				payload: {
					reportsFilters: result
				}
			});
		}
		//
	} catch (error) {
		console.log('GET_FILTER_DATA ERROR', error);
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loadingFilters: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_CUSTOMER_REPORT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(ReportApi.getCustomerReport, payload);

		if (result && Array.isArray(result)) {
			yield put({
				type: 'reports/SET_STATE',
				payload: {
					customerReport: result
				}
			});
		}
		//
	} catch (error) {
		console.log('GET_CUSTOMER_REPORT ERROR', error);
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_SUPPLIER_REPORT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(ReportApi.getSupplierReport, payload);

		if (result && Array.isArray(result)) {
			yield put({
				type: 'reports/SET_STATE',
				payload: {
					supplierReport: result
				}
			});
		}
		//
	} catch (error) {
		console.log('GET_SUPPLIER_REPORT ERROR', error);
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_DASHBOARD_SESSION_ACTIVE_CALLS() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(ReportApi.getSessionActiveCalls);

		if (result && Array.isArray(result)) {
			yield put({
				type: 'reports/SET_STATE',
				payload: {
					sessionsActiveCalls: result
				}
			});
		}
		//
	} catch (error) {
		console.log('GET_DASHBOARD_SESSION_ACTIVE_CALLS ERROR', error);
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_SALE_AND_COST_REPORT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(ReportApi.getSaleAndCostReport, payload);

		if (result && Array.isArray(result)) {
			yield put({
				type: 'reports/SET_STATE',
				payload: {
					saleAndCostReport: result
				}
			});
		}
		//
	} catch (error) {
		console.log('GET_SUPPLIER_REPORT ERROR', error);
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_CDR_REPORT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(ReportApi.getCDRReport, payload);

		if (result && Array.isArray(result)) {
			yield put({
				type: 'reports/SET_STATE',
				payload: {
					CDRReport: result
				}
			});
		}
		//
	} catch (error) {
		console.log('GET_CDR_REPORT ERROR', error);
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* REQUEST_CDR_REPORT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(ReportApi.requestCDRReport, payload);
		if (result) {
			yield put(triggerNotification('CDR Report requested', 'success'));
			yield put({ type: 'reports/GET_CDR_REPORT' });
		}
	} catch (error) {
		console.log('REQUEST_CDR_REPORT ERROR', error);
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* DOWNLOAD_CUSTOMER_REPORT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(ReportApi.getCustomerReport, payload, true);
		if (result) {
			saveAs(result, 'customer-report.csv');
		}
	} catch (error) {
		console.log('DOWNLOAD_CUSTOMER_REPORT ERROR', error);
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* DOWNLOAD_SUPPLIER_REPORT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(ReportApi.getSupplierReport, payload, true);
		if (result) {
			saveAs(result, 'supplier-report.csv');
		}
	} catch (error) {
		console.log('DOWNLOAD_SUPPLIER_REPORT ERROR', error);
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* DOWNLOAD_SALE_AND_COST_REPORT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(ReportApi.getSaleAndCostReport, payload, true);
		if (result) {
			saveAs(result, 'sale-and-cost-report.csv');
		}
	} catch (error) {
		console.log('DOWNLOAD_SALE_AND_COST_REPORT ERROR', error);
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* DOWNLOAD_CDR_REPORT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(ReportApi.downloadCDRReport, payload);
		if (result) {
			saveAs(result, 'cdr-report.zip');
		}
	} catch (error) {
		console.log('DOWNLOAD_CDR_REPORT ERROR', error);
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_DASHBOARD_PARTITION_STATS() {
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loadingPartitionStats: true
		}
	});
	const response = yield call(ReportApi.getPartitionStats);
	if (response && !response.error) {
		yield put({
			type: 'reports/SET_STATE',
			payload: {
				partitionStats: response
			}
		});
	}
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loadingPartitionStats: false
		}
	});
}

export function* GET_DASHBOARD_PARTITION_ACTIVE_CALLS() {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loadingPartitionActiveCalls: true
		}
	});
	const response = yield call(ReportApi.getPartitionActiveCalls);
	if (response && !response.error) {
		yield put({
			type: 'reports/SET_STATE',
			payload: {
				partitionActiveCalls: response
			}
		});
	}

	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loadingPartitionActiveCalls: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_ADVANCED_REPORT({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loadingAdvancedReports: true
		}
	});
	const response = yield call(ReportApi.getAdvancedReport, payload.filters, payload.params);
	if (response && !response.error) {
		yield put({
			type: 'reports/SET_STATE',
			payload: {
				advancedReport: response
			}
		});
	}

	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loadingAdvancedReports: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* NEW_REPORT_TEMPLATE({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loadingReportTemplates: true
		}
	});
	try {
		const result = yield call(ReportApi.saveTemplateReport, payload);
		if (result) {
			yield put({ type: 'reports/GET_REPORT_TEMPLATES' });
		}
	} catch (error) {
		console.log('NEW_REPORT_TEMPLATE ERROR', error);
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loadingReportTemplates: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* UPDATE_REPORT_TEMPLATE({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loadingReportTemplates: true
		}
	});
	try {
		const result = yield call(ReportApi.updateTemplateReport, payload);
		if (result) {
			yield put({ type: 'reports/GET_REPORT_TEMPLATES' });
		}
	} catch (error) {
		console.log('UPDATE_REPORT_TEMPLATE ERROR', error);
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loadingReportTemplates: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* DELETE_REPORT_TEMPLATE({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loadingReportTemplates: true
		}
	});
	try {
		const result = yield call(ReportApi.deleteTemplateReport, payload);
		if (result) {
			yield put({ type: 'reports/GET_REPORT_TEMPLATES' });
		}
	} catch (error) {
		console.log('DELETE_REPORT_TEMPLATE ERROR', error);
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loadingReportTemplates: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_REPORT_TEMPLATE({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loadingReportTemplates: true
		}
	});
	try {
		const result = yield call(ReportApi.getReportTemplate, payload);
		if (result) {
			console.log('result', result);
			yield put({
				type: 'reports/SET_STATE',
				payload: {
					reportTemplate: result
				}
			});
		}
	} catch (error) {
		console.log('GET_REPORT_TEMPLATE ERROR', error);
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loadingReportTemplates: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_REPORT_TEMPLATES({ payload }) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loadingReportTemplates: true
		}
	});
	try {
		const result = yield call(ReportApi.getReportTemplates);
		if (result) {
			yield put({
				type: 'reports/SET_STATE',
				payload: {
					reportTemplates: result
				}
			});
		}
	} catch (error) {
		console.log('GET_REPORT_TEMPLATES ERROR', error);
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			loadingReportTemplates: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_EXTERNAL_INVOICES({ payload }) {
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			EXTERNAL_INVOICE_REPORT_DATA: {
				data: [],
				loading:
					// !payload?.externalInvoicesCached ??
					true,
				error: null
			}
		}
	});
	try {
		const dataResult = yield call(ReportApi.getExternalInvoices);
		if (dataResult) {
			yield put({
				type: 'reports/SET_STATE',
				payload: {
					EXTERNAL_INVOICE_REPORT_DATA: {
						data: dataResult,
						loading: false,
						error: null,
						externalInvoicesCached: true
					}
				}
			});
			return;
		}

		yield put({
			type: 'reports/SET_STATE',
			payload: {
				EXTERNAL_INVOICE_REPORT_DATA: {
					data: [],
					loading: false,
					error: null,
					externalInvoicesCached: true
				}
			}
		});
	} catch (err) {
		yield put({
			type: 'reports/SET_STATE',
			payload: {
				EXTERNAL_INVOICE_REPORT_DATA: {
					data: [],
					loading: false,
					error: err.info
				}
			}
		});
	}
}

export function* GET_MONTHLY_INVOICES(action) {
	yield put({
		type: 'reports/SET_MONTHLY_INVOICES_STATE',
		payload: {
			clientType: action.payload.type,
			payload: { loading: true }
		}
	});

	try {
		let dataResult;
		switch (action.payload.type) {
			case 'supplier':
				dataResult = yield call(ReportApi.getSupplierMonthlyInvoices);
				break;
			default:
				dataResult = yield call(ReportApi.getCustomerMonthlyInvoices);
				break;
		}

		yield put({
			type: 'reports/SET_MONTHLY_INVOICES_STATE',
			payload: {
				clientType: action.payload.type,
				payload: {
					data: dataResult,
					loading: false,
					info: '',
					invoiceError: undefined
				}
			}
		});
	} catch (err) {
		yield put({
			type: 'reports/SET_MONTHLY_INVOICES_STATE',
			payload: {
				clientType: action.payload.type,
				payload: {
					data: [],
					loading: false,
					info: {
						error: err
					},
					invoiceError: undefined
				}
			}
		});
	}
}

export function* GET_INVOICES(action) {
	const isOrigination = action.payload.isOrigination ? 'origination' : 'standard';

	yield put({
		type: 'reports/SET_INVOICES_STATE_BY_TYPE',
		payload: {
			invoiceType: action.payload.type,
			isOrigination,
			payload: { loading: true }
		}
	});
	try {
		let dataResult;

		switch (action.payload.type) {
			case 'supplier':
				dataResult = yield call(ReportApi.getSupplierInvoices, action.payload.isOrigination);
				break;

			default:
				dataResult = yield call(ReportApi.getCustomerInvoices, action.payload.isOrigination);
				break;
		}

		if (dataResult?.response && Util.isErrorResponse(dataResult?.response)) {
			yield put({
				type: 'reports/SET_INVOICES_STATE_BY_TYPE',
				payload: {
					invoiceType: action.payload.type,
					isOrigination,
					payload: {
						data: dataResult,
						loading: false,
						info: {
							error: dataResult?.response
						},
						invoiceError: dataResult?.response
					}
				}
			});
			return;
		}

		yield put({
			type: 'reports/SET_INVOICES_STATE_BY_TYPE',
			payload: {
				invoiceType: action.payload.type,
				isOrigination,
				payload: {
					data: dataResult,
					loading: false,
					info: undefined,
					invoiceError: undefined
				}
			}
		});
	} catch (err) {
		yield put({
			type: 'reports/SET_INVOICES_STATE_BY_TYPE',
			payload: {
				invoiceType: action.payload.type,
				isOrigination,
				payload: {
					data: dataResult,
					loading: false,
					info: {
						error: err
					},
					invoiceError: err
				}
			}
		});
	}
}

export function* DOWNLOAD_INVOICE(action) {
	yield put({
		type: 'reports/SET_STATE',
		payload: {
			INVOICE_DOWNLOAD_DATA: {
				isDownloading: true
			}
		}
	});
	try {
		const { invoiceId, invoiceNumber, type, isOrigination } = action.payload;
		const blobData = yield call(ReportApi.downloadInvoice, {
			invoiceId,
			invoiceNumber,
			type,
			isOriginContract: isOrigination
		});
		const blob = new Blob([blobData]);
		saveAs(blob, `${invoiceNumber}.pdf`);
	} catch (err) {
		// yield put(invoiceDownloadCompleted());
	} finally {
		yield put({
			type: 'reports/SET_STATE',
			payload: {
				INVOICE_DOWNLOAD_DATA: {
					isDownloading: false
				}
			}
		});
	}
}

export function* PAY_INVOICE(action) {
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: 'reports/SET_INVOICES_GENERAL_STATE',
		payload: {
			paymentInfo: {
				status: 'inProgress',
				message: 'Your payment is being processed'
			}
		}
	});
	try {
		const result = yield call(ReportApi.supplierPayInvoice, action.payload);
		const response = result?.response ?? '';

		//************************CHECKING IF THERE IS AN ERROR **********************/
		if (result?.response && Util.isErrorResponse(result?.response)) {
			yield put({
				type: 'reports/SET_INVOICES_GENERAL_STATE',
				payload: {
					paymentInfo: {
						status: 'error',
						message: response?.details === undefined ? (response?.data === undefined ? 'There was an error!' : response?.data?.message) : response?.details
					}
				}
			});

			return;
		}

			//************************ UPDATING STATUS WITH SUCCESSFULL TRANSACTION **********************/
		yield put({
			type: 'reports/SET_INVOICES_GENERAL_STATE',
			payload: {
				paymentInfo: {
					status: 'success',
					message: response?.status || 'Invoice payed successfully'
				}
			}
		});

		//************************ FETCHING INVOICES AFTER PAYMENT **********************/
		yield put({
			type: 'reports/GET_INVOICES',
			payload: {
				type: 'supplier',
				isOrigination: true,
				invoicesCached: true
			}
		});
	} catch (error) {
		console.log('SUPPLIER_PAY_INVOICE_ERROR', error);
		yield put({
			type: 'reports/SET_INVOICES_GENERAL_STATE',
			payload: {
				paymentInfo: {
					status: 'error',
					message: 'There was an error while paying the invoce.'
				}
			}
		});
	} finally {
		yield put({
			type: 'reports/SET_INVOICES_GENERAL_STATE',
			payload: { paymentInfo: { status: '', message: '' } }
		});
		yield put(actionCreatorPreloaderOff());
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.GET_FILTER_DATA, GET_FILTER_DATA),
		takeEvery(actions.GET_CUSTOMER_REPORT, GET_CUSTOMER_REPORT),
		takeEvery(actions.GET_SUPPLIER_REPORT, GET_SUPPLIER_REPORT),
		takeEvery(actions.GET_SALE_AND_COST_REPORT, GET_SALE_AND_COST_REPORT),
		takeEvery(actions.GET_CDR_REPORT, GET_CDR_REPORT),
		takeEvery(actions.REQUEST_CDR_REPORT, REQUEST_CDR_REPORT),

		takeEvery(actions.GET_DASHBOARD_PARTITION_STATS, GET_DASHBOARD_PARTITION_STATS),
		takeEvery(actions.GET_DASHBOARD_PARTITION_ACTIVE_CALLS, GET_DASHBOARD_PARTITION_ACTIVE_CALLS),

		takeEvery(actions.DOWNLOAD_CUSTOMER_REPORT, DOWNLOAD_CUSTOMER_REPORT),
		takeEvery(actions.DOWNLOAD_SUPPLIER_REPORT, DOWNLOAD_SUPPLIER_REPORT),
		takeEvery(actions.DOWNLOAD_SALE_AND_COST_REPORT, DOWNLOAD_SALE_AND_COST_REPORT),
		takeEvery(actions.DOWNLOAD_CDR_REPORT, DOWNLOAD_CDR_REPORT),

		takeLatest(actions.GET_ADVANCED_REPORT, GET_ADVANCED_REPORT),

		takeEvery(actions.GET_REPORT_TEMPLATES, GET_REPORT_TEMPLATES),
		takeEvery(actions.GET_REPORT_TEMPLATE, GET_REPORT_TEMPLATE),
		takeEvery(actions.NEW_REPORT_TEMPLATE, NEW_REPORT_TEMPLATE),
		takeEvery(actions.UPDATE_REPORT_TEMPLATE, UPDATE_REPORT_TEMPLATE),
		takeEvery(actions.DELETE_REPORT_TEMPLATE, DELETE_REPORT_TEMPLATE),
		takeEvery(actions.GET_EXTERNAL_INVOICES, GET_EXTERNAL_INVOICES),
		takeEvery(actions.GET_MONTHLY_INVOICES, GET_MONTHLY_INVOICES),
		takeEvery(actions.GET_INVOICES, GET_INVOICES),
		takeEvery(actions.DOWNLOAD_INVOICE, DOWNLOAD_INVOICE),
		takeEvery(actions.PAY_INVOICE, PAY_INVOICE)
	]);
}
