import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

const RenderFormikSwitch = ({ formik: { values, errors, touched, setFieldValue, handleBlur }, name, label, ...rest }) => {
	let hasError = (errors[name] && touched[name] && true) || false;
	let errorMsg = (hasError && !!errors[name] && errors[name]) || '';

	let value = values[name] || '';
	if (name.indexOf('.') > -1) {
		value = name.split('.').reduce((o, i) => (o && o[i]) || '', values);
		const error = name.split('.').reduce((o, i) => (o && o[i]) || '', errors);
		const touchedEl = name.split('.').reduce((o, i) => (o && o[i]) || '', touched);
		hasError = (error && touchedEl && true) || false;
		errorMsg = (hasError && !!error && error) || '';
	}
	return (
		<FormControl error={!!hasError}>
			<FormControlLabel
				control={
					<Switch
						{...rest}
						value={value}
						checked={!!value}
						name={name}
						onChange={e => {
							setFieldValue(name, e.target.checked);
							if (rest.onChange) {
								rest.onChange(e);
							}
						}}
						onBlur={handleBlur}
						color="primary"
					/>
				}
				label={label}

			/>
			{hasError && <FormHelperText>{errorMsg}</FormHelperText>}
		</FormControl>
	);
};

RenderFormikSwitch.defaultProps = {};

RenderFormikSwitch.propTypes = {
	formik: PropTypes.object.isRequired,
	name: PropTypes.string.isRequired
};

export default RenderFormikSwitch;
