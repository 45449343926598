import React from "react";
import PropTypes from "prop-types";
import { AppBar, Tab, Tabs, Tooltip } from "@material-ui/core";

const MyAccountOptions = props => {
  const rol_group_1 = [ROLES.FUNDER, ROLES.CARRIER];
  const rol_group_2 = [ROLES.CARRIER];

  const {
    profileComplete,
    currentRol,
    callbackFromParent,
    selectedMenuItem
  } = props;

  return (
    <AppBar position="static">
      <Tabs value={selectedMenuItem} onChange={callbackFromParent}>
        {currentRol && currentRol.some(r => rol_group_1.indexOf(r) >= 0) ? (
          <Tab value="gi" label="General Information" />
        ) : (
          ""
        )}
        <Tab value="ua" label="User Administration" className="inactive-tab" />
        <Tab value="sec" label="Security" />
        <Tab value="contacts" label="Contacts" className="inactive-tab" />
        <Tab value="agreements" label="Legal Agreements" />
        <Tab value="lenderSettings" label="Lender Settings" />
      </Tabs>
    </AppBar>
  );
};

MyAccountOptions.defaultProps = {
  profileComplete: true
};

MyAccountOptions.propTypes = {
  profileComplete: PropTypes.bool,
  callbackFromParent: PropTypes.func.isRequired,
  selectedMenuItem: PropTypes.string.isRequired
};

export default MyAccountOptions;
