const radioStyle = theme => ({
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    display: "inline-flex",
    transition: "0.3s ease all"
  },
  radio: {
    color: theme.palette.primary.main
  },
  radioChecked: {
    width: `${theme.spacing(1) * 2}px`,
    height: `${theme.spacing(1) * 2}px`,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "50%"
  },
  radioUnchecked: {
    width: "0px",
    height: "0px",
    padding: `${theme.spacing(1)}px`,
    border: `1px solid ${theme.palette.grey.main}`,
    borderRadius: "50%"
  }
});

export default radioStyle;
