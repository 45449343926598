import React, { Component } from "react";
import { CreateOrderForm } from "components";
import { createOrder, order } from "store/actions";
import { reduxForm, reset } from "redux-form";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fromBiddingportal } from "store/selectors";
import {
    createValidator,
    required,
    isDecimal
} from 'services/validation';

class CreateOrderFormContainer extends Component {
  constructor(props) {
    super(props);
    this.createOpenOrder = this.createOpenOrder.bind(this);
    this.hideOpenOrderContainer = this.hideOpenOrderContainer.bind(this);
  }

  componentDidMount() {}

  createOpenOrder(data) {
    let { dispatch } = this.props;
    dispatch(reset("createOrderForm"));
    this.props.hideOpenOrder();
    this.props.handleSubmit(data);
  }

  hideOpenOrderContainer(data) {
    let { dispatch } = this.props;
    dispatch(reset("createOrderForm"));
    this.props.hideOpenOrder();
  }

  render() {
    return (
      <CreateOrderForm
        {...this.props}
        handleSubmit={this.createOpenOrder}
        hideOpenOrder={this.hideOpenOrderContainer}
      />
    );
  }
}

const mapStateToProps = state => ({
  initialValues: fromBiddingportal.initialCreateOrder(state),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      order,
    },
    dispatch,
  );
}

const onSubmit = (data, dispatch) => {
  dispatch(createOrder(data));
};

const validate = createValidator({
  interestRate: [isDecimal],
  dailyAmount: [isDecimal],
  minimumCreditRating: [required]
});

CreateOrderFormContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  hideOpenOrder: PropTypes.func.isRequired,
};

const reduxFormCreateOrderFormContainer = reduxForm({
  form: "createOrderForm",
  enableReinitialize: true,
  destroyOnUnmount: true,
  onSubmit,
  validate,
  touchOnBlur: false
})(CreateOrderFormContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxFormCreateOrderFormContainer);
