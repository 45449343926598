import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

const RenderFieldDatePicker = ({
  input,
  placeholder,
  defaultValue,
  meta: { touched, error },
  ...custom
}) => (
  <div className="input-group">
    <span className="input-group-addon">
      <i className="fa fa-calendar" />
    </span>
    <DatePicker
      dateForm="MM/DD/YYYY"
      placeholderText="Start Date"
      minDate={custom.minDate ? custom.minDate : ""}
      onChange={date => input.onChange(moment(date))}
      selected={input.value ? moment(input.value) : moment()}
    />

    {touched && error && <span>{error}</span>}
  </div>
);
export default RenderFieldDatePicker;
