import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import React, { Component } from 'react';

import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import SettingsIcon from '@material-ui/icons/Settings';
import { TechnicalInformationUpdateForm } from 'components';
import { withStyles } from '@material-ui/core/styles';
import techProfileListStyles from './style';

class TechnicalInformationList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
			selectedExternalProfileId: null
		};
	}

	getAccordionDetails(displayId, hardwareProfileOptionsData, hardwareProfile, hardwareProfiles, assignedToSmartContract) {
		const { selectedExternalProfileId } = this.state;
		if (displayId) {
			if (displayId !== selectedExternalProfileId) {
				return '';
			}
			const {
				updateTechnicalInfoSubmit,
				deleteTechnicalInfoSubmit,
				loadVendorRates,
				loadExternalRates,
				vendorRatesData,
				externalRatesData,
				uploadRates,
				uploadExternalRates,
				ratePlanUploadData,
				externalRatePlanUploadData,
				countries,
				statesDataArray,
				getStatesData,
				externalClientData,
				externalInvoiceData,
				loadExternalInvoicePreviewData,
				createExternalInvoice
			} = this.props;
			const hardwareProfileData = {
				...hardwareProfile,
				externalClient: {
					...externalClientData[hardwareProfile.tempId]
				}
			};
			return (
				<TechnicalInformationUpdateForm
					onSubmit={updateTechnicalInfoSubmit}
					onDelete={deleteTechnicalInfoSubmit}
					cancel={this.handleChange()}
					hardwareProfileOptionsData={hardwareProfileOptionsData}
					initialValues={hardwareProfileData}
					form={`TechnicalInformationFormUpdate${selectedExternalProfileId}`}
					hardwareProfileId={selectedExternalProfileId}
					hardwareProfile={hardwareProfileData}
					assignedToSmartContract={assignedToSmartContract}
					hardwareProfiles={hardwareProfiles}
					loadVendorRates={loadVendorRates}
					loadExternalRates={loadExternalRates}
					vendorRatesData={vendorRatesData}
					externalRatesData={externalRatesData}
					uploadRates={uploadRates}
					uploadExternalRates={uploadExternalRates}
					ratePlanUploadData={ratePlanUploadData}
					externalRatePlanUploadData={externalRatePlanUploadData}
					countries={countries}
					statesDataArray={statesDataArray}
					getStatesData={getStatesData}
					externalInvoiceData={externalInvoiceData}
					loadExternalInvoicePreviewData={loadExternalInvoicePreviewData}
					createExternalInvoice={createExternalInvoice}
				/>
			);
		}
		return '';
	}

	handleChange = externalProfileId => (event, expanded) => {
		const panel = `hardwareProfile${externalProfileId}`;
		// load expansion panel details content

		this.setState({
			expanded: expanded ? panel : false,
			selectedExternalProfileId: expanded ? externalProfileId : null
		});
	};

	render() {
		const { classes, hardwareProfiles, hardwareProfileOptionsData } = this.props;
		const { expanded } = this.state;

		const hardwareProfilePanels = Object.values(hardwareProfiles).map((hardwareProfile, i) => {
			const { name, ipAddresses } = hardwareProfile;
			const primaryIpAddress = ipAddresses && ipAddresses.length ? `${ipAddresses[0].ip}/${ipAddresses[0].netmask}:${ipAddresses[0].port}` : '';
			const voipProtocolObject =
				hardwareProfileOptionsData && hardwareProfileOptionsData.voipProtocols
					? hardwareProfileOptionsData.voipProtocols.find(obj => obj.voipProtocolId === hardwareProfile.voipProtocolId)
					: false;
			const voipProtocolName = voipProtocolObject ? voipProtocolObject.voipProtocolName : '';

			const secondaryText =
				hardwareProfile.type === 'egress'
					? `Tech Prefix: ${hardwareProfile.techPrefix} | Primary IP Address: ${primaryIpAddress} `
					: `Primary IP Address: ${primaryIpAddress} `;

			const assignedToSmartContract = hardwareProfile && hardwareProfile.smartContracts ? hardwareProfile.smartContracts.length > 0 : false;
			const profileIconClass = assignedToSmartContract ? classes.activeProfileIcon : classes.profileIcon;

			return (
				<Accordion expanded={expanded === `hardwareProfile${hardwareProfile._id}`} onChange={this.handleChange(hardwareProfile._id)} key={hardwareProfile._id}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<SettingsIcon className={profileIconClass} />
						<div>
							<Typography className={classes.heading}>{name}</Typography>
							<Typography className={classes.secondaryHeading}>{secondaryText}</Typography>
						</div>
					</AccordionSummary>
					<AccordionDetails>
						{this.getAccordionDetails(hardwareProfile._id, hardwareProfileOptionsData, hardwareProfile, hardwareProfiles, assignedToSmartContract)}
					</AccordionDetails>
					<Divider />
				</Accordion>
			);
		});
		return <div>{hardwareProfilePanels}</div>;
	}
}
TechnicalInformationList.defaultProps = {
	createExternalInvoice: () => {}
};

TechnicalInformationList.propTypes = {
	classes: PropTypes.shape().isRequired,
	hardwareProfiles: PropTypes.PropTypes.arrayOf(PropTypes.object).isRequired,
	updateTechnicalInfoSubmit: PropTypes.func.isRequired,
	deleteTechnicalInfoSubmit: PropTypes.func.isRequired,
	createExternalInvoice: PropTypes.func
};

export default withStyles(techProfileListStyles)(TechnicalInformationList);
