import { put, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fromUrlredirect, fromUseraccount } from "store/selectors";
import { cleanUrlRedirect } from "../actions";

export function* redirectPostLogin(action) {
  const urlRedirect = yield select(fromUrlredirect.getUrlRedirectState);
  const userData = yield select(fromUseraccount.getUserInfo);
  if (urlRedirect.url != null && userData.userId!=null && urlRedirect.userId === userData.userId) {
    yield put(push(urlRedirect.url));
    yield put(cleanUrlRedirect());
  }
}
