import React from "react";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";

class RenderMaterialSelect extends React.Component {
  constructor(props) {
    super(props);
    const { multiple, input } = this.props;

    const initialValue = multiple ? [] : "";
    const inputHasValue = typeof input.value !== "undefined";
    const convertValue = value => {
      const isArray = Array.isArray(value);
      if (!multiple) {
        return isArray ? value.join(",") : value;
      }
      return isArray ? value : value.split(",");
    };
    this.state = {
      value: inputHasValue ? convertValue(input.value) : initialValue
    };
  }

  handleChange = event => {
    this.setState({ value: event.target.value });
  };

  render() {
    const {
      input,
      label,
      multiple,
      children,
      className,
      readOnly,
      meta: { touched, error }
    } = this.props;
    const hasError =
      typeof error !== typeof undefined && error.length > 0 && touched;
    const isMultiple = typeof multiple !== typeof undefined && multiple;

    if (readOnly !== undefined && readOnly) {
      input.readOnly = true;
    }
    if (isMultiple) {
      return (
        <FormControl error={hasError} fullWidth>
          <InputLabel>{label}</InputLabel>
          <Select
            {...input}
            multiple
            value={this.state.value}
            onChange={this.handleChange}
            renderValue={selected => (
              <div>
                {selected.map(value => {
                  const item = children
                    ? children.find(
                        o => o.props.value.toString() === value.toString()
                      )
                    : undefined;
                  if (typeof item !== typeof undefined) {
                    return (
                      <Chip
                        key={value}
                        label={item.props.label}
                        style={{ margin: 2 }}
                      />
                    );
                  }
                })}
              </div>
            )}
          >
            {children}
          </Select>
          {hasError && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      );
    }
    return (
      <FormControl error={hasError} fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          {...input}
          fullWidth
          className={className + (hasError ? " is-invalid" : "")}
          onChange={this.handleChange}
          value={this.state.value}
          renderValue={selected => {
            const item = children
              ? children.find(
                  o => o.props.value.toString() === selected.toString()
                )
              : undefined;
            if (typeof item !== typeof undefined) {
              return <Chip label={item.props.label} style={{ margin: 2 }} />;
            }
          }}
        >
          {children}
        </Select>
        {hasError && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
}
export default RenderMaterialSelect;
