import { API } from 'aws-amplify';

const BiddingPortalApi = {
  initializeBiddingPortal(data) {
    return API.get('BIDDING_API', '/getIndex')
      .then(response => response)
      .catch(err => err);
  },
  createOrder(data) {
    let init = {
      body: {
        smartContractBiddingPortal: {
          dailyAllocatedAmount: data.dailyAmount,
          paymentTerms: data.paymentTerms,
          paymentTermsNet: data.paymentTermsNet,
          interestRate: data.interestRate,
          minimumCreditRating: data.minimumCreditRating,
        },
      },
    };
    return API.post('BIDDING_API', '/place-order', init)
      .then(response => response)
      .catch(err => err);
  },
  cancelOrder(data) {
    let init = {
      body: {
        smartContractBiddingPortal: {
          id: data,
        },
      },
    };
    return API.post('BIDDING_API', '/cancel', init)
      .then(response => response)
      .catch(err => err);
  },
  acceptOrder(data) {
    let init = {
      body: {
        smartContractBiddingPortal: {
          id: data,
        },
      },
    };
    return API.post('BIDDING_API', '/acceptOrder', init)
      .then(response => response)
      .catch(err => err);
  },
  order(data) {
    let init = {
      body: {
        smartContractBiddingPortal: {
          id: data,
        },
      },
    };
    return API.post('BIDDING_API', '/order', init)
      .then(response => response)
      .catch(err => err);
  },
};

export default BiddingPortalApi;
