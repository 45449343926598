import React, { Component } from "react";
import { Field } from "redux-form";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  MenuItem,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide
} from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import {
  SmartContractDialCodesModal,
  RenderSelect,
  RenderInput,
  RenderButton
} from "components";

const styles = theme => ({
  noWrap: {
    whiteSpace: "nowrap",
    minWidth: "150px"
  }
});

const Transition = props => <Slide direction="up" {...props} />;

class SmartContractDestinationCodeRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCountry: "",
      selectedDestinationType: ""
    };
  }

  selectCountry = event => {
    const { loadDestinationTypeCarrier, codeRowIndex } = this.props;

    this.setState({ selectedCountry: event.value });
    loadDestinationTypeCarrier(event.value, codeRowIndex);
  };

  selectDestinationType = event => {
    const { loadDestinationsCarrier, codeRowIndex } = this.props;
    const { selectedCountry } = this.state;

    this.setState({ selectedDestinationType: event.value });
    loadDestinationsCarrier(event.value, selectedCountry, codeRowIndex);
  };

  selectDestinationCarrier = event => {
    const { loadDialCodes, codeRowIndex } = this.props;
    const { selectedCountry, selectedDestinationType } = this.state;

    loadDialCodes(
      event.value,
      selectedCountry,
      selectedDestinationType,
      codeRowIndex
    );
  };

  render() {
    const {
      validateSelectCountry,
      toggleDialCodes,
      modalDialCodes,
      catalogsNewSmartContract,
      dataNewSmartContract,
      smartContract,
      fields,
      codeRowIndex,
      dialCodesSelected,
      destinationsSelected,
      destinationsCarrierSelected,
      nameBase,
      codeRowIndexSelected,
      disabled,
      classes
    } = this.props;

    destinationsSelected[codeRowIndex] =
      destinationsSelected[codeRowIndex] === undefined
        ? []
        : destinationsSelected[codeRowIndex];
    destinationsCarrierSelected[codeRowIndex] =
      destinationsCarrierSelected[codeRowIndex] === undefined
        ? []
        : destinationsCarrierSelected[codeRowIndex];

    const sortedCountries = catalogsNewSmartContract.countries.sort((a, b) =>
      a.countryName.toLowerCase().localeCompare(b.countryName.toLowerCase())
    );

    const actionsEnabled =
      (!smartContract ||
        !smartContract.status ||
        smartContract.status === "draft") &&
      !disabled;

    return (
      <tr key={codeRowIndex}>
        <td className={classes.noWrap}>
          <Field
            id={`${nameBase}.countryId`}
            name={`${nameBase}.countryId`}
            label="Country"
            disabled={!actionsEnabled}
            callback={this.selectCountry}
            component={RenderSelect}
            options={sortedCountries.map(option => ({
              value: option.countryId,
              label: option.countryName
            }))}
          />
        </td>
        <td className={classes.noWrap}>
          <Field
            id={`${nameBase}.destinationTypeId`}
            name={`${nameBase}.destinationTypeId`}
            disabled={!actionsEnabled}
            label="Destination type"
            callback={this.selectDestinationType}
            component={RenderSelect}
            options={destinationsSelected[codeRowIndex].map(option => ({
              value: option.destinationId,
              label: option.destinationName
            }))}
          />
        </td>
        <td className={classes.noWrap}>
          <Field
            id={`${nameBase}.destinationCarrierId`}
            name={`${nameBase}.destinationCarrierId`}
            label="Destination carrier"
            disabled={!actionsEnabled}
            callback={this.selectDestinationCarrier}
            component={RenderSelect}
            options={destinationsCarrierSelected[codeRowIndex].map(option => ({
              value: option,
              label: option
            }))}
          />
        </td>
        {/* <td className={classes.noWrap}>
          <Field
            component={RenderSelect}
            id={`${nameBase}.offerTypeId`}
            name={`${nameBase}.offerTypeId`}
            disabled={!actionsEnabled}
            label="Type"
          >
            {dataNewSmartContract.offerTypes.map(offerType => (
              <MenuItem
                value={offerType.offerTypeId}
                key={offerType.offerTypeId}
              >
                {offerType.offerTypeName}
              </MenuItem>
            ))}
          </Field>
        </td> */}
        <td className={classes.noWrap}>
          <Field
            id={`${nameBase}.billingIncrementId`}
            name={`${nameBase}.billingIncrementId`}
            label="Billing increment"
            disabled={!actionsEnabled}
            component={RenderSelect}
            options={dataNewSmartContract.billingIncrements.map(option => ({
              value: option.billingIncrementId,
              label: option.billingIncrement
            }))}
          />
        </td>
        <td className={classes.noWrap}>
          <Field
            type="hidden"
            name={`${nameBase}.smartContractOfferCodes`}
            component="input"
          />
          <TextField
            fullWidth
            margin="dense"
            name={`${nameBase}.dialCodeShow`}
            disabled={!actionsEnabled}
            label="Dial codes"
            value={
              dialCodesSelected[codeRowIndex] !== undefined
                ? `${
                    dialCodesSelected[codeRowIndex].filter(
                      codeDial => codeDial.selected !== false
                    ).length
                  } selected`
                : ""
            }
            onClick={() =>
              validateSelectCountry(`${nameBase}.countryId`, codeRowIndex)
            }
          />

          <Dialog
            maxWidth="md"
            fullWidth
            open={modalDialCodes}
            onBackdropClick={toggleDialCodes}
            TransitionComponent={Transition}
          >
            <DialogTitle>Select Dial Codes</DialogTitle>
            <DialogContent>
              <SmartContractDialCodesModal
                dialsCodes={
                  dialCodesSelected[codeRowIndexSelected] !== undefined
                    ? dialCodesSelected[codeRowIndexSelected]
                    : null
                }
                {...this.props}
                codeRowIndex={codeRowIndexSelected}
              />
            </DialogContent>
            <DialogActions>
              <RenderButton color="danger" onClick={toggleDialCodes}>
                Close
              </RenderButton>
            </DialogActions>
          </Dialog>
        </td>
        <td className={classes.noWrap}>
          <Field
            component={RenderInput}
            disabled={!actionsEnabled}
            type="number"
            step="any"
            min="0"
            name={`${nameBase}.offerPrice`}
            label="Price"
          />
        </td>
        <td className={classes.noWrap}>
          {actionsEnabled ? (
            <div>
              <RenderButton
                color="danger"
                onClick={() => fields.remove(codeRowIndex)}
                disabled={fields.length === 1}
              >
                Delete
              </RenderButton>
            </div>
          ) : (
            ""
          )}
        </td>
      </tr>
    );
  }
}

SmartContractDestinationCodeRow.propTypes = {
  loadDestinationTypeCarrier: PropTypes.func.isRequired,
  loadDestinationsCarrier: PropTypes.func.isRequired,
  loadDialCodes: PropTypes.func.isRequired,
  validateSelectCountry: PropTypes.func.isRequired,
  toggleDialCodes: PropTypes.func.isRequired,
  selectCodes: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  error: PropTypes.any,
  styleCountrySel: PropTypes.string.isRequired,
  valCountry: PropTypes.string,
  varDesType: PropTypes.string,
  modalDialCodes: PropTypes.bool.isRequired,
  dialCodes: PropTypes.array,
  fields: PropTypes.shape().isRequired,
  codeRowIndex: PropTypes.number,
  // dialCodesSelected: PropTypes.array,
  // destinationsSelected: PropTypes.array,
  // destinationsCarrierSelected: PropTypes.array,
  catalogsNewSmartContract: PropTypes.shape({
    countries: PropTypes.array.isRequired
  }).isRequired,
  dataNewSmartContract: PropTypes.shape({
    destinations: PropTypes.array.isRequired,
    billingIncrements: PropTypes.array.isRequired,
    offerTypes: PropTypes.array.isRequired
  }).isRequired
};

SmartContractDestinationCodeRow.defaultProps = {
  dialCodes: [],
  varDesType: "",
  valCountry: undefined,
  dialCodesSelected: [],
  destinationsSelected: [],
  destinationsCarrierSelected: []
};
export default withStyles(styles)(SmartContractDestinationCodeRow);
