import React, { Component } from 'react';
import { InfoTextContainer } from 'containers';
import { TableInterconnections } from 'components';
import { Grid, Paper, CardContent, Typography, AppBar, Tabs, Tab } from '@material-ui/core';
import { AuthManager } from '../../index';
import { withTranslation } from 'react-i18next';

class Interconnections extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tabIndex: 0
		};
	}

	handleChange = (event, tabIndex) => {
		this.setState({ tabIndex });
	};

	render() {
		const { interconnections, acceptInterconnections, agreementSign, agreementDownload, agreementCounterSign, interconnectionForm, user, t } = this.props;
		const { tabIndex } = this.state;
		return (
			<div>
				{/* <AppBar position="static">
          <Tabs value={tabIndex} onChange={this.handleChange}>
            <Tab label="Partners" />
          </Tabs>
        </AppBar>
        <br /> */}
				{/* {tabIndex === 0 && ( */}
				<Grid container spacing={1}>
					<Grid item xs={12} md={8}>
						<Grid container spacing={1}>
							<AuthManager permissions={['tradingPartners.invite-partners']}>
								<Grid item xs={12}>
									<Paper>
										<CardContent>{interconnectionForm}</CardContent>
									</Paper>
								</Grid>
							</AuthManager>
							<AuthManager permissions={['tradingPartners.view-partners', 'tradingPartners.accept-partners']}>
								<Grid item xs={12}>
									<TableInterconnections
										data={interconnections}
										acceptInterconnections={acceptInterconnections}
										agreementSign={agreementSign}
										agreementDownload={agreementDownload}
										agreementCounterSign={agreementCounterSign}
										user={user}
									/>
								</Grid>
							</AuthManager>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4}>
						{(t('info_text', { defaultValue: [] }) || []).map(p => (
							// eslint-disable-next-line react/no-danger
							<div dangerouslySetInnerHTML={{ __html: p }} />
						))}
					</Grid>
				</Grid>
				{/* )} */}
			</div>
		);
	}
}

export default withTranslation(['tradingpartners/interconnections'])(Interconnections);
