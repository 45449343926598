import { call, put } from "redux-saga/effects";
import InfoTextApi from "services/api/InfoTextApi";
import { actionGetInfoTextPostDataResponse } from "../actions";

export function* sagaInfoTextPostRequest(action) {
  try {
    const result = yield call(InfoTextApi.getInfoText, action.payload);
    yield put(actionGetInfoTextPostDataResponse(result));
  } catch (err) {
    const error = err.message || err;
    console.error(error);
  }
}
