import { Check } from '@material-ui/icons';
import RenderButton from 'components/atoms/RenderButton';
import React from 'react';
import { handleCurrency } from 'utils';
import moment from 'moment';
import { Chip } from '@material-ui/core';

export const columns = ({ setSelectedClaim, loading }) => [
	{
		Header: 'Claim ID',
		width: 130,
		Cell: ({ original: { claimNumber } }) => <p>{claimNumber}</p>
	},
	{
		Header: 'Approval Date',
		Cell: ({ original: { dueDate } }) => <p>{moment(dueDate).format('MMM Do YY')}</p>
	},
	{
		Header: () => <div className="centered-cell">Quantity</div>,
		Cell: ({
			original: {
				summary: { quantity }
			}
		}) => <div className="centered-cell">{quantity}</div>
	},

	{
		Header: () => <div className="centered-cell">Escrow</div>,
		width: 110,
		Cell: ({
			original: {
				summary: { value }
			}
		}) => <div className="centered-cell">{handleCurrency(value, 'USD')}</div>
	},
	{
		Header: () => <div className="centered-cell">Status</div>,
		width: 130,
		Cell: ({ original: { settled } }) => <div className="centered-cell">{settled ? <Chip color="primary" label="Settled" /> : <Chip label="Pending" />}</div>
	},
	{
		Header: () => <div className="centered-cell">Actions</div>,
		width: 110,
		Cell: ({ original }) => (
			<div className="centered-cell">
				<RenderButton color="primary" startIcon={<Check />} loading={loading.orders} onClick={() => setSelectedClaim(original)}>
					Select
				</RenderButton>
			</div>
		)
	}
];
