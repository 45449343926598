import { API, Auth } from 'aws-amplify';

import axios from 'axios';

const axiosAuthHeader = () =>
	Auth.currentAuthenticatedUser()
		.then(user => {
			const headers = {
				AuthorizationInfo: user.signInUserSession.idToken.jwtToken
			};

			const impersonateCode = window.localStorage.getItem('IMPERSONATE_CODE');
			if (impersonateCode) {
				headers.impersonateCode = impersonateCode;
			}

			return headers;
		})
		.catch(err => console.log(err));

const myAccountApiService = {
	registerUser(data) {
		return API.post('ACCOUNT_SERVICE_API', `/register`, { body: data })
			.then(response => response)
			.catch(err => err);
	},
	inviteNewUser(data) {
		const payload = {
			body: data
		};
		return API.post('NODE_SERVICE_API', '/user/invite', payload)
			.then(response => response)
			.catch(err => err);
	},
	getQrCode(data) {
		const payload = {
			body: data
		};
		return API.post('NODE_SERVICE_API', '/user/mfa/qr-code', payload)
			.then(response => response)
			.catch(err => err);
	},
	verifyMfaCode(data) {
		const payload = {
			body: data
		};
		return API.post('NODE_SERVICE_API', '/user/mfa/', payload)
			.then(response => response)
			.catch(err => err);
	},
	updatePasswordchallenge(data) {
		const payload = {
			body: data
		};
		return API.post('ACCOUNT_SERVICE_API', '/authChallenge', payload)
			.then(response => response)
			.catch(err => err);
	},
	getIndex() {
		return API.get('NODE_SERVICE_API', '/account')
			.then(response => response)
			.catch(err => {
				console.error(err);
				return false;
			});
	},
	postSignFinancingAgreement() {
		return API.post('NODE_SERVICE_API', '/account/sign/financing', {})
			.then(response => response)
			.catch(err => err);
	},
	getSignFinancingAgreementStatus() {
		return API.get('NODE_SERVICE_API', '/account/sign/financing/status')
			.then(response => response)
			.catch(err => err);
	},
	postSignPortalAgreement() {
		return API.post('NODE_SERVICE_API', '/account/sign/portal', {})
			.then(response => response)
			.catch(err => err);
	},
	getSignPortalAgreementStatus() {
		return API.get('NODE_SERVICE_API', '/account/sign/portal/status')
			.then(response => response)
			.catch(err => err);
	},
	getPortalAgreementPdf() {
		return API.get('NODE_SERVICE_API', '/account/sign/portal/download')
			.then(response => response)
			.catch(err => err);
	},
	postMyAccountData(data) {
		const myAccountData = {
			body: data
		};
		return API.post('NODE_SERVICE_API', '/account', myAccountData)
			.then(response => response)
			.catch(err => err);
	},
	uploadLogo(logo) {
		const fd = new FormData();
		fd.append('logo', logo, logo.name);

		return Auth.currentSession().then(async session =>
			axios
				.post(`${await API.endpoint('NODE_SERVICE_API')}/account/logo`, fd, {
					headers: {
						AuthorizationInfo: session.idToken.jwtToken,
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(response => response)
				.catch(err => err)
		);
	},
	postBankAccountData(data) {
		return Auth.currentSession().then(async session =>
			axios
				.post(`${await API.endpoint('NODE_SERVICE_API')}/account/bank-account`, data.bankAccount, {
					headers: {
						token: session.idToken.jwtToken,
						'Content-Type': 'application/json'
					}
				})
				.then(response => response.data)
				.catch(err => err)
		);
	},
	putBankAccountData(data) {
		return Auth.currentSession().then(async session =>
			axios
				.put(`${await API.endpoint('NODE_SERVICE_API')}/account/bank-account`, data.bankAccount, {
					headers: {
						token: session.idToken.jwtToken,
						'Content-Type': 'application/json'
					}
				})
				.then(response => response.data)
				.catch(err => err)
		);
	},
	postRemoveBankAccountData(hardwareProfileId) {
		return API.del('MY_ACCOUNT_API', `/myAccount/removeBankAccount/${hardwareProfileId}`)
			.then(response => response)
			.catch(err => err);
	},
	postNewHardwareData(data) {
		// return API.post(
		//   "MY_ACCOUNT_API",
		//   "/myAccount/storeHardwareProfile",
		//   newHardwareData
		// )
		//   .then(response => response)
		//   .catch(err => err);

		return Auth.currentSession().then(async session =>
			axios
				.post(`${await API.endpoint('NODE_SERVICE_API')}/account/hardware-profile`, data, {
					headers: {
						AuthorizationInfo: session.idToken.jwtToken,
						'Content-Type': 'application/json'
					}
				})
				.then(response => response)
				.catch(err => err)
		);
	},
	postTechnicalDetailsData(data) {
		const newTechnicalDetailsData = {
			body: data
		};
		return API.post('MY_ACCOUNT_API', '/myAccount/storeHardwareProfile', newTechnicalDetailsData)
			.then(response => response)
			.catch(err => err);
	},
	updateHardwareProfile(data) {
		// const requestBody = {
		//   body: data.hardwareProfileData
		// };
		// return API.post(
		//   "MY_ACCOUNT_API",
		//   "/myAccount/updateHardwareProfile",
		//   requestBody
		// )
		//   .then(response => response)
		//   .catch(err => err);

		return Auth.currentSession().then(async session =>
			axios
				.put(`${await API.endpoint('NODE_SERVICE_API')}/account/hardware-profile`, data.hardwareProfileData, {
					headers: {
						AuthorizationInfo: session.idToken.jwtToken,
						'Content-Type': 'application/json'
					}
				})
				.then(response => response)
				.catch(err => err)
		);
	},
	updateHardwareProfileSBC(data) {
		const requestBody = {
			body: data.hardwareProfileData
		};
		return API.post('MY_ACCOUNT_API', '/myAccount/updateHardwareProfile', requestBody)
			.then(response => response)
			.catch(err => err);
	},
	removeHardwareProfile(hardwareProfileId) {
		return API.get('MY_ACCOUNT_API', `/myAccount/deleteHardwareProfile/${hardwareProfileId}`)
			.then(response => response)
			.catch(err => err);
	},
	getCodecs() {
		return API.post('MY_ACCOUNT_API', '/myAccount/getCodecs', {})
			.then(response => response)
			.catch(err => err);
	},
	getHardwareProfileOptions() {
		return API.post('MY_ACCOUNT_API', '/myAccount/getHardwareProfileOptions', {})
			.then(response => response)
			.catch(err => err);
	},

	getCreateNewWallet() {
		return API.post('MY_ACCOUNT_API', '/myAccount/createWallet', {})
			.then(response => response)
			.catch(err => err);
	},
	postPhonePrefix(data) {
		return Auth.currentSession().then(async session =>
			axios
				.post(`${await API.endpoint('NODE_SERVICE_API')}/account/country-codes`, data, {
					headers: {
						token: session.idToken.jwtToken,
						'Content-Type': 'application/json'
					}
				})
				.then(response => response.data)
				.catch(err => err)
		);
	},
	postChangePassword(data) {
		return API.post('MY_ACCOUNT_API_NODE', 'security/changePassword', {
			body: data
		})
			.then(response => response)
			.catch(err => err);
	},
	getLenderSettings() {
		return API.get('NODE_SERVICE_API', '/account/lender-settings', {}).catch(err => err.message);
	},
	postLenderSettings(data) {
		return API.post('NODE_SERVICE_API', '/account/lender-settings', {
			body: data
		});
	},
	postExternalClientDetails(data) {
		return API.post('NODE_SERVICE_API', '/account/external-client', {
			body: data
		});
	},
	getExternalClientDetails() {
		return API.post('NODE_SERVICE_API', '/account/external-client/findAll', {});
	},

	////////////
	// new api endpoint
	getCountries() {
		return API.get('ACCOUNT_SERVICE_API', '/misc/countries', {});
	},
	getStates(countryId) {
		return API.get('ACCOUNT_SERVICE_API', `/misc/countries/${countryId}/states`, {})
			.then(response => response)
			.catch(err => err);
	},
	getBusinessTypes() {
		return API.get('ACCOUNT_SERVICE_API', '/misc/business-types', {})
			.then(response => response)
			.catch(err => err);
	},
	getCurrencies() {
		return API.get('ACCOUNT_SERVICE_API', '/misc/currencies', {})
			.then(response => response)
			.catch(err => err);
	},
	getAccountDetails() {
		return API.get('ACCOUNT_SERVICE_API', '', {})
			.then(response => response)
			.catch(err => err);
	},
	async updateAccountDetails(data) {
		return axios
			.patch(`${await API.endpoint('ACCOUNT_SERVICE_API')}`, data, { headers: await axiosAuthHeader() })
			.then(response => response.data)
			.catch(err => {
				console.error(err);
				return err;
			});
	},
	getBankAccounts() {
		return API.get('ACCOUNT_SERVICE_API', '/bank-account', {})
			.then(response => response)
			.catch(err => err);
	},
	createNewBankAccount(data) {
		return API.post('ACCOUNT_SERVICE_API', '/bank-account', { body: data })
			.then(response => response)
			.catch(err => err);
	},
	updateBankAccount(data) {
		return API.patch('ACCOUNT_SERVICE_API', '/bank-account', { body: data })
			.then(response => response)
			.catch(err => err);
	},
	deleteBankAccount(data) {
		return API.del('ACCOUNT_SERVICE_API', '/bank-account', { body: { _id: data } })
			.then(response => response)
			.catch(err => err);
	},
	getUserPermissions() {
		return API.get('ACCOUNT_SERVICE_API', '/misc/permissions', {})
			.then(response => {
				// console.log('🚀 ~ file: MyAccountApi.js:319 ~ response:', response);
				return response;
			})
			.catch(err => err);
	},
	getUserSubAccounts() {
		return API.get('ACCOUNT_SERVICE_API', '/sub-accounts', {})
			.then(response => response)
			.catch(err => err);
	},
	createNewSubAccount(data) {
		return API.post('ACCOUNT_SERVICE_API', '/sub-accounts', { body: data })
			.then(response => response)
			.catch(err => err);
	},
	updateSubAccount(data) {
		return API.patch('ACCOUNT_SERVICE_API', '/sub-accounts', { body: data })
			.then(response => response)
			.catch(err => err);
	},
	deleteSubAccount(data) {
		return API.del('ACCOUNT_SERVICE_API', '/sub-accounts', { body: data })
			.then(response => response)
			.catch(err => err);
	},
	getUserMFAStatus() {
		return API.get('ACCOUNT_SERVICE_API', '/mfa', {})
			.then(response => response)
			.catch(err => err);
	},
	getUserMFAQRCode() {
		return API.get('ACCOUNT_SERVICE_API', '/mfa/qr-code', {})
			.then(response => response)
			.catch(err => err);
	},
	updateUserEnableMFA(data) {
		return API.post('ACCOUNT_SERVICE_API', '/mfa/qr-code', { body: data })
			.then(response => response)
			.catch(err => err);
	},
	updateUserDisableMFA(data) {
		return API.del('ACCOUNT_SERVICE_API', '/mfa/qr-code', { body: data })
			.then(response => response)
			.catch(err => err);
	},
	getPlatformAgreementStatus() {
		return API.get('ACCOUNT_SERVICE_API', '/agreements/portal-agreement', {})
			.then(response => response)
			.catch(err => err);
	},
	initiatePlatformAgreement() {
		return API.post('ACCOUNT_SERVICE_API', '/agreements/portal-agreement', {})
			.then(response => response)
			.catch(err => err);
	},
	getFinancingAgreementStatus() {
		return API.get('ACCOUNT_SERVICE_API', '/agreements/financing-agreement', {})
			.then(response => response)
			.catch(err => err);
	},
	initiateFinancingAgreement() {
		return API.post('ACCOUNT_SERVICE_API', '/agreements/financing-agreement', {})
			.then(response => response)
			.catch(err => err);
	},
	getAcpPlatformAgreementStatus() {
		return API.get('ACCOUNT_SERVICE_API', '/agreements/acp-portal-agreement', {})
			.then(response => response)
			.catch(err => err);
	},
	initiateAcpPlatformAgreement() {
		return API.post('ACCOUNT_SERVICE_API', '/agreements/acp-portal-agreement', {})
			.then(response => response)
			.catch(err => err);
	},
	getAcpFinancingAgreementStatus() {
		return API.get('ACCOUNT_SERVICE_API', '/agreements/acp-financing-agreement', {})
			.then(response => response)
			.catch(err => err);
	},
	initiateAcpFinancingAgreement() {
		return API.post('ACCOUNT_SERVICE_API', '/agreements/acp-financing-agreement', {})
			.then(response => response)
			.catch(err => err);
	},
	getContractAgreements() {
		return API.get('ACCOUNT_SERVICE_API', '/agreements/contract-agreements', {})
			.then(response => response)
			.catch(err => err);
	},
	getContractAgreementByGUID(guid) {
		return API.get('ACCOUNT_SERVICE_API', `/agreements/contract-agreements/${guid}`, {})
			.then(response => response)
			.catch(err => err);
	},
	getLenderSettingsData() {
		return API.get('ACCOUNT_SERVICE_API', `/lender-settings`, {})
			.then(response => response)
			.catch(err => err);
	},
	setLenderSettingsData(data) {
		return API.post('ACCOUNT_SERVICE_API', `/lender-settings`, { body: data })
			.then(response => response)
			.catch(err => err);
	},
	getLegalDocuments() {
		return API.get('ACCOUNT_SERVICE_API', '/legal/documents', {})
			.then(response => response)
			.catch(err => err);
	},
	getApiKeys() {
		return API.get('ACP_SERVICE_API', '/auth/tokens')
			.then(response => response)
			.catch(err => {
				throw err.response;
			});
	},
	async downloadDocument(docId) {
		return axios
			.get(`${await API.endpoint('ACCOUNT_SERVICE_API')}/legal/documents/download/${docId}`, { headers: await axiosAuthHeader() })
			.then(response => {
				const contentType = response.headers['content-type'] || 'application/octet-stream';

				const disposition = response.headers['content-disposition'];

				const filenameMatch = disposition && disposition.match(/filename="(.*)"/);

				const filename = filenameMatch ? filenameMatch[1] : 'document-downloaded';

				const link = document.createElement('a');

				link.href = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));

				link.download = filename;

				document.body.appendChild(link);
				link.click();

				document.body.removeChild(link);

				return response;
			})
			.catch(err => {
				console.log(err);
				return err;
			});
	},
	// TODO - move to admin service
	verifyImpersonateToken(code) {
		return API.get('ACCOUNT_SERVICE_API', `/impersonate/verify-token/${code}`, {})
			.then(response => response)
			.catch(err => err);
	},
	getUserIp() {
		return axios
			.get('https://api.ipify.org?format=json')
			.then(response => response.data)
			.catch(err => console.error(err));
	}
};

export default myAccountApiService;
