import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Typography } from '@material-ui/core';
import { capitalizeWord, colorStatusText, handleCurrency } from 'utils';
import { RenderButton, RenderTable } from 'components';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { dummySmartContracts } from 'screens/AcpSmartContracts/dummyData';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Add from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import { columns } from './columns';
import CreateSmartContractModal from './components/CreateSmartContractModal';
import { set } from 'date-fns';
import SmartContractDetailsModal from './components/SmartContractDetailsModal';
import Loader from 'components/atoms/Loader';
import { useAccordion } from './hooks/useAccordion';

const financeStatusText = {
	approved: 'Approved',
	required: 'Under Review',
	rejected: 'Rejected',
	'not-required': 'Not Required'
};

export default memo(() => {
	const { t } = useTranslation('routing/routing');
	const { loading } = useSelector(state => state.escrowmanaging || {});
	const {
		createModalOpened,
		setCreateModalOpened,
		detailsModalOpened,
		openedOrder,
		setOpenedOrder,
		openedSmartContract,
		setOpenedSmartContract,
		handleToggleDetailsModal,
		orders
	} = useAccordion();

	return (
		<>
			<CreateSmartContractModal modalOpened={createModalOpened} handleToggleModal={() => setCreateModalOpened(!createModalOpened)} openedOrder={openedOrder} />
			<SmartContractDetailsModal
				modalOpened={detailsModalOpened}
				handleToggleModal={handleToggleDetailsModal}
				openedSmartContract={openedSmartContract}
				itemPrice={openedOrder?.itemPrice}
				openedOrder={openedOrder}
			/>
			{loading.orders ? (
				<Loader />
			) : (
				<div className={styles.container}>
					{orders.length > 0 ? (
						orders.map(order => {
							const { status, orderName, itemPrice, orderQty, totalAssignedItems, orderTotalFulfilled, availableEscrow, contracts, finance, availableItems } =
								order || {};
							const assignedItemsPercentage = Math.round((totalAssignedItems / orderQty) * 100);
							const totalFulfilledItemsPercentage = Math.round((orderTotalFulfilled / totalAssignedItems) * 100);

							const unassignedItems = orderQty - totalAssignedItems;
							const unassignedItemsPercentage = 100 - assignedItemsPercentage;

							return (
								<Accordion key={order?._id} className="accordion">
									<AccordionSummary className="order-summary" expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
										<div className="status" style={colorStatusText(status, 'backgroundColor')}>
											{status?.toUpperCase()}
										</div>
										<Grid container spacing={2}>
											<Grid item xs={2} sm={3} md={4}>
												<div className="order-id">
													<Typography variant="caption" className="label">
														Order ID
													</Typography>
													<Typography>{order.orderName}</Typography>
												</div>
											</Grid>
											<Grid className="centered" item xs={2}>
												<Typography variant="caption" className="label">
													Quantity
												</Typography>
												<Typography>{orderQty}</Typography>
											</Grid>
											<Grid className="divider-container" item xs={2}>
												<span className="divider" />
												<div className="centered">
													<Typography variant="caption" className="label">
														Escrow balance
													</Typography>
													<Typography variant="h6">{handleCurrency(availableEscrow, 'USD')}</Typography>
												</div>
												<span className="divider" />
											</Grid>
											<Grid className="centered" item xs={2}>
												<Typography variant="caption" className="label">
													Assigned
												</Typography>
												<Typography className="large-text">{`${assignedItemsPercentage}%`}</Typography>
												<Typography variant="caption" className="label text-centered">
													{totalAssignedItems === 0
														? 'not items assigned yet'
														: `${totalAssignedItems} items (${totalFulfilledItemsPercentage}% fulfilled)`}
												</Typography>
											</Grid>
											<Grid className="divider-container" item xs={2}>
												<span className="divider" />
												<div className="centered">
													<Typography variant="caption" className="label">
														Unassigned
													</Typography>
													<Typography className="large-text">{`${unassignedItemsPercentage}%`}</Typography>
													<Typography variant="caption" className="label">
														{unassignedItems === 0 ? 'All items assigned' : `${unassignedItems} items`}
													</Typography>
												</div>
												<span className="divider" />
											</Grid>
										</Grid>
									</AccordionSummary>
									<AccordionDetails className="order-details">
										<Box className="header">
											<div className="field">
												<Typography variant="caption">Financing status:</Typography>
												<Typography variant="body1">{financeStatusText[order?.finance?.status]}</Typography>
											</div>

											{availableItems !== 0 && (
												<div className="accordion-details-buttons">
													<RenderButton
														className="button"
														color="primary"
														startIcon={<Add />}
														onClick={() => {
															setCreateModalOpened(true);
															setOpenedOrder(order);
														}}
													>
														Create smart contract
													</RenderButton>
												</div>
											)}
										</Box>

										<RenderTable
											className="space-top"
											data={order.contracts || []}
											columns={columns({ handleToggleModal: handleToggleDetailsModal, setOpenedSmartContract, setOpenedOrder, order })}
											showPagination={false}
											sortable={false}
											minRows={2}
											noDataText={
												<div>
													<strong>There are no smart contracts associated with this order yet</strong>
												</div>
											}
										/>
									</AccordionDetails>
								</Accordion>
							);
						})
					) : (
						<>
							<Divider orientation="horizontal" />
							<Box className="no-orders-container">
								<Typography variant="h6">No orders available</Typography>
							</Box>
						</>
					)}
				</div>
			)}
		</>
	);
});
