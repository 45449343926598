import React, { useEffect } from 'react';
import { Typography, Box, CardContent, Divider, CircularProgress, Collapse, Zoom } from '@material-ui/core';
import MessageBubble from './components/MessageBubble';
import { useDispatch, useSelector } from 'react-redux';

export const ThreadsSection = ({ selectedTicket }) => {
	const { TICKETS, THREADS } = useSelector(state => state.support);
	const dispatch = useDispatch();

	useEffect(() => {
		if (selectedTicket !== '') {
			dispatch({ type: 'support/GET_THREADS', payload: { selectedTicket } });
		}
	}, [selectedTicket]);
	return (
		<>
			<Typography variant="h6"> Messages: {selectedTicket !== '' ? 'Ticket ' + TICKETS.data.find(ticket => ticket.id === selectedTicket)?.ticketNumber : ''}</Typography>
			<Collapse in={THREADS.loading}>
				<Box style={{ marginTop: 8, marginButton: 8 }}>
					<CardContent style={{ display: 'flex', justifyContent: 'center' }}>
						<CircularProgress />
					</CardContent>
				</Box>
			</Collapse>
			{selectedTicket === '' ? (
				<Zoom in={true}>
					<Box style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
						<Typography>Please select a ticket.</Typography>
					</Box>
				</Zoom>
			) : THREADS.data.length === 0 ? (
				<Zoom in={true}>
					<Box style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
						<Typography>There is no messages for this ticket.</Typography>
					</Box>
				</Zoom>
			) : (
				THREADS.data.map((thread, index) => {
					return <MessageBubble key={`thread-${index}`} thread={thread} />;
				})
			)}
			<Divider />
			<Zoom in={selectedTicket !== '' && THREADS.data.length !== 0}>
				<Box style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
					<Typography variant="h6">Go to your email to responde to this message.</Typography>
				</Box>
			</Zoom>
		</>
	);
};
