import { call, put, select } from "redux-saga/effects";
import settingsApi from "services/api/settingsApi";
import {
  actionCreatorPreloaderOff,
  actionCreatorPreloaderOn
} from "../preloader/actions";
import { setPlatformSettings } from "./actions";

export function* getPlatformSettings(action) {
  try {
    yield put(actionCreatorPreloaderOn());
    const result = yield call(settingsApi.getPlatformSettings);
    yield put(setPlatformSettings(result));
    yield put(actionCreatorPreloaderOff());
  } catch (err) {
    yield put(actionCreatorPreloaderOff());
  }
}
