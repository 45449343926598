import { saveAs } from 'file-saver';
import { isArray } from 'lodash';
import { call, put, takeEvery, all } from 'redux-saga/effects';
import AcpProgramApi from 'services/api/AcpProgramApi';
import { triggerNotification } from 'store/notifications/actions';
import { actionCreatorPreloaderOn, actionCreatorPreloaderOff } from '../preloader/actions';
// eslint-disable-next-line import/no-named-as-default
import actions, { acpClaimDownloadCompleted, acpNladSubmissionDownloadCompleted } from './actions';

export function* GET_ACP_CUSTOMER_CLAIM() {
	yield put(actionCreatorPreloaderOn);
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(AcpProgramApi.getAcpCustomerClaim);
		if (result) {
			yield put({
				type: actions.SET_STATE,
				payload: {
					acpClaims: isArray(result) ? result : result.toArray()
				}
			});
		}
	} catch (error) {
		console.log('GET_ACP_CUSTOMER_CLAIM ERROR');
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* UPLOAD_NEW_CLAIM({ payload }) {
	const { claimFile, claimPeriod, claimType } = payload;
	yield put(actionCreatorPreloaderOn());
	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingFile: true,
			loading: true
		}
	});
	try {
		if (claimFile && claimFile instanceof File) {
			const result = yield call(AcpProgramApi.uploadClaim, payload);
			if (!result) {
				yield put(triggerNotification((error.data && error.data.message) || 'Error while uploading the file', 'error'));
			} else {
				yield put(triggerNotification('Claim uploaded successfully', 'success'));
			}
		}
	} catch (error) {
		console.log('UPLOAD_NEW_CLAIM ERROR');
		yield put(triggerNotification((error.data && error.data.message) || 'Communication error - Unable to communicate properly with the endpoint', 'error'));
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingFile: false,
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* DOWNLOAD_ACP_CLAIM({ payload }) {
	try {
		const { acpClaimId, claimNumber } = payload;
		const data = yield call(AcpProgramApi.downloadClaim, { acpClaimId });
		const blob = new Blob([data]);
		saveAs(blob, `submission_${claimNumber}.csv`);
		yield put(acpClaimDownloadCompleted());
	} catch (error) {
		yield put(acpClaimDownloadCompleted());
	}
}

export function* DOWNLOAD_NLAD_SUBMISSION({ payload }) {
	try {
		const { nladSubmissionId, claimNumber, type } = payload;
		const data = yield call(AcpProgramApi.downloadNladSubmission, { nladSubmissionId });
		const blob = new Blob([data]);
		saveAs(blob, `${type}_submission_${claimNumber}.csv`);
		yield put(acpNladSubmissionDownloadCompleted());
	} catch (error) {
		yield put(acpNladSubmissionDownloadCompleted());
	}
}

export function* DOWNLOAD_CLAIMABLE_DEVICE_TEMPLATE() {
	try {
		const data = yield call(AcpProgramApi.downloadClaimableDeviceTemplate);
		const blob = new Blob([data]);
		saveAs(blob, 'acp-claimable-device-template.csv');
		yield put(acpClaimDownloadCompleted());
	} catch (error) {
		yield put(acpClaimDownloadCompleted());
	}
}

export function* DOWNLOAD_MRC_SALES_TEMPLATE() {
	try {
		const data = yield call(AcpProgramApi.downloadMrcSalesTemplate);
		const blob = new Blob([data]);
		saveAs(blob, 'acp-mrc_sales_template.csv');
		yield put(acpClaimDownloadCompleted());
	} catch (error) {
		yield put(acpClaimDownloadCompleted());
	}
}

export function* GET_ACP_INVOICES() {
	yield put(actionCreatorPreloaderOn);
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(AcpProgramApi.getAcpInvoices);
		if (result) {
			yield put({
				type: actions.SET_STATE,
				payload: {
					acpInvoices: isArray(result) ? result : result.toArray()
				}
			});
		}
	} catch (error) {
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_ACP_INVOICE({ payload }) {
	yield put(actionCreatorPreloaderOn);
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(AcpProgramApi.getAcpInvoice, payload.id);
		if (result) {
			yield put({
				type: actions.SET_STATE,
				payload: {
					invoice: result
				}
			});
		}
	} catch (error) {
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* DELETE_ACP_CLAIM({ payload }) {
	yield put(actionCreatorPreloaderOn);
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: true
		}
	});

	try {
		const result = yield call(AcpProgramApi.deleteClaimById, payload.claimId);
		if (!result) {
			yield put(triggerNotification('Error while deleting the claim', 'error'));
		} else {
			yield put(triggerNotification('Claim deleted successfully', 'success'));
			yield put({
				type: actions.GET_ACP_CUSTOMER_CLAIM
			});
		}
	} catch (error) {
		console.log('DELETE_CLAIM ERROR');
		yield put(triggerNotification((error.data && error.data.message) || 'Communication error - Unable to communicate properly with the endpoint', 'error'));
	}

	yield put({
		type: actions.SET_STATE,
		payload: {
			loadingFile: false,
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_NLAD_SUBMISSIONS() {
	yield put(actionCreatorPreloaderOn);
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(AcpProgramApi.getNladSubmissions);
		if (result) {
			const { active, archived, currentMonthSubmissions, lastMonthSubmissions } = result;
			yield put({
				type: actions.SET_STATE,
				payload: {
					nladSubmissions: active,
					nladSubmissionsArchived: archived,
					nladSubmissionsCurrentMonth: currentMonthSubmissions,
					nladSubmissionsLastMonth: lastMonthSubmissions
				}
			});
		}
	} catch (error) {
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* ARCHIVE_NLAD_SUBMISSION({ payload }) {
	yield put(actionCreatorPreloaderOn);
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(AcpProgramApi.archiveNladSubmissions, payload);
		if (!result) {
			yield put(triggerNotification('Error while archiving the claims', 'error'));
		} else {
			yield put(triggerNotification('Claims archived successfully', 'success'));
			yield put({
				type: actions.GET_NLAD_SUBMISSIONS
			});
		}
	} catch (error) {
		console.log('ARCHIVE_CLAIM ERROR');
		yield put(triggerNotification((error.data && error.data.message) || 'Communication error - Unable to communicate properly with the endpoint', 'error'));
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* UNARCHIVE_NLAD_SUBMISSION({ payload }) {
	yield put(actionCreatorPreloaderOn);
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(AcpProgramApi.unArchiveNladSubmissions, payload);
		if (!result) {
			yield put(triggerNotification('Error while un-archiving the claims', 'error'));
		} else {
			yield put(triggerNotification('Claims un-archived successfully', 'success'));
			yield put({
				type: actions.GET_NLAD_SUBMISSIONS
			});
		}
	} catch (error) {
		console.log('UNARCHIVE_CLAIM ERROR');
		yield put(triggerNotification((error.data && error.data.message) || 'Communication error - Unable to communicate properly with the endpoint', 'error'));
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_ARCHIVE_SETTINGS() {
	yield put(actionCreatorPreloaderOn);
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: true
		}
	});

	try {
		const result = yield call(AcpProgramApi.getArchiveSettings);
		if (!result) {
			yield put(triggerNotification('Error getting archiving settings', 'error'));
		} else {
			yield put({
				type: actions.SET_STATE,
				payload: {
					archiveSettings: result
				}
			});
		}
	} catch (error) {
		yield put(triggerNotification((error.data && error.data.message) || 'Error getting archiving settings', 'error'));
	}

	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* UPDATE_ARCHIVE_SETTINGS({ payload }) {
	yield put(actionCreatorPreloaderOn);
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: true
		}
	});

	try {
		const result = yield call(AcpProgramApi.updateArchiveSettings, payload);
		if (!result) {
			yield put(triggerNotification('Error updating archiving settings', 'error'));
		} else {
			yield put({
				type: actions.SET_STATE,
				payload: {
					archiveSettings: result
				}
			});

			yield put(triggerNotification('Archiving settings updated successfully', 'success'));
			yield put({
				type: actions.GET_ARCHIVE_SETTINGS
			});
		}
	} catch (error) {
		yield put(triggerNotification((error.data && error.data.message) || 'Error updating archiving settings', 'error'));
	}

	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_PRO_FORMA_CLAIMS() {
	yield put(actionCreatorPreloaderOn);
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(AcpProgramApi.getProFormaClaims);
		if (result) {
			yield put({
				type: actions.SET_STATE,
				payload: {
					proFormaClaims: result
				}
			});
		}
	} catch (error) {
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_NEW_PRO_FORMA_CLAIMS() {
	yield put(actionCreatorPreloaderOn);
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(AcpProgramApi.getNewProFormaClaims);
		if (result) {
			yield put({
				type: actions.SET_STATE,
				payload: {
					newProFormaClaims: result
				}
			});
		}
	} catch (error) {
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_PAYMENTS() {
	yield put(actionCreatorPreloaderOn);
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(AcpProgramApi.getPayments);
		if (result) {
			yield put({
				type: actions.SET_STATE,
				payload: {
					payments: result
				}
			});
		}
	} catch (error) {
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_STATEMENTS() {
	yield put(actionCreatorPreloaderOn);
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(AcpProgramApi.getStatements);
		if (result) {
			yield put({
				type: actions.SET_STATE,
				payload: {
					statements: result
				}
			});
		}
	} catch (error) {
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export function* GET_PRO_FORMA_CLAIMS_PARTICIPANT_INFO() {
	yield put(actionCreatorPreloaderOn);
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: true
		}
	});
	try {
		const result = yield call(AcpProgramApi.getProFormaClaimsParticipantInfo);
		if (result) {
			yield put({
				type: actions.SET_STATE,
				payload: {
					proFormaClaimsParticipantInfo: result
				}
			});
		}
	} catch (error) {
		yield put(
			triggerNotification(
				(error.response && error.response.data && error.response.data.message) || 'Communication error - Unable to communicate properly with the endpoint',
				'error'
			)
		);
	}
	yield put({
		type: actions.SET_STATE,
		payload: {
			loading: false
		}
	});
	yield put(actionCreatorPreloaderOff());
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.GET_ACP_CUSTOMER_CLAIM, GET_ACP_CUSTOMER_CLAIM),
		takeEvery(actions.DOWNLOAD_ACP_CLAIM, DOWNLOAD_ACP_CLAIM),
		takeEvery(actions.DOWNLOAD_NLAD_SUBMISSION, DOWNLOAD_NLAD_SUBMISSION),
		takeEvery(actions.GET_ACP_INVOICES, GET_ACP_INVOICES),
		takeEvery(actions.GET_ACP_INVOICE, GET_ACP_INVOICE),
		takeEvery(actions.UPLOAD_NEW_CLAIM, UPLOAD_NEW_CLAIM),
		takeEvery(actions.DELETE_ACP_CLAIM, DELETE_ACP_CLAIM),
		takeEvery(actions.GET_NLAD_SUBMISSIONS, GET_NLAD_SUBMISSIONS),
		takeEvery(actions.GET_PRO_FORMA_CLAIMS, GET_PRO_FORMA_CLAIMS),
		takeEvery(actions.DOWNLOAD_CLAIMABLE_DEVICE_TEMPLATE, DOWNLOAD_CLAIMABLE_DEVICE_TEMPLATE),
		takeEvery(actions.DOWNLOAD_MRC_SALES_TEMPLATE, DOWNLOAD_MRC_SALES_TEMPLATE),
		takeEvery(actions.GET_PRO_FORMA_CLAIMS_PARTICIPANT_INFO, GET_PRO_FORMA_CLAIMS_PARTICIPANT_INFO),
		takeEvery(actions.GET_NEW_PRO_FORMA_CLAIMS, GET_NEW_PRO_FORMA_CLAIMS),
		takeEvery(actions.GET_PAYMENTS, GET_PAYMENTS),
		takeEvery(actions.GET_STATEMENTS, GET_STATEMENTS),
		takeEvery(actions.ARCHIVE_NLAD_SUBMISSION, ARCHIVE_NLAD_SUBMISSION),
		takeEvery(actions.UNARCHIVE_NLAD_SUBMISSION, UNARCHIVE_NLAD_SUBMISSION),
		takeEvery(actions.GET_ARCHIVE_SETTINGS, GET_ARCHIVE_SETTINGS),
		takeEvery(actions.UPDATE_ARCHIVE_SETTINGS, UPDATE_ARCHIVE_SETTINGS)
	]);
}
