import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm, submit } from 'redux-form';
import PropTypes from 'prop-types';

import { SmartContractsEditForm, RenderNotification, SmartContractRequestFundingModal, RenderBadge, RenderTable, ContractWalletDetails } from 'components';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import moment from 'moment';

import {
	initialSmartContractNew,
	loadSmartContract,
	getIpParticipant,
	signSmartContract,
	loadDestinationTypeCarrier,
	loadDestinationsCarrier,
	loadDialCodes,
	updateSmartContract,
	loadRatePlanRates,
	clearRatePlan,
	uploadSmartContractRatePlan,
	uploadSmartContractImmediateRatePlan,
	initializeRatePlanUpload,
	triggerProposedRatesAction,
	clearProposedRatesData,
	saveFundingRequest,
	getNewSmartContractData,
	getContractWalletData
} from 'store/actions';
import { fromSmartcontracts, fromUseraccount } from 'store/selectors';
import { createValidator, required, isChecked, validateRequiredDepentValue, validateDepentValue } from 'services/validation';
import { withTranslation } from 'react-i18next';

function TabContainer(props) {
	return (
		<Typography component="div" style={{ padding: '24px 0' }}>
			{props.children}
		</Typography>
	);
}

class SmartContractsEditContainer extends Component {
	static contextTypes = {
		store: PropTypes.object
	};

	constructor(props) {
		super(props);
		this.changeStatus = this.changeStatus.bind(this);
		this.onHardwareIdChange = this.onHardwareIdChange.bind(this);
		this.loadDestinationTypeCarrierContainer = this.loadDestinationTypeCarrierContainer.bind(this);
		this.loadDestinationsCarrierContainer = this.loadDestinationsCarrierContainer.bind(this);
		this.validateSelectCountryContainer = this.validateSelectCountryContainer.bind(this);
		this.toggleDialCodesContainer = this.toggleDialCodesContainer.bind(this);
		this.loadDialCodesContainer = this.loadDialCodesContainer.bind(this);
		this.checkSelect = this.checkSelect.bind(this);
		this.selectCodes = this.selectCodes.bind(this);
		this.signContract = this.signContract.bind(this);
		this.setRateRefreshOptions = this.setRateRefreshOptions.bind(this);
		this.handleSubmitFundingRequest = this.handleSubmitFundingRequest.bind(this);
		this.handleTabChange = this.handleTabChange.bind(this);
		this.toggleImmediateRateChangeDialog = this.toggleImmediateRateChangeDialog.bind(this);

		this.state = {
			showImmediateRateChangeDialog: false,
			showOptionsSell: 'block',
			showOptionBuy: 'none',

			valCountry: undefined,
			varDesType: undefined,
			styleCountrySel: '',
			modalDialCodes: false,
			destinationsSelected: [],
			destinationsCarrierSelected: [],
			dialCodesSelected: [],
			codeRowIndexSelected: undefined,
			activeTab: 'contractDetails',
			requestFundingModal: false,
			rateRefreshOptions: {}
		};
	}

	componentWillMount() {
		const { dispatch, match, getSmartContract, getNewSmartContractData, loadInitialSmartContractNew, getContractWalletData } = this.props;
		console.log(`componentWillMount:${match.params.smartContractid}`);
		console.log('match,',match)
		if (match.params.smartContractid) {
			dispatch(initializeRatePlanUpload());
			// loadInitialSmartContractNew();
			getNewSmartContractData(match.params.smartContractid);
			getSmartContract(match.params.smartContractid);
			getContractWalletData(match.params.smartContractid);
		}
	}

	/* componentDidMount(){
    const { change, smartContract } = this.props;
    if(smartContract){
      smartContract.offers.forEach((member, index) => {
        console.log(`cambiando --- >> members.${index}.countryId=${member.countryId}`);
        change(
          `members.${index}.countryId`,
          member.countryId
        );
      });
    }
  } */

	componentWillReceiveProps(nextProps) {
		const { change, smartContract, ipSupplier, ipCustomer } = this.props;
		if (smartContract !== nextProps.smartContract && nextProps.smartContract) {
			let showOptionsSell = 'none';
			let showOptionBuy = 'none';
			if (nextProps.smartContract.type === 'Sell') {
				showOptionsSell = 'block';
			} else if (nextProps.smartContract.type === 'Buy') {
				showOptionBuy = 'block';
			}
			this.setState({ showOptionsSell, showOptionBuy });

			if (nextProps.smartContract.catalogOffers) {
				nextProps.smartContract.catalogOffers.destinationType.forEach((member, index) => {
					this.setState(prevState => {
						const destinationsSelectedOld = {
							...prevState.destinationsSelected
						};
						destinationsSelectedOld[index] = member;
						return {
							...prevState,
							destinationsSelected: destinationsSelectedOld
						};
					});
				});
				nextProps.smartContract.catalogOffers.destinationCarrier.forEach((member, index) => {
					this.setState(prevState => {
						const destinationsCarrierSelectedOld = {
							...prevState.destinationsCarrierSelected
						};
						destinationsCarrierSelectedOld[index] = member;
						return {
							...prevState,
							destinationsCarrierSelected: destinationsCarrierSelectedOld
						};
					});
				});
				nextProps.smartContract.catalogOffers.codes.forEach((member, index) => {
					this.setState(prevState => {
						const dialCodesSelectedOld = { ...prevState.dialCodesSelected };
						dialCodesSelectedOld[index] = this.mergeCodes(member, nextProps.smartContract.offers[index].destinationsCarriers);
						return {
							...prevState,
							dialCodesSelected: dialCodesSelectedOld
						};
					});
				});
			}
		}

		if (ipSupplier !== nextProps.ipSupplier) {
			change('supplierIpAddress', nextProps.ipSupplier);
		}
		if (ipCustomer !== nextProps.ipCustomer) {
			change('customerIpAddress', nextProps.ipCustomer);
		}

		const { dialCodes, destinations, destinationsCarrier } = this.props;

		if (dialCodes !== nextProps.dialCodes) {
			this.setState(prevState => {
				const dialCodesSelectedOld = { ...prevState.dialCodesSelected };
				dialCodesSelectedOld[prevState.codeRowIndexSelected] = nextProps.dialCodes;
				return {
					...prevState,
					dialCodesSelected: dialCodesSelectedOld
				};
			});
		}
		if (destinations !== nextProps.destinations) {
			this.setState(prevState => {
				const destinationsSelectedOld = { ...prevState.destinationsSelected };
				destinationsSelectedOld[prevState.codeRowIndexSelected] = nextProps.destinations;
				return {
					...prevState,
					destinationsSelected: destinationsSelectedOld
				};
			});
		}
		if (destinationsCarrier !== nextProps.destinationsCarrier) {
			this.setState(prevState => {
				const destinationsCarrierSelectedOld = {
					...prevState.destinationsCarrierSelected
				};
				destinationsCarrierSelectedOld[prevState.codeRowIndexSelected] = nextProps.destinationsCarrier;
				return {
					...prevState,
					destinationsCarrierSelected: destinationsCarrierSelectedOld
				};
			});
		}
	}

	setRateRefreshOptions(smartContractId, options) {
		this.setState({
			rateRefreshOptions: {
				smartContractId,
				options
			}
		});
	}

	toggleImmediateRateChangeDialog = () => {
		const { showImmediateRateChangeDialog } = this.state;
		this.setState({
			showImmediateRateChangeDialog: !showImmediateRateChangeDialog
		});
	};

	toggleRequestFundingModal = () => {
		const { requestFundingModal } = this.state;
		this.setState({ requestFundingModal: !requestFundingModal });
	};

	mergeCodes(allCodes, selectedCodes) {
		return allCodes.map(code => {
			let selected = false;
			selectedCodes.forEach(selectedCode => {
				if (selectedCode.id === code.id) selected = true;
			});
			return { ...code, selected };
		});
	}

	onHardwareIdChange(hardwareId) {
		this.setState({ selectedHardwareId: hardwareId });
	}

	loadDestinationTypeCarrierContainer(country, codeRowIndex, countryId) {
		console.log('-------loadDestinationTypeCarrierContainer------');
		const { loadDestinationTypeCarrierDispatch } = this.props;
		loadDestinationTypeCarrierDispatch(country);

		this.setState(prevState => {
			const dialCodesSelectedOld = { ...prevState.dialCodesSelected };
			dialCodesSelectedOld[codeRowIndex] = undefined;
			const destinationsSelectedOld = { ...prevState.destinationsSelected };
			destinationsSelectedOld[codeRowIndex] = undefined;
			const destinationsCarrierSelectedOld = {
				...prevState.destinationsCarrierSelected
			};
			destinationsCarrierSelectedOld[codeRowIndex] = undefined;
			const styleCountry = {};
			styleCountry[`${countryId}.style`] = '';
			return {
				...prevState,
				valCountry: country,
				...styleCountry,
				dialCodesSelected: dialCodesSelectedOld,
				destinationsSelected: destinationsSelectedOld,
				destinationsCarrierSelected: destinationsCarrierSelectedOld,
				codeRowIndexSelected: codeRowIndex
			};
		});
	}

	loadDestinationsCarrierContainer(destinationType, country, codeRowIndex) {
		const { loadDestinationsCarrierDispatch } = this.props;

		loadDestinationsCarrierDispatch(country, destinationType);

		this.setState(prevState => {
			const dialCodesSelectedOld = { ...prevState.dialCodesSelected };
			dialCodesSelectedOld[codeRowIndex] = undefined;
			const destinationsCarrierSelectedOld = {
				...prevState.destinationsCarrierSelected
			};
			destinationsCarrierSelectedOld[codeRowIndex] = undefined;
			return {
				...prevState,
				varDesType: destinationType,
				dialCodesSelected: dialCodesSelectedOld,
				destinationsCarrierSelected: destinationsCarrierSelectedOld,
				codeRowIndexSelected: codeRowIndex
			};
		});
	}

	loadDialCodesContainer(destinationCarries, country, destinationType, codeRowIndex) {
		const { loadDialCodesDispatch } = this.props;

		loadDialCodesDispatch(destinationCarries, country, destinationType);
		this.setState(prevState => {
			const dialCodesSelectedOld = { ...prevState.dialCodesSelected };
			dialCodesSelectedOld[codeRowIndex] = undefined;
			return {
				...prevState,
				dialCodesSelected: dialCodesSelectedOld,
				codeRowIndexSelected: codeRowIndex
			};
		});
	}

	validateSelectCountryContainer(country, codeRowIndex) {
		if (country === undefined || country == null || country === '') {
			const styleCountry = {};
			styleCountry[`${country}.style`] = 'red';
			// this.setState({ styleCountrySel: "red" });
			this.setState({ ...styleCountry });
		} else {
			const styleCountry = {};
			styleCountry[`${country}.style`] = '';
			this.setState(prevState => ({
				...prevState,
				...styleCountry,
				modalDialCodes: true,
				codeRowIndexSelected: codeRowIndex
				// dialCodesSelected: dialCodesSelectedOld
			}));
		}
	}

	toggleDialCodesContainer() {
		this.setState(prevState => {
			const modalDialCodes = { ...prevState.modalDialCodes };
			return { ...prevState, modalDialCodes: !modalDialCodes };
		});
	}

	checkSelect(sel, codeRowIndex, checked) {
		this.setState(prevState => {
			const dialCodesSelectedOld = { ...prevState.dialCodesSelected };
			const dialCodesSelectedNewRow = dialCodesSelectedOld[codeRowIndex].map(codeDial => {
				if (codeDial.id === sel) return { ...codeDial, selected: checked };
				return codeDial;
			});

			dialCodesSelectedOld[codeRowIndex] = dialCodesSelectedNewRow;

			return {
				...prevState,
				styleCountrySel: '',
				modalDialCodes: true,
				dialCodesSelected: dialCodesSelectedOld
			};
		});
	}

	selectCodes(codeRowIndex, selected) {
		this.setState(prevState => {
			const dialCodesSelectedOld = { ...prevState.dialCodesSelected };
			const dialCodesSelectedNewRow = dialCodesSelectedOld[codeRowIndex].map(codeDial => ({ ...codeDial, selected }));

			dialCodesSelectedOld[codeRowIndex] = dialCodesSelectedNewRow;

			return {
				...prevState,
				dialCodesSelected: dialCodesSelectedOld
			};
		});
	}

	changeStatus(status) {
		const { change } = this.props;
		change('status', status);
	}

	handleTabChange(event, activeTab) {
		this.setState({ activeTab });
	}

	signContract(smartContractId) {
		const { store } = this.context;
		const { nameForm, dispatch } = this.props;
		const state = store.getState();
		const selector = formValueSelector(nameForm);
		const valueTerms = selector(state, 'terms');
		if (valueTerms) {
			const { sign } = this.props;
			sign(smartContractId);
		} else {
			dispatch(submit(nameForm));
		}
	}

	handleSubmitFundingRequest(data) {
		this.props.saveFundingRequest(data);
		this.toggleRequestFundingModal();
	}

	render() {
		const {
			role,
			contractWalletData,
			handleSubmit,
			errorMessage,
			messageStatus,
			messageStatusError,
			dataNewSmartContract,
			newSmartContractData,
			smartContract,
			proposedRatesSuccessMessage,
			proposedRatesErrorMessage,
			catalogsNewSmartContract,
			initialValues: { invoicePeriodId },
			clearProposedRatesMessages,
			t
		} = this.props;

		const { activeTab } = this.state;
		const chainInformation = smartContract && smartContract.chain ? smartContract.chain : false;

		const isSignedByBothParties = smartContract && smartContract.signatures ? smartContract.signatures.length === 2 : false;

		const contract = smartContract && smartContract.contract ? smartContract.contract : null;

		const isFunder = role.indexOf(ROLES.FUNDER) !== -1;

		// this is used for SmartContractRequestFundingModal
		let contractData;
		if (smartContract && newSmartContractData) {
			const isBuy = smartContract.type === 'Buy';
			let supplierName = newSmartContractData.participant.participantName;
			let customerName = newSmartContractData.participant.participantName;
			if (isBuy && newSmartContractData.suppliers) {
				const searchSupplier = newSmartContractData.suppliers.find(obj => obj.value === smartContract.contract.supplierId);
				supplierName = searchSupplier ? searchSupplier.label : '';
			}
			if (!isBuy && newSmartContractData.customers) {
				const searchCustomer = newSmartContractData.customers.find(obj => obj.value === smartContract.contract.customerId);
				customerName = searchCustomer ? searchCustomer.label : '';
			}

			const catalogPaymentTerms = catalogsNewSmartContract && catalogsNewSmartContract.paymentTerms ? catalogsNewSmartContract.paymentTerms : [];

			let billingCycleName = invoicePeriodId ? catalogPaymentTerms.find(obj => obj.invoicePeriodId === invoicePeriodId) : false;
			billingCycleName = billingCycleName ? billingCycleName.invoicePeriodType : '';

			contractData = {
				contractTerms: {
					contractId: this.props.smartContractId || null,
					supplier: supplierName,
					customer: customerName,
					billingCycle: billingCycleName,
					paymentTerms: contract.paymentTerms || null,
					settlementPeriod: dataNewSmartContract && dataNewSmartContract.defaultSettlementPeriod ? dataNewSmartContract.defaultSettlementPeriod.settlementPeriod : '',
					billingTimezone: 'GMT',
					contractEndDate: contract.endDate || null
				}
			};
		}

		// console.log("catalogsNewSmartContract",catalogsNewSmartContract);
		// console.log("dataNewSmartContract",dataNewSmartContract);
		// console.log("smartContract",smartContract);

		return (
			<div>
				<RenderNotification
					variant="success"
					floating
					open={!!proposedRatesSuccessMessage}
					message={proposedRatesSuccessMessage}
					onClose={() => {
						clearProposedRatesMessages();
					}}
				/>
				<RenderNotification
					variant="danger"
					floating
					open={!!proposedRatesErrorMessage}
					message={proposedRatesErrorMessage}
					onClose={() => {
						clearProposedRatesMessages();
					}}
				/>

				<RenderNotification variant="success" floating={false} open={messageStatus} message={messageStatus} />
				<RenderNotification variant="danger" floating={false} open={messageStatusError} message={messageStatusError} />

				{smartContract === undefined && errorMessage !== undefined && <div className="alert alert-danger">{errorMessage}</div>}

				<AppBar position="static">
					<Tabs value={activeTab} onChange={this.handleTabChange}>
						<Tab label={t('tabs.contract_details')} value="contractDetails" />
						<Tab label={t('tabs.contract_wallet_details')} value="contractWalletDetails" />
					</Tabs>
				</AppBar>

				{activeTab === 'contractDetails' && (
					<TabContainer>
						{catalogsNewSmartContract !== undefined &&
						dataNewSmartContract !== undefined &&
						// dataNewSmartContract.participant !== undefined &&
						smartContract !== undefined ? (
							<div>
								<SmartContractsEditForm
									onHardwareIdChange={this.onHardwareIdChange}
									handleSubmit={handleSubmit}
									changeStatus={this.changeStatus}
									loadDestinationTypeCarrier={this.loadDestinationTypeCarrierContainer}
									loadDestinationsCarrier={this.loadDestinationsCarrierContainer}
									loadDialCodes={this.loadDialCodesContainer}
									validateSelectCountry={this.validateSelectCountryContainer}
									toggleRequestFundingModal={this.toggleRequestFundingModal}
									toggleDialCodes={this.toggleDialCodesContainer}
									checkSelect={this.checkSelect}
									selectCodes={this.selectCodes}
									signContract={this.signContract}
									setRateRefreshOptions={this.setRateRefreshOptions}
									toggleImmediateRateChangeDialog={this.toggleImmediateRateChangeDialog}
									{...this.props}
									{...this.state}
								/>

								<SmartContractRequestFundingModal
									open={this.state.requestFundingModal}
									openCloseHandler={this.toggleRequestFundingModal}
									onSubmit={this.handleSubmitFundingRequest}
									contractData={contractData || null}
									initialValues={{
										contractId: this.props.smartContractId
									}}
								/>
							</div>
						) : (
							<Typography style={{ textAlign: 'center', marginTop: 40 }}>Loading Contract data...</Typography>
						)}
					</TabContainer>
				)}

				{activeTab === 'contractWalletDetails' && (
					<TabContainer>
						<ContractWalletDetails data={contractWalletData} />
					</TabContainer>
				)}
			</div>
		);
	}
}

const getOffers = smartContract => {
	if (smartContract && smartContract.offers) {
		return smartContract.offers.map(offer => {
			const OfferReduced = {
				countryId: offer.countryId,
				destinationTypeId: offer.destinationTypeId,
				destinationCarrierId: offer.destinationCarrierId,
				smartContractOfferCodes: offer.destinationsCarriers.map(codes => codes.id).toString(),
				offerTypeId: offer.offerTypeId,
				billingIncrementId: offer.billingIncrementId,
				offerPrice: offer.offerPrice
			};
			return OfferReduced;
		});
	}
	return [];
};

const transformDataSubmit = dataForm => {
	const dataSubmit = Object.assign({}, dataForm);
	if (dataSubmit.endDate != null && dataSubmit.endDate !== undefined) dataSubmit.endDate = dataSubmit.endDate.valueOf();
	if (dataSubmit.smartContractCodecs != null && dataSubmit.smartContractCodecs !== undefined) {
		dataSubmit.smartContractCodecs = Object.keys(dataSubmit.smartContractCodecs)
			.map(codec => (dataSubmit.smartContractCodecs[codec] ? codec : null))
			.filter(codec => codec);
	}
	if (dataSubmit.members != null && dataSubmit.members !== undefined) {
		dataSubmit.smartContractOffers = dataSubmit.members
			.map(offer => {
				if (!offer.countryId) return null;

				if (!offer.offerPrice) return null;

				return { ...offer };
			})
			.filter(offer => offer);
		delete dataSubmit.members;
	}
	console.log(`==========SmartContractsNewContainer====processDataSubmit--${JSON.stringify(dataSubmit, null, ' ')}`);
	return dataSubmit;
};

const getTempId = dataNewSmartContract => dataNewSmartContract && dataNewSmartContract.tempId;

/* eslint-disable */
const mapStateToProps = state => ({
  catalogsNewSmartContract: fromSmartcontracts.getCatalogNewSmartContract(
    state
  ),
  dataNewSmartContract: fromSmartcontracts.getDataNewSmartContract(state),
  newSmartContractData: fromSmartcontracts.getNewSmartContractData(state),
  smartContract: fromSmartcontracts.getSmartContractsDataState(state)
    .smartContract,
  errorMessage: fromSmartcontracts.getSmartContractsDataState(state)
    .errorMessage,
  smartContractId: fromSmartcontracts.getContractData(state).id,
  initialValues: {
    smartContractId: fromSmartcontracts.getContractData(state).id,
    contractType: fromSmartcontracts.getContractType(state),
    supplierId: fromSmartcontracts.getContractData(state).supplierId,
    customerId: fromSmartcontracts.getContractData(state).customerId,
    supplierSbcId: fromSmartcontracts.getContractData(state).supplierSbcId,
    customerSbcId: fromSmartcontracts.getContractData(state).customerSbcId,
    trafficType: fromSmartcontracts.getContractData(state).trafficType,
    paymentTerms: fromSmartcontracts.getContractData(state).paymentTerms,
    isOriginContract: fromSmartcontracts.getContractData(state)
      .isOriginContract,
    supplierHardwareProfileId: fromSmartcontracts.getContractData(state)
      .supplierHardwareProfileId,
    customerHardwareProfileId: fromSmartcontracts.getContractData(state)
      .customerHardwareProfileId,
    settlementPeriodId: fromSmartcontracts.getContractData(state)
      .settlementPeriodId,
    invoicePeriodId: fromSmartcontracts.getContractData(state).invoicePeriodId,
    ratePlans: fromSmartcontracts.getRatePlans(state),
    members: getOffers(
      fromSmartcontracts.getSmartContractsDataState(state).smartContract
    ),
    status: fromSmartcontracts.getContractData(state).status,
    endDate: fromSmartcontracts.getContractData(state).endDate,
    tempId:
      fromSmartcontracts.getSmartContractTempId(state) ||
      getTempId(fromSmartcontracts.getDataNewSmartContract(state)),
    minimumNoticeDays: fromSmartcontracts.getContractData(state).minimumNoticeDays
  },
  hardwareProfiles: fromSmartcontracts.getHardwareProfiles(state),
  customerHardwareProfile: fromSmartcontracts.getCustomerHardwareProfile(state),
  supplierHardwareProfile: fromSmartcontracts.getSupplierHardwareProfile(state),
  enableReinitialize: true,
  nameForm: "editSmartContractForm",
  destinations: fromSmartcontracts.getDestinationsType(state),
  destinationsCarrier: fromSmartcontracts.getDestinationsCarrier(state),
  dialCodes: fromSmartcontracts.getDialCodes(state),
  paymentTermsSmartContract: fromSmartcontracts.getSmartContractsDataState(
    state
  ).smartContractsPaymentTerms,
  messageStatus: fromSmartcontracts.getSmartContractsDataState(state)
    .messageStatus,
  messageStatusError: fromSmartcontracts.getSmartContractsDataState(state)
    .messageStatusError,
  ratesData: fromSmartcontracts.getRatesDataState(state),
  ratePlanData: fromSmartcontracts.getSmartContractRatePlanState(state),
  proposedRatesSuccessMessage: fromSmartcontracts.getProposedRatesData(state).info,
  proposedRatesErrorMessage: fromSmartcontracts.getProposedRatesData(state).error,
  role: fromUseraccount.getUserRol(state),
  contractWalletData: fromSmartcontracts.getSmartContractWalletData(state)
});
/* eslint-enable */

const mapDispatchToProps = dispatch => ({
	clearUploadError() {
		dispatch(clearRatePlan());
	},
	uploadRatePlan(tempSmartContractId, ratesFile, effDate, rateRefreshOptions = null) {
		dispatch(uploadSmartContractRatePlan(tempSmartContractId, ratesFile, effDate, rateRefreshOptions));
	},
	uploadImmediateRatePlan(smartContractId, ratesFile) {
		dispatch(uploadSmartContractImmediateRatePlan(smartContractId, ratesFile));
	},
	getRatePlanRates(smartContractId, options) {
		dispatch(loadRatePlanRates(smartContractId, options));
	},
	sendProposedRatesAction(smartContractId, options) {
		dispatch(triggerProposedRatesAction(smartContractId, options));
	},
	clearProposedRatesMessages() {
		dispatch(clearProposedRatesData());
	},
	loadInitialSmartContractNew() {
		dispatch(initialSmartContractNew());
	},
	loadIpParticipant(id, type) {
		dispatch(getIpParticipant(id, type));
	},
	getSmartContract(smartContractId) {
		dispatch(loadSmartContract(smartContractId));
	},
	sign(smartContractId) {
		dispatch(signSmartContract(smartContractId));
	},
	loadDestinationTypeCarrierDispatch(country) {
		dispatch(loadDestinationTypeCarrier(country));
	},
	loadDestinationsCarrierDispatch(country, destinationType) {
		dispatch(loadDestinationsCarrier(country, destinationType));
	},
	loadDialCodesDispatch(destinationCarries, country, destinationType) {
		dispatch(loadDialCodes(destinationCarries, country, destinationType));
	},
	saveFundingRequest(data) {
		dispatch(saveFundingRequest(data));
	},
	getNewSmartContractData(data) {
		dispatch(getNewSmartContractData(data));
	},
	getContractWalletData(data) {
		dispatch(getContractWalletData(data));
	}
});

const onSubmit = (dataSmartContract, dispatch) => {
	dispatch(updateSmartContract(dataSmartContract.smartContractId, transformDataSubmit(dataSmartContract)));
};

SmartContractsEditContainer.defaultProps = {
	role: '',
	proposedRatesErrorMessage: '',
	proposedRatesSuccessMessage: '',
	dataNewSmartContract: {},
	newSmartContractData: {
		participant: {
			participantName: ''
		}
	},
	catalogsNewSmartContract: { paymentTerms: '' },
	initialValues: { invoicePeriodId: '' }
};

const validate = createValidator({
	customerId: [validateRequiredDepentValue('contractType', 'Buy')],
	supplierId: [validateRequiredDepentValue('contractType', 'Sell')],
	paymentTerms: [validateRequiredDepentValue('status', 'pending'), validateRequiredDepentValue('status', 'draft')],
	terms: [required, isChecked],
	minimumNoticeDays: [validateDepentValue('status', 'pending', required)]
});

const SmartContractsEditContainerConnect = reduxForm({
	form: 'editSmartContractForm',
	destroyOnUnmount: false,
	onSubmit,
	validate
})(SmartContractsEditContainer);

const SmartContractsEditContainerTranslated = withTranslation(['smartcontracts/edit', 'translations'])(SmartContractsEditContainerConnect);

export default connect(mapStateToProps, mapDispatchToProps)(SmartContractsEditContainerTranslated);
