import React, { memo, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import Joyride from 'react-joyride';
import { AppBar, Tab, Tabs } from '@material-ui/core';

import NLADVerifiedSubmissions from './components/NLADVerifiedSubmissionsTab';
import { ProviderSubmissionsTab } from './components/ProviderSubmissionsTab';
import ProFormaClaimsTab from './components/ProFormaClaimsTab';
import DailyClaimsTab from './components/DailyClaims';
import PaymentsTab from './components/PaymentsTab';
import StatementsTab from './components/StatementsTab';
import styles from './styles.module.scss';
import NLADVerifiedSubmissionsTab from './components/NLADVerifiedSubmissionsTab';

export default memo(() => {
	let history = useHistory();
	const { tab } = useParams();

	const handleTabChange = (event, value) => {
		history.push(`/acp-program/${value}`);
	};

	return (
		<div className={styles.container}>
			<div className="head-tabs">
				<AppBar position="static">
					<Tabs value={tab} onChange={handleTabChange}>
						<Tab id="provider-submissions-tab" value="provider-submissions" label="Provider Submissions" />
						<Tab id="nlad-verified-submissions-tab" value="nlad-verified-submissions" label="NLAD Verified Submissions" />
						<Tab id="daily-claims-tab" value="daily-claims" label="Daily Claims" />
						<Tab id="pro-forma-claims-tab" value="pro-forma-claims" label="Pro Forma Claims" />
						<Tab id="payments-tab" value="payments" label="Payments" />
						<Tab id="statements-tab" value="statements" label="Statements" />
					</Tabs>
				</AppBar>
			</div>
			<div>
				{(tab === 'provider-submissions' && <ProviderSubmissionsTab />) || ''}
				{(tab === 'nlad-verified-submissions' && <NLADVerifiedSubmissionsTab />) || ''}
				{(tab === 'daily-claims' && <DailyClaimsTab />) || ''}
				{(tab === 'pro-forma-claims' && <ProFormaClaimsTab />) || ''}
				{(tab === 'payments' && <PaymentsTab />) || ''}
				{(tab === 'statements' && <StatementsTab />) || ''}
			</div>
		</div>
	);
});
