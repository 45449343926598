import { initialState } from "./selectors";
import {
  CREATE_ORDER,
  CANCEL_ORDER,
  RESULT_PROCESS_ORDER,
  ACCEPT_ORDER,
  ORDER,
  RESULT_ORDER,
  INITIALIZE_BIDDING_PORTAL,
  RESULT_INITIALIZE_BIDDING_PORTAL
} from "./actions";

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case INITIALIZE_BIDDING_PORTAL:
      return {
        ...state,
        [INITIALIZE_BIDDING_PORTAL]: {
          ...payload
        }
      };

    case CREATE_ORDER:
      return {
        ...state,
        [CREATE_ORDER]: {
          ...payload
        }
      };

    case CANCEL_ORDER:
      return {
        ...state,
        [CANCEL_ORDER]: {
          ...payload
        }
      };

    case RESULT_PROCESS_ORDER:
      return {
        ...state,
        [RESULT_PROCESS_ORDER]: {
          ...payload
        }
      };

    case ACCEPT_ORDER:
      return {
        ...state,
        [ACCEPT_ORDER]: {
          ...payload
        }
      };

    case ORDER:
      return {
        ...state,
        [ORDER]: {
          ...payload
        }
      };

    case RESULT_ORDER:
      return {
        ...state,
        [RESULT_ORDER]: {
          ...payload
        }
      };

    case RESULT_INITIALIZE_BIDDING_PORTAL:
      return {
        ...state,
        [RESULT_INITIALIZE_BIDDING_PORTAL]: {
          ...payload
        }
      };

    default:
      return state;
  }
};
