import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { handleCurrency } from 'utils';

export const useForm = ({ selectedSmartContract, uploadInvoiceInputRef, modalOpened, selectedPof }) => {
	const dispatch = useDispatch();
	const [fileAddress, setFileAddress] = useState('');

	const { orderId, _id, cost, scQty, pof: pofs } = selectedSmartContract || {};

	const { _id: pofId, scId } = selectedPof || {};

	const blockedItems = pofs?.reduce((acc, pof) => {
		if (pof.status !== 'rejected') acc += parseInt(pof.deliveredQty);
		return acc;
	}, 0);

	const remainingItems = scQty - blockedItems;

	const handleResetInputValue = useCallback(() => {
		setFileAddress(null);
		formik.setFieldValue('file', '');
		if (uploadInvoiceInputRef?.current) {
			uploadInvoiceInputRef.current.value = '';
		}
	}, [uploadInvoiceInputRef]);

	const formik = useFormik({
		initialValues: {
			invoiceNumber: '',
			fulfillmentDate: '',
			itemPrice: '',
			deliveredQty: '',
			file: ''
		},
		onSubmit: values => {
			const formData = new FormData();
			Object.keys(values).forEach(key => {
				formData.append(key, values[key]);
			});
			formData.append('smartContractId', _id);
			formData.append('orderId', orderId);

			try {
				dispatch({
					type: 'escrowManaging/UPLOAD_FULFILLMENT_PROOF',
					payload: formData
				});
			} catch (e) {
				console.log(e);
			}
		},
		validationSchema: Yup.object().shape({
			invoiceNumber: Yup.string().required('Invoice ID is required'),
			fulfillmentDate: Yup.string().required('Fulfillment date is required'),
			itemPrice: Yup.number()
				.required('Price is required')
				.equals([cost], `Price must be ${handleCurrency(cost, 'USD')}`),
			deliveredQty: Yup.number()
				.required('Delivered quantity is required')
				.max(remainingItems, `Only ${remainingItems} items left to be delivered`),
			file: Yup.string().required('The pdf document is required is required')
		})
	});

	useEffect(() => {
		formik.setFieldValue('invoiceNumber', formik?.values?.invoiceNumber?.toUpperCase());
	}, [formik.values]);

	useEffect(() => {
		formik.setValues({ itemPrice: cost });
	}, [cost, selectedSmartContract]);

	useEffect(() => {
		if (!modalOpened) {
			formik.resetForm();
			handleResetInputValue();
		}
	}, [modalOpened]);

	useEffect(() => {
		if (pofId && scId) {
			dispatch({ type: 'escrowManaging/GET_POF_URL', payload: { contractId: scId, proofId: pofId } });
		}
	}, [pofId, scId]);

	const pdfUploadError = useMemo(() => {
		return formik?.touched?.file && formik?.errors?.file;
	}, [formik?.touched?.file, formik?.errors?.file]);

	return { formik, handleResetInputValue, fileAddress, setFileAddress, pdfUploadError };
};
