import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { FinancialContext } from 'screens/Financials';
import MonthlyExternalInvoiceTable from './MonthlyExternalInvoiceTable';
import Invoices from './Invoices';

export default ({ type }) => {
	const dispatch = useDispatch();
	const { monthlyInvoices, externalInvoices, monthlyInvoicesCached, externalInvoicesCached } = useSelector(state => ({
		monthlyInvoicesCached: state?.reports?.MONTHLY_INVOICE_REPORT_DATA?.monthlyInvoicesCached ?? false,
		externalInvoicesCached: state?.reports?.EXTERNAL_INVOICE_REPORT_DATA.externalInvoicesCached ?? false,
		monthlyInvoices: state?.reports?.MONTHLY_INVOICE_REPORT_DATA[type],
		externalInvoices: state?.reports?.EXTERNAL_INVOICE_REPORT_DATA
	}));

	useEffect(() => {
		dispatch({ type: 'reports/GET_EXTERNAL_INVOICES', payload: { externalInvoicesCached } });
		dispatch({ type: 'reports/GET_MONTHLY_INVOICES', payload: { type, monthlyInvoicesCached } });
	}, []);

	return (
		<div className={styles.container}>
			<Invoices type={type} />
			<br />
			<MonthlyExternalInvoiceTable
				invoicesType={type === 'customer' ? 'monthly_invoices_per_cust' : 'monthly_invoices_per_supplier'}
				invoices={monthlyInvoices}
				FinancialContext={FinancialContext}
			/>
			<br />
			{type === 'customer' && <MonthlyExternalInvoiceTable invoicesType={'external_invoices'} invoices={externalInvoices} FinancialContext={FinancialContext} />}
			<br />
		</div>
	);
};
