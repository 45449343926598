export const initialState = {};

export const initialLoginData = {
	userName: null,
	password: null,
	errorMessage: null,
	successMessage: null,
	loginSuccess: null,
	loading: false
};
export const LOGIN_DATA = 'LOGIN_DATA';

export const getLoginDataState = (state = initialState) => state[LOGIN_DATA] || initialLoginData;
