import React from 'react';
import Chip from '@material-ui/core/Chip';

const RenderAcpType = ({ claimType, size }) => {
	return (
		<Chip
			size={size}
			label={claimType === 'sales' ? 'Sales' : claimType === 'claimable-devices' ? 'Devices' : claimType === 'mrc' ? 'MRC' : 'Unknown Claim Type'}
			style={{
				backgroundColor: claimType === 'sales' ? '#05ce7b' : claimType === 'claimable-devices' ? '#2fded2' : claimType === 'mrc' ? '#42eda9' : '#9a9c98'
			}}
		/>
	);
};

export default RenderAcpType;
