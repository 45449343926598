import { CardContent, Grid, Paper, Typography } from "@material-ui/core";
import { Field, change, reduxForm } from "redux-form";
import React, { Component } from "react";
import {
  RenderButton,
  RenderFileUpload,
  RenderInput,
  RenderSelect
} from "components";
import {
  createValidator,
  isNumber,
  isOptionalNumber,
  maxLength,
  requiredString
} from "services/validation";

import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class GeneralInformationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryId: "",
      logo: ""
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.initialValues.countryId !== prevState.countryId) {
      const country = {
        countryId: nextProps.initialValues.countryId
      };

      nextProps.getStatesData(country);

      return {
        countryId: nextProps.initialValues.countryId
      };
    }

    if (nextProps.initialValues.logo !== prevState.logo) {
      return {
        logo: nextProps.initialValues.logo
      };
    }

    return null;
  }

  componentDidMount() {
    const { accountData } = this.props;
  }

  handleLogo = evt => {
    const { handleLogoUpload } = this.props;
    const fd = evt && evt.target && evt.target.files && evt.target.files[0];
    handleLogoUpload(fd);
  };

  changeCountry = e => {
    const { getStatesData } = this.props;
    getStatesData({ countryId: e.value });
  };

  render() {
    const {
      handleSubmit,
      businessTypeData,
      statesData,
      countriesData,
      phonePrefixData,
      submitSucceeded,
      pristine,
      initialValues,
      accountData
    } = this.props;

    const { logo } = this.state;

    const sc = countriesData.sort((a, b) =>
      a.countryName.toLowerCase().localeCompare(b.countryName.toLowerCase())
    );

    const sortedCountries = sc.map(option => ({
      value: option.countryId,
      label: option.countryName
    }));

    return (
      <form onSubmit={handleSubmit}>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Paper>
              <CardContent>
                <Typography variant="title" gutterBottom>
                  Business Profile
                </Typography>
                <Grid container spacing={8} alignItems="flex-end">
                  <Grid item xs={4}>
                    <Field
                      id="participantName"
                      name="participantName"
                      component={RenderInput}
                      type="text"
                      label="Company Name"
                      margin="none"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      id="countryId"
                      name="countryId"
                      label="Country"
                      component={RenderSelect}
                      options={sortedCountries}
                      callback={this.changeCountry}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Field
                      type="file"
                      component={RenderFileUpload}
                      allowedFileTypes={[".jpg", ".jpeg", ".png"]}
                      btnText="Upload logo"
                      name="image"
                      handleUploadFn={evt => {
                        this.handleLogo(evt);
                      }}
                    />
                  </Grid>
                  {logo && (
                    <Grid item xs={2}>
                      <img
                        src={logo}
                        alt="company logo"
                        style={{ maxWidth: "100%", maxHeight: 40 }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={4}>
                    <Field
                      id="businessTypeId"
                      name="businessTypeId"
                      label="Business Type"
                      component={RenderSelect}
                      options={businessTypeData.map(option => ({
                        value: option.businessTypeId,
                        label: option.businessType
                      }))}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      id="taxId"
                      name="taxId"
                      component={RenderInput}
                      type="text"
                      label="Tax ID"
                      margin="none"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      id="registrationNumber"
                      name="registrationNumber"
                      component={RenderInput}
                      type="text"
                      label="Registration Number"
                      margin="none"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      id="addressLine1"
                      name="addressLine1"
                      component={RenderInput}
                      type="text"
                      label="Registered Address"
                      margin="none"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      id="city"
                      name="city"
                      component={RenderInput}
                      type="text"
                      label="City"
                      margin="none"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      id="stateId"
                      name="stateId"
                      label="State/Province/Region"
                      component={RenderSelect}
                      options={[{ value: null, label: "-" }].concat(
                        statesData.map(option => ({
                          value: option.stateId,
                          label: option.stateName
                        }))
                      )}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <Field
                      id="zipPostalCode"
                      name="zipPostalCode"
                      component={RenderInput}
                      type="text"
                      label="Zip/Postal Code"
                      margin="none"
                    />
                  </Grid>
                  <Grid item xs={6} />
                  <Grid item xs={2}>
                    <Field
                      id="phonePrefixId"
                      name="phonePrefixId"
                      label="Country Code"
                      component={RenderSelect}
                      options={
                        phonePrefixData && Array.isArray(phonePrefixData)
                          ? phonePrefixData.map(option => ({
                              value: option._id,
                              label: `${option.countryName} +${option.countryPhonePrefix}`
                            }))
                          : []
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      id="phoneNumber"
                      name="phoneNumber"
                      component={RenderInput}
                      type="text"
                      label="Phone Number"
                      margin="none"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Field
                      id="contactPerson"
                      name="contactPerson"
                      component={RenderInput}
                      type="text"
                      label="Contact Person"
                      margin="none"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={8} justify="center">
                  <Grid item>
                    <br />
                    <RenderButton
                      type="submit"
                      color="primary"
                      disabled={submitSucceeded || pristine}
                    >
                      Save Profile
                    </RenderButton>

                    {/* <RenderButton color="warning" type="button" onClick={reset}>
                      Reset
                    </RenderButton> */}
                  </Grid>
                </Grid>
              </CardContent>
            </Paper>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <Paper>
              <CardContent>
                <Typography variant="title" gutterBottom>
                  Banking information
                </Typography>
                <Grid container spacing={8} />
              </CardContent>
            </Paper> */}
          {/* Hide verification documents for now */}
          {/* <br />
            <Paper>
              <CardContent>
                <Typography variant="title" gutterBottom>Verification documents</Typography>
                <Grid container spacing={8}>
                  <Grid item xs={12}>
                    <p>Incorporation documents</p>
                    <p>Photo ID for 2 directors</p>
                    <p>Official governmental letter confirming the tax ID and address</p>
                  </Grid>
                </Grid>
              </CardContent>
            </Paper> */}
          {/* </Grid> */}
        </Grid>
      </form>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      change
    },
    dispatch
  );
}

const validate = createValidator({
  participantName: [requiredString, maxLength(40)],
  businessTypeId: [requiredString],
  countryId: [requiredString],
  // stateId: [requiredString],
  city: [requiredString],
  taxId: [requiredString],
  contactPerson: [requiredString],
  faxNumber: [isOptionalNumber],
  phoneNumber: [isNumber]
});

GeneralInformationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const GeneralInformationReduxForm = reduxForm({
  form: "GeneralInformationForm",
  validate,
  enableReinitialize: true
})(GeneralInformationForm);

export default connect(null, mapDispatchToProps)(GeneralInformationReduxForm);
