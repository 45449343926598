import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FinancialContext } from 'screens/Financials';
import { Paper, CardContent, Grid, Typography } from '@material-ui/core';
import { RenderTable, AuthManager, WalletTransactionFilterForm } from 'components';
import useGetColumns from './hooks/useGetColumns';
import useFiltersAndPagination from './hooks/useFiltersAndPagination';
import useTriggers from './hooks/useTriggers';

export default () => {
	const { t } = useContext(FinancialContext);

	const { page, setPage, perPage, setPerPage, filters, setFilters } = useFiltersAndPagination();
	const { triggerTransactionFilterActions, triggerDownloadCSVActions, triggerWalletReportSubmit } = useTriggers({ setPage, setFilters });
	const columns = useGetColumns(filters);

	const { WALLET_TRANSACTIONS, TRANSACTIONS_LOADING } = useSelector(state => {
		return state.wallet;
	});

	const acpTransactions = WALLET_TRANSACTIONS?.data?.filter(transaction => transaction?.claimNumber !== undefined);
	// console.log('🚀 ~ file: index.js:23 ~ WALLET_TRANSACTIONS?.data:', WALLET_TRANSACTIONS?.data[1]);
	// console.log('🚀 ~ file: index.js:19 ~ acpTransactions:', acpTransactions);

	return (
		<AuthManager permissions={['financials.view-transactions']}>
			<Grid item xs={12} md={12}>
				<Paper>
					<CardContent>
						<Grid container>
							<Grid item xs={8}>
								<Typography variant="h1" gutterBottom>
									{t('transaction_history')}
								</Typography>
							</Grid>
						</Grid>

						<WalletTransactionFilterForm
							onChange={triggerTransactionFilterActions}
							onDownloadCSV={triggerDownloadCSVActions}
							onDownloadMonthlyStatements={triggerWalletReportSubmit}
						/>

						<RenderTable
							data={(Array.isArray(WALLET_TRANSACTIONS?.data) && WALLET_TRANSACTIONS.data) || []}
							minRows={2}
							loading={TRANSACTIONS_LOADING}
							columns={columns ? columns : []}
							page={page}
							defaultPageSize={perPage}
							pageSize={perPage}
							manual
							pages={Math.ceil((WALLET_TRANSACTIONS?._metaData?.total || 0) / perPage)}
							tableTotal={WALLET_TRANSACTIONS?._metaData?.total || 0}
							onPageChange={page => setPage(page)}
							onPageSizeChange={size => setPerPage(size)}
						/>
					</CardContent>
				</Paper>
			</Grid>
		</AuthManager>
	);
};
