import { put, takeEvery, all, call } from 'redux-saga/effects';
import actions from './actions';
import myAccountApiService from 'services/api/MyAccountApi';

const delay = time => new Promise(resolve => setTimeout(resolve, time));

export function* UPDATE_PASSWORD(action) {
	yield put({ type: 'passwordChallenge/SET_STATE', payload: { loading: true } });
	try {
		const result = yield call(myAccountApiService.updatePasswordchallenge, action.payload);

		yield put({ type: 'passwordChallenge/SET_STATE', payload: { loading: false } });

		if (result?.AuthenticationResult) {
			yield put({ type: 'LOGIN_REQUEST', payload: { userName: action.payload.email, password: action.payload.password } });

			yield put({
				type: 'passwordChallenge/SET_STATE',
				payload: {
					successMessage: 'Password updated successfully'
				}
			});

			yield call(delay, 4000);
			yield put({
				type: 'passwordChallenge/CLEAR_STATE'
			});
		} else {
			yield put({
				type: 'passwordChallenge/SET_STATE',
				payload: {
					errorMessage: result?.message || 'Unknown error while updating password.'
				}
			});

			yield call(delay, 4000);

			if (result.code === 'NotAuthorizedException') {
				yield put({
					type: 'passwordChallenge/CLEAR_STATE' //This routes back to the login screen
				});
			} else {
				yield put({
					type: 'passwordChallenge/SET_STATE',
					payload: {
						errorMessage: null //This just deletes the error so the message doesn't show every re render
					}
				});
			}
		}
	} catch (err) {
		const error = err.message || err;
		console.log('Error while updating password: ', error);

		yield put({
			type: 'passwordChallenge/SET_STATE',
			payload: {
				errorMessage: error
			}
		});

		yield call(delay, 4000);
		yield put({
			type: 'passwordChallenge/CLEAR_STATE' //This routes back to the login screen
		});
	} finally {
		yield put({ type: 'passwordChallenge/SET_STATE', payload: { loading: false } });
	}
}

export function* SET_PASSWORD_CHALLENGE_INFO(action) {
	try {
		yield put({ type: 'passwordChallenge/SET_STATE', payload: action.payload });
	} catch (err) {
		const error = err.message || err;
		console.log('Error while updating password: ', error);
		yield put({
			type: 'passwordChallenge/SET_STATE',
			payload: {
				errorMessage: error
			}
		});
	} finally {
		yield put({
			type: 'passwordChallenge/SET_STATE',
			payload: {
				errorMessage: null
			}
		});
	}
}

export default function* rootSaga() {
	yield all([takeEvery(actions.SET_PASSWORD_CHALLENGE_INFO, SET_PASSWORD_CHALLENGE_INFO), takeEvery(actions.UPDATE_PASSWORD, UPDATE_PASSWORD)]);
}
