import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, reset } from "redux-form";
import { bindActionCreators } from "redux";

import {
  createValidator,
  required,
  email,
  minLength
} from "services/validation";
import { MfaForm } from "components";
import {
  mfaRequest,
  loginSuccess,
  loginFailure,
  cleanDataLogin
} from "store/actions";
import { fromLoginaccount } from "store/selectors";

class MfaContainer extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(reset("loginForm"));
    dispatch(cleanDataLogin());
  }

  render() {
    return <MfaForm {...this.props} handleSubmit={this.props.handleSubmit} />;
  }
}

const mapStateToProps = state => ({
  form: state.form,
  info: fromLoginaccount.getLoginDataState(state)
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginSuccess,
      loginFailure
    },
    dispatch
  );
}

const onSubmit = (data, dispatch) => {
  dispatch(mfaRequest(data));
};

const validate = createValidator({
  email: [required, email],
  password: [required, minLength(8)]
});

MfaContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MfaContainer);

MfaContainer = reduxForm({
  form: "loginForm",
  destroyOnUnmount: true,
  onSubmit,
  validate,
  touchOnBlur: false
})(MfaContainer);

export default MfaContainer;
