import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, MenuItem, Select, InputLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';


const RenderFormikSelect = ({ formik: { values, errors, touched, setFieldValue, handleBlur }, name, options, label, onChange, allowClear, ...rest }) => {
	let hasError = (errors[name] && touched[name] && true) || false;
	const { t } = useTranslation(['translations']);


	let errorMsg = (hasError && !!errors[name] && errors[name]) || '';
	let value = values[name] || '';

	if (name.indexOf('.') > -1) {
		value = name.split('.').reduce((o, i) => (o && o[i]) || '', values);
		const error = name.split('.').reduce((o, i) => (o && o[i]) || '', errors);
		const touchedEl = name.split('.').reduce((o, i) => (o && o[i]) || '', touched);
		hasError = (error && touchedEl && true) || false;
		errorMsg = (hasError && !!error && error) || '';
	}
	return (
		<FormControl fullWidth error={hasError}>
			{(label && <InputLabel>{label}</InputLabel>) || ''}
			<Select
				name={name}
				onBlur={handleBlur}
				fullWidth
				value={value}
				onChange={e => {
					setFieldValue(name, e.target.value);
					if (onChange && typeof onChange === 'function') onChange(e);
				}}
				{...rest}
			>
				{(allowClear && <MenuItem value={null}>{t('formik.all')}</MenuItem>) || null}
				{options &&
					Array.isArray(options) &&
					options.length > 0 &&
					options.map(
						(option, key) =>
							(
								// eslint-disable-next-line react/no-array-index-key
								<MenuItem value={typeof option.value === "undefined" && (option.title || option.label) || option.value} key={key}>
									{option.title || option.label || ''}
								</MenuItem>
							) || ''
					)}

				{options && Array.isArray(options) && options.length === 0 && (
					<MenuItem value="" disabled>
						{t('formik.no_options')}
					</MenuItem>
				)}
			</Select>
			{hasError && <FormHelperText>{errorMsg}</FormHelperText>}
		</FormControl>
	);
};

RenderFormikSelect.defaultProps = {
	label: false
};

RenderFormikSelect.propTypes = {
	formik: PropTypes.object.isRequired,
	name: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	label: PropTypes.string
};

export default RenderFormikSelect;

// S
