export const VERIFICATION_CODE_REQUEST = "VERIFICATION_CODE_REQUEST";
export const VERIFICATION_CODE_FAILURE = "VERIFICATION_CODE_FAILURE";
export const VERIFICATION_CODE_SUCCESS = "VERIFICATION_CODE_SUCCESS";
export const CLEAN_DATA_VERIFICATION_CODE = "CLEAN_DATA_VERIFICATION_CODE";

export const verificationCodeRequest = (code, email, password) => ({
  type: VERIFICATION_CODE_REQUEST,
  payload: { code, email, password }
});
export const verificationCodeFailure = errorMessage => ({
  type: VERIFICATION_CODE_FAILURE,
  payload: errorMessage
});
export const verificationCodeSuccess = successMessage => ({
  type: VERIFICATION_CODE_SUCCESS,
  payload: successMessage
});

export const cleanDataVerificationCode = () => ({
  type: CLEAN_DATA_VERIFICATION_CODE
});
