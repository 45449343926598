import React from "react";

let newValue = [];
const CheckboxGroup = ({ input, options, meta: { touched, error } }) => {
  const checkboxGroup = options.map((option, index) => (
    <div className="checkbox" key={index}>
      <label>
        <input
          type="checkbox"
          name={`${input.name}[${index}]`}
          value={option.codexId}
          checked={input.value.indexOf(option.codexId) !== -1}
          onChange={event => {
            newValue = [...input.value];
            if (event.target.checked) {
              newValue.push(option.codexId);
            } else {
              newValue.splice(newValue.indexOf(option.codexId), 1);
            }

            return input.onChange(newValue);
          }}
        />
        {option.codexName}
      </label>
    </div>
  ));

  return (
    <div>
      {checkboxGroup}

      {(touched &&
        error && <span className="label label-danger">
{error}
</span>) ||
        (error &&
          newValue.length === 0 && (
            <span className="label label-danger">
{error}
</span>
          ))}
    </div>
  );
};

export default CheckboxGroup;
